import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GROUPED_KPI_ICONS } from '@salescore/client-common'
import { Form, Select, Space } from 'antd'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

export const KpiFormIconFormItem = () => {
  const { kpi, setKpi, setFormModified } = useKpiFormSelectors()

  return (
    <>
      <Form.Item label={t(`KPIアイコン`)}>
        <Select<string>
          value={kpi.icon}
          onChange={(value) => {
            setKpi({ icon: value })
            setFormModified(true)
          }}
        >
          {GROUPED_KPI_ICONS.map(([groupName, icons]) => (
            <Select.OptGroup label={groupName ?? t(`その他`)}>
              {icons.map((icon) => (
                <Select.Option key={`${icon.name}${icon.label ?? ''}`} value={icon.name}>
                  <Space>
                    <FontAwesomeIcon icon={icon.icon} />
                    <span>{icon.label ?? icon.name}</span>
                  </Space>
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}
