import { UnorderedListOutlined, WarningOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { FetchSyncModelResultsDocument } from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { Button, Drawer, Flex, Result, Table, type TableProps, Typography } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import Link from 'next/link'

const { Text } = Typography

interface DataType {
  key: string
  success: boolean
  name: string
  label: string
  timestamp: Date
  error: string | undefined
}

export interface ReportSyncModelParameters {
  connectionId: string
  organizationId: string
  jobQueueId: string
  checkSuccess: boolean
  checkError: string | undefined
  timestamp: Date
}

const DrawerContent = ({
  params: { organizationId, jobQueueId, checkSuccess, checkError },
}: {
  params: ReportSyncModelParameters
}) => {
  const fetchResult = useQuery(FetchSyncModelResultsDocument, {
    variables: {
      organizationId,
      jobQueueId,
    },
  })

  const dataSource: DataType[] = (fetchResult.data?.syncModelResults ?? []).compact().map((e, index) => ({
    key: index.toString(),
    success: e.success,
    name: e.name,
    label: e.label,
    timestamp: dayjs(e.timestamp).toDate(),
    error: e.error ?? undefined,
  }))

  const columns: TableProps<DataType>['columns'] = [
    {
      title: t('モデル名'),
      key: 'modelName',
      sorter: (a, b) => a.label.localeCompare(b.label),
      render: (_, record) => (
        <div>
          {record.label}
          <br />
          <Text type={'secondary'}>{record.name}</Text>
        </div>
      ),
    },
    {
      title: t('同期時刻'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a, b) => a.timestamp.getTime() - b.timestamp.getTime(),
      render: (x: Date) => t(`{{datetime, datetimestr}}`, { datetime: x }),
    },
    {
      title: t('結果'),
      dataIndex: 'success',
      key: 'success',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => Number(a.success) - Number(b.success),
      render: (x: boolean, record: DataType) =>
        x ? (
          t('成功')
        ) : (
          <Text type={'danger'}>
            {record.error ?? (
              <>
                <WarningOutlined /> {t('失敗')}
              </>
            )}
          </Text>
        ),
    },
  ]

  return (
    <div>
      {checkSuccess ? (
        <div>
          <Text type={'danger'}>
            <WarningOutlined />
            {t(
              `一部のオブジェクトの同期に失敗しました。しばらく時間がたっても解消しない場合カスタマー担当にご連絡ください。`,
            )}
          </Text>
          <Table dataSource={dataSource} columns={columns} />
        </div>
      ) : (
        <Result
          status={'error'}
          title={t('同期前エラー')}
          subTitle={checkError ?? t('各オブジェクトの同期前にエラーが発生しました。カスタマー担当にご連絡ください。')}
        />
      )}
    </div>
  )
}

export const EltModelSettingsLink = (properties: { connectionId: string }) => (
  <Link href={routes.eltModelsSettingsPathV2(properties.connectionId)} legacyBehavior>
    <Button type="primary" icon={<UnorderedListOutlined />} size={'small'}>
      {t('オブジェクト一覧')}
    </Button>
  </Link>
)

export const ReportSyncModel = ({ onClose, params }: { onClose: () => void; params?: ReportSyncModelParameters }) => (
  <Drawer
    title={
      params !== undefined && (
        <Flex justify={'space-between'}>
          <div>
            <span>
              {t('同期時刻')}: {t(`{{datetime, datetimestr}}`, { datetime: params.timestamp })}
              <br />
              <Text type={'secondary'}>ID: {params.jobQueueId} </Text>
            </span>
          </div>
          <EltModelSettingsLink connectionId={params.connectionId} />
        </Flex>
      )
    }
    closable={false}
    width={600}
    onClose={onClose}
    open={params !== undefined}
  >
    {params !== undefined && <DrawerContent params={params} />}
  </Drawer>
)
