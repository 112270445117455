import type { ViewUIDatePicker } from '@salescore/core'
import { DatePicker, message } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useContext } from 'react'

import { useViewUIRecordNodeContext } from './contexts'
import { ViewUIContext } from './ViewUIComponent'

export function ViewUIDatePickerC({ component }: { component: ViewUIDatePicker }) {
  const { isEditMode } = useContext(ViewUIContext)
  const { getValue, onChange } = useViewUIRecordNodeContext()
  const value = getValue(component.fieldName)
  const datetime = typeof value === 'string' ? dayjs(value) : undefined
  return (
    <DatePicker
      value={datetime}
      onChange={(value) => {
        const formatedDate =
          component.showTime === true ? value?.format('YYYY-MM-DDTHH:mm:ss.000+09:00') : value?.format('YYYY-MM-DD')
        onChange({ fieldName: component.fieldName, value: formatedDate })
      }}
      showTime={component.showTime === true ? { format: `HH:mm:ss` } : undefined}
      minuteStep={component.minuteStep as 1 | undefined} // TODO
      onClick={() => {
        if (component.readonly === true) {
          void message.warning(t(`この項目は書き込みが禁止されています。`))
        }
      }}
      disabled={isEditMode || component.readonly}
    />
  )
}
