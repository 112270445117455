import { useQuery } from '@apollo/client'
import { compareFunction } from '@salescore/buff-common'
import { FetchQueryResultsDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { getColumnSearchProps } from '@salescore/frontend-common'
import { Table } from 'antd'
import { t } from 'i18next'
import { z } from 'zod'

const resultSchema = z.object({
  query: z.object({
    model: z.object({
      model: z.object({
        explore: z.string(),
      }),
    }),
  }),
  result: z.object({
    rows: z.array(z.array(z.number())),
  }),
})

type Result = z.infer<typeof resultSchema>

export const SourceStatsContent = ({ eltModelNames }: { eltModelNames: string[] }) => {
  const { data, loading } = useQuery(FetchQueryResultsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      queries: eltModelNames.map((eltModelName) => ({
        model: {
          model: {
            explore: eltModelName,
          },
        },
      })),
    },
  })

  const dataSource = data?.queryResults
    .map((x) => resultSchema.safeParse(x))
    .map((x) => (x.success ? x.data : undefined))
    .compact()

  return (
    <Table
      loading={loading}
      dataSource={dataSource}
      rowKey={(record: Result) => record.query.model.model.explore}
      columns={[
        {
          title: t(`オブジェクト名`),
          ...getColumnSearchProps((x) => x.query.model.model.explore),
          sorter: (a, b) => compareFunction(a.query.model.model.explore, b.query.model.model.explore),
          render: (_text, record) => record.query.model.model.explore,
        },
        {
          title: t(`取得したレコード数`),
          ...getColumnSearchProps((x) => x.result.rows.join(', ') ?? '-'),
          sorter: (a, b) =>
            compareFunction(Number(a.result.rows.join('') ?? '0'), Number(b.result.rows.join('') ?? '0')),
          defaultSortOrder: 'descend',
          render: (_text, record) => record.result.rows.join(', ') ?? '-',
        },
      ]}
    />
  )
}
