import type { SharedUrl } from '@salescore/client-api'
import { viewConfigFilterNodeSchema, type ViewConfigSheet } from '@salescore/core'
import { stableStringify } from '@salescore/features'
import { z } from 'zod'

export function detectSharedUrlSettings(config: ViewConfigSheet, sharedUrl: SharedUrl | undefined) {
  try {
    const sharedUrlFilterTree = z
      .object({
        filterTree: viewConfigFilterNodeSchema,
      })
      .parse(sharedUrl?.parameter)
    return stableStringify(config.filterTree) === stableStringify(sharedUrlFilterTree.filterTree)
  } catch {
    return false
  }
}
