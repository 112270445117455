import type { SheetThread, SheetThreadCommentNotificationsQuery } from '@salescore/client-api'
import type {
  EltChangeResult,
  ViewConfigAdditionalConfig,
  ViewQueryRecordNode,
  ViewQueryResult,
  ViewQueryResultCursor,
  ViewQueryResultSnapshot,
} from '@salescore/core'
import { localStorageEffect, organizationHasFeature, queryParameterEffect } from '@salescore/frontend-common'
import type { Dayjs } from 'dayjs'
import { atom, atomFamily, DefaultValue, selector } from 'recoil'
import { z } from 'zod'

import type { ChangeHistory } from '../../state/useViewRecordsState/useChangesState'
import { meAtom } from '../view/atoms'

const prefix = `view`

//
// records関連
//
export const recordIdsAtom = atom<string[]>({
  key: 'recordIds',
  default: [],
})

// idが見つからなかった場合のレスポンスの型を明示的に指定する必要がある
export const recordAtomFamily = atomFamily<ViewQueryRecordNode | undefined, { id: string }>({
  key: 'record',
  default: undefined as never,
})

//
// atomという名前にしているが、実質はselector
// recordsの実態は、idの配列と、idをキーにしたatomFamilyからなる。
// この設計にしている理由は以下を参照
// https://zenn.dev/tera_ny/articles/e478fce56db413#bad%E3%81%AA%E3%82%A2%E3%82%A4%E3%83%87%E3%82%A2
//
export const recordsAtom = selector<ViewQueryRecordNode[]>({
  key: 'records',
  get: ({ get }) => {
    const recordIds = get(recordIdsAtom)
    return recordIds
      .map((id) => {
        const record = get(recordAtomFamily({ id }))
        return record
      })
      .compact()
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      set(recordIdsAtom, [])
    } else {
      const newRecords = newValue.uniqueBy((x) => x.id) // uniqueByは不要なはずだが、不具合で重複してしまうことに備えてuniqueByしておく
      set(recordIdsAtom, newRecords.map((record) => record.id).compact())
      for (const record of newRecords) {
        if (record.id !== undefined) {
          // TODO: idがundefinedになるのはいつ？
          set(recordAtomFamily({ id: record.id }), record)
        }
      }
    }
  },
})

export const snapshotsAtom = atom<ViewQueryResultSnapshot>({
  key: `${prefix}/snapshotsAtom`,
  default: {},
})
//
// 取得条件系
//
export const cursorAtom = atom<ViewQueryResultCursor | null | undefined>({
  key: `${prefix}/cursor`,
  default: null,
})

export interface HighlightConfig {
  value: string
  label: string
  date: Dayjs | undefined
}

// TODO: localStorageに永続化したいが、dateがDayjsのオブジェクトなのでそのまま永続化できない
export const highlightConfigAtom = atom<HighlightConfig | undefined>({
  key: `${prefix}/highlightConfig`,
  default: undefined,
})

const pageSizeSelector = selector<number>({
  key: 'pageSizeSelector',
  get: ({ get }) => {
    const me = get(meAtom)
    const enableNxOnlyFeature = organizationHasFeature(me.organization, 'enable_nx_only_feature')
    return enableNxOnlyFeature ? 1000 : 100
  },
})

export const pageSizeAtom = atom<number>({
  key: `${prefix}/pageSize`,
  default: pageSizeSelector,
  effects: [localStorageEffect(`${prefix}/pageSize`, z.number())],
})

// これ、atomsにする必要あるか？
export const organizationIdAtom = atom<string>({
  key: `${prefix}/organizationId`,
  default: '',
})

export const mustacheParameterAtom = atom<Record<string, unknown>>({
  key: `${prefix}/mustacheParameter`,
  default: {},
})

export const additionalConfigAtom = atom<ViewConfigAdditionalConfig>({
  key: `${prefix}/additionalConfigAtom`,
  default: {},
})

// XXX: URLに同期する際に分かりやすくしたいので、ここだけprefixを抜いている
export const searchQueryAtom = atom<string | null>({
  key: `searchQuery`,
  default: '',
  // XXX: タブ切り替え時のパラメータ削除はrecoil-pages/atoms側で行なっている
  effects: [queryParameterEffect(`searchQuery`)],
})

//
// 変更差分
//
export const changesAtom = atom<ChangeHistory[]>({
  key: `${prefix}/changes`,
  default: [],
})

export const backedChangesAtom = atom<ChangeHistory[]>({
  key: `${prefix}/backedChanges`,
  default: [],
})

export const pickedIdsAtom = atom<string[]>({
  key: `${prefix}/pickedIds`,
  default: [],
})

//
// フィードバックUI
//
// recoilの中でpresentationレイヤーの操作はできず、ant designのmessageのような処理をするとフリーズする（詳細未確認）ため、
// recoilの処理の中でフィードバックメッセージを表示したい場合は、以下のatomsにメッセージを格納するような形にする
export interface FeedbackMessage {
  message: string
  type: 'error' | 'warn' | 'info'
}

export const feedbackMessagesAtom = atom<FeedbackMessage[]>({
  key: `${prefix}/feedbackMessages`,
  default: [],
})

export const errorsAtom = atom<string[]>({
  key: `${prefix}/errors`,
  default: [],
})

export const errorsDrawerOpenAtom = atom<boolean>({
  key: `${prefix}/errorsDrawerOpen`,
  default: false,
})

// TODO: errorsAtomも含め、fetchの結果処理周りをリファクタリングしたい
export const aggregationErrorsAtom = atom<string[]>({
  key: `${prefix}/aggregationErrorsAtom`,
  default: [],
})

// TODO: suspense?
export const loadingAtom = atom<boolean>({
  key: `${prefix}/loading`,
  default: false,
})

export const isSavingAtom = atom<boolean>({
  key: `${prefix}/isSaving`,
  default: false,
})

export const viewQueryResultAtom = atom<ViewQueryResult | undefined>({
  key: `${prefix}/viewQueryResult`,
  default: undefined,
})

// viewQueryResultsAtomとして統合したいが、分割した方が何かと便利なので一旦こうしている。
export const viewAggregationQueryResultsAtom = atom<ViewQueryResult[]>({
  key: `${prefix}/viewAggregationQueryResultsAtom`,
  default: [],
})

export const aggregationRecordsSelector = selector<ViewQueryRecordNode[]>({
  key: 'aggregationRecordsSelector',
  get({ get }) {
    const results = get(viewAggregationQueryResultsAtom)

    return results.flatMap((x) => x.result)
  },
})

export const sheetInSaveErrorsAtom = atom<EltChangeResult[] | undefined>({
  key: `${prefix}/sheetInSaveErrorsAtom`,
  default: undefined,
})

export const sheetThreadsAtom = atom<SheetThread[]>({
  key: `${prefix}/sheetThreads`,
  default: [],
})

export const sheetThreadCommentNotificationsAtom = atom<
  SheetThreadCommentNotificationsQuery['sheetThreadCommentNotifications']
>({
  key: `${prefix}/sheetThreadCommentNotifications`,
  default: [],
})
