import { type ApolloClient, useApolloClient } from '@apollo/client'
import { r } from '@salescore/buff-common'
import { FetchViewSearchSqlResultDocument, type ViewFieldsFragment } from '@salescore/client-api'
import { recoil } from '@salescore/client-recoil'
import type { ViewQueryField, ViewQueryList } from '@salescore/core'
import { logger, useEffectWithChange } from '@salescore/frontend-common'
import Mustache from 'mustache'
import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import type { ViewRecordFieldChange } from '../state/useViewRecordsState/useChangesState'
import { changesModalAtom } from './navigation/atoms'
import { useKpiPivotPickedPresetName, useSheetPickedPresetName } from './navigation/hooks'
import {
  additionalConfigAtom,
  changesAtom,
  errorsDrawerOpenAtom,
  highlightConfigAtom,
  searchQueryAtom,
} from './records/atoms'
import { usePageSizeState } from './records/hooks'
import type { RecordChange } from './records/mutations/upsertViewRecords'
import { upsertViewRecordsMutation } from './records/mutations/upsertViewRecordsMutation'
import { useRefetchAggregationQueryResult } from './records/mutations/useRefetchAggregationQueryResult'
import { useRefetchKpiPivotQueryResult } from './records/mutations/useRefetchKpiPivotQueryResult'
import { useRefetchMutation } from './records/mutations/useRefetchMutation'
import { useMustacheParameterSelector } from './records/selectors/mustacheParameterSelector'
import { listQuerySelector } from './records/selectors/querySelector'
import {
  aggregationQueriesSelecttor,
  configAtom,
  hasChangeToKpiPivotParameterAtom,
  hasChangeToViewSchemaAtom,
  meAtom,
  multiTablePivotQuerySelecttor,
  oldestSyncedAtAtom,
  viewAtom,
} from './view/atoms'
import { useViewsContextValue, useWatchView } from './view/hooks'
import { useCachedViewsSelector } from './view/selectors/cachedViewSelector'
import { configSheetSelector } from './view/selectors/configSheetSelector'
import { useKpiFormSelectors } from './view/selectors/kpiFormSelector'

// TODO: 命名が思いつかない
export const useWatch = ({ onSaveView }: { onSaveView?: (views: ViewFieldsFragment[]) => void }) => {
  const { clearTargetViewQueryCaches } = recoil.sider.useClearViewQueryCacheMutation()
  const view = useRecoilValue(viewAtom)
  const { shouldJoinRecordsInApplication } = useRecoilValue(configSheetSelector)

  useWatchView({ onSaveView })
  const { query } = useRecoilValue(listQuerySelector)
  const aggregationQueries = useRecoilValue(aggregationQueriesSelecttor)
  const multiTablePivotQuery = useRecoilValue(multiTablePivotQuerySelecttor)
  const mustacheParameter = useMustacheParameterSelector()
  const highlightConfig = useRecoilValue(highlightConfigAtom)
  const searchQuery = useRecoilValue(searchQueryAtom)
  const changes = useRecoilValue(changesAtom)
  const config = useRecoilValue(configAtom)
  const client = useApolloClient()
  const me = useRecoilValue(meAtom)
  const upsertViewRecords = useSetRecoilState(upsertViewRecordsMutation)
  const [pageSize] = usePageSizeState()
  const [previousQuery, setPreviousQuery] = useState<ViewQueryList | undefined>()
  const [previousViewId, setPreviousViewId] = useState<string | undefined>()
  const [previousKpiPivotPresetName, setPreviousKpiPivotPresetName] = useState<string | undefined>()
  const [previousSheetPresetName, setPreviousSheetPresetName] = useState<string | undefined>()

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { refetch } = useRefetchMutation()
  const refetchAggregationQueryResult = useRefetchAggregationQueryResult()
  const refetchKpiPivotQueryResult = useRefetchKpiPivotQueryResult()
  const { shouldInitializeWithNewRecord, updateDurationMetricsList } = useViewsContextValue()
  const { shouldUseRefetchCache, updateCachedView, resetIgnoreCacheFlag } = useCachedViewsSelector()
  const oldestSyncedAt = useRecoilValue(oldestSyncedAtAtom)
  const additionalConfig = useRecoilValue(additionalConfigAtom)
  const [kpiPivotPickedPresetName] = useKpiPivotPickedPresetName()
  const [sheetPickedPresetName] = useSheetPickedPresetName()
  const { form, formModified } = useKpiFormSelectors()
  const hasChangeToKpiPivotParameter = useRecoilValue(hasChangeToKpiPivotParameterAtom)
  const hasChangeToViewSchema = useRecoilValue(hasChangeToViewSchemaAtom)
  const errorsDrawerOpen = useRecoilValue(errorsDrawerOpenAtom)
  const changesModal = useRecoilValue(changesModalAtom)

  // eslint-disable-next-line unicorn/consistent-function-scoping
  function pathAndName(field: ViewQueryField) {
    return `${field.nodePath.join('.')}.${field.name}`
  }

  function fieldsReordered(fields1: ViewQueryField[], fields2: ViewQueryField[]) {
    const pathAndNameSet2 = new Set(fields2.map((element) => pathAndName(element)))
    if (fields1.length === fields2.length && fields1.every((x) => pathAndNameSet2.has(pathAndName(x)))) {
      // フィールドの順番が変更されたかどうかの判定
      for (const [index, element] of fields1.entries()) {
        if (pathAndName(element) !== pathAndName(fields2[index]!)) {
          // fields1 と fields2 は同じ長さであることが保証されている
          return true
        }
      }
    }
    return false
  }

  useEffect(() => {
    updateCachedView?.({
      viewId: view.id,
      config,
      changes,
      kpiForm: form,
      additionalConfig,
      kpiPivotPickedPresetName,
      sheetPickedPresetName,
      updatedAt: view.updatedAt ?? undefined,
      oldestSyncedAt,
      isModifiedKpiForm: formModified,
      hasChangeToViewSchema,
      hasChangeToKpiPivotParameter,
      errorsDrawerOpen,
      changesModal,
    })
  }, [
    JSON.stringify({
      view,
      config,
      changes,
      form,
      additionalConfig,
      pickedPresetName: kpiPivotPickedPresetName,
      sheetPickedPresetName,
      oldestSyncedAt,
      formModified,
      hasChangeToViewSchema,
      hasChangeToKpiPivotParameter,
      errorsDrawerOpen,
      changesModal,
    }),
  ])

  // カーソル以外の条件が書き換わった場合の初期化処理
  // viewの変更とqueryの変更を別々のhooksで行なったりすると、新しいviewId・古いqueryの状態でfetchが走ったりするので注意
  useEffect(() => {
    // 初期化されるまでqueryはundefinedになっている // TODO: これどう防ぐのが良いか？
    if (query === undefined) {
      return
    }
    if (shouldInitializeWithNewRecord === true) {
      return
    }

    if (previousViewId === view.id && previousQuery !== undefined) {
      // ビューIDは変更ないが、queryが変更された場合
      if (query.fields.length > previousQuery.fields.length) {
        const previousQueryFieldsPaths = new Set(previousQuery.fields.map((element) => pathAndName(element)))
        const addedFields = query.fields.filter((x) => !previousQueryFieldsPaths.has(pathAndName(x)))
        void refetch({ kind: 'addColumn', fields: addedFields })
      } else if (query.fields.length < previousQuery.fields.length) {
        const queryFieldsPaths = new Set(query.fields.map((element) => pathAndName(element)))
        const deletedFields = previousQuery.fields.filter((x) => !queryFieldsPaths.has(pathAndName(x)))
        void refetch({ kind: 'deleteColumn', fields: deletedFields })
      } else if (
        fieldsReordered(query.fields, previousQuery.fields) &&
        ((config.type === 'sheet' && sheetPickedPresetName === previousSheetPresetName) ||
          (config.type === 'kpiPivot' && kpiPivotPickedPresetName === previousKpiPivotPresetName) ||
          config.type === 'kpiTimeSeries')
      ) {
        // プリセットが同一かつ列の並び替えの場合は Refetch する必要はない
      } else {
        // TODO: 列の追加・削除以外の条件が変更された場合の細かな処理
        void refetch({ kind: 'reset' })
      }
    } else {
      void refetch({ kind: 'changeView' }, shouldUseRefetchCache)
      resetIgnoreCacheFlag()
      if (!shouldUseRefetchCache) {
        clearTargetViewQueryCaches({ viewIds: [view.id] })
      }
    }
    setPreviousQuery(query)
    setPreviousViewId(view.id)
    setPreviousKpiPivotPresetName(kpiPivotPickedPresetName)
    setPreviousSheetPresetName(sheetPickedPresetName)
  }, [
    view.id,
    JSON.stringify(query),
    JSON.stringify(mustacheParameter),
    highlightConfig,
    pageSize,
    searchQuery,
    shouldJoinRecordsInApplication,
  ])

  useEffect(() => {
    void refetchAggregationQueryResult.refetch(shouldUseRefetchCache)
    resetIgnoreCacheFlag()
    if (!shouldUseRefetchCache) {
      clearTargetViewQueryCaches({ viewIds: [view.id] })
    }
  }, [view.id, JSON.stringify(aggregationQueries), mustacheParameter])

  useEffect(() => {
    refetchKpiPivotQueryResult.refetch(shouldUseRefetchCache)
    resetIgnoreCacheFlag()
    if (!shouldUseRefetchCache) {
      clearTargetViewQueryCaches({ viewIds: [view.id] })
    }
  }, [view.id, JSON.stringify(multiTablePivotQuery), mustacheParameter])

  const fieldsWithCallback = query.fields.filter((x) => (x.meta.callbacks ?? []).isPresent())

  useEffectWithChange((previousChanges, currentChanges) => {
    const diff = currentChanges.slice(previousChanges.length)
    const fieldChanges = diff.flatMap((x) => x.changes).flatMap((x) => x.fieldChanges)
    for (const fieldChange of fieldChanges) {
      // 雰囲気コード
      void runCallback(me.organization.id, client, query.fields, fieldsWithCallback, fieldChange, upsertViewRecords)
    }
  }, changes)
}

async function runCallback(
  organizationId: string,
  client: ApolloClient<unknown>,
  fields: ViewQueryField[],
  fieldsWithCallback: ViewQueryField[],
  fieldChange: ViewRecordFieldChange['fieldChanges'][0],
  upsert: (argument: { recordChanges: RecordChange[] }) => void,
) {
  const changedField = fieldsWithCallback.find((x) => x.name === fieldChange.fieldName)
  if (changedField === undefined) {
    return
  }

  const xs = (changedField.meta.callbacks ?? []).map(async (callback) => {
    logger.debug(`[runCallback] callback`, callback)
    // TODO: タイプ別に処理
    const rawSql = callback.sql
    // const rawSql = `SELECT name as salesforce_contact_last_name FROM salesforce_account where id = '{{ value }}'` // sample
    const sql = Mustache.render(rawSql, { id: fieldChange.id, value: fieldChange.value })

    // TODO: searchを流用している
    const result = await client.query({
      query: FetchViewSearchSqlResultDocument,
      variables: {
        organizationId,
        sql,
      },
    })

    const rows = (result.data.viewSearchSqlResult.rows ?? []) as Array<Record<string, unknown>>
    const row = rows.first()
    if (row === undefined) {
      return
    }

    const recordChanges = r(row)
      .map((fieldName, value): RecordChange | undefined => {
        const changedByCallbackField = fields.find((x) => x.name === fieldName)
        if (changedByCallbackField === undefined) {
          return undefined
        }
        return {
          rowIndex: fieldChange.rowIndex,
          innerRowIndex: fieldChange.innerRowIndex,
          nodePath: changedByCallbackField.nodePath,
          isNestedColumn: changedByCallbackField.nodePath.length > 1,
          field: changedByCallbackField,
          value,
          label: undefined,
        }
      })
      .compact()

    logger.debug(`[runCallback] recordChanges by callback`, { recordChanges, callback, row, sql })
    upsert({ recordChanges })
  })

  await Promise.all(xs)
}
