import { isTruthy } from '@salescore/buff-common'
import type { V2Organization } from '@salescore/client-api'
import { getOrganizationIdFromPath as _getOrganizationIdFromPath } from '@salescore/client-base'

export const signInAs = (organization: Pick<V2Organization, 'id'>, options?: { newWindow?: boolean }) => {
  const path = '/o/' + organization.id + '/v2'
  // 余計なキャッシュが残らないよう、assignを使ってページを再読み込み（＝InMemoryCacheを削除）している。
  // その分動作が遅いが、この機能を使うのは限られたユーザーのみ、限られた場面のみのはずなのでこうしておく
  if (isTruthy(options?.newWindow)) {
    window.open(path)
  } else {
    location.assign(path)
  }
}

export const setCurrentOrganizationIdToLocalStorage = (organizationId: string) => {
  localStorage.setItem('currentOrganizationId', organizationId)
}
export const getCurrentOrganizationIdFromLocalStorage = () => localStorage.getItem('currentOrganizationId')

export const getOrganizationIdFromPath = (): string => _getOrganizationIdFromPath()

export const setRedirectPathToLocalStorage = (path: string) => {
  localStorage.setItem('redirectPath', path)
}
export const getRedirectPathFromLocalStorage = () => localStorage.getItem('redirectPath')
