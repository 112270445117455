import { UnorderedListOutlined } from '@ant-design/icons'
import { useModal } from '@salescore/frontend-common'
import { Col, List, Modal, Row, Space } from 'antd'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import { KpiFormSheetFieldsFormItemSelectorTable } from './KpiFormSheetFieldsFormItemSelectorTable'
import { KpiFormSheetFieldsFormItemSorter } from './KpiFormSheetFieldsFormItemSorter'

export const KpiFormSheetFieldsFormItem = () => {
  const modal = useModal()
  const { currentFields } = useKpiFormSelectors()

  return (
    <div>
      <Space className="mb-3">
        <UnorderedListOutlined /> {t(`一覧表示時の項目（任意）`)}
      </Space>
      <List
        dataSource={currentFields}
        size="small"
        className="mb-8 cursor-pointer rounded border border-solid border-gray-200 p-2"
        renderItem={({ property, node }) => (
          <List.Item onClick={modal.showModal}>
            {node.nodePathAsLabel.join(': ')}: {property.label}
          </List.Item>
        )}
      />
      <Modal
        open={modal.isModalVisible}
        onCancel={() => {
          // onChange(values)
          modal.hideModal()
        }}
        width={'90%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        style={{ top: '3%' }}
      >
        <Row justify="space-between">
          <Col span={12} className="m-1 rounded-lg bg-gray-100 p-4">
            <KpiFormSheetFieldsFormItemSelectorTable />
          </Col>
          <Col span={11} className="m-1 rounded-lg bg-gray-100 p-4">
            <KpiFormSheetFieldsFormItemSorter />
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
