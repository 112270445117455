import { PageHeader } from '@ant-design/pro-layout'
import { useQuery } from '@apollo/client'
import { FetchConnectionDocument } from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { getOrganizationIdFromPath, SuspenseWithLoading } from '@salescore/client-common'
import { useRedirect } from '@salescore/frontend-common'
import { Spin, Steps } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { EditConnectionStep1 } from './EditConnectionStep1'
import { EditConnectionStep2 } from './EditConnectionStep2'

export const EditConnectionPage = ({ sourceId }: { sourceId: string }): JSX.Element => {
  const [step, setStep] = useState(0)
  const redirect = useRedirect()
  const [pickedEntityNames, setPickedEntityNames] = useState<string[]>([]) // 現状はwhitelistのみを使っている
  const { data, refetch } = useQuery(FetchConnectionDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      sourceId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setPickedEntityNames(data.source.connection?.eltConfig.sourceNamesWhitelist ?? [])
    },
  })

  if (data === undefined) {
    return (
      <>
        <Spin />
      </>
    )
  }
  const source = data.source
  const connection = source.connection

  return (
    <PageHeader
      title={t(`同期項目設定`)}
      onBack={() => {
        redirect(routes.sourceSettingsPathV2())
      }}
    >
      <Steps current={step} className="rounded bg-white px-1" type="navigation">
        <Steps.Step
          title={t(`オブジェクト選択`)}
          onClick={() => {
            setStep(0)
          }}
          className="cursor-pointer"
        />
        <Steps.Step
          title={t(`フィールド選択(任意)`)}
          onClick={() => {
            setStep(1)
          }}
          className="cursor-pointer"
        />
        <Steps.Step title={t(`設定完了`)} className="cursor-pointer" />
      </Steps>
      <div className="rounded bg-white p-3 pt-4">
        {step === 0 ? (
          <SuspenseWithLoading type="table">
            <EditConnectionStep1
              source={source}
              pickedEntityNames={pickedEntityNames}
              setPickedEntityNames={setPickedEntityNames}
              onFinish={() => {
                setStep(1)
              }}
            />
          </SuspenseWithLoading>
        ) : (
          <SuspenseWithLoading type="table">
            <EditConnectionStep2
              source={source}
              pickedEntityNames={pickedEntityNames}
              eltConfig={connection?.eltConfig}
              onAfterFinish={() => {
                void refetch()
              }}
            />
          </SuspenseWithLoading>
        )}
      </div>
    </PageHeader>
  )
}
