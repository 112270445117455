import { isPresent } from '@salescore/buff-common'
import type { ViewForSiderFieldsFragment } from '@salescore/client-api'
import { FolderPathAndCreator } from '@salescore/client-common'
import { useMemo } from 'react'

import { useViewsRelated } from '../../../../../recoil/view/selectors/viewsRelatedSelector'

interface Properties {
  view: Pick<ViewForSiderFieldsFragment, 'name' | 'viewGroupId' | 'createdBy'>
  showPath?: boolean
}

export const ViewListItem = (properties: Properties) => {
  const { view, showPath } = properties
  const { viewGroups, searchParentViewGroups } = useViewsRelated()

  const paths = useMemo(() => {
    if (isPresent(view.viewGroupId)) {
      return searchParentViewGroups(view.viewGroupId)
        .reverse()
        .map((viewGroup) => viewGroup.name)
    }
  }, [viewGroups, view.viewGroupId])

  const pathText = isPresent(paths) ? paths.join(' / ') : undefined

  return (
    <div>
      <div className="whitespace-pre-wrap break-words pb-1">{view.name}</div>
      <FolderPathAndCreator path={paths} createdBy={view.createdBy?.name} />
    </div>
  )
}
