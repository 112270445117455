//
// キューのn秒後にキューを実行するクラス。ただし、n秒間にさらにキューがあった場合、前のキューは破棄される。
// 処理が重い非同期関数をロジックの都合で何度も呼び出すが、都度実行する必要はなく最新のもののみ更新すれば十分な場合に使う
// （例）並び替え可能なユーザー一覧で、並び替えが発生する度に更新処理を行う
//

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const queues: any[] = [] // queueをインスタンス変数として定義したかったが、予想通りの挙動をしてくれなかった

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class TimeWindowExecutor<T extends (...arguments_: any) => void | Promise<void>> {
  private readonly method: T
  private readonly timeWindow: number

  // private readonly queues: Array<Parameters<T>>

  public constructor(method: T, timeWindow = 500) {
    this.method = method
    this.timeWindow = timeWindow
  }

  public queue(arguments_: Parameters<T>) {
    queues.push(arguments_)
    setTimeout(() => {
      this.execute()
    }, this.timeWindow)
  }

  private execute() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const queue = queues.shift()
    if (queues.length > 0) {
      // queueした回数executeするので、必ずいつか0になる
      return
    }
    if (queue === undefined) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    void this.method(...queue)
  }
}

// sample
// const method = (x: number) => {
//   logger.log(`x is ${x}`);
// }
// const executor = new TimeWindowExecutor(method)
// executor.queue([1])
// executor.queue([2])
