import { isTruthy } from '@salescore/buff-common'
import type { ModelSearcher, ViewQueryList, ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'

import { flatNodes, getRecordNodeFromRootNode } from '../../../../state/nodeUtil'
import type { ViewRecordFieldChange } from '../../../../state/useViewRecordsState/useChangesState'

export function generateDeleteChanges(
  query: ViewQueryList,
  targetNode: ViewQueryNode,
  recordNode: ViewQueryRecordNode,
  modelSearcher: ModelSearcher,
  isDeleteAllChildren?: boolean,
): ViewRecordFieldChange[] {
  if (targetNode.write === undefined) {
    return []
  }

  if (isDeleteAllChildren !== true) {
    const model = modelSearcher.searchModel(targetNode.write.streamName)
    if (!isTruthy(model?.deletable ?? true)) {
      return []
    }

    return [
      {
        id: recordNode.id,
        streamName: targetNode.write.streamName,
        fieldChanges: [],
        before: {},
        after: {},
        deleted: true,
      },
    ]
  }

  const visibleNodeNames = query.fields
    .flatMap((field) => field.nodePath)
    .compact()
    .unique()
  const nodes = flatNodes(targetNode).filter((x) => {
    const model = modelSearcher.searchModel(x.write?.streamName)
    return isTruthy(model?.deletable ?? true) && visibleNodeNames.includes(x.name)
  })

  return nodes
    .flatMap((node): ViewRecordFieldChange[] => {
      if (node.write === undefined) {
        return []
      }

      // 該当するnodeのrecordsを取得
      const recordNodes = getRecordNodeFromRootNode(recordNode, node.path.slice(targetNode.path.length - 1))

      if (recordNodes === undefined) {
        return []
      }

      const xs = Array.isArray(recordNodes) ? recordNodes : [recordNodes]
      const nameField = query.fields
        .filter((x) => x.nodePath.isEqual(node.path))
        .find((field) => field.meta.fieldMetaType === 'name')

      // recordNodesを全て削除
      return xs.map(
        (recordNode): ViewRecordFieldChange => ({
          id: recordNode.id,
          streamName: node.write!.streamName,
          fieldChanges: [],
          recordNameLabel: nameField === undefined ? undefined : (recordNode.attributes[nameField.name] as string),
          // before: recordNode.attributes,
          // after: {},
          deleted: true,
        }),
      )
    })
    .uniqueBy((x) => [x.id, x.streamName].join(','))
}
