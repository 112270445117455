import { DownOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { isSome, isTruthy } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import { checkSyncEnabled } from '@salescore/client-recoil'
import { ButtonWithTooltip, useModalAtom } from '@salescore/frontend-common'
import { Button, Dropdown, type MenuProps, notification, Popconfirm, Space } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useEffect, useState } from 'react'

import { VIEW_NEW_RECORD_PREFIX } from '../../../domain/constant'
import { convertLeadFormModalAtom } from '../../../recoil/navigation/atoms'
import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { useIsSavingState, usePickedIds } from '../../../recoil/records/hooks'
import { useRecordsAndChangesMutation } from '../../../recoil/records/mutations/recordsAndChangesMutation'
import {
  useMeValue,
  useQueryValue,
  useViewAbilityValue,
  useViewConfigSheet,
  useViewsContextValue,
} from '../../../recoil/view/hooks'

export function ViewNotification(): ReactNode {
  const [api, contextHolder] = notification.useNotification()
  const [pickedIds, setPickedIds] = usePickedIds()
  const sheet = useViewConfigSheet()
  const isSheetForLead = sheet.tree?.modelName === 'salesforce_lead' // XX: ベタ書きしている
  const convertLeadFormModal = useModalAtom(convertLeadFormModalAtom)

  useEffect(() => {
    if (convertLeadFormModal.isModalVisible) {
      api.destroy('ViewNotification')
      return
    }

    if (pickedIds.isPresent()) {
      api.open({
        key: 'ViewNotification',
        message: (
          <div className="flex w-full items-center justify-between align-middle">
            <span className="text-sm text-gray-600">
              <span className="mr-1 text-lg font-bold text-black">{pickedIds.length}</span>
              {t(`件を選択中`)}
            </span>
            <Space>
              {isSheetForLead && (
                <Button
                  type="text"
                  icon={<PlayCircleOutlined />}
                  style={{
                    color: CONSTANT.colors.primaryColor,
                  }}
                  onClick={() => {
                    convertLeadFormModal.showModal({
                      leadIds: pickedIds,
                      onFinish: () => {
                        setPickedIds([])
                      },
                    })
                  }}
                >
                  {t(`取引を開始`)}
                </Button>
              )}
              <DestroyButton />
            </Space>
          </div>
        ),
        placement: 'bottom',
        duration: 0,
        closeIcon: <></>,
        className: 'notification-without-close-button',
        style: {
          width: 400,
          // 元のnotificationの余白が微妙なので手動調整
          padding: '8px 8px 0 24px',
        },
      })
    } else {
      api.destroy('ViewNotification')
    }
  }, [pickedIds.length, convertLeadFormModal.isModalVisible])

  return <>{contextHolder}</>
}

function DestroyButton(): ReactNode {
  const me = useMeValue()
  const { syncUserPlanNotFoundModal } = useNavigationModal()
  const [pickedIds] = usePickedIds()
  const isSaving = useIsSavingState()
  const recordsAndChangesMutation = useRecordsAndChangesMutation()
  const [, existIds] = pickedIds.partition((id) => id.startsWith(VIEW_NEW_RECORD_PREFIX))
  const syncEnabled = checkSyncEnabled(me.myUser)
  const ability = useViewAbilityValue()
  const { setIsChangedDrillDownModal } = useViewsContextValue()

  const deleteRecords = (isDeleteAllChildren?: boolean): void => {
    if (syncEnabled) {
      void recordsAndChangesMutation.destroyPickedRecords(isDeleteAllChildren) // confirmを早めに閉じるためにvoidにしている。エラーハンドリングをこのレイヤーでやっていないのでこれで問題ないはずだが、やや不安

      if (isSome(setIsChangedDrillDownModal)) {
        setIsChangedDrillDownModal(true)
      }
    } else {
      syncUserPlanNotFoundModal.showModal()
    }
  }

  if (!ability.canSaveRecord || !ability.canDeleteRecord) {
    return (
      <ButtonWithTooltip
        className="flex items-center gap-1"
        type="primary"
        shape="round"
        danger
        disabled
        tooltipTitle={t(`権限がないため、削除はできません。`)}
      >
        {t(`削除`)}
        <DownOutlined style={{ fontSize: 10 }} />
      </ButtonWithTooltip>
    )
  }

  if (existIds.length > 0) {
    return (
      <DropdownDeleteButton
        loading={isSaving.isTrue}
        hasConfirm
        onDelete={() => {
          deleteRecords()
        }}
        onDeleteWithAllChildren={() => {
          deleteRecords(true)
        }}
      />
    )
  }

  return (
    <DropdownDeleteButton
      loading={isSaving.isTrue}
      onDelete={() => {
        deleteRecords()
      }}
      onDeleteWithAllChildren={() => {
        deleteRecords(true)
      }}
    />
  )
}

function DropdownDeleteButton({
  loading,
  hasConfirm,
  onDelete,
  onDeleteWithAllChildren,
}: {
  loading: boolean
  hasConfirm?: boolean
  onDelete: () => void
  onDeleteWithAllChildren: () => void
}): ReactNode {
  const query = useQueryValue()
  const [open, setOpen] = useState(false)

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <DropdownDeleteButtonMenu label={t('レコードの削除')} hasConfirm={hasConfirm} onDelete={onDelete} />,
    },
    isSome(query.tree.children) && !query.tree.children.isBlank()
      ? {
          key: '2',
          label: (
            <DropdownDeleteButtonMenu
              label={t('レコードの削除(子孫含む)')}
              hasConfirm={hasConfirm}
              onDelete={onDeleteWithAllChildren}
            />
          ),
        }
      : undefined,
  ].compact()

  return (
    <Dropdown
      menu={{ items }}
      placement="top"
      trigger={['click']}
      dropdownRender={(item) => <div className="-translate-y-2">{item}</div>}
      open={open}
      onOpenChange={(open, info) => {
        if (info.source === 'trigger' || open) {
          setOpen(open)
        }
      }}
    >
      <Button className="flex items-center gap-1" danger type="primary" shape="round" loading={loading}>
        {t(`削除`)}
        <DownOutlined style={{ fontSize: 10 }} />
      </Button>
    </Dropdown>
  )
}

function DropdownDeleteButtonMenu({
  label,
  hasConfirm,
  onDelete,
}: {
  label: string
  hasConfirm?: boolean
  onDelete: () => void
}): ReactNode {
  return isTruthy(hasConfirm) ? (
    <Popconfirm
      onConfirm={onDelete}
      title={
        <div>
          {t(`本当に削除しますか？(この操作は取り消せません。`)}
          <br />
          {t(`また、変更中の項目はすべてクリアされます)`)}
        </div>
      }
      okText={t(`削除`)}
      cancelText={t(`キャンセル`)}
    >
      <span className="inline-block w-full text-red-500">{label}</span>
    </Popconfirm>
  ) : (
    <span className="inline-block w-full text-red-500" onClick={onDelete}>
      {label}
    </span>
  )
}
