import { DeleteOutlined, TableOutlined } from '@ant-design/icons'
import { isNull, isTruthy } from '@salescore/buff-common'
import type { ViewConfigTreeNode } from '@salescore/core'
import { Button, Form, List, Select, Tooltip } from 'antd'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

type ViewTreeNodeWithParents = ViewConfigTreeNode & {
  parents: ViewConfigTreeNode[]
}

interface KpiFormChildNodeFormItemProperties {
  withLabelIcon?: true
  unDeletableFilter?: (node: ViewConfigTreeNode) => boolean
  onAfterChange?: (picked: ViewTreeNodeWithParents[], notPicked: ViewTreeNodeWithParents[]) => void
  onAfterRemove?: (node: ViewTreeNodeWithParents) => void
}

export const KpiFormChildNodeFormItem = (properties: KpiFormChildNodeFormItemProperties) => {
  const {
    childModelsSelectOptions,
    childModelsSelectOptionGroups,
    currentNodeModels,
    currentSheet,
    setSheetConfig,
    sheet,
    modelSearcher,
    setFormModified,
  } = useKpiFormSelectors()
  const pickedOptions = childModelsSelectOptions.filter((x) => x.existedNode !== undefined)

  return (
    <>
      {/* UIの統一のためForm.Itemを使うが、Formの機能は使わず、nameは指定しない */}
      <Form.Item
        // name={namePath}
        label={
          properties.withLabelIcon ? (
            <div>
              <TableOutlined /> {t(`関連付けするオブジェクト`)}
            </div>
          ) : (
            <div>{t(`関連付けするオブジェクト`)}</div>
          )
        }
        tooltip={
          <div>
            {t(
              `（例）「取引オブジェクト」をベースに選択したとき、会社名を表示するために「会社オブジェクト」を紐づける`,
            )}
          </div>
        }
      >
        <Select
          showSearch
          disabled={currentSheet.tree === undefined}
          filterOption={(input, option) =>
            // antd側のバグっており、anyが必要
            // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
            (((option as any).key as string) || '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(values) => {
            const picked = values.map((value) => childModelsSelectOptions.find((x) => x.value === value)).compact()
            const notPicked = pickedOptions.filter((option) => !values.includes(option.value))
            // updaterパターンが使えない…？
            const tree = currentSheet.tree
            if (tree === undefined) {
              return
            }
            const addedSheet = picked.reduce((accumulator, x) => {
              const result = accumulator.addViewConfigChildNode(x.node, x.referenceToProperty)
              if (!result.success) {
                return accumulator
              }
              const model = modelSearcher.searchModel(result.newNode.modelName)
              if (model === undefined) {
                // modelは見つかるはず
                return result.data
              }
              // modelのデフォルトプロパティを追加する
              return result.data.addDefaultPropertyFields(result.newNode, model, { toLast: true })
            }, sheet)
            const newSheet = notPicked.reduce(
              (accumulator, x) => accumulator.removeChildNode(x.existedNode?.name ?? ''),
              addedSheet,
            )
            setSheetConfig(newSheet.config)
            setFormModified(true)
            // RI用
            // TODO: RIとこのフォームを切り離したい
            properties.onAfterChange?.(
              picked.map((x) => x.node),
              notPicked.map((x) => x.node),
            )
          }}
          options={childModelsSelectOptionGroups.map((x) => ({
            ...x,
            options: x.options.filter((option) => {
              if (isNull(option.existedNode)) {
                return true
              }
              const node: ViewTreeNodeWithParents = { ...option.existedNode, parents: [] }
              const unDeletable = isTruthy(properties.unDeletableFilter?.(node))

              return !unDeletable
            }),
          }))}
          value={pickedOptions.map((x) => x.value)}
          mode="multiple"
          placeholder={`${t(`項目を追加`)}…`}
          tagRender={(properties) => <></>}
          optionRender={(option) => <span className="whitespace-normal break-words ">{option.label}</span>}
        />
      </Form.Item>
      {currentNodeModels.length > 0 && (
        <List
          className="mb-6"
          bordered
          dataSource={currentNodeModels.slice(1)}
          renderItem={(item, index) => {
            const unDeletable = isTruthy(properties.unDeletableFilter?.(item.node))

            return (
              <List.Item
                actions={[
                  <Tooltip
                    key={`node-${item.node.name}-${index}`}
                    title={
                      unDeletable
                        ? t(`{{objectName}}は必須です`, { objectName: item.nodePathAsLabel.slice(1).join(': ') })
                        : undefined
                    }
                    placement="right"
                  >
                    <Button
                      icon={<DeleteOutlined />}
                      disabled={unDeletable}
                      onClick={() => {
                        setSheetConfig(sheet.removeChildNode(item.node.name).config)
                        setFormModified(true)
                        // RI用
                        // TODO: RIとこのフォームを切り離したい
                        properties.onAfterRemove?.(item.node)
                      }}
                      type="text"
                    />
                  </Tooltip>,
                ]}
              >
                {item.nodePathAsLabel.slice(1).join(': ')} <span className="text-gray-400"> - {item.node.name}</span>
              </List.Item>
            )
          }}
        />
      )}
    </>
  )
}
