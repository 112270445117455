import { SaveOutlined } from '@ant-design/icons'
import { isNull } from '@salescore/buff-common'
import { recoil } from '@salescore/client-recoil'
import type { ViewConfigKpiPivot } from '@salescore/core'
import { Alert, Button, Form, Input, message, Modal, Space } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { useKpiPivotNavigationModal } from '../../../recoil/navigation/hooks'
import { useViewsContextValue } from '../../../recoil/view/hooks'

export const KpiPivotFormModal = () => {
  const { kpiPivotFormModal } = useKpiPivotNavigationModal()

  return (
    <Modal
      open={kpiPivotFormModal.isModalVisible}
      onCancel={() => {
        kpiPivotFormModal.hideModal()
      }}
      width={'80%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
        padding: 0,
      }}
      // 現状、常に新規作成？
      title={t(`ダッシュボードの新規作成`)}
      destroyOnClose
      footer={<></>}
    >
      {kpiPivotFormModal.content !== undefined && (
        <KpiPivotForm
          // kpiPivotViewGroupId={kpiPivotFormModal.content?.viewGroupId}
          onFinish={async (viewId: string) => {
            await kpiPivotFormModal.content?.onCreate(viewId)
            kpiPivotFormModal.hideModal()
            void message.success(t(`ダッシュボードを保存しました`))
          }}
        />
      )}
    </Modal>
  )
}

interface FormValue {
  // measureName: string
  name: string
}

function KpiPivotForm({
  // kpiPivotViewGroupId,
  onFinish,
}: {
  // kpiPivotViewGroupId: string
  onFinish: (viewId: string) => void
}) {
  const [isSaving, setIsSaving] = useState(false)
  const { createView, updateView } = useViewsContextValue()
  const canCreateKpiPivot = recoil.global.useCan(`kpi-pivot-create`)

  return (
    <>
      {!canCreateKpiPivot && (
        <Alert className="mb-4" showIcon type="warning" message={t(`権限がないため作成できません`)} />
      )}
      <Form<FormValue>
        wrapperCol={{ span: 8 }}
        onFinish={async (value) => {
          const config: ViewConfigKpiPivot = {
            type: 'kpiPivot',
            kpis: [],
            presets: [],
          }

          try {
            setIsSaving(true)
            const result = await createView({
              // viewGroupId: kpiPivotViewGroupId, // 指定しないことで、デフォルトのルート配下で作成される
              name: value.name,
              config,
            })
            if (isNull(result)) {
              void message.error(t(`エラーが発生しました。`)) // TODO: 発生しないはず？
              return
            }

            onFinish(result.id)
          } catch {
            void message.error(t(`エラーが発生しました。`))
          } finally {
            setIsSaving(false)
          }
        }}
        initialValues={{}}
      >
        <Form.Item name={'name'} label={t(`ダッシュボード名`)} rules={[{ required: true }]}>
          <Input width={200} />
        </Form.Item>

        <Space className="flex flex-row-reverse">
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              icon={<SaveOutlined />}
              loading={isSaving}
              disabled={!canCreateKpiPivot}
            >
              {t(`保存`)}
            </Button>
          </Form.Item>
        </Space>
      </Form>
    </>
  )
}
