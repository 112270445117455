import type { ViewQueryMultiTablePivot } from '../../../../schemas/query'
import type { ViewConfigPivot } from '../../../../schemas/view_config'

export function generateNodeFromDimension(
  pivot: ViewConfigPivot,
  depth: number,
  parentNodeNames: string[],
): ViewQueryMultiTablePivot['tree'] {
  if ((pivot.rows ?? []).isBlank()) {
    return {
      // TODO: 不要な要素が多いので、型を見直したい
      name: `valuesNode`,
      path: [...parentNodeNames, `valuesNode`],
      read: {
        tableSql: '',
        tableType: 'table',
        idColumn: '',
      },
      meta: {
        label: '',
        dependedStreamNames: [],
      },
    }
  }

  const name = `dimensionNode${depth}`
  return {
    // TODO: 不要な要素が多いので、型を見直したい
    name,
    path: [...parentNodeNames, name],
    read: {
      tableSql: '',
      tableType: 'table',
      idColumn: '',
    },
    meta: {
      label: '',
      dependedStreamNames: [],
    },
    children: [
      generateNodeFromDimension({ rows: (pivot.rows ?? []).slice(1), columns: pivot.columns ?? [] }, depth + 1, [
        ...parentNodeNames,
        name,
      ]),
    ],
  }
}
