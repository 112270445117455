import { useQuery } from '@apollo/client'
import { isPresent } from '@salescore/buff-common'
import { FetchSalescoreJwtDocument } from '@salescore/client-api'
import { useSalescoreAuth } from '@salescore/client-base'
import { logger, useRedirect } from '@salescore/frontend-common'
import { useEffect } from 'react'

//
// SalesforceのAppExchangeアプリとしてのSALESCORE（=Webタブ）からSALESCOREを開いた際に、自動でログインさせる仕組み
// Auth0のJWTではなく、SALESCOREのJWTを用いてログインする
//
export const SignInBySalesforceSession = ({
  sessionToken,
  instanceUrl,
}: {
  sessionToken: string
  instanceUrl: string
}) => {
  const fetchSalescoreJwtQuery = useQuery(FetchSalescoreJwtDocument, {
    variables: {
      type: 'salesforceSessionToken',
      sessionToken,
      instanceUrl,
    },
    fetchPolicy: 'no-cache',
  })
  const { setToken } = useSalescoreAuth()
  const redirect = useRedirect()

  useEffect(() => {
    const jwt = fetchSalescoreJwtQuery.data?.salescoreJwt
    if (isPresent(jwt)) {
      setToken(jwt)
      redirect('/')
    }
  }, [fetchSalescoreJwtQuery.data])

  logger.debug(`SignInBySalesforceSession`, {
    sessionToken,
    instanceUrl,
    fetchSalescoreJwtQuery,
  })

  return <></>
}
