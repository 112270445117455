import type { ApolloClient } from '@apollo/client'
import type { MutableSnapshot } from 'recoil'

import { apolloClientAtom, type Me, meAtom, organizationIdAtom } from './atoms'

type InitializeState = ((mutableSnapshot: MutableSnapshot) => void) | undefined

export const initializeState =
  ({
    organizationId,
    client,
    me,
  }: {
    organizationId: string
    client: ApolloClient<unknown>
    me: Me
  }): InitializeState =>
  ({ set }) => {
    set(organizationIdAtom, organizationId)
    set(apolloClientAtom, client)
    set(meAtom, me)
  }
