import type { ViewConfigKpi, ViewConfigSheet } from '../../../../schemas/view_config'

export function addDateFilter(config: ViewConfigSheet, date: ViewConfigKpi['date']): ViewConfigSheet {
  if (date === undefined) {
    return config
  }

  return {
    ...config,
    filterTree: {
      logicalOperator: `and`,
      children: [config.filterTree].compact(),
      leafs: [
        {
          type: 'property' as const,
          property: date.property,
          filterType: 'greater_than_or_equal' as const,
          filterValueParameterName: `startAt`,
        },
        {
          type: 'property',
          property: date.property,
          filterType: 'less_than_or_equal', // もともとの仕様に合わせたが、これが最適か悩ましい
          filterValueParameterName: `endAt`,
        },
      ],
    },
  }
}
