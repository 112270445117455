import { logger } from '@salescore/frontend-common'
import type { DataNode, EventDataNode } from 'antd/es/tree'
import type { NodeDragEventParams } from 'rc-tree/es/contextTypes'
import type { Key } from 'react'

import type { ExpandedAntdNode } from './convertFlatNodesToAntdTree'

interface DfsResult {
  parent: ExpandedAntdNode | undefined
  child: ExpandedAntdNode
  childIndex: number
  children: ExpandedAntdNode[]
}

type DragEvent = NodeDragEventParams & {
  dragNode: EventDataNode<DataNode>
  dragNodesKeys: Key[]
  dropPosition: number
  dropToGap: boolean
}

export const dragAntdNode = (oldTree: ExpandedAntdNode[], info: DragEvent) => {
  // TODO: 非破壊的操作をするために、本来はcloneしたいが、jsxが含まれていると難しい。
  //       とはいえ、返り値のオブジェクトIDは書き換えたいので、一旦トップレベルだけは違うオブジェクトになるよう配列の展開を行う
  const tree = [...oldTree]
  const dragKey = info.dragNode.key // dragされたnodeのkey
  const dropKey = info.node.key // drop先のnodeのkey
  const dropPos = info.node.pos.split('-') // drop先のnodeの何番目に挿入されたか
  const dropPosition = info.dropPosition - Number(dropPos.at(-1))

  // dragしたnodeを探索
  const dragSearchResult = dfs(tree, dragKey)
  if (dragSearchResult === undefined) {
    // ありえないはず
    logger.debug(`ドラッグしたノードが見つかりませんでした。`, info)
    return
  }
  const draggedNode = dragSearchResult.child

  // dropしたnodeを探索
  const dropSearchResultTemporary = dfs(tree, dropKey)
  if (dropSearchResultTemporary === undefined) {
    // ありえないはず
    logger.debug(`ドロップ先ノードが見つかりませんでした。`)
    return
  }

  // 元の位置から削除
  dragSearchResult.children.splice(dragSearchResult.childIndex, 1)

  // ドラッグ要素の削除後に、もう一度dropしたnodeを探索（同一parent内での移動だと、indexが書き変わる。非効率な処理だが、この方が確実なのでこうしている）
  const dropSearchResult = dfs(tree, dropKey)
  if (dropSearchResult === undefined) {
    // ありえないはず
    logger.debug(`ドロップ先ノードが見つかりませんでした。`)
    return
  }
  const droppedNode = dropSearchResult.child

  if (!info.dropToGap) {
    // leafにドロップしたときは、leafと同じ階層に移動させる
    if (droppedNode.isLeaf) {
      logger.debug(`!dropToGap isLeaf`) // 他のディレクトリにビューをドロップしたとき？条件わからず
      dropSearchResult.children.splice(dropSearchResult.childIndex + 1, 0, draggedNode)
      return {
        tree,
        target: draggedNode,
        siblings: dropSearchResult.children,
        parent: dropSearchResult.parent,
      }
    } else {
      logger.debug(`!dropToGap is not Leaf`) // ディレクトリ配下にドロップしたとき全て？条件がわかっていない
      // nodeにドロップしたときは、nodeのchildrenに移動させる
      droppedNode.children = droppedNode.children ?? []
      droppedNode.children.unshift(draggedNode)
      return {
        tree,
        target: draggedNode,
        siblings: droppedNode.children,
        parent: droppedNode,
      }
    }
  } else if (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-member-access
    ((info as any).node.props.children || []).length > 0 && // Has children
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-explicit-any
    (info as any).node.props.expanded && // Is expanded
    dropPosition === 1 // On the bottom gap
  ) {
    // leafにドロップしたときは、leafと同じ階層に移動させる
    if (droppedNode.isLeaf) {
      logger.debug(`has children, is expanded, on the bottom gap isLeaf`) // 条件不明
      dropSearchResult.children.splice(dropSearchResult.childIndex + 1, 0, draggedNode)
      return {
        tree,
        target: draggedNode,
        siblings: dropSearchResult.children,
        parent: dropSearchResult.parent,
      }
    } else {
      logger.debug(`has children, is expanded, on the bottom gap is not Leaf`) // 条件不明
      // nodeにドロップしたときは、nodeのchildrenに移動させる
      droppedNode.children = droppedNode.children ?? []
      droppedNode.children.unshift(draggedNode)
      return {
        tree,
        target: draggedNode,
        siblings: droppedNode.children,
        parent: droppedNode,
      }
    }
  } else if (dropPosition === -1) {
    logger.debug(`dropPosition === -1`) // ルートの最上段にdropしたとき
    dropSearchResult.children.splice(dropSearchResult.childIndex, 0, draggedNode)
    return {
      tree,
      target: draggedNode,
      siblings: dropSearchResult.children,
      parent: dropSearchResult.parent,
    }
  } else {
    logger.debug(`else`) // それ以外、通常の並び替え。普通に一覧の中から一覧の中など
    dropSearchResult.children.splice(dropSearchResult.childIndex + 1, 0, draggedNode)
    return {
      tree,
      target: draggedNode,
      siblings: dropSearchResult.children,
      parent: dropSearchResult.parent,
    }
  }
}

function dfs(
  children: ExpandedAntdNode[],
  id: string | number | bigint,
  parent?: ExpandedAntdNode,
): DfsResult | undefined {
  // 全ての子ツリーについて探索
  for (let index = 0; index < children.length; index++) {
    const child = children[index]
    if (child === undefined) {
      continue
    }
    // 対象のキーだったらcallbackを発火
    if (child.key === id) {
      return {
        parent,
        child,
        childIndex: index,
        children,
      }
    }
    const nextChildren = child.children

    // 深さ優先探索
    if (nextChildren === undefined) {
      continue
    }

    const result = dfs(nextChildren, id, child)
    if (result !== undefined) {
      return result
    }
  }
  return undefined
}
