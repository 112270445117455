import { DeleteOutlined } from '@ant-design/icons'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import {
  FetchAllowedIpsDocument,
  type OrganizationFieldsFragment,
  UpsertAllowedIpsDocument,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { Button, Col, Empty, Form, Input, message, Popconfirm, Row, Typography } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { type ReactNode, useState } from 'react'
import { Trans } from 'react-i18next'

interface FormValue {
  id?: string
  ip: string
  description?: string
}

const IP_V4_ADDRESS_REGEXP =
  /^(([01]?\d{1,2}\.)|(2[0-4]\d\.)|(25[0-5]\.)){3}(([01]?\d{1,2})|(2[0-4]\d)|(25[0-5]))(\/\d\d?)?$/
const IP_V6_ADDRESS_REGEXP =
  // eslint-disable-next-line regexp/no-dupe-disjunctions
  /^(([\dA-Fa-f]{1,4}:){7}[\dA-Fa-f]{1,4}|([\dA-Fa-f]{1,4}:){1,7}:|([\dA-Fa-f]{1,4}:){1,6}:[\dA-Fa-f]{1,4}|([\dA-Fa-f]{1,4}:){1,5}(:[\dA-Fa-f]{1,4}){1,2}|([\dA-Fa-f]{1,4}:){1,4}(:[\dA-Fa-f]{1,4}){1,3}|([\dA-Fa-f]{1,4}:){1,3}(:[\dA-Fa-f]{1,4}){1,4}|([\dA-Fa-f]{1,4}:){1,2}(:[\dA-Fa-f]{1,4}){1,5}|[\dA-Fa-f]{1,4}:((:[\dA-Fa-f]{1,4}){1,6})|:((:[\dA-Fa-f]{1,4}){1,7}|:)|fe80:(:[\dA-Fa-f]{0,4}){0,4}%[\dA-Za-z]+|::(ffff(:0{1,4})?:)?((25[0-5]|(2[0-4]|1?\d)?\d)\.){3}(25[0-5]|(2[0-4]|1?\d)?\d)|([\dA-Fa-f]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1?\d)?\d)\.){3}(25[0-5]|(2[0-4]|1?\d)?\d))/
// eslint-disable-next-line regexp/no-useless-assertions
const IP_V4_OR_V6_ADDRESS_REGEXP = new RegExp(`^(${IP_V4_ADDRESS_REGEXP.source})|(${IP_V6_ADDRESS_REGEXP.source})$`)

export function AllowedIpsForm({ organization }: { organization: OrganizationFieldsFragment }): ReactNode {
  const [form] = useForm<{ allowedIps: FormValue[] }>()
  const {
    data: { allowedIps },
    refetch,
  } = useSuspenseQuery(FetchAllowedIpsDocument, {
    variables: {
      organizationId: organization.id,
    },
  })
  const [upsertAllowedIpsMutation] = useMutation(UpsertAllowedIpsDocument)
  const [isSaveDisabled, setIsSaveDisabled] = useState(true)

  const onFinish = (value: { allowedIps: FormValue[] }): void => {
    void upsertAllowedIpsMutation({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        allowedIps: [
          ...value.allowedIps.map((x) => ({
            id: x.id,
            ip: x.ip,
            description: x.description,
          })),
          ...allowedIps
            .filter((x) => !value.allowedIps.map((y) => y.id).includes(x.id))
            .map((x) => ({
              id: x.id,
              ip: x.ip,
              description: x.description,
              deleted: true,
            })),
        ],
      },
      onCompleted: () => {
        void refetch()
        setIsSaveDisabled(true)
        void message.info(t(`更新しました`))
      },
      onError: (error) => {
        void message.error(`${t(`エラーが発生しました。`)}${error.name}: ${error.message}`)
      },
    })
  }

  return (
    <div key={JSON.stringify(allowedIps)}>
      <Typography.Title level={4}>{t(`IPアドレス制限`)}</Typography.Title>
      <div className="mb-4">{t(`利用できるIPアドレスを制限したい場合、CIDR形式でIPアドレスを入力してください。`)}</div>
      <Form
        form={form}
        initialValues={{ allowedIps }}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={() => {
          setIsSaveDisabled(false)
        }}
      >
        <Form.List name="allowedIps">
          {(fields, { add, remove }) => (
            <>
              {fields.length === 0 && <Empty description={t(`現在、IPアドレス制限を行なっていません`)} />}
              {fields.map((field, index) => (
                <>
                  <Row gutter={8} align="bottom">
                    <Col span={8}>
                      <Form.Item
                        name={[field.name, 'ip']}
                        label="IP"
                        required={true}
                        rules={[
                          {
                            pattern: IP_V4_OR_V6_ADDRESS_REGEXP,
                            required: true,
                            message: t(`CIDR表記で記述してください`),
                          },
                        ]}
                      >
                        <Input placeholder={`(${t(`例`)}) 0.0.0.0/0`} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item name={[field.name, 'description']} label={t(`説明`)} required={false}>
                        <Input placeholder={t(`(例) オフィスWiFiのIPアドレス`)} />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => {
                            remove(index)
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              ))}
              <Row className="mt-4" gutter={8}>
                <Col>
                  <Button
                    onClick={() => {
                      add()
                    }}
                  >
                    {t(`追加`)}
                  </Button>
                </Col>
                <Col>
                  <Popconfirm
                    title={
                      <div>
                        <Trans>
                          IPアドレス制限を行うことにより、現在利用中のユーザーが
                          <br />
                          SALESCOREを利用できなくなることがあります。
                          <br />
                          上記のIPアドレスのみにアクセスを制限して本当によろしいですか？
                        </Trans>
                      </div>
                    }
                    onConfirm={form.submit}
                  >
                    <Button type="primary" disabled={isSaveDisabled}>
                      {t(`保存`)}
                    </Button>
                  </Popconfirm>
                </Col>
              </Row>
            </>
          )}
        </Form.List>
      </Form>
    </div>
  )
}
