import { Form, Input } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

export const KpiFormNameFormItem = () => {
  const { form, setForm, setFormModified } = useKpiFormSelectors()
  const [kpiName, setKpiName] = useState(form.name)

  useEffect(() => {
    setKpiName(form.name)
  }, [form.name])

  return (
    <>
      <Form.Item data-e2e="kpi-form-name-form-item-kpi-name" label={t(`KPI名`)} required>
        <Input
          width={200}
          value={kpiName}
          onChange={(e) => {
            setKpiName(e.target.value)
            setFormModified(true)
          }}
          // onChangeでsetFormを呼ぶと一文字入力する度に各種Selector等の再計算が走ってしまい
          // 非常に重くなるので更新のタイミングをonBlurにしている
          onBlur={(e) => {
            setForm((oldValue) => ({ ...oldValue, name: e.target.value }))
          }}
        />
      </Form.Item>
    </>
  )
}
