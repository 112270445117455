import type { ViewQueryRecordNode } from '@salescore/core'
import { wrapKeyboardEvent } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'
import type { KeyboardEvent } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { useAddEmptyRecordMutation } from '../../../recoil/records/mutations/addEmptyRecordMutation'
import { useRecordsAndChangesMutation } from '../../../recoil/records/mutations/recordsAndChangesMutation'
import { isPastingAtom } from '../../../recoil/view/atoms'
import { getCellRelated } from '../../util/getCellValue'
import { openSheetThreadsFormAtom } from '../atoms'
import { usePaste } from '../hooks/usePaste'
import { useColumnsValue, useRowsValue } from '../models/propModels'
import { useCursorSelector } from '../selectors/cursorSelector'
import { useCursorMutation } from './useCursorMutation'

export const useAsyncHandleKeyDown = () => {
  const { cursor } = useCursorSelector()
  const recordsAndChangesMutation = useRecordsAndChangesMutation()
  const paste = usePaste()
  const { addRootRecord, addChildRecord } = useAddEmptyRecordMutation()
  const columns = useColumnsValue()
  const rows = useRowsValue()
  const column = cursor?.main.columnIndex === undefined ? undefined : columns[cursor.main.columnIndex]
  const cursorMutation = useCursorMutation()
  const openSheetThreadsForm = useRecoilValue(openSheetThreadsFormAtom)
  const setIsPasting = useSetRecoilState(isPastingAtom)

  return async (event: KeyboardEvent<HTMLDivElement>) => {
    if (cursor?.editing?.isEditing === true || openSheetThreadsForm.length > 0) {
      return
    }

    const wrapedEvent = wrapKeyboardEvent(event)

    switch (event.key) {
      case 'Enter': {
        if (cursor === undefined || column === undefined) {
          return
        }
        if (wrapedEvent.commandKey) {
          if (column.nodeType === 'children') {
            // eslint-disable-next-line @typescript-eslint/init-declarations
            let recordNode: ViewQueryRecordNode | undefined
            await addChildRecord({
              rowIndex: cursor.main.rowIndex,
              innerRowIndex: cursor.main.innerRowIndex,
              node: column.node,
              onAfterInsert(x) {
                recordNode = x // recoilのstate更新はpure functionである必要があり、ここで直接setCursorを呼ぶことができないため、汚い実装だがこの形で更新したレコードを取得する
              },
            })
            if (recordNode !== undefined) {
              cursorMutation.setCursor((x) => ({
                main: {
                  ...cursor.main,
                  innerRowIndex: recordNode!.meta.innerRowIndexStart,
                },
                copying: x?.copying,
              }))
            }
          } else if (column.node.path.length <= 1) {
            const addedRowIndex = await addRootRecord({ rowIndex: cursor.main.rowIndex })
            if (addedRowIndex === undefined) {
              // 中断されたということのはず
              return
            }
            cursorMutation.setCursor((x) => ({
              main: {
                ...cursor.main,
                rowIndex: addedRowIndex,
              },
              copying: x?.copying,
            }))
          }
        }
        // 単なるEnterのハンドリングは基本的にuseHandleKeyDownで行うが、空セルでEnterした際の挙動だけここで行う
        else if (column.nodeType === 'children' || column.nodeType === 'child') {
          const { recordNode } = getCellRelated(columns, rows, cursor.main)
          if (recordNode === undefined) {
            // TODO: 共通化
            await addChildRecord({
              rowIndex: cursor.main.rowIndex,
              innerRowIndex: cursor.main.innerRowIndex,
              node: column.node,
            })
          }
        }
        event.preventDefault()
        break
      }
      case 's': {
        if (wrapedEvent.commandKey) {
          try {
            await recordsAndChangesMutation.onSave()
          } catch (error) {
            if (error instanceof Error) {
              void message.error(error.message)
            } else {
              void message.error(t('エラーが発生しました'))
            }
          }
          event.preventDefault()
        }
        break
      }
      case 'v': {
        if (wrapedEvent.commandKey) {
          await paste().finally(() => {
            void message.info(t(`ペーストが完了しました`))
            setIsPasting(false)
          })
          event.preventDefault()
        }
        break
      }
    }
  }
}
