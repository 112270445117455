import type { OAuthExtraConfig } from '@salescore/client-common'
import { Collapse, Form, Input } from 'antd'
import { t } from 'i18next'
import type { Dispatch, SetStateAction } from 'react'

export function SalesforceSourceForm({
  setExtraConfig,
}: {
  setExtraConfig: Dispatch<SetStateAction<OAuthExtraConfig>>
}) {
  return (
    <Collapse defaultActiveKey={[]} className="mb-4">
      <Collapse.Panel header={t(`高度な設定`)} key="detail">
        <Form layout="vertical" validateTrigger="onChange">
          <Form.Item
            name="url"
            label={t(`※Sandbox環境を連携する場合、Sandbox環境のログインURL`)}
            rules={[
              {
                pattern: /^https:\/\/[\d.a-z-]*.com$/,
                //  ラベルに:が入ってしまうので一旦保留
                message: `https://<インスタンス名>salesforce.com`,
              },
            ]}
          >
            <Input
              placeholder={`(${t(`例`)}) https://salescore--salescore.my.salesforce.com`}
              onChange={(e) => {
                setExtraConfig((oldValue) => ({
                  ...oldValue,
                  authorizeUrl: `${e.target.value}/services/oauth2/authorize`,
                }))
              }}
            />
          </Form.Item>
          <Form.Item label={t(`自組織の接続アプリケーションを利用する場合、クライアントID`)}>
            <Input
              placeholder=""
              onChange={(e) => {
                setExtraConfig((oldValue) => ({
                  ...oldValue,
                  clientId: e.target.value,
                }))
              }}
            />
          </Form.Item>
          <Form.Item label={t(`自組織の接続アプリケーションを利用する場合、クライアントシークレット`)}>
            <Input
              placeholder=""
              onChange={(e) => {
                setExtraConfig((oldValue) => ({
                  ...oldValue,
                  clientSecret: e.target.value,
                }))
              }}
            />
          </Form.Item>
        </Form>
      </Collapse.Panel>
    </Collapse>
  )
}
