import { DeleteOutlined, HolderOutlined } from '@ant-design/icons'
import { HUB_PROPERTY_TYPE_ICONS } from '@salescore/client-common'
import { SortableFlexElement } from '@salescore/frontend-common'
import { Button, Row, Space } from 'antd'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

export const KpiFormSheetFieldsFormItemSorter = () => {
  const { currentFields, sheet, setSheet, setFormModified } = useKpiFormSelectors()

  return (
    <div>
      <div className="mb-3">
        <div className="font-bold">{t(`選択した項目`)}</div>
      </div>

      <SortableFlexElement
        axis="y"
        onChange={(items) => {
          setSheet(sheet.set({ fields: items.map((x) => x.meta.field) }))
          setFormModified(true)
        }}
        items={currentFields.map((field) => ({
          key: JSON.stringify(field.field.property), // TODO
          meta: field,
          render() {
            return (
              <Row className="mb-1 rounded bg-white px-2 py-1" justify="space-between">
                <Space>
                  {<HolderOutlined />}
                  {HUB_PROPERTY_TYPE_ICONS[field.property.type]}
                  <span>
                    {field.node.nodePathAsLabel.join(': ')}: {field.property.label}
                  </span>
                </Space>
                <Space>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={() => {
                      setSheet(sheet.removePropertyField(field.field.property))
                      setFormModified(true)
                    }}
                  />
                </Space>
              </Row>
            )
          },
        }))}
      />
    </div>
  )
}
