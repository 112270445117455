import { SettingOutlined } from '@ant-design/icons'
import { Card, Space } from 'antd'
import type { ReactNode } from 'react'

import { handleMe } from '../../presentation/handleMe'

export const AdminCard = ({ children }: { children: ReactNode }): ReactNode =>
  handleMe(({ isAdmin }) => {
    if (!isAdmin) {
      return <></>
    }
    return (
      <Card
        title={
          <Space>
            <SettingOutlined /> <span>管理者用</span>
          </Space>
        }
        className="mt-8 text-xs"
      >
        {children}
      </Card>
    )
  })
