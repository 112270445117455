import type { ViewConfigField, ViewConfigSorter, ViewQueryField } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import { useSetRecoilState } from 'recoil'

import { configSheetAtom } from '../atoms'
import { isSameField, isSameNodeProperty } from './field/util'
import { setConfigMutation } from './setConfigMutation'

const setSortersMutation = mutation({
  key: `view/setSortersMutation`,
  set({ get, set }, { sorters }: { sorters: ViewConfigSorter[] }) {
    const config = get(configSheetAtom)

    set(setConfigMutation, {
      ...config,
      sorters,
    })
  },
})

const toggleSorterMutation = mutation({
  key: `view/toggleSorterMutation`,
  set({ get, set }, { field }: { field: ViewQueryField }) {
    const config = get(configSheetAtom)
    const { sorters, fields } = config
    const fieldIndex = (fields ?? []).findIndex((x) => isSameField(x, field))
    if (fieldIndex === -1) {
      return
    }
    const newSortField = (fields ?? [])[fieldIndex]
    if (newSortField === undefined) {
      set(setConfigMutation, {
        ...config,
        sorters: [],
      })
      return
    }

    const existSorter = (sorters ?? []).find((sorter) => isSameNodeProperty(sorter.property, newSortField.property)) // TODO
    const newSorter = existSorter === undefined ? createSorter(newSortField, 'desc') : toggleSorter(existSorter)

    set(setConfigMutation, {
      ...config,
      sorters: [
        newSorter,
        ...(sorters ?? []).filter((sorter) => !isSameNodeProperty(sorter.property, newSortField.property)),
      ].compact(),
    })
  },
})

const setSorterOrderMutation = mutation({
  key: `view/setAscSorterMutation`,
  set({ get, set }, { field, order }: { field: ViewQueryField; order: 'asc' | 'desc' }) {
    const config = get(configSheetAtom)
    const { sorters, fields } = config
    const fieldIndex = (fields ?? []).findIndex((x) => isSameField(x, field))
    if (fieldIndex === -1) {
      return
    }
    const newSortField = (fields ?? [])[fieldIndex]
    if (newSortField === undefined) {
      return
    }

    const newSorter = createSorter(newSortField, order)
    const sameSorter = sorters?.find((sorter) => isSameNodeProperty(sorter.property, newSortField.property))

    set(setConfigMutation, {
      ...config,
      sorters:
        sameSorter === undefined
          ? [...(sorters ?? []), newSorter]
          : (sorters ?? [])
              .map((sorter) => (isSameNodeProperty(sorter.property, newSortField.property) ? newSorter : sorter))
              .compact(),
    })
  },
})

const removeSorterMutation = mutation({
  key: `view/removeSorterMutation`,
  set({ get, set }, { field }: { field: ViewQueryField }) {
    const config = get(configSheetAtom)
    const { sorters, fields } = config
    const fieldIndex = (fields ?? []).findIndex((x) => isSameField(x, field))
    if (fieldIndex === -1) {
      return
    }
    const newSortField = (fields ?? [])[fieldIndex]
    if (newSortField === undefined) {
      return
    }

    set(setConfigMutation, {
      ...config,
      sorters: (sorters ?? [])
        .filter((sorter) => !isSameNodeProperty(sorter.property, newSortField.property))
        .compact(),
    })
  },
})

export const useSetSorterMutations = () => ({
  toggle: useSetRecoilState(toggleSorterMutation),
  sort: useSetRecoilState(setSorterOrderMutation),
  cancel: useSetRecoilState(removeSorterMutation),
  set: useSetRecoilState(setSortersMutation),
})

export function createSorter(field: ViewConfigField, order: 'asc' | 'desc'): ViewConfigSorter {
  return {
    type: 'property',
    property: field.property,
    order,
  }
}

function toggleSorter(sorter: ViewConfigSorter): ViewConfigSorter | undefined {
  if (sorter.order === 'desc') {
    return {
      ...sorter,
      order: 'asc',
    }
  }

  return undefined
}
