import type { ViewUIInput } from '@salescore/core'
import { Input, message } from 'antd'
import { t } from 'i18next'
import { useContext, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { validationErrorVisibilityAtom } from '../../recoil/view/atoms'
import { useViewUIRecordNodeContext } from './contexts'
import { ViewUIContext } from './ViewUIComponent'
import { viewUiValidation } from './viewUiValidation'

export function ViewUIInputC({ component }: { component: ViewUIInput }) {
  const { isEditMode } = useContext(ViewUIContext)
  const { getValue, onChange } = useViewUIRecordNodeContext()
  const value = getValue(component.fieldName) as string
  const validationErrorVisibility = useRecoilValue(validationErrorVisibilityAtom)
  const errors = validationErrorVisibility ? viewUiValidation(value, component) : []
  const [text, setText] = useState<string>(value)

  useEffect(() => {
    setText(value)
  }, [value])

  if (component.textarea === true) {
    return (
      <Input.TextArea
        style={
          errors.isBlank()
            ? {}
            : {
                borderColor: 'red',
              }
        }
        value={text}
        onBlur={(e) => {
          onChange({ fieldName: component.fieldName, value: text })
        }}
        onChange={(e) => {
          setText(e.target.value)
        }}
        autoSize
        readOnly={isEditMode}
      />
    )
  }
  return (
    <Input
      status={errors.isPresent() ? 'error' : undefined}
      value={text}
      onBlur={(e) => {
        onChange({ fieldName: component.fieldName, value: text })
      }}
      onChange={(e) => {
        setText(e.target.value)
      }}
      onClick={() => {
        if (component.readonly === true) {
          void message.warning(t(`この項目は書き込みが禁止されています。`))
        }
      }}
      readOnly={isEditMode || component.readonly}
    />
  )
}
