import { MenuOutlined, MoreOutlined, WarningOutlined } from '@ant-design/icons'
import { isNull } from '@salescore/buff-common'
import type { WaterfallDimension } from '@salescore/core'
import { Button, Dropdown, List } from 'antd'
import { t } from 'i18next'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import type { SetterOrUpdater } from 'recoil'

import { waterfallDimensionGroupOptions, type WaterfallDimensionGroupType } from '../common'
import { WaterfallDimensionPropertySelect } from './WaterfallDimensionPropertySelect'

interface Item {
  value: WaterfallDimensionGroupType
  label: string
  key: string
}

interface WaterfallSortableDimensionListProperties {
  dragging: boolean
  items: Item[]
  waterfallDimensions: WaterfallDimension[] | undefined
  setWaterfallDimensions: SetterOrUpdater<WaterfallDimension[] | undefined>
}

export const WaterfallSortableDimensionList = SortableContainer<WaterfallSortableDimensionListProperties>(
  (properties: WaterfallSortableDimensionListProperties) => (
    <List
      size="small"
      style={{ cursor: properties.dragging ? 'grabbing' : 'grab' }}
      bordered
      dataSource={properties.items}
      renderItem={(item, index) => (
        <WaterfallSortableDimensionItem
          index={index}
          item={item}
          waterfallDimensions={properties.waterfallDimensions}
          setWaterfallDimensions={properties.setWaterfallDimensions}
        />
      )}
    />
  ),
)

interface WaterfallSortableDimensionItemProperties {
  item: Item
  waterfallDimensions: WaterfallDimension[] | undefined
  setWaterfallDimensions: SetterOrUpdater<WaterfallDimension[] | undefined>
}

const WaterfallSortableDimensionItem = SortableElement<WaterfallSortableDimensionItemProperties>(
  (properties: WaterfallSortableDimensionItemProperties) => {
    const currentOption = waterfallDimensionGroupOptions.find((x) => x.value === properties.item.value)
    const currentDimension = properties.waterfallDimensions?.find((x) => x.name === currentOption?.items.first()?.name)

    return (
      <div
        style={{ fontSize: 14, borderBottom: '1px solid #f0f0f0' }}
        className="flex h-12 items-center justify-between pl-3 pr-2 align-middle hover:opacity-75"
      >
        <div className="flex size-full  items-center justify-between">
          <div className="flex items-center gap-3">
            <MenuOutlined />
            <span>{properties.item.label}</span>
          </div>
          {isNull(currentDimension?.property) && (
            <div className="flex items-center gap-1">
              <WarningOutlined style={{ color: '#faad14' }} />
              <span className="text-xs text-[#faad14]">{t('項目が未設定です')}</span>
            </div>
          )}
        </div>
        <Dropdown
          placement="topRight"
          trigger={['click']}
          dropdownRender={(menu) => (
            <div className="translate-x-full translate-y-full">
              <WaterfallDimensionPropertySelect
                option={currentOption}
                dimension={currentDimension}
                setWaterfallDimensions={properties.setWaterfallDimensions}
              />
            </div>
          )}
        >
          <Button type="text" icon={<MoreOutlined />} />
        </Dropdown>
      </div>
    )
  },
)
