import { useMutation } from '@apollo/client'
import { type OrganizationFieldsFragment, UpdateOrganizationDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { Button, Form, message, Row, Switch } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

interface FormValue {
  shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
  shouldIgnoreInactiveSourceUser: boolean
}

export function OrganizationDetailSettingForm({ organization }: { organization: OrganizationFieldsFragment }) {
  const [updateOrganizationMutation] = useMutation(UpdateOrganizationDocument)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <Form
        onFinish={async (values: FormValue) => {
          try {
            await updateOrganizationMutation({
              variables: {
                organizationId: getOrganizationIdFromPath(),
                organization: {
                  setting: {
                    shouldCreateSalesforceOpportunityWhenConvertingLead:
                      values.shouldCreateSalesforceOpportunityWhenConvertingLead,
                    shouldIgnoreInactiveSourceUser: values.shouldIgnoreInactiveSourceUser,
                  },
                },
              },
            })
            void message.success(t(`設定を更新しました`))
            if (values.shouldIgnoreInactiveSourceUser !== organization.setting.shouldIgnoreInactiveSourceUser) {
              void message.info(t(`無効化ユーザーの設定は、次回の同期時に反映されます。`))
            }
          } finally {
            setLoading(false)
          }
        }}
        initialValues={organization.setting}
        layout="horizontal"
      >
        <Form.Item
          name="shouldIgnoreInactiveSourceUser"
          label={t(`[Salesforceのみ] Salesforceの無効化ユーザーを非表示`)}
          rules={[{ required: true, message: t(`入力してください`) }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item
          name="shouldCreateSalesforceOpportunityWhenConvertingLead"
          label={t(`[Salesforceのみ] リードの昇格時、商談を作成する`)}
          rules={[{ required: true, message: t(`入力してください`) }]}
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>

        <Form.Item>
          <Row justify="start">
            <Button type="primary" htmlType="submit" loading={loading}>
              {t(`保存`)}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </>
  )
}
