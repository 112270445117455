import { useMutation, useSuspenseQuery } from '@apollo/client'
import { FetchSourceDocument, UpdateSourceDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { salesforceCredentialsSpec, type SalesforceSpec } from '@salescore/core'
import { App, Button, Form, Space, Switch } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'

export const SalesforceSourceConfigForm = ({
  sourceId,
  onAfterFinish,
}: {
  sourceId: string
  onAfterFinish: () => void
}): ReactNode => {
  const { message } = App.useApp()
  const [updateSourceMutation, { loading }] = useMutation(UpdateSourceDocument)
  const [form] = Form.useForm<NonNullable<SalesforceSpec['option']>>()
  const {
    data: { source },
  } = useSuspenseQuery(FetchSourceDocument, {
    variables: {
      id: sourceId,
      organizationId: getOrganizationIdFromPath(),
    },
    fetchPolicy: 'network-only',
  })
  // 通常の操作では起きない
  if (source.provider !== 'salesforce') {
    throw new Error(`source.providerがsalesforceではありません: ${source.provider}`)
  }
  const parsed = salesforceCredentialsSpec.safeParse(source.config)
  // 通常の操作では起きない
  if (!parsed.success) {
    throw new Error(`source.configが適切ではありません: ${parsed.error.message}`)
  }
  return (
    <Form
      form={form}
      initialValues={{ fetchInactiveUser: parsed.data.option?.fetchInactiveUser }}
      onFinish={(value) => {
        const config: SalesforceSpec = {
          ...parsed.data,
          option: {
            fetchInactiveUser: value.fetchInactiveUser,
          },
        }
        void updateSourceMutation({
          variables: {
            id: source.id,
            organizationId: getOrganizationIdFromPath(),
            config,
          },
          onCompleted: () => {
            message.success(t('更新しました'))
            onAfterFinish()
          },
        })
      }}
    >
      <Form.Item name="fetchInactiveUser" label="無効化ユーザーを取得する" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item className="text-right">
        <Space>
          <Button
            onClick={() => {
              onAfterFinish()
            }}
          >
            {t('閉じる')}
          </Button>
          <Button type="primary" htmlType="submit" loading={loading}>
            {t('保存')}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  )
}
