import { isNull, isPresent, isTruthy } from '@salescore/buff-common'
import type { ViewKpiAppearance } from '@salescore/core'
import { Empty } from 'antd'

import {
  calculateUnit,
  numberWithDelimiterFilter,
  numberWithFixedDecimal,
  unitLabel as getUnitLabel,
} from '../../misc/filters'
import { assignColors } from './assignColors'
import {
  type ChartLabelDisplayModeValue,
  type ChartLegendsDisplayModeValue,
  EChart,
  type EChartProperties,
} from './EChart'

export const EChartPie = (
  properties: Omit<EChartProperties, 'option'> & {
    records: Array<Record<string, unknown>>
    ratioKey: string
    valueKey: string
    groupKey: string
    ratioLabelKey?: string
    groupLabelKey?: string
    groupFilters?: string[]
    valueLabelDisplayMode?: ChartLabelDisplayModeValue
    valueLabelUnitType?: ViewKpiAppearance['unitType']
    valueLabelDecimalPlaceType?: ViewKpiAppearance['decimalPlaceType']
    legendDisplayMode?: ChartLegendsDisplayModeValue
  },
) => {
  const {
    records,
    ratioKey,
    valueKey,
    groupKey,
    ratioLabelKey,
    groupLabelKey,
    groupFilters,
    valueLabelDisplayMode,
    valueLabelUnitType,
    valueLabelDecimalPlaceType,
    legendDisplayMode,
  } = properties
  const keys = records.groupBy((x) => x[ratioKey] as string).keys()
  const colorDict = assignColors(keys)
  const grouped = records
    .filter((x) => {
      if (isNull(groupFilters) || groupFilters.length === 0) {
        return true
      }
      return groupFilters.includes(x[groupKey] as string)
    })
    .groupBy((x) => (x[groupKey] as string) ?? '')
  const keyToValue =
    groupLabelKey === undefined
      ? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        ({} as Record<string, string>)
      : grouped.transformValues((vs) => vs.first()![groupLabelKey]).data

  if (records.isEmpty()) {
    return <Empty />
  }

  return (
    <div>
      {grouped
        .map((groupKeyValue, records) => {
          const data = [
            ...records
              .map((record) => {
                const key = record[ratioKey] as string
                return {
                  value: record[valueKey] as number,
                  name: (record[ratioLabelKey ?? ratioKey] as string) ?? (record[ratioKey] as string),
                  key, // keyという項目は本来不要だが、ドリルダウンのためにデータ型を拡張している
                  groupKey: groupKeyValue,
                  groupLabel: (keyToValue[groupKeyValue] as string) ?? groupKeyValue,
                  itemStyle: {
                    color: colorDict[key],
                  },
                }
              })
              .filter((x) => x.value > 0)
              .sortBy((x) => [x.groupLabel, -1 * x.value]),
          ]
          const series = [
            {
              name: '', // このnameも表示されるが、何を書くべきか？
              type: 'pie' as const,
              radius: '50%',
              data,
              label: {
                show: valueLabelDisplayMode !== 'none',
                formatter: (p: unknown) => {
                  const parameter = p as { name: string; value: number | string; color: string; percent: number }
                  const { name, value, percent } = parameter

                  const displayValue = generateDisplayValue({
                    value,
                    percent,
                    shouldIgnoreSmallValue: true,
                    valueLabelDisplayMode,
                    valueLabelUnitType,
                    valueLabelDecimalPlaceType,
                  })

                  if (!isPresent(displayValue)) {
                    return ''
                  }

                  return `${name}: ${displayValue}`
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ]
          return {
            data,
            series,
            groupKeyValue,
            records,
          }
        })
        .sortBy(({ data, groupKeyValue }) => data.first()?.groupLabel ?? groupKeyValue)
        .map(({ data, series, groupKeyValue, records }) => (
          <div key={groupKeyValue}>
            <EChart
              {...properties}
              height={600}
              option={{
                title: {
                  text: (keyToValue[groupKeyValue] as string) ?? groupKeyValue,
                  // subtext: 'Fake Data',
                  left: 'center',
                },
                tooltip: {
                  trigger: 'item',
                  // https://echarts.apache.org/en/option.html#tooltip.formatter
                  formatter: (p: unknown) => {
                    const parameters = p as { percent: number; name: string; value: number | string; color: string }
                    const displayValue = generateDisplayValue({
                      value: parameters.value,
                      percent: parameters.percent,
                      valueLabelDisplayMode,
                      valueLabelUnitType,
                      valueLabelDecimalPlaceType,
                    })

                    if (!isPresent(displayValue)) {
                      return ''
                    }

                    return `${parameters.name}  ${displayValue}`
                  },
                },
                legend:
                  legendDisplayMode === 'none'
                    ? undefined
                    : {
                        type: 'scroll',
                        orient: 'vertical',
                        left: 'left',
                        // 凡例が多い時に次のグラフのタイトルに被らないように下方向にpaddingを追加
                        padding: [0, 0, 80, 0],
                        textStyle: {
                          width: 200,
                          overflow: 'breakAll',
                        },
                        z: 1,
                      },
                series,
              }}
            />
          </div>
        ))}
    </div>
  )
}

function generateDisplayValue({
  value,
  percent,
  shouldIgnoreSmallValue,
  valueLabelDisplayMode,
  valueLabelUnitType,
  valueLabelDecimalPlaceType,
}: {
  value: number | string
  percent: number
  shouldIgnoreSmallValue?: boolean
  valueLabelDisplayMode?: ChartLabelDisplayModeValue
  valueLabelUnitType?: ViewKpiAppearance['unitType']
  valueLabelDecimalPlaceType?: ViewKpiAppearance['decimalPlaceType']
}) {
  const numberValue = Number(value)

  if (Number.isNaN(numberValue)) {
    return `${value}`
  }

  if (numberValue === 0) {
    return ``
  }

  if (valueLabelDisplayMode !== 'showAll' && isTruthy(shouldIgnoreSmallValue) && percent < 3) {
    return ``
  }

  // eslint-disable-next-line unicorn/no-useless-undefined
  const valueConvertedInUnit = calculateUnit(numberValue, valueLabelUnitType, undefined)
  // eslint-disable-next-line unicorn/no-useless-undefined
  const valueRoundedToDecimal = numberWithFixedDecimal(valueConvertedInUnit, valueLabelDecimalPlaceType, undefined)
  // eslint-disable-next-line unicorn/no-useless-undefined
  const percentRoundedToDecimal = numberWithFixedDecimal(percent, valueLabelDecimalPlaceType, undefined)
  // eslint-disable-next-line unicorn/no-useless-undefined
  const unitLabel = getUnitLabel(numberValue, valueLabelUnitType, undefined)
  const displayUnitLabel = isPresent(unitLabel) ? ' ' + unitLabel : ''

  return `${numberWithDelimiterFilter(valueRoundedToDecimal)}${displayUnitLabel} (${percentRoundedToDecimal}%)`
}
