import { isNull, isSome } from '@salescore/buff-common'
import { KpiIcon } from '@salescore/client-common'
import { getRecordNodeParents, type ViewQueryRecordNode, type ViewQueryRecordNodeWithParent } from '@salescore/core'
import { Tooltip } from 'antd'

import { getKpiHelpText } from '../../../../../recoil/selectors/pivotColumnsSelector'
import { useContextValue } from '../../../../recoil/models/propModels'
import { useCursorSelector } from '../../../../recoil/selectors/cursorSelector'
import { useKpisSelector } from '../../../../recoil/selectors/kpisSelector'
import type { RSheetColumn, RSheetRecordNode } from '../../../../types'
import type { RSheetsCursor } from '../../../../types/CursorTypes'
import type { CellArgument } from '../cellArgument'

export function RowKpiCell({ value, recordNode, column }: CellArgument) {
  const context = useContextValue()
  const { kpisMapper } = useKpisSelector()
  const { cursor, column: cursorColumn, row: cursorRow } = useCursorSelector()

  const kpi = kpisMapper[value as string]
  if (kpi === undefined) {
    return <></>
  }

  const isContextMenuOpen = isSome(cursor?.contextMenuPosition)
  const helpText = getKpiHelpText(kpi)

  const isContextMenuOpenInCurrentCell = () =>
    isContextMenuOpen &&
    isCursorOnCurrentColumn(cursorColumn, column) &&
    isCursorOnCurrentRow(cursor, cursorRow, recordNode)

  // kpi, rowKpiなどはcellBody側でpaddingを無視している
  return (
    <Tooltip
      title={helpText}
      placement="right"
      trigger={['hover']}
      open={
        // コンテキストメニューがこのセルで開いている時はツールチップがコンテキストメニューに重なってしまうので
        // 明示的にopen=falseを指定してtooltipを閉じる
        !isContextMenuOpenInCurrentCell() && undefined
      }
    >
      <div className="flex size-full items-center justify-center align-middle">
        <div className="w-full text-center" style={{ lineHeight: 1.3, marginTop: 2 }}>
          <KpiIcon imageName={kpi.icon} style={{ fontSize: 22 }} />
          <br />
          <span className="w-full" style={{ fontSize: 11, whiteSpace: 'normal', wordBreak: 'break-all' }}>
            {kpi.label}
          </span>
        </div>
      </div>
    </Tooltip>
  )
}

function isCursorOnCurrentColumn(
  cursorColumn?: RSheetColumn<RSheetRecordNode>,
  currentColumn?: RSheetColumn<RSheetRecordNode>,
): boolean {
  if (isNull(cursorColumn) || isNull(currentColumn)) {
    return false
  }

  return cursorColumn.index === currentColumn.index
}

function isCursorOnCurrentRow(
  cursor?: RSheetsCursor,
  cursorRow?: ViewQueryRecordNode,
  currentNode?: ViewQueryRecordNodeWithParent,
): boolean {
  if (isNull(cursor) || isNull(cursorRow) || isNull(currentNode)) {
    return false
  }

  const parents = getRecordNodeParents(currentNode)
  const rootNode = parents.at(-1)

  return rootNode?.id === cursorRow.id && currentNode.meta.innerRowIndexStart === cursor.main.innerRowIndex
}
