import { PlusOutlined } from '@ant-design/icons'
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { isNull } from '@salescore/buff-common'
import { Button } from 'antd'
import { type Dispatch, type SetStateAction, useMemo } from 'react'

import { KANBAN_GROUP_WIDTH, type KanbanGroup, KanbanGroupComponent } from './KanbanGroup'

// FYI https://master--5fc05e08a4a65d0021ae0bf2.chromatic.com/?path=/story/presets-sortable-multiple-containers--basic-setup
export function Kanban<G, I>(arguments_: {
  groups: Array<KanbanGroup<G, I>>
  setGroups: Dispatch<SetStateAction<Array<KanbanGroup<G, I>>>>
  addGroup: () => void
  addItem: (groupId: string) => void
  editGroup: (groupId: string, groupName: string) => void
  addGroupLabel: string
  addItemLabel: string
  checkItem?: (groupId: string, itemId: string, checked: boolean) => void
}) {
  // click event を拾うため設定 https://github.com/clauderic/dnd-kit/issues/591#issuecomment-1017050816
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 1 } }))
  const { groups, setGroups, addGroup, addGroupLabel, editGroup, checkItem } = arguments_
  const { isItem, isGroup, findGroup } = useMemo(() => {
    const groupIds = new Set(groups.map((x) => x.id))
    const itemIds = new Set(groups.flatMap((group) => group.items).map((x) => x.id))
    const isItem = (id: string) => itemIds.has(id)
    const isGroup = (id: string) => groupIds.has(id)
    const findGroup = (itemIdOrGroupId: string) => {
      if (isGroup(itemIdOrGroupId)) {
        return groups.find((x) => x.id === itemIdOrGroupId)
      }
      return groups.find((group) => group.items.some((item) => item.id === itemIdOrGroupId))
    }

    return {
      isItem,
      isGroup,
      findGroup,
    }
  }, [groups])
  return (
    <div className="kanban-root w-full overflow-x-scroll">
      <div style={{ width: groups.length * KANBAN_GROUP_WIDTH + 300 }}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          // ドラッグが終わった時＝グループ内での並び替え
          onDragEnd={(event) => {
            const { active, over } = event
            if (isNull(over) || active.id === over.id) {
              return
            }

            const overGroup = findGroup(over.id as string)
            const activeGroup = findGroup(active.id as string)
            if (isNull(overGroup) || isNull(activeGroup)) {
              return
            }

            if (isItem(active.id as string)) {
              // activeがアイテムのとき、onDragEndが発火するときは常にoverもアイテムのはず？
              if (!isItem(over.id as string)) {
                return
              }
              if (overGroup.id !== activeGroup.id) {
                return
              }

              setGroups((groups) =>
                groups.map((group) => {
                  if (group.id !== overGroup.id) {
                    return group
                  }
                  return {
                    ...group,
                    items: group.items.move(
                      group.items.findIndex((x) => x.id === active.id),
                      group.items.findIndex((x) => x.id === over.id),
                    ),
                  }
                }),
              )
              return
            }

            if (isGroup(active.id as string)) {
              if (overGroup.id === activeGroup.id) {
                return
              }

              setGroups((groups) =>
                groups.move(
                  groups.findIndex((x) => x.id === activeGroup.id),
                  groups.findIndex((x) => x.id === overGroup.id),
                ),
              )
            }
          }}
          // ドラッグ中の処理（＝グループを跨ぐ処理はここでやる）
          onDragOver={(event) => {
            const { active, over } = event
            if (isNull(over) || active.id === over.id) {
              return
            }

            const overGroup = findGroup(over.id as string)
            const activeGroup = findGroup(active.id as string)

            if (!overGroup || !activeGroup) {
              return
            }

            if (activeGroup !== overGroup) {
              setGroups((groups) =>
                groups.map((group) => {
                  if (group.id === activeGroup.id) {
                    return {
                      ...activeGroup,
                      items: activeGroup.items.filter((item) => item.id !== active.id),
                    }
                  }
                  if (group.id === overGroup.id) {
                    const item = activeGroup.items.find((item) => item.id === active.id)
                    if (item === undefined) {
                      return group
                    }
                    return {
                      ...overGroup,
                      items: [...overGroup.items, item],
                    }
                  }
                  return group
                }),
              )
            }
          }}
        >
          <div className="flex flex-row justify-start p-4">
            <SortableContext items={groups.map((x) => x.id)} strategy={rectSortingStrategy}>
              {groups.map((group) => (
                <KanbanGroupComponent
                  {...arguments_}
                  group={group}
                  onEditGroup={editGroup}
                  onDeleteGroup={(id) => {
                    setGroups((groups) => groups.filter((x) => x.id !== id))
                  }}
                  onDeleteItem={(id) => {
                    setGroups((gorups) =>
                      groups.map((group) => ({
                        ...group,
                        items: group.items.filter((x) => x.id !== id),
                      })),
                    )
                  }}
                  onCheckItem={
                    checkItem === undefined
                      ? undefined
                      : (id, checked) => {
                          checkItem(group.id, id, checked)
                        }
                  }
                />
              ))}
            </SortableContext>
            <div
              style={{
                width: 200,
              }}
              className="flex items-center justify-center rounded-lg border border-dashed border-blue-500 align-middle"
            >
              <Button
                type="text"
                className="my-1 size-full text-center text-blue-500"
                icon={<PlusOutlined />}
                onClick={addGroup}
              >
                {addGroupLabel}
              </Button>
            </div>
          </div>
        </DndContext>
      </div>
    </div>
  )
}
