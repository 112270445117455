import { isNull, isPresent, isSome, toObject } from '@salescore/buff-common'

export function recursiveRemoveEmptyValues(obj: object): object {
  const datas: Array<[string, unknown]> = Object.entries(obj)

  const res = datas
    .map(([key, value]): [string, unknown] | undefined => {
      if (isNull(value)) {
        return undefined
      }

      if (typeof value === 'object' && isSome(value)) {
        const removedEmptyObject = recursiveRemoveEmptyValues(value)

        if (isPresent(Object.keys(removedEmptyObject))) {
          return [key, removedEmptyObject]
        }

        return undefined
      }

      return [key, value]
    })
    .compact()

  return toObject(res)
}
