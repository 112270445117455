import { isPresent, treeUtil } from '@salescore/buff-common'
import { flatNodes } from '@salescore/core'

import type { User } from '../user/types'
import type { UserGroup } from '../user_group/types'
import type { GoalConfig } from './types'

export function getUserOrUserGroupIds({
  goalConfig,
  users,
  userGroups,
}: {
  goalConfig: GoalConfig
  users: User[]
  userGroups: UserGroup[]
}) {
  if (goalConfig.userType === 'none') {
    return []
  }

  if (goalConfig.userType === 'group') {
    return goalConfig.userGroupIds
  }

  // typeがuserのとき、グループが含むユーザーを求める
  const tree = treeUtil.createTreesFromItems({
    items: userGroups,
    getId: (x) => x.id,
    getParentId: (x) => x.user_group_id ?? undefined,
  })
  const flattenNodes = tree.flatMap((x) => flatNodes(x))
  const userGrouopsWithChildren = flattenNodes.filter((x) => goalConfig.userGroupIds.includes(x.item.id))
  const flattenUserGroupsWithChildren = userGrouopsWithChildren.flatMap((x) => flatNodes(x))
  const userGroupIds = new Set(flattenUserGroupsWithChildren.flatMap((x) => x.item.id))

  return [
    ...goalConfig.userIds,
    ...users.filter((user) => userGroupIds.has(user.user_group_id ?? '')).map((x) => x.id),
    // 「所属なし」を指定していれば、以下ロジックで対応
    ...(goalConfig.userGroupIds.includes('no-team')
      ? users.filter((user) => !isPresent(user.user_group_id)).map((x) => x.id)
      : []),
    // kpiTimeSeries の goalConfig の場合は、全ユーザーが対象
    ...(goalConfig.userGroupIds.includes('all') ? users.map((x) => x.id) : []),
  ]
}
