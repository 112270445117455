import {
  CloseOutlined,
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MenuOutlined,
  PlusOutlined,
  ReloadOutlined,
  StarOutlined,
} from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import type { ViewForSiderFieldsFragment } from '@salescore/client-api'
import { CONSTANT } from '@salescore/client-base'
import { recoil } from '@salescore/client-recoil'
import { SortableContainer, useBooleanState, useHover, useOnClickOutside } from '@salescore/frontend-common'
import { App, Badge, Button, Empty, Form, Input, type InputRef, message, Popconfirm, Space, Tooltip } from 'antd'
import { t } from 'i18next'
import { type CSSProperties, Suspense, useEffect, useRef, useState } from 'react'

import { SuspenseWithLoading } from '../SuspenseWithLoading'

interface Tab {
  key: string
  label: string
  icon?: JSX.Element
  iconColor?: string
  closable?: boolean // defaultでtrue
  hasUnsavedChanges: boolean
}

const getStyle = (isActive: boolean): CSSProperties => ({
  height: 40,
  padding: '8px 16px',
  // marginRight: 3,
  cursor: 'pointer',
  color: isActive ? '#4073F8' : '#666',
  backgroundColor: isActive ? 'white' : `#E8EAED`,
  fontWeight: isActive ? 'bold' : 'normal',
  borderTopRightRadius: isActive ? 8 : undefined,
  borderTopLeftRadius: isActive ? 8 : undefined,
  // borderRight: isActive ? undefined : `1px solid #555`,
  // borderBottom: isActive ? `3px solid ${CONSTANT.colors.primaryColor}` : undefined,
  // opacity: isActive ? 1 : 0.7
})

const tabPaddingWidth = 32
const tabMarginWidth = 8
const tabInnerMarginWidth = 100
export const DraggableTabV2 = ({
  tabs,
  views,
  activeKey,
  setKey,
  onAdd,
  onRemove,
  onRemoveAll,
  onSort,
  onMenuClick,
  className,
  children,
  dragDisabled,
}: {
  tabs: Tab[]
  views: ViewForSiderFieldsFragment[]
  activeKey: string
  setKey: (key: string) => void
  onAdd?: () => void
  onRemove?: (key: string) => void
  onRemoveAll?: () => void
  onSort?: (tabs: Tab[]) => Promise<void> | void
  onMenuClick?: () => void // ビューでの利用に特化した引数になってしまうが、現状ビューでしか使ってないので許可する
  className?: string
  children: JSX.Element
  dragDisabled?: boolean
}) => {
  // ソートにより更新があった場合もスムーズにレンダリングするため、内部的にstateを持つ
  const [innerTabs, setInnerTabs] = useState<Tab[]>(tabs)
  const { message } = App.useApp()

  useEffect(() => {
    setInnerTabs(tabs)
    // 中身に変更があった場合、ソート以外の要因でtabsが切り替わった場合に以下を行うべきだが、apollo clientのfetchのロジック的にチラつきが発生するため、一旦行わない
  }, [tabs])

  if (tabs.isBlank()) {
    return (
      <div className="flex size-full items-center justify-center bg-slate-50 align-middle">
        <Empty className="mb-16" description={<span>{t(`左のサイドバーからビューを選択してください。`)}</span>} />
        {onMenuClick !== undefined && (
          <div
            className="absolute left-0 top-0"
            style={{ fontSize: 16, paddingLeft: 12, paddingTop: 7, color: CONSTANT.colors.primaryColor }}
          >
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={onMenuClick}
              style={{ color: CONSTANT.colors.primaryColor }}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className={`draggable-tab overflow-none size-full pt-1 ${className ?? ''}`}
      style={{
        borderBottom: `1px solid ${CONSTANT.colors.border}`,
        backgroundColor: `#E8EAED`,
      }}
    >
      {/* tabs */}
      <div className={`draggable-tab-tabs w-full overflow-x-scroll`} style={{}}>
        {onMenuClick !== undefined && (
          <div
            className="fixed left-0 top-0 z-10"
            style={{
              fontSize: 16,
              paddingLeft: 12,
              paddingTop: 7,
              color: CONSTANT.colors.primaryColor,
              backgroundColor: `#E8EAED`,
            }}
          >
            <Button
              type="text"
              icon={<MenuOutlined />}
              onClick={onMenuClick}
              style={{ color: CONSTANT.colors.primaryColor }}
            />
          </div>
        )}
        <div
          className="draggable-tab-tabs-inner"
          style={{
            width:
              tabs.map((tab) => tabPaddingWidth + tabMarginWidth + tab.label.length * 14).sum() + tabInnerMarginWidth,
            paddingLeft: onMenuClick === undefined ? 0 : 40,
          }}
        >
          {/* sortable tabs */}
          <SortableContainer
            items={innerTabs
              .map((tab) => {
                const view = views.find((v) => v.id === tab.key)
                if (view === undefined) {
                  return
                }
                return {
                  id: tab.key,
                  render: () => (
                    <Suspense>
                      <TabComponent
                        tab={tab}
                        view={view}
                        isActive={tab.key === activeKey}
                        setKey={setKey}
                        onRemove={(key) => {
                          if (onRemove === undefined) {
                            return
                          }

                          const removeTabAction = () => {
                            onRemove(key)
                            setInnerTabs((xs) => xs.filter((x) => x.key !== key))
                          }

                          if (tab.hasUnsavedChanges) {
                            const messageKey = `remove-tab-warning`
                            void message.warning({
                              key: messageKey,
                              content: (
                                <span>
                                  {t(`変更差分がある場合はタブを閉じられません。`)}
                                  <br />
                                  {t(`差分を削除してタブを閉じますか？`)}
                                  <Button
                                    className="ml-2"
                                    onClick={() => {
                                      removeTabAction()
                                      message.destroy(messageKey)
                                    }}
                                  >
                                    {t(`はい`)}
                                  </Button>
                                </span>
                              ),
                              duration: 3,
                            })
                            return
                          }

                          removeTabAction()
                        }}
                        onRemoveAll={() => {
                          if (onRemoveAll === undefined) {
                            return
                          }
                          onRemoveAll()
                          setInnerTabs([])
                        }}
                      />
                    </Suspense>
                  ),
                }
              })
              .compact()}
            onSortEnd={(tabKeys) => {
              const newInnerTabs = [...innerTabs].sort((a, b) => {
                let aIndex = tabKeys.indexOf(a.key)
                let bIndex = tabKeys.indexOf(b.key)
                if (aIndex === -1) {
                  aIndex = tabKeys.length
                }
                if (bIndex === -1) {
                  bIndex = tabKeys.length
                }
                return aIndex - bIndex
              })
              setInnerTabs(newInnerTabs)
              if (onSort !== undefined) {
                void onSort(newInnerTabs)
              }
            }}
            // NOTE: スケールを変えずに横移動だけを許可する
            transformStyle={{
              y: 0,
              scaleX: 1,
              scaleY: 1,
            }}
            direction="horizontal"
          />
          {onAdd !== undefined && (
            <div
              style={getStyle(false)}
              className="hover:opacity-80"
              onClick={() => {
                onAdd()
              }}
            >
              <PlusOutlined />
            </div>
          )}
        </div>
      </div>
      {/* content */}
      <div className="draggable-tab-content" style={{ height: 'calc(100% - 40px)' }}>
        {children}
      </div>
    </div>
  )
}

function TabComponent({
  tab,
  view,
  isActive,
  setKey,
  onRemove,
  onRemoveAll,
}: {
  tab: Tab
  view: ViewForSiderFieldsFragment
  isActive: boolean
  setKey: (key: string) => void
  onRemove?: (key: string) => void
  onRemoveAll?: () => void
}) {
  const canEdit = recoil.global.useCanForView('update', view)
  const reference = useRef<HTMLDivElement>(null)
  const [widthInEditing, setWidthInEditing] = useState<number | undefined>()
  const [isEditing, setIsEditing] = useState(false)
  const [contextMenu, setContextMenu] = useState<TabComponentContextMenuProperties['contextMenu'] | undefined>()

  useEffect(() => {
    if (reference.current) {
      setWidthInEditing(reference.current.clientWidth)
    }
  }, [isEditing])

  const TabComponentMain = useHover((hovered) => (
    <Space
      ref={reference}
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setKey(tab.key)
      }}
      onDoubleClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!canEdit) {
          return
        }
        setIsEditing(true)
      }}
      onContextMenu={(e) => {
        e.preventDefault()
        e.stopPropagation()
        setContextMenu({
          type: 'view',
          position: {
            x: e.clientX,
            y: e.clientY,
          },
          item: view,
        })
      }}
      style={{
        ...getStyle(isActive),
        width: isEditing ? widthInEditing : undefined,
      }}
      className={['relative', 'hover:opacity-80', isEditing ? '[&>.ant-space-item]:w-full' : ''].join(' ')}
    >
      {isEditing ? (
        <TabComponentUpdateForm
          view={view}
          hide={() => {
            setIsEditing(false)
          }}
        />
      ) : (
        <>
          {isSome(tab.icon) && (
            <Badge dot={tab.hasUnsavedChanges} status="warning">
              <span className="rounded p-0.5 text-white" style={{ backgroundColor: tab.iconColor }}>
                {tab.icon}
              </span>
            </Badge>
          )}
          <span>{tab.label}</span>
          {(isActive || hovered) && onRemove !== undefined && tab.closable !== false && (
            <Button
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onRemove(tab.key)
              }}
              icon={<CloseOutlined />}
              type="text"
              size="small"
              style={{ color: isActive ? '#4073F8' : 'rgb(102, 102, 102)' }}
            />
          )}
        </>
      )}
      {!isActive && (
        <div
          className="absolute"
          style={{
            borderRight: `1px solid #919598`,
            height: 20,
            width: 1,
            top: 9,
            right: 0,
          }}
        />
      )}
    </Space>
  ))

  return (
    <>
      {TabComponentMain}
      {contextMenu !== undefined && (
        <SuspenseWithLoading type="none">
          <TabComponentContextMenu
            contextMenu={contextMenu}
            hide={() => {
              setContextMenu(undefined)
            }}
            onRemoveAll={() => {
              if (onRemoveAll === undefined) {
                return
              }
              onRemoveAll()
            }}
          />
        </SuspenseWithLoading>
      )}
    </>
  )
}

interface TabComponentUpdateFormValues {
  name: string
}

interface TabComponentUpdateFormProperties {
  view: ViewForSiderFieldsFragment
  hide: () => void
}

function TabComponentUpdateForm({ view, hide }: TabComponentUpdateFormProperties) {
  const reference = useRef<HTMLDivElement>(null)
  const nameInputReference = useRef<InputRef>(null)
  const [form] = Form.useForm<TabComponentUpdateFormValues>()
  const updateView = recoil.sider.useUpdateView()
  const [loading, setLoading] = useState(false)

  const onFinish = async (v: TabComponentUpdateFormValues) => {
    if (loading || v.name === undefined || v.name.length === 0 || v.name === view.name) {
      hide()
      return
    }
    setLoading(true)
    await updateView(
      {
        id: view.id,
        name: v.name,
      },
      view.type,
    )
    setLoading(false)
    hide()
  }

  useOnClickOutside(reference, async () => {
    // なぜか2回発火してしまうため、先に Component を破棄する
    hide()
    await onFinish(form.getFieldsValue())
  }, ['ant-popover-content'])

  useEffect(() => {
    nameInputReference.current?.focus()
  }, [])

  return (
    <div ref={reference}>
      <Form form={form} initialValues={{ name: view.name }} onFinish={onFinish}>
        <Form.Item name="name" className="!m-0">
          <Input ref={nameInputReference} type="text" size="small" />
        </Form.Item>
      </Form>
    </div>
  )
}

interface TabComponentContextMenuProperties {
  contextMenu: {
    type: 'view'
    position: {
      x: number
      y: number
    }
    item: ViewForSiderFieldsFragment
  }
  hide: () => void
  onRemoveAll: () => void
}

function TabComponentContextMenu({ contextMenu, hide, onRemoveAll }: TabComponentContextMenuProperties) {
  const { type, position, item } = contextMenu
  const { viewFormModal, moveViewOrViewGroupModal } = recoil.sider.useNavigationModal()
  const loading = useBooleanState()
  const copyView = recoil.sider.useCopyView()
  const createFavorite = recoil.sider.useCreateFavorite()
  const deleteFavorite = recoil.sider.useDeleteFavorite()
  const { findFavorite } = recoil.sider.useFavoriteRelated()
  const favorite = findFavorite(item.id, type)
  const reference = useRef<HTMLDivElement>(null)
  const canCreateView = recoil.global.policy.useCanForView(`create`, { type: item.type })

  useOnClickOutside(reference, () => {
    hide()
  }, ['ant-popover-content'])

  if (item.archived) {
    return (
      <div
        ref={reference}
        style={{
          position: 'fixed',
          top: position.y + 14,
          left: position.x,
          backgroundColor: 'white',
          boxShadow: '0 2px 6px 2px rgb(60 64 67 / 15%)',
          borderRadius: 2,
          zIndex: 101,
        }}
      >
        <Button
          type="text"
          className="block w-full text-left"
          icon={<ReloadOutlined />}
          onClick={() => {
            moveViewOrViewGroupModal.showModal({ item, type, action: 'unarchive' })
          }}
        >
          {t(`復旧`)}
        </Button>

        <Suspense
          fallback={
            <Button loading type="text" danger icon={<DeleteOutlined />}>
              {t(`削除`)}
            </Button>
          }
        >
          <DeleteButton contextMenu={contextMenu} hide={hide} />
        </Suspense>

        <CloseAllButton onConfirm={onRemoveAll} />
      </div>
    )
  }

  return (
    <div
      ref={reference}
      style={{
        position: 'fixed',
        top: position.y + 14,
        left: position.x,
        backgroundColor: 'white',
        boxShadow: '0 2px 6px 2px rgb(60 64 67 / 15%)',
        borderRadius: 2,
        zIndex: 101,
      }}
    >
      <Button
        type="text"
        className="block w-full text-left"
        icon={<CopyOutlined />}
        disabled={!canCreateView}
        onClick={async () => {
          try {
            loading.setTrue()
            await copyView(item.id, item.type)
            hide()
          } finally {
            loading.setFalse()
          }
        }}
        loading={loading.isTrue}
      >
        {t(`複製`)}
      </Button>

      <Button
        type="text"
        className="block w-full text-left"
        icon={<EditOutlined />}
        onClick={() => {
          viewFormModal.showModal({ id: item.id, private: item.private })
        }}
      >
        {t(`編集`)}
      </Button>

      <Button
        type="text"
        className="block w-full text-left"
        icon={<StarOutlined />}
        onClick={async () => {
          if (isSome(favorite)) {
            await deleteFavorite(favorite.id)
            void message.success(t(`お気に入りを解除しました`))
          } else {
            await createFavorite({ viewId: item.id })
            void message.success(t(`お気に入りに追加しました`))
          }
          hide()
        }}
      >
        {isSome(favorite) ? t(`お気に入りを解除`) : t(`お気に入り`)}
      </Button>

      {item.private && (
        <Button
          type="text"
          className="block w-full text-left"
          icon={<EyeOutlined />}
          onClick={() => {
            moveViewOrViewGroupModal.showModal({ item, type, action: 'publish' })
          }}
        >
          {t(`公開フォルダに移動`)}
        </Button>
      )}

      <Suspense
        fallback={
          <Button loading type="text" danger icon={<DeleteOutlined />}>
            {t(`ゴミ箱に移動`)}
          </Button>
        }
      >
        <DeleteButton contextMenu={contextMenu} hide={hide} />
      </Suspense>

      <CloseAllButton onConfirm={onRemoveAll} />
    </div>
  )
}

function DeleteButton({
  contextMenu,
  hide,
}: {
  contextMenu: TabComponentContextMenuProperties['contextMenu']
  hide: () => void
}) {
  const { item } = contextMenu
  const id = item.id
  const archiveView = recoil.sider.useArchiveView()
  const { deleteViewOrViewGroupModal } = recoil.sider.useNavigationModal()
  const canDelete = recoil.global.useCanForView('delete', {
    id,
    type: contextMenu.item.type,
    createdById: contextMenu.item.createdById,
  })

  if (!canDelete) {
    return (
      <Tooltip title={t(`権限がないため、削除できません`)}>
        <Button type="text" danger className="block w-full text-left" icon={<DeleteOutlined />} disabled>
          {t(`ゴミ箱に移動`)}
        </Button>
      </Tooltip>
    )
  }

  if (item.archived || item.private) {
    return (
      <Button
        type="text"
        danger
        className="block w-full text-left"
        icon={<DeleteOutlined />}
        onClick={() => {
          deleteViewOrViewGroupModal.showModal(contextMenu)
        }}
      >
        {t(`完全に削除`)}
      </Button>
    )
  }

  return (
    <Popconfirm
      title={<span>{t(`本当にゴミ箱に移動しますか？`)}</span>}
      onConfirm={async () => {
        await archiveView(id, item.type)
        hide()
      }}
    >
      <Button type="text" danger className="block w-full text-left" icon={<DeleteOutlined />}>
        {t(`ゴミ箱に移動`)}
      </Button>
    </Popconfirm>
  )
}

function CloseAllButton({ onConfirm }: { onConfirm: () => void }) {
  return (
    <Popconfirm
      title={
        <>
          <span>{t(`本当に全て閉じますか？`)}</span>
          <br />
          <span>{t('変更差分がある場合は全て削除されます')}</span>
        </>
      }
      onConfirm={onConfirm}
    >
      <Button type="text" className="block w-full text-left" icon={<CloseOutlined />}>
        {t(`全て閉じる`)}
      </Button>
    </Popconfirm>
  )
}
