import {
  ConsoleSqlOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FilterOutlined,
  HighlightOutlined,
  HistoryOutlined,
  LineHeightOutlined,
  LockTwoTone,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  SettingOutlined,
  SortAscendingOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { isNull, isSome, isTruthy } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import { checkSyncEnabled, recoil } from '@salescore/client-recoil'
import { CORE_CONSTANT, type ViewQueryRecordNode, type ViewUISheet } from '@salescore/core'
import {
  ButtonWithTooltip,
  isSharedLinkPresetName,
  organizationHasFeature,
  useMessage,
} from '@salescore/frontend-common'
import { Badge, Button, Dropdown, Menu, Popconfirm, Row, Select, Space, Switch } from 'antd'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { useRecoilState, useRecoilValue } from 'recoil'
import { z } from 'zod'

import type { RowLineNum as RowLineNumber } from '../../../recoil/navigation/atoms'
import { useNavigationModal, useRowHeightState, useSheetPickedPresetName } from '../../../recoil/navigation/hooks'
import { additionalConfigAtom, errorsDrawerOpenAtom, sheetInSaveErrorsAtom } from '../../../recoil/records/atoms'
import {
  useAggregationRecordsValue,
  useChangesValue,
  useIsSavingState,
  usePageSizeState,
  useRecordsValue,
  useViewQueryResult,
} from '../../../recoil/records/hooks'
import { useAddEmptyRecordMutation } from '../../../recoil/records/mutations/addEmptyRecordMutation'
import { useRecordsAndChangesMutation } from '../../../recoil/records/mutations/recordsAndChangesMutation'
import { useRefetchMutation } from '../../../recoil/records/mutations/useRefetchMutation'
import { useHighlightConfig } from '../../../recoil/records/selectors/highlightConfigSelector'
import { useSheetColumns } from '../../../recoil/selectors/useSheetColumns'
import { hasChangeToViewSchemaAtom, isPastingAtom, isSavingCurrentViewAtom } from '../../../recoil/view/atoms'
import {
  useQueriesValue,
  useUiValue,
  useViewAbilityValue,
  useViewsContextValue,
  useViewValue,
} from '../../../recoil/view/hooks'
import { useViewMutation } from '../../../recoil/view/mutations'
import { useFieldMutations } from '../../../recoil/view/mutations/fieldMutations'
import { useMetaMutations } from '../../../recoil/view/mutations/metaMutations'
import { useCachedViewsSelector } from '../../../recoil/view/selectors/cachedViewSelector'
import { useConfigSheetSelector } from '../../../recoil/view/selectors/configSheetSelector'
import { useMeRelatedSelector } from '../../../recoil/view/selectors/meRelatedSelector'
import { useQuerySelector } from '../../../recoil/view/selectors/querySelector'
import { useCursorMutation } from '../../../rsheet/recoil/mutations/useCursorMutation'
import type { RSheetColumn } from '../../../rsheet/types'
import { ViewUIErrorDrawer } from '../ViewUIErrorDrawer'
import { CompileErrorsButton } from '../ViewUIKpiPivot/CompileErrorsButton'
import { PeriodPicker } from '../ViewUIKpiPivot/PeriodPicker'
import { SearchQueryInput } from './SearchQueryInput'
import { SheetMoreActionsButton } from './SheetMoreActionsButton'
import { ViewJoinFiltersSettingModal } from './SheetNavigation/filter/ViewJoinFiltersSettingModal'
import { ImportSourceRecordsButton } from './SheetNavigation/ImportButton'
import { ImportFieldsModal } from './SheetNavigation/ImportFieldsModal'
import { SearchSqlFormModal } from './SheetNavigation/SearchSqlFormModal'
import { SheetPresetFormModal } from './SheetNavigation/SheetPresetFormModal'
import { SheetPresetsPicker } from './SheetNavigation/SheetPresetsPicker'
import { ViewQueryFiltersModal } from './SheetNavigation/ViewQueryFiltersModal'
import { ViewQueryNodeDetailModal } from './SheetNavigation/ViewQueryNodeDetailModal'
import { ViewQuerySortersModal } from './SheetNavigation/ViewQuerySortersModal'

export const SheetNavigation = ({ component }: { component: ViewUISheet }) => {
  const { clearTargetViewQueryCaches } = recoil.sider.useClearViewQueryCacheMutation()
  const { selectedDateForDiffHighlightOption, dateForDiffHighlightOptions, setHighlightConfig } = useHighlightConfig()
  const { addRootRecord } = useAddEmptyRecordMutation()
  const [pageSize, setPageSize] = usePageSizeState()
  const view = useViewValue()
  const { setConfigWithoutChange, setHasChangeToViewSchema } = useViewMutation()
  const { config, flattenFilterNodeLeafs, flattenAdditionalNodeLeafs, shouldJoinRecordsInApplication } =
    useConfigSheetSelector()
  const queries = useQueriesValue()
  const ui = useUiValue()
  const records = useRecordsValue()
  const aggregationRecords = useAggregationRecordsValue()
  const isSaving = useIsSavingState()
  const { setMeta } = useMetaMutations()
  const viewContext = useViewsContextValue()
  const viewQueryResult = useViewQueryResult()
  const changes = useChangesValue()
  const cursorMutation = useCursorMutation() // 結局ここでrsheet側のstateを操作するしかないか？
  const recordsMutation = useRefetchMutation()
  const recordsAndChangesMutation = useRecordsAndChangesMutation()
  const { query } = useQuerySelector()
  const {
    metadataModal,
    filtersModal,
    sortersModal,
    debugModal,
    changesModal,
    searchSqlModal,
    searchableFieldsModal,
    syncUserPlanNotFoundModal,
  } = useNavigationModal()
  const { rowLineNum, setRowLineNum } = useRowHeightState()
  const { me } = useMeRelatedSelector()
  const isGoalView = component.isGoalView ?? false
  const isKpiSheet = view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)
  const syncEnabled = checkSyncEnabled(me.myUser) || isGoalView // TODO: だいぶadhocなのでなんとかしたい…
  const additionalConfig = useRecoilValue(additionalConfigAtom)
  const previousKpiConfig =
    additionalConfig.previousConfig?.type === 'kpi' ? additionalConfig.previousConfig : undefined
  const periodPickerVisibility = previousKpiConfig?.date !== undefined
  const ability = useViewAbilityValue()
  const message = useMessage()
  const columns = useSheetColumns()
  const [presetName] = useSheetPickedPresetName()
  const invisibleColumns = columns.filter((x) => !isTruthy(x.visible))
  const hasChangeToViewSchema = useRecoilValue(hasChangeToViewSchemaAtom)
  const [isSavingCurrentView, setIsSavingCurrentView] = useRecoilState(isSavingCurrentViewAtom)
  const sheetInSaveErrors = useRecoilValue(sheetInSaveErrorsAtom)
  const { updateCachedView } = useCachedViewsSelector()
  const [errorsDrawerOpen, setErrorsDrawerOpen] = useRecoilState(errorsDrawerOpenAtom)
  const currentSavedView = viewContext.views.find((x) => x.id === view.id)
  const isPasting = useRecoilValue(isPastingAtom)

  // NOTE: NX様専用フラグ(シートで「行追加」ボタンを隠すために使用)
  const enableNxOnlyFeature = organizationHasFeature(me.organization, 'enable_nx_only_feature')

  const showSheetInSaveAlert = (errorSummary?: string) => {
    const viewId = view.id
    const messageKey = `sheet-in-save-error`

    message.error({
      key: messageKey,
      content: (
        <span>
          {errorSummary ?? t('シートの保存中にエラーが発生しました')}
          <Button
            className="ml-2"
            type="text"
            style={{ fontWeight: 'bold' }}
            onClick={() => {
              if (!isKpiSheet) {
                updateCachedView?.({ viewId, errorsDrawerOpen: true })
                viewContext.pickView(viewId)
              }
              setErrorsDrawerOpen(true)
              message.destroy(messageKey)
            }}
          >
            {t(`もっと見る`)}
          </Button>
        </span>
      ),
      duration: 5,
    })
  }

  return (
    <div data-e2e="sheet-navigation">
      <Row justify="space-between" className="py-2 pl-2 pr-5" style={{ borderBottom: '1px solid #E1E6E8' }}>
        <Row>
          {!isGoalView && !isKpiSheet && <SheetPresetsPicker />}
          {/* <FieldButton {...{ sheetNavigationState, viewRecordsState }} /> */}
          <Button
            type="text"
            icon={<FilterOutlined />}
            onClick={() => {
              // sheetNavigationState.action.toggleSiderType('filters')
              // eslint-disable-next-line unicorn/no-useless-undefined
              filtersModal.showModal(undefined)
            }}
          >
            {t(`絞り込み`)}
            {flattenFilterNodeLeafs.length + flattenAdditionalNodeLeafs.length > 0 && (
              <span>({flattenFilterNodeLeafs.length + flattenAdditionalNodeLeafs.length})</span>
            )}
          </Button>
          <Button
            type="text"
            icon={<SortAscendingOutlined />}
            onClick={() => {
              sortersModal.showModal()
            }}
          >
            {t(`並び替え`)}
            {(query.sorters ?? []).length > 0 && <span>({(query.sorters ?? []).length})</span>}
          </Button>
          {/* <Button type="text" icon={<UnorderedListOutlined />}>
      グルーピング
    </Button> */}
          <Space>
            {isGoalView && (
              <Button
                // type="text"
                icon={<ConsoleSqlOutlined />}
                disabled={isNull(viewQueryResult)}
                onClick={() => {
                  metadataModal.showModal()
                }}
              />
            )}
            {isGoalView && invisibleColumns.isPresent() && (
              <Dropdown
                menu={{
                  items: columns.map((x) => ({
                    key: `${x.configField?.property.modelName}-${x.configField?.property.propertyName}`,
                    label: <ColumnVisiblityItem column={x} />,
                  })),
                }}
              >
                <Badge color="rgb(250,204,21)" count={invisibleColumns.length} overflowCount={999} offset={[-3, 3]}>
                  <Button icon={<EyeInvisibleOutlined />} className="border-yellow-400 text-yellow-500">
                    {t(`非表示`)}
                  </Button>
                </Badge>
              </Dropdown>
            )}
          </Space>
          {!isGoalView && (
            <>
              <Dropdown
                overlay={
                  <div className="bg-white p-4 shadow" style={{ width: 240 }}>
                    <div className="mb-3">
                      <Trans>
                        直近の変更を
                        <span className="bg-yellow-100">ハイライト</span>
                      </Trans>
                      <br />
                      <span className="text-xs">※{t(`親レコードのみ`)}</span>
                    </div>
                    <div>
                      <Select
                        className="w-full"
                        value={selectedDateForDiffHighlightOption.value}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        onChange={(value) => {
                          setHighlightConfig(value) // TODO
                        }}
                        onMouseDown={() => {
                          if (changes.length > 0) {
                            message.warning(t('設定を変更すると変更情報が失われます。'))
                          }
                        }}
                        options={dateForDiffHighlightOptions}
                      />
                    </div>
                  </div>
                }
                placement="bottomLeft"
                trigger={['hover']}
              >
                <Button type="text" icon={<HighlightOutlined />} />
              </Dropdown>
              <Dropdown
                overlay={
                  <Menu title={t(`1行の高さ`)}>
                    {[1, 2, 3, 5, 10].map((x) => (
                      <Menu.Item
                        className={rowLineNum === x ? `bg-blue-50` : ``}
                        onClick={() => {
                          setRowLineNum(x as RowLineNumber)
                        }}
                      >
                        {t(`{{x}}行分`, { x })}
                        {x === 1 ? t(`(デフォルト)`) : ``}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
                placement="bottomCenter"
              >
                <Button icon={<LineHeightOutlined />} type="text" />
              </Dropdown>
              <Dropdown
                overlay={
                  <div className="bg-white p-4 shadow" style={{ width: 300 }}>
                    <div className="mb-4">
                      {t(`一度に取得するレコード数の変更`)}
                      <br />
                      <span className="text-xs">※{t(`表示する行数とは異なる場合があります。`)}</span>
                      <br />
                      <span className="text-xs">※{t(`大きな数を設定した場合、動作が重くなることがあります。`)}</span>
                      <Select
                        className="mt-2 w-full"
                        value={pageSize}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        onChange={(value: number) => {
                          setPageSize(value)
                        }}
                        onMouseDown={() => {
                          if (changes.length > 0) {
                            message.warning(t('設定を変更すると変更情報が失われます。'))
                          }
                        }}
                        options={[100, 200, 500, 1000, 2000, 5000].map((x) => ({
                          value: x,
                          label: t(`{{x}}行`, { x }),
                        }))}
                      />
                    </div>
                    <div className="mb-4">
                      {t(`表示する子レコード行数の制限`)}
                      <br />
                      <span className="text-xs">
                        {t(
                          `1つの親レコードに複数の子レコードが紐づくような場合、表示される子レコードの行数を制限することができます。`,
                        )}
                      </span>
                      <Select
                        className="mt-2 w-full"
                        value={component.visibleRowNum ?? -1}
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                        }}
                        onChange={(visibleRowNumber: number) => {
                          // valueがundefinedのときにonChangeが反応しないため、-1で代替している
                          setMeta({
                            newMeta: {
                              visibleRowNum: visibleRowNumber === -1 ? undefined : visibleRowNumber,
                            },
                          })
                        }}
                        options={[-1, 1, 2, 3, 4, 5, 10].map((x) => ({
                          value: x,
                          label: x === -1 ? t(`全て表示`) : t(`{{x}}行`, { x }),
                        }))}
                      />
                    </div>
                    {previousKpiConfig === undefined && (
                      <div className="mb-4">
                        {t(`アプリケーションジョイン`)}
                        <br />
                        <span className="text-xs">
                          {t(
                            `有効化によりシートの読み込み速度の向上が期待されます。ただしオブジェクトを跨いだ絞り込みができなくなります。ブロックのフィルタは可能です。`,
                          )}
                        </span>
                        <div className="flex items-center justify-between align-middle">
                          {/* NOTE: Switchだけだとステータスが若干分かりづらい & レイアウトが難しいと思ったので説明テキストもいれている。しかし冗長だろうか？デザインに悩んだ。 */}
                          <div className="mt-2 text-xs text-gray-500">
                            {shouldJoinRecordsInApplication ? t(`現在有効化されています`) : t(`現在無効化されています`)}
                          </div>
                          <Popconfirm
                            title={
                              <div className="text-yellow-500">
                                {t(`アプリケーションジョイン設定を変更すると`)}
                                <br />
                                {t(`表示されるレコードが変わる可能性があります。`)}
                                <br />
                                {t(`挙動をよく理解した上でお使いください。`)}
                                <br />
                                {t(`変更してよろしいですか？`)}
                              </div>
                            }
                            onConfirm={(e) => {
                              e?.preventDefault()
                              e?.stopPropagation()
                              setMeta({
                                newMeta: {
                                  shouldJoinRecordsInApplication: !shouldJoinRecordsInApplication,
                                },
                              })
                            }}
                          >
                            <Switch
                              className="mt-2"
                              checked={shouldJoinRecordsInApplication}
                              onChange={(value, e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                // valueがundefinedのときにonChangeが反応しないため、-1で代替している
                              }}
                            />
                          </Popconfirm>
                        </div>
                      </div>
                    )}
                    <Row justify="space-between" className="mb-2">
                      <Space>
                        <SearchOutlined />
                        {t(`検索項目設定`)}
                      </Space>
                      <Button
                        type="text"
                        style={{
                          color: CONSTANT.colors.primaryColor,
                        }}
                        onClick={() => {
                          searchableFieldsModal.showModal()
                        }}
                      >
                        {t(`設定`)}
                      </Button>
                    </Row>
                    <Row justify="space-between" className="mb-2">
                      <Space>
                        <ConsoleSqlOutlined />
                        SQL{t(`表示`)}
                      </Space>
                      <Button
                        type="text"
                        style={{
                          color: CONSTANT.colors.primaryColor,
                        }}
                        disabled={isNull(viewQueryResult)}
                        onClick={() => {
                          metadataModal.showModal()
                        }}
                      >
                        {t(`表示`)}
                      </Button>
                    </Row>
                    {me.isAdmin && (
                      <Row justify="space-between" className="mb-2">
                        <Space className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}>
                          <ConsoleSqlOutlined />
                          {t(`設定編集`)}
                        </Space>
                        <Button
                          type="text"
                          style={{
                            color: CONSTANT.colors.primaryColor,
                          }}
                          onClick={() => {
                            debugModal.showModal({
                              content: isSome(additionalConfig)
                                ? {
                                    config,
                                    additionalConfig,
                                  }
                                : config,
                              // schema: viewConfigFieldSchema, // TODO: 指定はしているものの、現状ワークしていない
                              // TODO: 直接編集を許すか？
                              // onChange(newField) {
                              //   fieldMutations.setField({ field: newField as ViewConfigField })
                              // },
                            })
                          }}
                        >
                          {t(`表示`)}
                        </Button>
                      </Row>
                    )}
                    {me.isAdmin && (
                      <Row justify="space-between" className="mb-2">
                        <Space className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}>
                          <ConsoleSqlOutlined />
                          {t(`スキーマ表示`)}
                        </Space>
                        <Button
                          type="text"
                          style={{
                            color: CONSTANT.colors.primaryColor,
                          }}
                          onClick={() => {
                            debugModal.showModal({
                              content: {
                                config,
                                queries,
                                ui,
                              },
                              // schema: viewConfigFieldSchema, // TODO: 指定はしているものの、現状ワークしていない
                              // TODO: 直接編集を許すか？
                              // onChange(newField) {
                              //   fieldMutations.setField({ field: newField as ViewConfigField })
                              // },
                            })
                          }}
                        >
                          {t(`表示`)}
                        </Button>
                      </Row>
                    )}
                    {me.isAdmin && (
                      <>
                        <Row justify="space-between" className="mb-2">
                          <Space className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}>
                            <ConsoleSqlOutlined />
                            {t(`レコード表示`)}
                          </Space>
                          <Button
                            type="text"
                            style={{
                              color: CONSTANT.colors.primaryColor,
                            }}
                            onClick={() => {
                              debugModal.showModal({
                                content: {
                                  records,
                                },
                                // schema: viewConfigFieldSchema, // TODO: 指定はしているものの、現状ワークしていない
                                // TODO: 直接編集を許すか？
                                // onChange(newField) {
                                //   fieldMutations.setField({ field: newField as ViewConfigField })
                                // },
                              })
                            }}
                          >
                            {t(`表示`)}
                          </Button>
                        </Row>
                        <Row justify="space-between" className="mb-2">
                          <Space className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}>
                            <ConsoleSqlOutlined />
                            {t(`集計レコード表示`)}
                          </Space>
                          <Button
                            type="text"
                            style={{
                              color: CONSTANT.colors.primaryColor,
                            }}
                            onClick={() => {
                              debugModal.showModal({
                                content: {
                                  aggregationRecords,
                                },
                                // schema: viewConfigFieldSchema, // TODO: 指定はしているものの、現状ワークしていない
                                // TODO: 直接編集を許すか？
                                // onChange(newField) {
                                //   fieldMutations.setField({ field: newField as ViewConfigField })
                                // },
                              })
                            }}
                          >
                            {t(`表示`)}
                          </Button>
                        </Row>
                      </>
                    )}
                  </div>
                }
                placement="bottomLeft"
                trigger={['hover']}
              >
                <Button type="text" icon={<SettingOutlined />} data-e2e="setting-button" />
              </Dropdown>
              {/* <div className="text-xs text-gray-400 ml-2" style={{ transform: 'scale(0.7)', opacity: 0.7 }}>
            デバッグ
            <br />
            <Switch
              size="small"
              checked={viewState.debugMode}
              onChange={(value) => {
                viewState.setDebugMode(value)
              }}
            />
          </div> */}
              {periodPickerVisibility && <PeriodPicker />}

              <SearchQueryInput />
            </>
          )}
        </Row>
        {/* 右側 */}
        <Row>
          <Space>
            {!isGoalView && (
              <ImportSourceRecordsButton
                buttonType="link"
                onSuccess={async () => {
                  // 同期後に古いキャッシュを読まないようにするためにApolloのキャッシュをクリアする
                  const { isDrillDown, prevView, setIsChangedDrillDownModal } = viewContext
                  const cacheClearViewId = isTruthy(isDrillDown) ? prevView?.id : view.id
                  clearTargetViewQueryCaches({ viewIds: [cacheClearViewId].compact() })

                  if (isTruthy(isDrillDown) && isSome(setIsChangedDrillDownModal)) {
                    setIsChangedDrillDownModal(true)
                  }

                  await recordsMutation.refetch({ kind: 'save' })
                }}
              />
            )}
            <SheetMoreActionsButton />
            <Button
              disabled={changes.isBlank()}
              onClick={() => {
                changesModal.showModal()
              }}
              icon={<HistoryOutlined />}
            />
            {isSome(sheetInSaveErrors) && (
              <Badge count={sheetInSaveErrors.length} overflowCount={999} offset={[-3, 3]}>
                <Button
                  danger
                  type="default"
                  icon={<WarningOutlined />}
                  onClick={() => {
                    setErrorsDrawerOpen(true)
                  }}
                />
              </Badge>
            )}
            <CompileErrorsButton
              onClick={() => {
                setErrorsDrawerOpen(true)
              }}
            />
            {!isGoalView && !enableNxOnlyFeature && (
              <>
                <ButtonWithTooltip
                  disabled={!ability.canCreateNewRecord || !ability.canSaveRecord}
                  showTooltip={!ability.canCreateNewRecord || !ability.canSaveRecord}
                  tooltipTitle={t(`権限がないため、行追加はできません。`)}
                  onClick={async () => {
                    await addRootRecord({ rowIndex: -1 })
                    cursorMutation.moveToRowIndexAndEditMode(0)
                  }}
                  loading={isSaving.isTrue}
                  // type="primary"
                  icon={<PlusOutlined />}
                >
                  {t(`行追加`)}
                </ButtonWithTooltip>
              </>
            )}
            {!isSharedLinkPresetName(presetName) && hasChangeToViewSchema && isSome(currentSavedView) && (
              <ButtonWithTooltip
                disabled={!ability.canUpdate || !hasChangeToViewSchema}
                showTooltip={!ability.canUpdate}
                tooltipTitle={t(`権限がないため、設定の変更を破棄できません。`)}
                onClick={() => {
                  // presetNameがundefinedの場合(すなわち選択中のpresetを削除した時)、
                  // presetの先頭を表示する仕様だが、currentSavedViewのconfigは削除したpresetの設定が元となっているため
                  // 復元対象を先頭presetのconfigにしてあげる必要がある
                  if (
                    presetName === undefined &&
                    currentSavedView.config.type === 'sheet' &&
                    currentSavedView.config.presets?.[0] !== undefined
                  ) {
                    setConfigWithoutChange({
                      ...currentSavedView.config,
                      ...currentSavedView.config.presets[0],
                    })
                  } else {
                    setConfigWithoutChange(currentSavedView.config)
                  }
                  setHasChangeToViewSchema(false)
                }}
                icon={<DeleteOutlined />}
                danger
              >
                {t(`設定変更を破棄`)}
              </ButtonWithTooltip>
            )}
            {!isSharedLinkPresetName(presetName) &&
              (hasChangeToViewSchema || isSavingCurrentView || viewContext.isSaveConfigButtonActive) && (
                <ButtonWithTooltip
                  disabled={!ability.canUpdate || (!hasChangeToViewSchema && !viewContext.isSaveConfigButtonActive)}
                  showTooltip={!ability.canUpdate}
                  tooltipTitle={t(`権限がないため、保存はできません。`)}
                  onClick={() => {
                    setIsSavingCurrentView(true)
                  }}
                  loading={isSavingCurrentView}
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  {t(`設定を保存`)}
                </ButtonWithTooltip>
              )}
            {!isTruthy(viewContext.prevView?.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX)) && (
              <Badge count={syncEnabled ? 0 : <LockTwoTone />}>
                <ButtonWithTooltip
                  disabled={!ability.canSaveRecord || changes.length === 0 || isPasting}
                  showTooltip={!ability.canSaveRecord || isPasting}
                  tooltipTitle={
                    isPasting
                      ? t(`ペースト処理中のため保存できません、完了までお待ちください`)
                      : t(`権限がないため、保存はできません。`)
                  }
                  onClick={async () => {
                    if (syncEnabled || isGoalView) {
                      try {
                        await recordsAndChangesMutation.onSave()
                      } catch (error) {
                        // とりあえずmessageを持つことだけ担保できればOK
                        const errorSchema = z.object({
                          message: z.string(),
                        })
                        const parsed = errorSchema.safeParse(error)
                        if (parsed.success) {
                          // NOTE: シート書き込み時のエラーは専用アラート(showSheetInSaveAlert)で知らせる
                          const errorSummary = parsed.data.message.split('\n').first()
                          showSheetInSaveAlert(errorSummary)
                          return
                        }
                        message.error(t('エラーが発生しました。'))
                      }
                    } else {
                      syncUserPlanNotFoundModal.showModal()
                    }
                  }}
                  loading={isSaving.isTrue}
                  type="primary"
                  icon={<SaveOutlined />}
                >
                  {t(`保存`)}
                </ButtonWithTooltip>
              </Badge>
            )}
          </Space>
        </Row>
      </Row>

      <SearchSqlFormModal modal={searchSqlModal} />
      <SheetPresetFormModal />
      <ViewQueryFiltersModal />
      <ViewQuerySortersModal />
      <ViewJoinFiltersSettingModal />
      <ViewQueryNodeDetailModal />
      <ImportFieldsModal />
      <ViewUIErrorDrawer
        open={errorsDrawerOpen}
        onClose={() => {
          setErrorsDrawerOpen(false)
        }}
      />
    </div>
  )
}

function ColumnVisiblityItem({ column }: { column: RSheetColumn<ViewQueryRecordNode> }) {
  const { setUiColumn } = useFieldMutations()
  return (
    <div
      className="flex max-w-xs flex-nowrap justify-between gap-2"
      onClick={() => {
        const field = column.field
        if (isNull(field)) {
          return
        }
        setUiColumn({
          field,
          column: {
            visible: !isTruthy(column.visible),
          },
        })
      }}
    >
      <span className="contents">{column.title}</span>
      {isTruthy(column.visible) ? <EyeOutlined /> : <EyeInvisibleOutlined className="text-slate-400" />}
    </div>
  )
}
