import type { ViewUIInputNumber } from '@salescore/core'
import { InputNumber, message } from 'antd'
import { t } from 'i18next'
import { useContext, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { validationErrorVisibilityAtom } from '../../recoil/view/atoms'
import { useViewUIRecordNodeContext } from './contexts'
import { ViewUIContext } from './ViewUIComponent'
import { viewUiValidation } from './viewUiValidation'

export function ViewUIInputNumberC({ component }: { component: ViewUIInputNumber }) {
  const { isEditMode } = useContext(ViewUIContext)
  const { getValue, onChange } = useViewUIRecordNodeContext()
  const originalValue = getValue(component.fieldName) as number
  const [value, setValue] = useState<number | null>(originalValue)
  const validationErrorVisibility = useRecoilValue(validationErrorVisibilityAtom)
  const errors = validationErrorVisibility ? viewUiValidation(value, component) : []

  return (
    <InputNumber
      status={errors.isPresent() ? 'error' : undefined}
      value={value}
      onBlur={(e) => {
        onChange({ fieldName: component.fieldName, value })
      }}
      onChange={(value) => {
        setValue(value)
      }}
      onClick={() => {
        if (component.readonly === true) {
          void message.warning(t(`この項目は書き込みが禁止されています。`))
        }
      }}
      readOnly={isEditMode || component.readonly}
    />
  )
}
