import type { CoreDslFormLeafPartial } from '@salescore/core'
import { SortableListWrapper } from '@salescore/frontend-common'
import { Button, Dropdown, Form } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'

import type { TargetProperty } from './targetProperty'

function isExtraFormVisible({
  leaf,
  targetProperty,
}: {
  leaf: CoreDslFormLeafPartial
  targetProperty: TargetProperty
}) {
  const options = leaf.left?.type === 'snapshotVariable' ? (targetProperty.property.selectOptions ?? []) : []
  return options.isPresent() && ['>', '<'].includes(leaf.operator ?? '')
}

export function CoreDslFormExtraFormItem({
  targetProperty,
  leaf,
  onChange,
}: {
  onChange: (x: CoreDslFormLeafPartial['extra']) => void
  leaf: CoreDslFormLeafPartial
  targetProperty: TargetProperty
}) {
  const options = leaf.left?.type === 'snapshotVariable' ? (targetProperty.property.selectOptions ?? []) : []
  const values = leaf.extra?.sortedValues ?? []

  const visibility = isExtraFormVisible({ leaf, targetProperty })
  useEffect(() => {
    // フォームが表示されていないとき、すなわち順序設定の必要がない時、もし順序が設定されていたらクリアする
    if (!visibility && (leaf.extra?.sortedValues ?? []).isPresent()) {
      onChange({
        sortedValues: undefined,
      })
    }
    // フォームが表示されているとき、すなわち順序設定の必要がある時、もし順序が設定されていなかったらセットする
    if (visibility && (leaf.extra?.sortedValues ?? []).isBlank()) {
      onChange({
        sortedValues: options.map((x) => x.value as string),
      })
    }
  }, [visibility])

  if (!visibility) {
    return <></>
  }

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: [
          {
            key: '',
            label: (
              <div
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
              >
                <SortableListWrapper
                  items={options
                    .sortBy((option) => values.indexOf(option.value as string))
                    .map((option) => ({ ...option, key: option.value as string, value: option.value as string }))}
                  onChange={(values) => {
                    onChange({
                      sortedValues: values.map((x) => x.value),
                    })
                  }}
                  options={{
                    deletable: false,
                    bordered: false,
                  }}
                />
              </div>
            ),
          },
        ],
      }}
    >
      <Form.Item label="順序設定">
        <Button>{t(`順序設定`)}</Button>
      </Form.Item>
    </Dropdown>
  )
}
