import { Posthog, type PosthogEvent } from '@salescore/client-base'

import { useViewsContextValue, useViewValue } from './view/hooks'

interface PosthogTrackParameters {
  viewId?: string // 本ビューの ID
}

type PosthogTrackViewParameters = {
  prevViewId?: string // 本ビューの元になるビュー（もしあれば）の ID
  prevViewType?: string // "kpiPivot" ならダッシュボードのドリルダウンから本ビューが開かれたことを意味する
  nodeName?: string // 対象イベント: [view_sheet_custom_model_property_form]。
} & PosthogTrackParameters

type PosthogTrackClickParameters = PosthogTrackParameters

export function usePosthogTrackView() {
  const view = useViewValue()
  const context = useViewsContextValue()

  return (eventName: PosthogEvent, parameters?: PosthogTrackViewParameters) => {
    Posthog.track(eventName, {
      viewId: parameters?.viewId ?? view.id,
      prevViewId: parameters?.prevViewId ?? context.prevView?.id,
      prevViewType: parameters?.prevViewType ?? context.prevView?.type,
      nodeName: parameters?.nodeName,
    })
  }
}

export function usePosthogTrackClick() {
  const view = useViewValue()

  return (eventName: PosthogEvent, parameters?: PosthogTrackClickParameters) => {
    Posthog.track(eventName, {
      viewId: parameters?.viewId ?? view.id,
    })
  }
}
