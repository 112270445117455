import { useApolloClient, useMutation } from '@apollo/client'
import { isNull, isSome, isTruthy, r } from '@salescore/buff-common'
import { WriteEltChangesChunkDocument } from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS, Tracker } from '@salescore/client-base'
import { recoil } from '@salescore/client-recoil'
import {
  CORE_CONSTANT,
  type EltChangesChunk,
  type EltChangeToDelete,
  flatNodes,
  type ModelSearcher,
  type ViewConfigTreeNode,
  type ViewQueryRecordNode,
  type ViewQueryTableNode,
} from '@salescore/core'
import { pivotedGoalModelWithoutProperties } from '@salescore/features'
import { message } from 'antd'
import { t } from 'i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { VIEW_NEW_RECORD_PREFIX } from '../../../domain/constant'
import { recordsAction } from '../../../state/useViewRecordsState/recordsAction'
import { useQueryValue, useViewsContextValue, useViewValue } from '../../view/hooks'
import { modelSearcherSelector } from '../../view/selectors/modelSearcherSelector'
import { errorsAtom, organizationIdAtom, pickedIdsAtom, recordsAtom } from '../atoms'
import { useIsSavingState } from '../hooks'
import { useChangesSelector } from '../selectors/changesSelector'
import { type ChangesWithTransactionUpsertStatus, useChangesMutation } from './changesMutation'
import { useRefetchAggregationQueryResult } from './useRefetchAggregationQueryResult'
import { useRefetchMutation } from './useRefetchMutation'

const NOT_FOUND_PLACEHOLDER = '_NOT_FOUND_'

export const useRecordsAndChangesMutation = () => {
  const organizationId = useRecoilValue(organizationIdAtom)
  const refetchMutation = useRefetchMutation()
  const refetchAggregationQueryResult = useRefetchAggregationQueryResult()
  const query = useQueryValue()
  const context = useViewsContextValue()
  const view = useViewValue()
  const records = useRecoilValue(recordsAtom)
  const changesMutation = useChangesMutation()
  const modelSearcher = useRecoilValue(modelSearcherSelector)
  const client = useApolloClient()
  const pickedIds = useRecoilValue(pickedIdsAtom)
  const { changesChunks } = useChangesSelector() // TODO: selectorを使う必要はあるか？
  const { clearTargetViewQueryCaches } = recoil.sider.useClearViewQueryCacheMutation()

  const setPickedIds = useSetRecoilState(pickedIdsAtom)
  const setErrors = useSetRecoilState(errorsAtom)
  const setRecords = useSetRecoilState(recordsAtom)

  const isSaving = useIsSavingState()

  const [writeEltChangesChunkMutation] = useMutation(WriteEltChangesChunkDocument)

  async function upsertRecords(changesChunks: EltChangesChunk[]): Promise<ChangesWithTransactionUpsertStatus> {
    if (isSaving.isTrue) {
      return {
        status: 'notUpserted',
      }
    }
    isSaving.setTrue()

    try {
      Tracker.shared().track({ category: 'saveRecords', value: changesChunks.length.toString() })
      const result = await writeEltChangesChunkMutation({
        variables: {
          organizationId,
          changesChunks,
          viewId: view.id,
        },
      })

      // シートのコンポーネントを目標入力UIでも流用しているため、目標入力時もここのロジックを通るが
      // 別で記録したいので分ける
      const isGoalView = changesChunks.some((x) => x.modelName === pivotedGoalModelWithoutProperties.name)
      if (isGoalView) {
        const goalConfigId = window.location.pathname.split('/').last()
        Posthog.track(POSTHOG_EVENTS.save_goals, {
          goalConfigId,
          changesCount: changesChunks.flatMap((x) => x.changes).length,
        })
      } else {
        Posthog.track(POSTHOG_EVENTS.save_records, {
          changesCount: changesChunks.flatMap((x) => x.changes).length,
          viewId: view.id,
          viewName: view.name,
          changedProperies: changesChunks.flatMap((x) =>
            x.changes.flatMap((change) => {
              switch (change.type) {
                case 'create': {
                  return r(change.data)
                    .keys()
                    .map((propertyName) => ({ modelName: change.modelName, propertyName, type: 'create' }))
                }
                case 'update': {
                  return r(change.data)
                    .keys()
                    .map((propertyName) => ({ modelName: change.modelName, propertyName, type: 'update' }))
                }
                case 'delete': {
                  return [{ modelName: change.modelName, type: 'delete' }]
                }
              }
            }),
          ),
          prevViewId: context.prevView?.id,
          prevViewType: context.prevView?.type,
        })
      }
      // エラーが発生しなかったときだけrefetchする
      // TODO: 必要な行だけをrefetchするような形にしたい
      const results = result.data?.writeEltChangesChunk.flatMap((x) => x.results) ?? []
      const errors = results.filter((x) => x.status !== 'success')
      if (errors.isBlank()) {
        const cacheClearViewIds = [
          context.prevView?.id,
          view.id.replace(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX, ''),
        ].compact()
        clearTargetViewQueryCaches({ viewIds: cacheClearViewIds })
        await refetchMutation.refetch({ kind: 'save' })
      } else {
        Posthog.track(POSTHOG_EVENTS.failed_save_sheet_records, {
          viewId: view.id,
          viewName: view.name,
          errorsCount: errors.length,
          errorTexts: errors
            .map((x) => x.error)
            .unique()
            .join(','),
          prevViewId: context.prevView?.id,
          prevViewType: context.prevView?.type,
        })
      }
      await refetchAggregationQueryResult.refetch()
      return {
        status: 'upserted',
        results: result.data?.writeEltChangesChunk ?? [],
      }
    } catch (error) {
      return {
        status: 'upsertError',
        error,
      }
    } finally {
      isSaving.setFalse()
    }
  }

  return {
    ...recordsAction({
      records,
      query,
      client,
      setRecords,
      addChange(x) {
        changesMutation.addChange(x)
      },
    }),
    togglePicked(id: string, forcePick?: boolean) {
      setPickedIds((oldPickedIds) => {
        const filteredIds = oldPickedIds.filter((oldId) => oldId !== id)
        const picked = [...filteredIds, id]
        const unpicked = filteredIds
        if (forcePick !== undefined) {
          return forcePick ? picked : unpicked
        }

        if (oldPickedIds.includes(id)) {
          return unpicked
        }
        return picked
      })
    },
    async destroyPickedRecords(isDeleteAllChildren?: boolean) {
      if (view.config.type !== 'sheet' || isNull(view.config.tree)) {
        return
      }
      // 新規行と既存行で分ける
      const [newIds, existIds] = pickedIds.partition((id) => id.startsWith(VIEW_NEW_RECORD_PREFIX))
      // const rowIndex = records.findIndex((record)=>record.id === )

      if (newIds.length > 0) {
        // レコードから該当するidを削除
        setRecords((oldRecords) => {
          // TODO: DRY
          if (oldRecords === undefined) {
            return oldRecords
          }
          const rootNode = query.tree
          if (rootNode === undefined) {
            return oldRecords
          }
          const records = oldRecords
          if (records === undefined) {
            return oldRecords
          }

          // TODO: この処理だと、ルートからしか取り除けない。現状pickできるidはルートのみなので問題ないが、今後必要であれば拡張
          const newRecords = records.filter((record) => !newIds.includes(record.id!))
          return newRecords
        })

        changesMutation.removeChanges(newIds)
        setPickedIds((oldPickedIds) => oldPickedIds.filter((id) => !newIds.includes(id)))
      }

      if (existIds.length > 0) {
        const streamName = query.tree.write?.streamName
        if (streamName === undefined || existIds.length === 0) {
          void message.error(t(`更新不可能なオブジェクトです`))
          return
        }

        const changesChunks: EltChangesChunk[] = isTruthy(isDeleteAllChildren)
          ? generateChunksForDeleteAllChildren({
              records: records.filter((x) => isSome(x.id) && existIds.includes(x.id)),
              tree: view.config.tree,
              modelSearcher,
            })
          : [
              {
                modelName: streamName,
                changes: existIds.map(
                  (id): EltChangeToDelete => ({
                    type: 'delete',
                    modelName: streamName,
                    id,
                    before: {}, // TODO
                  }),
                ),
              },
            ]

        const result = await upsertRecords(changesChunks)
        Posthog.track(POSTHOG_EVENTS.save_records, {
          changesCount: changesChunks.flatMap((x) => x.changes).length,
          viewId: view.id,
          viewType: view.type,
          viewName: view.name,
          changedProperies: changesChunks.flatMap((x) =>
            x.changes.flatMap((change) => {
              switch (change.type) {
                case 'create': {
                  return r(change.data)
                    .keys()
                    .map((propertyName) => ({ modelName: change.modelName, propertyName, type: 'create' }))
                }
                case 'update': {
                  return r(change.data)
                    .keys()
                    .map((propertyName) => ({ modelName: change.modelName, propertyName, type: 'update' }))
                }
                case 'delete': {
                  return [{ modelName: change.modelName, type: 'delete' }]
                }
              }
            }),
          ),
          prevViewId: context.prevView?.id,
          prevViewType: context.prevView?.type,
        })
        if (result.status === 'upserted') {
          const errorResults = result.results
            .flatMap((x) => x.results)
            .map((x) => (x.status === 'success' ? undefined : x))
            .compact()
          if (errorResults.isPresent()) {
            const errorTexts = errorResults.map((x) => x.error).compact()
            void message.error(errorTexts.join(','))
            setErrors(errorTexts)
            const errorIds = errorResults.map((x) => x.change.id).compact()
            setPickedIds(errorIds)
            void message.error(t(`{{length}}件のレコードでエラーが発生しました。`, { length: errorIds.length }))
            Posthog.track(POSTHOG_EVENTS.failed_save_sheet_records, {
              viewId: view.id,
              viewType: view.type,
              viewName: view.name,
              errorsCount: errorIds.length,
              errorTexts: errorTexts.join(','),
              prevViewId: context.prevView?.id,
              prevViewType: context.prevView?.type,
            })
          } else {
            setPickedIds([])
            void message.success(t(`削除しました`))
          }
        } else {
          void message.error(t(`エラーが発生しました`))
        }
      }
    },
    async onSave() {
      if (isSaving.isTrue) {
        return
      }
      return await changesMutation.resetChangesWithTransaction(async () => await upsertRecords(changesChunks))
    },
  }
}

function flatChildrenTableNodes(records: ViewQueryRecordNode[]): ViewQueryTableNode[] {
  // 与えられたrecordsに属する全てのViewQueryTableNodeを返す
  return records.flatMap((record) =>
    record.children.flatMap((child) => [child, ...flatChildrenTableNodes(child.children)]),
  )
}

function generateChunksForDeleteAllChildren({
  records,
  tree,
  modelSearcher,
}: {
  records: ViewQueryRecordNode[]
  tree: ViewConfigTreeNode
  modelSearcher: ModelSearcher
}): EltChangesChunk[] {
  const rootModelName = tree.modelName
  const flattenTree = flatNodes(tree)
  const treeModels = flattenTree.map((x) => ({ nodeName: x.name, modelName: x.modelName }))
  const flattenChildrenTableNodes = [
    {
      nodeName: rootModelName,
      children: records,
    },
    ...flatChildrenTableNodes(records),
  ]
  const groupedTableNodes = flattenChildrenTableNodes.groupBy((node) => {
    const model = treeModels.find((x) => x.nodeName === node.nodeName)
    return model?.modelName ?? NOT_FOUND_PLACEHOLDER // 見つからなかった場合は後で除外するために定数値を入れる
  })

  const chunks: EltChangesChunk[] = groupedTableNodes
    .map((modelName, nodes) => {
      if (modelName === NOT_FOUND_PLACEHOLDER) {
        return
      }

      // 削除不可なモデルはスキップ
      const model = modelSearcher.searchModel(modelName)
      if (!isTruthy(model?.deletable ?? true)) {
        return
      }

      const deleteIds = nodes
        .flatMap((node) => node.children.map((x) => x.id))
        .compact()
        .unique()
      if (deleteIds.isBlank()) {
        return
      }

      return {
        modelName,
        changes: deleteIds.map(
          (id): EltChangeToDelete => ({
            type: 'delete',
            modelName,
            id,
            before: {},
          }),
        ),
      }
    })
    .compact()

  return chunks
}
