import { CORE_CONSTANT } from '@salescore/core'
import { selector, useRecoilValue } from 'recoil'

import { flatNodes } from '../../../state/nodeUtil'
import { configSheetAtom, viewAtom, viewsContextAtom } from '../atoms'

//
// TODO: recoil-pagesとviewsでatoms,selectorが被っている
//
export const viewSelector = selector({
  key: `view/viewSelector`,
  get({ get }) {
    const view = get(viewAtom)
    // const config = view.config
    const config = get(configSheetAtom)
    const viewContext = get(viewsContextAtom)
    const flattenNodes = flatNodes(config.tree)

    const backgroundViewType =
      viewContext.prevView?.config.type === 'kpiPivot'
        ? 'kpiPivot'
        : view.id.startsWith(
              `${CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX}${CORE_CONSTANT.KPI_TIME_SERIES_DRILL_DOWN_VIEW_ID_PREFIX}`,
            )
          ? 'kpiTimeSeries'
          : view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)
            ? 'kpi'
            : 'sheet'

    const backgroundViewId =
      backgroundViewType === 'kpiPivot'
        ? viewContext.prevView?.id
        : backgroundViewType === 'kpiTimeSeries'
          ? view.id.replace(
              `${CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX}${CORE_CONSTANT.KPI_TIME_SERIES_DRILL_DOWN_VIEW_ID_PREFIX}`,
              '',
            )
          : backgroundViewType === 'kpi'
            ? view.id.replace(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX, '')
            : view.id

    return {
      view,
      config,
      flattenNodes,
      backgroundViewType,
      backgroundViewId,
      getNode: (nodeName: string) => flattenNodes.find((x) => x.name === nodeName),
    }
  },
})

export const useViewSelector = () => useRecoilValue(viewSelector)
