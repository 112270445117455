export function flatNodes<T extends { children?: T[] }>(node: T | undefined): T[] {
  if (node === undefined) {
    return []
  }

  return [node, ...(node.children?.flatMap((childNode) => flatNodes(childNode)) ?? [])]
}

export function generateAbsoluteFieldsWithAsName(tableName: string, columnName: string) {
  return `${generateAbsoluteFields(tableName, columnName)} AS "${generateAbsoluteFieldAsName(tableName, columnName)}"`
}

export function generateAbsoluteFields(tableName: string, idColumn: string) {
  return `"${tableName}"."${idColumn}"`
}

export function generateAbsoluteFieldAsName(tableName: string, idColumn: string) {
  return `${tableName}_${idColumn}`
}

export function getLabeledName(fieldName: string) {
  return `${fieldName}_label`
}
