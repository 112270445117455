import { r } from '@salescore/buff-common'
import type { ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import type { SetRecoilState } from 'recoil'

import { getInnerRecordNode, getRecordNodeFromRootNode } from '../../../state/nodeUtil'
import { recordAtomFamily } from '../atoms'
import { updateHeight, updateInnerRow } from './upsertViewRecords'

export interface RelationChange {
  rowIndex: number
  innerRowIndex: number
  node: ViewQueryNode
  recordNode: ViewQueryRecordNode
}

export function updateRecordNodeRelations(
  records: ViewQueryRecordNode[],
  relationChanges: RelationChange[],
  set: SetRecoilState,
) {
  for (const relationChange of relationChanges) {
    const { rowIndex, innerRowIndex, node, recordNode } = relationChange
    // ルートのレコード(行)を取得
    const rootRecordNode = records[rowIndex]
    if (rootRecordNode === undefined) {
      continue
    }
    const newRootRecordNode = rootRecordNode
    // const newRootRecordNode = r(rootRecordNode).clone().data

    // 親レコードを取得
    const parentRecordNodes = getRecordNodeFromRootNode(newRootRecordNode, node.path.slice(0, -1))
    const parentRecordNode = Array.isArray(parentRecordNodes)
      ? getInnerRecordNode(parentRecordNodes, innerRowIndex)
      : parentRecordNodes
    if (parentRecordNode === undefined) {
      // childrenのnodeについて、emptyなセルの参照項目を直接編集すると起こりうるが、useEditModeする際にこのケースはonAddChildRecordしているはずなので問題ない
      // notifyBugsnag({ error: new Error(`parentRecordNode not found in changeRelation. ${node.path.join(',')}`) })
      continue
    }

    //
    // 子要素にfetchした値を入れる
    //
    let targetTableNode = parentRecordNode.children.find((x) => x.nodeName === node.name)
    // tableNodeがなければ作成する必要がある
    if (targetTableNode === undefined) {
      targetTableNode = {
        nodeName: node.name,
        children: [],
      }
      parentRecordNode.children.push(targetTableNode)
    }
    if (!targetTableNode.children.some((x) => x.id === recordNode.id)) {
      // 同じIDを持つレコードがなければ入れ替え
      targetTableNode.children.splice(0, targetTableNode.children.length, recordNode)
    }

    // ラベル要素があれば、親レコードのラベルを更新
    const parentRecordNodeKeys = r(parentRecordNode.attributes).keys()
    for (const x of r(recordNode.attributes).toArray()) {
      const [key, value] = x
      if (!key.endsWith(`_label`)) {
        continue
      }
      if (parentRecordNodeKeys.includes(key)) {
        parentRecordNode.attributes[key] = value
      } else if (parentRecordNodeKeys.includes(key.replace(/_label$/, ''))) {
        parentRecordNode.attributes[key] = value
      }
    }

    // heightを参照渡しで更新
    updateHeight(newRootRecordNode)
    // innerStartIndexを参照渡しで更新
    updateInnerRow(newRootRecordNode)

    // familyを更新
    set(recordAtomFamily({ id: newRootRecordNode.id! }), r(newRootRecordNode).clone().data) // 複製して渡さないとfreezeされてしまい、後続の関数で編集できなくなる
  }
}
