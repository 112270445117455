import { Form, Input } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

export const KpiFormDescriptionFormItem = () => {
  const { kpi, setKpi, setFormModified } = useKpiFormSelectors()
  const [kpiDescription, setKpiDescription] = useState(kpi.description ?? '')

  useEffect(() => {
    setKpiDescription(kpi.description ?? '')
  }, [kpi.description])

  return (
    <>
      <Form.Item label={t(`KPIの説明`)}>
        <Input
          value={kpiDescription}
          onChange={(event) => {
            setKpiDescription(event.target.value)
            setFormModified(true)
          }}
          // onChangeでsetKpiを呼ぶと一文字入力する度に各種Selector等の再計算が走ってしまい
          // 非常に重くなるので更新のタイミングをonBlurにしている
          onBlur={(event) => {
            setKpi({ description: event.target.value })
          }}
        />
      </Form.Item>
    </>
  )
}
