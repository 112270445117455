import { gql } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import dayjs, { type Dayjs } from 'dayjs'
import { print } from 'graphql'

import { notifyBugsnag } from '../bugsnag'
import type { ActionLog, Organization, Token, User } from './types'

const INACTIVE_JUDGE_TIME = 180

const CREATE_ACTION_LOG_MUTATION = gql`
  mutation createActionLog(
    $organizationId: ID!
    $userId: ID!
    $organizationName: String!
    $userName: String!
    $path: String!
    $actionLog: ActionLogInput!
  ) {
    createActionLog(
      organizationId: $organizationId
      userId: $userId
      organizationName: $organizationName
      userName: $userName
      path: $path
      actionLog: $actionLog
    ) {
      id
    }
  }
`

class Tracker {
  private token: Token = undefined
  private organization: Organization = { id: 'unknown', name: 'unknown' }
  private user: User = { id: 'unknown', name: 'unknown' }
  private sessionStartAt: Dayjs | undefined = undefined
  // マウス操作、またはフォーカスが当たった際にアクティブとみなす
  private lastActiveMotionAt: Dayjs = dayjs()

  private static instance: Tracker

  private constructor() {
    window.addEventListener('unload', () => {
      this.onUnload()
    })

    window.addEventListener('focus', () => {
      this.onFocus()
    })

    window.addEventListener('blur', () => {
      this.onBlur()
    })

    window.addEventListener('mousemove', () => {
      this.onMouseMove()
    })

    setInterval(() => {
      this.onActivityCheck()
    }, 10 * 1000)
  }

  public static shared(): Tracker {
    if (Tracker.instance === undefined) {
      Tracker.instance = new Tracker()
    }
    return Tracker.instance
  }

  public track(actionLog: ActionLog) {
    if (isNull(process.env.TRACKER_GRAPHQL_URL)) {
      return
    }

    const headers: { type: string; authorization?: string } = { type: 'application/json' }
    if (!isNull(this.token)) {
      headers.authorization = `Bearer ${this.token}`
    }

    const blob = new Blob(
      [
        JSON.stringify({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any
          query: print(CREATE_ACTION_LOG_MUTATION as any),
          variables: {
            organizationId: this.organization.id,
            userId: this.user.id,
            organizationName: this.organization.name,
            userName: this.user.name,
            path: window.location.pathname,
            actionLog,
          },
        }),
      ],
      headers,
    )

    try {
      navigator.sendBeacon(process.env.TRACKER_GRAPHQL_URL, blob)
    } catch (error) {
      if (error instanceof Error) {
        notifyBugsnag({ error, severity: 'warning' })
      }
    }
  }

  public setToken(token: Token) {
    this.token = token
  }

  public setUser(organization: Organization, user: User) {
    this.organization = organization
    this.user = user
    this.startSession()
  }

  private startSession() {
    if (!isNull(this.sessionStartAt)) {
      return
    }
    this.sessionStartAt = dayjs()
  }

  private getSessionTime(): number | undefined {
    if (isNull(this.sessionStartAt)) {
      return undefined
    }
    const time = dayjs().diff(this.sessionStartAt, 'second', false)
    this.sessionStartAt = undefined
    return time
  }

  private trackSessionTime(excessTime = 0) {
    const sessionTime = this.getSessionTime()
    if (isNull(sessionTime)) {
      return
    }
    // 非アクティブ時など余剰な時間をセッション時間から差し引く
    const actualSessionTime = sessionTime - excessTime

    // NOTE: actualSessionTimeが負の値になることは想定しない動きだが、
    // ログイン時間がマイナスになってしまうという報告を受けて暫定の止血対応
    // c.f. https://github.com/salescore-inc/issues/issues/968
    if (actualSessionTime <= 0) {
      return
    }

    this.track({ category: 'session', value: actualSessionTime.toString() })
  }

  private onUnload() {
    this.trackSessionTime()
  }

  private onFocus() {
    this.lastActiveMotionAt = dayjs()
    this.startSession()
  }

  private onBlur() {
    this.trackSessionTime()
  }

  private onMouseMove() {
    this.lastActiveMotionAt = dayjs()
    // 放置後、sessionStartAtがundefinedになるため、また動かした際にセッションを開始
    if (isNull(this.sessionStartAt)) {
      this.startSession()
    }
  }

  private onActivityCheck() {
    // 180秒経過後にセッション開始時間(sessionStartAt)をundefinedに初期化すると、
    // それまでの操作時間がtrack(計上)されずに消えてしまうため、180秒放置された場合もtrackするようにする
    // (trackSessionTime()->getSessionTime()内でsessionStartAtはundefinedに初期化される)
    if (!isNull(this.sessionStartAt) && dayjs().diff(this.lastActiveMotionAt, 'second', false) >= INACTIVE_JUDGE_TIME) {
      this.trackSessionTime(INACTIVE_JUDGE_TIME)
    }
  }
}

export { Tracker }
