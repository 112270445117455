import type { GoalConfigFragment } from '@salescore/core'
import { Select } from 'antd'
import { t } from 'i18next'
import { type Dispatch, type ReactNode, type SetStateAction, useEffect } from 'react'

import { useViewValue } from '../../../../recoil/view/hooks'

export type TimeSpan = GoalConfigFragment['timeSpanType']

export interface TimeSpanFormInputProperties {
  timeSpan: TimeSpan | undefined
  setTimeSpan: Dispatch<SetStateAction<TimeSpan | undefined>>
}

export function TimeSpanFormInput(properties: TimeSpanFormInputProperties): ReactNode {
  const view = useViewValue()

  useEffect(() => {
    if (properties.timeSpan === undefined) {
      properties.setTimeSpan('month')
    }
  }, [properties.timeSpan, properties.setTimeSpan])

  return (
    <Select
      value={properties.timeSpan}
      onChange={properties.setTimeSpan}
      options={[
        {
          value: 'month',
          label: t(`月`),
        },
        {
          value: 'week',
          label: t(`週`),
        },
        {
          value: 'day',
          label: t(`日`),
        },
      ]}
      disabled={view.createdAt !== view.updatedAt}
    />
  )
}
