import { r } from '@salescore/buff-common'
import type { ViewQueryList, ViewQueryRecordNode } from '@salescore/core'
import { logger } from '@salescore/frontend-common'
import { t } from 'i18next'
import type { SetRecoilState } from 'recoil'

import type { ViewRecordFieldChange } from '../../../state/useViewRecordsState/useChangesState'
import { feedbackMessagesAtom, recordAtomFamily } from '../atoms'
import { isNewRecord } from './upsertViewRecords'

export interface RecordNodePathAtom {
  tableNodeName: string
  recordNodeId: string
}

export type RecordNodePath = RecordNodePathAtom[]

export interface RecordChangeByPath {
  recordNodePath: RecordNodePath
  id: string
  fieldName: string
  value: unknown
}

//
// recordsを破壊的操作で更新し、変更結果changesを返す関数
// TODO: 新規作成系は既にaddEmptyRecordMutation関連で実装しており、ここで作成することはペースト系のみしかないはず。
//       ペーストのロジックを書き換え次第、ここのロジックをリファクタリングする。
//
export function upsertViewRecordByPath({
  record,
  view,
  recordChanges,
  set,
}: {
  record: ViewQueryRecordNode
  view: ViewQueryList
  recordChanges: RecordChangeByPath[]
  set: SetRecoilState
}): ViewRecordFieldChange[] {
  return recordChanges.flatMap((recordChange) => upsertViewRecord(recordChange, record, view, set))
}

function upsertViewRecord(
  recordChange: RecordChangeByPath,
  rootRecordNode: ViewQueryRecordNode,
  query: ViewQueryList,
  set: SetRecoilState,
): ViewRecordFieldChange[] {
  const { recordNodePath, fieldName, value } = recordChange
  const field = query.fields.find((x) => x.name === fieldName)
  if (field === undefined) {
    return []
  }

  // レコードの中の対象ノードを取得
  // 親要素の時レコード、子の時はレコードの配列
  const targetRecordNode = getRecordNodeFromRecordNodePath(rootRecordNode, recordNodePath)

  // 対象ノードが存在しないことはありえないが、一応ガードしておく
  if (targetRecordNode === undefined) {
    logger.debug('targetRecordNode not found')
    return []
  }

  if (field.write === undefined) {
    logger.debug('field.write not found')
    return []
  }

  const oldValue = targetRecordNode.attributes[field.name]
  if (oldValue === value) {
    return []
  }
  if (field.meta.creatable === false && isNewRecord(targetRecordNode)) {
    set(feedbackMessagesAtom, [
      {
        message: t(`{{label}}は作成禁止です`, { label: field.meta.label }),
        type: 'warn' as const,
      },
    ])
    return []
  }
  if (field.meta.updatable === false && !isNewRecord(targetRecordNode)) {
    set(feedbackMessagesAtom, [
      {
        message: t(`{{label}}は更新禁止です`, { label: field.meta.label }),
        type: 'warn' as const,
      },
    ])
    return []
  }

  // レコードを更新
  targetRecordNode.attributes[field.name] = value as string
  // TODO
  // if (label !== undefined) {
  //   targetRecordNode.attributes[getLabeledFieldName(field.name)] = label
  // }
  set(recordAtomFamily({ id: rootRecordNode.id! }), r(rootRecordNode).clone().data) // 複製して渡さないとfreezeされてしまい、後続の関数で編集できなくなる

  if (targetRecordNode.id === undefined) {
    return []
  }
  const nameField = query.fields
    .filter((x) => x.nodePath.isEqual(query.tree.path))
    .find((field) => field.meta.fieldMetaType === 'name')
  return [
    {
      rootNodeId: rootRecordNode.id,
      id: targetRecordNode.id,
      streamName: field.write.streamName,
      fieldChanges: [
        {
          id: targetRecordNode.id,
          fieldName: field.name,
          propertySourceName: field.write.propertySourceName,
          value,
          rowIndex: 0, // TODO
          innerRowIndex: 0, // TODO
        },
      ],
      recordNameLabel: nameField === undefined ? undefined : (targetRecordNode.attributes[nameField.name] as string),
      before: {
        [field.write.propertySourceName]: oldValue,
      },
      after: {
        [field.write.propertySourceName]: value,
      },
    },
  ]
}

export function getRecordNodeFromRecordNodePath(
  recordNode: ViewQueryRecordNode,
  recordNodePath: RecordNodePath,
): ViewQueryRecordNode | undefined {
  if (recordNodePath.length === 0) {
    return recordNode
  }
  const { tableNodeName, recordNodeId } = recordNodePath[0]!
  const tableNode = recordNode.children.find((x) => x.nodeName === tableNodeName)
  const childRecordNode = (tableNode?.children ?? []).find((x) => x.id === recordNodeId)
  if (childRecordNode === undefined) {
    return undefined
  }
  return getRecordNodeFromRecordNodePath(childRecordNode, recordNodePath.slice(1))
}
