import { isPresent } from '@salescore/buff-common'
import Highlighter from 'react-highlight-words'
import { selector, useRecoilValue } from 'recoil'

import { searchKeyAtom, viewGroupTreeAtom } from '../atoms'
import type { ExpandedAntdNode } from '../functions/convertFlatNodesToAntdTree'

const searchResultNodesSelector = selector({
  key: 'navigation/searchResultNodesSelector',
  get: ({ get }) => {
    const searchKey = get(searchKeyAtom)
    const viewGroupTree = get(viewGroupTreeAtom)
    // treeを全操作して、favoritesに相当するノードをpushする(TODO: 非効率すぎるようであれば要検討)
    const result: ExpandedAntdNode[] = []
    if (isPresent(searchKey)) {
      for (const node of viewGroupTree) {
        dfs([], node, searchKey, result)
      }
    }

    return {
      searchKey,
      searchResultNodes: result,
    }
  },
})

export const useSearchResultNodes = () => useRecoilValue(searchResultNodesSelector)

function dfs(parentNodes: ExpandedAntdNode[], node: ExpandedAntdNode, name: string, result: ExpandedAntdNode[]): void {
  if (node.searchKey.includes(name)) {
    const titleText = [...parentNodes.map(async (x) => await x.title), node.title].compact().join(' / ')
    result.push({
      ...node,
      title: (
        <Highlighter
          textToHighlight={titleText}
          searchWords={[name]}
          highlightStyle={{
            backgroundColor: '#FDE047',
          }}
        />
      ),
    })
  }

  for (const child of node.children ?? []) {
    dfs([...parentNodes, node], child, name, result)
  }
}
