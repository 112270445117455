import { CopyButton } from '@salescore/frontend-common'
import { Modal, Space, Typography } from 'antd'
import { t } from 'i18next'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { useRecoilValue } from 'recoil'
import { format } from 'sql-formatter'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { viewAggregationQueryResultsAtom } from '../../../../recoil/records/atoms'
import { useViewQueryResult } from '../../../../recoil/records/hooks'

export const ViewQueryResultsMetadataModal = () => {
  const { metadataModal } = useNavigationModal()

  return (
    <Modal
      open={metadataModal.isModalVisible}
      onCancel={metadataModal.hideModal}
      width={'60%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      title={<div className="font-bold">SQL{t(`実行状況詳細`)}</div>}
      style={{ top: '3%' }}
    >
      <Body />
    </Modal>
  )
}

function Body() {
  const viewQueryResult = useViewQueryResult()
  const viewAggregationQueryResults = useRecoilValue(viewAggregationQueryResultsAtom)
  const results = [viewQueryResult, ...viewAggregationQueryResults].compact()
  if (results.isBlank()) {
    return <></>
  }
  const errors = results.map((x) => x.error).compact()
  const sqls = results.flatMap((x) => x.sqlDetails ?? x.sqls?.map((sql) => ({ sql, name: `` })) ?? [])

  return (
    <>
      {errors.isPresent() && (
        <div className="mb-8">
          <Typography.Title level={4}>{t(`エラーが発生した`)}SQL</Typography.Title>
          {errors.map((error) => (
            <div className="text-red-500">{error}</div>
          ))}
        </div>
      )}
      <div className="mb-8">
        <Typography.Title level={4}>SQL{t(`詳細`)}</Typography.Title>
        {sqls.map((sql, index) => (
          <div className="mb-8" key={index}>
            <Typography.Title level={5}>
              <Space>
                <>
                  {index + 1}. {sql.name}
                </>
                {/* <span className="ml-1 mr-2 text-sm font-normal text-gray-500">({viewQueryResult.duration}ms)</span> */}
                {/* <QueryResultCsvDownloadButton queryResult={viewQueryResult.} /> */}
                <CopyButton text={sql.sql} />
              </Space>
            </Typography.Title>
            <SyntaxHighlighter language="sql" style={monokaiSublime}>
              {format(sql.sql, {
                language: 'postgresql',
              })}
            </SyntaxHighlighter>
          </div>
        ))}
      </div>
    </>
  )
}
