import { type CSSProperties, useState } from 'react'

export const MINIMUM_WIDTH = 30
const DRAGGABLE_WIDTH = 20

export function DraggableBar({
  width,
  height,
  setWidth,
}: {
  width: number
  height: number
  setWidth: (width: number) => void
}) {
  const [x, setX] = useState<number | undefined>()
  const [startX, setStartX] = useState<number | undefined>()

  const diffX = (x ?? 0) - (startX ?? 0)
  const minimumDIffX = -width + MINIMUM_WIDTH

  const headerRowBarStyle: CSSProperties = {
    position: 'absolute',
    height,
    width: 3,
    backgroundColor: '#5991F6',
    // opacity: 0,
    zIndex: 12,
    top: 0,
    right: 0,
    cursor: 'col-resize',
  }

  return (
    <>
      {/* hoverで表示される要素 */}
      {/* <div
        className="visible-on-hover"
        style={{
          ...headerRowBarStyle,
          // opacity: 1,
          zIndex: 3,
        }}
      /> */}
      {/* ドラッグ可能な要素 */}
      <div
        className="visible-on-hover"
        style={{
          ...headerRowBarStyle,
          width: DRAGGABLE_WIDTH, // ドラッグ可能な範囲を広めにしておく
          // paddingLeft: 10,
          backgroundColor: 'none',
          right: -(DRAGGABLE_WIDTH / 2),
        }}
        draggable={true} // これの指定が必須
        onDragStart={(e) => {
          setX(e.clientX)
          setStartX(e.clientX)
          // TODO: ドラッグイベントの終了時に、ドラッグした要素が「元の位置に戻る」アニメーションが発火してしまい、onDragEndが発火するまで少し時間がかかる。
          //       以下やonDropなど諸々を試したが、うまくいかなかった
          e.dataTransfer.effectAllowed = 'move'
          // e.dataTransfer.setData('text/plain', '')
        }}
        onDrag={(e) => {
          if (e.clientX !== 0 && x !== e.clientX) {
            // 書き方が悪いのか、onDragEndの直前にclientXが0になってしまうのでそれを防ぐ(ユーザーが0の位置までカーソルを移動させることは、今回の文脈だとありえない)
            setX(e.clientX)
          }
        }}
        onDragEnd={(e) => {
          setWidth(width + Math.max(diffX, minimumDIffX))
          setX(undefined)
          setStartX(undefined)
        }}
      >
        {x === undefined && startX === undefined && (
          <div
            style={{
              ...headerRowBarStyle,
              right: DRAGGABLE_WIDTH / 2,
              // opacity: 1,
              zIndex: 3,
            }}
          />
        )}
      </div>
      {/* ドラッグ中に表示される要素 */}
      {x !== undefined && startX !== undefined && (
        <>
          <div
            style={{
              ...headerRowBarStyle,
              right: -Math.max(diffX, minimumDIffX),
              zIndex: 1,
            }}
          />
          <div
            style={{
              position: 'absolute',
              height: window.screen.height,
              width: 1,
              backgroundColor: '#5991F6',
              zIndex: 100,
              top: 0,
              right: -Math.max(diffX, minimumDIffX),
            }}
          />
          {/* 親要素にonDropを定義しており、親要素の幅を以下により広げることにより?、onDropが発火するようにするための要素。 */}
          <div
            style={{
              position: 'absolute',
              height: window.screen.height,
              width,
              // backgroundColor: '#5991F6',
              zIndex: 1,
              top: 0,
              left: diffX + width / 2,
              opacity: 0,
            }}
          />
        </>
      )}
    </>
  )
}
