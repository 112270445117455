import { compact } from '@salescore/buff-common'
import type { ReferenceTo, ViewQueryField, ViewQueryList } from '@salescore/core'

import { getNode } from '../../../state/nodeUtil'

export function getUserColumnNameFromReferenceTo(referenceTo: ReferenceTo[]): string | undefined {
  const columnNames = compact(referenceTo.map((r) => getUserColumnName(r.modelName)))
  if (columnNames.length > 0) {
    return columnNames[0]
  }
  return undefined
}

function getUserColumnName(modelName: string): string | undefined {
  // TODO: property側でユーザー参照かどうかを持ちたい
  switch (modelName) {
    case 'salesforce_user': {
      return 'salesforce_user_id'
    }
    case 'hubspot_owners': {
      return 'hubspot_owner_id'
    }
    case 'hubspot_users': {
      return 'hubspot_user_id'
    }
  }

  return undefined
}

export function getUserColumnNameFromField(field: ViewQueryField, query: ViewQueryList) {
  const labelNodePath = field.read.labelNodePath ?? []
  if (labelNodePath.isBlank()) {
    return
  }

  const labelNode = getNode(query.tree, labelNodePath)
  if (labelNode === undefined) {
    return
  }

  const tableName = labelNode.read.tableSql

  return getUserColumnName(tableName)
}
