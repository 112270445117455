import { EditOutlined, ExclamationCircleOutlined, PlayCircleOutlined, SwapOutlined } from '@ant-design/icons'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { isSome } from '@salescore/buff-common'
import {
  DeleteReverseEltDocument,
  FetchReverseEltsDocument,
  QueueReverseEltJobDocument,
  type ReverseEltFieldsFragment,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { createDestroyColumn, useModal } from '@salescore/frontend-common'
import { Button, Dropdown, Menu, message, Modal, Table } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useState, useTransition } from 'react'

import { UpsertReverseEltForm } from './UpsertReverseEltForm'

export function ReverseEltsTable() {
  const [deleteReverseEltMutation] = useMutation(DeleteReverseEltDocument)
  const [queue] = useMutation(QueueReverseEltJobDocument)
  const editModal = useModal<{ reverseElt: ReverseEltFieldsFragment }>()
  const [destroyLoading, setDestroyLoading] = useState(false)
  const { data, refetch } = useSuspenseQuery(FetchReverseEltsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const { reverseElts, eltModels, destinations } = data
  const [loading, startTransition] = useTransition()
  const invalidate = () => {
    startTransition(async () => {
      await refetch()
    })
  }
  const { reverseEltJobModal } = recoil.setting.useReverseEltRelated()

  const execute = async (reverseEltId: string, syncMode: 'BULK' | 'INCREMENTAL') => {
    try {
      const result = await queue({
        variables: {
          reverseEltId,
          organizationId: getOrganizationIdFromPath(),
          syncMode,
        },
      })
      void message.success(t(`ジョブを開始しました。`))
      if (isSome(result.data) && isSome(result.data.queueReverseEltJob)) {
        reverseEltJobModal.showModal({ jobId: result.data.queueReverseEltJob.id })
      }
    } catch (error) {
      if (error instanceof Error) {
        void message.error(error.message)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    }
  }

  return (
    <>
      <Table
        loading={loading}
        dataSource={reverseElts}
        columns={[
          {
            title: t(`設定名`),
            dataIndex: `name`,
          },
          {
            title: t(`データベース`),
            render(_, record) {
              return <span>{record.destination.provider}</span>
            },
          },
          {
            title: t(`更新するモデル`),
            render(_, record) {
              return <span>{record.eltModel.model.label}</span>
            },
          },
          {
            title: t(`同期時刻`),
            render(_, record) {
              return <span>{dayjs(record.syncedAt).format()}</span>
            },
          },
          {
            title: ``,
            key: `edit`,
            width: 100,
            render: (_, record) => (
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  editModal.showModal({ reverseElt: record })
                }}
              >
                {t(`編集`)}
              </Button>
            ),
          },
          {
            title: ``,
            key: `queue`,
            width: 100,
            render: (_, record) => (
              <Dropdown
                overlay={
                  <Menu
                    items={[
                      {
                        key: 'incremental',
                        label: (
                          <Button
                            className="w-full text-left"
                            type="text"
                            key="incremental"
                            icon={<SwapOutlined />}
                            onClick={() => {
                              void execute(record.id, 'INCREMENTAL')
                            }}
                          >
                            {t(`差分のみ更新`)}
                          </Button>
                        ),
                      },
                      {
                        key: 'bulk',
                        label: (
                          <Button
                            className="w-full text-left"
                            type="text"
                            key="bulk"
                            icon={<ExclamationCircleOutlined />}
                            onClick={() => {
                              void execute(record.id, 'BULK')
                            }}
                          >
                            {t(`全てのレコードを更新`)}
                          </Button>
                        ),
                      },
                    ]}
                  />
                }
                trigger={['click']}
                arrow
              >
                <Button type="primary" icon={<PlayCircleOutlined />}>
                  {t(`実行`)}
                </Button>
              </Dropdown>
            ),
          },
          createDestroyColumn(destroyLoading, setDestroyLoading, async (record: ReverseEltFieldsFragment) => {
            setDestroyLoading(true)
            await deleteReverseEltMutation({
              variables: {
                id: record.id,
                organizationId: getOrganizationIdFromPath(),
              },
            })
            invalidate()
            void message.success(t(`削除しました`))
            setDestroyLoading(false)
          }),
        ]}
      />
      <Modal
        open={editModal.isModalVisible}
        onCancel={editModal.hideModal}
        width={'60%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
        destroyOnClose
      >
        {editModal.content !== undefined && (
          <UpsertReverseEltForm
            reverseElt={editModal.content.reverseElt}
            onAfterFinish={() => {
              invalidate()
              editModal.hideModal()
            }}
          />
        )}
      </Modal>
    </>
  )
}
