// グラフなどで使うカラーパレットをここで定義しておく
// https://tailwindcss.com/docs/customizing-colors#color-palette-reference
const tailwindcssColors400 = [
  '#22D3EE', // cyan
  '#38BDF8', // sky
  '#60A5FA', // blue
  '#818CF8', // indigo
  '#A78BFA', // violet
  '#C084FC', // purple
  '#E879F9', // fuchisia
  '#F472B6', // pink
  '#FB7185', // rose
  '#F87171', // red
  '#FB923C', // orange
  '#FBBF24', // amber
  '#FACC15', // yellow
  '#A3E635', // lime
  '#4ADE80', // green
  '#34D399', // emerald
  '#2DD4BF', // teal
]

const tailwindcssColors300 = [
  '#67E8F9', // cyan
  '#7DD3FC', // sky
  '#93C5FD', // blue
  '#A5B4FC', // indigo
  '#C4B5FD', // violet
  '#D8B4FE', // purple
  '#F0ABFC', // fuchisia
  '#F9A8D4', // pink
  '#FDA4AF', // rose
  '#FCA5A5', // red
  '#FDBA74', // orange
  '#FCD34D', // amber
  '#FDE047', // yellow
  '#BEF264', // lime
  '#86EFAC', // green
  '#6EE7B7', // emerald
  '#5EEAD4', // teal
]

export const CONSTANT = {
  colors: {
    primaryColor: '#376DF8',
    primaryColorLight: '#CBD1D8',
    SUCCESS_COLOR: '#38A169',
    SUCCESS_COLOR_BLUE: '#2563EB',
    colorSeries: [
      // カラーパレットの隣り合う色は、判別が難しいため、1つ飛ばしにしたものを色列とする
      ...tailwindcssColors400.filter((_x, i) => i % 2 === 0),
      ...tailwindcssColors400.filter((_x, i) => i % 2 === 1),
    ],
    border: `#E9EBF0`,
    backgroundGray: `#FAFBFC`,
  },
  colorClasses: {
    SUCCESS_COLOR: 'text-green-600', // このように動的に指定していると、purge cssが認識できない可能性があるため注意。DefaultLayoutで対応している。
    SUCCESS_COLOR_BLUE: 'text-blue-600',
    DANGER_COLOR: 'text-red-600',
    NORMAL_COLOR: 'text-gray-700',
    ADMIN_TEXT_COLOR: 'text-blue-800',
  },
  hub: {
    TOTAL_WORD: '_TOTAL_',
    NULL_WORD: '_NULL_',
    KPI_OWNER: 'KPI_OWNER',
    KPI_DATE: 'KPI_DATE',
    KPI_DATE_MONTH: 'KPI_DATE_MONTH',
    KPI_DATE_WEEK: 'KPI_DATE_WEEK',
    KPI_OWNER_TEAM: 'KPI_OWNER_TEAM',
    KPI_ID: 'KPI_ID',
    KPI_KIND: 'KPI_KIND',
    REPORT_KIND: 'REPORT_KIND',
    KPI_VALUE: 'KPI_VALUE',
    KPI_GOAL_VALUE: 'KPI_GOAL_VALUE',
    GROUPING_BIT: '_grouping_bit',
    SALESCORE_USER_ID: 'SALESCORE_USER_ID',
    SALESCORE_USER_GROUP_ID: 'SALESCORE_USER_GROUP_ID',
    SALESCORE_USER_GROUP1_ID: 'SALESCORE_USER_GROUP1_ID',
    SALESCORE_USER_GROUP_DEPTH_N: 'salescore_user_group%d',
    SALESCORE_USER_GROUP_DEPTH_N_PREFIX: 'salescore_user_group',
    SALESCORE_USER_TABLE_NAME: 'salescore_users',
    SALESCORE_USER_VISIBILITY_COLUMN_NAME: 'visibility',
    SALESCORE_USER_USER_GROUP_COLUMN_NAME: 'user_group_id',
    SALESCORE_USER_GROUP_TABLE_NAME: 'salescore_user_groups',
    SALESCORE_USER_GROUP_DEPTH_COLUMN_NAME: 'depth',
    SALESCORE_USER_GROUP_PARENT_GROUP_ID_COLUMN_NAME: 'user_group_id',
    GOAL_STREAM_NAME: 'salescore_goals',
  },
  link: {
    help: 'https://www.notion.so/SALESCORE-1a42cb0d65f34a3ab97e6e12cbe6b836',
    helpV2: 'https://intercom.help/salescore/ja',
    helpForHighlight:
      'https://intercom.help/salescore/ja/articles/8553539-%E3%83%8F%E3%82%A4%E3%83%A9%E3%82%A4%E3%83%88%E6%9D%A1%E4%BB%B6-%CE%B2%E7%89%88-%E3%81%AE%E5%B1%A5%E6%AD%B4%E3%82%AA%E3%83%96%E3%82%B8%E3%82%A7%E3%82%AF%E3%83%88%E3%81%AE%E5%90%8C%E6%9C%9F%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-salesforce%E3%81%AE%E3%81%BF',
  },
  localStorageKey: {
    PARTIAL_AUTH0_RESPONSE: 'PARTIAL_AUTH0_RESPONSE',
  },
}
