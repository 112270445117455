import { treeUtil } from '@salescore/buff-common'
import type { ViewConfigTreeNode } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { hasChangeToViewSchemaAtom } from '../../atoms'
import { viewSelector } from '../../selectors/viewSelector'
import { setConfigMutation } from '../setConfigMutation'

export const editNodeMutation = mutation<{
  node: ViewConfigTreeNode
  f: (oldNode: ViewConfigTreeNode) => void // 破壊的操作
}>({
  key: `view/editNodeMutation`,
  set({ get, set }, { node, f }) {
    const { config } = get(viewSelector)
    const tree = config.tree
    if (tree === undefined) {
      // 初期化はsetRootNodeにより終わっているはず
      return
    }

    const newTree = treeUtil.replace(tree, node.name, f)

    set(setConfigMutation, {
      ...config,
      tree: newTree,
    })
    set(hasChangeToViewSchemaAtom, true)
  },
})
