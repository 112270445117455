import { isNull } from '@salescore/buff-common'
import { FetchPoliciesDocument } from '@salescore/client-api'
import { type PolicyAction, type PolicySubject, UserAbility } from '@salescore/features'
import { atomFamily, selector, selectorFamily, useRecoilValue, useSetRecoilState } from 'recoil'

import { apolloClientAtom, meAtom } from '../atoms'

const abilitySelector = selector<UserAbility>({
  key: `common/abilitySelector`,
  get: ({ get }) => {
    const me = get(meAtom)
    if (me === undefined) {
      // 通常の処理フローではここに到達することはない
      throw new Error('user not logged in')
    }
    const client = get(apolloClientAtom)

    return new UserAbility(
      {
        id: me.myUser.id,
        role: me.myUser.role === 'guest' ? 'member' : me.myUser.role,
        organizationId: me.organization.id,
      },
      {
        resolve: async ({ action, subject }) => {
          // 権限チェックが必要になる度に通信を行う
          // できるだけ通信回数を減らすため、actionなしでfetchし、Apollo Clientのレイヤーでキャッシュを貯める
          if (subject === undefined) {
            // NOTE: コード(defaultPolicies.ts)でのポリシー管理から脱却し、
            //       デフォルト/カスタム権限の両方をPolicyテーブルから取得する。
            //       BEで組織紐づかないデフォルト権限用のOR条件(organizationId=null)を加えている
            const policies = await client.query({
              query: FetchPoliciesDocument,
              variables: {
                organizationId: me.organization.id,
              },
              fetchPolicy: 'cache-first',
            })
            return policies.data.policies.filter((x) => x.action === action && isNull(x.subject)).map((x) => ({ ...x }))
          }

          const policies = await client.query({
            query: FetchPoliciesDocument,
            variables: {
              organizationId: me.organization.id,
              subjectId: subject.id,
              subjectType: subject.entityType,
            },
            fetchPolicy: 'cache-first',
          })
          return policies.data.policies
            .filter((x) => x.action === action)
            .map((x) => ({ ...x, subject: x.subject ?? undefined }))
        },
      },
    ) // TODO
  },
})

// subjectごとにinvalidateするためのatom
const abilityRequestIdForSubjectAtom = atomFamily<number, { subject?: PolicySubject }>({
  key: `common/abilityRequestIdForSubjectAtom`,
  default: 0,
})

export const abilitySelectorFamily = selectorFamily<boolean, { action: PolicyAction; subject?: PolicySubject }>({
  key: `common/abilitySelectorFamily`,
  get:
    ({ action, subject }) =>
    async ({ get }) => {
      get(
        abilityRequestIdForSubjectAtom({
          subject: subject === undefined ? undefined : { id: subject.id, entityType: subject.entityType },
        }),
      ) // createdByIdがnullだったりundefinedだったりの違いでキャッシュが適切にinvalidateされないことがあるので、わざわざここで詰め替えている
      const ability = get(abilitySelector)
      return await ability.can(action, subject)
    },
})

export const useCan = (action: PolicyAction, subject?: PolicySubject) =>
  useRecoilValue(abilitySelectorFamily({ action, subject }))

// PolicyFormからの仕様を想定した、invalidateのためのhooks
export const useInvalidateAbility = (subject?: PolicySubject) => {
  const set = useSetRecoilState(
    abilityRequestIdForSubjectAtom({
      subject: subject === undefined ? undefined : { id: subject.id, entityType: subject.entityType },
    }),
  )
  const invalidate = () => {
    set((previous) => previous + 1)
  }
  return invalidate
}
