import { isPresent } from '@salescore/buff-common'

const isDebugSkipAuth = process.env.NODE_ENV === 'development' && process.env.DEBUG_SKIP_AUTHORIZATION !== undefined

export function useSalescoreAuth() {
  const token = isDebugSkipAuth ? `dummy` : sessionStorage.getItem('jwt')

  return {
    token,
    isSignedInBySalescoreAuth: isPresent(token) && !isDebugSkipAuth,
    setToken: (token: string) => {
      sessionStorage.setItem('jwt', token)
    },
    clearToken: () => {
      sessionStorage.removeItem('jwt')
    },
  }
}
