import { useOrganizationId } from './atoms'
import { useMe } from './hooks'
import { initializeState } from './initializer'
import { launcher } from './launcher'
import { abilitySelectorFamily, useCan, useInvalidateAbility } from './policy/useCan'
import { useCanForFeatures } from './policy/useCanForFeature'
import { abilityForViewSelectorFamily, useCanForView } from './policy/useCanForView'
import { view } from './view'

export const globalRecoil = {
  useMe,
  view,
  launcher,
  initializeState,
  useOrganizationId,
  policy: {
    useCan,
    useCanForView,
    useInvalidateAbility,
    abilitySelectorFamily,
    abilityForViewSelectorFamily,
    useCanForFeatures,
  },
  // @deprecated
  useCan,
  // @deprecated
  useCanForView,
  // @deprecated
  useInvalidateAbility,
  // @deprecated
  abilitySelectorFamily,
  // @deprecated
  abilityForViewSelectorFamily,
}
