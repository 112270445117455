import { Alert } from 'antd'
import { t } from 'i18next'

export function VisualizeUserPlanNotFound() {
  return (
    <div className="p-8">
      <Alert
        showIcon
        type="warning"
        message={
          <div>
            {t(`この機能はVISUALIZEプランをご契約の方のみご利用可能です。`)}
            <br />
            {t(`組織の管理者に、VISUALIZEプランの契約とライセンス割り当てを依頼してください。`)}
          </div>
        }
      />
    </div>
  )
}
