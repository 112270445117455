import { simpleLogger } from '../misc/simpleLogger'

const createTableSql = /* sql */ `
CREATE TABLE IF NOT EXISTS salescore_goals_v2 (
	"goal_config_id" TEXT NOT NULL COLLATE "ja-x-icu",
	"date" DATE NOT NULL,
	"kpi_id" TEXT NOT NULL COLLATE "ja-x-icu",
	"user_id" TEXT NOT NULL COLLATE "ja-x-icu",
	"dimension1" TEXT NOT NULL COLLATE "ja-x-icu",
	"dimension2" TEXT NOT NULL COLLATE "ja-x-icu",
	"dimension3" TEXT NOT NULL COLLATE "ja-x-icu",
	"dimension4" TEXT NOT NULL COLLATE "ja-x-icu",
	"dimension5" TEXT NOT NULL COLLATE "ja-x-icu",
	"value" NUMERIC NOT NULL,
	"created_at" TIMESTAMP(3) NOT NULL DEFAULT CURRENT_TIMESTAMP,
	"updated_at" TIMESTAMP(3) NOT NULL DEFAULT CURRENT_TIMESTAMP
);`

// パフォーマンス面で、インデックスを貼るべきは以下
// 1. 目標入力時の表示: goal_config_id, dateのインデックス -> 以下のunique index
// 2. 目標の初期化(作成), 入力: ユニークキー -> 以下のunique index
const uniqueIndexSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS salescore_goals_v2_uidx ON salescore_goals_v2 (goal_config_id, date, kpi_id, user_id, dimension1, dimension2, dimension3, dimension4, dimension5);`
// 3. 目標の初期化(削除): goal_config_idと date, user_id, dimensionsでそれぞれに貼るべき。（dateはunique indexで完了済み)
const userIndexSql = /* sql */ `CREATE INDEX IF NOT EXISTS salescore_goals_v2_idx_user ON salescore_goals_v2 (goal_config_id, user_id);`
const d1IndexSql = /* sql */ `CREATE INDEX IF NOT EXISTS salescore_goals_v2_idx_d1 ON salescore_goals_v2 (goal_config_id, dimension1);`
const d2IndexSql = /* sql */ `CREATE INDEX IF NOT EXISTS salescore_goals_v2_idx_d2 ON salescore_goals_v2 (goal_config_id, dimension2);`
const d3IndexSql = /* sql */ `CREATE INDEX IF NOT EXISTS salescore_goals_v2_idx_d3 ON salescore_goals_v2 (goal_config_id, dimension3);`
const d4IndexSql = /* sql */ `CREATE INDEX IF NOT EXISTS salescore_goals_v2_idx_d4 ON salescore_goals_v2 (goal_config_id, dimension4);`
const d5IndexSql = /* sql */ `CREATE INDEX IF NOT EXISTS salescore_goals_v2_idx_d5 ON salescore_goals_v2 (goal_config_id, dimension5);`

export async function createGoalsTable(query: (sql: string) => Promise<void>) {
  await query(createTableSql)
  // 冪等にしたいが、インデックスが存在するとエラーになるので無視（丁寧にケアすれば可能だがやらない）
  const queryAndIgnoreError = async (sql: string) => {
    try {
      await query(sql)
    } catch (error) {
      simpleLogger.warn(error)
    }
  }
  await queryAndIgnoreError(uniqueIndexSql)
  await queryAndIgnoreError(userIndexSql)
  await queryAndIgnoreError(d1IndexSql)
  await queryAndIgnoreError(d2IndexSql)
  await queryAndIgnoreError(d3IndexSql)
  await queryAndIgnoreError(d4IndexSql)
  await queryAndIgnoreError(d5IndexSql)
}
