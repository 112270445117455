import {
  makeIdentifiable,
  NOT_FOUND_FIELD_NAME_PREFIX,
  POSTGRES_COLUMN_LENGTH_LIMIT,
  pruneSheetConfigTree,
  type ViewQueryField,
} from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { meRelatedSelector } from '../../selectors/meRelatedSelector'
import { viewSelector } from '../../selectors/viewSelector'
import { setConfigMutation } from '../setConfigMutation'
import { isSameField } from './util'

export const removeFieldMutation = mutation({
  key: `view/removeFieldMutation`,
  set({ get, set }, { field }: { field: ViewQueryField }) {
    const { permission, me } = get(meRelatedSelector)
    if (!permission.view.canEditField) {
      return // 本来辿り着かないはずなので、特にメッセージなどは出さない（出したいが、recoilの中でメッセージを表示させる機構が現状ない）
    }
    const isNotFoundField = field.name.startsWith(NOT_FOUND_FIELD_NAME_PREFIX) // TODO

    const { config } = get(viewSelector)
    // TODO: このレイヤでnode,model,propertyのチェックは行うか？
    // const { streams } = get(connectionsSelector)
    // const { getNodeName } = get(querySelector)
    const configFields = config.fields ?? []
    const newConfig = {
      ...config,
      fields: isNotFoundField
        ? configFields.filter(
            (configField) =>
              makeIdentifiable(
                [NOT_FOUND_FIELD_NAME_PREFIX, configField.property.nodeName, configField.property.propertyName].join(
                  '_',
                ),
                POSTGRES_COLUMN_LENGTH_LIMIT,
              ) !== field.name,
          )
        : configFields.filter((configField) => !isSameField(configField, field)),
    }
    const prunedConfigTree = pruneSheetConfigTree(newConfig)

    set(setConfigMutation, {
      ...newConfig,
      tree: prunedConfigTree,
    })
  },
})
