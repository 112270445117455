import { isNull } from '@salescore/buff-common'
import { CORE_CONSTANT, type ViewConfigDimension, type WaterfallDimension } from '@salescore/core'
import type { Period } from '@salescore/features'
import { Form } from 'antd'
import { t } from 'i18next'
import { type Dispatch, type ReactNode, type SetStateAction, useEffect } from 'react'
import { type SetterOrUpdater, useRecoilValue } from 'recoil'

import { riDisplayFormatAtom } from '../../../../recoil/navigation/atoms'
import { useKpiFormAtom } from '../../../../recoil/view/hooks'
import {
  useKpiFormEffectsForKpiTimeSeries,
  useKpiFormSelectors,
} from '../../../../recoil/view/selectors/kpiFormSelector'
import { DimensionPicker } from '../../ViewUIKpiPivot/DimensionPicker'
import { KpiFormChildNodeFormItem } from '../../ViewUIKpiPivot/KpiForm/KpiFormChildNodeFormItem'
import { KpiFormDateFormItem } from '../../ViewUIKpiPivot/KpiForm/KpiFormDateFormItem'
import { KpiFormFilterTreeFormItem } from '../../ViewUIKpiPivot/KpiForm/KpiFormFilterTreeFormItem'
import { KpiFormMeasureFormItem } from '../../ViewUIKpiPivot/KpiForm/KpiFormMeasureFormItem'
import { KpiFormRootNodeFormItem } from '../../ViewUIKpiPivot/KpiForm/KpiFormRootNodeFormItem'
import { KpiFormUserFormItem } from '../../ViewUIKpiPivot/KpiForm/KpiFormUserFormItem'
import { useDimensions, useKpiTimeSeriesConfig } from '../hooks'
import { AggregationDayFormInput } from './AggregationDayFormInput'
import { PeriodFormInput } from './PeriodFormInput'
import { type TimeSpan, TimeSpanFormInput } from './TimeSpanFormInput'
import { ViewUiRiWaterfallDimensionPicker } from './ViewUiRiWaterfallDimensionPicker'

export function ViewUiRiAggregationSetting(properties: {
  period: Period | undefined
  timeSpan: TimeSpan | undefined
  breakdownProperty: ViewConfigDimension | undefined
  waterfallDimensions: WaterfallDimension[] | undefined
  setPeriod: SetterOrUpdater<Period | undefined>
  setTimeSpan: Dispatch<SetStateAction<TimeSpan | undefined>>
  setBreakdownProperty: Dispatch<ViewConfigDimension | undefined>
  setWaterfallDimensions: SetterOrUpdater<WaterfallDimension[] | undefined>
}): ReactNode {
  const { waterfallDimensions, setWaterfallDimensions } = properties
  const config = useKpiTimeSeriesConfig()
  const [, setKpiForm] = useKpiFormAtom()
  const format = useRecoilValue(riDisplayFormatAtom)

  useEffect(() => {
    if (config.kpiFragment === undefined) {
      return
    }
    setKpiForm(config.kpiFragment)
  }, [config, setKpiForm])

  useKpiFormEffectsForKpiTimeSeries()

  const { form, childModelsSelectOptions } = useKpiFormSelectors()
  const isRootNodeSet = form.sheet?.type === 'sheet'

  const dimensions = useDimensions()

  return (
    <div className="space-y-2 py-4">
      <div className="text-xl font-bold">{t(`集計設定`)}</div>
      <div>
        <div className="font-bold">{t(`集計オブジェクト`)}</div>
        {/* TODO: フォームの要素をKPIから流用しているが、kpiFormAtomに依存してしまうのでやめたい */}
        <KpiFormRootNodeFormItem
          snapshotMode
          sheetDefaultProperties="all"
          onAfterChange={() => {
            setWaterfallDimensions((old) => old?.map((x) => ({ ...x, property: undefined })))
          }}
        />
        <KpiFormChildNodeFormItem
          unDeletableFilter={(node) => {
            const originalModelIdNode = childModelsSelectOptions.find(
              (x) => x.referenceToProperty.propertyName === `id`,
            )?.existedNode

            return (
              node.modelName.endsWith(CORE_CONSTANT.SNAPSHOT_SEQUENCE_TABLE_SUFFIX) ||
              node.modelName === originalModelIdNode?.modelName
            )
          }}
          onAfterChange={(picked) => {
            if (isNull(waterfallDimensions)) {
              return
            }
            const newDimensions = waterfallDimensions.map((dimension) => {
              const isMissingProperty = picked.every((node) => node.modelName !== dimension.property?.modelName)
              return {
                ...dimension,
                property: isMissingProperty ? undefined : dimension.property,
              }
            })
            setWaterfallDimensions(newDimensions)
          }}
          onAfterRemove={(node) => {
            if (isNull(waterfallDimensions)) {
              return
            }
            const newDimensions = waterfallDimensions.map((dimension) => {
              const isMissingProperty = node.modelName === dimension.property?.modelName
              return {
                ...dimension,
                property: isMissingProperty ? undefined : dimension.property,
              }
            })
            setWaterfallDimensions(newDimensions)
          }}
        />

        <div className="font-bold">{t(`横軸`)}</div>
        {format === 'timeSeries' && (
          <>
            <KpiFormDateFormItem disabled={true} formKey="date" />
            <Form.Item label={t(`期間`)}>
              <PeriodFormInput period={properties.period} setPeriod={properties.setPeriod} />
              <div className="mt-2">
                <TimeSpanFormInput timeSpan={properties.timeSpan} setTimeSpan={properties.setTimeSpan} />
              </div>
            </Form.Item>
          </>
        )}

        {format === 'waterfall' && (
          <Form.Item label={t(`項目`)}>
            <ViewUiRiWaterfallDimensionPicker
              disabled={!isRootNodeSet}
              waterfallDimensions={properties.waterfallDimensions}
              setWaterfallDimensions={properties.setWaterfallDimensions}
            />
          </Form.Item>
        )}

        <div className="font-bold">{t(`縦軸`)}</div>
        {/* TODO: 以下のフィールドを、 kpiConfig.dateY を変更するフィールドに変える */}
        <KpiFormDateFormItem disabled={!isRootNodeSet} formKey="dateY" />
        <KpiFormMeasureFormItem snapshotMode disabled={!isRootNodeSet} />
        {format === 'timeSeries' && <AggregationDayFormInput />}
        <KpiFormUserFormItem disabled={!isRootNodeSet} />
        <KpiFormFilterTreeFormItem disabled={!isRootNodeSet} />

        {format === 'timeSeries' && (
          <Form.Item label={t(`内訳`)}>
            <DimensionPicker
              dimensions={dimensions}
              currentDimension={properties.breakdownProperty}
              onChange={properties.setBreakdownProperty}
              allowClear
              stretch
            />
          </Form.Item>
        )}
      </div>
    </div>
  )
}
