import { useEffect, useState } from 'react'

// 描画を1tick遅らせるためのラッパー
export function NextTickRender({ children, fallback }: { children: JSX.Element; fallback?: JSX.Element }) {
  const [flag, setFlag] = useState(false)
  useEffect(() => {
    setFlag(true)
  }, [])

  if (!flag) {
    return fallback ?? <></>
  }

  return <>{children}</>
}
