import { useResize } from '@salescore/frontend-common'
import { Card } from 'antd'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'

interface ActivityChartProperties {
  title: string
  color: string
  dataset: {
    userNames: string[]
    values: number[]
  }
}

interface ChartViewProperties {
  width: number
  barThickness: number
}

export const ActivityChart = ({ title, color, dataset }: ActivityChartProperties) => {
  const { ref, rect } = useResize()
  const containerWidth = rect?.width ?? 0
  const chartViewProperties = useMemo(() => {
    const barThickness = 8 // 棒の太さ
    const chartWidth = barThickness * 3 * dataset.userNames.length // thicknessが8で、ここを2.9にしたときに、300レコードの棒グラフが1件1件表示された（2.5より下げると、1件飛ばしになる）
    if (containerWidth > chartWidth) {
      // 画面幅がグラフ幅より大きい場合はグラフ幅を画面幅に合わせる
      const thickness = Math.min(Math.max(20 * (1 - chartWidth / containerWidth), 10), 20)
      return { width: containerWidth, barThickness: thickness }
    }
    return { width: chartWidth, barThickness }
  }, [containerWidth, dataset])

  const options = {
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    animation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    barThickness: chartViewProperties.barThickness,
    maxBarThickness: 20,
  }

  const data = {
    labels: dataset.userNames,
    datasets: [
      {
        label: title,
        data: dataset.values,
        backgroundColor: color,
      },
    ],
  }

  return (
    <Card title={title}>
      <div style={{ width: '100%', position: 'relative', overflowX: 'scroll' }} ref={ref}>
        <div style={{ width: chartViewProperties.width.toString() + 'px', height: '320px', position: 'relative' }}>
          <Bar data={data} options={options} style={{ position: 'absolute', top: '0', left: '0' }} />
        </div>
      </div>
    </Card>
  )
}
