import { CORE_CONSTANT } from '../../../../../constant'
import type { NodePath, ViewQueryField, ViewQueryMeasure, ViewQueryNode } from '../../../../../schemas/query'
import type {
  NodePropertyName,
  ViewConfigKpiMeasure,
  ViewConfigMeasure,
  ViewConfigPresetKpiMeasure,
  ViewConfigPresetMeasure,
  ViewConfigSheet,
  ViewConfigTreeNode,
} from '../../../../../schemas/view_config'
import { NotImplementedError } from '../../../../errors'
import { generateFilterSql } from '../../../../query/executeViewQuery/generateSql'
import type { CompileContext } from '../../../common'
import { generatePropertySql } from '../compileSheetViewConfigFields'
import { compileSheetViewConfigFilterTree } from '../compileSheetViewConfigFilterTree'

export function compileMeasure(
  measure: ViewConfigMeasure | ViewConfigKpiMeasure,
  sheet: ViewConfigSheet,
  argument: CompileFilterArgument,
  context: CompileContext,
): (ViewQueryMeasure & { nodePaths: NodePath[] }) | undefined {
  const type = measure.type
  switch (type) {
    case 'preset': {
      const field = (sheet.fields ?? []).find((field) => field.measures?.some((x) => x.name === measure.name) ?? false)
      if (field === undefined) {
        context.logs.error(`集計項目のコンパイルに失敗しました。項目名: ${measure.name}`)
        return undefined
      }
      const result = compileMeasurePreset(measure, field.property, argument, context)
      if (result === undefined) {
        context.logs.error(`集計項目のコンパイルに失敗しました。項目名: ${measure.name}`)
        return undefined
      }

      return {
        name: measure.name,
        label: measure.label,
        ...result,
      }
    }
    case 'custom': {
      return {
        name: measure.name,
        label: measure.label ?? measure.name,
        sql: measure.sql,
        nodePaths: [],
      }
    }
    case 'kpiPreset': {
      const result = compileMeasurePreset(measure, measure.property, argument, context)
      if (result === undefined) {
        context.logs.error(`集計項目のコンパイルに失敗しました。項目名: KPI`)
        return undefined
      }
      return {
        name: CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME,
        label: `kpi`,
        ...result,
      }
    }
    case 'kpiCustom': {
      return {
        name: CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME,
        label: `kpi`,
        sql: measure.sql,
        nodePaths: [],
      }
    }
    default: {
      throw new Error(type satisfies never)
    }
  }
}

export function compileMeasurePreset(
  measure: ViewConfigPresetMeasure | ViewConfigPresetKpiMeasure,
  property: NodePropertyName,
  argument: CompileFilterArgument,
  context: CompileContext,
) {
  const sql = generatePropertySql({ ...property })
  const node = argument.queryNodes.find((x) => x.name === property.nodeName)
  if (node === undefined) {
    context.logs.error(
      `[compileMeasurePreset] ノードが見つかりません。 ${property.nodeName}, measureName: ${
        measure.type === 'preset' ? measure.name : `kpi`
      }, nodes: ${argument.queryNodes.map((x) => x.name).join(',')}`,
    ) // ありえないはず
    return
  }
  switch (measure.function) {
    case 'count': {
      return {
        sql: `COUNT(${generateAggregationArgument(`"${property.nodeName}"."id"`, measure, argument, context)})::numeric`, // idカラムがあることに暗黙的に依存している。expにしても良いが、もともとNULLが入っているケースの扱いが微妙
        nodePaths: [node.path],
      }
    }
    case 'sum': {
      return {
        sql: `SUM(${generateAggregationArgument(sql, measure, argument, context)})::numeric`,
        nodePaths: [node.path],
      }
    }
    case 'avg': {
      return {
        sql: `AVG(${generateAggregationArgument(sql, measure, argument, context)})::numeric`,
        nodePaths: [node.path],
      }
    }
    case 'countUnique': {
      return {
        sql: `COUNT(DISTINCT ${generateAggregationArgument(`"${property.nodeName}"."id"`, measure, argument, context)})::numeric`, // idカラムがあることに暗黙的に依存している。expにしても良いが、もともとNULLが入っているケースの扱いが微妙
        nodePaths: [node.path],
      }
    }
    case 'countUniqueValue': {
      return {
        sql: `COUNT(DISTINCT ${generateAggregationArgument(sql, measure, argument, context)})::numeric`,
        nodePaths: [node.path],
      }
    }
    case 'min': {
      return {
        sql: `MIN(${generateAggregationArgument(sql, measure, argument, context)})::numeric`,
        nodePaths: [node.path],
      }
    }
    case 'max': {
      return {
        sql: `MAX(${generateAggregationArgument(sql, measure, argument, context)})::numeric`,
        nodePaths: [node.path],
      }
    }
    default: {
      const x: never = measure.function
      throw new NotImplementedError(`not implemented measure function.`)
    }
  }
}

export interface CompileFilterArgument {
  queryNodes: ViewQueryNode[]
  configNodes: ViewConfigTreeNode[]
  fields: ViewQueryField[]
}

export function generateAggregationArgument(
  exp: string,
  measure: ViewConfigPresetMeasure | ViewConfigPresetKpiMeasure,
  argument: CompileFilterArgument,
  context: CompileContext,
): string {
  // フィルタがなければ、普通に使うだけ
  if (measure.type === 'kpiPreset' || measure.filterTree === undefined) {
    return exp
  }
  // フィルタがあるとき、CASE WHEN ... の構文にしてから返す
  const filter = compileSheetViewConfigFilterTree(
    measure.filterTree,
    argument.queryNodes,
    argument.configNodes,
    argument.fields,
    context,
  )
  const filterSql = generateFilterSql(filter, 0, {}) // ここにmustacheをいれることも考えると、ここでSQLを組み立てない方が良いか？
  if (filterSql === undefined) {
    return exp
  }
  return `CASE WHEN (${filterSql}) THEN ${exp} ELSE NULL END`
}
