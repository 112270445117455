import { selector, useRecoilValue } from 'recoil'

import { usersAtom } from '../atoms'

export const usersSelector = selector({
  key: `view/usersSelector`,
  get({ get }) {
    const users = get(usersAtom)

    return {
      users,
      usersMapper: users.groupBy((x) => x.resourceUser.id).transformValues((xs) => xs[0]!).data,
      salesforceUsersMapper: users.groupByUniqueKey((x) => x.resourceUser.salescore_user_id ?? '-'),
    }
  },
})

export const useUsersSelector = () => useRecoilValue(usersSelector)
