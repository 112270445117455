import type { CSSProperties } from 'react'

import { useRSheetMutation } from '../../recoil/mutations/useRSheetMutation'
import { useCursorSelector } from '../../recoil/selectors/cursorSelector'
import { getRecordNodeWithInnerRowIndex } from '../../util/innerRowRelated'
import { RSheetsStyle } from '../../util/RSheetsStyle'
import { InputCell } from '../body/cell/InputCell'

export function InputCursorBox() {
  const { finishEditMode } = useRSheetMutation()
  const { cursor, row, column, position } = useCursorSelector()
  if (position === undefined || column === undefined || cursor === undefined || row === undefined) {
    return <></>
  }

  const recordNodeWithInnerRowIndex = getRecordNodeWithInnerRowIndex(row, column.node, cursor.main.innerRowIndex)
  if (recordNodeWithInnerRowIndex === undefined) {
    return <></>
  }

  const { x, y, width, height } = position

  const style: CSSProperties = {
    position: 'absolute',
    top: y,
    left: x,
    width,
    height,
    borderRadius: 4,
    border: `2px #3274E0 solid`,
    boxShadow: '1px 4px 7px 2px rgb(60 64 67 / 15%)',
    zIndex: RSheetsStyle.zIndex.cursorWhenLeftFixed, // 編集の場合、常に最上位にする
  }

  return (
    <InputCell
      key={[recordNodeWithInnerRowIndex.recordNode?.id, cursor.main.rowIndex, cursor.main.innerRowIndex].join(',')}
      recordNode={recordNodeWithInnerRowIndex.recordNode}
      parentRecordNode={recordNodeWithInnerRowIndex.parentRecordNode}
      rootRecordNode={row}
      column={column}
      rowIndex={cursor.main.rowIndex}
      innerRowIndex={cursor.main.innerRowIndex}
      defaultWidth={width}
      boxStyle={style}
      finishEditMode={finishEditMode}
    />
  )
}
