import { CloseCircleOutlined } from '@ant-design/icons'
import type { SelectOption } from '@salescore/core'
import { Button, Dropdown, Menu, Select, Space, Tag } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

export function TagSelect({
  options,
  value,
  mode,
  allowClear,
  disabled,
  status,
  // style,
  onChange,
  onClick,
}: {
  options: SelectOption[]
  value: string
  mode?: 'multiple'
  allowClear?: boolean
  disabled?: boolean
  status?: 'error' | 'warning' // 現状multi selectでしか実装されていない
  // style?: CSSProperties
  onChange: (value?: string | number) => void
  onClick: () => void
}) {
  if (mode === 'multiple') {
    return (
      <Select
        mode="multiple"
        value={value}
        options={options}
        allowClear
        disabled={disabled}
        status={status}
        // style={
        //   errors.isBlank()
        //     ? {}
        //     : {
        //       border: `1px solid red`, // TODO: どうするのが適切か不明。4.19からstatusが追加されているので、これを使いたい。
        //     }
        // }
        onChange={onChange}
        onClick={onClick}
        tagRender={({ label, value, onClose }) => {
          const option = options.find((x) => x.value === value)
          if (option === undefined) {
            return <Tag>{value}</Tag>
          }
          return <SelectOptionTag option={option} showDestroy={true} onDestroy={onClose} />
        }}
      />
    )
  }

  const [visibility, setVisibility] = useState(false)
  const option = options.find((x) => x.value === value)
  return (
    <Dropdown
      className="w-full cursor-pointer hover:bg-gray-50"
      trigger={[`click`]}
      onVisibleChange={(v) => {
        setVisibility(v)
      }}
      overlay={
        <Menu
          // 新しいバージョンだといかが使えるはず
          // items={options.map(x => ({
          //   key: x.value,
          //   label: x.label
          // }))}
          disabled={disabled}
        >
          {options.map((option) => (
            <Menu.Item
              onClick={() => {
                if (value !== option.value) {
                  onChange(option.value)
                }
                setVisibility(false) // clickした時にonVisibleChangeが発火しなかったので…
              }}
              key={option.value}
            >
              {' '}
              <SelectOptionTag option={option} />
            </Menu.Item>
          ))}
        </Menu>
      }
    >
      <Space
        onClick={() => {
          onClick()
        }}
      >
        {option === undefined ? (
          <span className="text-gray-500">{t(`未設定`)}</span>
        ) : (
          <SelectOptionTag
            option={option}
            showDestroy={visibility && allowClear}
            onDestroy={() => {
              onChange()
            }}
          />
        )}
      </Space>
    </Dropdown>
  )
}

function SelectOptionTag({
  option,
  showDestroy,
  onDestroy,
}: {
  option: SelectOption
  showDestroy?: boolean
  onDestroy?: () => void
}) {
  return (
    <Tag
      color={option.color ?? undefined}
      style={{
        fontSize: 14,
        padding: '3px 10px',
        marginTop: -4,
      }}
    >
      {option.label}
      {showDestroy === true && onDestroy !== undefined && (
        <Button
          className="text-white opacity-75 hover:text-white hover:opacity-100"
          onClick={onDestroy}
          size="small"
          type="text"
          icon={<CloseCircleOutlined />}
        />
      )}
    </Tag>
  )
}
