import { MinusOutlined, PlusOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import {
  CreateGoalConfigDocument,
  FetchGoalConfigFormRelatedDocument,
  FetchUserGroupsDocument,
} from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import {
  ErrorContext,
  getOrganizationIdFromPath,
  HandleQuery,
  SuspenseWithLoading,
  ViewPicker,
} from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { goalConfigSchema } from '@salescore/core'
import { getQueryParameter, useModal } from '@salescore/frontend-common'
import { Button, Form, Input, message, Modal, Row, Select, Space, Tooltip } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { type ReactNode, useContext, useState, useTransition } from 'react'
import { z } from 'zod'

import { UpsertGoalDimensionForm } from '../settings/goal_dimension/UpsertGoalDimensionForm'

const GOAL_DIMENSION_MAX_COUNT = 5
const goalConfigForFormSchema = goalConfigSchema
  .omit({
    id: true,
    kpiViews: true,
    goalDimension1: true,
    goalDimension2: true,
    goalDimension3: true,
    goalDimension4: true,
    goalDimension5: true,
  })
  .merge(
    z.object({
      dashboardViewId: z.string(),
      goalDimension1Id: z.string().nullable().optional(),
      goalDimension2Id: z.string().nullable().optional(),
      goalDimension3Id: z.string().nullable().optional(),
      goalDimension4Id: z.string().nullable().optional(),
      goalDimension5Id: z.string().nullable().optional(),
      userAndGroupIdsConfig: z.object({ key: z.string(), value: z.string() }).array().optional(),
    }),
  )
type FormValue = z.infer<typeof goalConfigForFormSchema>

export function CreateGoalConfigForm({
  onAfterFinish,
}: {
  onAfterFinish: (goalId: string | undefined) => void
}): ReactNode {
  const [form] = useForm<FormValue>()
  const [createGoalConfigMutation, { loading }] = useMutation(CreateGoalConfigDocument)
  const errorContext = useContext(ErrorContext)
  const [userType, setUserType] = useState(`user`)
  const canManageGoal = recoil.global.policy.useCan('manage-goal')
  const queryDashboardViewId = getQueryParameter('dashboardViewId')

  const onFinish = (values: unknown): void => {
    const goalConfig = goalConfigForFormSchema.parse(values)
    // 目標軸は全て異なる必要がある
    const goalDimensionIds = [
      goalConfig.goalDimension1Id,
      goalConfig.goalDimension2Id,
      goalConfig.goalDimension3Id,
      goalConfig.goalDimension4Id,
      goalConfig.goalDimension5Id,
    ].compact()
    if (goalDimensionIds.length !== goalDimensionIds.unique().length) {
      void message.error(t(`目標軸が重複しています`))
      return
    }
    const userGroupIdsConfig =
      goalConfig.userAndGroupIdsConfig?.filter((x) => x.key.endsWith('group')).map((x) => x.value) ?? []
    const userIdsConfig =
      goalConfig.userAndGroupIdsConfig?.filter((x) => x.key.endsWith('user')).map((x) => x.value) ?? []
    if (goalConfig.userType === 'user' && [...userGroupIdsConfig, ...userIdsConfig].isBlank()) {
      void message.error(t(`目標を設定するチーム・ユーザーを指定してください`))
      return
    }
    if (goalConfig.userType === 'group' && [...userGroupIdsConfig].isBlank()) {
      void message.error(t(`目標を設定するチームを指定してください`))
      return
    }

    void createGoalConfigMutation({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        goalConfig: {
          dashboardViewId: goalConfig.dashboardViewId,
          name: goalConfig.name,
          goalDimension1Id: goalConfig.goalDimension1Id,
          goalDimension2Id: goalConfig.goalDimension2Id,
          goalDimension3Id: goalConfig.goalDimension3Id,
          goalDimension4Id: goalConfig.goalDimension4Id,
          goalDimension5Id: goalConfig.goalDimension5Id,
          timeSpanType: goalConfig.timeSpanType,
          userType: goalConfig.userType,
          userGroupIdsConfig,
          userIdsConfig,
        },
      },
      onCompleted: (data) => {
        void message.info(t(`目標を作成しました！`))
        Posthog.track(POSTHOG_EVENTS.create_goal_config, {})
        onAfterFinish(data.createGoalConfig.id) // redirect to 目標入力画面
      },
      onError: (error) => {
        errorContext.throwErrors(error)
      },
    })
  }

  const initialValues: Partial<FormValue> = {
    timeSpanType: 'month',
    userType: 'user',
    dashboardViewId: queryDashboardViewId ?? undefined,
  }

  if (!canManageGoal) {
    return <PageHeader title={t(`目標設定を作成`)}>{t(`設定権限がありません。`)}</PageHeader>
  }

  return (
    <PageHeader>
      <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
        <Form.Item name="name" rules={[{ required: true, message: t(`目標設定の名前を入力してください`) }]}>
          <Input
            size="large"
            bordered={false}
            placeholder="目標設定の名前を入力"
            style={{ fontSize: 24, fontWeight: 'bold' }}
          />
        </Form.Item>
        <Form.Item name="dashboardViewId" label={t(`ダッシュボード`)} required>
          <ViewPicker allowPrivate />
        </Form.Item>
        <Form.Item name="timeSpanType" label={t(`時間軸`)} required>
          <Select
            options={[
              {
                value: 'month',
                label: t(`月`),
              },
              {
                value: 'week',
                label: t(`週`),
              },
              {
                value: 'day',
                label: t(`日`),
              },
              // いったん必要なさそうという話になり削る
              // {
              //   value: 'none',
              //   label: '時間に依存しない目標',
              // },
            ]}
          />
        </Form.Item>
        <Form.Item name="userType" label={t(`ユーザー軸`)} required>
          <Select
            onChange={(v) => {
              setUserType(v as string)
            }}
            options={[
              {
                value: 'user',
                label: t(`ユーザー`),
              },
              {
                value: 'group',
                label: t(`ユーザーグループ`),
              },
              {
                value: 'none',
                label: t(`ユーザーに依存しない目標`),
              },
            ]}
          />
        </Form.Item>
        {userType === 'user' && (
          <>
            <UserAndGroupPickerFormItem />
          </>
        )}
        {userType === 'group' && (
          <>
            <UserAndGroupPickerFormItem label={t(`チームを指定`)} />
          </>
        )}
        <SuspenseWithLoading>
          <GoalConfigFormItems />
        </SuspenseWithLoading>
        <Form.Item>
          <Row justify="end">
            <Button type="primary" loading={loading} htmlType="submit">
              {t(`保存`)}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </PageHeader>
  )
}

function GoalConfigFormItems(): ReactNode {
  const goalDimensionModal = useModal()
  const { data, refetch } = useSuspenseQuery(FetchGoalConfigFormRelatedDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const goalDimensions = data.goalDimensions
  const [loading, startTransition] = useTransition()
  const invalidate = (): void => {
    startTransition(async () => {
      await refetch()
    })
  }

  const [inputGoalDimensions, setInputGoalDimensions] = useState<string[]>([])

  return (
    <>
      {inputGoalDimensions.map((dimension, index) => (
        <Form.Item key={index} name={`goalDimension${index + 1}Id`} label={t(`目標軸{{val}}`, { val: index + 1 })}>
          <Select
            allowClear
            value={dimension}
            loading={loading}
            options={goalDimensions.map((x) => ({ value: x.id, label: x.label }))}
            onChange={(value) => {
              setInputGoalDimensions((xs) => [...xs.slice(0, index), value, ...xs.slice(index + 1)])
            }}
          />
        </Form.Item>
      ))}
      <Tooltip title={t(`商品ごとやリードソースごと等ユーザー以外の軸で目標を作成したい場合`)}>
        <Button
          className="mr-2"
          disabled={inputGoalDimensions.length >= GOAL_DIMENSION_MAX_COUNT}
          icon={<PlusOutlined />}
          onClick={() => {
            setInputGoalDimensions([...inputGoalDimensions, ''])
          }}
        >
          {t(`目標軸を追加`)}
        </Button>
      </Tooltip>
      <Space />
      <Button
        disabled={inputGoalDimensions.isBlank()}
        icon={<MinusOutlined />}
        onClick={() => {
          inputGoalDimensions.pop()
          setInputGoalDimensions([...inputGoalDimensions])
        }}
      >
        {t(`目標軸を削除`)}
      </Button>
      <Button
        icon={<PlusOutlined />}
        type="text"
        className="text-sm text-blue-600"
        onClick={() => {
          goalDimensionModal.showModal()
        }}
      >
        {t(`目標軸の新規作成`)}
      </Button>
      <Modal
        key="editModal"
        open={goalDimensionModal.isModalVisible}
        onCancel={goalDimensionModal.hideModal}
        width={'70%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        style={{ top: '3%' }}
        destroyOnClose
      >
        <SuspenseWithLoading>
          <UpsertGoalDimensionForm
            onAfterFinish={() => {
              invalidate()
            }}
            withDimensionGroup
          />
        </SuspenseWithLoading>
      </Modal>
    </>
  )
}

const UserAndGroupPickerFormItemBody = ({ label }: { label?: string }): ReactNode => {
  const {
    data: { userGroups },
  } = useSuspenseQuery(FetchUserGroupsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })

  return (
    <Form.Item name="userAndGroupIdsConfig" label={label ?? t(`チーム・ユーザーを指定`)} required>
      <Select
        mode="multiple"
        filterOption={(input, option) =>
          ((option?.key as string) ?? '')
            .replace(option?.value as string, '')
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        labelInValue
      >
        {userGroups
          .mySortBy((x) => x.rank)
          .map((userGroup) => (
            <Select.Option value={userGroup.id} key={`${userGroup.id}-${userGroup.name}-group`}>
              <TeamOutlined />
              <span> {userGroup.name}</span>
            </Select.Option>
          ))}
        {label === undefined &&
          userGroups
            .flatMap((userGroup) => userGroup.users.map((user) => ({ userGroup, user })))
            // .filter((x) => x.user.visibility)
            .map(({ user, userGroup }) => (
              <Select.Option value={user.id} key={`${user.id}-${user.name}-${userGroup.name}-user`}>
                {/* <UserAvatar user={user}/> */}
                <UserOutlined />
                <span> {user.name}</span>
                <span className="ml-3 text-gray-600">
                  - {t(`現在`)}: {userGroup.name}
                  {/* {user.role === UserRoleEnum.UserOnIntegration && <>（未ログインユーザー）</>} */}
                </span>
              </Select.Option>
            ))}
      </Select>
    </Form.Item>
  )
}

export function UserAndGroupPickerFormItem({ label }: { label?: string }): ReactNode {
  return (
    <HandleQuery loadingElement={<Select loading />}>
      <UserAndGroupPickerFormItemBody label={label} />
    </HandleQuery>
  )
}
