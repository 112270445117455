// やや手間にはなるが、全てのイベントをここで定義することで、どんなイベントを定義しているかを一覧できるようにする
// また、極力イベント名の命名規則が統一されるようにする（統一することに大きな意味はない）
export const POSTHOG_EVENTS = {
  // 訪問イベント
  visit: 'visit',
  //
  // 旧設計
  //
  'click-syncConnectionButton': 'click-syncConnectionButton',
  'click-periodSelector': `click-periodSelector`,
  'click-periodSelector-dashboard': `click-periodSelector-dashboard`,
  'click-periodSelector-goal': `click-periodSelector-goal`,
  'click-Dashboard/Cell': 'click-Dashboard/Cell',

  //
  // 新設計(2023/05)
  // 全てv2系のトラッキング
  //
  // 閲覧系
  view_sheet: 'view_sheet', // シートが開かれたとき。 TODO: ViewComponentに実装しており、動的ビューも発火させてしまっている。（例えば、KPIの一覧を開いた時にview_sheetとしてもカウントされる）
  view_kpi: 'view_kpi', // KPIが開かれた時。ドリルダウンも含む
  view_dashboard: 'view_dashboard', // ダッシュボードが開かれた時
  view_highlight_conditional_effect_modal: 'view_highlight_conditional_effect_modal', // ハイライト条件のモーダルを開いた時
  view_sheet_custom_model_property_form: 'view_sheet_custom_model_property_form', // カスタム列のフォームを開いた時
  view_kpi_time_series_chart_panel: 'view_kpi_time_series_chart_panel', // KPIの時系列パネルを開いた時
  view_kpi_pie_chart_panel: 'view_kpi_pie_chart_panel', // KPIの円グラフパネルを開いた時
  view_kpi_pivot_table_panel: 'view_kpi_pivot_table_panel', // KPIのピボットテーブルパネルを開いた時
  view_kpi_bar_chart_panel: 'view_kpi_bar_chart_panel', // KPIの棒グラフパネルを開いた時

  // 新規作成、保存
  // 基本的に全ての処理が終わった後にイベントを仕込んでいるので、処理中にエラーがあればイベントは発火しない
  create_view_sheet: 'create_view_sheet', // シートの新規作成をしたとき TODO: 全ての導線をカバーしているか怪しい。
  create_view_kpi: 'create_view_kpi', // 同上
  create_view_dashboard: 'create_view_dashboard', // 同上
  create_goal_config: 'create_goal_config', // 目標種別を作成したとき
  create_sheet_custom_model_property: 'create_sheet_custom_model_property', // カスタム列を作成したとき
  create_custom_object: 'create_custom_object', // カスタムオブジェクトを新規作成
  create_salescore_object: 'create_salescore_object', // SALESCOREオブジェクトを新規作成
  save_kpi: 'save_kpi', // KPI設定で保存ボタンを押した
  save_goals: 'save_goals', // 目標入力で保存ボタンを押した
  save_records: 'save_records', // シートで保存ボタンを押した
  save_view_setting: 'save_view_setting', // Viewで設定を保存ボタンを押した
  save_highlight_conditional_effect: 'save_highlight_conditional_effect', // ハイライト条件の保存ボタンを押した
  save_goal_dimention: 'save_goal_dimention', // 目標軸の設定で保存ボタンを押した
  save_dimention_group: 'save_dimention_group', // 目標軸グループの設定で保存ボタンを押した
  save_sync_fields: 'save_sync_fields', // 同期項目設定で完了ボタンを押した
  save_custom_object: 'save_custom_object', // カスタムオブジェクトの編集から更新を押した
  save_salescore_object: 'save_salescore_object', // SALESCOREオブジェクトの編集から更新を押した
  save_custom_role: 'save_custom_role', // カスタム権限で保存ボタンを押した
  save_user_groups: 'save_user_groups', // グループ設定でヘッダーの保存ボタンを押した

  // アップロード系
  upload_goal_csv: 'upload_goal_csv',

  // エラー系
  failed_fetch_view_query_result: 'failed_fetch_view_query_result', // Viewのデータ取得時にエラーが発生した
  failed_save_sheet_records: 'failed_save_sheet_records', // シートの保存時にエラーが発生した
  failed_shareurl_copy: 'failed_shareurl_copy', // 共有用URLのコピー失敗時(URL長が長すぎる場合)
  view_compile_header_error: 'view_compile_header_error',
  view_compile_header_warning: 'view_compile_header_warning',
  error_move_to_record: 'error_move_to_record', // 書き込みエラー時に「エラー行へ移動」を押下した
  error_feedback_good: 'error_feedback_good',
  error_feedback_bad: 'error_feedback_bad',

  // 汎用クリック
  // ※イベント名を綺麗に統一する必然性はあまりないので、迷ったらclick_で適当につけてOKです
  click_help: 'click_help',
  click_kpi_from_dashboard: 'click_kpi_from_dashboard',
  click_shareurl_from_dashboard: 'click_shareurl_from_dashboard',
  // CSV,画像ダウンロード系
  click_kpi_bar_chart_csv_download: 'click_kpi_bar_chart_csv_download',
  click_kpi_bar_chart_image_download: 'click_kpi_bar_chart_image_download',
  click_kpi_pie_chart_csv_download: 'click_kpi_pie_chart_csv_download',
  click_kpi_pie_chart_image_download: 'click_kpi_pie_chart_image_download',
  click_dashboard_csv_download: 'click_dashboard_csv_download',
  click_sheet_csv_download: 'click_sheet_csv_download',
  click_reload_client: 'click_reload_client',

  // その他
  can_not_get_view_id: 'can_not_get_view_id', // ImportButton.tsx
  access_v1_url: 'access_v1_url',
  render_duration: 'render_duration',
} as const

export type PosthogEvent = keyof typeof POSTHOG_EVENTS
