import type { CoreModel } from '@salescore/core'

export const reverseEltLogModel: CoreModel = {
  name: `salescore_reverse_elt_logs`,
  label: `リバースELTのログ`,
  properties: [
    { name: `transaction_id`, label: `同期ID`, type: `string` },
    { name: `reverse_elt_id`, label: `リバースELT設定のID`, type: `string` },
    {
      name: `status`,
      label: `ステータス`,
      type: `string`,
      selectOptions: [
        { value: `success`, label: `成功`, color: `green` },
        { value: `failure`, label: `失敗`, color: `red` },
        { value: `unknown`, label: `不明`, color: `gray` },
      ],
    },
    {
      name: `change_type`,
      label: `同期種別`,
      type: `string`,
      selectOptions: [
        { value: `create`, label: `作成`, color: `blue` },
        { value: `update`, label: `更新`, color: `green` },
        { value: `delete`, label: `削除`, color: `red` },
      ],
    },
    {
      name: `record_id`,
      label: `レコードID`,
      type: `string`,
      referenceTo: [
        {
          modelName: `salesforce_contact`,
        },
        {
          modelName: `salesforce_task`,
        },
      ],
    },
    {
      name: `record_model_name`,
      label: `書き込み先モデル名`,
      type: `string`,
      selectOptions: [
        { value: `salesforce_contact`, label: `Contact` },
        { value: `salesforce_task`, label: `ToDo` },
      ],
    },
    {
      name: `original_record_id`,
      label: `元レコードID`,
      type: `string`,
      referenceTo: [
        {
          modelName: `google_mail_messages`,
        },
        {
          modelName: `google_calendar_events`,
        },
      ],
    },
    {
      name: `original_model_name`,
      label: `元のモデル名`,
      type: `string`,
      selectOptions: [
        { value: `google_mail_messages`, label: `Gメール` },
        { value: `google_calendar_events`, label: `Googleカレンダー` },
      ],
    },
    { name: `error`, label: `エラー`, type: `string`, meta: `text` },
    { name: `data`, label: `生データ`, type: `object` },
    { name: `created_at`, label: `同期時刻`, type: `datetime` },
  ],
}
