import { SearchOutlined } from '@ant-design/icons'
import { CORE_CONSTANT, type ViewConfigDimension } from '@salescore/core'
import { Select, Tag } from 'antd'
import { t } from 'i18next'

interface Option {
  value: string
  label: string
  key: string
  description?: string
  count?: number
  tags?: string[]
}

export const DimensionsPickerSelect = ({
  dimensions,
  pickedDimensions,
  placeholder,
  onChange,
}: {
  dimensions: ViewConfigDimension[]
  pickedDimensions: ViewConfigDimension[]
  placeholder?: string
  onChange: (values: string[]) => void
}) => {
  const options = dimensions
    .filter(
      (x) =>
        x.fieldType === 'string' || x.fieldType === 'date' || x.fieldType === 'datetime' || x.fieldType === 'boolean',
    )
    .sortBy((x) => -1 * (x.dimensionCount ?? 0))
    .map((x) => ({
      value: x.key,
      key: [x.key, x.label].join(' '),
      label: x.label,
      // count: (x.dimensionCount ?? 0) > 1 ? x.dimensionCount : undefined,
      tags: x.type === 'dimensionGroup' ? [t(`軸グループ`)] : isKpiDimension(x) ? [t(`KPI設定`)] : undefined,
    }))

  return (
    <Select
      showSearch
      mode="multiple"
      filterOption={(input, option) => ((option?.key ?? '') as string).toLowerCase().includes(input.toLowerCase())}
      tagRender={(properties) => {
        if (properties.value === pickedDimensions[0]?.key) {
          return (
            <div style={{ color: '#bfbfbf', paddingLeft: 7, top: 3 }} className="absolute">
              {placeholder ?? `${t(`項目を追加`)}…`}
              <br />
            </div>
          )
        }
        return <></>
      }}
      onChange={(values) => {
        onChange(values)
      }}
      value={pickedDimensions.map((x) => x.key)}
      placeholder={`${t(`項目を追加`)}…`}
      suffixIcon={<SearchOutlined />}
      className="w-full"
    >
      {options
        .uniqueBy((x) => x.value)
        .map((option) => (
          <Select.Option value={option.value} key={option.key}>
            <div className="py-1">
              <span className="mr-1 whitespace-pre-wrap break-words">{option.label}</span>
              {(option.tags ?? []).map((tag) => (
                <Tag className="ml-1">{tag}</Tag>
              ))}
              {/* {isSome(option.description) && (
                    <span className="ml-2 text-xs text-gray-500">- {option.description}</span>
                  )} */}
            </div>
          </Select.Option>
        ))}
    </Select>
  )
}

function isKpiDimension(x: ViewConfigDimension) {
  return (
    [CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key, CORE_CONSTANT.KPI_PIVOT_USER_DIMENSION().key].includes(x.key) ||
    x.key.includes(`salescore_user_group`)
  )
}
