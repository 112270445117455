import { isNull } from '@salescore/buff-common'
import type { ViewQueryList } from '@salescore/core'

import { flatNodes, removeNodes } from '../../../state/nodeUtil'

export function pruneQuery(query: ViewQueryList): ViewQueryList {
  return pruneSorters(pruneFields(pruneTree(query)))
}

function pruneFields(query: ViewQueryList): ViewQueryList {
  const nodes = flatNodes(query.tree)
  const newFields = query.fields.filter((field) =>
    nodes.map((x) => x.path.join(',')).includes(field.nodePath.join(',')),
  )

  return {
    ...query,
    fields: newFields,
  }
}

function pruneSorters(query: ViewQueryList): ViewQueryList {
  const nodes = flatNodes(query.tree)
  const fields = query.fields
  const sorters = query.sorters.filter((sorter) => {
    const sorterNodes = sorter.nodePaths.map((nodePath) => nodes.find((node) => node.path.isEqual(nodePath))).compact()
    if (sorterNodes.length !== sorter.nodePaths.length) {
      return false
    }
    const field = fields.find((field) => field.read.sql === sorter.read.sql)
    if (isNull(field)) {
      return false
    }
    return true
  })

  return {
    ...query,
    sorters,
  }
}

function pruneTree(viewQuery: ViewQueryList) {
  const leafs = flatNodes(viewQuery.filterTree).flatMap((x) => x.leafs)
  const usingNodePaths = [
    ...viewQuery.fields.map((x) => x.nodePath),
    ...viewQuery.fields.map((x) => x.read.labelNodePath),
    ...leafs.flatMap((leaf) => leaf.nodePaths),
    ...viewQuery.sorters.flatMap((x) => x.nodePaths),
  ]
    .compact()
    .uniqueBy((x) => x.join('-'))
    .flatMap((x) => x.mapConsIncremental()) // ['a', 'b', 'c']というパスがあれば、これを[['a'], ['a', 'b'], ['a', 'b', 'c']]に分解する
    .uniqueBy((x) => x.join('-'))
  const notUsingNodes = flatNodes(viewQuery.tree).filter(
    (node) => !usingNodePaths.map((path) => path.join('-')).includes(node.path.join('-')),
  )
  const newViewQuery = {
    ...viewQuery,
    tree: removeNodes({ tree: viewQuery.tree, targetNodeNames: notUsingNodes.map((x) => x.path.last()!) }),
  }

  return newViewQuery
}
