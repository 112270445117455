import { getRowRecordIds } from '@salescore/core'
import { Empty } from 'antd'
import type { CSSProperties } from 'react'

import { useChangesSelector } from '../../../recoil/records/selectors/changesSelector'
import { useViewConfig } from '../../../recoil/view/hooks'
import { useVirtualizedBody } from '../../hooks/useVirtualizedBody'
import { useContextValue, useRowsValue } from '../../recoil/models/propModels'
import { useColumnsRelatedSelector } from '../../recoil/selectors/columnRelatedSelector'
import { RSheetsStyle } from '../../util/RSheetsStyle'
import { HEADER_RIGHT_WIDTH_MARGIN } from '../header/const'
import { RSheetRowMemoWrapper } from './RSheetRow'

export function RSheetBody({ leftFixed }: { leftFixed: boolean }) {
  const config = useViewConfig()
  const rows = useRowsValue()
  const options = useContextValue()
  const columnRelated = useColumnsRelatedSelector()
  const virtualizedBody = useVirtualizedBody()
  const renderingColumnsLeftIndex = config.type === 'sheet' ? virtualizedBody.renderingColumnsLeftIndex : 0
  const { getLatestFieldChanges } = useChangesSelector()

  const { leftFixedColumns, notFixedColumns } = columnRelated
  // 親要素側でsliceしても良いが、その場合はmemo化が崩れないように注意すること
  const targetColumns = leftFixed
    ? leftFixedColumns
    : config.type === 'sheet' // シートの時のみ列の仮想化を行う
      ? notFixedColumns.slice(virtualizedBody.renderingColumnsLeftIndex, virtualizedBody.renderingColumnsRightIndex)
      : notFixedColumns
  if (targetColumns.length === 0 && leftFixed) {
    // flexを使っている関係で、!leftFixedなときは描画しないとレイアウトが崩れる
    return <></>
  }

  const stickyStyle: CSSProperties = leftFixed
    ? {
        position: 'sticky',
        left: 0,
        zIndex: RSheetsStyle.zIndex.bodyRowWhenLeftFixed,
      }
    : {}

  const virtualizedRows = rows.slice(virtualizedBody.renderingRowsTopIndex, virtualizedBody.renderingRowsBottomIndex)

  return (
    <div
      style={{
        width: targetColumns.map((x) => x.width ?? 300).sum(),
        ...stickyStyle,
        borderRight: leftFixed ? RSheetsStyle.border.rightEdgeColumn : undefined,
        paddingTop: virtualizedBody.renderingRowsTop,
        paddingBottom: virtualizedBody.renderingRowsBottom,
        marginLeft: leftFixed ? 0 : config.type === 'sheet' ? virtualizedBody.renderingColumnsLeft : 0,
        marginRight: leftFixed ? 0 : HEADER_RIGHT_WIDTH_MARGIN,
      }}
      data-e2e={`rsheets-body-${leftFixed ? 'left-fixed' : 'not-fixed'}`}
    >
      {rows.isBlank() && (
        <div
          style={{
            width: '100vw',
          }}
          className="bg-white p-8"
        >
          <Empty />
        </div>
      )}
      {virtualizedRows.map((row, virtualizedRowIndex) => {
        const rowRecordIds = getRowRecordIds(row)
        const latestFieldChangesRow = getLatestFieldChanges(rowRecordIds)
        return (
          <RSheetRowMemoWrapper
            // keyをrow.idにすると、子レコードでソートをして親レコードが複数行になる際におかしくなるため注意
            key={row.id}
            row={row}
            columns={targetColumns}
            rowIndex={virtualizedRowIndex + virtualizedBody.renderingRowsTopIndex}
            columnIndexStart={leftFixed ? 0 : (options.fixedLeftColumnIndex ?? 0) + renderingColumnsLeftIndex}
            isFixed={leftFixed}
            latestFieldChangesRow={latestFieldChangesRow}
          />
        )
      })}
      {/* 親側でまとめて右端の表示をしたければ以下 */}
      {/* { leftFixed && <div
        style={{
          position: "absolute",
          height: "100%",
          width: 2,
          backgroundColor: "blue",
          zIndex: 21,
          top: 0,
          right: -2
        }}
      /> } */}
    </div>
  )
}
