import { DeleteOutlined } from '@ant-design/icons'
import { type CoreModel, ModelSearcher } from '@salescore/core'
import { Button, List } from 'antd'
import { useMemo } from 'react'

export function PropertiesList({
  models,
  propertyNames,
  onDelete,
}: {
  models: CoreModel[]
  propertyNames: Array<{ propertyName: string; modelName: string }>
  onDelete: (index: number) => void
}) {
  const searcher = useMemo(() => {
    const searcher = new ModelSearcher(models)
    return searcher
  }, [models])
  return (
    <List
      dataSource={propertyNames}
      bordered
      renderItem={(item, index) => {
        const property = searcher.searchPropertyWithModel(item.modelName, item.propertyName)
        return (
          <List.Item
            actions={[
              <Button
                onClick={() => {
                  onDelete(index)
                }}
                type="text"
                icon={<DeleteOutlined />}
              />,
            ]}
          >
            {property?.model.label ?? item.modelName}: {property?.property.label ?? item.propertyName}
          </List.Item>
        )
      }}
    />
  )
}
