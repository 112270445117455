import type { KeyboardEvent } from 'react'

import type { FinishEditMode } from '../../../../types'
import { RSheetsStyle } from '../../../../util/RSheetsStyle'

// 以下を行うためのwrapper
// ・入力範囲外をクリックした際に、onChangeFixedを発火させて入力内容の永続化
// ・汎用的なhandleKeyDownの提供
export const RSheetsInputCellWrapper = (
  { onChangeFixed, finishEditMode }: { onChangeFixed: () => void; finishEditMode: FinishEditMode },
  callback: ({ handleKeyDown }: { handleKeyDown: (e: KeyboardEvent) => void }) => JSX.Element,
) => {
  const handleKeyDown = (e: KeyboardEvent) => {
    // 変換中以外の操作で発火させる
    if (e.key === 'Enter' && !e.metaKey && !e.shiftKey) {
      onChangeFixed()
      finishEditMode({ moveRowDiff: 1 })
      e.preventDefault()
      e.stopPropagation()
    }
    if (e.key === 'Tab') {
      onChangeFixed()
      finishEditMode({ moveColumnDiff: 1 })
      e.preventDefault()
      e.stopPropagation()
    }
    if (e.key === 'Escape') {
      finishEditMode()
      e.preventDefault()
      e.stopPropagation()
    }
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          width: '100%',
          height: '100%',
          // backgroundColor: "red",
          top: 0,
          left: 0,
          zIndex: RSheetsStyle.zIndex.cursorWhenLeftFixed,
        }}
        // 入力カーソル範囲外をクリックしたら、入力を確定させて終了
        onClick={() => {
          onChangeFixed()
          finishEditMode()
        }}
        onContextMenu={(e) => {
          onChangeFixed()
          finishEditMode()
          e.preventDefault()
          e.stopPropagation()
        }}
      />
      {callback({ handleKeyDown })}
    </>
  )
}
