export function generateCsvString({ headers, rows }: { headers: string[]; rows: unknown[][] }) {
  const textHeader = headers.join(',')
  const textBody = rows.map((row) => convertRow(row)).join('\n')
  const text = textHeader + '\n' + textBody
  return text
}

function convertRow(row: unknown[]) {
  return row.map((value) => convertCellValue(value)).join(',')
}

function convertCellValue(x: unknown): string {
  if (typeof x === 'string') {
    return `"${x.replaceAll('"', '""')}"`
  }
  if (typeof x === 'number') {
    return `${x}`
  }
  if (typeof x === 'boolean') {
    return x ? 'TRUE' : 'FALSE'
  }
  // これ以外の値の時、どうするか…？
  return ``
}
