import { Steps } from 'antd'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

export const KpiFormSteps = () => {
  const { step, setStep, stepStatus } = useKpiFormSelectors()
  return (
    <Steps
      current={step}
      direction="horizontal"
      className="mb-8"
      size="small"
      onChange={(value) => {
        setStep(value as 0 | 1 | 2 | 3)
      }}
    >
      <Steps.Step title={t(`KPI`)} description="" />
      <Steps.Step title={t(`オブジェクト`)} description="" disabled={!stepStatus.isStep2Enabled} />
      <Steps.Step title={t(`条件設定`)} description="" disabled={!stepStatus.isStep3Enabled} />
      <Steps.Step title={t(`詳細設定`)} description="" disabled={!stepStatus.isStep4Enabled} />
    </Steps>
  )
}
