import { t } from 'i18next'
import { z } from 'zod'

export const policyActionSchema = z.enum([
  // 全般
  'manage-policy',
  // シート関連
  // "sheet-read", // 閲覧系はいったんなしという話になった。
  'sheet-create',
  'sheet-update',
  'sheet-delete',
  'sheet-create-new-record', // レコードの新規作成
  'sheet-save-record', // レコードの保存(=更新,新規作成,削除すべて)
  'sheet-delete-record', // レコードの削除
  'sheet-open-relation-input-form', // 参照先レコードの新規作成フォームを開ける
  'sheet-download-csv',
  'convert-lead',
  // 「権限設定の権限」は、実装の都合上と、権限の粒度に迷っているのもあり、一旦manage-policyとしてまとめた。
  // 一旦は個別で設定することはないのでこれでいいが、必要になり次第統廃合する予定
  // "sheet-manage-policy",
  // KPI関連
  // "kpi-read",
  'kpi-create',
  'kpi-update',
  'kpi-delete',
  'kpi-create-new-record',
  'kpi-save-record',
  'kpi-delete-record',
  'kpi-open-relation-input-form',
  'kpi-download-csv',
  // "kpi-manage-policy",
  // ダッシュボード関連
  // "kpi-pivot-read",
  'kpi-pivot-create',
  'kpi-pivot-update',
  'kpi-pivot-delete',
  'kpi-pivot-download-csv',
  // "kpi-pivot-manage-policy",
  // ビュー全般
  // TODO: 並び替えは権限で制御するかどうか検討中
  // ビューグループ関連
  // "view-group-read",
  'view-group-create',
  // "view-group-update", // 現状要件にないので外している
  'view-group-delete',
  // その他
  'manage-organization-setting',
  'manage-connection',
  'manage-goal',
  'manage-dimension-group',
  'manage-users',
  'manage-user-groups',
  'manage-invitation',
  'read-user-activity',

  // 現状adminのみ
  'manage-reverse-elt', // adminのみのはず
  'manage-internal', // 内部情報の取得など
])

export type PolicyAction = z.infer<typeof policyActionSchema>

// こういう系をi18n的にやるか、tsのコードでやるか迷い中
// tsでやってしまった方が型が効くので、漏れが少ない
// i18n:
export const policyActionLabelJaMap = (): Record<PolicyAction, string> => ({
  'manage-policy': t('権限設定の操作'), // ユーザーがカスタマイズ仕様はしばらく後にする予定。
  // ['sheet-read']: "シートの閲覧",
  'sheet-create': t('シート作成'),
  'sheet-update': t('シート編集'),
  'sheet-delete': t('シートの削除'),
  'sheet-create-new-record': t('シートでレコードの新規作成'),
  'sheet-save-record': t('シートでレコードの保存'),
  'sheet-delete-record': t('シートでレコードの削除'),
  'sheet-open-relation-input-form': t('シートの参照先セルで新規作成モーダルを開く'),
  'sheet-download-csv': t('シートのCSVダウンロード'),
  'convert-lead': t('リードの昇格'),
  // ['sheet-manage-policy']: "シートの権限設定",
  // ['kpi-read']: "KPIの閲覧",
  'kpi-create': t('KPI作成'),
  'kpi-update': t('KPI編集'),
  'kpi-delete': t('KPIの削除'),
  'kpi-create-new-record': t('KPIシートでレコードの新規作成'),
  'kpi-save-record': t('KPIシートでレコードの保存'),
  'kpi-delete-record': t('KPIシートでレコードの削除'),
  'kpi-open-relation-input-form': t('KPIシートの参照先セルで新規作成モーダルを開く'),
  'kpi-download-csv': t('KPIのCSVダウンロード'),
  // ['kpi-manage-policy']: "KPIの権限設定",
  // ['kpi-pivot-read']: "ダッシュボードの閲覧",
  'kpi-pivot-create': t('ダッシュボード作成'),
  'kpi-pivot-update': t('ダッシュボード編集'),
  'kpi-pivot-delete': t('ダッシュボードの削除'),
  'kpi-pivot-download-csv': t('ダッシュボードのCSVダウンロード'),
  // ['kpi-pivot-manage-policy']: "ダッシュボードの権限設定",
  // ['view-group-read']: "フォルダの閲覧",
  'view-group-create': t('フォルダの作成'),
  // ['view-group-update']: "フォルダの編集",
  'view-group-delete': t('フォルダの削除'),
  // その他
  'manage-organization-setting': t('ユーザーグループ設定の操作'),
  'manage-connection': t('連携設定の操作'),
  'manage-goal': t('目標の操作'),
  'manage-dimension-group': t('軸グループの操作'),
  'manage-users': t('ユーザーの操作'),
  'manage-user-groups': t('ユーザーグループの操作'),
  'manage-invitation': t('招待の操作'),
  'read-user-activity': t('利用状況を閲覧'),
  // admin
  'manage-reverse-elt': t('R-ELTの設定'),
  'manage-internal': t('内部情報の取得'),
})
