import { Form, Row } from 'antd'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import { PropertySelect } from './PropertySelect'

interface KpiFormDateFormItemProperties {
  disabled?: boolean
  formKey?: 'date' | 'dateY'
}

export const KpiFormDateFormItem = (properties: KpiFormDateFormItemProperties) => {
  const { setForm, form, sheet, currentNodeDateProperties, setFormModified } = useKpiFormSelectors()
  const formKey = properties.formKey ?? 'date'

  return (
    <Row>
      <Form.Item label={t(`期間項目`)} style={{ width: `100%` }}>
        <PropertySelect
          allowClear
          properties={currentNodeDateProperties}
          value={form[formKey]?.property}
          onChange={(value) => {
            if (value === undefined) {
              setForm((oldValue) => ({
                ...oldValue,
                [formKey]: undefined,
              }))
              return
            }
            // 選択されたプロパティを詳細項目に自動追加
            const newSheet = sheet.addPropertyField(value.nodePropertyName, { toLast: true })
            setForm((oldValue) => ({
              ...oldValue,
              sheet: newSheet.config,
              [formKey]: {
                property: value.nodePropertyName,
              },
            }))
            setFormModified(true)
          }}
          disabled={properties.disabled}
        />
      </Form.Item>
    </Row>
  )
}
