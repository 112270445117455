import { t } from 'i18next'
import { useState } from 'react'

export const ExpandableSpan = ({
  text = '',
  className,
  ellipsisChars = 100,
}: {
  text: string
  className?: string
  ellipsisChars?: number
}) => {
  const shouldExpandable = text.length >= ellipsisChars
  const [expanded, setExpanded] = useState(!shouldExpandable)
  const fixedText = expanded ? text : text.replaceAll('\n', ' ').slice(0, ellipsisChars)

  return (
    <span
      className={`${className ?? ''} ${shouldExpandable ? 'cursor-pointer' : ''}`}
      onClick={() => {
        shouldExpandable && setExpanded(!expanded)
      }}
      style={{ fontWeight: 'normal' }}
    >
      {fixedText}
      {!expanded && <span className="ml-1 text-blue-600">...{t(`もっと読む`)}</span>}
    </span>
  )
}
