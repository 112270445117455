import { isSome } from '@salescore/buff-common'
import type { SourceFieldsFragment } from '@salescore/client-api'
import { List } from 'antd'
import { t } from 'i18next'
import { z } from 'zod'

const SHEET_URL_PREFIX = 'https://docs.google.com/spreadsheets/d/'

export const SourceInfoContent = ({
  source,
}: {
  source: Pick<SourceFieldsFragment, 'name' | 'config' | 'provider'>
}) => {
  const config = z
    .object({
      spreadsheetId: z.string().optional(),
      meta: z
        .object({
          display_name: z.string().optional(),
          email: z.string().optional(),
        })
        .optional(),
    })
    .parse(source.config)

  const items: Array<{ description: string; title: JSX.Element | string }> = [
    { description: t(`連携先名称`), title: source.name ?? '-' },
    isSome(config.spreadsheetId)
      ? {
          description: t(`連携先スプレッドシートURL`),
          title: (
            <a
              href={`${SHEET_URL_PREFIX}${config.spreadsheetId}`}
              target="_blank"
              rel="noreferrer"
            >{`${SHEET_URL_PREFIX}${config.spreadsheetId}`}</a>
          ),
        }
      : undefined,
    isSome(config.meta?.display_name)
      ? {
          description: t(`連携ユーザー名`),
          title: config.meta.display_name ?? '-',
        }
      : undefined,

    isSome(config.meta?.email)
      ? {
          description: t(`連携ユーザーメールアドレス`),
          title: config.meta.email ?? '-',
        }
      : undefined,
  ].compact()
  return (
    <>
      <List
        bordered={false}
        split={false}
        dataSource={items}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta title={item.title} description={item.description} />
          </List.Item>
        )}
      ></List>
    </>
  )
}
