import { range } from '@salescore/buff-common'

import { PRESET_MODELS } from '../../../../constant/presetModels'
import type {
  NodePropertyName,
  ViewConfigField,
  ViewConfigSheet,
  ViewConfigTreeNode,
} from '../../../../schemas/view_config'
import { getNodeByName } from '../../../util/node'
import type { CompileContext } from '../../common'

// ユーザーを介さず、直接グループを紐づけるケース。目標のときのみを想定
export function addSalescoreUserGroupTreeAndFields(
  config: ViewConfigSheet,
  rootProperty: NodePropertyName,
  context: CompileContext,
): ViewConfigSheet {
  if (config.tree === undefined) {
    // ありえないはず
    return config
  }

  // salescore_user_groupsをjoinする
  const newTree = structuredClone(config.tree) // TODO
  const nodeWithUserProperty = getNodeByName(newTree, rootProperty.nodeName)?.node
  if (nodeWithUserProperty === undefined) {
    context.logs.error(`ユーザーグループフィールドのノードが見つかりませんでした。${rootProperty.nodeName}`)
    return config
  }

  const newNode: ViewConfigTreeNode = {
    type: `model`,
    name: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
    modelName: PRESET_MODELS.USER_GROUPS_WITH_PARENTS.NAME,
    referenceToProperty: {
      ...rootProperty,
      referenceTo: {
        modelName: PRESET_MODELS.USER_GROUPS_WITH_PARENTS.NAME,
      },
    },
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- 直したいけど大丈夫なのかわからないので保留
  nodeWithUserProperty.children ||= []
  nodeWithUserProperty.children.push(newNode)

  return {
    ...config,
    tree: newTree,
    fields: [
      ...(config.fields ?? []),
      ...range(1, 5).flatMap((depth): ViewConfigField[] => [
        {
          type: 'property',
          property: {
            nodeName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
            modelName: PRESET_MODELS.USER_GROUPS_WITH_PARENTS.NAME,
            propertyName: `user_group_d${depth}_id`,
          },
        },
        {
          type: 'property',
          property: {
            nodeName: PRESET_MODELS.USER_WITH_USER_GROUP.NAME,
            modelName: PRESET_MODELS.USER_GROUPS_WITH_PARENTS.NAME,
            propertyName: `user_group_d${depth}_name`,
          },
        },
      ]),
    ],
  }
}
