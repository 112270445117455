import { isArray } from '@apollo/client/utilities'
import { isNull, isPresent } from '@salescore/buff-common'
import type { WaterfallDimension } from '@salescore/core'
import { Select, Space } from 'antd/es'
import { arrayMoveImmutable } from 'array-move'
import { type ReactNode, useState } from 'react'
import type { SetterOrUpdater } from 'recoil'

import { waterfallDimensionGroupOptions, type WaterfallDimensionGroupType } from './common'
import { WaterfallSortableDimensionList } from './WaterfallDimension/WaterfallSortableDimensionList'

export function ViewUiRiWaterfallDimensionPicker({
  disabled,
  waterfallDimensions,
  setWaterfallDimensions,
}: {
  disabled?: boolean
  waterfallDimensions: WaterfallDimension[] | undefined
  setWaterfallDimensions: SetterOrUpdater<WaterfallDimension[] | undefined>
}): ReactNode {
  const [dragging, setDragging] = useState(false)
  const selectedOptionValues = waterfallDimensionGroupOptions
    .filter((option) => option.items.every((item) => waterfallDimensions?.some((x) => x.name === item.name)))
    .sort((a, b) => {
      const aIndex = waterfallDimensions?.findIndex((x) => x.name === a.items.first()?.name) ?? -1
      const bIndex = waterfallDimensions?.findIndex((x) => x.name === b.items.first()?.name) ?? -1
      return aIndex - bIndex
    })
    .map((option) => option.value)

  return (
    <Space className="w-full" direction="vertical" size={'middle'}>
      <Select
        className="w-full"
        mode="multiple"
        disabled={disabled}
        options={waterfallDimensionGroupOptions}
        onChange={(value, option) => {
          const selectedOptions = isArray(option) ? option : [option]
          setWaterfallDimensions((oldDimensions) =>
            selectedOptions
              .flatMap((option) => {
                const existedDimensions = oldDimensions?.filter((x) =>
                  option.items.some((item) => x.name === item.name),
                )
                if (existedDimensions?.length === option.items.length) {
                  return existedDimensions
                }
                // 新規に選択されたものは項目未設定の状態で追加
                return option.items.map((item) => ({
                  name: item.name,
                  label: item.label,
                }))
              })
              .uniqueBy((x) => x.name),
          )
        }}
        value={selectedOptionValues}
      />
      {isPresent(selectedOptionValues) && (
        <WaterfallSortableDimensionList
          items={selectedOptionValues
            .map((value) => {
              const option = waterfallDimensionGroupOptions.find((x) => x.value === value)
              if (isNull(option)) {
                // ありえないはず
                return
              }

              return {
                value: option.value,
                label: option.label,
                key: option.value,
              }
            })
            .compact()}
          dragging={dragging}
          waterfallDimensions={waterfallDimensions}
          setWaterfallDimensions={setWaterfallDimensions}
          onSortStart={() => {
            setDragging(true)
          }}
          onSortEnd={(e) => {
            const sortedOptionValues = arrayMoveImmutable(selectedOptionValues, e.oldIndex, e.newIndex)
            setWaterfallDimensions(sortWaterfallDimensions({ waterfallDimensions, sortedOptionValues }))
          }}
        />
      )}
    </Space>
  )
}

function sortWaterfallDimensions({
  waterfallDimensions,
  sortedOptionValues,
}: {
  waterfallDimensions?: WaterfallDimension[]
  sortedOptionValues: WaterfallDimensionGroupType[]
}): WaterfallDimension[] {
  const sortedDimensionsMeta = sortedOptionValues.flatMap((value) => {
    const option = waterfallDimensionGroupOptions.find((x) => x.value === value)
    if (isNull(option)) {
      // ありえないはず
      return
    }
    return option.items
  })

  return sortedDimensionsMeta.map((item) => waterfallDimensions?.find((x) => x.name === item?.name)).compact()
}
