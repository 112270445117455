import { useLauncher } from './useLauncher'
import { useLauncherCommands } from './useLauncherCommands'
import { useLauncherState } from './useLauncherState'

// NOTE: recoilへの移行が中途半端でいまいち綺麗に書ききれていないが、一旦この形とする
export const launcher = {
  useLauncher,
  useLauncherCommands,
  useLauncherState,
}
