import type { PolicyAction } from '../action'

// 組織管理者：全ての操作ができるロール
// XXX: 各種ロールは定義の簡素化のため上位ロールを継承している。
//      権限を変更したら下位ロールにも影響する可能性があるため、継承関係を意識して変更すること
export const managerRolePolicyDefinition: Record<PolicyAction, boolean> = {
  'manage-policy': true,
  // ['sheet-read']: true,
  'sheet-create': true,
  'sheet-update': true,
  'sheet-delete': true,
  'sheet-create-new-record': true,
  'sheet-save-record': true,
  'sheet-delete-record': true,
  'sheet-open-relation-input-form': true,
  'sheet-download-csv': true,
  'convert-lead': true,
  // ['sheet-manage-policy']: true,
  // ['kpi-read']: true,
  'kpi-create': true,
  'kpi-update': true,
  'kpi-delete': true,
  'kpi-create-new-record': true,
  'kpi-save-record': true,
  'kpi-delete-record': true,
  'kpi-open-relation-input-form': true,
  'kpi-download-csv': true,
  // ['kpi-manage-policy']: true,
  // ['kpi-pivot-read']: true,
  'kpi-pivot-create': true,
  'kpi-pivot-update': true,
  'kpi-pivot-delete': true,
  'kpi-pivot-download-csv': true,
  // ['kpi-pivot-manage-policy']: true,
  // ['view-group-read']: true,
  'view-group-create': true,
  // ['view-group-update']: true,
  'view-group-delete': true,
  // その他
  'manage-organization-setting': true,
  'manage-connection': true,
  'manage-goal': true,
  'manage-dimension-group': true,
  'manage-users': true,
  'manage-user-groups': true,
  'manage-invitation': true,
  'read-user-activity': true,

  // admin
  'manage-reverse-elt': false,
  'manage-internal': false,
}
