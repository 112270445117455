import { PageHeader } from '@ant-design/pro-layout'
import { useApolloClient } from '@apollo/client'
import { FetchViewsDocument, FetchViewSyncTimeDocument, type ViewFieldsFragment } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { SuspenseWithLoading } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { Divider, Space } from 'antd'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'

import { oldestSyncedAtAtom } from '../recoil/view/atoms'
import { ViewComponent, type ViewComponentArgument } from './ViewComponent'
import { ViewLoading } from './ViewLoading'

export function ViewComponentWrapper(
  argument: Omit<ViewComponentArgument, 'view'> & { viewId: string; name?: string; withHeader?: boolean },
) {
  const { viewId, name, withHeader, viewsContext } = argument
  const [views, setViews] = useState<ViewFieldsFragment[]>([])
  const [oldestSyncedAt, setOldestSyncedAt] = useRecoilState(oldestSyncedAtAtom)
  const view = views.find((x) => x.id === argument.viewId)
  const client = useApolloClient()
  const me = recoil.global.useMe()
  const [hasChanges, setHasChanges] = useState(false)
  const [isSaveConfigButtonActive, setIsSaveConfigButtonActive] = useState(false)
  const [isChangedDrillDownModal, setIsChangedDrillDownModal] = useState(false)
  const fetchViews = async (ids: string[]) => {
    const result = await client.query({
      query: FetchViewsDocument,
      variables: {
        organizationId: getOrganizationIdFromPath(),
        ids,
        withDependency: true,
      },
      // 2023/01/14 cache-firstからno-cacheに変更
      // cache-firstにしたいところだが、hooksではないこの関数でそうしてしまうと、ダメなキャッシュを引いてしまうことがある
      // …が、no-cacheにしていてパフォーマンスの深刻な問題があったためにcache-firstにした気がする。要調整
      fetchPolicy: `no-cache`,
    })
    return result.data.views ?? []
  }

  const fetchOldestSyncTime = async () => {
    const result = await client.query({
      query: FetchViewSyncTimeDocument,
      variables: {
        organizationId: getOrganizationIdFromPath(),
        viewId,
      },
    })
    return result.data.viewOldestSyncTime ?? undefined
  }

  const fetchAndSet = async () => {
    const views = await fetchViews([viewId])
    const oldestSyncedAt = await fetchOldestSyncTime()

    setViews(views) // 依存ビューが全て含まれているはず
    setOldestSyncedAt(oldestSyncedAt)
  }

  useEffect(() => {
    void fetchAndSet()
  }, [viewId, name]) // 名前を書き換えた時にconfigに反映させるためにこれが必要（かなり微妙だが、更新頻度は高くないはずなので許容する）

  if (view !== undefined) {
    const body = (
      <SuspenseWithLoading type="custom" loadingElement={<ViewLoading context={{ view }} />}>
        <ViewComponent
          {...argument}
          view={view}
          oldestSyncedAt={oldestSyncedAt}
          viewsContext={{
            ...argument.viewsContext,
            views,
            // onSaveView, onAddResourceなどを作ってしまったが、後からupdateViewをproxyすればいいことに気づいた
            updateView: async (x) => {
              const result = await argument.viewsContext.updateView(x)
              if (result !== undefined) {
                // 依存があればreplaceする
                setViews((oldViews) => oldViews.map((oldView) => (oldView.id === result.id ? result : oldView)))
              }
              return result
            },
            pickView: (viewId: string) => {
              argument.viewsContext.pickView(viewId)
            },
            refetchViews: async () => {
              await argument.viewsContext.refetchViews()
            },
            onAddResource: async ({ viewIds }) => {
              if (viewIds === undefined || viewIds.isBlank()) {
                return
              }
              const views = await fetchViews(viewIds)
              await viewsContext.onAddResource({ viewIds })
              setViews((oldViews) => {
                const newViews = [...views, ...oldViews].uniqueBy((x) => x.id)
                return newViews
              })
            },
            onSaveView: async (views, option) => {
              if (option?.forceFetch === true) {
                await fetchAndSet()
                return
              }
              // 依存があればreplaceする
              setViews((oldViews) =>
                oldViews.map((oldView) => {
                  const newView = views.find((view) => view.id === oldView.id)
                  return newView ?? oldView
                }),
              )

              // XXX: 以下のときだけrefetchする
              // 1. 名前に変更があったとき
              // 2. KPI設定でシートを変更したとき(=2つ変更されたとき、とする)
              const savedView = views.find((x) => x.id === viewId)
              if ((savedView !== undefined && savedView.name !== view.name) || views.length >= 2) {
                await argument.viewsContext.onSaveView(views)
                await fetchAndSet()
              }
            },
            isSaveConfigButtonActive,
            setIsSaveConfigButtonActive,
            isChangedDrillDownModal,
            setIsChangedDrillDownModal,
            hasChanges,
            onChangesChange: (hasChange: boolean) => {
              setHasChanges(hasChange)
            },
          }}
        />
      </SuspenseWithLoading>
    )
    if (withHeader !== true) {
      return body
    }

    // このコンポーネントで、wrap以外のことをしたくなかったが、ヘッダーのロジックをいれるのに適した場所がなかったのでここでやることにする
    return (
      <PageHeader
        title={<Space className="px-4 pt-2">{view.name}</Space>}
        style={{
          padding: 0,
        }}
        className="w-full overflow-hidden"
      >
        <Divider style={{ margin: 0 }} />
        <div
          style={{
            height: 'calc(100vh - 150px)', // 画面一杯に表示するために、100vhからモーダル・タイトルをざっくり引いた値を指定
          }}
        >
          {body}
        </div>
      </PageHeader>
    )
  }
  return <ViewLoading context={{}} />
}
