import { PageHeader } from '@ant-design/pro-layout'
import type { ViewConfigField } from '@salescore/core'
import { useModalAtom } from '@salescore/frontend-common'
import { Empty, Modal } from 'antd'
import { t } from 'i18next'
import { useSetRecoilState } from 'recoil'

import { measureFormModalAtom } from '../../../recoil/navigation/atoms'
import { useViewConfigSheet, useViewValue } from '../../../recoil/view/hooks'
import { setConfigMutation } from '../../../recoil/view/mutations/setConfigMutation'
import { MeasureForm } from './MeasureForm'

export const MeasureFormModal = () => {
  const measureFormModal = useModalAtom(measureFormModalAtom)
  return (
    <Modal
      open={measureFormModal.isModalVisible}
      onCancel={() => {
        measureFormModal.hideModal()
      }}
      width={'80%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        top: '3%',
        maxWidth: 1000,
        padding: 0,
      }}
      bodyStyle={
        {
          // backgroundColor: "#eee"
        }
      }
      // title={'集計方法の編集'}
      destroyOnClose
      footer={<></>}
    >
      {measureFormModal.content !== undefined && (
        <Body
          field={measureFormModal.content.field}
          measureName={measureFormModal.content.measureName}
          onFinish={() => {
            measureFormModal.hideModal()
          }}
        />
      )}
    </Modal>
  )
}

function Body({
  field,
  measureName,
  onFinish,
}: {
  field: ViewConfigField
  measureName: string | undefined
  onFinish: () => void
}) {
  const view = useViewValue()
  const sheetConfig = useViewConfigSheet() // XXX: viewからconfigを取得するとmeasuresが削除されている…？詳細不明
  const setConfig = useSetRecoilState(setConfigMutation)

  if (sheetConfig.type !== 'sheet') {
    return <Empty description={t(`ビューが見つかりません`)} />
  }

  return (
    <PageHeader title={t(`集計設定`)}>
      <MeasureForm
        sheetView={{
          ...view,
          config: sheetConfig,
        }}
        field={field}
        measureName={measureName}
        onFinish={({ sheetViewConfig }) => {
          if (sheetViewConfig !== undefined && sheetViewConfig.type === 'sheet') {
            setConfig(sheetViewConfig)
          }
          onFinish()
        }}
      />
    </PageHeader>
  )
}
