import { isTruthy } from '@salescore/buff-common'
import { logger } from '@salescore/frontend-common'
import dayjs from 'dayjs'
import { t } from 'i18next'

import type { FeedbackMessage } from '../../recoil/records/atoms'
import type { RSheetColumn, RSheetRecordNode } from '../types'

export function validateAndNormalize(
  x: unknown,
  column: RSheetColumn<RSheetRecordNode>,
  feedback: (x: FeedbackMessage) => void,
) {
  switch (column.type) {
    case 'numeric': {
      if (typeof x === 'number') {
        return x
      }
      if (typeof x === 'string') {
        if (x.trim() === '') {
          // 空文字はnullとして扱う（undefinedだと変更が無視されてしまうため）
          return null
        }
        // 少なくとも数字が含まれていることは確認する
        if (/\d/.test(x)) {
          const number = Number(x.replaceAll(',', '').replace('¥', ''))
          if (!Number.isNaN(number)) {
            return number
          }
        }
      }

      logger.debug(`validation error`)
      feedback({
        type: 'warn',
        message: t(`{{target}}は{{title}}に貼り付けできません。`, {
          target: JSON.stringify(x),
          title: column.title ?? '',
        }),
      })
      return
    }
    case 'integer': {
      if (typeof x === 'number') {
        return x
      }
      if (typeof x === 'string') {
        if (x.trim() === '') {
          // 空文字はnullとして扱う（undefinedだと変更が無視されてしまうため）
          return null
        }
        // 少なくとも数字が含まれていることは確認する
        if (/\d/.test(x)) {
          const number = Number(x.replaceAll(',', '').replace('¥', ''))
          if (!Number.isNaN(number)) {
            return number
          }
        }
      }

      logger.debug(`validation error`)
      feedback({
        type: 'warn',
        message: t(`{{target}}は{{title}}に貼り付けできません。`, {
          target: JSON.stringify(x),
          title: column.title ?? '',
        }),
      })
      return
    }
    case 'date': {
      if (typeof x === 'string') {
        const date = dayjs(x)
        if (date.isValid()) {
          return date.format(`YYYY-MM-DD`)
        }
      }

      feedback({
        type: 'warn',
        message: t(`{{target}}は{{title}}に貼り付けできません。`, {
          target: JSON.stringify(x),
          title: column.title ?? '',
        }),
      })
      return
    }
    case 'datetime': {
      if (typeof x === 'string') {
        const date = dayjs(x)
        if (date.isValid()) {
          return date.format('YYYY-MM-DDTHH:mm:ss')
        }
      }

      feedback({
        type: 'warn',
        message: t(`{{target}}は{{title}}に貼り付けできません。`, {
          target: JSON.stringify(x),
          title: column.title ?? '',
        }),
      })
      return
    }
    case 'boolean': {
      if (typeof x === 'boolean') {
        return x
      }
      return isTruthyString(x)
    }
    case 'array': {
      return column.metaType === 'multi_select' && typeof x === 'string' ? x.split(',').map((p) => p.trim()) : x
    }
  }
  return x
}

function isTruthyString(x: unknown): boolean {
  if (typeof x === 'string') {
    if (x === '' || x === '0') {
      return false
    }
    return !/false/i.test(x) // false系の単語以外はすべてtrue扱いにする
  }
  return isTruthy(x)
}
