import { useMessage } from '@salescore/frontend-common'
import { Input } from 'antd'
import { t } from 'i18next'

import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { useChangesValue, useLoadingState } from '../../../recoil/records/hooks'
import { useSearchQuerySelector, useSetSearchQuery } from '../../../recoil/records/selectors/searchQuerySelector'
import { SearchableFieldsFormModal } from './SearchableFieldsFormModal'

export const SearchQueryInput = () => {
  const { searchableFieldsModal } = useNavigationModal()
  const { searchableFields, searchQuery } = useSearchQuerySelector()
  const setSearchQuery = useSetSearchQuery()
  const loading = useLoadingState()
  const disabled = searchableFields.isBlank()
  const message = useMessage()
  const changes = useChangesValue()

  return (
    <>
      <div
        className={disabled ? `cursor-pointer` : ``}
        // disabledするとSearchのonClickも効かなくなるので、divをラップしている
        onClick={() => {
          if (disabled) {
            searchableFieldsModal.showModal()
          }
        }}
        data-e2e="search-query-input"
      >
        <Input.Search
          defaultValue={searchQuery ?? ''}
          disabled={disabled}
          // onChange={(e) => {
          //   // TODO: lazy set
          //   // setSearchQuery(e.target.value)
          // }}
          // enterButton={<span>
          //   <SearchOutlined/> 検索
          // </span>}
          placeholder={
            disabled
              ? t(`検索設定がされていません`)
              : `${t(`{{keyword}}で検索`, {
                  keyword: searchableFields
                    .map((x) => x.meta.label.replace(`(${t(`必須`)})`, ''))
                    .unique()
                    .join(','),
                })}
                ...`
          }
          loading={loading.isTrue}
          onSearch={(query) => {
            setSearchQuery(query)
          }}
          allowClear
          style={{
            width: 300,
            cursor: 'pointer',
          }}
          onMouseDown={() => {
            if (changes.length > 0) {
              message.warning(t('検索すると変更情報が失われます。'))
            }
          }}
          className="px-3"
        />
      </div>
      <SearchableFieldsFormModal />
    </>
  )
}
