import type { ViewConfigKpi, ViewConfigSheet } from '../../../../schemas/view_config'
import { addDateFilter } from './addDateFilter'

export function addDateFieldAndFilter(config: ViewConfigSheet, date: ViewConfigKpi['date']): ViewConfigSheet {
  if (date === undefined) {
    return config
  }

  const configWithFilter = addDateFilter(config, date)

  return {
    ...configWithFilter,
    fields: [
      ...(configWithFilter.fields ?? []),
      {
        type: `property`,
        property: date.property,
      },
    ],
  }
}
