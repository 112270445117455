import { useEffect, useState } from 'react'

// 描画を指定した時間遅らせるためのラッパー
export function DelayWrapper({
  children,
  fallback,
  delay,
}: {
  children: JSX.Element
  fallback?: JSX.Element
  delay?: number
}) {
  const [flag, setFlag] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setFlag(true)
    }, delay ?? 0)
  }, [])

  if (!flag) {
    return fallback ?? <></>
  }

  return <>{children}</>
}
