const getOrganizationIdFromPathBody = (): string | undefined => {
  if (typeof window === 'undefined') {
    return undefined
  }

  const { pathname } = window.location
  if (pathname.startsWith('/o/')) {
    return pathname.split('/')[2]
  }
  return undefined
}

export const getOrganizationIdFromPath = (): string => {
  if (process.env.NODE_ENV === 'test') {
    // TODO
    return '1'
  }

  const id = getOrganizationIdFromPathBody()
  if (id === undefined) {
    return ''
  }

  return id
}
