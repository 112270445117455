import { dateUtil, isNull, isSome, isTruthy } from '@salescore/buff-common'
import type { BusinessDayFieldsFragment } from '@salescore/client-api'
import { CORE_CONSTANT, timeframeSchema, type ViewTimeframe } from '@salescore/core'
import type { Dayjs } from 'dayjs'

import type { RSheetContext } from '../../../rsheet/types'
import { generateDatesWithHoliday } from './generateDates'

export interface TimeRelatedDimensionRowIndex {
  index: number
  type: 'day' | 'week' | 'month' | 'year'
  format: string
}

interface ElapsedAndBusinessDays {
  elapsedDays: Record<string, number>
  businessDays: Record<string, number>
}

interface KpiDateDimension {
  type: 'row' | 'column'
  index: number
  key: string
  timeframe: ViewTimeframe
  timeframeRank: number
}

export interface ProgressType {
  elapsedDays: number // 期間内の経過日の日数(現時点までで経過した営業日)
  businessDays: number // 期間内の営業日の日数
  progressRate: number // 進捗率(ratioなので0~1の間)。経過日/営業日
  progressPercent: number // 進捗率(%なので0~100の間)。経過日/営業日 * 100
  hasTimeRelatedDimension: boolean // 時間系の軸があるかどうか（KPI日付の「週」を行にしているなど）
  //
  // 時間系の軸がある場合、その軸ごとの経過日と営業日の日数を事前に計算している。
  // 例えば「2023年9月第二週に営業日がいくつあるか」などを求めている
  // これをKpiCellで使って、セルごとの進捗率を計算する。
  // TODO: 事前計算の必要性は必ずしもなく、また現状だと全期間のときにこれが求められず、不具合となっているため、事前計算するのではなく必要な箇所で計算するようにしたい
  //
  daysByTimeframe: Partial<Record<ViewTimeframe, ElapsedAndBusinessDays>>
  // KPI日付軸があれば、それをここに格納。行・列の何番目で指定されているか
  kpiDateDimensions: KpiDateDimension[]
}

const calculateProgressRate = (businessDays: number, elapsedDays: number, isDemo: boolean) => {
  if (isDemo) {
    return 0.33
  }

  if (businessDays === 0) {
    return 0
  }

  return (elapsedDays * 1) / businessDays
}

const timeframes = ['day', 'week', 'month', 'fiscal_quarter', 'fiscal_year', 'year']

//
// startAtからendAtの間に含まれる営業日・経過日を計算し、進捗率(経過日/営業日)を求める関数
//
export const calculateAverageProgressService = ({
  startAt,
  endAt,
  current,
  isDemo,
  pivot,
  businessDays,
}: {
  startAt: Dayjs | undefined
  endAt: Dayjs | undefined
  current: Dayjs
  isDemo?: boolean
  pivot: RSheetContext['pivot']
  businessDays: BusinessDayFieldsFragment[]
}): ProgressType => {
  //
  // 進捗率を求める計算は、基本的にはstartAt〜endAtに含まれる営業日などを求めるだけで終わりなのだが、
  // 日付軸を使っている時は、行（列）ごとに個別の進捗率計算を行う必要があり、日付軸関連の情報が必要になる
  // 例えば、2023年9月20日に「全期間」で「行：KPI日付(月)」で分析している場合、
  // 「2023-08」の行は進捗率100%扱いにするし、「2023-09」の行は66%だし、「2023-10」の行は0%である
  //
  const kpiDateDimensions = [
    ...(pivot?.rows ?? []).map((x, index) => ({ ...x, index, type: 'row' as const })),
    ...(pivot?.columns ?? []).map((x, index) => ({ ...x, index, type: 'column' as const })),
  ]
    .filter((x) => x.key.endsWith(CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key)) // TODO
    .map((x): KpiDateDimension | undefined => {
      if (x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key) {
        return {
          ...x,
          timeframe: 'month' as const, // dateSpanがないとき、デフォルトだとmonth // TODO
          timeframeRank: timeframes.indexOf('month'), // TODO
        }
      }
      const maybeTimeframe = x.key.replace(`_${CORE_CONSTANT.KPI_PIVOT_KPI_DATE_DIMENSION().key}`, ``)
      const timeframe = timeframeSchema.safeParse(maybeTimeframe) // TODO
      if (!timeframe.success) {
        return undefined
      }
      return {
        ...x,
        timeframe: timeframe.data,
        timeframeRank: timeframes.indexOf(timeframe.data), // TODO
      }
    })
    .compact()
    .sortBy((x) => x.timeframeRank)
  const hasTimeRelatedDimension = kpiDateDimensions.isPresent()

  // 全期間のときは、進捗率100%扱いにする
  if (isNull(startAt) || isNull(endAt)) {
    return {
      elapsedDays: 0,
      businessDays: 0,
      progressRate: 1,
      progressPercent: 100,
      hasTimeRelatedDimension,
      kpiDateDimensions,
      daysByTimeframe: {},
    }
  }
  const dates = isSome(startAt) && isSome(endAt) ? generateDatesWithHoliday({ startAt, endAt, businessDays }) : []
  const businessDates = dates.filter((date) => date.is_business_day)
  const elapsedDates = businessDates.filter(
    (date) => date.date.isBefore(current, 'day') || date.date.isSame(current, 'day'),
  )
  const progressRate = calculateProgressRate(businessDates.length, elapsedDates.length, isTruthy(isDemo))

  return {
    elapsedDays: elapsedDates.length,
    businessDays: businessDates.length,
    progressRate,
    progressPercent: Math.round(progressRate * 100),
    hasTimeRelatedDimension,
    kpiDateDimensions,
    daysByTimeframe: kpiDateDimensions.toObject((d) => {
      const elapsedDays = elapsedDates
        .groupBy(
          (x) =>
            // TODO: weekの始まりの日の統一
            // if (timeRelatedDimensionRowIndex.type === 'week') {
            //   return x.date.startOf('week').add(1, 'day').format(timeRelatedDimensionRowIndex.format)
            // }
            // TODO: timeframeの統一
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any
            dateUtil.format(x.date, d.timeframe as any) ?? '',
        )
        .transformValues((xs) => xs.length).data
      const businessDays = businessDates
        .groupBy(
          (x) =>
            // 同上
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any
            dateUtil.format(x.date, d.timeframe as any) ?? '',
        )
        .transformValues((xs) => xs.length).data
      return [
        d.timeframe,
        {
          elapsedDays,
          businessDays,
        },
      ]
    }),
  }
}
