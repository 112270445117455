import { usePrevious } from '@dnd-kit/utilities'
import { isNull } from '@salescore/buff-common'
import { type ViewConfigDimension, viewConfigSchema, type WaterfallDimension } from '@salescore/core'
import type { Period } from '@salescore/features'
import { useDebouncedValue } from '@salescore/frontend-common'
import { Divider, Tabs } from 'antd'
import { t } from 'i18next'
import { type Dispatch, type ReactNode, type SetStateAction, useEffect, useMemo, useState } from 'react'
import { type SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { chartDesignSettingsAtom, riDisplayFormatAtom } from '../../../recoil/navigation/atoms'
import { additionalConfigAtom } from '../../../recoil/records/atoms'
import { viewAtom } from '../../../recoil/view/atoms'
import { setConfigWithoutChangeMutation } from '../../../recoil/view/mutations/setConfigMutation'
import type { TimeSpan } from './Form/TimeSpanFormInput'
import { ViewUiRiAggregationSetting } from './Form/ViewUiRiAggregationSettings'
import { ViewUiRiDesignSetting } from './Form/ViewUiRiDesignSetting'
import { ViewUIRiFormCompleteButton } from './Form/ViewUiRiFormCompleteButton'
import { ViewUiRiGoalSetting } from './Form/ViewUiRiGoalSetting'
import { ViewUIRiFormSaveButton } from './Form/ViewUiRiSaveButton'
import { useKpiTimeSeriesConfig, useKpiTimeSeriesConfigForm, useSavedPeriod, useUpdateKpiTimeSeriesView } from './hooks'
import { ViewUIRiPreview } from './ViewUIRiPreview'

// TODO: フォームの値をRecoilで管理するようにしたい
export function ViewUIRiForm(properties: { onClose: () => void }): ReactNode {
  const config = useKpiTimeSeriesConfig()

  // NOTE: 設定画面ではフィルタは適用しない
  const [additionalConfig, setAdditionalConfig] = useRecoilState(additionalConfigAtom)

  useEffect(() => {
    const previousSkipKpiTimeSeriesFilters = additionalConfig.skipKpiTimeSeriesFilters

    setAdditionalConfig((previous) => ({
      ...previous,
      skipKpiTimeSeriesFilters: true,
    }))

    return () => {
      setAdditionalConfig((previous) => ({
        ...previous,
        skipKpiTimeSeriesFilters: previousSkipKpiTimeSeriesFilters,
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // NOTE: 集計設定フォームの KPI 関連以外のインプット値とその初期化
  const [isFormInitialized, setIsFormInitialized] = useState(false)

  const { getSavedPeriod } = useSavedPeriod()
  const [period, setPeriod] = useState<Period | undefined>()
  const [timeSpan, setTimeSpan] = useState<TimeSpan | undefined>()
  const [breakdownProperty, setBreakdownProperty] = useState<ViewConfigDimension | undefined>()
  const [waterfallDimensions, setWaterfallDimensions] = useState<WaterfallDimension[] | undefined>([])

  useEffect(() => {
    if (config.period !== undefined) {
      setPeriod(getSavedPeriod(config.period))
    }

    if (config.goalConfigFragment?.timeSpanType !== undefined) {
      setTimeSpan(config.goalConfigFragment.timeSpanType)
    }

    if (config.breakdownProperty !== undefined) {
      setBreakdownProperty(config.breakdownProperty)
    }

    if (config.waterfall?.dimensions !== undefined) {
      setWaterfallDimensions(config.waterfall.dimensions)
    }

    // NOTE: 初期化直後の compile し直しを防ぐために form の debounce の遅延よりも大きい値で遅延させてからフラグを立てる
    setTimeout(() => {
      setIsFormInitialized(true)
    }, 1250)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // NOTE: デザイン設定フォームの初期化
  const [chartDesignSettings, setChartDesignSettings] = useRecoilState(chartDesignSettingsAtom)

  useEffect(() => {
    setChartDesignSettings((previous) => {
      if (config.designSettings === undefined) {
        return previous
      }

      return {
        ...previous,
        chartAxisLabelDisplayMode:
          config.designSettings.chartAxisLabelDisplayMode ?? chartDesignSettings.chartAxisLabelDisplayMode,
        chartValueLabelDisplayMode:
          config.designSettings.chartValueLabelDisplayMode ?? chartDesignSettings.chartValueLabelDisplayMode,
        chartValueLabelUnitType:
          config.designSettings.chartValueLabelUnitType ?? chartDesignSettings.chartValueLabelUnitType,
        chartValueLabelDecimalPlaceType:
          config.designSettings.chartValueLabelDecimalPlaceType ?? chartDesignSettings.chartValueLabelDecimalPlaceType,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // NOTE: configForm を watch し、変更があった場合は compile をし直す. それにより preview を更新する
  const [, setView] = useRecoilState(viewAtom)
  const setConfigWithoutChange = useSetRecoilState(setConfigWithoutChangeMutation)
  const { configForm, disabled, errorMessage } = useKpiTimeSeriesConfigForm({
    period,
    timeSpan,
    breakdownProperty,
    waterfallDimensions,
  })
  const configFormJsonString = useMemo(() => JSON.stringify(configForm), [configForm]) // 参照のみの差分は除きたいので、string 値で比較する
  const debouncedConfigFormJsonString = useDebouncedValue(configFormJsonString, 1000) // form 更新時に configForm が複数回更新されるため、debounce 値を watch の対象にする
  const previousDebouncedConfigFormJsonString = usePrevious(debouncedConfigFormJsonString)
  const [hasChanged, setHasChanged] = useState(false)

  useEffect(() => {
    if (!isFormInitialized || debouncedConfigFormJsonString === previousDebouncedConfigFormJsonString || disabled) {
      return
    }

    setHasChanged(true)

    const { data } = viewConfigSchema.safeParse(JSON.parse(configFormJsonString))
    if (isNull(data)) {
      return
    }

    setView((previous) => ({ ...previous, config: data }))
    setConfigWithoutChange(data)
  }, [debouncedConfigFormJsonString])

  const { updateKpiTimeSeriesView, loading } = useUpdateKpiTimeSeriesView()

  const updateKpiTimeSeriesViewBindConfigForm = async (
    shouldUseExistingGoalConfigFragment?: boolean,
  ): Promise<void> => {
    await updateKpiTimeSeriesView({ configForm, shouldUseExistingGoalConfigFragment })
    setHasChanged(false)
  }

  return (
    <div className="flex h-full flex-col bg-white px-4">
      <div className="flex h-full">
        <div className="relative w-1/2">
          <div className="absolute inset-0 overflow-y-auto">
            <div className="h-full pr-3">
              <ViewUIRiFormTabs
                period={period}
                timeSpan={timeSpan}
                breakdownProperty={breakdownProperty}
                waterfallDimensions={waterfallDimensions}
                loading={loading}
                disabled={disabled}
                hasChanged={hasChanged}
                setPeriod={setPeriod}
                setTimeSpan={setTimeSpan}
                setBreakdownProperty={setBreakdownProperty}
                setWaterfallDimensions={setWaterfallDimensions}
                updateKpiTimeSeriesView={updateKpiTimeSeriesViewBindConfigForm}
              />
            </div>
          </div>
        </div>

        <Divider type="vertical" className="ml-0 h-full" />

        <div className="flex w-1/2 flex-col py-4">
          <ViewUIRiPreview hideFilters />

          <div className="mr-16 flex justify-end gap-2">
            <ViewUIRiFormSaveButton
              loading={loading}
              disabled={disabled}
              errorMessage={errorMessage}
              onClick={() => {
                void updateKpiTimeSeriesViewBindConfigForm()
              }}
            />
            <ViewUIRiFormCompleteButton
              loading={loading}
              disabled={disabled}
              errorMessage={errorMessage}
              onClick={async () => {
                await updateKpiTimeSeriesViewBindConfigForm()
                properties.onClose()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function ViewUIRiFormTabs(properties: {
  period: Period | undefined
  timeSpan: TimeSpan | undefined
  breakdownProperty: ViewConfigDimension | undefined
  waterfallDimensions: WaterfallDimension[] | undefined
  loading: boolean
  disabled: boolean
  hasChanged: boolean
  setPeriod: SetterOrUpdater<Period | undefined>
  setTimeSpan: Dispatch<SetStateAction<TimeSpan | undefined>>
  setBreakdownProperty: Dispatch<ViewConfigDimension | undefined>
  setWaterfallDimensions: SetterOrUpdater<WaterfallDimension[] | undefined>
  updateKpiTimeSeriesView: (shouldUseExistingGoalConfigFragment?: boolean) => Promise<void>
}): ReactNode {
  const format = useRecoilValue(riDisplayFormatAtom)
  const viewUIKpiTimeSeriesFormFormTabs = [
    {
      key: '1',
      label: t(`集計設定`),
      children: (
        <ViewUiRiAggregationSetting
          period={properties.period}
          timeSpan={properties.timeSpan}
          breakdownProperty={properties.breakdownProperty}
          waterfallDimensions={properties.waterfallDimensions}
          setPeriod={properties.setPeriod}
          setTimeSpan={properties.setTimeSpan}
          setBreakdownProperty={properties.setBreakdownProperty}
          setWaterfallDimensions={properties.setWaterfallDimensions}
        />
      ),
    },
    format === 'timeSeries'
      ? {
          key: '2',
          label: t(`目標設定`),
          children: (
            <ViewUiRiGoalSetting
              period={properties.period}
              timeSpan={properties.timeSpan}
              breakdownProperty={properties.breakdownProperty}
              loading={properties.loading}
              hasChanged={properties.hasChanged}
              updateKpiTimeSeriesView={properties.updateKpiTimeSeriesView}
            />
          ),
          disabled: properties.disabled,
        }
      : undefined,
    {
      key: '3',
      label: t(`デザイン設定`),
      children: <ViewUiRiDesignSetting />,
      disabled: properties.disabled,
    },
  ].compact()

  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className="kpi-time-series-form-tabs-wrapper h-full">
      <Tabs items={viewUIKpiTimeSeriesFormFormTabs} destroyInactiveTabPane />
    </div>
  )
}
