import {
  BranchesOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FontSizeOutlined,
  MonitorOutlined,
  PlayCircleOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  StopOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { isNull, isSome } from '@salescore/buff-common'
import {
  ApplyEltModelChangeDocument,
  FetchSourcesTableRecordsDocument,
  UpsertHubConnectionDocument,
} from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import {
  CLIENT_COMMON_CONSTANT,
  getOrganizationIdFromPath,
  ProviderLogo,
  useOpenAuthorizationUrl,
} from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { useRedirect } from '@salescore/frontend-common'
import { App, Button, Dropdown, Form, Space, Table, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import Link from 'next/link'
import { type ReactNode, Suspense, useState } from 'react'

import { I18nSourcesContent } from './I18nSourcesContent'
import { ReportSyncConnection, type ReportSyncConnectionParameters } from './ReportSyncConnection'
import { type KintonePasswordForm, KintoneSourceEditForm } from './source_forms/KintoneSourceForm'
import { SalesforceSourceConfigForm } from './source_forms/SalesforceSourceConfigForm'
import { SourceDeleteContent } from './SourceDeleteModal'
import { SourceEditButton } from './SourceEditButton'
import { SourceInfoContent } from './SourceInfoContent'
import { SourceStatsContent } from './SourceStatsContent'
import { SyncButton } from './SyncButton'

export const SourcesTable = (): ReactNode => {
  const { message, modal } = App.useApp()

  const redirect = useRedirect()
  const me = recoil.global.useMe()

  const { openAuthorizationUrl } = useOpenAuthorizationUrl()

  const { data, loading, refetch } = useQuery(FetchSourcesTableRecordsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
    fetchPolicy: 'cache-and-network',
  })

  const [upsertHubConnectionMutation] = useMutation(UpsertHubConnectionDocument)
  const [applyEltModelChangeMutation] = useMutation(ApplyEltModelChangeDocument)

  const [reportSyncConnectionParameters, setReportSyncConnectionReportParameters] = useState<
    ReportSyncConnectionParameters | undefined
  >()
  const [kintoneForm] = Form.useForm<KintonePasswordForm>()

  return (
    <>
      <ReportSyncConnection
        onClose={() => {
          setReportSyncConnectionReportParameters(undefined)
        }}
        params={reportSyncConnectionParameters}
      />

      <Table
        rowKey={(record) => record.connection.source.id}
        loading={loading}
        pagination={false}
        dataSource={data?.sourcesTableRecords}
        columns={[
          {
            title: t(`連携先`),
            width: 90,
            render(_text, record) {
              return (
                <div className="px-2 py-1">
                  <ProviderLogo provider={record.connection.source.provider} />
                </div>
              )
            },
          },
          {
            title: t(`連携先名称`),
            dataIndex: 'name',
            render(_text, record) {
              return <span>{record.connection.source.name}</span>
            },
          },
          {
            title: (
              <Space>
                {t(`同期時刻`)}
                <Tooltip
                  title={t(
                    '連携先のオブジェクトの中で最も古い同期時刻が表示されます。エラーが発生している場合は最新のエラー発生時刻が表示されます。',
                  )}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
              </Space>
            ),
            render(_value, record) {
              const changedEltModels = record.connection.eltModels.filter((p) => p.eltModelChange !== null)
              const hasChange = !changedEltModels.isEmpty()
              const lastSyncedAt = record.lastSyncedAt
              const hasError = record.hasError
              const ChangeButton = hasChange ? (
                <Button
                  className="text-yellow-500"
                  type={'text'}
                  icon={<ExclamationCircleOutlined />}
                  onClick={() => {
                    void modal.confirm({
                      title: t('同期設定している項目の一部に変更が発生しています'),
                      content: (
                        <div>
                          <ul>
                            {changedEltModels.map((changedEltModel, index) => (
                              <li key={index}>{changedEltModel.model.label}</li>
                            ))}
                          </ul>
                          {t(
                            '同期項目を更新するをクリックすると次回の同期タイミングで再同期を行います。再同期には時間がかかることがあります。',
                          )}
                        </div>
                      ),
                      cancelText: t('閉じる'),
                      okText: t('同期項目を更新する'),
                      onOk: () => {
                        void applyEltModelChangeMutation({
                          variables: {
                            organizationId: me.organization.id,
                            eltModelChangeIds: changedEltModels
                              .map((x) => x.eltModelChange)
                              .compact()
                              .map((c) => c.id),
                          },
                          onCompleted: () => {
                            void message.success(t('更新しました'))
                          },
                        })
                      },
                    })
                  }}
                />
              ) : (
                <></>
              )
              const ErrorButton = hasError ? (
                <Button
                  danger={true}
                  type={'text'}
                  icon={<CloseCircleOutlined />}
                  onClick={() => {
                    setReportSyncConnectionReportParameters({
                      provider: record.connection.source.provider,
                      name: record.connection.source.name ?? '',
                      connectionId: record.connection.id,
                      organizationId: me.organization.id,
                    })
                  }}
                />
              ) : (
                <></>
              )
              if (!record.connection.active) {
                return (
                  <div>
                    {t(`停止中`)}
                    {ChangeButton}
                    {ErrorButton}
                  </div>
                )
              }
              if (isNull(lastSyncedAt)) {
                return (
                  <div>
                    {t(`初期化中`)}
                    {ChangeButton}
                    {ErrorButton}
                  </div>
                )
              }

              return (
                <div>
                  {t(`{{datetime, datetimestr}}`, { datetime: dayjs(lastSyncedAt) })}
                  {ChangeButton}
                  {ErrorButton}
                  {hasError && (
                    <>
                      <br />
                      <span className="text-xs">{t(`※同期されていないオブジェクトがあります`)}</span>
                    </>
                  )}
                </div>
              )
            },
          },
          {
            title: '',
            width: 80,
            render(_value, record) {
              return (
                <>
                  <SourceEditButton
                    sourceId={record.connection.source.id}
                    sourceName={record.connection.source.name ?? ''}
                    onCompleted={() => {
                      void message.success(t(`更新しました`))
                    }}
                    onError={() => {
                      void message.error(t(`エラーが発生しました`))
                    }}
                  />
                </>
              )
            },
          },
          {
            title: '',
            width: 100,
            render(_value, record) {
              const connection = record.connection

              // TODO
              return (
                <SyncButton
                  connection={{ ...connection, source: record.connection.source }}
                  onSuccess={() => {
                    void refetch()
                  }}
                />
              )
            },
          },
          {
            title: '',
            width: 100,
            render(_value, record) {
              return (
                <Link href={routes.connectionSourceSettingsPathV2(record.connection.source.id)} legacyBehavior>
                  <Button
                    icon={<UnorderedListOutlined />}
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                  >
                    {t(`同期項目`)}
                  </Button>
                </Link>
              )
            },
          },
          {
            title: '',
            width: 20,
            render(_value, record) {
              const source = record.connection.source
              const items = [
                {
                  key: `${source.id}-source-info`,
                  label: t(`設定内容確認`),
                  icon: <SettingOutlined />,
                  onClick: () => {
                    void modal.info({
                      maskClosable: true,
                      title: CLIENT_COMMON_CONSTANT.i18n.ja.provider[source.provider],
                      content: <SourceInfoContent source={source} />,
                    })
                  },
                },
                isSome(record.connection)
                  ? {
                      key: `${source.id}-models`,
                      label: t('オブジェクト一覧'),
                      icon: <UnorderedListOutlined />,
                      onClick: () => {
                        redirect(routes.eltModelsSettingsPathV2(record.connection.id))
                      },
                    }
                  : undefined,
                {
                  key: `${source.id}-record-count`,
                  label: t(`レコード数の確認`),
                  icon: <MonitorOutlined />,
                  onClick: () => {
                    void modal.info({
                      maskClosable: true,
                      width: '600px',
                      title: t(`レコード数の確認`),
                      content: <SourceStatsContent eltModelNames={record.connection.eltModels.map((p) => p.name)} />,
                    })
                  },
                },
                source.provider === 'salesforce' && me.organization.setting.i18nEnabled
                  ? {
                      key: `${source.id}-i18n`,
                      label: t(`多言語設定`),
                      icon: <FontSizeOutlined />,
                      onClick: () => {
                        void modal.warning({
                          maskClosable: true,
                          width: '600px',
                          title: t(`多言語設定`),
                          content: (
                            <I18nSourcesContent
                              source={source}
                              onCompleted={() => {
                                void message.success(t(`削除しました`))
                              }}
                              onError={() => {
                                void message.error(t(`エラーが発生しました`))
                              }}
                            />
                          ),
                          okType: 'default',
                          okText: t('閉じる'),
                        })
                      },
                    }
                  : undefined,
                source.provider === 'salescore' || source.provider === 'kintone'
                  ? undefined
                  : {
                      key: `${source.id}-reconnection`,
                      label: t(`再連携`),
                      icon: <BranchesOutlined />,
                      onClick: () => {
                        void openAuthorizationUrl({
                          type: 'organizationSource',
                          provider: source.provider,
                          source,
                        })
                      },
                    },
                source.provider === 'kintone'
                  ? {
                      key: `${source.id}-reconfigure`,
                      label: t(`再設定`),
                      icon: <BranchesOutlined />,
                      onClick: () => {
                        const m = modal.info({
                          okText: t('閉じる'),
                          okType: 'default',
                          title: t('Kintoneの接続情報の再設定'),
                          content: (
                            <KintoneSourceEditForm
                              form={kintoneForm}
                              source={source}
                              onAfterFinish={() => {
                                m.destroy()
                              }}
                            />
                          ),
                        })
                      },
                    }
                  : undefined,
                source.provider === 'salesforce'
                  ? {
                      key: `${source.id}-reconfigure`,
                      label: t(`詳細設定`),
                      icon: <EditOutlined />,
                      onClick: () => {
                        const m = modal.info({
                          footer: null,
                          title: t('Salesforceの詳細設定'),
                          content: (
                            <Suspense>
                              <SalesforceSourceConfigForm
                                sourceId={source.id}
                                onAfterFinish={() => {
                                  m.destroy()
                                }}
                              />
                            </Suspense>
                          ),
                        })
                      },
                    }
                  : undefined,
                {
                  key: `${source.id}-connection_status`,
                  label: record.connection.active ? t(`連携を一時停止`) : t(`連携を再開`),
                  icon: record.connection.active ? <StopOutlined /> : <PlayCircleOutlined />,
                  onClick: async () => {
                    if (isNull(record.connection)) {
                      return
                    }

                    try {
                      await upsertHubConnectionMutation({
                        variables: {
                          input: {
                            organizationId: record.connection.organizationId,
                            sourceId: record.connection.sourceId,
                            active: !record.connection.active,
                          },
                        },
                      })
                      void message.success(t(`更新しました`))
                    } catch {
                      void message.error(t(`エラーが発生しました`))
                    }
                  },
                },
                source.provider === 'salescore'
                  ? undefined
                  : {
                      key: `${source.id}-connection-delete`,
                      label: t(`連携設定を削除`),
                      icon: <DeleteOutlined />,
                      onClick: () => {
                        const m = modal.warning({
                          maskClosable: true,
                          width: '600px',
                          title: t(`{{provider}}連携を削除しますか？`, {
                            provider: CLIENT_COMMON_CONSTANT.i18n.ja.provider[source.provider],
                          }),
                          okType: 'default',
                          okText: t('閉じる'),
                          content: (
                            <SourceDeleteContent
                              sourceId={source.id}
                              onCompleted={() => {
                                void message.success(t(`連携設定を削除しました`))
                                m.destroy()
                                void refetch()
                              }}
                              onError={() => {
                                void message.error(t(`エラーが発生しました`))
                              }}
                            />
                          ),
                        })
                      },
                    },
              ].compact()

              return (
                <Dropdown menu={{ items }} trigger={['click']}>
                  <Button icon={<SettingOutlined />} />
                </Dropdown>
              )
            },
          },
        ]}
      />
    </>
  )
}
