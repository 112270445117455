import { ExclamationCircleOutlined, MailOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { compact, compareFunction, isNull, isSome } from '@salescore/buff-common'
import {
  FetchMixedUsersDocument,
  type FetchMixedUsersQuery,
  UpsertInvitationsDocument,
  UpsertUsersDocument,
  UserRoleEnumV2,
} from '@salescore/client-api'
import {
  getOrganizationIdFromPath,
  handleMe,
  UploadImageButton,
  UserAvatar,
  UserPlanTag,
} from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { createDestroyColumn, getColumnSearchProps } from '@salescore/frontend-common'
import { message, Select, Table, Tag, Tooltip } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useState, useTransition } from 'react'

import { createUserRoleOptions, USER_PROVIDER_JA } from '../invitations/CreateInvitationForm'
import { INTEGRATION_PROVIDERS_WITH_COLUMN_NAMES, IntegrationProviderColumnIcon } from './IntegrationProviderColumnIcon'

type MixedUser = FetchMixedUsersQuery['mixedUsers'][0]

export const roleToRank = (role?: string) => {
  if (isNull(role)) {
    return 9
  }

  // NOTE:カスタム権限のランクは一旦4とする(要PdM相談)
  if (role.startsWith('custom_')) {
    return 4
  }

  return (
    {
      [UserRoleEnumV2.Admin]: 0,
      [UserRoleEnumV2.Manager]: 1,
      [UserRoleEnumV2.PlayingManager]: 2,
      [UserRoleEnumV2.NecPlayingManager]: 2,
      [UserRoleEnumV2.NecMember]: 3,
      [UserRoleEnumV2.NxMember]: 3,
      [UserRoleEnumV2.Member]: 4,
      [UserRoleEnumV2.ReadonlyMember]: 5,
      [UserRoleEnumV2.Guest]: 10,
    }[role] ?? 100
  )
}

export const UsersTable = (): ReactNode => {
  const options = createUserRoleOptions()
  const { data, refetch } = useSuspenseQuery(FetchMixedUsersDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })
  const users = data.mixedUsers
  const [fetching, startTransition] = useTransition()
  const invalidate = () => {
    startTransition(async () => {
      await refetch()
    })
  }
  // const me = api.fetchMe({ organizationId: getOrganizationIdFromPath() }) // TODO
  const [upsertInvitationsMutation] = useMutation(UpsertInvitationsDocument)
  const [upsertUsersMutation] = useMutation(UpsertUsersDocument)
  // const [updateResourceUsersMutation] = useUpdateResourceUsersMutation()
  const [updatingUserIds, setUpdatingUserIds] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const sortedUsers = users
    .filter((x) => x.user?.role !== UserRoleEnumV2.Admin)
    .mySortBy((x) => [roleToRank(x.user?.role), x.invitation?.role, x.resourceUser.email])
  const canManageUsers = recoil.global.policy.useCan('manage-users')

  return handleMe(({ isAdmin }) => (
    <Table
      loading={fetching}
      rowKey="id"
      size="small"
      pagination={{ defaultPageSize: 100 }}
      dataSource={sortedUsers}
      columns={[
        {
          title: t(`ユーザー名`),
          dataIndex: 'name',
          width: 200,
          sorter: (a, b) => compareFunction(a.resourceUser.name, b.resourceUser.name),
          ...getColumnSearchProps((record: MixedUser) => record.resourceUser.name),
          render(_text, record: MixedUser) {
            return (
              <Tooltip title={`${t(`ユーザーID`)}: ${record.id}`}>
                <span>
                  <UserAvatar user={record.resourceUser} /> {record.resourceUser.name}
                </span>
              </Tooltip>
            )
          },
        },
        {
          title: t(`メールアドレス`),
          dataIndex: 'email',
          sorter: (a, b) => compareFunction(a.resourceUser.email, b.resourceUser.email),
          ...getColumnSearchProps((record: MixedUser) => record.resourceUser.email),
          render(_text, record: MixedUser) {
            return <span>{record.resourceUser.email}</span>
          },
        },
        {
          title: t(`連携状況`),
          dataIndex: 'integration',
          width: 100,
          render(_text, record: MixedUser) {
            return (
              <div>
                {INTEGRATION_PROVIDERS_WITH_COLUMN_NAMES.map((provider) => {
                  const ids = compact(provider.columns.map((column) => record.resourceUser[column]))
                  if (ids.length === 0) {
                    return <></>
                  }
                  return (
                    <IntegrationProviderColumnIcon
                      key={`${record.id}-${provider.provider}`}
                      provider={provider}
                      ids={ids}
                    />
                  )
                })}
              </div>
            )
          },
        },
        {
          title: t(`招待`),
          width: 70,
          dataIndex: 'invitationStatus',
          sorter: (a, b) => {
            const isInvitedA = isNull(a.user) && isSome(a.invitation)
            const isInvitedB = isNull(b.user) && isSome(b.invitation)
            return compareFunction(isInvitedA, isInvitedB)
          },
          render(_text, record: MixedUser) {
            const { user, invitation } = record
            if (isNull(user) && isSome(invitation)) {
              return <Tag icon={<MailOutlined />}>{t(`招待中`)}</Tag>
            }
            return <span></span>
          },
        },
        {
          title: t(`ライセンス`),
          width: 100,
          sorter: (a, b) => {
            const planA = [a.user?.planForSync, a.user?.planForVis, a.invitation?.planForSync, a.invitation?.planForVis]
            const planB = [b.user?.planForSync, b.user?.planForVis, b.invitation?.planForSync, b.invitation?.planForVis]
            return compareFunction(planA, planB)
          },
          render(_, record) {
            return <UserPlanTag userOrInvitation={record.user ?? record.invitation ?? undefined} />
          },
        },
        {
          title: t(`権限`),
          width: 180,
          sorter: (a, b) => {
            const roleA = a.user?.role ?? a.invitation?.role
            const roleB = b.user?.role ?? b.invitation?.role
            return compareFunction(roleA, roleB)
          },
          render(_, record) {
            const role = record.user?.role ?? record.invitation?.role
            if (role === undefined) {
              // TODO
              return (
                <Tooltip
                  title={t(
                    `連携中のCRMに存在するユーザーです。SALESCOREのアカウントを持たないため、請求対象にはなりません。`,
                  )}
                >
                  <div className="text-gray-600">
                    {t(`連携先ユーザー`)} <QuestionCircleOutlined />
                  </div>
                </Tooltip>
              )
            }

            return (
              <Select
                style={{ width: 200 }}
                className="w-full"
                value={role}
                options={options}
                optionRender={(option) => <span className="whitespace-normal break-words ">{option.label}</span>}
                loading={updatingUserIds.includes(record.id)}
                disabled={updatingUserIds.includes(record.id) || !canManageUsers}
                onChange={async (e) => {
                  const role = e as UserRoleEnumV2
                  const { invitation, user } = record
                  setUpdatingUserIds((old) => [...old, record.id])

                  if (isSome(user)) {
                    await upsertUsersMutation({
                      variables: {
                        organizationId: getOrganizationIdFromPath(),
                        users: [
                          {
                            id: user.id,
                            role,
                          },
                        ],
                      },
                    })
                  } else if (isSome(invitation)) {
                    await upsertInvitationsMutation({
                      variables: {
                        organizationId: getOrganizationIdFromPath(),
                        invitations: [
                          {
                            id: invitation.id,
                            email: invitation.email,
                            provider: invitation.provider,
                            role,
                          },
                        ],
                      },
                    })
                  }

                  setUpdatingUserIds((old) => old.filter((x) => x !== record.id))
                  invalidate()
                  void message.success(t(`役割を更新しました`))
                }}
              />
            )
          },
        },
        {
          title: t(`ログイン方法`),
          width: 200,
          sorter: (a, b) => {
            const providerA = a.user?.identity.provider ?? a.invitation?.provider
            const providerB = b.user?.identity.provider ?? b.invitation?.provider
            return compareFunction(providerA, providerB)
          },
          render(_, record: MixedUser) {
            const provider = record.user?.identity.provider ?? record.invitation?.provider
            if (provider === undefined) {
              // TODO
              return <div className="text-gray-500">-</div>
            }

            return <div className="">{isSome(provider) ? (USER_PROVIDER_JA[provider] ?? provider) : ''}</div>
          },
        },
        // {
        //   title: (
        //     <div>
        //       表示 <QuestionCircleOutlined className="ml-0" />
        //     </div>
        //   ),
        //   showSorterTooltip: {
        //     title:
        //       '非表示にした場合、目標設定やチーム設定にユーザーが表示されなくなります。ダッシュボードには表示されます。（ダッシュボードでの表示ユーザーを操作したい場合、ダッシュボード項目での設定をお願いいたします）',
        //   },
        //   width: 100,
        //   key: 'visibility',
        //   sorter: (a, b) => {
        //     return compareFunction(a.resourceUser.visibility, b.resourceUser.visibility)
        //   },
        //   render(_, record: MixedUser) {
        //     const visibility = record.resourceUser.visibility
        //     const [loading, setLoading] = useState(false)

        //     return (
        //       <Switch
        //         key={`${record.id}-visibility`}
        //         defaultChecked={visibility}
        //         loading={loading}
        //         onChange={async (visibility) => {
        //           setLoading(true)
        //           try {
        //             await updateResourceUsersMutation({
        //               variables: {
        //                 organizationId: getOrganizationIdFromPath(),
        //                 users: [
        //                   {
        //                     id: record.id,
        //                     visibility,
        //                   },
        //                 ],
        //               },
        //             })
        //             // void message.success('表示を更新しました')
        //           } catch (e) {
        //             void message.error('エラーが発生しました')
        //             notifyBugsnag(e)
        //           } finally {
        //             setLoading(false)
        //           }
        //         }}
        //       />
        //     )
        //   },
        // },
        ...(isAdmin
          ? [
              {
                title: (
                  <span>
                    <ExclamationCircleOutlined className="mr-1" />
                    {t(`画像アップロード`)}
                  </span>
                ),
                render(_: unknown, record: MixedUser) {
                  return (
                    <div key={record.id}>
                      <UploadImageButton
                        onFinish={async (imageUrl) => {
                          const { user } = record
                          if (isNull(user)) {
                            return
                          }

                          setUpdatingUserIds((old) => [...old, record.id])
                          await upsertUsersMutation({
                            variables: {
                              organizationId: getOrganizationIdFromPath(),
                              users: [
                                {
                                  id: user.id,
                                  imageUrl,
                                },
                              ],
                            },
                          })
                          invalidate()
                          setUpdatingUserIds((old) => old.filter((x) => x !== record.id))
                          void message.success(t(`プロフィール画像を更新しました`))
                        }}
                      />
                    </div>
                  )
                },
              },
            ]
          : []),
        createDestroyColumn(
          loading,
          setLoading,
          async (mixedUser: MixedUser) => {
            const { user, invitation } = mixedUser
            if (isSome(user)) {
              await upsertUsersMutation({
                variables: {
                  organizationId: getOrganizationIdFromPath(),
                  users: [
                    {
                      id: user.id,
                      deleted: true,
                    },
                  ],
                },
              })
            } else if (isSome(invitation)) {
              await upsertInvitationsMutation({
                variables: {
                  organizationId: getOrganizationIdFromPath(),
                  invitations: [
                    {
                      id: invitation.id,
                      email: invitation.email,
                      provider: invitation.provider,
                      role: invitation.role,
                      deleted: true,
                    },
                  ],
                },
              })
            }
            invalidate()
          },
          (mixedUser: MixedUser) => {
            if (!canManageUsers) {
              return false
            }
            const { user, invitation } = mixedUser
            return isSome(user) || isSome(invitation)
          },
        ),
      ]}
    />
  ))
}
