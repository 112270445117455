import { CalendarOutlined, DeleteOutlined, MenuOutlined } from '@ant-design/icons'
import { CONSTANT } from '@salescore/client-base'
import { CORE_CONSTANT, type ViewConfigDimension, type ViewTimeframe } from '@salescore/core'
import { Button, List, Select } from 'antd'
import { arrayMoveImmutable } from 'array-move'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

// i18n:グローバル変数のため別途対応
const dateTimeOptions: Array<{ value: ViewTimeframe; label: string }> = [
  {
    value: 'year',
    label: t(`年`),
  },
  {
    value: 'fiscal_year',
    label: t(`会計年`),
  },
  {
    value: 'fiscal_half',
    label: t(`半期`),
  },
  {
    value: 'fiscal_quarter',
    label: t(`Q`),
  },
  {
    value: 'month',
    label: t(`月`),
  },
  {
    value: 'week',
    label: t(`週`),
  },
  {
    value: 'weekday',
    label: t(`曜日`),
  },
  {
    value: 'day',
    label: t(`日`),
  },
  {
    value: 'hour',
    label: t(`時間`),
  },
]

interface GroupedDimension {
  dimension: ViewConfigDimension
  isDateRelated: boolean
  dateSpans: Array<Exclude<ViewConfigDimension['dateSpan'], undefined>>
}

interface SortableItemArgument {
  groupedDimension: GroupedDimension
  onDestroy: (item: GroupedDimension) => void
  onDateSpanChange: (item: GroupedDimension) => void
}
const SortableItem = SortableElement<SortableItemArgument>(
  ({ groupedDimension, onDestroy, onDateSpanChange }: SortableItemArgument) => {
    const deletable = groupedDimension.dimension.key !== CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key

    return (
      <div
        className={`flex h-12 items-center justify-between pl-3 pr-2 align-middle`}
        style={{
          borderBottom: `1px solid ${CONSTANT.colors.border}`,
        }}
      >
        <div
          style={{
            // width: 200,
            fontSize: 13,
          }}
        >
          <MenuOutlined className="pr-3 hover:opacity-75" />
          <span className="w-full text-left hover:opacity-75">{groupedDimension.dimension.label}</span>
        </div>
        <div className={`flex h-12 items-center justify-between align-middle`}>
          {groupedDimension.isDateRelated && (
            <div>
              <CalendarOutlined />
              <Select
                size="small"
                className="min-w-[130px] text-xs"
                bordered={false}
                placeholder={t(`表示形式`)}
                tagRender={(option) => <span style={{ marginRight: 1 }}>{option.label}</span>}
                mode="multiple"
                value={groupedDimension.dateSpans}
                options={dateTimeOptions}
                onChange={(value: string[]) => {
                  const dateSpans = value as Array<Exclude<ViewConfigDimension['dateSpan'], undefined>>
                  onDateSpanChange({
                    ...groupedDimension,
                    dateSpans: dateSpans.sortBy((x) => dateTimeOptions.findIndex((option) => option.value === x)),
                  })
                }}
              />
            </div>
          )}
          {deletable && (
            <Button
              icon={<DeleteOutlined />}
              // danger
              onClick={() => {
                onDestroy(groupedDimension)
              }}
              type="text"
            />
          )}
        </div>
      </div>
    )
  },
)

interface ListBodyArgument {
  dimensions: GroupedDimension[]
  loading: boolean
  dragging: boolean
  onDestroy: (dimension: GroupedDimension) => void
  onDateSpanChange: (item: GroupedDimension) => void
}
const SortableDimensionsListBody = SortableContainer<ListBodyArgument>(
  ({ dimensions, loading, dragging, onDestroy, onDateSpanChange }: ListBodyArgument) => (
    <List
      className={`mt-4 overflow-scroll`}
      loading={loading}
      bordered={true}
      style={dragging ? { cursor: 'grabbing' } : { cursor: 'grab' }}
      dataSource={dimensions}
      renderItem={(dimension, index) => (
        <SortableItem
          key={`item-${dimension.dimension.key}`}
          index={index}
          groupedDimension={dimension}
          onDestroy={onDestroy}
          onDateSpanChange={onDateSpanChange}
        />
      )}
    />
  ),
)

function groupDimensions(dimensions: ViewConfigDimension[]): GroupedDimension[] {
  // dimensionsは、dateSpanがバラバラに入っているが、UI上はまとめる
  return dimensions
    .groupBy((x) => x.key)
    .map((_key, ds): GroupedDimension => {
      const dimension = ds.first()!
      return {
        dimension,
        isDateRelated: dimension.fieldType === 'date' || dimension.fieldType === 'datetime',
        dateSpans: ds
          .map((d) => d.dateSpan)
          .compact()
          .sortBy((x) => dateTimeOptions.findIndex((y) => y.value === x)),
      }
    })
}

function ungroupDimensions(groups: GroupedDimension[]): ViewConfigDimension[] {
  return groups.flatMap((group) => {
    if (group.dateSpans.isBlank()) {
      return [group.dimension]
    }
    return group.dateSpans.map((dateSpan) => ({
      ...group.dimension,
      dateSpan,
    }))
  })
}

export function DimensionsPickerSortableList({
  dimensions,
  onChange,
}: {
  dimensions: ViewConfigDimension[]
  onChange: (dimensions: ViewConfigDimension[]) => void
}) {
  const [dragging, setDragging] = useState(false)
  const [loading, setLoading] = useState(false)
  const groupedDimensions = useMemo(() => groupDimensions(dimensions), [dimensions])

  if (dimensions.length === 0) {
    return <></>
  }

  return (
    <SortableDimensionsListBody
      dimensions={groupedDimensions}
      dragging={dragging}
      loading={loading}
      onDestroy={(dimension) => {
        const filtered = groupedDimensions.filter((x) => x.dimension.key !== dimension.dimension.key)
        onChange(ungroupDimensions(filtered))
      }}
      onDateSpanChange={(newGroupedDimension) => {
        const newGroupedDimensions = groupedDimensions.map((group) => {
          if (group.dimension.key === newGroupedDimension.dimension.key) {
            return newGroupedDimension
          }
          return group
        })
        onChange(ungroupDimensions(newGroupedDimensions))
      }}
      onSortStart={() => {
        setDragging(true)
      }}
      onSortEnd={({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        setDragging(false)
        if (oldIndex === newIndex) {
          // 何もしない
        } else {
          // NOTE: ソート時のUXを良くするため、onChangeは非同期で行い、itemsやvalueの変更はソート直後に行われるようにした方が良い。
          const newItems = arrayMoveImmutable(groupedDimensions, oldIndex, newIndex)
          try {
            setLoading(true)
            onChange(ungroupDimensions(newItems))
          } finally {
            setLoading(false)
          }
        }
      }}
    />
  )
}
