import { isNull, isTruthy } from '@salescore/buff-common'
import {
  CORE_CONSTANT,
  positiveWaterfallDimensions,
  type ViewQueryRecordNode,
  type WaterfallDimension,
} from '@salescore/core'
import { EChartWaterfall } from '@salescore/frontend-common'
import { Table } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { useAggregationRecordsValue } from '../../../../recoil/records/hooks'
import { useKpiTimeSeriesConfig } from '../hooks'
import type { ViewUIRiPreviewProperties } from '../viewUIRiPreviewProperties'
import { ViewUiRiPreviewWrapper } from './ViewUiRiPreviewWrapper'
import { ViewUiRiWaterfallPreviewHeader } from './ViewUiRiWaterfallPreviewHeader'

export function ViewUiRiWaterfallPreview(properties: ViewUIRiPreviewProperties): ReactNode {
  return (
    <ViewUiRiPreviewWrapper
      header={<ViewUiRiWaterfallPreviewHeader {...properties} />}
      body={<ViewUiRiWaterfallPreviewGraph {...properties} />}
    />
  )
}

function ViewUiRiWaterfallPreviewGraph(properties: ViewUIRiPreviewProperties): ReactNode {
  const config = useKpiTimeSeriesConfig()
  const records = useAggregationRecordsValue()
  const { drillDownModal } = useNavigationModal()

  if (isNull(config)) {
    return (
      <div className="h-full overflow-hidden py-6">
        <Table></Table>
      </div>
    )
  }

  const { p1RecordData, p2RecordData } = generatePeriodRecordData(records)
  const dimensionedRecordMap = records.toRecord<ViewQueryRecordNode>((record) => {
    const key = record.attributes[CORE_CONSTANT.WATERFALL_DIMENSION_FIELD_NAME]
    if (typeof key !== 'string') {
      // undefinedを返せないので適当な値を入れている。使う想定はない
      return [`__NotFoundedDimension__`, record]
    }
    return [key, record]
  })

  const dimensions = config.waterfall?.dimensions ?? []
  const partitioned = dimensions.partition((dimension) =>
    new Set<WaterfallDimension['name']>(positiveWaterfallDimensions).has(dimension.name),
  )
  const [positiveDimensions, negativeDimensions] = partitioned
  const dimensionsData = [...positiveDimensions, ...negativeDimensions].map((dimension) => {
    const record = dimensionedRecordMap.data[dimension.name]
    const value = record?.attributes[CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME]
    return {
      category: {
        name: dimension.name,
        label: dimension.label,
      },
      value: typeof value === 'number' ? value : 0,
    }
  })

  const othersData = {
    category: {
      name: 'others',
      label: t('その他'),
    },
    value: p2RecordData.value - p1RecordData.value - dimensionsData.map((x) => x.value).sum(),
  }

  const data = [p1RecordData, ...dimensionsData, ...(othersData.value === 0 ? [] : [othersData]), p2RecordData]

  return (
    <div className="h-full overflow-hidden py-6">
      <EChartWaterfall
        options={{
          data,
          // 画面を分割して表示している時は、X軸の項目を回転させて重ならないようにする
          shouldLabelRotate: !isTruthy(properties.fullWidth),
          designSettings: {
            axisLabelDisplayMode: config.designSettings?.chartAxisLabelDisplayMode,
            valueLabelDisplayMode: config.designSettings?.chartValueLabelDisplayMode,
            valueLabelUnitType: config.designSettings?.chartValueLabelUnitType,
            valueLabelDecimalPlaceType: config.designSettings?.chartValueLabelDecimalPlaceType,
          },
        }}
        onSeriesClick={(value) => {
          drillDownModal.showModal({
            type: 'kpi',
            dimensionValues: {
              rows: [
                {
                  value: value.key === 'others' ? CORE_CONSTANT.KPI_PIVOT_NULL_STRING : value.key,
                  label: value.label,
                },
              ].compact(),
              columns: [],
            },
          })
        }}
      />
    </div>
  )
}

function generatePeriodRecordData(records: ViewQueryRecordNode[]) {
  // 期間1と期間2の集計値はnoGroupingRootで返ってくるはず
  const periodRecords = records.filter((record) => record.id === CORE_CONSTANT.AGGREGATION_NO_GROUPING_ROOT_ID)
  const [p1Record, p2Record] = periodRecords

  const p1RecordValue = p1Record?.attributes[CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME]
  const p2RecordValue = p2Record?.attributes[CORE_CONSTANT.KPI_PIVOT_VALUE_COLUMN_NAME]

  return {
    p1RecordData: {
      category: {
        name: CORE_CONSTANT.WATERFALL_FIRST_PERIOD_NODE_SUFFIX,
        label: t('期間1'),
      },
      value: typeof p1RecordValue === 'number' ? p1RecordValue : 0,
    },
    p2RecordData: {
      category: {
        name: CORE_CONSTANT.WATERFALL_SECOND_PERIOD_NODE_SUFFIX,
        label: t('期間2'),
      },
      value: typeof p2RecordValue === 'number' ? p2RecordValue : 0,
    },
  }
}
