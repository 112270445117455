import { compareFunction } from '@salescore/buff-common'
import type { SourceEntityPropertySchemaFieldsFragment, SourceEntitySchemaFieldsFragment } from '@salescore/client-api'
import { PropertyMetaTag, PropertyTypeTag } from '@salescore/client-common'
import { ellipsisFilter, getColumnSearchProps } from '@salescore/frontend-common'
import { Modal, Table, Tag } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

export const SourceEntitySchemaModal = ({
  initialPropertyNames,
  entitySchema,
  isModalVisible,
  onFinish,
}: {
  initialPropertyNames: string[]
  entitySchema: SourceEntitySchemaFieldsFragment
  isModalVisible: boolean
  onFinish: (propertyNames: string[]) => void
}) => {
  const [propertyNames, setPropertyNames] = useState(initialPropertyNames)
  // セットする際に、強制的にforcePickのentityを追加、またソートする
  const set = (xs: string[]) => {
    const requiredPropertyNames = entitySchema.properties.filter((x) => x.forcePick === true).map((x) => x.name)
    setPropertyNames([...xs, ...requiredPropertyNames].unique().sortBy((x) => x))
  }

  useEffect(() => {
    // 初期化
    set(propertyNames)
  }, [entitySchema.properties])

  return (
    <Modal
      open={isModalVisible}
      title={
        <div>
          {entitySchema.label} {t(`オブジェクト`)}
        </div>
      }
      onCancel={() => {
        onFinish(propertyNames)
      }}
      onOk={() => {
        onFinish(propertyNames)
      }}
      width="90%"
      style={{ top: '5%' }}
      cancelButtonProps={{ style: { display: 'none' } }}
    >
      <p>{t(`同期する項目を選択してください`)}</p>
      <div className="overflow-x-scroll">
        <Table
          dataSource={entitySchema.properties
            .map((x) => ({ ...x, key: x.name }))
            .sortBy((x) => [(x.forcePick ?? false) ? 0 : 1, x.name])}
          pagination={false}
          rowSelection={{
            selectedRowKeys: propertyNames,
            onChange: (newPropertyNames: unknown[]) => {
              // 全てを外したらそもそもオブジェクトの選択を外す？とかのロジックをやるか？どこでやるか？
              set(newPropertyNames as string[])
            },
            getCheckboxProps(record) {
              return {
                disabled: record.forcePick === true,
              }
            },
          }}
          scroll={{ y: 'min(75vh, calc(100vh - 300px)' }}
          style={{ width: '100%' }}
          columns={[
            {
              title: <></>,
              dataIndex: `forcePick`,
              width: 20,
              sorter: (a, b) => compareFunction(a.forcePick, b.forcePick),
              render(_value, record) {
                if (record.forcePick === true) {
                  return <Tag color="blue">必須</Tag>
                }
                return <></>
              },
            },
            {
              title: t(`項目名`),
              key: 'label',
              width: 250,
              sorter: (a, b) => compareFunction(a.label, b.label),
              render(_value, record) {
                return <div>{ellipsisFilter(record.label, 35)}</div>
              },
              ...getColumnSearchProps((record: SourceEntityPropertySchemaFieldsFragment) => record.label),
            },
            {
              title: <div className="">{t(`内部名`)}</div>,
              dataIndex: 'name',
              key: 'name',
              width: 150,
              sorter: (a, b) => compareFunction(a.name, b.name),
              render(_value, record) {
                return <div className="text-gray-500">{ellipsisFilter(record.name, 30)}</div>
              },
              ...getColumnSearchProps((record: SourceEntityPropertySchemaFieldsFragment) => record.name),
            },
            {
              title: t(`型`),
              dataIndex: 'type',
              sorter: (a, b) => compareFunction(a.type, b.type),
              render(_value: string, record) {
                return <PropertyTypeTag type={record.type} />
              },
            },
            {
              title: t(`メタ情報`),
              dataIndex: 'meta',
              sorter: (a, b) => compareFunction(a.meta, b.meta),
              render(_value: string, record) {
                return <PropertyMetaTag meta={record.meta} />
              },
            },
            {
              title: t(`選択肢`),
              dataIndex: 'selectOptions',
              sorter: (a, b) =>
                compareFunction(
                  (a.selectOptions ?? []).map((x) => x.label).join(','),
                  (b.selectOptions ?? []).map((x) => x.label).join(','),
                ),
              render(_value: string, record) {
                const options = (record.selectOptions ?? []).map((x) => x.label)
                if (options.length === 0) {
                  return <span></span>
                }
                return (
                  <span>
                    {options.slice(0, 3).join(',')}
                    {options.length > 3 ? `...${t(`(他{{length}}件)`, { length: options.length - 3 })}` : ''}
                  </span>
                )
              },
            },
            {
              title: t(`参照先`),
              dataIndex: 'referenceTo',
              sorter: (a, b) => compareFunction(a.referenceTo, b.referenceTo),
              render(_value: unknown, record) {
                return (
                  <>
                    {(record.referenceTo ?? []).map((x) => (
                      <Tag color="default" className="px-2">
                        {x}
                      </Tag>
                    ))}
                  </>
                )
              },
            },
          ]}
        />
      </div>
    </Modal>
  )
}
