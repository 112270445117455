import { selector } from 'recoil'

import { ROW_HEIGHT } from '../../rsheet/components/body/const'
import { uiAtom } from '../view/atoms'
import { rowLineNumAtom as rowLineNumberAtom } from './atoms'

export const rowLineSelector = selector({
  key: `view/navigation/rowLineSelector`,
  get({ get }) {
    const rowLineNumber = get(rowLineNumberAtom)

    // TODO: kpiPivotであることをどう判定するか
    //       そもそも、複数シートを操作しうることなどを考えれば、atomでやるべき操作ではない
    const ui = get(uiAtom)
    if (ui[0]?.type === 'KpiPivotSheet' || ui[0]?.type === 'KpiSheet') {
      return {
        rowLineNum: 2,
        rowHeight: 2 * ROW_HEIGHT,
      }
    }

    return {
      rowLineNum: rowLineNumber,
      rowHeight: rowLineNumber * ROW_HEIGHT,
    }
  },
})
