import { EditOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons'
import { normalizeNumber, range, roundValue } from '@salescore/buff-common'
import {
  presetFunctions,
  type PresetMeasureFunctions,
  type ViewQueryField,
  type ViewUISheetColumnMeasureCell,
} from '@salescore/core'
import { numberWithDelimiterFilter, useModalAtom } from '@salescore/frontend-common'
import { Button, Popover, Space, Spin } from 'antd'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'

import { measureFormModalAtom } from '../../../recoil/navigation/atoms'
import { viewAggregationQueryResultsAtom } from '../../../recoil/records/atoms'
import { useAggregationRecordsValue } from '../../../recoil/records/hooks'
import { useMeValue, useViewValue } from '../../../recoil/view/hooks'
import { useFieldMutations } from '../../../recoil/view/mutations/fieldMutations'
import { useConfigSheetSelector } from '../../../recoil/view/selectors/configSheetSelector'
import { useContextValue } from '../../recoil/models/propModels'
import { useCursorMutation } from '../../recoil/mutations/useCursorMutation'
import { useColumnsRelatedSelector } from '../../recoil/selectors/columnRelatedSelector'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import { RSheetsStyle } from '../../util/RSheetsStyle'
import { CELL_WIDTH } from '../body/cell/RsheetsCell'
import { ROW_HEIGHT } from '../body/const'

export function RSheetsFixedFooterRow({ leftFixed }: { leftFixed: boolean }) {
  const me = useMeValue()
  if (!me.organization.isEarlyReleaseTarget) {
    return <></>
  }

  const { columns } = useColumnsRelatedSelector()
  const records = useAggregationRecordsValue()
  const { leftFixedColumns, notFixedColumns } = useColumnsRelatedSelector()
  const targetColumns = leftFixed ? leftFixedColumns : notFixedColumns
  const widths = targetColumns.map((c) => c.width ?? CELL_WIDTH)

  // sheetのfooterとしてaggregationsを表示する時、以下のようにレコードをマージできる(dimensionsが存在しないため)
  const aggregatedValues = records.reduce((accumulator, x) => ({ ...accumulator, ...x.attributes }), {})

  const maxMeasureNumber = columns.map((c) => c.measures?.length ?? 0).max()
  const context = useContextValue()

  if (targetColumns.length === 0 && leftFixed) {
    // flexを使っている関係で、!leftFixedなときは描画しないとレイアウトが崩れる
    return <></>
  }

  // TODO: 固定カラムへの対応が必要
  return (
    <div
      className={leftFixed ? `fixed-footer-row-left` : `fixed-footer-row-right`}
      style={{
        position: 'sticky',
        ...(leftFixed ? { left: 0 } : {}),
        zIndex: leftFixed ? RSheetsStyle.zIndex.fixedFooterRowWhenLeftFixed : RSheetsStyle.zIndex.fixedFooterRow,
        display: 'flex',
        backgroundColor: 'white',
        height: ROW_HEIGHT * [1, maxMeasureNumber].max()!,
        width: widths.sum(),
        // minWidth: '100%', // どうしよ？
        border: `1px #EDF1F2 solid`,
        // verticalAlign: 'middle'
      }}
      data-e2e={`rsheets-fixed-footer-row-${leftFixed ? 'left-fixed' : 'not-fixed'}`}
    >
      {targetColumns.map((column) => {
        const measures = column.measures ?? []
        return (
          <div
            className="border border-solid border-gray-100"
            style={{
              backgroundColor: '#fafafa',
              width: column.width,
              overflow: 'hidden',
              height: '100%',
            }}
          >
            {range(0, [1, maxMeasureNumber].max()! - 1).map((index) => {
              const measure = measures[index]
              if (measure !== undefined) {
                return <CalculatorCell field={column.field} record={aggregatedValues} measure={measure} />
              }
              if (context.isGoalView === true) {
                return <></>
              }

              return <AddMeasureButton column={column} />
            })}
          </div>
        )
      })}
    </div>
  )
}

function CalculatorCell({
  measure,
  field,
  record,
}: {
  measure: ViewUISheetColumnMeasureCell
  record: Record<string, unknown>
  field: ViewQueryField | undefined
}) {
  const viewAggregationQueryResults = useRecoilValue(viewAggregationQueryResultsAtom)
  const measureFormModal = useModalAtom(measureFormModalAtom)
  const view = useViewValue()
  const context = useContextValue()
  const { getConfigField } = useConfigSheetSelector()
  const cursorMutation = useCursorMutation()
  const configField = field === undefined ? undefined : getConfigField(field)
  const error = (
    <div className="w-full text-xs text-gray-100" style={{ height: ROW_HEIGHT }}>
      <Button
        danger
        size="small"
        className="size-full text-center"
        type="text"
        icon={<WarningOutlined />}
        onClick={() => {
          cursorMutation.clearCursor()
          if (configField !== undefined) {
            measureFormModal.showModal({
              sheetViewId: view.id,
              field: configField,
              measureName: measure.name,
            })
          }
        }}
      >
        {t(`エラー`)}
      </Button>
    </div>
  )

  if (viewAggregationQueryResults.isBlank()) {
    return (
      <div className="flex w-full items-center justify-center align-middle" style={{ height: ROW_HEIGHT }}>
        <Spin />
      </div>
    )
  }

  if (field === undefined || configField === undefined) {
    return <span>field undefined</span>
  }

  // TODO: loading
  if (record === undefined) {
    return <span>loading</span>
  }

  const value = record[measure.name]
  if (typeof value !== 'string' && typeof value !== 'number' && value !== null) {
    return error
  }

  const content = (
    <div
      className="cursor-pointer hover:bg-gray-100"
      onClick={() => {
        if (context.isGoalView === true) {
          return
        }
        cursorMutation.clearCursor()
        measureFormModal.showModal({
          sheetViewId: view.id,
          field: configField,
          measureName: measure.name,
        })
      }}
      style={{ height: ROW_HEIGHT }}
    >
      <div className="scale-75 text-xs text-gray-400" style={{ height: 20 }}>
        {measure.label}
      </div>
      <div className="px-1 text-right font-bold" style={{ marginTop: -4 }}>
        {numberWithDelimiterFilter(roundValue(normalizeNumber(value ?? undefined))) ?? '-'}
      </div>
    </div>
  )

  if (context.isGoalView === true) {
    return <>{content}</>
  }
  return (
    <>
      <Popover
        // デフォルトだとpadidngが入り、これがattributeだと制御不可能なので、antd.lessに以下クラスを書いた
        overlayClassName="popover-without-padding"
        title={t(`集計を追加`)}
        content={
          <div>
            <Button
              className="w-full text-left"
              icon={<PlusOutlined />}
              onClick={() => {
                cursorMutation.clearCursor()
                if (configField !== undefined) {
                  measureFormModal.showModal({
                    sheetViewId: view.id,
                    field: configField,
                  })
                }
              }}
              type="text"
            >
              {t(`新規追加`)}
            </Button>
            <br />
            <Button
              className="w-full text-left"
              icon={<EditOutlined />}
              onClick={() => {
                cursorMutation.clearCursor()
                if (configField !== undefined) {
                  measureFormModal.showModal({
                    sheetViewId: view.id,
                    field: configField,
                    measureName: measure.name,
                  })
                }
              }}
              type="text"
            >
              {t(`編集`)}
            </Button>
          </div>
        }
      >
        {content}
      </Popover>
    </>
  )
}

function AddMeasureButton({ column }: { column: RSheetColumn<RSheetRecordNode> }) {
  const view = useViewValue()
  const { addMeasure } = useFieldMutations()
  const cursorMutation = useCursorMutation()
  const measureFormModal = useModalAtom(measureFormModalAtom)
  const { getConfigField } = useConfigSheetSelector()
  const field = column.field

  // ありえないはず
  if (field === undefined) {
    return <></>
  }

  if (column.isSystem === true) {
    return <></>
  }

  const onAddClick = (functionName: PresetMeasureFunctions, label: string) => () => {
    const configField = getConfigField(field)
    if (configField === undefined) {
      return
    }
    addMeasure({
      field: configField,
      measure: {
        type: 'preset',
        name: [field.name, functionName].join('_'),
        label,
        function: functionName,
        fieldName: field.name,
      },
    })
  }

  return (
    <>
      <Popover
        // デフォルトだとpadidngが入り、これがattributeだと制御不可能なので、antd.lessに以下クラスを書いた
        overlayClassName="popover-without-padding"
        title={t(`集計を追加`)}
        content={
          <div>
            {presetFunctions().map(({ value, label, availableFieldType }) => {
              if (availableFieldType !== undefined && !availableFieldType.includes(field.meta.fieldType)) {
                return <></>
              }
              // countUniqueはルートノードでは表示しない（意味がないので）
              if (value === 'countUnique' && (column.node.path ?? []).length < 2) {
                return <></>
              }
              return (
                <>
                  <Button className="w-full text-left" type="text" onClick={onAddClick(value, label)}>
                    {label}
                  </Button>
                  <br />
                </>
              )
            })}
            <Button
              className="w-full text-left"
              onClick={() => {
                cursorMutation.clearCursor()
                if (column.field === undefined) {
                  return
                }
                const configField = getConfigField(column.field)
                if (configField === undefined) {
                  return
                }
                measureFormModal.showModal({
                  sheetViewId: view.id,
                  field: configField,
                })
              }}
              type="text"
            >
              {t(`詳細設定`)}
            </Button>
            <br />
          </div>
        }
      >
        <Space
          onClick={() => {
            cursorMutation.clearCursor()
            if (column.field === undefined) {
              return
            }
            const configField = getConfigField(column.field)
            if (configField === undefined) {
              return
            }
            measureFormModal.showModal({
              sheetViewId: view.id,
              field: configField,
            })
          }}
          className="m-0 w-full cursor-pointer text-xs text-gray-600 opacity-0 hover:bg-gray-100 hover:opacity-100"
          style={{ height: ROW_HEIGHT }}
        >
          <PlusOutlined />
          {t(`集計を追加`)}
        </Space>
      </Popover>
    </>
  )
}
