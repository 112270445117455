// いい実装ができず、やや無理やりになっている
export function ExpandableSider({
  visibility,
  sider,
  width,
  body,
}: {
  sider: JSX.Element
  body: JSX.Element
  visibility: boolean
  width: number
}) {
  return (
    <div className="expandable-sider-layout flex h-full">
      <div
        className="expandable-sider-sider"
        style={{
          width: visibility ? width : 0,
          height: '100%',
          overflow: 'hidden',
          transition: `all 0.2s`,
        }}
      >
        <div style={{ width }} className="h-full">
          {sider}
        </div>
      </div>
      <div
        className="expandable-sider-body"
        style={{
          width: visibility ? `calc(100% - ${width}px)` : '100%',
          transition: `all 0.2s`,
        }}
      >
        {body}
      </div>
    </div>
  )
}
