import type { ViewQueryField } from '@salescore/core'
import type { ModalAtomHook } from '@salescore/frontend-common'
import { Modal } from 'antd'
import { t } from 'i18next'

import { useFieldMutations } from '../../../../recoil/view/mutations/fieldMutations'
import { SearchSqlForm } from './SearchSqlForm'

export const SearchSqlFormModal = ({ modal }: { modal: ModalAtomHook<ViewQueryField> }) => {
  const { setQueryField } = useFieldMutations()
  const field = modal.content

  return (
    <Modal
      open={modal.isModalVisible}
      onCancel={() => {
        modal.hideModal()
      }}
      width={'90%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
      }}
      destroyOnClose
      footer={<></>}
    >
      {field !== undefined && (
        <SearchSqlForm
          title={t(`参照カラムの検索設定`)}
          onAfterFinish={(sql: string) => {
            setQueryField({
              field,
              override: {
                read: {
                  searchSql: sql,
                },
              },
            })
            modal.hideModal()
          }}
          sql={field.read.searchSql ?? ''}
        />
      )}
    </Modal>
  )
}
