import { selector, useRecoilValue } from 'recoil'

import { convertedSheetCustomModelsSelector, viewsContextAtom } from '../atoms'
import { querySelector } from './querySelector'

export const connectionsSelector = selector({
  key: `view/connectionsSelector`,
  get({ get }) {
    const { models } = get(viewsContextAtom)
    const convertedSheetCustomModels = get(convertedSheetCustomModelsSelector)
    const modelMapper = [...models, ...convertedSheetCustomModels]
      .groupBy((model) => model.name)
      .transformValues((values) => {
        const model = values.first()!
        const propertyMapper = model.properties.groupBy((x) => x.name).transformValues((values) => values.first()!).data
        return {
          model,
          propertyMapper,
        }
      }).data
    const { flattenNodes } = get(querySelector)
    const availableModels = flattenNodes // TODO
      .map((node) => {
        const model = models.find((x) => x.name === node.read.tableSql) // TODO: これで正しいか??
        if (model === undefined) {
          return
        }
        return {
          node,
          model,
        }
      })
      .compact()
    // const optionGroups = generatePropertyOptionGroups(availableModels) // TODO

    return {
      models,
      availableModels,
      getModel: (modelName: string | undefined) => {
        if (modelName === undefined) {
          return
        }
        return modelMapper[modelName]?.model
      },
      getModelProperty: (modelName: string | undefined, propertyName: string | undefined) => {
        if (modelName === undefined || propertyName === undefined) {
          return
        }
        const propertyMapper = modelMapper[modelName]?.propertyMapper
        if (propertyMapper === undefined) {
          return
        }
        return propertyMapper[propertyName]
      },
      getModelAndProperty: (modelName: string, propertyName: string) => {
        if (modelName === undefined) {
          return
        }
        const model = modelMapper[modelName]
        if (model === undefined) {
          return
        }
        const property = model.propertyMapper[propertyName]
        if (property === undefined) {
          return
        }
        return {
          model: model.model,
          property,
        }
      },
      streams: [],
      availableStreams: [],
      optionGroups: [],
    }
  },
})

export const useConnectionsSelector = () => useRecoilValue(connectionsSelector)
