import { Modal } from 'antd'
import { t } from 'i18next'

import { useKpiPivotNavigationModal } from '../../../recoil/navigation/hooks'
import { KpiBulkDuplicateForm } from './KpiBulkDuplicateForm'

export function KpiBulkDuplicateModal() {
  const { kpiBulkDuplicateModal } = useKpiPivotNavigationModal()
  return (
    <Modal
      open={kpiBulkDuplicateModal.isModalVisible}
      onCancel={kpiBulkDuplicateModal.hideModal}
      width={'70%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{ top: '3%' }}
      destroyOnClose
    >
      <div className="w-full overflow-x-scroll">
        <KpiBulkDuplicateForm
          onFinish={() => {
            kpiBulkDuplicateModal.hideModal()
          }}
        />
      </div>
    </Modal>
  )
}
