// FYI
// https://support.miitel.jp/hc/ja/articles/360030159052-JavaScript-%E3%82%A6%E3%82%A3%E3%82%B8%E3%82%A7%E3%83%83%E3%83%88
// https://api.miitel.jp/static/widget/v1.js

import { isNull } from '@salescore/buff-common'

const script = `'use strict'
var _miitelWidget = function () {},
  COMPACT_WIDTH = 200,
  WIDE_WIDTH = 400,
  INNER_HEIGHT = 480,
  OUTER_HEIGHT = 500
;(_miitelWidget.prototype = {
  openState: !1,
  lang: 'en',
  isWide: !1,
  message: {
    en: {
      not_supported: 'Your browser is not supported. Please use the latest version of Google Chrome.',
      company_code_not_set: 'Company ID has not set.',
      company_code_invalid: 'Company ID is invalid.',
      access_key_not_set: 'Access key has not set.',
      access_key_invalid: 'Access key is invalid.',
    },
    ja: {
      not_supported: 'お使いのブラウザは対応しておりません。最新版の Google Chrome をお使いください。',
      company_code_not_set: '企業 ID がセットされていません',
      company_code_invalid: '企業 ID が正しくありません。',
      access_key_not_set: 'アクセスキーがセットされていません。',
      access_key_invalid: 'アクセスキーが正しくありません。',
    },
  },
  url: {
    base: 'https://api.miitel.jp/',
    domain: 'miitel.jp',
    open_black: '/static/images/miitel-phone-black.svg',
    open_white: '/static/images/miitel-phone-white.svg',
    close_black: '/static/images/underscore-black.svg',
    close_white: '/static/images/underscore-white.svg',
  },
  logging: function (e) {
    this.conf.debug && void logger.debug(e)
  },
  setLang: function () {
    'ja' == document.documentElement.lang && (this.lang = 'ja')
  },
  showError: function (e) {
    this.logging(e), alert(e)
  },
  init: function () {
    this.setLang(),
      'conf' in window.miitelWidget &&
        (('debug' in window.miitelWidget.conf && window.miitelWidget.conf.debug) || (this.conf.debug = !1),
        this.logging(window.miitelWidget.conf)),
      'company_id' in window.miitelWidget.conf
        ? 'access_key' in window.miitelWidget.conf
          ? (this.appendButton(),
            this.appendFrame(),
            window.addEventListener('resize', this.onResize.bind(this)),
            this.checkBrowser())
          : this.showError(this.message[this.lang].access_key_not_set)
        : this.showError(this.message[this.lang].company_code_not_set)
  },
  checkBrowser: function () {
    var e, t
    this.isChrome()
      ? (this.logging('Browser is supported'), this.checkCompanyId())
      : (this.logging('Browser is not supported'),
        (e = document.getElementById('miitelPhoneIFrameOuter')),
        ((t = document.createElement('p')).id = 'miitelPhoneIframe'),
        (t.style.display = 'none'),
        (t.innerHTML = '<p>' + this.message[this.lang].not_supported + '</p>'),
        e.appendChild(t))
  },
  checkCompanyId: function () {
    this.logging('Checking company ID')
    var i = new XMLHttpRequest(),
      e =
        ((i.onload = function () {
          var e,
            t = JSON.parse(i.responseText)
          this.logging(t),
            t.records.is_valid_code
              ? this.checkAccessKey()
              : ((t = document.getElementById('miitelPhoneIFrameOuter')),
                ((e = document.createElement('p')).id = 'miitelPhoneIframe'),
                (e.style.display = 'none'),
                (e.innerHTML = '<p>' + this.message[this.lang].company_code_invalid + '</p>'),
                t.appendChild(e))
        }.bind(this)),
        window.miitelWidget.conf.company_id)
    i.open('GET', this.url.base + 'api/company_code/v1/?company_code=' + e), i.send()
  },
  checkAccessKey: function () {
    this.logging('Checking Access key')
    var i = new XMLHttpRequest(),
      e =
        ((i.onload = function () {
          var e,
            t = JSON.parse(i.responseText)
          this.logging(t),
            t.records.is_valid_key
              ? this.appendFrameInner()
              : ((t = document.getElementById('miitelPhoneIFrameOuter')),
                ((e = document.createElement('p')).id = 'miitelPhoneIframe'),
                (e.style.display = 'none'),
                (e.innerHTML = '<p>' + this.message[this.lang].access_key_invalid + '</p>'),
                t.appendChild(e))
        }.bind(this)),
        window.miitelWidget.conf.company_id),
      t = window.miitelWidget.conf.access_key
    i.open('POST', this.url.base + 'api/widget_access_key/v1/'),
      i.setRequestHeader('Content-type', 'application/x-www-form-urlencoded'),
      i.send('access_key=' + t + '&company_id=' + e)
  },
  adjustButtonPosition: function (e) {
    switch (((e.style.position = 'fixed'), (e.style.top = 'auto'), (e.style.bottom = '10px'), !0)) {
      case !('position' in window.miitelWidget.conf):
      case -1 < window.miitelWidget.conf.position.indexOf('right'):
        ;(e.style.right = '10px'), (e.style.left = 'auto')
        break
      case -1 < window.miitelWidget.conf.position.indexOf('left'):
        ;(e.style.right = 'auto'), (e.style.left = '10px')
    }
  },
  adjustFramePosition: function (e) {
    switch (((e.style.position = 'fixed'), (e.style.top = 'auto'), (e.style.bottom = '10px'), !0)) {
      case !('position' in window.miitelWidget.conf):
      case -1 < window.miitelWidget.conf.position.indexOf('right'):
        ;(e.style.right = '10px'), (e.style.left = 'auto')
        break
      case -1 < window.miitelWidget.conf.position.indexOf('left'):
        ;(e.style.right = 'auto'), (e.style.left = '10px')
    }
  },
  appendButton: function () {
    var e = document.createElement('div'),
      t =
        ((e.id = 'miitelPhoneIFrameButton'),
        (e.style.cssText = 'position:absolute;width:200px;height:30px;z-index:101;display:block;'),
        (e.style.padding = '5px 0px'),
        'color' in window.miitelWidget.conf && 'white' == window.miitelWidget.conf.color
          ? ((e.style.border = '1px solid #ddd'), (e.style.background = '#eee'), (e.style.color = '#333'))
          : ((e.style.border = '1px solid #333'), (e.style.background = '#333'), (e.style.color = '#f8f8f8')),
        this.adjustButtonPosition(e),
        this.url.base + this.url.open_black),
      i =
        ('color' in window.miitelWidget.conf &&
          'white' == window.miitelWidget.conf.color &&
          (t = this.url.base + this.url.open_white),
        'openButtonImage' in window.miitelWidget.conf &&
          (t = this.escapeHtml(window.miitelWidget.conf.openButtonImage)),
        (e.style.textAlign = 'center'),
        (e.style.borderRadius = '10px'),
        document.createElement('p')),
      n =
        ((i.style.cursor = 'pointer'),
        (i.style.margin = '2px'),
        (i.onclick = function () {
          window.miitelWidget.openFrame()
        }),
        document.createElement('img'))
    ;(n.src = t), (n.style.width = '140px'), i.appendChild(n), e.appendChild(i), document.body.appendChild(e)
  },
  appendFrame: function () {
    var e = document.createElement('div'),
      t =
        ((e.id = 'miitelPhoneIFrameOuter'),
        (e.style.cssText = 'position:absolute;width:200px;z-index:100;display:block;'),
        (e.style.padding = '5px 0px'),
        'color' in window.miitelWidget.conf && 'white' == window.miitelWidget.conf.color
          ? (e.style.border = '1px solid #ddd')
          : (e.style.border = '1px solid #999'),
        (e.style.height = '30px'),
        (e.style.transition = 'height .5s, top .5s'),
        (e.style.background = '#f8f8f8'),
        (e.style.color = '#333'),
        (e.style.borderRadius = '10px'),
        (e.style.overflow = 'hidden'),
        this.adjustButtonPosition(e),
        document.createElement('div')),
      i = this.url.base + this.url.close_white,
      n = ((t.style.textAlign = 'right'), (t.style.height = '25px'), document.createElement('span')),
      o =
        ((n.style.cursor = 'pointer'),
        (n.style.padding = '0px 15px'),
        (n.onclick = function () {
          window.miitelWidget.closeFrame()
        }),
        document.createElement('img'))
    ;(o.src = i),
      (o.style.width = '20px'),
      (o.style.height = '20px'),
      n.appendChild(o),
      t.appendChild(n),
      e.appendChild(t),
      document.body.appendChild(e)
  },
  appendFrameInner: function () {
    var e = document.getElementById('miitelPhoneIFrameOuter'),
      t = document.createElement('iframe'),
      i =
        ((t.id = 'miitelPhoneIframe'),
        (t.width = '200px'),
        (t.style.border = 'none'),
        (t.style.display = 'none'),
        window.miitelWidget.conf.company_id),
      i =
        ((t.height = INNER_HEIGHT + 'px'),
        (t.src = 'https://' + i + '.' + this.url.domain + '/widget'),
        new URLSearchParams())
    i.set('logo', window.miitelWidget.conf.logoImage || ''),
      i.set('wide', void 0 !== window.miitelWidget.conf.wide ? String(window.miitelWidget.conf.wide) : 'true'),
      (t.src = t.src + '?' + i.toString()),
      (t.allow = 'microphone; autoplay'),
      e.appendChild(t),
      this.addReceiveMessageEvent()
  },
  openFrame: function () {
    ;(this.openState = !0), this.logging('Open frame')
    var t,
      e = window.miitelWidget.conf.company_id,
      i = document.getElementById('miitelPhoneIframe'),
      n = document.getElementById('miitelPhoneIFrameOuter'),
      o = this.isWide ? COMPACT_WIDTH + WIDE_WIDTH : COMPACT_WIDTH
    ;(n.style.width = o + 'px'),
      (i.style.width = o + 'px'),
      (n.style.height = OUTER_HEIGHT + 'px'),
      (i.style.height = INNER_HEIGHT + 'px'),
      (t = 'https://' + e + '.' + this.url.domain + '/widget'),
      (document.getElementById('miitelPhoneIFrameButton').style.display = 'none'),
      this.adjustFramePosition(n),
      setTimeout(function () {
        var e = document.getElementById('miitelPhoneIframe')
        e && (e.contentWindow.postMessage({ eventName: 'changeWidth' }, t), (e.style.display = 'block'))
      }, 500)
  },
  closeFrame: function () {
    ;(this.openState = !1), this.logging('Close frame')
    var e = document.getElementById('miitelPhoneIFrameOuter')
    this.adjustButtonPosition(e),
      (e.style.width = COMPACT_WIDTH + 'px'),
      (e.style.height = '30px'),
      (document.getElementById('miitelPhoneIframe').style.display = 'none'),
      setTimeout(function () {
        document.getElementById('miitelPhoneIFrameButton').style.display = 'block'
      }, 500)
  },
  call: function (e) {
    this.openFrame(), this.logging('call'), this.logging(e)
    var e = { eventName: 'startDial', phoneNumber: e },
      t = 'https://' + window.miitelWidget.conf.company_id + '.' + this.url.domain + '/widget'
    return document.getElementById('miitelPhoneIframe').contentWindow.postMessage(e, t), !1
  },
  addReceiveMessageEvent: function () {
    window.addEventListener('message', this.onReceiveMessage.bind(this))
  },
  onReceiveMessage: function (e) {
    var t, i, n
    this.logging(e),
      'eventName' in e.data &&
        ('receiveCall' == e.data.eventName
          ? (this.openFrame(),
            this.logging('onReceiveCall'),
            'onReceiveCall' in this.conf && this.conf.onReceiveCall(e.data))
          : 'changeReadyState' == e.data.eventName
          ? (this.logging('onChangereadyState'),
            this.logging(e.data),
            'onChangeReadyState' in this.conf && this.conf.onChangeReadyState(e.data))
          : 'receiveSequenceId' == e.data.eventName
          ? (this.logging('onReceiveSequenceId'),
            this.logging(e.data),
            'onReceiveSequenceId' in this.conf && this.conf.onReceiveSequenceId(e.data))
          : 'dialBegin' == e.data.eventName
          ? (this.logging('onDialBegin'),
            this.logging(e.data),
            'onDialBegin' in this.conf && this.conf.onDialBegin(e.data))
          : 'callBegin' == e.data.eventName
          ? (this.logging('onCallBegin'),
            this.logging(e.data),
            'onCallBegin' in this.conf && this.conf.onCallBegin(e.data))
          : 'callEnd' == e.data.eventName
          ? (this.logging('onCallEnd'), this.logging(e.data), 'onCallEnd' in this.conf && this.conf.onCallEnd(e.data))
          : 'changeWidth' == e.data.eventName
          ? (this.logging('changeWidth'),
            this.logging(e.data),
            (this.isWide = e.data.isWide),
            (t = document.getElementById('miitelPhoneIframe')),
            (i = document.getElementById('miitelPhoneIFrameOuter')) &&
              t &&
              this.openState &&
              ((n = e.data.isWide ? COMPACT_WIDTH + WIDE_WIDTH : COMPACT_WIDTH),
              (i.style.width = n + 'px'),
              (t.style.width = n + 'px')))
          : 'loaded' == e.data.eventName &&
            (this.logging('loaded'),
            this.logging(e.data),
            (window.miitelWidget.conf.version = String(e.data.version)),
            this.onResize()))
  },
  onResize: function () {
    var e = document.getElementById('miitelPhoneIFrameButton'),
      t = document.getElementById('miitelPhoneIFrameOuter')
    this.adjustButtonPosition(e), this.openState ? this.adjustFramePosition(t) : this.adjustButtonPosition(t)
  },
  isChrome: function () {
    var e = window.chrome,
      t = window.navigator,
      i = t.vendor,
      n = void 0 !== window.opr,
      o = -1 < t.userAgent.indexOf('Edge')
    return !t.userAgent.match('CriOS') && null != e && 'Google Inc.' === i && !1 == n && !1 == o
  },
  escapeHtml: function (e) {
    return e
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;')
  },
}),
  (window.miitelWidget = window.miitelWidget || {}),
  (function () {
    var e,
      t = new _miitelWidget()
    for (e in t) window.miitelWidget[e] = t[e]
  })(),
  window.miitelWidget.init()
`

// TODO: 複数組織をまたいで使うケースは考慮していない
let initialized = false

export function openMiitel({
  companyId,
  accessKey,
}: {
  companyId: string | null | undefined
  accessKey: string | null | undefined
}) {
  if (isNull(companyId) || isNull(accessKey)) {
    return
  }

  if (initialized) {
    return
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.miitelWidget = {
    conf: {
      company_id: companyId,
      access_key: accessKey,
    },
  }

  // eslint-disable-next-line no-eval
  eval(script)
  initialized = true
}

interface MiitelWidget {
  call: (phoneNumber: string) => void
}

export function getMiitelWidget(): MiitelWidget | undefined {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return window.miitelWidget as unknown as MiitelWidget | undefined
}
