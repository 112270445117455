//
// Excelの数値データを扱うときによくある数値の細かい表記揺れを吸収して数値にする。具体的には以下を行う
// ・カンマの除去
// ・￥マークの除去
//
export function normalizeNumber(value: unknown): number | null {
  if (typeof value === 'number') {
    return value
  }
  if (typeof value === 'string') {
    const normalized = Number(value.replaceAll(',', '').replace('¥', ''))
    return Number.isNaN(normalized) ? null : normalized
  }

  return null
}

export function validateNumber(value: unknown): boolean {
  const normalized = normalizeNumber(value)
  return normalized !== null
}
