//
// SALESCOREにおける権限の定義について
//

import { z } from 'zod'

import { policyActionSchema } from './action'

//
// 1.
// SALESCOREでは「ポリシー」を定めることで、権限を定義できる
// ポリシーでは「誰が(Principal)」「何に(Subject)」「何を(Action)」「できる/できない(Permission)」を定義することができる
// const policySchema = z.object({
//   principal: principalSchema,
//   subject: subjectSchema.optional(),
//   action: actionSchema,
//   permission: z.boolean(),
// })

// 「誰が(Principal)」では、以下を指定できる。
// ・ユーザー
// ・ユーザーの属性(現在は「作成者」のみ)
// ・ユーザーのロール
// ・全員(組織のデフォルト)
// memo: ロールは今後カスタマイズ可能にする予定だが、一旦はカスタマイズ不可能
// 今後は「ユーザーのグループ」などの追加がありそう。
const userPrincipal = z.object({
  type: z.literal('user'),
  id: z.string(),
})
const userAttributePrincipal = z.object({
  type: z.literal('userAttribute'),
  attributeType: z.literal('createdBy'),
})
const userRoleSchema = z.enum([
  'admin',
  'manager',
  'playingManager',
  'member',
  'necMember',
  'necPlayingManager',
  'readonlyMember',
  'nxMember',
])
const userCustomRoleSchema = z.string().regex(/^custom_/, "Custome role must start with 'custom_'")
const userRoleUnionSchema = z.union([userRoleSchema, userCustomRoleSchema])
export type PolicyUserRole = z.infer<typeof userRoleUnionSchema>
const userRolePrincipal = z.object({
  type: z.literal('userRole'),
  role: userRoleUnionSchema,
})
const everyonePrincipal = z.object({
  type: z.literal('everyone'),
})
export const policyPrincipalSchema = z.union([
  userPrincipal,
  userAttributePrincipal,
  userRolePrincipal,
  everyonePrincipal,
])
export type PolicyPrincipal = z.infer<typeof policyPrincipalSchema>

// 「何に(Subject)」は、現状はエンティティ(=PostgreSQLのレコード)である。
// また、これは指定しないこともできる。
export const policySubjectSchema = z.object({
  entityType: z.enum([`view`, `viewGroup`]),
  id: z.string(),
  createdById: z.string().nullish(),
})
export type PolicySubject = z.infer<typeof policySubjectSchema>

// 「何を(Action)」は、以下のような粒度で指定していく。
// 「目標設定ページを閲覧する(read-goals)」「目標設定ページで編集する(edit-goals)」のような単位であることに注意
// （他のシステムでは、actionがreadで、subjectがgoalsのような単位で指定することもあるが、ここではそうしていない）
// export const actionSchema = z.enum([`read-goals`, `edit-goals`, ...])
// ※長くなるので別ファイルにした

// これらを組み合わせてポリシーを定義できる
export const policySchema = z.object({
  principal: policyPrincipalSchema,
  subject: policySubjectSchema.omit({ createdById: true }).nullish(),
  action: policyActionSchema,
  permission: z.boolean(),
})
export type Policy = z.infer<typeof policySchema>

//
// 2.
// 特定のアクションを実行する際に、そのアクションに対する権限があるかどうかを判定するロジックは以下のようになる
// ・定義されたポリシーのうち、Principal, Action が一致するものを抽出
// ※ユーザーがActionをするとき、複数のPrincipalが一致しうる(ユーザー、属性、ロールでそれぞれ一致しうる)
// ・一致するポリシーが複数ある場合、以下の優先順位でポリシーを選択し、そのPermissionを適用する
// 1. Subjectが一致するか
// 2. PolicyのPrincipalを以下順序で評価する
//  1. ユーザー
//  2. ユーザーの属性
//  3. ユーザーのロール
//  4. デフォルト
// ・何も一致しなかった場合は、falseを返す

//
// ※以下、2023/04時点での現状に即した説明
//
// 現状は、結局以下のユースケースしかない想定
// 「ロールごとに機能の権限設定(例: 目標ページが見れるか)」
// 「ビュー・ビューグループのレコードごと、ロールor作成者属性ごとの権限設定（例: このシートは、マネージャーと作成者が閲覧・編集可能）」
//
// また、ロールはプリセットのロールしか存在せず、カスタマイズ不可能。よって実装にハードコーディングしている。
// 初期状態だと組織に存在するポリシーは、ロールのためのデフォルトポリシーのみが存在する。
// 「このシートは、マネージャーと作成者が編集可能」のような権限設定を行う場合は、以下のようなポリシーが追加される
// ・Principalがマネージャー、Subjectがシート、Actionが「edit-view」、Permissionがtrue
// ・Principalが作成者、Subjectがシート、Actionが「edit-view」、Permissionがtrue
// ・Principalがデフォルト、Subjectがシート、Actionが「edit-view」、Permissionがfalse
//
