import type { RSheetColumn, RSheetRecordNode } from '../types'
import type { RSheetsCursorPosition } from '../types/CursorTypes'
import { getRecordNodeWithInnerRowIndex } from './innerRowRelated'

export function getCellRelated(
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  data: RSheetRecordNode[],
  mainCursor: RSheetsCursorPosition,
) {
  const column = columns[mainCursor.columnIndex]
  const row = data[mainCursor.rowIndex]
  if (column === undefined || row === undefined) {
    return {
      column,
      row,
      recordNode: undefined,
      value: undefined,
    }
  }

  const recordNode = getRecordNodeWithInnerRowIndex(row, column.node, mainCursor.innerRowIndex)?.recordNode
  const value = recordNode === undefined ? undefined : column.value(recordNode)

  return {
    column,
    row,
    recordNode,
    value,
  }
}
