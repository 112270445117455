import type { ExpressionNodeWithDeprecated } from './syntax/expression_ast'

export type CoreDslParameter = Record<string, unknown>

export function flatAstNode(node: ExpressionNodeWithDeprecated): ExpressionNodeWithDeprecated[] {
  const type = node.type
  switch (type) {
    case 'literal': {
      return [node]
    }
    case 'variable': {
      return [node]
    }
    case 'func': {
      return [node, ...node.args.flatMap((x) => flatAstNode(x))]
    }
    case 'array': {
      return [node]
    }
    case 'operator': {
      return [node, ...flatAstNode(node.left), ...flatAstNode(node.right)]
    }
    // deprecated
    case 'function': {
      return [node, ...node.arguments.flatMap((x) => flatAstNode(x))]
    }
    case 'value': {
      return [node]
    }
    case 'recordValue': {
      return [node]
    }
    default: {
      throw new Error(type satisfies never)
    }
  }
}
