import { Checkbox, Form } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

export const KpiFormSheetFieldsFormAddDimeisions = () => {
  const { form, setForm, setFormModified } = useKpiFormSelectors()

  return (
    <div>
      <Form.Item className="mt-2" label={t(`ユーザー•グループの分析軸を自動表示`)}>
        <Checkbox
          checked={form.options?.dimensionFieldsVisibility === true}
          value={form.options?.dimensionFieldsVisibility === true}
          onChange={(value: CheckboxChangeEvent) => {
            setForm((oldValue) => ({
              ...oldValue,
              options: {
                dimensionFieldsVisibility: value.target.checked,
              },
            }))
            setFormModified(true)
          }}
        />
      </Form.Item>
    </div>
  )
}
