import type { ApolloClient } from '@apollo/client'
import type { ViewQueryList } from '@salescore/core'

import { fetchChildRecordNode } from '../../../recoil/records/mutations/fetchChildNode'
import type { RelationChange } from '../../../recoil/records/mutations/updateRecordNodeRelations'
import type { RecordChange } from '../../../recoil/records/mutations/upsertViewRecords'
import { getNode } from '../../../state/nodeUtil'

export async function searchRelation({
  recordChange,
  client,
  query,
}: {
  recordChange: RecordChange
  client: ApolloClient<unknown>
  query: ViewQueryList
}): Promise<RelationChange | undefined> {
  const { field, value, rowIndex, innerRowIndex } = recordChange
  // 参照項目でなければ早期return。参照項目かどうかをmetaTypeで判定
  // XXX: relationかつnameのとき、metaTypeはnameになっているためnameも許す。参照項目でないときは、後続でundefinedになるので問題ない
  if (field.meta.fieldMetaType !== 'relation' && field.meta.fieldMetaType !== 'name') {
    return undefined
  }
  const node = getNode(query.tree, field.nodePath)
  if (node === undefined) {
    return undefined
  }
  // 参照しているノードを取得（TODO: labelNodePathを使う形はやや微妙そう）
  const referencingNode = (node.children ?? []).find((child) => child.path.isEqual(field.read.labelNodePath ?? []))
  if (referencingNode === undefined) {
    return undefined
  }

  const fetchedChildRecordNode = await fetchChildRecordNode(referencingNode, value as string, query, client, field)
  if (fetchedChildRecordNode === undefined) {
    return undefined
  }

  return {
    node: referencingNode,
    rowIndex,
    innerRowIndex,
    recordNode: fetchedChildRecordNode,
  }
}
