import type { ViewConfigField, ViewQueryField } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { configSheetAtom, hasChangeToViewSchemaAtom } from '../../atoms'
import { setConfigMutation } from '../setConfigMutation'
import { isSameField } from './util'

export const setSearchableFieldsMutation = mutation<{
  searchableFields: ViewQueryField[]
}>({
  key: `view/setSearchableFieldsMutation`,
  set({ get, set }, { searchableFields }) {
    const config = get(configSheetAtom)
    if (config.fields === undefined) {
      return
    }

    const newFields = config.fields.map(
      (field): ViewConfigField => ({
        ...field,
        override: {
          ...field.override,
          queryField: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            meta: {
              // TODO: fieldTypeがstringかどうかの判定はcompielのロジックで期待
              searchable: searchableFields.some((x) => isSameField(field, x)),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } as any,
          },
        },
      }),
    )
    set(setConfigMutation, {
      ...config,
      fields: newFields,
    })
    set(hasChangeToViewSchemaAtom, true)
  },
})
