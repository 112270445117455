import { range } from '@salescore/buff-common'
import type { ModelProperty } from '@salescore/core'

type Row = Record<string, unknown>

export async function createTableForSheetCustomModel({
  name,
  query,
}: {
  name: string
  query: (sql: string) => Promise<Row[]>
}) {
  const columnsMapper = getSheetCustomModelColumns()
  const columns = [
    ...columnsMapper.string,
    ...columnsMapper.text,
    ...columnsMapper.numeric,
    ...columnsMapper.date,
    ...columnsMapper.datetime,
    ...columnsMapper.boolean,
    ...columnsMapper.options,
  ]
  const columnSql = columns.map((x) => `${x.name} ${x.sqlType}`).join(',\n')
  const sql = /* sql */ `
CREATE TABLE IF NOT EXISTS "${name}" (
  "id" varchar(255) NOT NULL COLLATE "ja-x-icu",
  "parent_id" varchar(255) NOT NULL COLLATE "ja-x-icu",
  ${columnSql},
  "created_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
  "updated_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
  PRIMARY KEY ("id")
)`
  const indices = generateIndices(name, columns)
  await query(sql)
  for (const index of indices) {
    await query(index)
  }
}

interface Column {
  name: string
  sqlType: string
  type: ModelProperty['type']
}

export function getSheetCustomModelColumns() {
  return {
    string: range(1, 10).map(
      (index): Column => ({ name: `string${index}`, type: 'string', sqlType: `varchar(255) COLLATE "ja-x-icu"` }),
    ),
    text: range(1, 10).map(
      (index): Column => ({ name: `text${index}`, type: 'string', sqlType: `text COLLATE "ja-x-icu"` }),
    ),
    numeric: range(1, 10).map((index): Column => ({ name: `numeric${index}`, type: 'numeric', sqlType: `numeric` })),
    date: range(1, 10).map((index): Column => ({ name: `date${index}`, type: 'date', sqlType: `date` })),
    datetime: range(1, 10).map(
      (index): Column => ({ name: `datetime${index}`, type: 'datetime', sqlType: `timestamptz` }),
    ),
    boolean: range(1, 10).map((index): Column => ({ name: `boolean${index}`, type: 'boolean', sqlType: `boolean` })),
    options: range(1, 10).map(
      (index): Column => ({ name: `options${index}`, type: 'string', sqlType: `varchar(255) COLLATE "ja-x-icu"` }),
    ),
  }
}

function generateIndices(name: string, columns: Column[]) {
  return [
    `CREATE UNIQUE INDEX IF NOT EXISTS uidx_parent_id ON "${name}" (parent_id)`,
    ...columns.map((x) => `CREATE INDEX IF NOT EXISTS idx_${x.name} ON "${name}" (${x.name})`), // 本当に全部生成して大丈夫なんだろうか…？
  ]
}

// 2023/08/09のmigration用。終わったら消す
export function generateIndicesForMigration202308(name: string) {
  const columnsMapper = getSheetCustomModelColumns()
  const columns = [
    ...columnsMapper.string,
    ...columnsMapper.text,
    ...columnsMapper.numeric,
    ...columnsMapper.date,
    ...columnsMapper.datetime,
    ...columnsMapper.boolean,
    ...columnsMapper.options,
  ]
  return [
    `CREATE UNIQUE INDEX IF NOT EXISTS uidx_parent_id ON "${name}" (parent_id)`,
    ...columns.map((x) => `CREATE INDEX IF NOT EXISTS idx_${x.name} ON "${name}" (${x.name})`), // 本当に全部生成して大丈夫なんだろうか…？
  ]
}
