import { Form, Row, Select } from 'antd'
import { t } from 'i18next'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import { PropertySelect } from './PropertySelect'

interface KpiFormUserFormItemProperties {
  disabled?: boolean
}

export const KpiFormUserFormItem = (properties: KpiFormUserFormItemProperties) => {
  const { setForm, form, sheet, currentNodeStringProperties, currentNodeUserReferenceProperties, setFormModified } =
    useKpiFormSelectors()

  return (
    <Row>
      <Form.Item label={t(`ユーザーの紐付け方法`)} style={{ width: `100%` }}>
        <Select
          allowClear
          value={form.user?.joinAs}
          onChange={(x) => {
            setForm((oldValue) => ({
              ...oldValue,
              user: {
                joinAs: x,
              },
            }))
            setFormModified(true)
          }}
          options={[
            {
              label: t(`IDで紐付け`),
              value: 'id',
            },
            {
              label: t(`メールアドレスで紐付け`),
              value: 'email',
            },
            {
              label: t(`名前で紐付け`),
              value: 'name',
            },
          ]}
          disabled={properties.disabled}
        />
      </Form.Item>
      {/* TODO */}
      <Form.Item label={t(`ユーザー項目`)} style={{ width: `100%` }}>
        <PropertySelect
          allowClear
          disabled={form.user?.joinAs === undefined}
          properties={form.user?.joinAs === 'id' ? currentNodeUserReferenceProperties : currentNodeStringProperties}
          value={form.user?.property}
          onChange={(value) => {
            if (value !== form.user?.property) {
              // PropertySelect は onChage をマウント時に呼び出すので、
              // 本当に値が変わった時だけ setFormModified を呼ぶようにする
              setFormModified(true)
            }
            if (value === undefined) {
              setForm((oldValue) => ({
                ...oldValue,
                user: {
                  ...oldValue.user,
                  property: undefined,
                },
              }))
              return
            }
            // 選択されたプロパティを詳細項目に自動追加
            const newSheet = sheet.addPropertyField(value.nodePropertyName, { toLast: true })
            setForm((oldValue) => ({
              ...oldValue,
              sheet: newSheet.config,
              user: {
                ...oldValue.user,
                property: value.nodePropertyName,
              },
            }))
          }}
        />
      </Form.Item>
    </Row>
  )
}
