import { type ApolloClient, useMutation } from '@apollo/client'
import { sleep } from '@salescore/buff-common'
import {
  type Connection,
  FetchJobStatusesDocument,
  type FetchJobStatusesQuery,
  type FetchJobStatusesQueryVariables,
  type SyncContextEnum,
  SyncHubConnectionDocument,
} from '@salescore/client-api'
import { Posthog } from '@salescore/client-base'
import { logger } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { getOrganizationIdFromPath } from '../presentation/common/auth'

export type ConnectionWithSourceProvider = Pick<Connection, 'id' | 'active'> & {
  source: Pick<Connection['source'], 'provider'>
}

export const useSyncConnection = ({
  connections,
  client,
  onSuccess,
}: {
  connections: ConnectionWithSourceProvider[]
  client: ApolloClient<unknown>
  onSuccess?: () => Promise<void> | void
}) => {
  const [syncHubConnectionMutation] = useMutation(SyncHubConnectionDocument)
  const [queueing, setQueuing] = useState(false)
  const [waiting, setWaiting] = useState(false)

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const success = () => {
    void message.success(t('更新しました'))
  }

  let sleepCount = 0
  const sleepByCount = async () => {
    if (sleepCount < 10) {
      await sleep(2)
    } else if (sleepCount < 20) {
      await sleep(5)
    } else if (sleepCount < 50) {
      await sleep(10)
    } else {
      await sleep(30)
    }
    sleepCount += 1
  }

  // deprecated. useFetchJobStatusによせたい
  const polling = async () => {
    setWaiting(true)

    while (true) {
      const variables: FetchJobStatusesQueryVariables = {
        organizationId: getOrganizationIdFromPath(),
        jobName: 'SyncSourceRecordsJob',
      }
      const query = FetchJobStatusesDocument
      const result = await client.query<FetchJobStatusesQuery>({
        variables,
        query,
        fetchPolicy: 'no-cache',
      })

      logger.debug({ jobStatuses: result.data.jobStatuses, sleepCount })

      if (result.data.jobStatuses.filter((x) => x.status === 'running' || x.status === 'waiting').length === 0) {
        // 以下エラー回避のためcacheクリア方法を実験的に変更。パフォーマンス悪化やエラー解消の確認などリリース後に要効果測定
        // https://www.apollographql.com/docs/react/errors#%7B%22version%22%3A%223.9.10%22%2C%22message%22%3A32%2C%22args%22%3A%5B%5D%7D
        // cf. https://github.com/apollographql/apollo-client/issues/2919
        await client.cache.reset() // キャッシュのクリアをここで実行している。影響が大きすぎる場合は要検討
        if (onSuccess !== undefined) {
          await onSuccess()
        }
        success()
        setWaiting(false)
        sleepCount = 0
        return
      }
      // if (result.data.jobStatuses.some((x) => x === JobStatusEnum.WillRetryOrDead)) {
      //   setWaiting(false)
      //   void message.error('更新に失敗しました。時間をおいて再度実行してください。')
      //   sleepCount = 0
      //   return
      // }
      await sleepByCount()
    }
  }

  const queueSync = async (syncContext: SyncContextEnum) => {
    try {
      setQueuing(true)
      // 正確にはまだ開始していないため、ここでメッセージを出したくないが
      void message.success(t('同期を開始しました'))
      // どこの処理でやるか悩ましいが、基本的にはsalescoreは同期の必要がないはず。明確に指定されたときのみ実行
      const cs = connections.length === 1 ? connections : connections.filter((x) => x.source.provider !== 'salescore')
      for (const connection of cs) {
        await syncHubConnectionMutation({
          variables: {
            id: connection.id,
            organizationId: getOrganizationIdFromPath(),
            syncContext,
          },
        })
      }
      await sleep(6) // ジョブのキューイングの後、少しだけ待機する。本来はここのsleepは不要なはずだが、直後だとなぜかキューが反映されていない
      void polling()
      Posthog.track('click-syncConnectionButton', {
        organizationId: getOrganizationIdFromPath(),
      })
    } catch (error: unknown) {
      if (error instanceof Error) {
        void message.error(error.message)
      }
    } finally {
      setQueuing(false)
    }
  }

  return {
    loading: queueing || waiting,
    queueSync,
  }
}
