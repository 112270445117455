import { Modal } from 'antd'
import { t } from 'i18next'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { SheetPresetForm } from './SheetPresetForm'

export function SheetPresetFormModal() {
  const { sheetPresetFormModal } = useNavigationModal()

  const isNew = sheetPresetFormModal.content?.preset === undefined
  const title = t(`プリセットの{{title}}`, { title: isNew ? t(`新規作成`) : t(`編集`) })

  return (
    <Modal
      open={sheetPresetFormModal.isModalVisible}
      onOk={sheetPresetFormModal.hideModal}
      onCancel={sheetPresetFormModal.hideModal}
      destroyOnClose
      title={title}
      width="90%"
      footer={null}
    >
      <SheetPresetForm />
    </Modal>
  )
}
