import { DashboardOutlined, UploadOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useMutation } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import {
  type GoalConfigFieldsFragment,
  InitializeGoalsDocument,
  type InputMaybe,
  type OrganizationSettingFieldsFragment,
  type Scalars,
  UpdateGoalConfigDocument,
  type UserGroupFieldsFragment,
  type ViewFieldsFragment,
} from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { getOrganizationIdFromPath, getPeriods, type Period, PeriodPicker } from '@salescore/client-common'
import { ViewComponent } from '@salescore/client-feature-view'
import { recoil } from '@salescore/client-recoil'
import { CORE_CONSTANT, type ViewConfig, type ViewConfigSheetExtraPeriod } from '@salescore/core'
import { generateDateStringsForInitialize, generatePivotedGoalModel } from '@salescore/features'
import { useRedirect } from '@salescore/frontend-common'
import { Button, Col, Row, Spin, Table } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import Link from 'next/link'
import { useEffect, useState } from 'react'

export const GoalsSheet = ({
  goalConfig,
  userGroups,
  organizationSetting,
  onBack,
}: {
  goalConfig: GoalConfigFieldsFragment
  userGroups: UserGroupFieldsFragment[]
  organizationSetting: OrganizationSettingFieldsFragment
  onBack?: () => void
}) => {
  const savedPeriod = getSavedPeriod({ goalConfig, setting: organizationSetting })
  const redirect = useRedirect()
  const [period, setPeriod] = useState<Period | undefined>(savedPeriod)
  const [initializedPeriod, setInitializedPeriod] = useState<Period | undefined>()
  const [hasChangeToPeriod, setHasChangeToPeriod] = useState<boolean | undefined>()
  const [initializeGoalsMutation] = useMutation(InitializeGoalsDocument)
  const { pickView } = recoil.sider.usePickedViewIds()

  const initialize = async (period: Period | undefined) => {
    if (isNull(period) || isNull(period.startAt) || isNull(period.endAt)) {
      return
    }
    setInitializedPeriod(undefined)
    await initializeGoalsMutation({
      variables: {
        goalConfigId: goalConfig.id,
        organizationId: getOrganizationIdFromPath(),
        dates: generateDateStringsForInitialize(period.startAt, period.endAt, goalConfig.timeSpanType),
      },
    })
    setInitializedPeriod(period)
  }

  useEffect(() => {
    void initialize(period)

    // 初回レンダリング時にはhasChangeToPeriodをtrueにしない
    if (hasChangeToPeriod === undefined) {
      setHasChangeToPeriod(false)
      return
    }
    setHasChangeToPeriod(true)
  }, [period])

  return (
    <PageHeader
      onBack={onBack}
      title={
        <Row>
          <Col>
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', paddingRight: '10px' }}>
              {t(`目標設定`)} - {goalConfig.dashboardView?.name} - {goalConfig.name}
            </div>
          </Col>
          <Col>
            <PeriodPicker
              pickerType={'goal'}
              setPeriod={setPeriod}
              selectedPeriod={period}
              posthogEventName={`click-periodSelector-goal`}
              withInitialization={true}
            />
          </Col>
        </Row>
      }
      extra={[
        <Button
          icon={<DashboardOutlined />}
          onClick={() => {
            // TODO: launcherと同じように、recoilを直接いじってviewIdを反映させるべきか迷い中
            redirect(routes.topPathV2(`viewId=${goalConfig.dashboardViewId ?? ''}`))
            pickView(goalConfig.dashboardViewId ?? '')
          }}
        >
          {t(`ダッシュボードへ`)}
        </Button>,
        <Link href={routes.editGoalConfigPathV2(goalConfig.id)} legacyBehavior>
          <Button icon={<UploadOutlined />}>{t(`設定`)}</Button>
        </Link>,
        <Link href={routes.goalConfigCsvPathV2(goalConfig.id)} legacyBehavior>
          <Button icon={<UploadOutlined />}>{t(`CSVアップロード`)}</Button>
        </Link>,
      ]}
    >
      {initializedPeriod === undefined ? (
        <Table
          loading={{
            indicator: (
              <div style={{ marginLeft: -200 }}>
                <Spin
                  tip={t(`目標に紐づくユーザー数が多いと読み込みに時間がかかる場合があります`)}
                  style={{ whiteSpace: 'nowrap', paddingRight: 400 }}
                >
                  <></>
                </Spin>
              </div>
            ),
          }}
        />
      ) : (
        <Sheet
          goalConfig={goalConfig}
          userGroups={userGroups}
          organizationSetting={organizationSetting}
          period={initializedPeriod}
          hasChangeToPeriod={hasChangeToPeriod ?? false}
          onUpdateView={() => {
            setHasChangeToPeriod(false)
          }}
        />
      )}
    </PageHeader>
  )
}

function Sheet({
  goalConfig,
  period,
  userGroups,
  organizationSetting,
  hasChangeToPeriod,
  onUpdateView,
}: {
  goalConfig: GoalConfigFieldsFragment
  period: Period
  userGroups: UserGroupFieldsFragment[]
  organizationSetting: OrganizationSettingFieldsFragment
  hasChangeToPeriod: boolean
  onUpdateView: () => void
}) {
  const users = userGroups.flatMap((x) => x.users)
  const { startAt, endAt } = period
  const { pivotedGoalModel, goalViewConfig } = generatePivotedGoalModel({
    goalConfig: {
      ...goalConfig,
      userIds: goalConfig.userIdsConfig,
      userGroupIds: goalConfig.userGroupIdsConfig,
      config: goalConfig.config?.type === 'sheet' ? goalConfig.config : undefined,
    },
    userGroups,
    users,
    userGroupCategoryNames: organizationSetting.userGroupCategoryNames,
    // 必ずstartAt,endAtが存在するはず
    startAt: startAt!,
    endAt: endAt!,
  })
  const [updateGoalConfigMutation] = useMutation(UpdateGoalConfigDocument)
  const { clearTargetViewQueryCaches } = recoil.sider.useClearViewQueryCacheMutation()

  const goalView: ViewFieldsFragment = {
    id: ``,
    type: `sheet`,
    config: goalViewConfig,
    queries: [],
    ui: [],
    viewGroupId: ``,
    name: ``,
    rank: 1,
    defaultPermission: `none`,
    roleRecordPermissions: [],
    private: false,
    archived: false,
    archivedAt: null,
  }

  return (
    <div style={{ height: '80vh' }}>
      <ViewComponent
        view={goalView}
        parameter={{}}
        viewsContext={{
          models: [pivotedGoalModel, CORE_CONSTANT.SALESCORE_USER_MODEL],
          views: [goalView],
          viewGroups: [],
          viewsForSider: [],
          createView: async (): Promise<undefined> => {
            await Promise.resolve()
          },
          updateView: async (x): Promise<undefined> => {
            await updateGoalConfigMutation({
              variables: {
                organizationId: getOrganizationIdFromPath(),
                goalConfig: {
                  id: goalConfig.id,
                  name: goalConfig.name,
                  userGroupIdsConfig: goalConfig.userGroupIdsConfig,
                  userIdsConfig: goalConfig.userIdsConfig,
                  rank: goalConfig.rank,
                  config: generateUpdatedConfig({ config: x.config, period }),
                },
              },
            })
            onUpdateView()
          },
          deleteView: async () => {
            await Promise.resolve()
          },
          pickView: async () => {
            await Promise.resolve()
          },
          refetchViews: async () => {
            await Promise.resolve()
          },
          onSaveView: async () => {
            await Promise.resolve()
          },
          onAddResource: async () => {
            // empty
          },
          onChangesChange: () => {
            // 目標入力に変更があった場合には紐づくダッシュボードのキャッシュをクリアする
            // TODO:保存時に実行するのが望ましいがcontext経由で注入できないためやや手抜き
            clearTargetViewQueryCaches({ viewIds: goalConfig.dashboardViewId ? [goalConfig.dashboardViewId] : [] })
          },
          hasChanges: false,
          setIsSaveConfigButtonActive: () => {
            // empty
          },
          isSaveConfigButtonActive: hasChangeToPeriod,
          isSheetMoreActionsCopyVisible: false,
        }}
      />
    </div>
  )
}

function getSavedPeriod({
  goalConfig,
  setting,
}: {
  goalConfig: GoalConfigFieldsFragment
  setting: OrganizationSettingFieldsFragment
}): Period | undefined {
  const { config } = goalConfig
  if (config?.type !== 'sheet' || isNull(config.extra?.period)) {
    return
  }

  const savedPeriod = config.extra.period

  // TODO: できればラベル比較はやめたい。。。
  // カスタム期間の場合は保存された期間を返す
  if (savedPeriod.name === 'カスタム' || savedPeriod.name === 'カスタム(月)') {
    return deserializePeriod(savedPeriod)
  }

  // カスタム期間以外の場合は表示時の日付で再計算された期間を返す
  const periods = getPeriods('goal', setting)
  return periods.find((x) => x.name === savedPeriod.name)
}

function generateUpdatedConfig({
  config,
  period,
}: {
  config?: InputMaybe<Scalars['ViewConfig']['input']>
  period: Period
}): ViewConfig | undefined {
  if (config?.type !== 'sheet') {
    return
  }

  if (period.name === 'カスタム' || period.name === 'カスタム(月)') {
    return {
      ...config,
      extra: {
        ...config.extra,
        period: serializePeriod(period),
      },
    }
  }

  return {
    ...config,
    extra: {
      ...config.extra,
      period: {
        ...period,
        // カスタム期間以外の場合はstartAt,endAtは直接保存しない
        startAt: undefined,
        endAt: undefined,
      },
    },
  }
}

// periodのstartAt,endAtはフロントアプリケーションで使用する時にはDayjs型だが、DBに保存する際にはstring型に変換するので、
// その変換を行うserialize/deserialize関数を用意する
function serializePeriod(period?: Period): ViewConfigSheetExtraPeriod | undefined {
  const formatString = 'YYYY-MM-DD'
  if (isNull(period)) {
    return
  }
  return {
    ...period,
    startAt: period.startAt?.format(formatString),
    endAt: period.endAt?.format(formatString),
  }
}

function deserializePeriod(period?: ViewConfigSheetExtraPeriod): Period | undefined {
  const startAt = period?.startAt
  const endAt = period?.endAt
  if (isNull(period) || isNull(startAt) || isNull(endAt)) {
    return
  }
  return {
    ...period,
    startAt: dayjs(startAt),
    endAt: dayjs(endAt),
  }
}
