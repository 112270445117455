import { logger, mutation } from '@salescore/frontend-common'

import { changesAtom, pickedIdsAtom, recordAtomFamily, recordIdsAtom } from '../../../recoil/records/atoms'
import { updateHeight, updateInnerRow } from '../../../recoil/records/mutations/upsertViewRecords'
import { getChangesByPseudoRootNode } from './paste/getChangesByPseudoRootNode'
import { getRowsAndColumns } from './paste/getRowsAndColumns'
import { PseudoRowIterator } from './paste/PseudoRowIterator'

// 新しい実装のペースト
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const pasteMutation = mutation<void>({
  key: `rsheet/pasteMutation`,
  set({ get, set }) {
    const x = getRowsAndColumns(get, set)
    if (x === undefined) {
      logger.debug(`failed to getRowsAndColumns()`)
      return
    }
    const { source, dest, recordRelated } = x

    // 擬似ルートごとに処理を行う
    const results = source.pseudoRootNodes.map((sourcePseudoRootNode, pseudoRootNodeIndex) => {
      // TODO: sourceとdestで対応する擬似ルートを適切に選択
      const destinationPseudoRootNode = dest.pseudoRootNodes[pseudoRootNodeIndex]!
      const iterator = new PseudoRowIterator(destinationPseudoRootNode, dest.editingRows, {
        innerRowIndex: dest.area.upInnerRowIndex,
        rowIndex: dest.area.upRowIndex,
      })
      const changes = getChangesByPseudoRootNode(
        sourcePseudoRootNode,
        destinationPseudoRootNode,
        source,
        dest,
        iterator,
        set,
      )
      return {
        iterator,
        changes,
      }
    })

    const newRecords = structuredClone(recordRelated.records)

    // 行関連の処理をまとめて行う
    // TODO: この辺のロジック、upsertViewRecord関連に近い。共通化したい。
    // heightを参照渡しで更新
    const maxRowIndex = results.map((x) => x.iterator.lastEditedRowIndex).max() ?? 0
    for (const [index, destinationRow] of dest.editingRows.entries()) {
      if (index > maxRowIndex) {
        continue
      }
      updateHeight(destinationRow)
      // innerStartIndexを参照渡しで更新
      updateInnerRow(destinationRow)

      // 全ての変更をまとめて適用
      set(recordAtomFamily({ id: destinationRow.id! }), destinationRow)

      // newRecordsにも反映させる
      const changedRecord = newRecords.find((x) => x.id === destinationRow.id)
      if (changedRecord !== undefined) {
        Object.assign(changedRecord, destinationRow)
      }
    }

    // recoilに反映させる
    if (recordRelated.newRecordIds.length !== recordRelated.recordIds.length) {
      set(recordIdsAtom, recordRelated.newRecordIds)
    }
    const changes = results.flatMap((x) => x.changes)
    const rowCheckboxChanges = changes.map((x) => (x.type === 'rowCheckbox' ? x : undefined)).compact()
    set(pickedIdsAtom, (oldPickedIds) => {
      const picks = rowCheckboxChanges.filter((x) => x.value).map((x) => x.recordId)
      const unpicks = rowCheckboxChanges.filter((x) => !x.value).map((x) => x.recordId)
      return [...oldPickedIds.difference(unpicks), ...picks].unique()
    })
    const recordChanges = changes.map((x) => (x.type === 'rowCheckbox' ? undefined : x)).compact()
    if (recordChanges.isPresent()) {
      const newChange = {
        datetime: new Date().toISOString(),
        rowIndices: [0], // TODO: 結局使ってないので廃止
        snapshot: JSON.stringify(recordRelated.records), // snapshotは以前のものをいれる
        changes: recordChanges,
        snapshotAfterChange: JSON.stringify(newRecords),
      }
      set(changesAtom, (oldChanges) => [...oldChanges, newChange])
    }
  },
})
