import { buildInitializePropHook, buildReadonlyAtom, buildReadonlyHook } from '@salescore/frontend-common'

import { recordsAtom } from '../../../recoil/records/atoms'
import { columnsAtom, contextAtom, rootRefAtom } from '../atoms'

export const rowsModel = buildReadonlyAtom('rowsModel', recordsAtom)
export const useRowsValue = buildReadonlyHook(recordsAtom)

export const columnsModel = buildReadonlyAtom('columnsModel', columnsAtom)
export const useInitializeColumns = buildInitializePropHook(columnsAtom)
export const useInitializeContext = buildInitializePropHook(contextAtom)
export const useColumnsValue = buildReadonlyHook(columnsAtom)
export const useContextValue = buildReadonlyHook(contextAtom)

export const rootRefModel = buildReadonlyAtom('rootRefModel', rootRefAtom)
export const useInitializeRootRef = buildInitializePropHook(rootRefAtom)
export const useRootRefValue = buildReadonlyHook(rootRefAtom)
