import { isSome, range } from '@salescore/buff-common'
import { CORE_CONSTANT } from '@salescore/core'
import { useMessage } from '@salescore/frontend-common'
import { Button } from 'antd'
import { t } from 'i18next'
import { useRecoilState, useRecoilValue } from 'recoil'

import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { useRecordsAndChangesMutation } from '../../../recoil/records/mutations/recordsAndChangesMutation'
import { useViewsContextValue, useViewValue } from '../../../recoil/view/hooks'
import { useCachedViewsSelector } from '../../../recoil/view/selectors/cachedViewSelector'
import { getRecordNodeFromRootNode } from '../../../state/nodeUtil'
import type { ViewRecordFieldChange } from '../../../state/useViewRecordsState/useChangesState'
import type { FinishEditMode, RSheetColumn, RSheetRecordNode } from '../../types'
import { cursorModel } from '../models/cursorModel'
import { rowsModel, useRootRefValue as useRootReferenceValue } from '../models/propModels'
import { useUpsertSheetRowMutation, useUpsertSheetRowsMutation } from './upsertSheetRowMutation'
import { useCursorMutation } from './useCursorMutation'

export const useRSheetMutation = () => {
  const recordsAndChangesMutation = useRecordsAndChangesMutation()
  const reference = useRootReferenceValue()
  const cursorMutation = useCursorMutation()
  const [cursor, setCursor] = useRecoilState(cursorModel)
  const upsertSheetRowMutation = useUpsertSheetRowMutation()
  const upsertSheetRowsMutation = useUpsertSheetRowsMutation()
  const { changesModal } = useNavigationModal()
  const rows = useRecoilValue(rowsModel)
  const view = useViewValue()
  const { pickView } = useViewsContextValue()
  const { updateCachedView } = useCachedViewsSelector()
  const message = useMessage()
  const isKpiSheet = view.id.startsWith(CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX)

  const finishEditMode: FinishEditMode = (option?: { moveRowDiff?: number; moveColumnDiff?: number }) => {
    cursorMutation.finishEditMode()
    if (isSome(option?.moveRowDiff) || isSome(option?.moveColumnDiff)) {
      cursorMutation.moveCursor({
        columnDiff: option.moveColumnDiff ?? 0,
        rowDiff: option.moveRowDiff ?? 0,
        expand: false,
      })
    }
    if (reference.current) {
      reference.current.focus()
    }
  }

  function getRowChanges(
    column: RSheetColumn<RSheetRecordNode>,
    up: number,
    upInnerRowIndex: number,
    down: number,
    downInnerRowIndex: number,
    isDeleteAllChildren: boolean,
  ) {
    const selectedRowAndInnerRowIndexes: Array<{ rowIndex: number; innerRowIndex: number }> = []
    for (const rowIndex of range(up, down)) {
      const row = rows[rowIndex]
      if (row === undefined || column === undefined) {
        continue
      }
      // row から column のオブジェクトを取り出す
      const objectRecordNodes = getRecordNodeFromRootNode(row, column.node.path)
      if (objectRecordNodes === undefined) {
        continue
      }
      if (Array.isArray(objectRecordNodes)) {
        if (objectRecordNodes.length === 0) {
          // ありえないはず。この場合は何もしない
          continue
        }
        const startObjectRecordNodeIndex =
          rowIndex === up ? objectRecordNodes.findIndex((node) => node.meta.innerRowIndexStart === upInnerRowIndex) : 0
        const endObjectRecordNodeIndex =
          rowIndex === down
            ? objectRecordNodes.findIndex((node) => node.meta.innerRowIndexStart === downInnerRowIndex)
            : objectRecordNodes.last()!.meta.innerRowIndexStart // objectRecordNodes.length !== 0 を上の行で保証済み
        for (const objectRecordNodeIndex of range(startObjectRecordNodeIndex, endObjectRecordNodeIndex)) {
          const objectRecordNode = objectRecordNodes[objectRecordNodeIndex]
          if (objectRecordNode === undefined) {
            continue
          }
          selectedRowAndInnerRowIndexes.push({ rowIndex, innerRowIndex: objectRecordNode.meta.innerRowIndexStart })
        }
        continue
      }
      selectedRowAndInnerRowIndexes.push({ rowIndex, innerRowIndex: 0 })
    }
    return selectedRowAndInnerRowIndexes.map(({ rowIndex, innerRowIndex }) => ({
      rowIndex,
      innerRowIndex,
      column,
      value: undefined,
      isDelete: true,
      isDeleteAllChildren,
    }))
  }

  function onAfterChange(changes: ViewRecordFieldChange[]) {
    const messageKey = `delete-diff-alert`
    // 2022/03 間違って削除ボタンを押すことがあるので、常にフィードバックが欲しいとのこと
    message.warning({
      key: messageKey,
      content: (
        <span>
          {t(`{{length}}件のレコードを削除します。 `, { length: changes.length })}
          <Button
            type="default"
            onClick={() => {
              if (!isKpiSheet) {
                pickView(view.id)
                updateCachedView?.({ viewId: view.id, changesModal: { visible: true, content: undefined } })
              }
              changesModal.showModal()
              message.destroy(messageKey)
            }}
          >
            {t(`差分を確認`)}
          </Button>
        </span>
      ),
      duration: 6,
    })
  }

  return {
    finishEditMode,
    async onDestroyRowsFromContextMenu(
      column: RSheetColumn<RSheetRecordNode>,
      up: number,
      upInnerRowIndex: number,
      down: number,
      downInnerRowIndex: number,
      isDeleteAllChildren: boolean,
    ) {
      const rowChanges = getRowChanges(column, up, upInnerRowIndex, down, downInnerRowIndex, isDeleteAllChildren)
      await upsertSheetRowsMutation(rowChanges, {
        onAfterChange,
      })
      cursorMutation.clearCursor()
      reference.current?.focus()
    },
    async onDestroyFromContextMenu(column: RSheetColumn<RSheetRecordNode>, isDeleteAllChildren?: boolean) {
      if (column === undefined || cursor === undefined) {
        return
      }
      // TODO: deletable
      await upsertSheetRowMutation(
        {
          rowIndex: cursor.main.rowIndex,
          innerRowIndex: cursor.main.innerRowIndex,
          column,
          value: undefined,
          isDelete: true,
          isDeleteAllChildren,
        },
        {
          onAfterChange,
        },
      )
      cursorMutation.moveToAboveInnerRow()
      reference.current?.focus()
    },
    removeRelation(column: RSheetColumn<RSheetRecordNode>) {
      if (cursor === undefined) {
        return
      }
      recordsAndChangesMutation.removeRelation({
        rowIndex: cursor.main.rowIndex,
        innerRowIndex: cursor.main.innerRowIndex,
        node: column.node,
      })
    },
  }
}
