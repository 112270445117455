import { FolderOpenOutlined, UserOutlined } from '@ant-design/icons'
import { isPresent } from '@salescore/buff-common'

export function FolderPathAndCreator({
  path,
  createdBy,
}: {
  path: string[] | undefined
  createdBy: string | undefined
}) {
  return (
    <div className="flex flex-wrap justify-between text-gray-400">
      <span className="whitespace-pre-wrap break-words">
        {isPresent(path) && (
          <div className="flex flex-row items-start gap-x-1">
            <div className="pt-[0.25px]">
              <FolderOpenOutlined />
            </div>
            {path.join(' / ')}
          </div>
        )}
      </span>
      <span className="flex items-start justify-end whitespace-pre-wrap break-words">
        {isPresent(createdBy) && (
          <div className="flex flex-row gap-x-1">
            <div>
              <UserOutlined />
            </div>
            {createdBy}
          </div>
        )}
      </span>
    </div>
  )
}
