import {
  ConsoleSqlOutlined,
  DeleteOutlined,
  DownOutlined,
  HistoryOutlined,
  SaveOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { isSome } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import { SuspenseWithLoading } from '@salescore/client-common'
import { CORE_CONSTANT, type ViewUIForm } from '@salescore/core'
import { ButtonWithTooltip } from '@salescore/frontend-common'
import { Avatar, Button, Card, Col, Dropdown, Empty, Form, message, Popconfirm, Row, Skeleton, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { type ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { useSetRecoilState } from 'recoil'

import { useNavigationModal } from '../../recoil/navigation/hooks'
import { useHandleKeyDownMutation } from '../../recoil/navigation/mutation/useHandleKeyDown'
import { useChangesValue, useLoadingState, useRecordsValue } from '../../recoil/records/hooks'
import { useRecordsAndChangesMutation } from '../../recoil/records/mutations/recordsAndChangesMutation'
import type { RecordNodePath } from '../../recoil/records/mutations/upsertViewRecordByPath'
import { useUpsertViewRecordsByPathMutation } from '../../recoil/records/mutations/upsertViewRecordsByPathMutation'
import { validationErrorVisibilityAtom } from '../../recoil/view/atoms'
import { useMeValue, useQueryValue, useViewAbilityValue, useViewConfig } from '../../recoil/view/hooks'
import { ViewUIRecordNodeContext, ViewUIRecordNodeContextClass } from './contexts'
import { ExtractedCallingMetadataPage, extractedModelName } from './is_gpt/ExtractedCallingMetadataPage'
import { ModelIcon } from './ModelIcon'
import { ViewUIComponentC, ViewUIContext } from './ViewUIComponent'

interface ViewQueryRecordNodeForForm {
  id: string | undefined
  attributes: Record<string, unknown>
  meta: {
    height: number
    innerRowIndexStart: number
    innerRowIndexEnd: number
    highlightAttributeNames?: string[] // TODO: 後から適切な形式を考える予定
  }
  children: Record<string, ViewQueryRecordNodeForForm[]>
}

export function ViewUIFormC({ component }: { component: ViewUIForm }): ReactNode {
  const config = useViewConfig()
  if (
    config.type === 'form' &&
    [config.tree?.modelName, ...(config.tree?.children ?? []).map((x) => x.modelName)].includes(extractedModelName)
  ) {
    return (
      <SuspenseWithLoading>
        <>
          <ExtractedCallingMetadataPage />
          {/* 雑に元のフォームも出しておく。アノテーションをフォームUIでみたい。 */}
          <div className="mt-32">
            <Body component={component} />
          </div>
        </>
      </SuspenseWithLoading>
    )
  }

  return <Body component={component} />
}

// 本体
function Body({ component }: { component: ViewUIForm }): ReactNode {
  const me = useMeValue()
  const { debugModal } = useNavigationModal()
  const changes = useChangesValue()
  const { changesModal, metadataModal } = useNavigationModal()
  const upsertViewRecordsByPath = useUpsertViewRecordsByPathMutation()
  const loading = useLoadingState()
  const records = useRecordsValue()
  const { onFinish } = useContext(ViewUIContext)
  const [form] = useForm()
  const reference = useRef<HTMLDivElement>(null)
  const recordsAndChangesMutation = useRecordsAndChangesMutation()

  const [isSaving, setIsSaving] = useState(false)
  const [isDeleteClicked, setIsDeleteClicked] = useState(false) // こうしないとrecoilの反映がされないことがあるため
  const [isSaveClicked, setIsSaveClicked] = useState(false) // こうしないとViewUIInputの反映がされないことがあるため
  const handleKeyDown = useHandleKeyDownMutation()
  const ability = useViewAbilityValue()
  const setValidationErrorVisibility = useSetRecoilState(validationErrorVisibilityAtom)

  const onSave = async () => {
    setIsSaving(true)
    setValidationErrorVisibility(true)

    try {
      // TODO: エラーの処理どうするんだっけ？
      const result = await recordsAndChangesMutation.onSave()
      setValidationErrorVisibility(false)
      // TODO:
      // void message.success(`${records.length}件のレコードを保存しました`)
      if (onFinish !== undefined) {
        const success = result?.map((x) => (x.status === 'success' ? x : undefined)).compact()
        onFinish({ id: success?.first()?.result.id })
      }
    } catch (error) {
      if (error instanceof Error) {
        void message.error(error.message)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    } finally {
      setIsSaving(false)
      setIsSaveClicked(false)
    }
  }

  const onDelete = async () => {
    try {
      // TODO: エラーの処理どうするんだっけ？
      await recordsAndChangesMutation.onSave()
      // TODO:
      // void message.success(`${records.length}件のレコードを保存しました`)
      if (onFinish !== undefined) {
        onFinish({ id: records.first()?.id })
      }
    } catch (error) {
      if (error instanceof Error) {
        void message.error(error.message)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    } finally {
      setIsSaveClicked(false)
      setIsDeleteClicked(false)
    }
  }

  const onConfirmDelete = (isDeleteAllChildren?: boolean): void => {
    // TODO: 途中で更新してから削除した場合どうする？
    upsertViewRecordsByPath({
      deleteRecordChanges: [
        {
          recordNodePath,
          isDeleteAllChildren,
        },
      ],
    })
    // onDeleteをここで呼ぶと、上記の変更がrecoilに反映される前にsaveされてしまう
    setIsDeleteClicked(true)
  }

  useEffect(() => {
    if (isSaveClicked) {
      void onSave()
    }
  }, [isSaveClicked])

  useEffect(() => {
    if (isDeleteClicked) {
      void onDelete()
    }
  }, [isDeleteClicked])

  useEffect(() => {
    if (reference.current !== null) {
      reference.current.focus()
    }
  }, [reference.current])

  if (loading.isTrue) {
    return <Skeleton active />
  }

  // TODO: firstをどこでやるか？
  if (!Array.isArray(records) || records.length === 0) {
    return <Empty />
  }
  const recordNode = records.first()

  if (recordNode === undefined) {
    return <Empty />
  }

  const isNewRecord = recordNode.id?.startsWith(CORE_CONSTANT.VIEW_NEW_RECORD_PREFIX) ?? false
  const nameValue =
    (recordNode.attributes[component.nameFieldName ?? ''] as string | undefined) ??
    (isNewRecord ? '新規レコード' : undefined) // TODO
  const urlValue = recordNode.attributes[component.recordUrlFieldName ?? ''] as string | undefined // TODO
  const recordNodePath: RecordNodePath = []
  const relatedBlockComponents = component.children.slice(1) // XXX: 右の「関連」に出すコンポーネント。本当はこんなロジックは書きたくないが、現状はこれが一番実装が楽なのでこの形になっている

  return (
    <div
      ref={reference}
      // onKeyDown={(event) => {
      //   const isWindows = platform.os?.family === 'Windows'
      //   const commandKey: boolean = isWindows ? event.ctrlKey : event.metaKey

      //   switch (event.key) {
      //     case 'Enter': {
      //       if (commandKey) {
      //         form.submit()
      //         event.preventDefault()
      //       }
      //       return
      //     }
      //     case 's': {
      //       if (commandKey) {
      //         form.submit()
      //         event.preventDefault()
      //       }
      //       return
      //     }
      //   }

      //   handleKeyDown(event)
      // }}
    >
      <PageHeader
        title={
          // TODO: 雑に調整している
          <Space style={{ marginTop: -12 }}>
            {/* <BankOutlined/> */}
            <div className="mt-3">
              <Avatar
                style={{ backgroundColor: component.color ?? '#888' }}
                size={45}
                shape={'square'}
                icon={<ModelIcon icon={component.icon} fontSize={26} />}
              />
            </div>
            <div className="" style={{ lineHeight: 1 }}>
              <span className="text-sm font-normal text-gray-600">{component.label}</span>
              <br />
              {isSome(urlValue) ? (
                <a target="_blank" href={urlValue} rel="noreferrer">
                  {nameValue ?? recordNode.id ?? ''}
                </a>
              ) : (
                <span>{nameValue ?? recordNode.id ?? ''}</span>
              )}
            </div>
          </Space>
        }
        extra={[
          me.isAdmin ? (
            <Dropdown
              menu={{
                items: [
                  {
                    key: `record`,
                    label: (
                      <Space className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}>
                        <ConsoleSqlOutlined />
                        {t(`レコードを表示`)}
                      </Space>
                    ),
                    onClick: () => {
                      debugModal.showModal({
                        content: {
                          records,
                        },
                      })
                    },
                  },
                ],
              }}
            >
              <Button icon={<SettingOutlined />} />
            </Dropdown>
          ) : (
            <></>
          ),
          isNewRecord ? (
            <></>
          ) : (
            <Button
              // type="text"
              onClick={() => {
                metadataModal.showModal()
              }}
              icon={<ConsoleSqlOutlined />}
            />
          ),
          <Button
            disabled={changes.isBlank()}
            onClick={() => {
              changesModal.showModal()
            }}
            icon={<HistoryOutlined />}
          />,
          isNewRecord ? (
            <></>
          ) : ability.canDeleteRecord ? (
            <DropdownDeleteButton
              onDelete={() => {
                onConfirmDelete()
              }}
              onDeleteWithAllChildren={() => {
                onConfirmDelete(true)
              }}
            />
          ) : (
            <ButtonWithTooltip danger disabled tooltipTitle={t(`権限がありません`)} icon={<DeleteOutlined />}>
              {t(`削除`)}
            </ButtonWithTooltip>
          ),
          <ButtonWithTooltip
            showTooltip={!ability.canSaveRecord}
            disabled={!ability.canSaveRecord || changes.isBlank()}
            tooltipTitle={t(`権限がありません`)}
            icon={<SaveOutlined />}
            type="primary"
            htmlType="submit"
            loading={isSaving}
            onClick={() => {
              setIsSaveClicked(true)
            }}
          >
            {t(`保存`)}
          </ButtonWithTooltip>,
        ]}
      >
        <ViewUIRecordNodeContext.Provider
          value={
            new ViewUIRecordNodeContextClass({
              recordNode,
              recordNodePath,
              upsertViewRecordsByPath,
            })
          }
        >
          <Form<ViewQueryRecordNodeForForm> form={form} layout="vertical" onFinish={onSave}>
            {/* この辺のレイアウト設定はconfig側で正しくやりたいが、一旦現状のユースケースのみで気軽に設定したいので、決め打ちで以下を表示 */}
            <Row gutter={16}>
              {relatedBlockComponents.isPresent() ? (
                <>
                  <Col span={14}>
                    <Card title={t(`詳細`)}>
                      <ViewUIComponentC component={component.children[0]!} />
                    </Card>
                  </Col>
                  <Col span={10}>
                    <Card title={t(`関連`)} bodyStyle={{ padding: 8 }}>
                      {relatedBlockComponents.map((child) => (
                        <ViewUIComponentC component={child} />
                      ))}
                      {component.children.length <= 1 && <div>{t(`関連なし`)}</div>}
                    </Card>
                  </Col>
                </>
              ) : (
                <Col span={24}>
                  <Card>
                    <ViewUIComponentC component={component.children[0]!} />
                  </Card>
                </Col>
              )}
            </Row>
          </Form>
        </ViewUIRecordNodeContext.Provider>
      </PageHeader>
    </div>
  )
}

function DropdownDeleteButton({
  onDelete,
  onDeleteWithAllChildren,
}: {
  onDelete: () => void
  onDeleteWithAllChildren: () => void
}): ReactNode {
  const query = useQueryValue()
  const [open, setOpen] = useState(false)

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: '1',
            label: (
              <DeleteButtonMenu
                label={t(`レコードの削除`)}
                onConfirm={() => {
                  onDelete()
                }}
              />
            ),
          },
          isSome(query.tree.children) && !query.tree.children.isBlank()
            ? {
                key: '2',
                label: (
                  <DeleteButtonMenu
                    label={t(`レコードの削除(子孫含む)`)}
                    onConfirm={() => {
                      onDeleteWithAllChildren()
                    }}
                  />
                ),
              }
            : undefined,
        ].compact(),
      }}
      open={open}
      trigger={['click']}
      onOpenChange={(open, info) => {
        if (info.source === 'trigger' || open) {
          setOpen(open)
        }
      }}
    >
      <Button type="primary" shape="round" danger>
        <div className="flex items-center gap-2">
          <DeleteOutlined />
          <DownOutlined style={{ fontSize: 10 }} />
        </div>
      </Button>
    </Dropdown>
  )
}

function DeleteButtonMenu({ label, onConfirm }: { label: string; onConfirm: () => void }): ReactNode {
  return (
    <Popconfirm
      title={
        <div>
          {t(`本当に削除しますか？(この操作は取り消せません。`)}
          <br />
          {t(`また、変更中の項目はすべてクリアされます)`)}
        </div>
      }
      onConfirm={onConfirm}
      okText={t(`削除`)}
      cancelText={t(`キャンセル`)}
    >
      <span className="inline-block w-full text-red-500">{label}</span>
    </Popconfirm>
  )
}
