import { simpleLogger } from '../misc/simpleLogger'

const createTableSql = /* sql */ `
CREATE TABLE "public"."salescore_users" (
    "id" varchar(255) NOT NULL COLLATE "ja-x-icu",
    "name" text COLLATE "ja-x-icu",
    "rank" int NOT NULL DEFAULT(0),
    "email" text COLLATE "ja-x-icu",
    "image_url" text COLLATE "ja-x-icu",
    "user_group_id" text COLLATE "ja-x-icu",
    "visibility" bool NOT NULL DEFAULT(TRUE),
    "salescore_user_id" text COLLATE "ja-x-icu",
    "salescore_invitation_id" text COLLATE "ja-x-icu",
    "salesforce_user_id" text COLLATE "ja-x-icu",
    "hubspot_owner_id" text COLLATE "ja-x-icu",
    "hubspot_user_id" text COLLATE "ja-x-icu",
    "zoho_user_id" text COLLATE "ja-x-icu",
    "next_sfa_user_id" text COLLATE "ja-x-icu",
    "google_sheets_user_id" text COLLATE "ja-x-icu",
    "comdesk_user_id" text COLLATE "ja-x-icu",
    "senses_user_id" text COLLATE "ja-x-icu",
    "microsoft_dynamics_user_id" text COLLATE "ja-x-icu",
    "big_query_user_id" text COLLATE "ja-x-icu",
    "kintone_user_id" text COLLATE "ja-x-icu",
    "created_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    "updated_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    "deleted_at" timestamptz,
    PRIMARY KEY ("id")
);`

// ここに増やしたらsalescore_users_with_groupでも作成すること
const ciSUserGroupSql = /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users__user_group_id ON salescore_users (user_group_id);`
const ciSUsersEmailSql = /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users__email ON salescore_users (email);`
const ciRUserGroupSql = /* sql */ `CREATE INDEX IF NOT EXISTS r__salescore_users__user_group_id ON salescore_users (user_group_id);`

const cuiSUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__salescore_user_id ON salescore_users (salescore_user_id);`
const cuiUserInvitationSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__salescore_invitation_id ON salescore_users (salescore_invitation_id);`
const cuiSFUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__salesforce_user_id ON salescore_users (salesforce_user_id);`
const cuiHSUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__hubspot_user_id ON salescore_users (hubspot_user_id);`
const cuiHSOwnerIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__hubspot_owner_id ON salescore_users (hubspot_owner_id);`
const cuiZHUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__zoho_user_id ON salescore_users (zoho_user_id);`
const cuiNSUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__next_sfa_user_id ON salescore_users (next_sfa_user_id);`
const cuiGSUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__google_sheets_user_id ON salescore_users (google_sheets_user_id);`
const cuiCDUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__comdesk_user_id ON salescore_users (comdesk_user_id);`
const cuiSSUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__senses_user_id ON salescore_users (senses_user_id);`
const cuiMDUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__microsoft_dynamics_user_id ON salescore_users (microsoft_dynamics_user_id);`
const cuiBQUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__big_query_user_id ON salescore_users (big_query_user_id);`
const cuiKiUserIdSql = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users__kintone_user_id ON salescore_users (kintone_user_id);`

export async function createUsersTable(query: (sql: string) => Promise<void>) {
  await query(createTableSql)
  // 冪等にしたいが、インデックスが存在するとエラーになるので無視（丁寧にケアすれば可能だがやらない）
  const queryAndIgnoreError = async (sql: string): Promise<void> => {
    try {
      await query(sql)
    } catch (error) {
      simpleLogger.warn(error)
    }
  }

  await Promise.all([
    queryAndIgnoreError(ciSUserGroupSql),
    queryAndIgnoreError(ciSUsersEmailSql),
    queryAndIgnoreError(ciRUserGroupSql),

    queryAndIgnoreError(cuiSUserIdSql),
    queryAndIgnoreError(cuiUserInvitationSql),
    queryAndIgnoreError(cuiSFUserIdSql),
    queryAndIgnoreError(cuiHSUserIdSql),
    queryAndIgnoreError(cuiHSOwnerIdSql),
    queryAndIgnoreError(cuiZHUserIdSql),
    queryAndIgnoreError(cuiNSUserIdSql),
    queryAndIgnoreError(cuiGSUserIdSql),
    queryAndIgnoreError(cuiCDUserIdSql),
    queryAndIgnoreError(cuiSSUserIdSql),
    queryAndIgnoreError(cuiMDUserIdSql),
    queryAndIgnoreError(cuiBQUserIdSql),
    queryAndIgnoreError(cuiKiUserIdSql),
  ])
}
