import { isNull, isPresent, isSome, treeUtil } from '@salescore/buff-common'
import { flatNodes } from '@salescore/core'

import type { User } from '../user/types'
import type { UserGroup } from '../user_group/types'
import type { GoalConfig } from './types'

// 多分UserGroup側にrankを生やしてもいいが、影響範囲を見るのをサボった
type UserGroupWithRank = UserGroup & { rank: number }

export function getUsersWithUserGroup({
  goalConfig,
  users,
  userGroups,
}: {
  goalConfig: GoalConfig
  users: User[]
  userGroups: UserGroupWithRank[]
}) {
  if (goalConfig.userType === 'none') {
    return []
  }

  const tree = treeUtil.createTreesFromItems({
    items: userGroups,
    getId: (x) => x.id,
    getParentId: (x) => x.user_group_id ?? undefined,
  })
  const flattenNodes = tree.flatMap((x) => flatNodes(x))
  const userGrouopsWithChildren = flattenNodes.filter((x) => goalConfig.userGroupIds.includes(x.item.id))
  const flattenUserGroupsWithChildren = userGrouopsWithChildren.flatMap((x) => flatNodes(x))
  const userGroupIds = new Set(flattenUserGroupsWithChildren.flatMap((x) => x.item.id))

  const userIdsIncludingChildGroup = [
    ...goalConfig.userIds,
    ...users.filter((user) => userGroupIds.has(user.user_group_id ?? '')).map((x) => x.id),
    ...(goalConfig.userGroupIds.includes('no-team')
      ? users.filter((user) => !isPresent(user.user_group_id)).map((x) => x.id)
      : []),
  ].unique()

  return [
    ...userIdsIncludingChildGroup
      .map((userId) => {
        const user = users.find((user) => user.id === userId)
        const group = userGroups.find(
          (group) => group.id === user?.user_group_id || (group.id === 'no-team' && isNull(user?.user_group_id)),
        )
        return isSome(user) && isSome(group) ? { user, group } : undefined
      })
      .compact()
      .sortBy((x) => x.group.rank),
  ]
}
