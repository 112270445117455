import type { ViewQueryList, ViewQueryNode } from '@salescore/core'

import { getNode } from '../../../state/nodeUtil'

// 引数で渡したnodeの親ノードについて、親ノードに参照フィールドがある場合にそのフィールドを取得する関数
export function getParentReferenceField(query: ViewQueryList, node: ViewQueryNode) {
  const parentNode = getNode(query.tree, node.path.slice(0, -1))
  if (parentNode === undefined) {
    throw new Error(`parentNode not found`)
  }

  const joinOn = node.read.join?.joinOn
  if (joinOn === undefined) {
    throw new Error(`parentNodeColumnName undefined`)
  }
  if (joinOn.type === 'sql') {
    // i18n: エラー系は一旦保留
    throw new Error(`カスタムSQLによるJOINを定義している場合、このアクションは実行できません`)
  }
  if (joinOn.parentNodeColumnName === 'id') {
    throw new Error(`parentNodeColumnName must not be id`)
  }
  const parentReferenceField = query.fields.find((field) => {
    const x = field.meta.dependedPropertyNamesWithStreamName.first()
    if (x === undefined) {
      return false
    }
    return x.streamName === joinOn.meta.parentNodeStreamName && x.propertyName === joinOn.meta.parentNodePropertyName
  })

  return {
    parentReferenceField,
    joinOn,
    parentNode,
  }
}
