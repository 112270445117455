import { ModelSearcher } from '@salescore/core'
import { selector } from 'recoil'

import { viewsContextAtom } from '../atoms'

const prefix = `view`
export const modelSearcherSelector = selector({
  key: `${prefix}/modelSearcherSelector`,
  get({ get }) {
    const { models } = get(viewsContextAtom)
    const searcher = new ModelSearcher(models)
    return searcher
  },
})
