import { SettingOutlined } from '@ant-design/icons'
import { compareFunction, isSome } from '@salescore/buff-common'
import type { EltModelFieldsFragment } from '@salescore/client-api'
import { PropertyMetaTag, PropertyTypeTag } from '@salescore/client-common'
import type { ModelProperty } from '@salescore/core'
import { getColumnSearchProps } from '@salescore/frontend-common'
import { App, Button, Table, Tag } from 'antd'
import { t } from 'i18next'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'

const ellipsisFilter = (string_: unknown, x: number): string => {
  if (typeof string_ !== 'string') {
    return ''
  }

  if (string_.length <= x) {
    return string_
  }
  return string_.slice(0, x) + '...'
}

export const ModelPropertiesModalContent = ({
  eltModel,
}: {
  eltModel: EltModelFieldsFragment | undefined
}): JSX.Element => {
  const { modal } = App.useApp()

  return (
    <div className="overflow-x-scroll">
      <Table
        dataSource={eltModel?.model.properties ?? []}
        pagination={false}
        style={{ width: '100%' }}
        columns={[
          {
            title: '属性名',
            dataIndex: 'description',
            key: 'description',
            width: 250,
            sorter: (a, b) => compareFunction(a.label, b.label),
            render(_value, record) {
              return <div>{ellipsisFilter(record.label, 35)}</div>
            },
            ...getColumnSearchProps((record: ModelProperty) => record.label),
          },
          {
            title: <div className="">内部名</div>,
            dataIndex: 'name',
            key: 'name',
            width: 150,
            sorter: (a, b) => compareFunction(a.name, b.name),
            render(_value, record) {
              return <div className="text-gray-500">{ellipsisFilter(record.name, 30)}</div>
            },
            ...getColumnSearchProps((record: ModelProperty) => record.name),
          },
          {
            title: '型',
            dataIndex: 'type',
            sorter: (a, b) => compareFunction(a.type, b.type),
            render(_value: string, record) {
              return <PropertyTypeTag type={record.type} />
            },
          },
          {
            title: 'メタ情報',
            dataIndex: 'meta',
            sorter: (a, b) => compareFunction(a.meta, b.meta),
            render(_value: string, record) {
              return <PropertyMetaTag meta={record.meta} />
            },
          },
          {
            title: '選択肢',
            dataIndex: 'selectOptions',
            sorter: (a, b) => compareFunction((a.selectOptions ?? []).join(','), (b.selectOptions ?? []).join(',')),
            render(_value: string, record) {
              const options = (record.selectOptions ?? []).map((x) => x.label)
              if (options.length === 0) {
                return <span></span>
              }
              return (
                <span>
                  {options.slice(0, 3).join(',')}
                  {options.length > 3 ? `...(他${options.length - 3}件)` : ''}
                </span>
              )
            },
          },
          {
            title: '参照先',
            dataIndex: 'referenceTo',
            sorter: (a, b) => compareFunction(a.referenceTo, b.referenceTo),
            render(_value: unknown, record) {
              return (
                <>
                  {(record.referenceTo ?? []).map((x, index) => (
                    <Tag color="default" className="px-2" key={index}>
                      {x.modelName} {isSome(x.key) ? `(${x.key})` : ''}
                    </Tag>
                  ))}
                </>
              )
            },
          },
          {
            title: t('詳細'),
            dataIndex: 'all',
            render(_value, record) {
              return (
                <Button
                  icon={<SettingOutlined />}
                  onClick={() => {
                    void modal.info({
                      width: '600px',
                      title: t('詳細'),
                      content: (
                        <SyntaxHighlighter
                          language="json"
                          style={monokaiSublime}
                          customStyle={{ padding: 10 }}
                          wrapLongLines={true}
                        >
                          {JSON.stringify(record, null, 2)}
                        </SyntaxHighlighter>
                      ),
                      okText: t(`閉じる`),
                    })
                  }}
                />
              )
            },
          },
        ]}
      />
    </div>
  )
}
