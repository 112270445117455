import { CalendarOutlined, LeftOutlined, RightOutlined, SaveOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import * as holidayJp from '@holiday-jp/holiday_jp'
import { type BusinessDayFieldsFragment, UpsertBusinessDayDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { useBooleanState } from '@salescore/frontend-common'
import { Button, Calendar, message, Row, Space, Tag } from 'antd'
import type { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

interface BusinessDayInput {
  id?: string
  date: string
  isBusinessDay: boolean
}

export const BusinessDayCalendarForm = ({
  businessDays,
  refetch,
}: {
  businessDays: BusinessDayFieldsFragment[]
  refetch: () => Promise<void>
}) => {
  const [upsertBusinessDayMutation] = useMutation(UpsertBusinessDayDocument)
  const [currentBusinessDays, setCurrentBusinessDays] = useState<BusinessDayInput[]>(businessDays)
  const loading = useBooleanState()
  const hasChange = !currentBusinessDays
    .map((x) => x.date)
    .sortBy((x) => x)
    .isEqual(businessDays.map((x) => x.date).sortBy((x) => x))

  useEffect(() => {
    setCurrentBusinessDays(businessDays)
  }, [businessDays])

  const toggleDay = (date: Dayjs) => {
    setCurrentBusinessDays((xs) => {
      const x = businessDays.find((day) => day.date === date.format('YYYY-MM-DD'))
      if (xs.some((day) => day.date === date.format('YYYY-MM-DD'))) {
        // 既に存在していたら、削除
        return xs.filter((day) => day.date !== date.format('YYYY-MM-DD'))
      } else if (x === undefined) {
        // 存在しておらず、元の配列にもなければ、新規作成
        return [
          ...xs,
          {
            id: undefined,
            date: date.format('YYYY-MM-DD'),
            isBusinessDay: !getDateWithHoliday(date, xs).isBusinessDayOriginal,
          },
        ]
      } else {
        // 存在しておらず、元の配列にあったら、元のを戻す
        return [...xs, x]
      }
    })
  }

  const onFinish = async () => {
    try {
      loading.setTrue()
      // 削除されたもの
      const currentIds = currentBusinessDays.map((x) => x.id).compact()
      for (const businessDay of businessDays.filter((x) => x.id !== undefined && !currentIds.includes(x.id))) {
        await upsertBusinessDayMutation({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            businessDay: {
              ...businessDay,
              deleted: true,
            },
          },
        })
      }
      // 新規作成されたもの
      for (const day of currentBusinessDays.filter((x) => x.id === undefined)) {
        await upsertBusinessDayMutation({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            businessDay: {
              ...day,
            },
          },
        })
      }
      await refetch()
      void message.success(t(`保存しました`))
    } catch {
      void message.error(t(`エラーが発生しました`))
    } finally {
      loading.setFalse()
    }
  }

  return (
    <Calendar
      className="business-day-calendar"
      headerRender={({ onChange, onTypeChange, value }) => {
        const onNext = () => {
          onChange(value.clone().add(1, 'month').startOf('month'))
        }
        const onBack = () => {
          onChange(value.clone().subtract(1, 'month').startOf('month'))
        }

        return (
          <Row align="middle" justify="space-between" className="w-full px-2 py-4 font-bold">
            <Space>
              <div className="bold mr-4 text-lg">{value.format('YYYY年M月')}</div>
              <Space>
                <Button onClick={onBack} icon={<LeftOutlined />}>
                  {t(`前月`)}
                </Button>
                <Button onClick={onNext} icon={<RightOutlined />}>
                  {t(`次月`)}
                </Button>
              </Space>
            </Space>
            <Space>
              <Button
                loading={loading.isTrue}
                type="primary"
                disabled={!hasChange}
                onClick={onFinish}
                icon={<SaveOutlined />}
              >
                {t(`保存`)}
              </Button>
            </Space>
          </Row>
        )
      }}
      dateFullCellRender={(value) => {
        const date = getDateWithHoliday(value, currentBusinessDays)

        return (
          <div
            className={
              date.isSunday || date.isHoliday
                ? 'bg-red-50 text-red-500'
                : date.isSaturday
                  ? 'bg-blue-50 text-blue-500'
                  : ''
            }
            style={{
              height: 80,
              borderTop: 'solid #eee 1px',
            }}
            onClick={() => {
              toggleDay(value)
            }}
          >
            <div className="px-2 py-1">
              {value.date() === 1 ? value.format('M月') : ''}
              {value.format('D日')}
              <div className="text-left">
                {date.isBusinessDay && (
                  <Tag
                    closable
                    // className="w-full"
                    icon={<CalendarOutlined />}
                  >
                    {t(`営業日`)}
                  </Tag>
                )}
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

function getDateWithHoliday(date: Dayjs, days: BusinessDayInput[]) {
  const isSunday = date.day() === 0
  const isSaturday = date.day() === 6
  const isHoliday = holidayJp.isHoliday(date.toDate())
  const businessDay = days.find((x) => x.date === date.format('YYYY-MM-DD'))
  const isBusinessDayOriginal = !(isHoliday || isSunday || isSaturday)

  return {
    isSunday,
    isSaturday,
    isHoliday,
    isBusinessDayOriginal,
    isBusinessDay: businessDay === undefined ? isBusinessDayOriginal : businessDay.isBusinessDay,
  }
}
