import { BarChartOutlined, DashboardOutlined, LoginOutlined, TableOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { FetchViewGroupsDocument, type ViewForSiderFieldsFragment } from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { t } from 'i18next'
import { useMemo } from 'react'

import {
  convertFlatViewGroupsToAntdTree,
  type ExpandedAntdNode,
} from '../../sider/functions/convertFlatNodesToAntdTree'
import { usePickedViewIds } from '../../sider/hooks'
import { useOrganizationId } from '../atoms'
import { useMe } from '../hooks'
import { useCanForFeatures } from '../policy/useCanForFeature'
import type { LauncherCommandV2 } from './launcherCommandV2'
import { useMoveCommands } from './useMoveCommands'

export const useLauncherCommands = ({ redirect }: { redirect: (path: string) => void }): LauncherCommandV2[] => {
  const moveCommands = useMoveCommands({ redirect })
  const loginCommands = useLoginCommands({ redirect })
  const moveViewCommands = useMoveViewsCommands({ redirect })
  return [...moveCommands, ...loginCommands, ...moveViewCommands]
}

function useLoginCommands({ redirect }: { redirect: (path: string) => void }): LauncherCommandV2[] {
  const organizationId = useOrganizationId()
  const me = useMe()
  const users = me.myUser.identity.users
  if (users.length <= 1) {
    return []
  }

  return users
    .filter((user) => user.organization.id !== organizationId)
    .map((user) => ({
      type: `other`,
      icon: <LoginOutlined />,
      title: t(`移動: {{name}}`, { name: user.organization.name }),
      searchKeyword: `move ${user.organization.name.toLowerCase()}`,
      // shortcut: ['command', 'd'],
      method: () => {
        // 再現手順は不明だが、組織の移動の際に稀に移動前の組織の情報が残ってしまう不具合があったので
        // 組織移動の時のみredirectではなくlocation.assignを使うように変更
        location.assign(routes.topPathWithOrganizationIdV2(user.organization.id)) // TODO: 常にv2でいいのか？
      },
    }))
}

function useMoveViewsCommands({ redirect }: { redirect: (path: string) => void }): LauncherCommandV2[] {
  const organizationId = useOrganizationId()
  const { data } = useQuery(FetchViewGroupsDocument, { variables: { organizationId } })
  const { pickView } = usePickedViewIds()
  const ability = useCanForFeatures()
  const views = data?.viewsForSider ?? []
  const viewGroups = data?.viewGroups ?? []
  const viewWithGroups = useMemo(() => {
    const trees = convertFlatViewGroupsToAntdTree(viewGroups, views)
    const leafs = trees.flatMap((tree) => extractLeaf(tree, []))
    return leafs.map((leaf) => {
      const view = leaf.item as ViewForSiderFieldsFragment // TODO: 雑
      const type = view.type
      const typeLabel = {
        sheet: ``,
        kpi: `KPI`,
        kpiPivot: t(`ダッシュボード`),
        form: t(`フォーム`),
        kpiTimeSeries: `案件管理`,
      }[type]
      return {
        title: [typeLabel, view.name].compact().join(': '),
        subTitle: [
          view.private ? t(`非公開フォルダ`) : view.archived ? t(`ゴミ箱`) : undefined,
          ...leaf.parents.map((parent) => parent.item.name),
        ]
          .compact()
          .join(' / '),
        id: view.id,
        type,
        createdBy: view.createdBy ?? undefined,
        archived: view.archived,
      }
    })
  }, [views, viewGroups])
  if (viewWithGroups.length <= 1) {
    return []
  }

  return viewWithGroups
    .map((view): LauncherCommandV2 | undefined => {
      if (view.type === 'kpi' && !ability.canOpenKpi) {
        return undefined
      }
      return {
        type: view.archived ? 'archived' : view.type === 'form' ? `sheet` : view.type,
        icon:
          view.type === 'kpiPivot' ? (
            <DashboardOutlined />
          ) : view.type === 'kpi' ? (
            <BarChartOutlined />
          ) : (
            <TableOutlined />
          ),
        title: view.title,
        subTitle: view.subTitle,
        createdBy: view.createdBy,
        searchKeyword: `view ${view.title.toLowerCase()} ${view.subTitle.toLowerCase()} ${view.createdBy?.name ?? ''}`,
        method: () => {
          pickView(view.id)
          redirect(routes.topPathV2())
        },
      }
    })
    .compact()
}

function extractLeaf(
  node: ExpandedAntdNode,
  parents: ExpandedAntdNode[],
): Array<ExpandedAntdNode & { parents: ExpandedAntdNode[] }> {
  if (node.isLeaf === true) {
    return [
      {
        ...node,
        parents,
      },
    ]
  }

  return node.children.flatMap((childNode) => extractLeaf(childNode, [...parents, node]))
}
