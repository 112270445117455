import type { Command } from './parser/grammer_schema'
import { selectCommand } from './query/selectCommand'
import type { ResultRecord, Table } from './query/types'

export function query(command: Command, tables: Table[]): ResultRecord[] {
  const type = command.type
  switch (type) {
    case 'selectCommand': {
      return selectCommand(command, tables)
    }
    case 'setCommand': {
      return []
    }
    default: {
      const x: never = type
      throw new Error(`not implemented`)
    }
  }
}
