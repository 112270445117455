import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons'
import type { CSSProperties } from 'react'

import { useAddEmptyRecordMutation } from '../../../../recoil/records/mutations/addEmptyRecordMutation'
import { useCursorMutation } from '../../../recoil/mutations/useCursorMutation'
import type { RSheetColumn, RSheetRecordNode } from '../../../types'
import { RSheetsStyle } from '../../../util/RSheetsStyle'
import { MyCheckbox } from './CellBody'

export function RsheetsEmptyCell({
  column,
  rowIndex,
  columnIndex,
  innerRowIndex,
  defaultStyle,
}: {
  column: RSheetColumn<RSheetRecordNode>
  rowIndex: number
  columnIndex: number
  innerRowIndex: number
  defaultStyle: CSSProperties
}) {
  const { addChildRecord } = useAddEmptyRecordMutation()
  const cursorMutation = useCursorMutation()
  const boxStyle: CSSProperties = {
    ...defaultStyle,
    fontSize: 14,
    padding: '10px 10px',
    // backgroundColor: isRangeSelected ? 'rgba(14, 101, 235, 0.1)' : isEditing ? '#fff' : 'inherit',
    overflow: 'hidden',
    // boxShadow: isEditing ? '1px 4px 7px 2px rgb(60 64 67 / 15%)' : '', // 表示用のrelativeなdivでは下方向に表示されないが、input側のabsoluteの方で下方向が表示される
    // zIndex: isEditing ? 1 : 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    transition: RSheetsStyle.cellTransition,
    textAlign: 'center',
    color: RSheetsStyle.color.gray,
  }

  const position = {
    columnIndex,
    rowIndex,
    innerRowIndex,
  }

  return (
    <>
      <div
        style={boxStyle}
        className={`rsheet-empty-cell bg-${column.color ?? 'gray'}-50`}
        onClick={async () => {
          switch (column.nodeType) {
            case 'children': {
              cursorMutation.setMainCursor(position)
              await addChildRecord({ rowIndex, innerRowIndex, node: column.node })

              break
            }
            case 'child': {
              cursorMutation.setMainCursor(position, true)
              await addChildRecord({ rowIndex, innerRowIndex, node: column.node })

              break
            }
            case 'parent': {
              cursorMutation.setMainCursor(position, true)

              break
            }
            // No default
          }
        }}
        onContextMenu={async (e) => {
          const contextMenuPosition = {
            x: e.clientX,
            y: e.clientY,
          }
          cursorMutation.setContextMenuPosition(position, contextMenuPosition)
          e.preventDefault()
          e.stopPropagation()

          // 2023/06 「空セルの上でも右クリックメニューが出せる」という仕様でがあると、ペースト側で空セルなのかどうかの判定が必要で、多少面倒
          //         そのため、右クリックのときも左クリックと同様にレコードを作成する挙動とする
          //         特に問題ないと考えているが、もし問題があればusePasteWithNewRecord側で空セル判定を行う
          if (column.nodeType === 'children') {
            cursorMutation.setMainCursor(position)
            await addChildRecord({ rowIndex, innerRowIndex, node: column.node })
          } else if (column.nodeType === 'child') {
            cursorMutation.setMainCursor(position, true)
            await addChildRecord({ rowIndex, innerRowIndex, node: column.node })
          }
        }}
      >
        {column.nodeType === 'parent' ? (
          <SearchOutlined />
        ) : column.nodeType === 'children' ? (
          <PlusCircleOutlined />
        ) : column.nodeType === 'child' ? (
          <ChildEmptyCell column={column} />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

// parent,childrenの時は、その関係性がわかる空白セルとして表示するが、
// childのときは、単純な空白セルとして表示する。すなわち、子であることがわからない見た目となる。
// 例えばchildrenのときは、子レコードがなければ+マークがでるが、childのときはレコードがなくても+がでない。
function ChildEmptyCell({ column }: { column: RSheetColumn<RSheetRecordNode> }) {
  const type = column.type
  switch (type) {
    case 'boolean': {
      return (
        <div className="text-left">
          <MyCheckbox value={false} />
        </div>
      )
    }
  }

  return <></>
}
