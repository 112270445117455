import { BranchesOutlined, DeleteOutlined } from '@ant-design/icons'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import {
  DeleteI18nSourceDocument,
  FetchI18nSourcesDocument,
  LangEnum,
  type SourceFieldsFragment,
} from '@salescore/client-api'
import { useOpenAuthorizationUrl } from '@salescore/client-common'
import { Button, Popconfirm, Space } from 'antd'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

export const I18nSourcesContent = ({
  source,
  onCompleted,
  onError,
}: {
  source: Pick<SourceFieldsFragment, 'id' | 'organizationId' | 'provider'>
  onCompleted: () => void
  onError: () => void
}) => {
  const { openAuthorizationUrl } = useOpenAuthorizationUrl()
  const { data: i18nSources, refetch: invalidate } = useSuspenseQuery(FetchI18nSourcesDocument, {
    variables: {
      organizationId: source.organizationId,
    },
  })
  const [deleteI18nSource, { loading }] = useMutation(DeleteI18nSourceDocument)

  // 現状、対応すべきはenのみなので、enに対してのみのフォームを作る
  const enSource = i18nSources.i18nSources.find((x) => x.lang === LangEnum.En)

  return (
    <Space direction={'vertical'}>
      <div>
        {t(`Salesforceのオブジェクト名や項目名などのメタデータは、連携設定を行なったユーザーの言語で取得されます。`)}
        <br />
        <Trans>
          英語でメタデータを取得したい場合、Salesforceの言語設定を英語にしたユーザーが以下の連携設定を行うことで英語でメタデータを取得することができます。
        </Trans>
      </div>

      <Space>
        <span>
          {t(`英語の連携設定`)}:{enSource === undefined ? t(`未設定`) : t(`設定済み`)}
        </span>

        <Button
          type="primary"
          icon={<BranchesOutlined />}
          onClick={() => {
            void openAuthorizationUrl({ type: 'i18nSource', provider: source.provider, source, i18nLang: 'en' })
          }}
        >
          {enSource === undefined ? t(`連携する`) : t(`再連携`)}
        </Button>

        {enSource !== undefined && (
          <Popconfirm
            title={t(`本当に削除しますか？`)}
            onConfirm={async () => {
              await deleteI18nSource({
                variables: {
                  organizationId: source.organizationId,
                  id: enSource.id,
                },
                onCompleted: () => {
                  onCompleted()
                  void invalidate()
                },
                onError,
              })
            }}
          >
            <Button danger icon={<DeleteOutlined />}>
              {t(`削除する`)}
            </Button>
          </Popconfirm>
        )}
      </Space>
    </Space>
  )
}
