import { selector, useRecoilValue } from 'recoil'

import { abilitySelectorFamily } from './useCan'

const abilityForFeaturesSelector = selector({
  key: `common/abilityForFeaturesSelector`,
  get: ({ get }) => ({
    canManageOrganizationSetting: get(abilitySelectorFamily({ action: `manage-organization-setting` })),
    canManageConnection: get(abilitySelectorFamily({ action: `manage-connection` })),
    canManageGoal: get(abilitySelectorFamily({ action: `manage-goal` })),
    canManageDimensionGroup: get(abilitySelectorFamily({ action: `manage-dimension-group` })),
    canManageUsers: get(abilitySelectorFamily({ action: `manage-users` })),
    canManageUserGroups: get(abilitySelectorFamily({ action: `manage-user-groups` })),
    canManageInvitation: get(abilitySelectorFamily({ action: `manage-invitation` })),
    canManagePolicy: get(abilitySelectorFamily({ action: `manage-policy` })),
    canReadUserActivity: get(abilitySelectorFamily({ action: `read-user-activity` })),
    // v1用の権限
    canManageKpi: get(abilitySelectorFamily({ action: `kpi-update` })),
    canManageDashboard: get(abilitySelectorFamily({ action: `kpi-pivot-update` })),
    // v2の仕様が微妙な箇所の権限
    canOpenKpi: get(abilitySelectorFamily({ action: `kpi-create` })), // KPIセクションを開く権限
  }),
})

export const useCanForFeatures = () => useRecoilValue(abilityForFeaturesSelector)
