import { DeleteOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { isSome } from '@salescore/buff-common'
import {
  FetchOrganizationSettingDocument,
  UpdateOrganizationDocument,
  type UserGroupFieldsFragment,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { FEATURE_CONSTANT } from '@salescore/features'
import { Button, Divider, Form, Input, message, Row } from 'antd'
import { t } from 'i18next'
import { type ReactNode, useState } from 'react'

const Body = ({
  currentDepth,
  userGroupCategoryNames,
  onAfterFinish = () => {
    /* empty */
  },
}: {
  currentDepth?: number
  userGroupCategoryNames: string[]
  onAfterFinish?: () => void
}): ReactNode => {
  const [names, setNames] = useState(userGroupCategoryNames)
  const [updateOrganizationMutation] = useMutation(UpdateOrganizationDocument)
  const onSave = (): void => {
    void updateOrganizationMutation({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        organization: {
          setting: {
            userGroupCategoryNames: names,
          },
        },
      },
      onCompleted: () => {
        void message.success(t(`更新しました`))
        onAfterFinish()
      },
    })
  }

  return (
    <PageHeader title={t(`階層構造を編集`)}>
      <div className="mb-8">
        {t(`組織の階層構造を定義したい場合、こちらのフォームより編集してください。`)}
        <br />
        {t(`（例）組織構造が「部」「課」「室」のときは、階層1に「部」、階層3に「室」を入力してください。`)}
        <br />
        {t(`※組織に階層構造がない場合は、こちらの入力は不要です。`)}
      </div>
      <Divider />
      <Form>
        {names.map((name, index) => (
          <Row key={index}>
            <Form.Item label={t(`階層{{val}}`, { val: index + 1 })}>
              <Input
                value={name}
                onChange={(e) => {
                  const newNames = [...names]
                  newNames[index] = e.target.value
                  setNames(newNames)
                }}
              />
            </Form.Item>
            <Button
              className="ml-2"
              icon={<DeleteOutlined />}
              onClick={() => {
                const newNames = [...names]
                newNames.splice(index, 1)
                setNames(newNames)
              }}
            />
          </Row>
        ))}
        <Row>
          <Button
            disabled={names.length >= FEATURE_CONSTANT.USER_GROUP_CATEGORY_NAMES_MAX_LENGTH}
            icon={<PlusOutlined />}
            onClick={() => {
              setNames([...names, ''])
            }}
          >
            {t(`階層を追加`)}
          </Button>
        </Row>
        <Row justify="end">
          <Button type="primary" icon={<SaveOutlined />} onClick={onSave}>
            {t(`保存`)}
          </Button>
        </Row>
      </Form>
    </PageHeader>
  )
}

export const UserGroupCategoryNamesForm = ({
  userGroups,
  onAfterFinish,
}: {
  userGroups?: UserGroupFieldsFragment[]
  onAfterFinish?: () => void
}): ReactNode => {
  const {
    data: { organization },
  } = useSuspenseQuery(FetchOrganizationSettingDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
    fetchPolicy: 'cache-and-network',
  })
  const currentDepth = isSome(userGroups) ? Math.max(...userGroups.map((x) => x.depth)) : undefined

  return (
    <Body
      currentDepth={currentDepth}
      userGroupCategoryNames={organization.setting.userGroupCategoryNames}
      onAfterFinish={onAfterFinish}
    />
  )
}
