import { isPresent } from '@salescore/buff-common'
import type { DataEntryCommon } from '@salescore/core'
import { t } from 'i18next'

// 一旦、requiredのみを雑に対応
export function viewUiValidation(value: unknown, component: DataEntryCommon) {
  if (component.required === true && component.readonly !== true && !isPresent(value)) {
    return [
      {
        message: t(`入力してください`),
      },
    ]
  }

  return []
}
