import { useQuery } from '@apollo/client'
import { FetchUsersDocument } from '@salescore/client-api'
import { MentionTextarea, UserAvatar } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { organizationHasFeature } from '@salescore/frontend-common'
import { Button, Form, Typography } from 'antd'

interface Properties {
  isSmall: boolean
  isReply: boolean
  isDisabled: boolean
  onClickCancelButton: () => void
}

export function CommentForm({ isSmall, isReply, isDisabled, onClickCancelButton }: Properties) {
  const { organization, myUser } = recoil.global.useMe()
  const users = useQuery(FetchUsersDocument, {
    variables: {
      organizationId: organization.id,
    },
    fetchPolicy: 'cache-first',
  })
  const adminMentionEnabled = organizationHasFeature(organization, 'enable_admin_mention')
  const options = (users.data?.users ?? [])
    .filter((user) => adminMentionEnabled || user.role !== 'admin')
    .map((user) => ({
      id: user.id,
      display: user.name,
    }))

  return (
    <div className="flex w-full items-start gap-2">
      <UserAvatar user={myUser} size={isSmall ? 'small' : 'default'} />
      <div className="mt-1 flex-1">
        <Typography.Text strong className="text-default">
          {myUser.name}
        </Typography.Text>
        <Form.Item name="text" className="my-1 w-full">
          <MentionTextarea isReply={isReply} options={options} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }} className="my-1">
          <div className="flex justify-end gap-2">
            <Button type="text" size={isSmall ? 'small' : 'middle'} onClick={onClickCancelButton}>
              <Typography.Text strong>閉じる</Typography.Text>
            </Button>
            <Button htmlType="submit" type="primary" size={isSmall ? 'small' : 'middle'} disabled={isDisabled}>
              コメント
            </Button>
          </div>
        </Form.Item>
      </div>
    </div>
  )
}
