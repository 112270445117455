import type { CoreModel, ModelProperty } from '@salescore/core'
import { t } from 'i18next'

// SheetCustomModelをCoreModelの形に変換する
interface SheetCustomModel {
  name: string
  properties: ModelProperty[]
}

export function convertSheetCustomModel(sheetCustomModel: SheetCustomModel): CoreModel {
  return {
    name: sheetCustomModel.name,
    label: t(`SALESCOREカスタム`),
    write: {
      sourceEntityName: sheetCustomModel.name,
      shouldWriteToNormalizedTable: true,
    },
    properties: [
      ...sheetCustomModel.properties,
      {
        name: `parent_id`,
        label: ``, // これがユーザーに露出することはないはず。compileSheetViewConfigTreeで空文字列はnodeのラベルに表示しないロジックをいれている
        type: 'string',
        referenceTo: [
          {
            modelName: ``, // 使われないのでなくてOK
            relation: 'one_to_one',
          },
        ],
        write: {
          sourcePropertyName: `parent_id`,
        },
      },
    ],
  }
}
