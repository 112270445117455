import type { KpiFieldsFragment } from '@salescore/client-api'
import { buildStateHook } from '@salescore/frontend-common'
import { atom } from 'recoil'

const prefix = `settings/kpis`

const pickedKpisAtom = atom<KpiFieldsFragment[]>({
  key: `${prefix}/pickedKpis`,
  default: [],
})

export const usePickedKpis = buildStateHook(pickedKpisAtom)
