import { simpleLogger } from '../../misc/simpleLogger'

const createTableSql = /* sql */ `
CREATE TABLE IF NOT EXISTS "public"."salescore_reverse_elt_logs" (
    "id" varchar(255) NOT NULL,
    "reverse_elt_id" varchar(255) NOT NULL,
    "transaction_id" varchar(255) NOT NULL,
    "status" varchar(255) NOT NULL,
    "change_type" varchar(255) NOT NULL,
    "record_id" varchar(255),
    "record_model_name" varchar(255) NOT NULL,
    "original_record_id" varchar(255),
    "original_model_name" varchar(255),
    "error" TEXT,
    "data" JSON NOT NULL,
    "created_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    PRIMARY KEY ("id")
);`

const index1 = /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_reverse_elt_logs__transaction_id ON salescore_reverse_elt_logs (transaction_id);`
const index2 = /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_reverse_elt_logs__created_at ON salescore_reverse_elt_logs (created_at);`
const index3 = /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_reverse_elt_logs__status ON salescore_reverse_elt_logs (status, created_at);`

export async function createReverseEltLogTable(query: (sql: string) => Promise<void>) {
  // 冪等にしたいが、インデックスが存在するとエラーになるので無視（丁寧にケアすれば可能だがやらない）
  const queryAndIgnoreError = async (sql: string): Promise<void> => {
    try {
      await query(sql)
    } catch (error) {
      simpleLogger.warn(`SQLの実行中にエラーが発生`, sql, error)
    }
  }
  await queryAndIgnoreError(createTableSql)
  await Promise.all([queryAndIgnoreError(index1), queryAndIgnoreError(index2), queryAndIgnoreError(index3)])
}
