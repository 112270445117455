import { PlusCircleOutlined, SyncOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import { Button } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import { useCursorValue, useLoadingState } from '../../../recoil/records/hooks'
import { useAddEmptyRecordMutation } from '../../../recoil/records/mutations/addEmptyRecordMutation'
import { useRefetchMutation } from '../../../recoil/records/mutations/useRefetchMutation'
import { useMeValue } from '../../../recoil/view/hooks'
import { useRowsValue } from '../../recoil/models/propModels'
import { useCursorMutation } from '../../recoil/mutations/useCursorMutation'
import { useColumnsRelatedSelector } from '../../recoil/selectors/columnRelatedSelector'
import { ROW_HEIGHT } from '../body/const'

export function RSheetsFooterRow() {
  const me = useMeValue()
  const recordsMutation = useRefetchMutation()
  const { ref, inView } = useInView({ threshold: 0 })
  const cursor = useCursorValue()
  const loading = useLoadingState()
  const cursorMutation = useCursorMutation()
  const { addRootRecord } = useAddEmptyRecordMutation()
  const rows = useRowsValue() // TODO: onClick相当をmutationにしたい。
  const { widthsSum } = useColumnsRelatedSelector()

  // footerがビューに入ったら追加読み込み
  useEffect(() => {
    if (inView) {
      void recordsMutation.loadNext()
    }
  }, [inView, rows.length])

  // TODO: ここで定義するか？
  const isLoadable = isSome(cursor)

  // inViewのロジックは引き続き使うが、UIコンポーネントは使わない
  if (me.organization.isEarlyReleaseTarget) {
    if (isLoadable) {
      // TODO: style
      return (
        <div
          ref={ref}
          style={{
            width: widthsSum,
          }}
          onClick={() => {
            if (loading.isFalse) {
              void recordsMutation.loadNext()
            }
          }}
          data-e2e="rsheets-footer-row"
        >
          <Button
            loading={loading.isTrue}
            icon={<SyncOutlined />}
            type="text"
            disabled={loading.isTrue}
            style={{
              color: '#555',
            }}
          >
            {t(`さらに読み込み`)}
          </Button>
        </div>
      )
    }
    return <div className="not-loadable" />
  }

  return (
    <>
      <div
        onClick={async () => {
          await addRootRecord({ rowIndex: rows.length - 1 })
          cursorMutation.moveToRowIndexAndEditMode(rows.length)
        }}
        style={{
          backgroundColor: 'white',
          cursor: 'pointer',
          zIndex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: ROW_HEIGHT,
          width: '100vw',
          border: `1px #EDF1F2 solid`,
        }}
      >
        {isLoadable && (
          <div
            ref={ref}
            onClick={() => {
              if (loading.isFalse) {
                void recordsMutation.loadNext()
              }
            }}
          >
            <Button
              loading={loading.isTrue}
              icon={<SyncOutlined />}
              type="text"
              disabled={loading.isTrue}
              style={{
                color: '#555',
              }}
            >
              {t(`さらに読み込み`)}
            </Button>
          </div>
        )}
        <div
          style={{
            left: 20,
            position: 'absolute',
            color: '#999',
          }}
        >
          <PlusCircleOutlined /> {t(`追加`)}
        </div>
      </div>
    </>
  )
}
