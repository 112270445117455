// TODO

import { selector, useRecoilValue } from 'recoil'

import { sheetUiComponentSelector } from '../../../recoil/view/selectors/uiSelecotr'
import { rowConfigAtomFamily } from '../atoms'
import { rowsModel } from '../models/propModels'

export const rowsRelatedSelector = selector({
  key: 'rowsRelatedSelector',
  get: ({ get }) => {
    const rows = get(rowsModel)
    const sheet = get(sheetUiComponentSelector)
    const visibleRowNumber = sheet?.visibleRowNum // 表示する子レコード数の上限
    // const columns = get(columnsModel)

    const rowHeights = rows.map((row) => {
      if (visibleRowNumber === undefined) {
        return row.meta.height
      }
      const config = get(rowConfigAtomFamily({ id: row.id! }))
      if (config?.expanded === true) {
        return row.meta.height
      }
      return Math.min(row.meta.height, visibleRowNumber)
    })

    const maxRowIndex = rows.length - 1

    return {
      // heights,
      maxRowIndex,
      rowHeights,
    }
  },
})

export const useRowsRelatedSelector = () => useRecoilValue(rowsRelatedSelector)
