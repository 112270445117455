import { isNull } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import { DelayWrapper } from '@salescore/client-common'
import { type CSSProperties, useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

import { DurationTimerEnd } from '../../components/misc/DurationTimer'
import { ViewLoading } from '../../components/ViewLoading'
import { CLIENT_VIEW_CONSTANT } from '../../constants/constants'
import { useCursorValue, useLoadingState, useViewQueryResult } from '../../recoil/records/hooks'
import { loadingProgressAtom } from '../../recoil/view/atoms'
import { useViewValue } from '../../recoil/view/hooks'
import { contextAtom } from '../recoil/atoms'
import { useInitializeColumns, useRootRefValue as useRootReferenceValue } from '../recoil/models/propModels'
import { useAsyncHandleKeyDown } from '../recoil/mutations/useAsyncHandleKeyDown'
import { useHandleKeyDownMutation } from '../recoil/mutations/useHandleKeyDown'
import { useColumnsRelatedSelector } from '../recoil/selectors/columnRelatedSelector'
import type { RSheetColumn, RSheetContext, RSheetRecordNode } from '../types'
import { RSheetsStyle } from '../util/RSheetsStyle'
import { RSheetBody } from './body/RSheetBody'
import { RSheetsCursorBox } from './cursor/RSheetsCursorBox'
import { RSheetsFixedFooterRow } from './footer/RSheetsFixedFooterRow'
import { RSheetsFooterRow } from './footer/RSheetsFooterRow'
import { RSheetsHeaderRowMemo } from './header/RSheetsHeaderRow'

interface RSheetArgument {
  height: CSSProperties['height']
  columns: Array<RSheetColumn<RSheetRecordNode>>
  context?: RSheetContext
}

//
// RecordNodeを表示するためのSheetコンポーネント。略してRSheet。
// 普通の「シート」は行列（＝二次元配列）を表示するものだが、
// RSheetはRecordNode（＝ツリー）の配列を表示する。
//
export function RSheet({ height, columns, context }: RSheetArgument) {
  const view = useViewValue()
  const { isLoading } = useRSheetLoading({ context })
  const setLoadingProgress = useSetRecoilState(loadingProgressAtom)

  useEffect(() => {
    if (!isLoading) {
      setLoadingProgress((progress) => ({
        ...progress,
        completed: true,
      }))
    }
  }, [isLoading])

  if (isLoading) {
    return <ViewLoading context={{ view }} />
  }

  return (
    <DelayWrapper delay={CLIENT_VIEW_CONSTANT.RENDER_DELAY_AFTER_LOAD} fallback={<ViewLoading context={{ view }} />}>
      {/* ローディング画面で読み込みが完了したことを通知してからViewを表示するために、Bodyの描画タイミングを読み込み完了からごく短時間遅らせている */}
      <Body height={height} columns={columns} context={context} />
    </DelayWrapper>
  )
}

function Body({ height, columns, context }: RSheetArgument) {
  const rootReference = useRootReferenceValue()
  const setContext = useSetRecoilState(contextAtom)
  const columnRelated = useColumnsRelatedSelector()
  const handleKeyDown = useHandleKeyDownMutation()
  const asyncHandleKeyDown = useAsyncHandleKeyDown()

  useInitializeColumns(columns)
  useEffect(() => {
    setContext(context ?? {})
  }, [context])

  return (
    // テーブル全体
    <div
      className="rsheets"
      ref={rootReference}
      style={{
        outline: 'none',
        width: '100%',
        height,
        overflow: 'scroll',
        position: 'relative',
        // overflow: hidden;
        overscrollBehavior: 'none',
        backgroundColor: CONSTANT.colors.backgroundGray,
      }}
      tabIndex={0}
      onKeyDown={async (event) => {
        handleKeyDown(event)
        await asyncHandleKeyDown(event)
      }}
      // NOTE: focusがあたるとき=どこかのセルをクリックしたときのはずで、セル側でカーソルの設定を行うため、onFocusで何か発火する必要はない
      // onFocus={() => {
      // }}
      // onBlur={() => {
      // if (cursor?.isEditing === true) {
      // TODO: 編集中の内容を保存した上で編集を終えたいが、子コンポーネントにこのロジックがあるせいで厳しい。
      //       グローバルなstateに編集状態を保持した方が良い。
      // TODO: onBlurが編集開始時にも発火していたので、結局disabled
      // finishEditMode()
      // } else {
      // TODO: onBlurだと違うタブに一時的に移動したときなども発火してしまうため、いったんやめた。他のエリアのonClickイベントを明示的に発火させるしかない？
      // setCursor(undefined)
      // }
      // }}
    >
      <div
        style={{
          position: 'sticky',
          left: 0,
          zIndex: RSheetsStyle.zIndex.cursorWhenLeftFixed,
        }}
      >
        <RSheetsCursorBox asFixed={true} />
      </div>

      {/* テーブル本体 */}
      <div
        style={{
          position: 'relative',
          width: columnRelated.widthsSum,
          display: 'flex',
          flexFlow: 'wrap',
        }}
      >
        <RSheetsHeaderRowMemo leftFixed={true} />
        <RSheetsHeaderRowMemo leftFixed={false} />

        <RSheetBody leftFixed={true} />
        <RSheetBody leftFixed={false} />
        {/* TODO: footerの機能をonAddRow依存にするのかなど、仕様が定まっていないため設計も中途半端になっている */}
        <div className="sticky bottom-0 flex" style={{ zIndex: RSheetsStyle.zIndex.fixedFooterRowWhenLeftFixed }}>
          {context?.asKpiTable !== true && (
            <>
              <RSheetsFixedFooterRow leftFixed={true} />
              <RSheetsFixedFooterRow leftFixed={false} />
            </>
          )}
        </div>
        <RSheetsFooterRow />

        <RSheetsCursorBox asFixed={false} />
      </div>

      {/* {loading === true && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            opacity: 0.4,
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: RSheetsStyle.zIndex.bodyLoadingSpinner,
          }}
        >
          <Spin size="large" />
        </div>
        // <div
        //   style={{
        //     position: 'absolute',
        //     backgroundColor: 'white',
        //     top: 0,
        //     width: "100vw",
        //     zIndex: 100,
        //     opacity: 0.3
        //   }}
        // >
        //   <Table loading={true} />
        // </div>
      )} */}
      <DurationTimerEnd />
    </div>
  )
}

function useRSheetLoading({ context }: { context?: RSheetContext }) {
  const queryResult = useViewQueryResult()
  // TODO: ローディング周りのロジックが煩雑になってしまっているので改善
  // loadingは別で表示するので、ここのロジックにいれないこと
  const loadingState = useLoadingState()
  const cursor = useCursorValue()
  // sheetの場合は、新規読み込みの時のみローディング表示を行う(=cursorもresultもないとき)
  const loadingForSheet = loadingState.isTrue && isNull(cursor) && isNull(queryResult)
  const loadingForKpi = loadingState.isTrue || isNull(queryResult) // TODO: 現在のロジックだと集計軸を変えた際にチラつきが発生しやすい。。parameterを使わないようにする？
  const isLoading = context?.asKpiTable === true ? loadingForKpi : loadingForSheet

  return { isLoading }
}
