import { mutation } from '@salescore/frontend-common'
import { useSetRecoilState } from 'recoil'

import { listQueryAtom } from '../../view/atoms'
import { modelSearcherSelector } from '../../view/selectors/modelSearcherSelector'
import { changesAtom, recordIdsAtom, recordsAtom } from '../atoms'
import { addNewChildRecordByPath, type NewRecordChangeByPath } from './addNewRecord/addNewChildRecordByPath'
import { deleteRecordByPath, type DeleteRecordChangeByPath } from './addNewRecord/deleteRecordByPath'
import { type RecordChangeByPath, upsertViewRecordByPath } from './upsertViewRecordByPath'

interface Argument {
  newRecordChanges?: NewRecordChangeByPath[]
  recordChanges?: RecordChangeByPath[]
  deleteRecordChanges?: DeleteRecordChangeByPath[]
  // relationChanges?: RelationChange[]
  // onAfterInsert?: OnAfterInsert
  // onAfterChange?: OnAfterChange
}

// upsertViewRecordsを実行し、その結果をsetするmutation
const upsertViewRecordsByPathMutation = mutation<Argument>({
  key: `rsheet/upsertViewRecordsByPathMutation`,
  set({ get, set }, { recordChanges, newRecordChanges, deleteRecordChanges }) {
    const query = get(listQueryAtom)
    const records = get(recordsAtom)
    const recordIds = get(recordIdsAtom)
    const modelSearcher = get(modelSearcherSelector)

    // レコードをcloneして参照渡しで更新していく
    // このような形にしないと、前の関数での変更が次の関数に伝わらない。また、関数の中でsetする際にレコードを普通に渡すとfreezeされるので、cloneして渡すこと。
    const newRecords = structuredClone(records)
    // recordIdsについても同じく。（関数の中でset(oldValue => {})の関数を複数回使っても、oldValueは初期値のままなので注意）
    const newRecordIds = [...recordIds]

    const newChanges =
      newRecordChanges?.map((x) => addNewChildRecordByPath(query, newRecords.first()!, x, set)).compact() ?? []
    if (newRecordIds.length !== recordIds.length) {
      set(recordIdsAtom, newRecordIds)
    }

    const upsertedChanges = upsertViewRecordByPath({
      record: newRecords.first()!,
      view: query,
      recordChanges: [...newChanges.flatMap((x) => x.recordChangesForDefault), ...(recordChanges ?? [])],
      set,
    })
    // updateRecordNodeRelations(newRecords, relationChanges ?? [], set)

    const deleteChanges =
      deleteRecordChanges
        ?.flatMap((x) => deleteRecordByPath(query, newRecords.first()!, x, modelSearcher, set))
        .compact() ?? []

    //
    // changesを反映させる
    //
    const changes = [...newChanges.map((x) => x.change), ...upsertedChanges, ...deleteChanges].compact()
    if (changes.isPresent()) {
      const newChange = {
        datetime: new Date().toISOString(),
        rowIndices: [0], // TODO: 結局使ってないので廃止
        snapshot: JSON.stringify(records), // snapshotは以前のものをいれる
        snapshotAfterChange: JSON.stringify(newRecords),
        changes,
      }
      set(changesAtom, (oldChanges) => [...oldChanges, newChange])
    }
    // if (onAfterChange !== undefined) {
    //   onAfterChange(changes)
    // }
  },
})

export const useUpsertViewRecordsByPathMutation = () => useSetRecoilState(upsertViewRecordsByPathMutation)
