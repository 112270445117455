import { useSalescoreAuth } from '@salescore/client-base'
import { logger, useRedirect } from '@salescore/frontend-common'
import { useEffect } from 'react'

//
// Auth0のJWTではなく、SALESCOREのJWTを用いてログインする
//
export const SignInBySalescoreToken = ({ salescoreToken }: { salescoreToken: string }) => {
  const { setToken } = useSalescoreAuth()
  const redirect = useRedirect()

  useEffect(() => {
    setToken(salescoreToken)
    redirect('/')
  }, [])

  logger.debug(`SignInBySalesforceSession`, {
    salescoreToken,
  })

  return <></>
}
