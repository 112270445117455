import { isNull } from '@salescore/buff-common'

import type { User } from '../user/types'
import type { UserGroup } from '../user_group/types'
import { GOAL_DIMENSION_EMPTY_VALUE } from './constant'
import { getUserOrUserGroupIds } from './getUserOrUserGroupIds'
import type { Goal, GoalConfig } from './types'

export async function deleteInvalidGoals({
  goalConfig,
  users,
  userGroups,
  deleteMany,
  deleteManyQuery,
}: {
  goalConfig: GoalConfig
  users: User[]
  userGroups: UserGroup[]
  deleteMany: (
    condition:
      | Partial<Goal>
      | { user_id: { notIn: string[] } }
      | { dimension1: { not: string } }
      | { dimension2: { not: string } }
      | { dimension3: { not: string } }
      | { dimension4: { not: string } }
      | { dimension5: { not: string } }
      | { dimension1: { notIn: string[] } }
      | { dimension2: { notIn: string[] } }
      | { dimension3: { notIn: string[] } }
      | { dimension4: { notIn: string[] } }
      | { dimension5: { notIn: string[] } },
  ) => Promise<void>
  deleteManyQuery: (condition: string) => Promise<void>
}) {
  //
  // dimension設定していないのに、dimensionに値が入っていたら削除
  // （configでdimensionの削除をしたときの処理はまた別で行っている）
  //
  // eslint-disable-next-line unicorn/prefer-ternary
  if (isNull(goalConfig.goalDimension1)) {
    await deleteMany({
      dimension1: {
        not: GOAL_DIMENSION_EMPTY_VALUE,
      },
    })
  } else {
    await deleteMany({
      dimension1: {
        // dimensionが指定されているとき、GOAL_DIMENSION_EMPTY_VALUEはありえないので本来は削除したいが、移行時のみこれが存在しているので残す
        notIn: [...goalConfig.goalDimension1.selectOptionsV3.map((x) => x.value), GOAL_DIMENSION_EMPTY_VALUE],
      },
    })
  }
  await (isNull(goalConfig.goalDimension2)
    ? deleteMany({
        dimension2: {
          not: GOAL_DIMENSION_EMPTY_VALUE,
        },
      })
    : deleteMany({
        dimension2: {
          notIn: [...goalConfig.goalDimension2.selectOptionsV3.map((x) => x.value), GOAL_DIMENSION_EMPTY_VALUE],
        },
      }))
  await (isNull(goalConfig.goalDimension3)
    ? deleteMany({
        dimension3: {
          not: GOAL_DIMENSION_EMPTY_VALUE,
        },
      })
    : deleteMany({
        dimension3: {
          notIn: [...goalConfig.goalDimension3.selectOptionsV3.map((x) => x.value), GOAL_DIMENSION_EMPTY_VALUE],
        },
      }))
  await (isNull(goalConfig.goalDimension4)
    ? deleteMany({
        dimension4: {
          not: GOAL_DIMENSION_EMPTY_VALUE,
        },
      })
    : deleteMany({
        dimension4: {
          notIn: [...goalConfig.goalDimension4.selectOptionsV3.map((x) => x.value), GOAL_DIMENSION_EMPTY_VALUE],
        },
      }))
  await (isNull(goalConfig.goalDimension5)
    ? deleteMany({
        dimension5: {
          not: GOAL_DIMENSION_EMPTY_VALUE,
        },
      })
    : deleteMany({
        dimension5: {
          notIn: [...goalConfig.goalDimension5.selectOptionsV3.map((x) => x.value), GOAL_DIMENSION_EMPTY_VALUE],
        },
      }))

  // dateがtimeSpanに一致した値になっていなければ削除
  const timeSpanType = goalConfig.timeSpanType
  switch (timeSpanType) {
    case 'month': {
      await deleteManyQuery(`"date"::text != DATE_TRUNC('month', "date")::date::text`)
      break
    }
    case 'week': {
      await deleteManyQuery(`"date"::text != DATE_TRUNC('week', "date")::date::text`) // TODO: 月曜日始まり？
      break
    }
    case 'day': {
      // nothing
      break
    }
    case 'none': {
      // TODO: 仕様検討
      break
    }
    default: {
      const x: never = timeSpanType
    }
  }

  // 存在しないユーザーの目標を削除
  // 一時的に誤って外した可能性を考慮して、空レコード(初期表示用のレコード=kpi_idが空の値のレコード)のみ削除する
  const userOrUserGroupIds = getUserOrUserGroupIds({ goalConfig, users, userGroups })
  await deleteMany({
    user_id: { notIn: [...userOrUserGroupIds, GOAL_DIMENSION_EMPTY_VALUE] },
    kpi_id: GOAL_DIMENSION_EMPTY_VALUE,
  })

  // 存在しないKPIは許可する（一時的に誤って外しただけの可能性があるので）
}
