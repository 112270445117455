import { t } from 'i18next'
import { selector } from 'recoil'

import { feedbackMessagesAtom } from '../../../recoil/records/atoms'
import { viewAbilityAtom } from '../../../recoil/view/atoms'
import type { RSheetsCursor } from '../../types/CursorTypes'
import { cursorAtom } from '../atoms'
import { columnsModel, rowsModel } from './propModels'

export const cursorModel = selector<RSheetsCursor | undefined>({
  key: 'cursorModel',
  get: ({ get }) => {
    const cursor = get(cursorAtom)
    if (cursor === undefined) {
      return
    }

    const rows = get(rowsModel)
    const columns = get(columnsModel)
    const row = rows[cursor.main.rowIndex]
    const column = columns[cursor.main.columnIndex]

    // 指し示すrow,columnがなければ、カーソルはundefinedとする
    if (row === undefined || column === undefined) {
      return
    }

    return cursor
  },
  set: ({ get, set }, newValue) => {
    const ability = get(viewAbilityAtom)
    const newCursor = newValue as RSheetsCursor | undefined
    if (!ability.canSaveRecord && newCursor?.editing?.isEditing === true) {
      set(feedbackMessagesAtom, [
        {
          message: t(`このシートのレコードを編集する権限がありません`),
          type: 'warn' as const,
        },
      ])
      return
    }
    if (newCursor !== undefined && newCursor.editing?.isEditing === true && newCursor.copying?.visible === true) {
      // isEditingがtrueになった時、copying.visibleを強制的にfalseにする
      set(cursorAtom, {
        ...newCursor,
        copying: {
          ...newCursor.copying,
          visible: false,
        },
      })
    } else {
      set(cursorAtom, newValue)
    }
  },
})
