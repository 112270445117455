import { treeUtil } from '@salescore/buff-common'

import { isSameNodeProperty } from '../functions/view/compileViewConfig/sheet/isSameNodeProperty'
import type { CoreModel } from '../schemas/model/model'
import type {
  NodePropertyName,
  ViewConfigFilterNode,
  ViewConfigSheet,
  ViewConfigTreeNode,
} from '../schemas/view_config'
import {
  addViewConfigChildNode,
  type ReferenceToPropertyForAddChildNodeMutation,
} from './view_config/addViewConfigChildNode'

export class ViewConfigSheetValueObject {
  public readonly config: ViewConfigSheet

  public constructor(config: ViewConfigSheet) {
    this.config = config
  }

  public get fields() {
    return this.config.fields ?? []
  }

  public get filterTree(): ViewConfigFilterNode {
    return (
      this.config.filterTree ?? {
        logicalOperator: `and`,
        leafs: [],
        children: [],
      }
    )
  }

  public get sorters() {
    return this.config.sorters ?? []
  }

  public set(config: Partial<ViewConfigSheet>) {
    // TODO: cloneする？
    const newConfig = {
      ...this.config,
      ...config,
    }
    return new ViewConfigSheetValueObject(newConfig)
  }

  public addViewConfigChildNode(
    node: ViewConfigTreeNode, // 追加したいノード
    referenceToProperty: ReferenceToPropertyForAddChildNodeMutation, // 追加で使うreferenceTo
  ) {
    if (this.config.tree === undefined) {
      return {
        success: false as const,
        error: `treeが設定されていません`,
      }
    }
    const result = addViewConfigChildNode(this.config.tree, node, referenceToProperty)
    if (result.tree === undefined) {
      return {
        success: true as const,
        data: this,
        newNode: result.newNode,
      }
    }

    return {
      success: true as const,
      data: this.set({ tree: result.tree }),
      newNode: result.newNode,
    }
  }

  public removeChildNode(nodeName: string) {
    const tree = this.config.tree
    if (tree === undefined) {
      return this
    }

    return this.set({
      ...this.config,
      tree: treeUtil.remove(tree, nodeName),
      fields: this.fields.filter((x) => x.property.nodeName !== nodeName),
      filterTree: treeUtil.exec(this.filterTree, (x) => ({
        ...x,
        leafs: x.leafs.filter((leaf) => leaf.type !== 'property' || leaf.property.nodeName !== nodeName),
      })),
      sorters: this.sorters.filter((x) => x.property.nodeName !== nodeName),
    })
  }

  public addDefaultPropertyFields(
    node: ViewConfigTreeNode,
    model: CoreModel,
    option?: { index?: number; toLast?: boolean },
  ) {
    return [model.properties.find((x) => x.meta === 'name'), model.properties.find((x) => x.meta === 'record_url')]
      .compact()
      .reduce<ViewConfigSheetValueObject>(
        (accumulator, property) =>
          accumulator.addPropertyField(
            { nodeName: node.name, modelName: model.name, propertyName: property.name },
            option,
          ),
        this,
      )
  }

  public addAllPropertyFields(
    node: ViewConfigTreeNode,
    model: CoreModel,
    option?: { index?: number; toLast?: boolean },
  ) {
    return model.properties
      .compact()
      .reduce<ViewConfigSheetValueObject>(
        (accumulator, property) =>
          accumulator.addPropertyField(
            { nodeName: node.name, modelName: model.name, propertyName: property.name },
            option,
          ),
        this,
      )
  }

  public addPropertyField(property: NodePropertyName, option?: { index?: number; toLast?: boolean }) {
    // 既にあれば何もしない
    if (this.hasPropertyField(property)) {
      // TODO: これはどういう扱いにするか？
      return this
    }

    const index = option?.toLast === true ? this.fields.length : (option?.index ?? 0)

    return this.set({
      fields: [
        ...this.fields.slice(0, index),
        {
          type: `property`,
          property,
        },
        ...this.fields.slice(index),
      ],
    })
  }

  public removePropertyField(property: NodePropertyName) {
    // TODO: validation
    return this.set({
      fields: this.fields.filter((x) => !isSameNodeProperty(x.property, property)),
    })
  }

  public togglePropertyField(property: NodePropertyName) {
    if (this.hasPropertyField(property)) {
      return this.removePropertyField(property)
    }
    return this.addPropertyField(property, { toLast: true })
  }

  public hasPropertyField(property: NodePropertyName) {
    return this.fields.map((x) => x.property).some((x) => isSameNodeProperty(x, property))
  }
}
