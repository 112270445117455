import type { PolicyAction } from '../action'
import { necPlayingManagerRolePolicyDefinition } from './necPlayingManagerRolePolicyDefinition'

// NEC様向け一般ユーザー：「目標設定ができない現場マネージャー」のロール。
// XXX: 各種ロールは定義の簡素化のため上位ロールを継承している。
//      権限を変更したら下位ロールにも影響する可能性があるため、継承関係を意識して変更すること
export const necMemberRolePolicyDefinition: Record<PolicyAction, boolean> = {
  ...necPlayingManagerRolePolicyDefinition,
  [`manage-goal`]: false,
}
