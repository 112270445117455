import { ButtonWithTooltip } from '@salescore/frontend-common'
import { t } from 'i18next'
import type { ReactNode } from 'react'

export interface ViewUIRiFormSaveButtonProperties {
  loading: boolean
  disabled: boolean
  errorMessage: string
  onClick: () => void
}

export function ViewUIRiFormSaveButton(properties: ViewUIRiFormSaveButtonProperties): ReactNode {
  return (
    <ButtonWithTooltip
      type="primary"
      onClick={properties.onClick}
      loading={properties.loading}
      disabled={properties.disabled}
      tooltipTitle={properties.errorMessage}
    >
      {t(`保存`)}
    </ButtonWithTooltip>
  )
}
