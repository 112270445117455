import type { ApolloClient } from '@apollo/client'
import { notifyBugsnag } from '@salescore/client-base'
import type { ViewQueryList, ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import { logger } from '@salescore/frontend-common'
import type { Dispatch, SetStateAction } from 'react'

import { updateHeight, updateInnerRow } from '../../recoil/records/mutations/upsertViewRecords'
import { getInnerRecordNode, getNode, getRecordNodeFromRootNode } from '../nodeUtil'
import type { ChangeHistory, ViewRecordFieldChange } from './useChangesState'

//
// TODO: mutationに移行したい
//
export const recordsAction = ({
  records,
  query,
  client,
  setRecords,
  addChange,
}: {
  records: ViewQueryRecordNode[]
  query: ViewQueryList
  client: ApolloClient<unknown>
  setRecords: Dispatch<SetStateAction<ViewQueryRecordNode[]>>
  addChange: (x: ChangeHistory) => void
}) => ({
  removeRelation({
    // TODO: 共通化。この行の上にコメントすると謎のエラーになるので注意
    rowIndex,
    innerRowIndex,
    node,
  }: {
    rowIndex: number
    innerRowIndex: number
    node: ViewQueryNode
  }) {
    if (node.read.join?.relation !== 'many_to_one') {
      return
    }
    const changes: ViewRecordFieldChange[] = []
    const parentNode = getNode(query.tree, node.path.slice(0, -1))
    if (parentNode === undefined) {
      throw new Error(`parentNode not found`)
    }

    const joinOn = node.read.join.joinOn
    if (joinOn === undefined) {
      throw new Error(`parentNodeColumnName undefined`)
    }
    if (joinOn.type === 'sql') {
      // i18n: エラー系は一旦保留
      throw new Error(`カスタムSQLによるJOINを定義している場合、このアクションは実行できません`)
    }
    if (joinOn.parentNodeColumnName === 'id') {
      throw new Error(`parentNodeColumnName must not be id`)
    }
    const parentReferenceField = query.fields.find((field) => {
      const x = field.meta.dependedPropertyNamesWithStreamName.first()
      if (x === undefined) {
        return false
      }
      return x.streamName === joinOn.meta.parentNodeStreamName && x.propertyName === joinOn.meta.parentNodePropertyName
    })

    let newRecordsCopy: ViewQueryRecordNode[] = []

    setRecords((oldRecords) => {
      if (oldRecords === undefined) {
        return oldRecords
      }

      const newRecords = structuredClone(oldRecords)

      // ルートのレコード(行)を取得
      const rootRecordNode = newRecords[rowIndex]!
      // 親レコードを取得
      const parentRecordNodes = getRecordNodeFromRootNode(rootRecordNode, node.path.slice(0, -1))
      const parentRecordNode = Array.isArray(parentRecordNodes)
        ? getInnerRecordNode(parentRecordNodes, innerRowIndex)
        : parentRecordNodes
      if (parentRecordNode === undefined) {
        logger.debug(`parentRecordNode not found`)
        notifyBugsnag({ error: new Error(`parentRecordNode not found in removeRelation. ${node.path.join(',')}`) })
        return oldRecords
      }

      //
      // 親要素に参照フィールドがあれば削除
      //
      if (parentReferenceField !== undefined) {
        parentRecordNode.attributes[parentReferenceField.name] = undefined
        parentRecordNode.attributes[`${parentReferenceField.name}_label`] = undefined
      }

      //
      // 子要素から指定した値を削除
      //
      const childRecordNodes = getRecordNodeFromRootNode(rootRecordNode, node.path)
      const childRecordNode = Array.isArray(childRecordNodes)
        ? getInnerRecordNode(childRecordNodes, innerRowIndex)
        : undefined
      const targetTableNode = parentRecordNode.children.find((x) => x.nodeName === node.name)
      if (targetTableNode !== undefined && childRecordNode !== undefined) {
        const index = targetTableNode.children.findIndex((x) => x.id === childRecordNode.id)
        if (index !== -1) {
          targetTableNode.children.splice(index, 1)
        }
      }

      //
      // 差分の更新
      //
      const parentReferenceFieldValue =
        parentReferenceField === undefined ? undefined : parentRecordNode.attributes[parentReferenceField.name]

      changes.push({
        id: parentRecordNode.id,
        streamName: parentNode.write!.streamName,
        fieldChanges: [], // TODO: referenceの削除によるcallbackは一旦無視
        // TODO: ここのchangesのロジックだけpropertySourceNameではなくpropertyNameになっている。後者に統一したい。
        before: {
          [joinOn.meta.parentNodePropertyName]: parentReferenceFieldValue ?? childRecordNode?.id ?? null, // どちらもundefinedなことはありえないはず
        },
        after: {
          [joinOn.meta.parentNodePropertyName]: null,
        },
      })

      // heightを参照渡しで更新
      updateHeight(rootRecordNode)
      // innerStartIndexを参照渡しで更新
      updateInnerRow(rootRecordNode)

      newRecordsCopy = newRecords
      return newRecords
    })

    // changesは親要素の参照項目のみ
    addChange({
      datetime: new Date().toISOString(),
      rowIndices: [0], // TODO: 結局使ってないので廃止
      snapshot: JSON.stringify(records),
      changes,
      snapshotAfterChange: JSON.stringify(newRecordsCopy),
    })
  },
})
