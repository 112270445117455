import { useMutation, useQuery } from '@apollo/client'
import { faMessageLines } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isSome } from '@salescore/buff-common'
import { CreateSheetThreadCommentDocument, SheetThreadCommentNotificationsDocument } from '@salescore/client-api'
import { recoil } from '@salescore/client-recoil'
import { CORE_CONSTANT, type FieldType } from '@salescore/core'
import { organizationHasFeature } from '@salescore/frontend-common'
import { Badge, Divider, Form, List, Popover, Tooltip } from 'antd'
import { type KeyboardEventHandler, type PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import { useKpiPivotParameter, useSheetPickedPresetName } from '../../recoil/navigation/hooks'
import { additionalConfigAtom } from '../../recoil/records/atoms'
import { useSheetThread } from '../../recoil/records/mutations/useSheetThreads'
import { alreadyScrolledToCommentAtom, sharedUrlAtom } from '../../recoil/view/atoms'
import { useViewConfigSheet, useViewsContextValue, useViewValue } from '../../recoil/view/hooks'
import { useViewSelector } from '../../recoil/view/selectors/viewSelector'
import { openSheetThreadsFormAtom } from '../../rsheet/recoil/atoms'
import { openSheetThreadMutation } from '../../rsheet/recoil/mutations/openSheetThreadMutation'
import { detectSharedUrlSettings } from '../view_ui/common'
import { CommentForm } from './CommentForm'
import { fieldTypeSchema, SheetThreadForm } from './SheetThreadForm'

export function SheetThreadsFormPopoverIfAllowed({
  recordId,
  recordName,
  children,
}: PropsWithChildren<{
  recordId: string | undefined
  recordName: string | undefined
}>) {
  const view = useViewValue()
  const { backgroundViewId } = useViewSelector()
  const me = recoil.global.useMe()
  const organizationId = me.organization.id
  const config = useViewConfigSheet()
  const sharedUrl = useRecoilValue(sharedUrlAtom)
  const [openSheetThreadsForm, setOpenSheetThreadsForm] = useRecoilState(openSheetThreadsFormAtom)
  const setOpenSheetThread = useSetRecoilState(openSheetThreadMutation)
  const { sheetThreadsOfRecord, fetchAndUpdateSheetThread } = useSheetThread(recordId)
  const sheetThreadCommentNotificationsQueryData = useQuery(SheetThreadCommentNotificationsDocument, {
    variables: {
      organizationId,
    },
    // サイダーでフェッチしているため、ビュー内ではそのキャッシュを使う
    // TODO: より細かいキャッシュの制御が必要な場合は、fetchPolicy を変更する
    fetchPolicy: 'cache-first',
  })
  const sheetThreadCommentNotifications =
    sheetThreadCommentNotificationsQueryData.data?.sheetThreadCommentNotifications ?? []
  const [hoveredOnChildren, setHoveredOnChildren] = useState(false)
  const views = useViewsContextValue()
  const [kpiPivotParameter] = useKpiPivotParameter()
  const { kpiPivotPresetName } = useViewsContextValue()
  const [sheetPresetName] = useSheetPickedPresetName()
  const additionalConfig = useRecoilValue(additionalConfigAtom)
  const [createSheetThreadComment] = useMutation(CreateSheetThreadCommentDocument)
  const [form] = Form.useForm()
  const commentReferences = useRef<Map<string, HTMLElement>>(new Map())
  const [alreadyScrolledToComment, setAlreadyScrolledToComment] = useRecoilState(alreadyScrolledToCommentAtom)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const isSharedUrlSettings = detectSharedUrlSettings(config, sharedUrl)
  useEffect(() => {
    const sharedUrlSheetThreadComment = sharedUrl?.sheetThreadComment
    if (isSharedUrlSettings && isSome(sharedUrlSheetThreadComment) && !alreadyScrolledToComment) {
      const commentListItem = commentReferences.current.get(sharedUrlSheetThreadComment.id)
      if (isSome(commentListItem)) {
        commentListItem.scrollIntoView({ behavior: 'smooth', block: 'start' })
        setAlreadyScrolledToComment(true)
      }
    }
  }, [isSharedUrlSettings, sharedUrl, commentReferences.current.size])

  const hasThreads = sheetThreadsOfRecord.length > 0

  const hasNotReadComments =
    recordId !== undefined &&
    sheetThreadCommentNotifications
      .filter((n) => n.sheetThreadComment.sheetThread.recordId === recordId)
      .some((notification) => notification.status === 'notRead')
  const status = hasNotReadComments ? 'error' : 'default'
  const commentFeatureEnabled = organizationHasFeature(me.organization, 'enable_slack_comment')

  if (
    !commentFeatureEnabled ||
    recordId === undefined ||
    view.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_DRILL_DOWN_VIEW_ID_PREFIX) || // KPIプレビューの時
    view.id === '' || // 目標設定の時
    views.views.first()?.type === 'kpi' || // KPI タブ内の場合
    config.tree?.modelName === undefined
  ) {
    return <>{children}</>
  }

  function handleOpenChange(open: boolean): void {
    if (!open) {
      setOpenSheetThreadsForm(openSheetThreadsForm.filter((form) => form.recordId !== recordId))
    } else if (recordId !== undefined) {
      setOpenSheetThreadsForm([{ recordId }, ...openSheetThreadsForm.filter((form) => form.recordId !== recordId)])
    }
  }

  async function onFinish(fields: FieldType) {
    if (isSubmitting) {
      return
    }
    try {
      const { text } = fieldTypeSchema.parse(fields)
      if (backgroundViewId === undefined) {
        return
      }
      setIsSubmitting(true)
      if (!commentFeatureEnabled || recordId === undefined || config.tree?.modelName === undefined) {
        return null
      }
      await createSheetThreadComment({
        variables: {
          sheetThreadCommentCreateInput: {
            viewId: backgroundViewId,
            organizationId,
            threadId: undefined, // 新しいスレッドを作成する
            recordId,
            modelName: config.tree.modelName,
            recordName,
            message: text,
            sheetViewId: view.id,
            kpiPivotParameter,
            kpiPivotPresetName,
            sheetPresetName,
            additionalConfigFilterLeafs: additionalConfig.filterLeafs,
          },
        },
      })
    } catch {
      // TODO: エラー処理
    } finally {
      setIsSubmitting(false)
    }
    form.resetFields()
    await fetchAndUpdateSheetThread()
    setIsSubmitting(false)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLFormElement> = (event) => {
    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault()
      form.submit()
    }
  }

  return (
    <Popover
      placement="rightTop"
      content={
        <div className="max-h-96 w-96 overflow-y-auto">
          <List>
            {sheetThreadsOfRecord.map((sheetThread) => (
              <List.Item key={sheetThread.id} className="!border-none !py-0">
                <SheetThreadForm
                  commentRefs={commentReferences}
                  recordId={recordId}
                  recordName={recordName}
                  sheetThread={sheetThread}
                  updateDraftComment={(draftComment) => {
                    setOpenSheetThread({ threadId: sheetThread.id, draftComment, view })
                  }}
                  fetchAndUpdateSheetThread={fetchAndUpdateSheetThread}
                />
              </List.Item>
            ))}
          </List>
          <Divider className="my-1" />
          <Form
            form={form}
            onFinish={(values) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              void onFinish(values)
            }}
            onKeyDown={handleKeyDown}
          >
            <CommentForm
              isReply={false}
              isSmall={false}
              isDisabled={false}
              onClickCancelButton={() => {
                setOpenSheetThreadsForm(openSheetThreadsForm.filter((form) => form.recordId !== recordId))
              }}
            />
          </Form>
        </div>
      }
      onOpenChange={(open) => {
        handleOpenChange(open)
      }}
      open={openSheetThreadsForm.some((form) => form.recordId === recordId)}
      trigger={['click']}
    >
      <Tooltip
        placement="topLeft"
        title={
          sheetThreadsOfRecord.length > 0
            ? `${sheetThreadsOfRecord.reduce((sum, thread) => sum + thread.comments.length, 0)} 件のコメントがあります`
            : 'コメントする'
        }
        onOpenChange={(open) => {
          setHoveredOnChildren(open)
        }}
      >
        <Badge dot={hasThreads || hasNotReadComments} status={status} offset={[-2, -2]}>
          {hoveredOnChildren ? (
            <FontAwesomeIcon icon={faMessageLines} size="lg" className="-mt-1" />
          ) : (
            <div className="size-4">{children}</div>
          )}
        </Badge>
      </Tooltip>
    </Popover>
  )
}
