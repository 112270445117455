import { PageHeader } from '@ant-design/pro-layout'
import { SuspenseWithLoading } from '@salescore/client-common'
import { useModal } from '@salescore/frontend-common'
import { Button, Modal } from 'antd'
import { t } from 'i18next'

import { ReverseEltJobsTable } from './ReverseEltJobsTable'
import { ReverseEltsTable } from './ReverseEltsTable'
import { UpsertReverseEltForm } from './UpsertReverseEltForm'

export const ReverseEltSettingPage = () => {
  const createModal = useModal()

  return (
    <PageHeader
      title={t(`Reverse-ELT設定一覧`)}
      extra={[
        <Button
          type="primary"
          onClick={() => {
            createModal.showModal()
          }}
        >
          {t(`新規作成`)}
        </Button>,
      ]}
    >
      <SuspenseWithLoading>
        <ReverseEltsTable />
      </SuspenseWithLoading>
      <SuspenseWithLoading>
        <ReverseEltJobsTable />
      </SuspenseWithLoading>
      <Modal
        open={createModal.isModalVisible}
        onCancel={createModal.hideModal}
        width={'60%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
        destroyOnClose
      >
        <SuspenseWithLoading>
          <UpsertReverseEltForm
            onAfterFinish={() => {
              createModal.hideModal()
            }}
          />
        </SuspenseWithLoading>
      </Modal>
    </PageHeader>
  )
}
