import { isNull, isSome } from '@salescore/buff-common'
import { routes } from '@salescore/client-base'
import { useRouter } from 'next/router'

interface RedirectConfig {
  sourcePaths: string[]
  destinationPath: string
}

// v1 -> v2のリダイレクトパスを取得するためのhooks
// v1が完全に撤廃されアクセスがない事を確認後このhooksは削除する
export function useV1ToV2Redirect() {
  return {
    getRedirectPath,
  }
}

/**
 * パスがv1->v2のリダイレクト対象である場合に、リダイレクト先のパスを返す関数
 *
 * @param asPath クエリを含むパス
 */
function getRedirectPath(): string | undefined {
  const router = useRouter()

  const path = router.asPath.split('?')[0]
  if (isNull(path)) {
    return undefined
  }

  // リダイレクト先が明確にマッピングされている場合は、そのパスを返す
  const config = getRedirectConfig().find((config) => config.sourcePaths.includes(path))
  if (isSome(config)) {
    return config.destinationPath
  }

  // リダイレクト先が明確にマッピングされていなくても、v1のパスであればv2のトップページにリダイレクトする
  if (isV1Path(path)) {
    return routes.topPathV2()
  }
  return undefined
}

function getRedirectConfig(): RedirectConfig[] {
  return [
    {
      sourcePaths: [
        routes.topPathV1(),
        routes.myActionsPath(),
        routes.dashboardItemSettingsPath(),
        routes.kpisSettingsPath(),
        routes.sheetPath(),
      ],
      destinationPath: routes.topPathV2(),
    },
    {
      sourcePaths: [routes.mePathV1()],
      destinationPath: routes.mePathV2(),
    },
    {
      sourcePaths: [routes.settingsPathV1()],
      destinationPath: routes.settingsPathV2(),
    },
    {
      sourcePaths: [routes.goalsPathV1()],
      destinationPath: routes.goalConfigsPathV2(),
    },
    {
      sourcePaths: [routes.invitedUsersSettingsPathV1()],
      destinationPath: routes.invitedUsersSettingsPathV2(),
    },
    {
      sourcePaths: [routes.sourceSettingsPathV1()],
      destinationPath: routes.sourceSettingsPathV2(),
    },
    {
      sourcePaths: [routes.userGroupsSettingsPathV1()],
      destinationPath: routes.userGroupsSettingsPathV2(),
    },
    {
      sourcePaths: [routes.usersSettingsPathV1()],
      destinationPath: routes.usersSettingsPathV2(),
    },
    {
      sourcePaths: [routes.userActivitiesPathV1()],
      destinationPath: routes.userActivitiesPathV2(),
    },
  ]
}

function isV1Path(path: string): boolean {
  // o/[organizationId]/sign_in はv1のパスとして扱わない
  return path.startsWith(routes.topPathV1()) && !path.startsWith(routes.topPathV2()) && !path.includes('/sign_in')
}
