import { isNull, treeUtil } from '@salescore/buff-common'

import type {
  ViewConfigFilter,
  ViewConfigFilterNode,
  ViewConfigKpi,
  ViewConfigSheet,
  ViewConfigTreeNode,
} from '../../../../schemas/view_config'
import type { CompileContext } from '../../common'
import { addDateFilter } from './addDateFilter'
import { addSalescoreUserField } from './addSalescoreUserFields'
import { addSalescoreUserTree } from './addSalescoreUserTree'

export function updateConfigByAdditionalConfig(configRaw: ViewConfigSheet, context: CompileContext): ViewConfigSheet {
  // kpi関連の値の準備
  const kpiRelated = getKpiRelatedConfig(context)

  // additionalConfigを適用する
  const additionalLeafs: ViewConfigFilter[] = [
    ...(context.additionalConfig?.filterLeafs ?? []).map((x) => x.leaf),
    ...(context.additionalConfig?.kpiParameter?.dimensionFilterLeafs ?? []),
  ].compact()
  const filterTreeWithAdditionalLeafs: ViewConfigFilterNode = additionalLeafs.isPresent()
    ? {
        logicalOperator: 'and',
        leafs: [],
        children: [
          configRaw.filterTree,
          {
            logicalOperator: 'and' as const,
            children: [],
            leafs: additionalLeafs,
          },
        ].compact(),
      }
    : (configRaw.filterTree ?? { logicalOperator: 'and', children: [], leafs: [] })
  const additionalFilterTrees = [
    // ...(context.additionalConfig?.filterTrees ?? []).map((x) => x.tree),
    // kpiでシートのreferenceを行い、かつkpi側での絞り込みをするような初期構想の仕様が戻るなら、以下のような処理が必要
    // kpiRelated?.measure.type === 'preset' ? kpiRelated.measure.filterTree : undefined,
  ].compact()
  const filterTreeWithAdditionalTrees: ViewConfigFilterNode = additionalFilterTrees.isPresent()
    ? {
        logicalOperator: `and`,
        children: [filterTreeWithAdditionalLeafs, ...additionalFilterTrees],
        leafs: [],
      }
    : filterTreeWithAdditionalLeafs
  const configWithFilter: ViewConfigSheet = {
    ...configRaw,
    filterTree: filterTreeWithAdditionalTrees,
  }

  //
  // previousがあり、それがkpiのとき（＝KPIからドリルダウンしてきたとき）は、userの紐付けやdateのフィルタを行う
  //
  if (kpiRelated === undefined) {
    return configWithFilter
  }

  // 紐づくSalescoreユーザー情報をツリーとフィールドに追加する
  // フィールドへの追加は、絞り込みのためにも必要なので、ロジックを変える際は注意すること
  const withUserTree = addSalescoreUserTree(configWithFilter, kpiRelated.previousKpiConfig.user, context)
  const withDate = addDateFilter(withUserTree, kpiRelated.previousKpiConfig.date)
  const withUser = addSalescoreUserField(
    withDate,
    kpiRelated.previousKpiConfig.user,
    context,
    kpiRelated.previousKpiConfig.options,
  )

  return mergeKpiSheetExtraConfig(withUser, kpiRelated.previousKpiConfig)
}

function getKpiRelatedConfig(context: CompileContext) {
  const previousConfig = context.additionalConfig?.previousConfig
  const previousKpiConfig = previousConfig?.type === 'kpi' ? previousConfig : undefined
  if (previousKpiConfig?.sheet === undefined) {
    return
  }
  const sheetViewConfig =
    previousKpiConfig === undefined ? undefined : context.viewSearcher.searchConfigIfReference(previousKpiConfig.sheet)
  if (sheetViewConfig?.type !== 'sheet') {
    return
  }

  return {
    previousKpiConfig,
  }
}

// sheetExtraSettingが定義されていれば合成する
// 現状はブロックのフィルターのみを想定しており、これに特化した実装にしている
function mergeKpiSheetExtraConfig(config: ViewConfigSheet, kpi: ViewConfigKpi): ViewConfigSheet {
  const { sheetExtraSetting } = kpi
  if (isNull(sheetExtraSetting)) {
    return config
  }
  const { tree } = config
  if (isNull(tree)) {
    return config
  }

  // 実装の関係で、絞り込みの高度な設定（children）は許していないので、かならずleafsだけのはず
  const leafs = sheetExtraSetting.filterTree?.leafs ?? []
  if (leafs.isBlank()) {
    return config
  }
  const newTree = leafs.reduce((newTree, leaf) => {
    if (leaf.type !== 'property') {
      return newTree
    }
    treeUtil.exec(newTree, (node: ViewConfigTreeNode) => {
      if (node.name !== leaf.property.nodeName) {
        return node
      }
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing -- 直したいけど大丈夫なのかわからないので保留
      node.additionalJoinOn ||= {
        logicalOperator: 'and',
        leafs: [],
        children: [],
      }
      node.additionalJoinOn.leafs.push(leaf)
      return node
    })
    return newTree
  }, tree)

  return {
    ...config,
    tree: newTree,
  }
}
