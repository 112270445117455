import { isNull } from '@salescore/buff-common'
import type { ViewGroupFieldsFragment } from '@salescore/client-api'

export function searchParentViewGroups(
  viewGroupId: string | undefined | null,
  viewGroupsMap: Record<string, ViewGroupFieldsFragment>,
  depth?: number,
): ViewGroupFieldsFragment[] {
  if ((depth ?? 0) > 20) {
    // ありえないはずだが、循環参照があった場合に無限ループになるので、念のため
    return []
  }
  if (isNull(viewGroupId)) {
    return []
  }
  const viewGroup = viewGroupsMap[viewGroupId]
  if (viewGroup === undefined) {
    return []
  }
  return [viewGroup, ...searchParentViewGroups(viewGroup.viewGroupId ?? undefined, viewGroupsMap, (depth ?? 0) + 1)]
}
