import {
  BarChartOutlined,
  ClearOutlined,
  ConsoleSqlOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  FilterOutlined,
  FunctionOutlined,
  HighlightOutlined,
  ImportOutlined,
  PlusOutlined,
  PushpinOutlined,
  RightOutlined,
  SearchOutlined,
  SettingOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import { isSome, isTruthy } from '@salescore/buff-common'
import { CONSTANT, isConditionalEffectEnabledOrganization, POSTHOG_EVENTS } from '@salescore/client-base'
import { getOrganizationIdFromPath, SuspenseWithLoading } from '@salescore/client-common'
import { CORE_CONSTANT, type ViewConfigField, viewConfigFieldSchema } from '@salescore/core'
import { parseJsonIfValid, PopconfirmIf, useOnClickOutside } from '@salescore/frontend-common'
import { App, Button, Tag, Tooltip } from 'antd'
import { t } from 'i18next'
import { type CSSProperties, type ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { z } from 'zod'

import { DimensionAdditionForm } from '../../../components/view_ui/ViewUIKpi/DimensionAdditionForm'
import { DimensionFilterPicker } from '../../../components/view_ui/ViewUIKpi/DimensionFilterPicker'
import { DimensionSorter } from '../../../components/view_ui/ViewUIKpi/DimensionSorter'
import { KpiAdditionForm } from '../../../components/view_ui/ViewUIKpiPivot/KpiAdditionForm'
import { PropertySelectorTableForContextMenu } from '../../../components/view_ui/ViewUISheet/PropertySelectorTableForContextMenu'
import { SheetCustomModelPropertyEditForm } from '../../../components/view_ui/ViewUISheet/PropertySelectorTableForContextMenu/SheetCustomModelPropertyEditForm'
import {
  useKpiPivotParameter,
  useNavigationModal,
  useShowFilterModalWithFieldMutation,
} from '../../../recoil/navigation/hooks'
import { useChangesValue } from '../../../recoil/records/hooks'
import { usePosthogTrackView } from '../../../recoil/usePosthogTrack'
import { sheetCustomModelsAtom } from '../../../recoil/view/atoms'
import { useViewValue } from '../../../recoil/view/hooks'
import { queryFieldToConfigField } from '../../../recoil/view/mutations/field/util'
import { useFieldMutations } from '../../../recoil/view/mutations/fieldMutations'
import { useFilterMutations } from '../../../recoil/view/mutations/filterMutations'
import { useMetaMutations } from '../../../recoil/view/mutations/metaMutations'
import { useSetSorterMutations } from '../../../recoil/view/mutations/setSorterMutations'
import { useMeRelatedSelector } from '../../../recoil/view/selectors/meRelatedSelector'
import { useViewSelector } from '../../../recoil/view/selectors/viewSelector'
import { openColumnContextMenuAtom } from '../../recoil/atoms'
import { useContextValue } from '../../recoil/models/propModels'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import { RSheetsStyle } from '../../util/RSheetsStyle'
import {
  ContextMenu,
  type ContextMenuItem,
  useHeaderColumnContextMenu,
  useHeaderRowContextMenu,
  useKpiContextMenu,
} from '../common/ContextMenu'

interface Properties {
  column: RSheetColumn<RSheetRecordNode>
  hide: () => void
}

const width = 180
const menuStyle: CSSProperties = {
  // paddingRight: 8,
  position: 'absolute',
  width,
  left: 0, // right: 0もありだが、固定カラムの右隣で実行すると被る問題がz-indexの関係的に解決できなかった
  top: 40,
  backgroundColor: 'white',
  boxShadow: '0 2px 6px 2px rgb(60 64 67 / 15%)',
  borderRadius: 2,
  zIndex: RSheetsStyle.zIndex.contextMenu,
}

export function RSheetsHeaderContextMenu(properties: Properties): ReactNode {
  const context = useContextValue()

  if (context.asKpiTable === true) {
    return <Kpi {...properties} />
  }

  return <Main {...properties} />
}

function Kpi({ column, hide }: Properties): ReactNode {
  const reference = useRef<HTMLDivElement>(null)
  useOnClickOutside(reference, () => {
    hide()
  }, ['anticon', 'disable-on-click-outside'])
  useEffect(() => {
    if (reference.current !== null) {
      reference.current.focus()
    }
  }, [])

  const [kpiPivotParameter] = useKpiPivotParameter()
  const isRowColumn = column.index <= kpiPivotParameter.pivot.rows.length // こんなんでいいだろうか？
  const rowIndex = isRowColumn ? column.index - 1 : -1
  const [dimensionFilterDropdownVisible, setDimensionFilterDropdownVisible] = useState(false)
  const [dimensionSorterDropdownVisible, setDimensionSorterDropdownVisible] = useState(false)
  const [addKpiDropdownVisible, setAddKpiDropdownVisible] = useState(false)
  const [addRowDimensionDropdownVisibile, setAddRowDimensionDropdownVisible] = useState(false)
  const kpiId = useMemo(() => {
    const kpiIndex = kpiPivotParameter.pivot.columns.findIndex(
      (x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key,
    )
    if (kpiIndex === -1) {
      return
    }
    const columnValues = parseJsonIfValid(column.field?.name ?? '')
    const parsed = z.string().array().safeParse(columnValues)
    if (parsed.success) {
      return parsed.data[kpiIndex]
    }
  }, [kpiPivotParameter])
  const kpiContextMenu = useKpiContextMenu({
    kpiId,
    setAddKpiDropdownVisible,
    hideContextMenu: hide,
    addKpiDirection: 'right',
  })
  const headerColumnContextMenu = useHeaderColumnContextMenu({
    column,
    hideContextMenu: hide,
  })
  const headerRowContextMenu = useHeaderRowContextMenu({
    setDimensionFilterDropdownVisible,
    setDimensionSorterDropdownVisible,
    setAddRowDimensionDropdownVisible,
    hideContextMenu: hide,
    rowIndex,
    column,
  })

  const field = column.field

  // 常にあるはず
  if (field === undefined) {
    return <></>
  }

  return (
    <div>
      <ContextMenu
        menu={isRowColumn ? headerRowContextMenu : [...headerColumnContextMenu, ...kpiContextMenu]}
        hideContextMenu={hide}
        style={{
          position: 'absolute',
          width,
          left: 0, // right: 0もありだが、固定カラムの右隣で実行すると被る問題がz-indexの関係的に解決できなかった
          top: 40,
        }}
      />
      {dimensionFilterDropdownVisible && (
        <div
          className="disable-on-click-outside absolute top-0 bg-white p-2"
          style={{
            ...menuStyle,
            width: 400, // ここを変更する際は、transition.lessでslide-leftも変更する必要がある
            top: 0,
            left: width + 1,
          }}
        >
          <SuspenseWithLoading>
            <DimensionFilterPicker hide={hide} field={field} />
          </SuspenseWithLoading>
        </div>
      )}
      {dimensionSorterDropdownVisible && (
        <div
          className="disable-on-click-outside absolute top-0 bg-white p-2"
          style={{
            ...menuStyle,
            width: 400, // ここを変更する際は、transition.lessでslide-leftも変更する必要がある
            top: 0,
            left: width + 1,
          }}
        >
          <DimensionSorter hide={hide} field={field} />
        </div>
      )}
      {addRowDimensionDropdownVisibile && (
        <div
          className="disable-on-click-outside absolute top-0 bg-white p-2"
          style={{
            ...menuStyle,
            width: 400, // ここを変更する際は、transition.lessでslide-leftも変更する必要がある
            top: 0,
            left: width + 1,
          }}
        >
          <DimensionAdditionForm hide={hide} prevRowIndex={rowIndex} />
        </div>
      )}
      {addKpiDropdownVisible && (
        <div
          className="disable-on-click-outside absolute top-0 bg-white p-2"
          style={{
            ...menuStyle,
            width: 320,
            top: 0,
            left: width + 1,
          }}
        >
          <KpiAdditionForm hide={hide} prevKpiId={kpiId} />
        </div>
      )}
    </div>
  )
}

function Main({ column, hide }: Properties): ReactNode {
  const context = useContextValue()
  const reference = useRef<HTMLDivElement>(null)
  useOnClickOutside(reference, () => {
    hide()
  }, [
    'ant-dropdown-menu-title-content',
    'ant-dropdown',
    'ant-select-dropdown',
    'ant-select-item-option-content',
    'ant-popover',
    'anticon',
    'disable-on-click-outside',
  ])

  useEffect(() => {
    if (reference.current !== null) {
      reference.current.focus()
    }
  }, [])

  const view = useViewValue()
  const { node, field } = column
  const { getNode, config } = useViewSelector()
  const { me, permission } = useMeRelatedSelector()
  const options = useContextValue()
  const setSorterMutations = useSetSorterMutations()
  const fieldMutations = useFieldMutations()
  const metaMutations = useMetaMutations()
  const showFilterModalWithFieldMutation = useShowFilterModalWithFieldMutation()
  const filterMutations = useFilterMutations()
  const [openColumnContextMenu, setOpenColumnContextMenu] = useRecoilState(openColumnContextMenuAtom)
  const [editSheetCustomModelDropdownVisibility, setEditSheetCustomModelDropdownVisibility] = useState(false)
  const {
    measureFormModal,
    searchSqlModal,
    nodeSearchSqlModal,
    debugModal,
    nodeModal,
    importFieldsModal,
    joinFiltersModal,
    conditionalEffectsFormModal,
    conditionalEffectsFormModal2,
  } = useNavigationModal() // modal系を余計にsubscribeしているので、パフォーマンスが問題になれば個別に参照
  const currentLeftColumnIndex = column.index + 1
  const sheetCustomModels = useRecoilValue(sheetCustomModelsAtom)
  const isSheetCustomModelNode = sheetCustomModels.some((x) => x.name === node.name) // これ以外の方法で判定できるようにしたいが、仕方ない？
  const canEditField = permission.view.canEditField
  const isGoalView = context.isGoalView ?? false
  const changes = useChangesValue()
  const { message } = App.useApp()
  const posthogTrackView = usePosthogTrackView()

  if (field === undefined) {
    return <></>
  }

  const addColumnDropdownVisibility =
    openColumnContextMenu?.column.key === column.key && openColumnContextMenu.addColumnDropdownVisible
  const setAddColumnDropdownVisibility = (visibility: boolean): void => {
    if (openColumnContextMenu?.column.key !== column.key) {
      return
    }
    setOpenColumnContextMenu({
      column,
      addColumnDropdownVisible: visibility,
    })
  }

  const menu: Array<ContextMenuItem | undefined> = [
    isGoalView
      ? undefined
      : {
          text: (
            <span>
              {t(`右に一列追加`)}
              <RightOutlined className="pl-8" />
            </span>
          ),
          icon: <PlusOutlined />,
          disabled: !canEditField,
          onClick: () => {
            setAddColumnDropdownVisibility(true)
            setEditSheetCustomModelDropdownVisibility(false)
          },
        },
    isSheetCustomModelNode
      ? {
          text: (
            <span>
              {t(`カスタム列の編集`)}
              <RightOutlined className="pl-1" />
            </span>
          ),
          icon: <EditOutlined />,
          disabled: !canEditField,
          onClick: () => {
            setAddColumnDropdownVisibility(false)
            setEditSheetCustomModelDropdownVisibility(true)
          },
        }
      : undefined,
    isGoalView
      ? undefined
      : {
          text: t(`列のインポート`),
          icon: <ImportOutlined />,
          disabled: !canEditField,
          onClick: () => {
            const configField = isSome(column.field)
              ? queryFieldToConfigField(column.field, config.fields ?? [])
              : undefined
            if (isSome(configField)) {
              importFieldsModal.showModal(configField)
            }
          },
        },
    isGoalView
      ? undefined
      : {
          text: t(`列の削除`),
          icon: <DeleteOutlined />,
          disabled: !canEditField || column.columnDeletable === false,
          onClick: () => {
            setSorterMutations.cancel({ field })
            fieldMutations.remove({ field })
            hide()
          },
          confirmMessage: isSheetCustomModelNode ? (
            <span>
              {t(`本当に削除しますか？`)}
              <br />
              {t(`カスタム列を削除すると、データも全て削除されます`)}
            </span>
          ) : undefined,
          tooltipMessage: canEditField && column.columnDeletable === false ? t(`この項目は削除できません`) : undefined,
        },
    {
      text: t(`フィルタ`),
      icon: <FilterOutlined />,
      disabled: !canEditField,
      onClick: () => {
        showFilterModalWithFieldMutation({ field })
        hide()
      },
    },
    column.isFiltered === true
      ? {
          text: t(`フィルタの削除`),
          icon: <ClearOutlined />,
          disabled: !canEditField,
          onClick: () => {
            filterMutations.removeFilterByField({ field })
            hide()
          },
        }
      : undefined,
    node.path.length > 1
      ? {
          icon: <FilterOutlined />,
          disabled: !canEditField,
          onClick: () => {
            const configNode = getNode(node.name)
            if (isSome(configNode)) {
              joinFiltersModal.showModal(configNode)
            } else {
              message.warning(t(`ノードが見つかりませんでした`))
            }
            hide()
          },
          text: t(`ブロックのフィルタ`),
        }
      : undefined,
    column.sortState === 'asc'
      ? undefined
      : {
          icon: <SortAscendingOutlined />,
          disabled: !canEditField,
          onClick: () => {
            setSorterMutations.sort({ field, order: 'asc' })
            hide()
          },
          text: t(`昇順で並び替え`),
          confirmMessage: changes.length > 0 ? t('設定を変更すると変更情報が失われます。') : undefined,
        },
    column.sortState === 'desc'
      ? undefined
      : {
          icon: <SortDescendingOutlined />,
          disabled: !canEditField,
          onClick: () => {
            setSorterMutations.sort({ field, order: 'desc' })
            hide()
          },
          text: t(`降順で並び替え`),
          confirmMessage: changes.length > 0 ? t('設定を変更すると変更情報が失われます。') : undefined,
        },
    column.sortState === undefined
      ? undefined
      : {
          icon: <ClearOutlined />,
          disabled: !canEditField,
          onClick: () => {
            setSorterMutations.cancel({ field })
            hide()
          },
          text: t(`並び替えを解除`),
          confirmMessage: changes.length > 0 ? t('設定を変更すると変更情報が失われます。') : undefined,
        },
    options.fixedLeftColumnIndex === currentLeftColumnIndex
      ? undefined
      : {
          icon: <PushpinOutlined />,
          disabled: !canEditField,
          onClick: () => {
            metaMutations.setMeta({
              newMeta: {
                fixedLeftColumnIndex: currentLeftColumnIndex, // TODO: fieldIndexを永続化すべきがcolumnIndexを保存してしまっているため、systemColumnの数が変った際に不具合になりうる
              },
            })
            hide()
          },
          text: t(`列を固定`),
        },
    !isGoalView && column.readonly !== true
      ? {
          icon: <EditOutlined />,
          disabled: !canEditField,
          onClick: () => {
            fieldMutations.setUiColumn({
              field,
              column: {
                creatable: false,
                updatable: false,
              },
            })
            hide()
          },
          text: t(`書き込み禁止`),
        }
      : undefined,
    !isGoalView && column.field !== undefined && me.organization.isEarlyReleaseTarget
      ? {
          icon: <BarChartOutlined />,
          disabled: !canEditField,
          onClick: () => {
            const configField = isSome(column.field)
              ? queryFieldToConfigField(column.field, config.fields ?? [])
              : undefined
            if (isSome(configField)) {
              measureFormModal.showModal({
                sheetViewId: view.id,
                field: configField,
              })
            }
            hide()
          },
          text: t(`集計を追加`),
        }
      : undefined,
    !isGoalView &&
    column.field !== undefined &&
    (isConditionalEffectEnabledOrganization(getOrganizationIdFromPath()) || me.isAdmin)
      ? {
          icon: <FunctionOutlined />,
          disabled: !canEditField,
          onClick: () => {
            const configField = isSome(column.field)
              ? queryFieldToConfigField(column.field, config.fields ?? [])
              : undefined
            if (isSome(configField)) {
              conditionalEffectsFormModal.showModal({
                configField,
              })
            }
            hide()
          },
          text: t(`条件付き書式`),
        }
      : undefined,
    isGoalView
      ? undefined
      : {
          icon: <HighlightOutlined />,
          disabled: !canEditField,
          onClick: () => {
            const configField = isSome(column.field)
              ? queryFieldToConfigField(column.field, config.fields ?? [])
              : undefined
            if (isSome(configField)) {
              posthogTrackView(POSTHOG_EVENTS.view_highlight_conditional_effect_modal)
              conditionalEffectsFormModal2.showModal({
                configField,
                column,
              })
            }
            hide()
          },
          text: (
            <span>
              {t(`ハイライト条件`)}
              <Tag className="ml-1 opacity-80" color="blue">
                {t(`β版`)}
              </Tag>
            </span>
          ),
        },
    options.fixedLeftColumnIndex === currentLeftColumnIndex
      ? {
          icon: <PushpinOutlined />,
          disabled: !canEditField,
          onClick: () => {
            metaMutations.setMeta({
              newMeta: {
                fixedLeftColumnIndex: undefined,
              },
            })
            hide()
          },
          text: t(`列の固定を解除`),
        }
      : undefined,
    !isGoalView && column.readonly === true
      ? {
          icon: <EditOutlined />,
          disabled: !canEditField,
          onClick: () => {
            fieldMutations.setUiColumn({
              field,
              column: {
                creatable: true,
                updatable: true,
              },
            })
            hide()
          },
          text: t(`書き込み禁止を解除`),
        }
      : undefined,
    column.visible === true
      ? {
          icon: <EyeInvisibleOutlined />,
          disabled: !canEditField,
          onClick: () => {
            fieldMutations.setUiColumn({
              field,
              column: {
                visible: false,
              },
            })
            hide()
          },
          text: t(`列を非表示`),
        }
      : undefined,
    isGoalView
      ? undefined
      : {
          icon: <SettingOutlined />,
          disabled: !canEditField,
          onClick: () => {
            const configNode = getNode(node.name)
            if (configNode !== undefined) {
              nodeModal.showModal(configNode)
            }
            hide()
          },
          text: t(`高度な設定`),
        },
    // admin
    ...(me.isAdmin
      ? [
          isSome(field.read.searchSql)
            ? {
                icon: <SearchOutlined />,
                disabled: !canEditField,
                onClick: () => {
                  searchSqlModal.showModal(field)
                  hide()
                },
                text: t(`検索設定`),
                className: CONSTANT.colorClasses.ADMIN_TEXT_COLOR,
              }
            : undefined,
          isSome(node.read.join?.search.sql) && node.read.join.relation === 'many_to_one'
            ? {
                icon: <SearchOutlined />,
                disabled: !canEditField,
                onClick: () => {
                  const configNode = getNode(node.name)
                  if (configNode !== undefined) {
                    nodeSearchSqlModal.showModal(configNode)
                  }
                  hide()
                },
                text: t(`ブロックの検索設定`),
                className: CONSTANT.colorClasses.ADMIN_TEXT_COLOR,
              }
            : undefined,
          {
            icon: <ConsoleSqlOutlined />,
            onClick: () => {
              const { node, ...others } = column
              debugModal.showModal({
                content: others,
              })
              hide()
            },
            text: t(`スキーマ表示`),
            className: CONSTANT.colorClasses.ADMIN_TEXT_COLOR,
          },
        ]
      : []),
    // 2023/01/30 widthを0にする目的で、設定編集をしたいという声があったため
    ...(me.isAdmin || me.isAdminOrManager
      ? [
          {
            icon: <ConsoleSqlOutlined />,
            onClick: () => {
              const configField = isSome(column.field)
                ? queryFieldToConfigField(column.field, config.fields ?? [])
                : undefined
              if (isSome(configField)) {
                debugModal.showModal({
                  content: configField,
                  schema: viewConfigFieldSchema, // TODO: 指定はしているものの、現状ワークしていない
                  onChange(newField) {
                    fieldMutations.setField({ field: newField as ViewConfigField })
                  },
                })
              }
              hide()
            },
            text: t(`設定編集`),
            className: CONSTANT.colorClasses.ADMIN_TEXT_COLOR,
          },
        ]
      : []),
  ]

  return (
    <div
      ref={reference}
      style={{
        ...menuStyle,
      }}
    >
      {menu.compact().map((item, index) => (
        <PopconfirmIf
          key={index}
          condition={item.confirmMessage !== undefined}
          title={<span>{item.confirmMessage}</span>}
          onConfirm={() => {
            item.onClick?.()
          }}
        >
          <Tooltip title={getTooltipMessage(item)}>
            <Button
              type="text"
              style={{ width: '100%', textAlign: 'left' }}
              icon={item.icon}
              disabled={item.disabled}
              onClick={item.confirmMessage === undefined ? item.onClick : undefined}
              className={item.className ?? ''}
            >
              {item.text}
            </Button>
          </Tooltip>
        </PopconfirmIf>
      ))}
      {addColumnDropdownVisibility && (
        <div
          className="absolute top-0 bg-white p-2"
          style={{
            ...menuStyle,
            width: 400, // ここを変更する際は、transition.lessでslide-leftも変更する必要がある
            top: 0,
            left: width + 1,
          }}
        >
          <div>
            <PropertySelectorTableForContextMenu initialNodePath={node.path} configIndex={column.configIndex} />
          </div>
        </div>
      )}
      {editSheetCustomModelDropdownVisibility && (
        <div
          className="absolute top-0 bg-white p-2"
          style={{
            ...menuStyle,
            width: 400, // ここを変更する際は、transition.lessでslide-leftも変更する必要がある
            top: 0,
            left: width + 1,
          }}
        >
          <div>
            <SheetCustomModelPropertyEditForm
              node={node}
              propertyName={column.configField?.property.propertyName ?? ''}
            />
          </div>
        </div>
      )}
    </div>
  )
}

function getTooltipMessage(item: ContextMenuItem): string | undefined {
  if (isSome(item.tooltipMessage)) {
    return item.tooltipMessage
  }

  if (isTruthy(item.disabled)) {
    return t(`権限がありません`)
  }
}
