import { simpleLogger } from '../misc/simpleLogger'
import { createUserGroupsWithParents } from './createUserGroupsWithParents'

const createViewSql = /* sql */ `
CREATE MATERIALIZED VIEW "salescore_users_with_group" AS (
SELECT
  salescore_user_groups_with_parents.user_group_d1_id,
  salescore_user_groups_with_parents.user_group_d1_name,
  salescore_user_groups_with_parents.user_group_d2_id,
  salescore_user_groups_with_parents.user_group_d2_name,
  salescore_user_groups_with_parents.user_group_d3_id,
  salescore_user_groups_with_parents.user_group_d3_name,
  salescore_user_groups_with_parents.user_group_d4_id,
  salescore_user_groups_with_parents.user_group_d4_name,
  salescore_user_groups_with_parents.user_group_d5_id,
  salescore_user_groups_with_parents.user_group_d5_name,
  salescore_user_groups_with_parents.depth as user_group_depth,
  salescore_user_groups_with_parents.name as user_group_name,
	salescore_users.*
FROM
	salescore_users
	LEFT JOIN salescore_user_groups_with_parents ON (salescore_users.user_group_id = salescore_user_groups_with_parents.id)
);`

const indices = [
  // salescore_users系
  /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_users_with_group__id ON salescore_users_with_group (id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__email ON salescore_users_with_group (email);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__salescore_user_id ON salescore_users_with_group (salescore_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__salescore_invitation_id ON salescore_users_with_group (salescore_invitation_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__salesforce_user_id ON salescore_users_with_group (salesforce_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__hubspot_user_id ON salescore_users_with_group (hubspot_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__hubspot_owner_id ON salescore_users_with_group (hubspot_owner_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__zoho_user_id ON salescore_users_with_group (zoho_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__next_sfa_user_id ON salescore_users_with_group (next_sfa_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__google_sheets_user_id ON salescore_users_with_group (google_sheets_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__comdesk_user_id ON salescore_users_with_group (comdesk_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__senses_user_id ON salescore_users_with_group (senses_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__microsoft_dynamics_user_id ON salescore_users_with_group (microsoft_dynamics_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__big_query_user_id ON salescore_users_with_group (big_query_user_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group__kintone_user_id ON salescore_users_with_group (kintone_user_id);`,
  // salescore_user_groups系
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group_d1_id ON salescore_users_with_group (user_group_d1_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group_d2_id ON salescore_users_with_group (user_group_d2_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group_d3_id ON salescore_users_with_group (user_group_d3_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group_d4_id ON salescore_users_with_group (user_group_d4_id);`,
  /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_users_with_group_d5_id ON salescore_users_with_group (user_group_d5_id);`,
]

export async function createUsersWithUserGroupView(query: (sql: string) => Promise<void>) {
  await createUserGroupsWithParents(query) // 先にsalescore_user_grouops_with_parentsを作成する

  await query(createViewSql)
  // 冪等にしたいが、インデックスが存在するとエラーになるので無視（丁寧にケアすれば可能だがやらない）
  const queryAndIgnoreError = async (sql: string) => {
    try {
      await query(sql)
    } catch (error) {
      simpleLogger.warn(error)
    }
  }
  for (const index of indices) {
    await queryAndIgnoreError(index)
  }
}
