import type { CoreModel, ViewConfigTreeNode } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { hasChangeToViewSchemaAtom } from '../../atoms'
import { setConfigMutation } from '../setConfigMutation'

export const setRootNodeMutation = mutation<{ model: CoreModel; onSet: (node: ViewConfigTreeNode) => void }>({
  key: `view/setRootNodeMutation`,
  set({ get, set }, { model, onSet }) {
    const newNode: ViewConfigTreeNode = {
      name: model.name,
      modelName: model.name,
      type: 'model',
    }
    set(setConfigMutation, (config) => ({
      ...config,
      type: 'sheet' as const,
      tree: newNode,
    }))
    set(hasChangeToViewSchemaAtom, true)
    onSet(newNode)
  },
})
