import { PageHeader } from '@ant-design/pro-layout'
import { SuspenseWithLoading } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { organizationHasFeature } from '@salescore/frontend-common'
import { Tabs } from 'antd'
import { t } from 'i18next'

import { CustomizedModelTable } from './CustomizedModelTable'
import { SalescoreModelTable } from './SalescoreModelTable'
import { SnapshotModelTable } from './SnapshotModelTable'
import { SourceModelTable } from './SourceModelTable'

// とりあえず雑にclient側で出し分けしている
const ENABLE_CUSTOM_MODEL_EMAILS = new Set([`keijiro.takeishi@plaid.co.jp`, `kumiko.tajima@plaid.co.jp`])

export const EltModelsPage = ({ connectionId }: { connectionId: string | null }): JSX.Element => {
  const me = recoil.global.useMe()
  const isSalescoreCustomModelEnabled = me.isAdmin || ENABLE_CUSTOM_MODEL_EMAILS.has(me.myIdentity.email)
  const haveDataExtensionLicense =
    me.isAdmin || me.organization.organizationPlans.some((x) => x.license === 'dataExtension')
  const enableRi = organizationHasFeature(me.organization, 'enable_ri')
  return (
    <PageHeader title={<span>{t('オブジェクト一覧')}</span>}>
      <Tabs
        defaultActiveKey="source"
        type="card"
        items={[
          {
            key: '1',
            label: t(`連携先`),
            children: (
              <SuspenseWithLoading type="table">
                <SourceModelTable key="source" connectionId={connectionId} />
              </SuspenseWithLoading>
            ),
          },
          haveDataExtensionLicense
            ? {
                key: '2',
                label: t(`カスタムオブジェクト`),
                children: (
                  <SuspenseWithLoading type="table">
                    <CustomizedModelTable key="userCustomizedDerived" />
                  </SuspenseWithLoading>
                ),
              }
            : undefined,
          haveDataExtensionLicense && isSalescoreCustomModelEnabled
            ? {
                key: '3',
                label: t(`SALESCOREカスタムオブジェクト`),
                children: (
                  <SuspenseWithLoading type="table">
                    <SalescoreModelTable key="userCustomizedSalescoreSource" />
                  </SuspenseWithLoading>
                ),
              }
            : undefined,
          haveDataExtensionLicense && enableRi
            ? {
                key: '4',
                label: t(`スナップショットオブジェクト`),
                children: (
                  <SuspenseWithLoading type="table">
                    <SnapshotModelTable key="userCustomizedSnapshot" />
                  </SuspenseWithLoading>
                ),
              }
            : undefined,
        ].compact()}
      />
    </PageHeader>
  )
}
