import type { ExpressionNodeWithDeprecated, LiteralNode } from './syntax/expression_ast'

export type CoreDslParameter = Record<string, unknown>

//
// XXX: 一旦作ったが、括弧の対応などが雑
//
export function toString(node: ExpressionNodeWithDeprecated | undefined): string {
  if (node === undefined) {
    return ''
  }

  const type = node.type
  switch (type) {
    case 'literal': {
      return toStringLiteralNode(node)
    }
    case 'variable': {
      return node.path.join('.')
    }
    case 'func': {
      return `${node.name}(${node.args.map((x) => toString(x)).join(', ')})`
    }
    case 'array': {
      return `[${node.elements.map((x) => toString(x)).join(', ')}]`
    }
    case 'operator': {
      return ['(', toString(node.left), node.operator, toString(node.right), ')'].join(' ')
    }
    // deprecated
    case 'function': {
      return `${node.functionType}(${node.arguments.map((x) => toString(x)).join(', ')})`
    }
    case 'value': {
      return toStringLiteral(node.value)
    }
    case 'recordValue': {
      return node.path.join('.')
    }
    default: {
      throw new Error(type satisfies never)
    }
  }
}

function toStringLiteral(value: unknown): string {
  switch (typeof value) {
    case 'string': {
      return `"${value}"`
    }
    case 'number': {
      return `${value}`
    }
    case 'boolean': {
      return value ? 'true' : 'false'
    }
  }
  if (value === null) {
    return `null`
  }
  if (value === undefined) {
    return `undefined`
  }
  return ``
}

function toStringLiteralNode(node: LiteralNode): string {
  const literalType = node.literalType
  switch (literalType) {
    case 'string': {
      return `"${node.value}"`
    }
    case 'number': {
      return `${node.value}`
    }
    case 'boolean': {
      return node.value ? 'true' : 'false'
    }
    case 'date': {
      return `date("${node.value.format('YYYY-MM-DD')}")`
    }
    case 'null': {
      return 'null'
    }
    default: {
      const x: never = literalType
      return ``
    }
  }
}
