import { useMutation } from '@apollo/client'
import { UpdateSourceDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import { App, Button, Form, type FormInstance, Input } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'

export interface KintoneConfig {
  host: string
  credentials: {
    type: 'password'
    id: string
    password: string
  }
}

export interface KintonePasswordForm {
  host: string
  id: string
  password: string
}

// OAuthのほうが良いかもしれないがその場合はbackend側client実装も変更の必要があるかも
export const KintoneSourceForm = ({
  form,
  onFinish,
}: {
  form: FormInstance<KintonePasswordForm>
  onFinish: (form: KintonePasswordForm) => void
}): ReactNode => (
  <Form layout="vertical" form={form} onFinish={onFinish}>
    <Form.Item
      label={t(`ホスト名`)}
      name="host"
      required
      rules={[
        {
          required: true,
          // https://jp.cybozu.help/general/ja/id/02076.html#admin_domain_30
          pattern: /^[\dA-Za-z][\dA-Za-z-]{1,30}[\dA-Za-z]\.cybozu\.com$/,
          message: t(`ホスト名を入力してください。`),
        },
      ]}
    >
      <Input addonBefore={'https://'} placeholder="example.cybozu.com" />
    </Form.Item>
    <Form.Item label={t(`ID`)} name="id" required rules={[{ required: true, message: t(`IDを入力してください。`) }]}>
      <Input placeholder="" />
    </Form.Item>
    <Form.Item
      label={t(`パスワード`)}
      name="password"
      required
      rules={[{ required: true, message: t(`パスワードを入力してください。`) }]}
    >
      <Input type="password" placeholder="" />
    </Form.Item>
  </Form>
)

export const KintoneSourceEditForm = ({
  source,
  form,
  onAfterFinish,
}: {
  source: { id: string }
  form: FormInstance<KintonePasswordForm>
  onAfterFinish: () => void
}): ReactNode => {
  const { message } = App.useApp()
  const [updateSourceMutation] = useMutation(UpdateSourceDocument)

  const handleFinish = (form: KintonePasswordForm): void => {
    const config: KintoneConfig = {
      host: form.host,
      credentials: {
        type: 'password',
        id: form.id,
        password: form.password,
      },
    }
    void updateSourceMutation({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        id: source.id,
        config,
      },
      onCompleted: () => {
        void message.success(t(`設定を更新しました`))
        onAfterFinish()
      },
      onError: (error) => {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      },
    })
  }

  return (
    <Form layout="vertical" form={form} onFinish={handleFinish}>
      <Form.Item
        label={t(`ホスト名`)}
        name="host"
        required
        rules={[
          {
            required: true,
            // https://jp.cybozu.help/general/ja/id/02076.html#admin_domain_30
            pattern: /^[\dA-Za-z][\dA-Za-z-]{1,30}[\dA-Za-z]\.cybozu\.com$/,
            message: t(`ホスト名を入力してください。`),
          },
        ]}
      >
        <Input addonBefore={'https://'} placeholder="example.cybozu.com" />
      </Form.Item>
      <Form.Item label={t(`ID`)} name="id" required rules={[{ required: true, message: t(`IDを入力してください。`) }]}>
        <Input placeholder="" />
      </Form.Item>
      <Form.Item
        label={t(`パスワード`)}
        name="password"
        required
        rules={[{ required: true, message: t(`パスワードを入力してください。`) }]}
      >
        <Input type="password" placeholder="" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType={'submit'}>
          {t('更新')}
        </Button>
      </Form.Item>
    </Form>
  )
}
