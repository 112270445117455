import { useApolloClient } from '@apollo/client'
import { useEffect } from 'react'

import { useRefetchFavorites } from './mutation/refetchFavorites'
import { useRefetchViewGroups } from './mutation/refetchViewGroups'

export const useSyncProperties = ({ organizationId }: { organizationId: string }) => {
  const client = useApolloClient()
  const refetchFavorites = useRefetchFavorites()
  const refetchViewGroups = useRefetchViewGroups()

  useEffect(() => {
    void refetchViewGroups()
    void refetchFavorites()
  }, [])
}
