import { EditOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import {
  DeleteDimensionGroupDocument,
  type DimensionGroupFieldsFragment,
  type GoalDimensionFieldsFragment,
} from '@salescore/client-api'
import { getOrganizationIdFromPath, SuspenseWithLoading } from '@salescore/client-common'
import { type CoreModel, ModelSearcher } from '@salescore/core'
import { createDestroyColumn, useModal } from '@salescore/frontend-common'
import { Button, Modal, Table, Tag } from 'antd'
import { t } from 'i18next'
import { useMemo, useState } from 'react'

import { UpsertDimensionGroupForm } from './UpsertDimensionGroupForm'

export function DimensionGroupsTable({
  dimensionGroups,
  goalDimensions,
  models,
  refetch,
}: {
  dimensionGroups: DimensionGroupFieldsFragment[]
  goalDimensions: GoalDimensionFieldsFragment[]
  models: CoreModel[]
  refetch: () => void
}) {
  const [deleteDimensionGroupMutation] = useMutation(DeleteDimensionGroupDocument)
  const editModal = useModal<{ dimensionGroup: DimensionGroupFieldsFragment }>()
  const [destroyLoading, setDestroyLoading] = useState(false)

  const searcher = useMemo(() => {
    const searcher = new ModelSearcher(models)
    return searcher
  }, [models])

  return (
    <>
      <Table
        dataSource={dimensionGroups}
        columns={[
          {
            title: t(`グループ名`),
            dataIndex: `label`,
          },
          {
            title: t(`グループ化されたプロパティ`),
            dataIndex: `properties`,
            render: (_, record) => (
              // TODO: 丁寧にモデルを表示…？
              <div>
                {record.properties.map((x) => {
                  if (x.type === 'goalDimension') {
                    const goalDimension = goalDimensions.find((y) => y.id === x.goalDimensionId)
                    return (
                      <Tag>
                        {t(`目標`)}: {goalDimension?.label ?? x.goalDimensionId}
                      </Tag>
                    )
                  }
                  const property = searcher.searchPropertyWithModel(x.modelName, x.propertyName)
                  return (
                    <Tag>
                      {property?.model.label ?? x.modelName}: {property?.property.label ?? x.propertyName}
                    </Tag>
                  )
                })}
              </div>
            ),
          },
          {
            title: ``,
            key: `edit`,
            width: 100,
            render: (_, record) => (
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  editModal.showModal({ dimensionGroup: record })
                }}
              >
                {t(`軸グループの編集`)}
              </Button>
            ),
          },
          createDestroyColumn(destroyLoading, setDestroyLoading, async (record: DimensionGroupFieldsFragment) => {
            await deleteDimensionGroupMutation({
              variables: {
                id: record.id,
                organizationId: getOrganizationIdFromPath(),
              },
            })
            refetch()
          }),
        ]}
      />
      <Modal
        open={editModal.isModalVisible}
        onCancel={editModal.hideModal}
        width={'60%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
        destroyOnClose
      >
        {editModal.content !== undefined && (
          <SuspenseWithLoading>
            <UpsertDimensionGroupForm
              dimensionGroup={editModal.content.dimensionGroup}
              onAfterFinish={() => {
                refetch()
                editModal.hideModal()
              }}
            />
          </SuspenseWithLoading>
        )}
      </Modal>
    </>
  )
}
