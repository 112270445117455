import { treeUtil } from '@salescore/buff-common'

import type { DimensionGroup } from '../../../../schemas/misc/dimension_group'
import type { GoalConfig } from '../../../../schemas/misc/goal_config'
import type {
  NodePropertyName,
  ViewConfigDimension,
  ViewConfigField,
  ViewConfigKpi,
  ViewConfigSheet,
} from '../../../../schemas/view_config'
import type { CompileContext } from '../../common'
import { generateFieldName } from './generateFieldName'
import { isSameNodeProperty } from './isSameNodeProperty'

// 指定されたdimensionが、どのfieldに相当するかを求める
// このレイヤーでaliasの解決が行われる
export function searchFieldAndPropertyForDimension(
  dimension: ViewConfigDimension,
  config: ViewConfigSheet,
  dimensionAliases: ViewConfigKpi['dimensionAliases'],
  context: CompileContext,
) {
  const field = findDimensionField(dimension, config, dimensionAliases, context)
  if (field === undefined || config.tree === undefined) {
    return {
      dimension,
      field: undefined,
      model: undefined,
      property: undefined,
    }
  }
  const nodeNames = treeUtil.flatNodes(config.tree).map((x) => x.name)
  if (!nodeNames.includes(field.property.nodeName)) {
    return {
      dimension,
      field: undefined,
      model: undefined,
      property: undefined,
    }
  }

  const propertyWithModel = context.modelSearcher.searchPropertyWithModel(
    field.property.modelName,
    field.property.propertyName,
  )
  if (propertyWithModel === undefined) {
    context.logs.warn(`プロパティが見つかりません。${field.property.modelName}.${field.property.propertyName}`)
    return {
      dimension,
      field,
      model: undefined,
      property: undefined,
    }
  }
  return {
    dimension,
    field,
    model: propertyWithModel.model,
    property: propertyWithModel.property,
  }
}

function findDimensionField(
  dimension: ViewConfigDimension,
  config: ViewConfigSheet,
  dimensionAliases: ViewConfigKpi['dimensionAliases'],
  context: CompileContext,
): ViewConfigField | undefined {
  const type = dimension.type
  switch (type) {
    case 'property': {
      // 初期構想だとisSameNodePropertyのみで一致させる予定だったが、
      // 何も見つからなかったときに、何も表示しないよりは何かしら表示した方がマシなので、ノード名が違っても同じプロパティなら表示することにする
      // また、旧環境からの移行のロジックで、ノード名の命名規則が異なっており、isSameNodePropertyのみだとその辺で問題になる
      return (
        config.fields?.find((field) => isSameNodeProperty(field.property, dimension.property)) ??
        config.fields?.find((field) => isSameProperty(field.property, dimension.property))
      )
    }
    case 'fieldName': {
      // KPIのみで利用予定
      return findConfigField(dimension.fieldName, config.fields ?? [])
    }
    case 'dimensionGroup': {
      const group = context.resources.organizationSetting.dimensionGroups.find((x) => x.id === dimension.groupId)
      if (group === undefined) {
        return undefined
      }
      return config.fields?.find((field) =>
        group.properties.some((dimensionGroupProperty) =>
          isMatchToDimensionGroup(
            field.property,
            dimensionGroupProperty,
            context.additionalConfig?.kpiParameter?.goalConfig,
          ),
        ),
      )
    }
    // case 'fieldLabel': {
    //   const candidates = (config.fields ?? []).map((field) => generateLabel(field, context)).compact()
    //   return candidates.find((c) => dimension.label === c.label)?.field
    // }
    case 'alias': {
      const alias = dimensionAliases?.find((x) => x.alias === dimension.key)
      if (alias === undefined) {
        context.logs.log(`${dimension.label}の別名は見つかりませんでした。`)
        return undefined
      }
      return (
        (config.fields ?? []).find((x) => isSameNodeProperty(x.property, alias.property)) ??
        (config.fields ?? []).find((x) => isSameProperty(x.property, alias.property))
      )
    }
    default: {
      const x: never = type
      throw new Error(`not implemented`)
    }
  }
}

// labelを動的に生成して、一致するものを選ぶ
// TODO: fieldのラベルを求める方法を一般化したい
function generateLabel(field: ViewConfigField, context: CompileContext) {
  const propertyWithModel = context.modelSearcher.searchPropertyWithModel(
    field.property.modelName,
    field.property.propertyName,
  )
  if (propertyWithModel === undefined) {
    return
  }
  const property = propertyWithModel.property
  return {
    field,
    model: propertyWithModel.model,
    property: propertyWithModel.property,
    label: field.override?.uiColumn?.label ?? property.label,
  }
}

export function findConfigField(fieldName: string, fields: ViewConfigField[]) {
  return fields.find((field) => generateFieldName({ ...field.property }) === fieldName)
}

export function isMatchToDimensionGroup(
  property: NodePropertyName,
  dimensionGroupProperty: DimensionGroup['properties'][0],
  goalConfig: GoalConfig | undefined,
) {
  const type = dimensionGroupProperty.type
  switch (type) {
    case 'modelProperty': {
      return (
        dimensionGroupProperty.modelName === property.modelName &&
        dimensionGroupProperty.propertyName === property.propertyName
      )
    }
    case 'goalDimension': {
      const goalDimension = getGoalConfigDimensionGroupByPropertyName(property.propertyName, goalConfig)
      return dimensionGroupProperty.goalDimensionId === goalDimension?.id
    }
    default: {
      const x: never = type
      throw new Error(`未対応のdimensionGroupProperty.typeです。${type as string}`)
    }
  }
}

function getGoalConfigDimensionGroupByPropertyName(propertyName: string, goalConfig: GoalConfig | undefined) {
  switch (propertyName) {
    case 'dimension1': {
      return goalConfig?.goalDimension1
    }
    case 'dimension2': {
      return goalConfig?.goalDimension2
    }
    case 'dimension3': {
      return goalConfig?.goalDimension3
    }
    case 'dimension4': {
      return goalConfig?.goalDimension4
    }
    case 'dimension5': {
      return goalConfig?.goalDimension5
    }
  }
}

function isSameProperty(a: NodePropertyName | undefined, b: NodePropertyName | undefined): boolean {
  if (a === undefined || b === undefined) {
    return false
  }
  return b.modelName === a.modelName && b.propertyName === a.propertyName
}
