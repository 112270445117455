import { Modal } from 'antd'
import { t } from 'i18next'

import { useKpiPivotNavigationModal } from '../../../recoil/navigation/hooks'
import { ViewConfigKpiPresetForm } from './KpiPresetForm'

export function KpiPresetFormModal() {
  const { kpiPresetFormModal } = useKpiPivotNavigationModal()

  return (
    <Modal
      open={kpiPresetFormModal.isModalVisible}
      onCancel={kpiPresetFormModal.hideModal}
      width={'90%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      title={
        <div>
          {t(`集計プリセットの{{title}}`, {
            title: kpiPresetFormModal.content?.preset === undefined ? t(`新規作成`) : t(`編集`),
          } satisfies { title: string })}
        </div>
      }
      style={{ top: '3%' }}
      destroyOnClose
      footer={null}
    >
      <div className="w-full overflow-x-scroll">
        {kpiPresetFormModal.content !== undefined && (
          <ViewConfigKpiPresetForm
            preset={kpiPresetFormModal.content.preset}
            onFinish={(preset) => {
              kpiPresetFormModal.content?.onFinish?.(preset)
              kpiPresetFormModal.hideModal()
            }}
          />
        )}
      </div>
    </Modal>
  )
}
