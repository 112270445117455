//
// bugsnagやlogtailのログでユーザー名を使いたいが、ユーザー情報を引数で渡す形が難しいケースが多い。
// 堅牢ではないが、Auth0のユーザー情報をlocalStorageに入れてしまい、それを使い回す形にする
//
import { z } from 'zod'

import { CONSTANT } from '../setting'

const auth0Schema = z.object({
  email: z.string(),
  sub: z.string(),
  name: z.string(),
})

export function getAuth0UserFromLocalStorage() {
  try {
    const cache = localStorage.getItem(CONSTANT.localStorageKey.PARTIAL_AUTH0_RESPONSE)
    if (cache === null) {
      return
    }
    const auth0User = JSON.parse(cache) as unknown
    return auth0Schema.parse(auth0User)
  } catch {}
}
