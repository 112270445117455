import { DeleteOutlined, PlusCircleFilled } from '@ant-design/icons'
import { compact, isNull, isPresent, isTruthy } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import { equalFilterTypes, type FilterType, includeFilterTypes, startsWithFilterTypes } from '@salescore/core'
import { Button, Checkbox, Form, Select, Space } from 'antd'
import { t } from 'i18next'

import { useConfigSheetSelector } from '../../../../../recoil/view/selectors/configSheetSelector'
import type { NodeWithModel } from '../common/nodeWithModel'
import type { FilterFormItemState, ViewConfigFilterNodeForForm } from './common'
import { ViewFilterFormItems } from './ViewFilterFormItems'

export const initialState: FilterFormNodeState = {
  logicalOperator: 'and',
  // leafs: [{}],
  leafs: [],
  children: [],
}

export type FilterFormNodeState = ViewConfigFilterNodeForForm

export function ViewFilterNodeFormItem({
  filterNodeState,
  propertiesWithNode,
  isAdvancedMode,
  inNestedForm,
  setFilterState,
  setFilterNodeState,
  onDestroy,
  onLogicalOperatorChange,
  disabled,
}: {
  filterNodeState: FilterFormNodeState
  propertiesWithNode: NodeWithModel[]
  isAdvancedMode: boolean
  inNestedForm?: boolean
  setFilterState: (
    index: number,
    filterFormState: FilterFormItemState | undefined,
    option?: { asInitialization?: boolean },
  ) => void
  setFilterNodeState: (index: number, filterFormNodeState: FilterFormNodeState | undefined) => void
  onDestroy?: () => void
  onLogicalOperatorChange: (op: FilterFormNodeState['logicalOperator']) => void
  disabled?: boolean
}) {
  const { getModelProperty } = useConfigSheetSelector()
  const isDisplayCaseSensitiveForm = (filterState: FilterFormItemState) => {
    const includeFilterTypesSet = new Set<FilterType>(includeFilterTypes)
    const caseConsiderableFilterTypesSet = new Set<FilterType>([
      ...equalFilterTypes,
      ...includeFilterTypes,
      ...startsWithFilterTypes,
    ])
    const filterType = filterState.filterType
    const property = getModelProperty(filterState.property?.modelName, filterState.property?.propertyName)

    if (property?.type !== 'string' || isNull(filterType)) {
      return false
    }

    if (isPresent(property.selectOptions)) {
      return includeFilterTypesSet.has(filterType)
    }
    return caseConsiderableFilterTypesSet.has(filterType)
  }

  return (
    <div
      className="mb-4 rounded-lg border border-solid border-gray-300 bg-gray-100 p-6"
      style={{
        backgroundColor: '#f9f9f9',
      }}
    >
      {filterNodeState.leafs.length > 0 && (
        <>
          {/* <div className="border border-solid border-gray-300 rounded-lg px-6 pt-6 mb-4 bg-gray-100"> */}
          {filterNodeState.leafs.map((filterState, index) => (
            <>
              <ViewFilterFormItems
                // key={`filter-${index}-${filter.nodePaths.join(',') ?? ''}`}
                filter={filterState}
                onChange={(newFilterState: FilterFormItemState, option?: { asInitialization?: boolean }) => {
                  setFilterState(index, newFilterState, option)
                }}
                propertiesWithNode={propertiesWithNode}
              >
                <Button
                  icon={<DeleteOutlined />}
                  type="text"
                  style={{ marginTop: '6px' }}
                  onClick={() => {
                    setFilterState(index, undefined)
                  }}
                />
              </ViewFilterFormItems>
              {isDisplayCaseSensitiveForm(filterState) && (
                <Form.Item className={isTruthy(inNestedForm) ? 'mt-6' : ''} style={{ minHeight: 32 }}>
                  <Checkbox
                    checked={isTruthy(filterState.option?.shouldConsiderCase)}
                    onChange={(value) => {
                      setFilterState(index, {
                        ...filterState,
                        option: {
                          ...filterState.option,
                          shouldConsiderCase: value.target.checked,
                        },
                      })
                    }}
                  >
                    {t('大文字と小文字を区別する')}
                  </Checkbox>
                </Form.Item>
              )}
              <DividerWithLogicalOperator
                {...{
                  inNestedForm,
                  filterNodeState,
                  onLogicalOperatorChange,
                  withLogicalOperator: index !== filterNodeState.leafs.length - 1 && isAdvancedMode,
                }}
              />
            </>
          ))}
          {/* </div> */}
        </>
      )}
      {isAdvancedMode &&
        filterNodeState.children.map((childFilterNodeState, childFilterNodeStateIndex) => (
          <>
            <DividerWithLogicalOperator
              {...{ withLogicalOperator: true, filterNodeState, onLogicalOperatorChange, inNestedForm }}
            />
            <ViewFilterNodeFormItem
              inNestedForm={inNestedForm}
              isAdvancedMode={true}
              filterNodeState={childFilterNodeState}
              propertiesWithNode={propertiesWithNode}
              setFilterState={(index: number, newFilter: FilterFormItemState | undefined) => {
                const newFilterNodeState = {
                  ...childFilterNodeState,
                  leafs: compact([
                    ...childFilterNodeState.leafs.slice(0, index),
                    newFilter,
                    ...childFilterNodeState.leafs.slice(index + 1),
                  ]),
                }
                setFilterNodeState(childFilterNodeStateIndex, newFilterNodeState)
              }}
              setFilterNodeState={(index: number, newFilterNode: FilterFormNodeState | undefined) => {
                const newFilterNodeState = {
                  ...childFilterNodeState,
                  children: compact([
                    ...childFilterNodeState.children.slice(0, index),
                    newFilterNode,
                    ...childFilterNodeState.children.slice(index + 1),
                  ]),
                }
                setFilterNodeState(childFilterNodeStateIndex, newFilterNodeState)
              }}
              onDestroy={() => {
                setFilterNodeState(childFilterNodeStateIndex, undefined)
              }}
              onLogicalOperatorChange={(logicalOperator) => {
                setFilterNodeState(childFilterNodeStateIndex, {
                  ...childFilterNodeState,
                  logicalOperator,
                })
              }}
            />
          </>
        ))}

      <Space className="">
        <Button
          // type="dashed"
          onClick={() => {
            const newFilter: FilterFormItemState = {}
            setFilterState(filterNodeState.leafs.length, newFilter)
          }}
          block
          icon={<PlusCircleFilled />}
          type="text"
          style={{
            color: CONSTANT.colors.primaryColor,
            // paddingLeft: 0,
          }}
          disabled={disabled}
        >
          {t(`条件を追加`)}
        </Button>
        {isAdvancedMode && (
          <>
            <Button
              // type="dashed"
              onClick={() => {
                const newFilterNode: FilterFormNodeState = initialState
                setFilterNodeState(filterNodeState.children.length, newFilterNode)
              }}
              block
              icon={<PlusCircleFilled />}
              type="text"
              style={{
                color: CONSTANT.colors.primaryColor,
              }}
              disabled={disabled}
            >
              {t(`グループを追加`)}
            </Button>

            {onDestroy !== undefined && (
              <Button
                icon={<DeleteOutlined />}
                type="text"
                onClick={() => {
                  onDestroy()
                }}
                style={{
                  color: CONSTANT.colors.primaryColor,
                }}
              >
                {t(`削除`)}
              </Button>
            )}
          </>
        )}
      </Space>
    </div>
  )
}

function DividerWithLogicalOperator({
  withLogicalOperator,
  filterNodeState,
  inNestedForm,
  onLogicalOperatorChange,
}: {
  withLogicalOperator: boolean
  filterNodeState: FilterFormNodeState
  inNestedForm?: boolean
  onLogicalOperatorChange: (op: FilterFormNodeState['logicalOperator']) => void
}) {
  return (
    <div className={`relative mb-8 ${inNestedForm === true ? `mt-8` : ``}`}>
      <hr className="opacity-30" />
      {withLogicalOperator && (
        <Select
          style={{
            position: 'absolute',
            top: -16,
          }}
          value={filterNodeState.logicalOperator}
          onChange={(value) => {
            onLogicalOperatorChange(value)
          }}
          options={[
            {
              label: t(`AND条件`),
              value: 'and',
            },
            {
              label: t(`OR条件`),
              value: 'or',
            },
          ]}
        />
      )}
    </div>
  )
}
