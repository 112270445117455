import { DeleteOutlined, HistoryOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { r } from '@salescore/buff-common'
import { useBooleanState } from '@salescore/frontend-common'
import { Button, message, Modal, Popover, Space, Table, Tag } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { useChangesMutation } from '../../../../recoil/records/mutations/changesMutation'
import { useRefetchMutation } from '../../../../recoil/records/mutations/useRefetchMutation'
import { useChangesSelector } from '../../../../recoil/records/selectors/changesSelector'
import { useQueryValue } from '../../../../recoil/view/hooks'
import { useQuerySelector } from '../../../../recoil/view/selectors/querySelector'
import { flatNodes } from '../../../../state/nodeUtil'

export function ChangesModal() {
  const { changesModal } = useNavigationModal()
  const { changes, changesChunks } = useChangesSelector()
  const recordsMutation = useRefetchMutation()
  const changesMutation = useChangesMutation()
  const streamNameToLabel = useStreamLabel()
  const streamNameToColor = useStreamColor()
  const propertyNameToLabel = usePropertyLabel()

  const loading = useBooleanState()
  return (
    <Modal
      open={changesModal.isModalVisible}
      onCancel={changesModal.hideModal}
      width={'80%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{ top: '3%' }}
    >
      <PageHeader
        title={
          <Space>
            {/* <HistoryOutlined />  */}
            {t(`変更内容`)}
          </Space>
        }
        extra={[
          <Button
            disabled={changes.isBlank()}
            danger
            icon={<DeleteOutlined />}
            onClick={async () => {
              loading.setTrue()
              try {
                await recordsMutation.refetch({ kind: 'reset' })
              } catch (error) {
                if (error instanceof Error) {
                  void message.error(error.message)
                }
              } finally {
                loading.setFalse()
              }
            }}
          >
            {t(`変更内容をリセット`)}
          </Button>,
        ]}
      >
        <Table
          size="small"
          dataSource={changesChunks.flatMap((x) => x.changes)}
          columns={[
            {
              title: t(`オブジェクト名`),
              key: 'before',
              width: 180,
              render(_, change) {
                const color = streamNameToColor[change.modelName] ?? 'gray'
                return <Tag color={color as string}>{streamNameToLabel[change.modelName] ?? change.modelName}</Tag>
              },
            },
            {
              title: t(`変更したレコード(ID)`),
              key: 'id',
              width: 200,
              render(_, change) {
                if (change.type === 'create') {
                  return <Tag>{t(`新規レコード`)}</Tag>
                }
                const { id, recordNameLabel } = change
                if (recordNameLabel !== undefined) {
                  return (
                    <Popover content={id}>
                      <Tag>{recordNameLabel}</Tag>
                    </Popover>
                  )
                }
              },
            },
            {
              title: t(`変更前`),
              key: 'before',
              render(_, change) {
                if (change.type === 'create') {
                  return <></>
                }

                return (
                  <div>
                    {r(change.before ?? {}).map((key, value) => (
                      <div>
                        <>
                          {propertyNameToLabel[key] ?? key} : {value ?? t(`入力なし`)}
                        </>
                      </div>
                    ))}
                  </div>
                )
              },
            },
            {
              title: t(`変更後`),
              key: 'before',
              render(_, change) {
                if (change.type === 'delete') {
                  return <span className="text-red-400">{t(`レコードを削除`)}</span>
                }
                return (
                  <div>
                    {r(change.data ?? {}).map((key, value) => (
                      <div>
                        <>
                          {propertyNameToLabel[key] ?? key} : {value}
                        </>
                      </div>
                    ))}
                  </div>
                )
              },
            },
          ]}
        />
      </PageHeader>
      <PageHeader
        title={
          <Space>
            <HistoryOutlined /> {t(`変更履歴`)}
          </Space>
        }
      >
        <Table
          size="small"
          dataSource={[...changes].reverse().map((x, index) => ({ ...x, key: x.datetime, index }))}
          columns={[
            {
              title: t(`変更時刻`),
              key: 'datetime',
              width: 200,
              render(_, record) {
                return <div>{dayjs(record.datetime).format(`HH:mm:ss.SSS`)}</div>
              },
            },
            {
              title: t(`オブジェクト名`),
              key: 'before',
              width: 250,
              render(_, record) {
                return (
                  <div>
                    {record.changes.map((change) => {
                      const color = streamNameToColor[change.streamName] ?? 'gray'
                      return (
                        <Tag color={color as string}>{streamNameToLabel[change.streamName] ?? change.streamName}</Tag>
                      )
                    })}
                  </div>
                )
              },
            },
            {
              title: t(`変更したレコード(ID)`),
              key: 'id',
              width: 220,
              render(_, record) {
                return (
                  <div>
                    {record.changes.map((change) => {
                      const { id, recordNameLabel } = change
                      if (recordNameLabel !== undefined) {
                        return (
                          <Popover content={id}>
                            <Tag>{recordNameLabel}</Tag>
                          </Popover>
                        )
                      }
                      return <Tag>{id ?? t(`新規レコード`)}</Tag>
                    })}
                  </div>
                )
              },
            },
            {
              title: t(`変更前`),
              key: 'before',
              render(_, record) {
                return (
                  <div>
                    {record.changes.map((change) => (
                      <div>
                        {r(change.before ?? {}).map((key, value) => (
                          <div>
                            <>
                              {propertyNameToLabel[key] ?? key} : {value ?? t(`入力なし`)}
                            </>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )
              },
            },
            {
              title: t(`変更後`),
              key: 'after',
              render(_, record) {
                return (
                  <div>
                    {record.changes.map((change) => (
                      <div>
                        {r(change.after ?? {}).map((key, value) => (
                          <div>
                            <>
                              {propertyNameToLabel[key] ?? key} : {value ?? t(`入力なし`)}
                            </>
                          </div>
                        ))}
                        {change.deleted === true ? <span className="text-red-400">{t(`レコードを削除`)}</span> : ''}
                      </div>
                    ))}
                  </div>
                )
              },
            },
            // エラーが解消できなかったため後回し
            {
              title: '',
              key: 'back',
              render(_, record) {
                if (record.index === 0) {
                  return <></>
                }

                return (
                  <Button
                    // icon={<BackwardOutlined/>}
                    onClick={() => {
                      const targetIndex = record.index
                      changesMutation.backHistory(targetIndex)
                    }}
                  >
                    {t(`この状態に戻す`)}
                  </Button>
                )
              },
            },
          ]}
        />
      </PageHeader>
    </Modal>
  )
}

function useStreamLabel() {
  const query = useQueryValue()
  if (query === undefined) {
    return {}
  }
  const nodes = flatNodes(query.tree)
  return nodes.toObject((node) => [node.write?.streamName ?? '', node.meta.label.split('(').first()!]) // TODO: stream.meta.labelを取得したい
}

function useStreamColor() {
  const { query, nodeNamesWithColors } = useQuerySelector()
  if (query === undefined) {
    return {}
  }
  const nodes = flatNodes(query.tree)
  return nodes.toObject((node) => {
    const color = nodeNamesWithColors[node.name]
    if (color === undefined) {
      return [undefined, undefined]
    }
    return [node.write?.streamName ?? '', color]
  })
}

function usePropertyLabel() {
  const query = useQueryValue()
  if (query === undefined) {
    return {}
  }

  return {
    ...query.fields.toObject((field) => [field.write?.propertySourceName ?? '', field.meta.label.split('(').first()!]),
    ...query.fields.toObject((field) => [
      field.meta.dependedPropertyNamesWithStreamName.first()?.propertyName ?? '',
      field.meta.label,
    ]),
  }
}
