import { EditOutlined, SaveOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { UpdateSourceDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { Button, Form, Input, Modal } from 'antd'
import { t } from 'i18next'

interface FormValue {
  name: string
}

export const SourceEditButton = ({
  sourceName,
  sourceId,
  onCompleted,
  onError,
}: {
  sourceName: string
  sourceId: string
  onCompleted: () => void
  onError: () => void
}) => {
  const [modal, contextHolder] = Modal.useModal()
  const [form] = Form.useForm<FormValue>()
  const [updateSourceMutation, { loading }] = useMutation(UpdateSourceDocument)

  return (
    <>
      {contextHolder}
      <Button
        onClick={() => {
          void modal.confirm({
            width: '600px',
            title: t('連携先名称の編集'),
            content: (
              <>
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={async (value) => {
                    await updateSourceMutation({
                      variables: {
                        organizationId: getOrganizationIdFromPath(),
                        id: sourceId,
                        name: value.name,
                      },
                      onCompleted,
                      onError,
                    })
                  }}
                >
                  <Form.Item name="name" initialValue={sourceName}>
                    <Input showCount maxLength={30} />
                  </Form.Item>
                </Form>
              </>
            ),
            maskClosable: true,
            okText: (
              <>
                <SaveOutlined />
                {t('保存')}
              </>
            ),
            onOk: () => {
              form.submit()
            },
          })
        }}
        icon={<EditOutlined />}
      >
        {t(`編集`)}
      </Button>
    </>
  )
}
