import { useEffect } from 'react'

import { useRowHeightState } from '../../../recoil/navigation/hooks'
import { useRootRefValue as useRootReferenceValue } from '../../recoil/models/propModels'
import { useClickCurrentCursorMutation } from '../../recoil/mutations/cursor/clickCurrentCursorMutation'
import { useCursorMutation } from '../../recoil/mutations/useCursorMutation'
import { useColumnBlocksRelatedSelector } from '../../recoil/selectors/columnBlockRelatedSelector'
import { useCursorSelector } from '../../recoil/selectors/cursorSelector'
import type { RSheetColumn, RSheetRecordNode } from '../../types'
import type { RSheetsCursor } from '../../types/CursorTypes'
import { RSheetsStyle } from '../../util/RSheetsStyle'

export function MainCursorBox() {
  const { cursor, column, position } = useCursorSelector()
  const clickCurrentCursorMutation = useClickCurrentCursorMutation()

  if (cursor === undefined || column === undefined || position === undefined) {
    return <></>
  }

  return (
    <MainCursorBoxBody
      mainCursor={cursor.main}
      expand={cursor.expand}
      column={column}
      fixedLeftColumnIndex={position.fixedLeftColumnIndex}
      onClick={() => {
        clickCurrentCursorMutation({})
      }}
      x={position.x}
      y={position.y}
      offsetX={position.offsetX}
      width={position.width}
      height={position.height}
      fixedLeftColumnWidth={position.fixedLeftColumnWidth}
    />
  )
}

// useEffectを使うために分割したが、冗長で厳しい
function MainCursorBoxBody<T extends RSheetRecordNode>({
  mainCursor,
  expand,
  column,
  fixedLeftColumnIndex,
  onClick,
  x,
  y,
  offsetX,
  width,
  height,
  fixedLeftColumnWidth,
}: {
  mainCursor: RSheetsCursor['main']
  expand: RSheetsCursor['expand']
  column: RSheetColumn<T>
  fixedLeftColumnIndex: number
  x: number
  y: number
  offsetX: number
  width: number
  height: number
  fixedLeftColumnWidth: number
  onClick: () => void
}) {
  const { rowHeight } = useRowHeightState()
  const cursorMutation = useCursorMutation()
  const rootReference = useRootReferenceValue()
  const columnBlocksRelated = useColumnBlocksRelatedSelector()

  useEffect(() => {
    if (rootReference.current !== null) {
      handleScroll({
        rowHeight,
        fixedLeftColumnWidth,
        cursorLeftX: x,
        cursorTopY: y,
        offsetX,
        cursorWidth: width,
        cursorHeight: height,
        rootDivElement: rootReference.current,
        headerHeight: columnBlocksRelated.headerHeight,
      })
    }
  }, [x, y, width, height])

  return (
    <div
      style={{
        position: 'absolute',
        top: y,
        left: x,
        width,
        height,
        border: expand === undefined ? `2px #3274E0 solid` : undefined,
        borderRadius: 4,
        pointerEvents: 'none',
        transition: RSheetsStyle.cursorTransition,
        zIndex:
          mainCursor.columnIndex < fixedLeftColumnIndex
            ? RSheetsStyle.zIndex.cursorWhenLeftFixed
            : RSheetsStyle.zIndex.cursor,
      }}
      onClick={onClick}
      onContextMenu={(e) => {
        const contextMenuPosition = {
          x: e.clientX,
          y: e.clientY,
        }
        cursorMutation.setContextMenuPosition(mainCursor, contextMenuPosition)
        e.preventDefault()
        e.stopPropagation()
      }}
    ></div>
  )
}

function handleScroll({
  rowHeight,
  cursorLeftX,
  cursorTopY,
  offsetX,
  cursorWidth,
  cursorHeight,
  fixedLeftColumnWidth,
  rootDivElement,
  headerHeight,
}: {
  rowHeight: number
  cursorLeftX: number
  cursorTopY: number
  offsetX: number
  cursorWidth: number
  cursorHeight: number
  fixedLeftColumnWidth: number
  headerHeight: number
  rootDivElement: HTMLDivElement
}) {
  const cursorRightX = cursorLeftX + cursorWidth
  const areaWidth = rootDivElement.clientWidth
  const areaLeftX = rootDivElement.scrollLeft
  const areaRightX = areaLeftX + areaWidth

  const cursorBottomY = cursorTopY + cursorHeight
  const areaHeight = rootDivElement.clientHeight
  const areaTopY = rootDivElement.scrollTop
  const areaBottomY = areaTopY + areaHeight

  // カーソル(+オフセット)が表示領域の右側にいってしまった場合
  if (areaRightX <= cursorRightX + offsetX) {
    // カーソル幅が画面表示領域よりも大きい場合、特別に左揃え
    if (cursorWidth >= areaWidth) {
      rootDivElement.scrollTo({ left: cursorLeftX, behavior: undefined })
      // それ以外の場合、基本的にはカーソルの右端と表示領域の右端を揃える
    } else {
      rootDivElement.scrollTo({ left: cursorRightX - areaWidth + offsetX, behavior: undefined })
      // rootDivElement.scrollTo({ left: cursorLeftX - offsetX, behavior: undefined })
    }
    // カーソルが表示領域の左側にいってしまった場合
  } else if (cursorLeftX - fixedLeftColumnWidth <= areaLeftX) {
    rootDivElement.scrollTo({ left: cursorLeftX - fixedLeftColumnWidth, behavior: undefined })
  }

  // カーソルが表示領域の下側にいってしまった場合
  if (areaBottomY <= cursorBottomY) {
    // カーソル高が表示高よりも大きい場合、上に揃える
    if (cursorHeight >= areaHeight) {
      rootDivElement.scrollTo({ top: cursorTopY - rowHeight, behavior: undefined })
    } else {
      rootDivElement.scrollTo({ top: cursorBottomY - areaHeight, behavior: undefined }) // カーソルの下端が表示領域の下端となるようにする
    }
    // カーソルが表示領域の上側にいってしまった場合
  } else if (cursorTopY - headerHeight <= areaTopY) {
    rootDivElement.scrollTo({ top: cursorTopY - headerHeight, behavior: undefined })
  }
}
