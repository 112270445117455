import type { SafeParseReturnType } from 'zod'

//
// zodの3.21.1より、safeParseの結果のerrorがgetterとして実装されるようになった
// フロントエンドでrecoilを開発環境で使うと、オブジェクトを再起的にfreezeする挙動をするが、
// このgetterの中のロジックで、オブジェクトを変更する処理があるため、recoilの値としてsafeParseの結果を使うとエラーになる
// これを防ぐため、いったんerrorを呼び出し、先にgetterを実行させてオブジェクトを生成したうえで値を返す
//
// https://github.com/colinhacks/zod/commit/67b981e70b4bacb2278a9aacd22d38cbb4b52d17#diff-c54113cf61ec99691748a3890bfbeb00e10efb3f0a76f03a0fd9ec49072e410aR95-R103
//
export function normalizeZodSafeParseResult<T>(x: SafeParseReturnType<unknown, T>): SafeParseReturnType<unknown, T> {
  if (x.success) {
    return {
      success: true as const,
      data: x.data,
    }
  }
  return {
    success: false as const,
    error: x.error,
  }
}
