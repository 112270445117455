import { cartesianProduct } from '@salescore/buff-common'
import dayjs from 'dayjs'

import type { User } from '../user/types'
import type { UserGroup } from '../user_group/types'
import { GOAL_DIMENSION_EMPTY_VALUE } from './constant'
import { getUserOrUserGroupIds } from './getUserOrUserGroupIds'
import type { Goal, GoalConfig } from './types'
import { validateDateByGoalConfig } from './util'

export function generateGoals({
  goalConfig,
  dates,
  users,
  userGroups,
}: {
  goalConfig: GoalConfig
  dates: Date[]
  users: User[]
  userGroups: UserGroup[]
}) {
  const validatedDates = dates.map((x) => validateDateByGoalConfig(x, goalConfig.timeSpanType)).compact()
  const producted = cartesianProduct([
    validatedDates.uniqueBy((x) => dayjs(x).format(`YYYY-MM-DD`)).sortBy((x) => x.getTime()),
    generateDimensionValues(getUserOrUserGroupIds({ goalConfig, users, userGroups })),
    generateDimensionValues(goalConfig.goalDimension1?.selectOptionsV3.map((x) => x.value) ?? []),
    generateDimensionValues(goalConfig.goalDimension2?.selectOptionsV3.map((x) => x.value) ?? []),
    generateDimensionValues(goalConfig.goalDimension3?.selectOptionsV3.map((x) => x.value) ?? []),
    generateDimensionValues(goalConfig.goalDimension4?.selectOptionsV3.map((x) => x.value) ?? []),
    generateDimensionValues(goalConfig.goalDimension5?.selectOptionsV3.map((x) => x.value) ?? []),
    // goalConfig.kpiViews,
    [{ kpiId: GOAL_DIMENSION_EMPTY_VALUE }], // 全てのKPIについて作ると遅いので諦める
  ])

  return producted.map(
    (x): Goal => ({
      date: x[0],
      user_id: x[1],
      dimension1: x[2],
      dimension2: x[3],
      dimension3: x[4],
      dimension4: x[5],
      dimension5: x[6],
      kpi_id: x[7].kpiId,
      value: 0,
      goal_config_id: goalConfig.id,
    }),
  )
}

function generateDimensionValues(options: string[]) {
  if (options.isBlank()) {
    return [GOAL_DIMENSION_EMPTY_VALUE]
  }
  return options.unique()
}
