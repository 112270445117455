export const RSheetsStyle = {
  // 移動アニメーション。重い環境の人がいれば切る
  // transition: 'all 0.2s ease-out'
  // transition: 'all 0.2s cubic-bezier(.215, .61, .355, 1)' // ; /* easeOutCubic */
  // transition: 'all 0.2s cubic-bezier(.19, 1, .22, 1)' // /* easeOutExpo */
  // cellTransition: 'all 0.6s cubic-bezier(.23, 1, .32, 1)', // ; /* easeOutQuint */
  cellTransition: undefined, // 微妙になりがちなのでやめる
  // cellTransition: 'all 0.3s cubic-bezier(.215, .61, .355, 1)', // ; /* easeOutCubic */
  // cellTransition: 'all 0.2s ease-out', // ; /* easeOutCubic */
  cursorTransition: 'all 0.2s cubic-bezier(.23, 1, .32, 1)', // ; /* easeOutQuint */

  color: {
    backgroundColor: `#FAFBFC`,
    border: '#EDF1F2',
    borderBlack: '#BDD1D2',
    error: '#FEE2E2',
    gray: '#ddd',
    kpiTotalCell: '#F6FCFF',
    kpiSubTotalCell: '#F6F6F6',
    sortedCell: '#fefce8',
    // kpiTotalCell: '#EFF7FC', // sf
    kpiCell: '#fff',
  },

  border: {
    rightEdgeColumn: '3px double #ddd',
  },

  zIndex: {
    // 最上位レイヤ
    cellInputDropdown: 41, // 一応指定はしているものの、bodyでこれを呼んでもbodyのzIndexを引き継ぐので意味がない
    contextMenu: 40,
    bodyLoadingSpinner: 20,

    // ヘッダーと固定カラム
    headerRowWhenLeftFixed: 24,
    headerRow: 23,

    cursorWhenLeftFixed: 21,
    bodyRowWhenLeftFixed: 18,

    // フッター(ヘッダのコンテキストメニューのzIndexはヘッダーと同じになるので、ヘッダーより少なくする必要)
    fixedFooterRowWhenLeftFixed: 20,
    fixedFooterRow: 19,
    // カーソル
    cursor: 10,
    // それ以下（通常のセル）
  },
}
