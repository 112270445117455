import type { ViewConfigField } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { meRelatedSelector } from '../../selectors/meRelatedSelector'
import { viewSelector } from '../../selectors/viewSelector'
import { setConfigMutation } from '../setConfigMutation'
import { isSameNodeProperty } from './util'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Argument = {
  field: ViewConfigField
}

export const setFieldMutation = mutation<Argument>({
  key: `view/setFieldMutation`,
  set({ get, set }, { field }) {
    const { permission, me } = get(meRelatedSelector)
    if (!permission.view.canEditField) {
      return // 本来辿り着かないはずなので、特にメッセージなどは出さない（出したいが、recoilの中でメッセージを表示させる機構が現状ない）
    }
    const { config } = get(viewSelector)
    const fields = config.fields ?? []

    const targetIndex = fields.findIndex((x) => isSameNodeProperty(x.property, field.property))
    if (targetIndex !== -1) {
      set(setConfigMutation, {
        ...config,
        fields: [...fields.slice(0, targetIndex), field, ...fields.slice(targetIndex + 1)],
      })
    }
  },
})
