import { isNull, isPresent, isSome } from '@salescore/buff-common'
import {
  generateFieldName,
  type ModelProperty,
  type ViewConfigField,
  type ViewConfigTreeNode,
  type ViewQueryField,
  type ViewQueryRecordNode,
} from '@salescore/core'
import { Button, Checkbox, Col, Form, InputNumber, message, Row, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import type { SearchResultRow } from '../../../../../domain/service/generateSearchSql'
import { useSearchQueue } from '../../../../../recoil/hooks/searchRelation'
import { useSheetColumns } from '../../../../../recoil/selectors/useSheetColumns'
import { useFieldMutations } from '../../../../../recoil/view/mutations/fieldMutations'
import { useConnectionsSelector } from '../../../../../recoil/view/selectors/connectionsSelector'
import { useViewSelector } from '../../../../../recoil/view/selectors/viewSelector'
import type { RSheetColumn } from '../../../../../rsheet/types'

export const FieldDefaultValuesForm = ({
  node,
  onAfterFinish,
}: {
  node: ViewConfigTreeNode
  onAfterFinish: () => void
}) => {
  const { config, view } = useViewSelector()
  const columns = useSheetColumns()
  const { getModelProperty } = useConnectionsSelector()
  const mutation = useFieldMutations()
  const [fields, setFields] = useState(() =>
    (config.fields ?? [])
      .map((field) => {
        const property = getModelProperty(field.property.modelName, field.property.propertyName)
        if (isNull(property)) {
          return
        }
        return {
          field,
          property,
        }
      })
      .compact()
      .filter(
        ({ field, property }) =>
          field.property.nodeName === node.name && isSome(property.write) && property.creatable !== false,
      ),
  )
  const onFinish = () => {
    mutation.setFieldDefaultValues({ fieldsWithDefaultValue: fields.map((x) => x.field) })
    void message.info(t(`デフォルト値を更新しました`))
    onAfterFinish()
  }
  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Row gutter={16}>
        {fields.map(({ field, property }, index) => {
          // XXX: columnsとfieldsが対応するかどうか保証がないが、現状のロジック上は一致するはずなのでこうしている。
          const column = columns.find((column) => column.field?.name === generateFieldName(field.property))
          return (
            <Col span={8}>
              <Form.Item label={field.override?.uiColumn?.label ?? property.label}>
                <FieldDefaultValueInput
                  field={field}
                  column={column}
                  property={property}
                  setDefaultValue={(defaultValue) => {
                    setFields((fields) => {
                      const newField: ViewConfigField = {
                        ...field,
                        override: {
                          ...field.override,
                          queryField: {
                            ...field.override?.queryField,
                            // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                            meta: {
                              ...field.override?.queryField?.meta,
                              defaultValue: isPresent(defaultValue) ? defaultValue : undefined,
                            } as ViewQueryField['meta'], // TODO
                          },
                        },
                      }
                      return [
                        ...fields.slice(0, index),
                        {
                          field: newField,
                          property,
                        },
                        ...fields.slice(index + 1),
                      ]
                    })
                  }}
                />
              </Form.Item>
            </Col>
          )
        })}
      </Row>
      <Row justify="end">
        <Form.Item>
          <Button htmlType="submit">{t(`適用`)}</Button>
        </Form.Item>
      </Row>
    </Form>
  )
}

type SetDefaultValue = (defaultValue: string | number | boolean | undefined) => void

interface Argument {
  field: ViewConfigField
  property: ModelProperty
  column: RSheetColumn<ViewQueryRecordNode> | undefined
  setDefaultValue: SetDefaultValue
}

function FieldDefaultValueInput(argument: Argument) {
  const { field, property } = argument
  switch (property.meta) {
    // case 'record_url':
    //   return <RecordUrlInput {...arg} />
    case 'relation': {
      return <ReferenceInput {...argument} />
    }
    // case 'url':
    //   return <UrlInput {...arg} />
    // case 'phone_number':
    //   return <UrlInput {...arg} />
    // case 'email':
    //   return <UrlInput {...arg} />
    // case 'html':
    //   return <HtmlInput {...arg} />
    case 'text': {
      return <TextInput {...argument} />
    }
  }

  if ((property.selectOptions ?? []).length > 0) {
    // if (field.meta.fieldMetaType === 'multi_select') {
    //   return <MultiSelectOptionInput {...arg} />
    // }
    return <SelectOptionInput {...argument} />
  }

  switch (property.type) {
    case 'integer' as const: {
      return <NumberInput {...argument} />
    }
    case 'numeric' as const: {
      return <NumberInput {...argument} />
    }
    case 'date' as const: {
      return <DateInput {...argument} />
    }
    case 'datetime' as const: {
      return <DateTimeInput {...argument} />
    }
    case 'time' as const: {
      return <TimeInput {...argument} />
    }
    case 'boolean' as const: {
      return <BooleanInput {...argument} />
    }
    // system系
    // case 'checkbox' as const:
    //   return <BooleanInput {...arg} />
    // case 'open' as const:
    //   return <OpenInput {...arg} />
    // case 'rowIndex' as const:
    //   return <span className="rsheet-cell-body-imvalid-row-index" />
    default: {
      // const x: never = column.type
      return <TextInput {...argument} />
    }
  }
}

// function StringInput({ field, setDefaultValue }: Arg) {
//   useEffect(() => {
//     if (
//       field.override?.queryField?.meta?.defaultValue !== undefined &&
//       typeof field.override?.queryField?.meta?.defaultValue !== 'string'
//     ) {
//       setDefaultValue(undefined)
//     }
//   }, [])

//   return (
//     <Input
//       allowClear
//       value={
//         typeof field.override?.queryField?.meta?.defaultValue === 'string'
//           ? field.override?.queryField?.meta?.defaultValue
//           : undefined
//       }
//       placeholder="デフォルト値なし"
//       onChange={(e) => {
//         const value = e.target.value
//         setDefaultValue(value)
//       }}
//     />
//   )
// }

function TextInput({ field, setDefaultValue, property }: Argument) {
  useEffect(() => {
    if (
      field.override?.queryField?.meta?.defaultValue !== undefined &&
      typeof field.override.queryField.meta.defaultValue !== 'string'
    ) {
      setDefaultValue(undefined)
    }
  }, [])

  return (
    <TextArea
      rows={property.meta === 'text' ? 2 : 1}
      allowClear
      value={
        typeof field.override?.queryField?.meta?.defaultValue === 'string'
          ? field.override.queryField.meta.defaultValue
          : undefined
      }
      placeholder={t(`デフォルト値なし`)}
      onChange={(e) => {
        const value = e.target.value
        setDefaultValue(value)
      }}
    />
  )
}

function NumberInput({ field, setDefaultValue }: Argument) {
  useEffect(() => {
    if (
      field.override?.queryField?.meta?.defaultValue !== undefined &&
      typeof field.override.queryField.meta.defaultValue !== 'number'
    ) {
      setDefaultValue(undefined)
    }
  }, [])
  return (
    <InputNumber
      style={{ width: '100%' }}
      value={
        typeof field.override?.queryField?.meta?.defaultValue === 'number'
          ? field.override.queryField.meta.defaultValue
          : undefined
      }
      placeholder={t(`デフォルト値なし`)}
      onChange={(value) => {
        setDefaultValue(value ?? undefined)
      }}
    />
  )
}

function BooleanInput({ field, setDefaultValue }: Argument) {
  useEffect(() => {
    if (
      field.override?.queryField?.meta?.defaultValue !== undefined &&
      typeof field.override.queryField.meta.defaultValue !== 'boolean'
    ) {
      setDefaultValue(undefined)
    }
  }, [])

  return (
    <Checkbox
      value={
        typeof field.override?.queryField?.meta?.defaultValue === 'boolean'
          ? field.override.queryField.meta.defaultValue
          : undefined
      }
      checked={
        typeof field.override?.queryField?.meta?.defaultValue === 'boolean'
          ? field.override.queryField.meta.defaultValue
          : undefined
      }
      onChange={(e) => {
        setDefaultValue(e.target.checked)
      }}
    />
  )
}

function NotSupportedTypeInput() {
  return <div className="text-xs text-gray-400">{t(`現在サポートされていません`)}</div>
}

function DateInput({ field, setDefaultValue }: Argument) {
  // TODO: 特定の日付にするのではなく、相対日付を選ぶ
  // return <Select options={[]} />
  return <NotSupportedTypeInput />
}

function DateTimeInput({ field, setDefaultValue }: Argument) {
  // TODO: 特定の日付にするのではなく、相対日付を選ぶ
  // return <Select options={[]} />
  return <NotSupportedTypeInput />
}

function TimeInput({ field, setDefaultValue }: Argument) {
  // TODO: 特定の日付にするのではなく、相対日付を選ぶ
  // return <Select options={[]} />
  return <NotSupportedTypeInput />
}

function SelectOptionInput({ field, property, setDefaultValue }: Argument) {
  const options = property.selectOptions ?? []
  useEffect(() => {
    const selectedOption = options.find((option) => option.value === field.override?.queryField?.meta?.defaultValue)
    if (field.override?.queryField?.meta?.defaultValue !== undefined && selectedOption === undefined) {
      setDefaultValue(undefined)
    }
  }, [])
  return (
    <Select
      allowClear
      placeholder={t(`デフォルト値なし`)}
      options={options}
      value={field.override?.queryField?.meta?.defaultValue}
      onChange={(value) => {
        setDefaultValue(value)
      }}
    />
  )
}

// TODO
type SearchResultRowWithKey = SearchResultRow & {
  key: string
}

function ReferenceInput(argument: Argument) {
  const { field, column, setDefaultValue } = argument
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SearchResultRowWithKey[]>([])
  const { queueSearch, search } = useSearchQueue({
    setLoading,
    setOptions: (options) => {
      setOptions(options.map((x) => ({ ...x, key: x.value })))
    },
  })

  const searchSql = column?.field?.read.searchSql ?? column?.node.read.join?.search.sql

  useEffect(() => {
    if (options.length === 0 && isSome(searchSql)) {
      void search(searchSql, '')
    }
  }, [])

  if (searchSql === undefined) {
    return <TextInput {...argument} />
  }

  return (
    <Select
      loading={loading}
      allowClear
      placeholder={t(`デフォルト値なし`)}
      showSearch
      filterOption={false}
      onChange={(value) => {
        setDefaultValue(value)
      }}
      onSearch={(searchKey) => {
        if (isSome(searchSql)) {
          queueSearch(searchSql, searchKey)
        }
      }}
      value={field.override?.queryField?.meta?.defaultValue}
    >
      {options.map((option) => (
        <Select.Option key={option.key} value={option.value}>
          {option.label}
          <span className="ml-2 text-gray-400">
            {isSome(option.meta_label) ? '- ' : ''}
            {option.meta_label}
          </span>
        </Select.Option>
      ))}
    </Select>
  )
}
