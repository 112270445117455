export class NotImplementedError extends Error {
  public constructor(message?: string) {
    super(message)
    this.name = 'NotImplementedError'
  }
}

export class ImplementationError extends Error {
  public constructor(message?: string) {
    super(message)
    this.name = 'ImplementationError'
  }
}

export class ValidationError extends Error {
  public constructor(message?: string) {
    super(message)
    this.name = 'ValidationError'
  }
}
