//
// 雑多なフラグ管理をlocalStorageで行う（いちいちサーバーサイドに追加したくないようなフラグ）
// migrationでデータが消失する可能性もあるので、それでも構わないような設定のみを管理すること
//

import { z } from 'zod'

const flagSchema = z.object({
  shouldPreventPopstate: z.boolean().optional(),
})
export type LocalStorageFlag = z.infer<typeof flagSchema>
const key = `localStorageFlags`

export function getLocalStorageFlags() {
  const data = localStorage.getItem(key)
  try {
    const result = flagSchema.safeParse(JSON.parse(data ?? ''))
    if (result.success) {
      return result.data
    }
  } catch {}
}

export function setLocalStorageFlags(data: LocalStorageFlag) {
  localStorage.setItem(key, JSON.stringify(data))
  const result = flagSchema.safeParse(data)
  if (result.success) {
    return result.data
  }
}
