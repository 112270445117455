import { isNull } from '@salescore/buff-common'
import type { ViewConfigSheetExtraPeriod } from '@salescore/core'
import type { Period } from '@salescore/features'
import dayjs from 'dayjs'

export function usePeriod() {
  return { removeAbsolutePeriod, serializePeriod, deserializePeriod }
}

/**
 * カスタム以外のPeriodからstartAt,endAtを削除する
 */
function removeAbsolutePeriod<T extends Period | ViewConfigSheetExtraPeriod>(period?: T): T | undefined {
  if (isNull(period)) {
    return
  }

  if (period.name.includes('カスタム')) {
    return period
  }

  return {
    ...period,
    startAt: undefined,
    endAt: undefined,
  }
}

// periodのstartAt,endAtはフロントアプリケーションで使用する時にはDayjs型だが、DBに保存する際にはstring型に変換するので、
// その変換を行うserialize/deserialize関数を用意する
function serializePeriod(period?: Period): ViewConfigSheetExtraPeriod | undefined {
  const formatString = 'YYYY-MM-DD'
  if (isNull(period)) {
    return
  }
  return {
    ...period,
    startAt: period.startAt?.format(formatString),
    endAt: period.endAt?.format(formatString),
  }
}

function deserializePeriod(period?: ViewConfigSheetExtraPeriod): Period | undefined {
  const startAt = period?.startAt
  const endAt = period?.endAt
  if (isNull(period) || isNull(startAt) || isNull(endAt)) {
    return
  }
  return {
    ...period,
    startAt: dayjs(startAt),
    endAt: dayjs(endAt),
  }
}
