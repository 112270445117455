import { isNull, isSome } from '@salescore/buff-common'
import type { ViewGroup } from '@salescore/client-api'
import { selector, useRecoilValue } from 'recoil'

import { viewGroupsAtom, viewsAtom } from '../atoms'

const viewsRelatedSelector = selector({
  key: 'recoil-pages/viewsRelatedSelector',
  get: ({ get }) => {
    const viewGroups = get(viewGroupsAtom)
    const views = get(viewsAtom)
    const viewsMap = views.groupBy((x) => x.id).transformValues((vs) => vs.first()!).data
    const viewGroupsMap = viewGroups.groupByUniqueKey((x) => x.id)
    const viewGroupsWithParents = viewGroups.map((viewGroup) => ({
      viewGroup,
      parents: searchViewGroupParents(viewGroup.viewGroupId, viewGroupsMap),
    }))

    return {
      views,
      viewGroups,
      viewsMap,
      viewGroupsMap,
      viewGroupsWithParents,
      searchView: (viewId: string) => viewsMap[viewId],
    }
  },
})

export const useViewsRelated = () => useRecoilValue(viewsRelatedSelector)

function searchViewGroupParents(
  id: string | undefined | null,
  mapper: Record<string, ViewGroup>,
  depth?: number,
): ViewGroup[] {
  if ((depth ?? 0) > 20) {
    // ありえないはずだが、循環参照があった場合に無限ループになるので、念のため
    return []
  }
  if (isNull(id)) {
    return []
  }
  const parent = mapper[id]
  return isSome(parent) ? [parent, ...searchViewGroupParents(parent.viewGroupId, mapper, (depth ?? 0) + 1)] : []
}
