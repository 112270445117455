import { globalRecoil } from './global'
import { settingRecoil } from './setting'
import { siderRecoil } from './sider'

export type { MoveViewOrViewGroupModalAtomArg } from './sider/atoms'
export type { ViewOrViewGroupModalArg } from './sider/atoms'
export type { ExpandedAntdNode } from './sider/functions/convertFlatNodesToAntdTree'

export const recoil = {
  sider: siderRecoil,
  global: globalRecoil,
  setting: settingRecoil,
}

// 本来はrecoilからはhooks以外をexportしたくないが、
// 「recoil内で定義したが他でも使いたい関数」が一部存在しているためexport。
// 既存ロジックをrecoil側に移行できれば、このexportも不要になるはず
export * from './functions/checkPlan'

// recoilにアクセスするレイヤーを制限するため、他のパッケージではrecoilをインストールしない
// RecoilRootだけはトップレイヤーで必要になるが、ここでもrecoilをインストールさせないため、ここからexportする
export { type Me } from './global/atoms'
export type { LauncherCommandV2 } from './global/launcher/launcherCommandV2'
export { type UrlQueryParameterMap } from './sider/atoms'
export { RecoilRoot } from 'recoil'
