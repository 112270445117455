import type { ViewConfigField, ViewQueryField } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { configSheetAtom, hasChangeToViewSchemaAtom } from '../../atoms'
import { setConfigMutation } from '../setConfigMutation'
import { isSameField } from './util'

export const setQueryFieldMutation = mutation({
  key: `view/setQueryFieldMutation`,
  set(
    { get, set },
    {
      field,
      override,
    }: { field: ViewQueryField; override: Exclude<ViewConfigField['override'], undefined>['queryField'] },
  ) {
    const config = get(configSheetAtom)
    const { fields } = config

    if (fields === undefined) {
      return
    }
    // oldValueはreadonlyになっているので、一旦jsonにして回避している // TODO: 他の解決策はあるか？
    const newFields = structuredClone(fields)
    const fieldIndex = newFields.findIndex((x) => isSameField(x, field))
    if (fieldIndex === -1) {
      return
    }
    newFields[fieldIndex]!.override = {
      ...newFields[fieldIndex]!.override,
      queryField: {
        ...newFields[fieldIndex]!.override?.queryField,
        ...override,
      },
    }

    set(setConfigMutation, {
      ...config,
      fields: newFields,
    })
    set(hasChangeToViewSchemaAtom, true) // TODO: これ不要にしたい
  },
})
