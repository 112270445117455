import { PlayCircleOutlined, PlusCircleFilled } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { CONSTANT } from '@salescore/client-base'
import {
  type ConditionalEffectConfig,
  conditionalEffectConfigSchema,
  CoreDsl,
  type ViewConfigField,
} from '@salescore/core'
import { JsonSyntaxHighlighter, useBooleanState, useModal, useModalAtom } from '@salescore/frontend-common'
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Divider,
  Empty,
  Form,
  type FormInstance,
  type FormListFieldData,
  message,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { z } from 'zod'

import { conditionalEffectsFormModalAtom } from '../../../recoil/navigation/atoms'
import { useRecordsValue } from '../../../recoil/records/hooks'
import { useFieldMutations } from '../../../recoil/view/mutations/fieldMutations'
import { useRowRelatedSelector } from '../../recoil/selectors/rowRelatedSelector'
import { ConditionalEffectExpressionEditor } from './ConditionalEffectExpressionEditor'

export const ConditionalEffectsFormModal = () => {
  const conditionalEffectsFormModal = useModalAtom(conditionalEffectsFormModalAtom)
  return (
    <Modal
      open={conditionalEffectsFormModal.isModalVisible} // TODO
      onCancel={() => {
        conditionalEffectsFormModal.hideModal()
      }}
      width={'80%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
        padding: 0,
      }}
      bodyStyle={
        {
          // backgroundColor: "#eee"
        }
      }
      // title={'集計方法の編集'}
      destroyOnClose
      footer={<></>}
    >
      <PageHeader title={t(`条件付き書式設定`)}>
        {conditionalEffectsFormModal.content !== undefined && (
          <ConditionalEffectsForm
            configField={conditionalEffectsFormModal.content.configField}
            onFinish={() => {
              conditionalEffectsFormModal.hideModal()
            }}
          />
        )}
      </PageHeader>
    </Modal>
  )
}

const defaultEffect: ConditionalEffectConfig = {
  effect: 'disable',
  expression: `equal(1, 1)`,
}

function ConditionalEffectsForm({ configField, onFinish }: { configField: ViewConfigField; onFinish: () => void }) {
  const mutation = useFieldMutations()
  const effects = configField.conditionalEffects ?? []
  const [form] = Form.useForm()

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        effects: effects.isPresent() ? effects : [defaultEffect],
      }}
      onFinish={(values) => {
        const effects = z.object({ effects: conditionalEffectConfigSchema.array() }).safeParse(values)
        if (!effects.success) {
          void message.error(t(`書式が間違っています`))
          return
        }
        mutation.setField({
          field: {
            ...configField,
            conditionalEffects: effects.data.effects,
          },
        })
        void message.success(t(`条件付き書式を設定しました`))
        onFinish()
      }}
    >
      <Form.List name="effects">
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <>
                <FormItems field={field} configField={configField} form={form} />
                {fields.length - 1 !== index && <Divider />}
              </>
            ))}
            <Row justify="center">
              <Space>
                <Button
                  type="text"
                  icon={<PlusCircleFilled />}
                  color={CONSTANT.colors.primaryColor}
                  style={{
                    color: CONSTANT.colors.primaryColor,
                  }}
                  onClick={() => {
                    add(defaultEffect)
                  }}
                >
                  {t(`効果を追加`)}
                </Button>
              </Space>
            </Row>
          </>
        )}
      </Form.List>
      <Divider />
      <Row justify="end" className="mt-4">
        <Space>
          <Button htmlType="submit">{t(`適用`)}</Button>
        </Space>
      </Row>
    </Form>
  )
}

function FormItems({
  form,
  field,
  configField,
}: {
  form: FormInstance
  field: FormListFieldData
  configField: ViewConfigField
}) {
  const [result, setResult] = useState<unknown>()
  const records = useRecordsValue()
  const { getInnerCells } = useRowRelatedSelector(records.first()!.id!) // TODO
  const sheetCellGroup = getInnerCells(0) // TODO: columnIndex
  const recordNode = sheetCellGroup[0]?.recordNode
  const confirmModal = useModal()
  const shouldIgnoreModal = useBooleanState()

  const execute = () => {
    const expression = form.getFieldValue([`effects`, field.name, `expression`]) as string
    if (typeof expression !== 'string') {
      void message.warning(t(`式を定義してください`))
      return
    }
    if (recordNode === undefined) {
      return
    }

    try {
      const result = CoreDsl.executeWithRecordNode(expression, recordNode)
      if (result.success) {
        setResult(result.data)
      } else {
        setResult('')
        void message.error(`${t(`エラーが発生しました。`)}${JSON.stringify(result.error)}`)
      }
    } catch (error) {
      if (error instanceof Error) {
        void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
      } else {
        void message.error(t(`エラーが発生しました。`))
      }
    }
  }

  const height = 160
  return (
    <Row>
      <Col span={8}>
        <Form.Item
          label={t(`効果`)}
          name={[field.name, 'effect']}
          rules={[{ required: true, message: t(`効果を入力してください`) }]}
        >
          <Select
            options={[
              {
                value: `disable`,
                label: t(`書き込み禁止`),
              },
              {
                value: `emphasis`,
                label: t(`強調`),
              },
            ]}
          />
        </Form.Item>
      </Col>
      <Col span={19}>
        {/* TODO: 中途半端にコンポーネント分割してしまった感 */}
        <ConditionalEffectExpressionEditor
          field={field}
          configField={configField}
          height={height}
          onExecute={() => {
            if (shouldIgnoreModal.isTrue) {
              execute()
            } else {
              confirmModal.showModal()
            }
          }}
        />
      </Col>
      <Col
        span={5}
        className=""
        style={{
          backgroundColor: `#2E2E2E`,
          color: `white`,
          height,
          borderLeft: `1px solid #444`,
        }}
      >
        <div
          className="header flex items-center px-2 align-middle opacity-80"
          style={{ height: 32, borderBottom: `1px solid #444` }}
        >
          {t(`実行結果`)}
        </div>
        {/* <Button onClick={execute}>実行</Button> */}
        <div className="p-2">{JSON.stringify(result) ?? ''}</div>
      </Col>
      <Modal
        open={confirmModal.isModalVisible}
        onCancel={confirmModal.hideModal}
        width={'60%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        footer={null}
        // style={{ top: '3%' }}
      >
        <Alert
          type="info"
          showIcon
          description={<div>{t(`現在の列の1行目のレコードの値を変数として、定義した関数を実行します。`)}</div>}
        />
        <Row justify="end" className="my-4">
          <Space className="mr-4">
            <Checkbox
              onChange={(e) => {
                shouldIgnoreModal.toggle()
              }}
            />
            {t(`このメッセージを今後表示しない`)}
          </Space>

          <Button
            type="primary"
            icon={<PlayCircleOutlined />}
            onClick={() => {
              execute()
              confirmModal.hideModal()
            }}
          >
            {t(`実行`)}
          </Button>
        </Row>

        <div
          style={{
            maxHeight: 500,
            overflowY: 'scroll',
          }}
        >
          <div className="mb-2">{t(`変数として代入される値`)}</div>
          {recordNode === undefined ? (
            <Empty description={t(`レコードがないため、変数がない状態で実行します。`)} />
          ) : (
            <JsonSyntaxHighlighter jsonString={JSON.stringify(recordNode)} />
          )}
        </div>
      </Modal>
    </Row>
  )
}
