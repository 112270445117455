import type { PolicyAction } from '../action'
import { memberRolePolicyDefinition } from './memberRolePolicyDefinition'

// NX様向け一般ユーザー：ビューの保存ができない以外、通常メンバーと同じ
export const nxMemberRolePolicyDefinition: Record<PolicyAction, boolean> = {
  ...memberRolePolicyDefinition,
  [`sheet-update`]: false,
  [`sheet-open-relation-input-form`]: false,
  [`kpi-update`]: false,
  [`kpi-pivot-update`]: false,
  [`sheet-create`]: false,
  [`kpi-create`]: false,
  [`kpi-pivot-create`]: false,
  [`sheet-delete`]: false,
  [`kpi-delete`]: false,
  [`kpi-pivot-delete`]: false,
}
