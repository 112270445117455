import { useQuery } from '@apollo/client'
import { isPresent } from '@salescore/buff-common'
import { FetchSalescoreJwtDocument } from '@salescore/client-api'
import { useSalescoreAuth } from '@salescore/client-base'
import { logger, useRedirect } from '@salescore/frontend-common'
import { useEffect } from 'react'

//
// クエリパラメータにoauthCode,providerがあるとき、
// Auth0を使わずにSALESCORE側で認証を行う
//
export const SignInByOauthCode = ({ oauthCode, provider }: { oauthCode: string; provider: string }) => {
  const fetchSalescoreJwtQuery = useQuery(FetchSalescoreJwtDocument, {
    variables: {
      type: 'oauth',
      oauthCode,
      provider,
    },
    fetchPolicy: 'no-cache',
  })
  const { setToken } = useSalescoreAuth()
  const redirect = useRedirect()

  useEffect(() => {
    const jwt = fetchSalescoreJwtQuery.data?.salescoreJwt
    if (isPresent(jwt)) {
      setToken(jwt)
      redirect('/')
    }
  }, [fetchSalescoreJwtQuery.data])

  logger.debug(`SignInBySalesforceSession`, {
    oauthCode,
    provider,
    fetchSalescoreJwtQuery,
  })

  return <></>
}
