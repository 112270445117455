import { BranchesOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import type { UserSourceFieldsFragment } from '@salescore/client-api'
import { DeleteUserSourceDocument, FetchUserSourcesDocument, SourceProviderEnum } from '@salescore/client-api'
import { getOrganizationIdFromPath, ProviderLogo, useOpenAuthorizationUrl } from '@salescore/client-common'
import { createDestroyColumn } from '@salescore/frontend-common'
import { App, Button, Row, Table } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { CreateUserSourceConfirmModal } from './CreateUserSourceConfirmModal'
import { CreateUserSourceModalContent } from './CreateUserSourceModalContent'

export const UserSourcesTable = (): JSX.Element => {
  const { modal } = App.useApp()
  const { data, refetch } = useQuery(FetchUserSourcesDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })
  const sources = data?.sources ?? []
  const userSources = data?.userSources ?? []
  const [deleteUserSourceMutation] = useMutation(DeleteUserSourceDocument)
  const { openAuthorizationUrl } = useOpenAuthorizationUrl()
  const [loading, setLoading] = useState(false)
  return (
    <>
      <Table
        rowKey="provider"
        title={() => (
          <>
            <div className="font-bold">{t(`連携中のサービス`)}</div>
            <Row justify={'end'}>
              <Button
                key="button-create-source"
                type="primary"
                onClick={() => {
                  const m = modal.info({
                    title: t(`連携を編集`),
                    width: 'min(80%, 1200px)',
                    content: (
                      <CreateUserSourceModalContent
                        afterFinish={() => {
                          m.destroy()
                          void refetch()
                        }}
                        sources={sources.filter(
                          (source) => !userSources.map((x) => x.provider).includes(source.provider),
                        )}
                      />
                    ),
                    maskClosable: true,
                    okText: t(`閉じる`),
                    okButtonProps: {},
                  })
                }}
              >
                {t(`新規作成`)}
              </Button>
            </Row>
          </>
        )}
        size="small"
        columns={[
          {
            title: t(`連携先`),
            key: 'provider',
            width: 220,
            render(_value, record) {
              return <ProviderLogo width={60} provider={record.provider} />
            },
          },
          {
            title: t(`連携先名称`),
            key: 'name',
            render(_text, record) {
              return <span>{record.name}</span>
            },
          },
          {
            title: '',
            width: 50,
            render(_value, record) {
              const source = sources.find((x) => x.provider === record.provider) // TODO
              if (isNull(source)) {
                return <></>
              }
              // FIXME: こういう決め打ちコードは書きたくない
              if (record.provider === SourceProviderEnum.Kintone) {
                return <></>
              }

              return (
                <Button
                  icon={<BranchesOutlined />}
                  onClick={() => {
                    void openAuthorizationUrl({ type: 'userSource', provider: record.provider, source })
                  }}
                >
                  {t(`再連携`)}
                </Button>
              )
            },
          },
          createDestroyColumn<UserSourceFieldsFragment>(loading, setLoading, async (record) => {
            await deleteUserSourceMutation({
              variables: {
                id: record.id,
                organizationId: getOrganizationIdFromPath(),
              },
              refetchQueries: ['fetchUserSources'],
            })
          }),
        ]}
        dataSource={userSources}
      />
      <CreateUserSourceConfirmModal />
    </>
  )
}
