import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { compareFunction } from '@salescore/buff-common'
import {
  DeleteEltModelDocument,
  type EltModelFieldsFragment,
  FetchConnectionsDocument,
  FetchEltModelsDocument,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { datetimeFormatJaYYYYMMDDHHmm, getColumnSearchProps } from '@salescore/frontend-common'
import { App, Button, Card, message, Table, Tag } from 'antd'
import { t } from 'i18next'

import { CustomizedModelForm } from './CustomizedModelForm'
import { ModelLabel } from './ModelLabel'
import { PropertyButton } from './PropertyButton'

export const CustomizedModelTable = (): JSX.Element => {
  const { modal } = App.useApp()
  const { data, refetch, loading } = useQuery(FetchEltModelsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const connections =
    useQuery(FetchConnectionsDocument, {
      variables: {
        organizationId: getOrganizationIdFromPath(),
      },
    }).data?.connections ?? []
  const [deleteEltModel] = useMutation(DeleteEltModelDocument)
  const connectionMapper = connections.groupByUniqueKey((c) => c.id)
  const sortedEltModels = (data?.eltModels ?? [])
    .filter((x) => x.type === 'userCustomizedDerived')
    .sortBy((x) => [connectionMapper[x.connectionId ?? '']?.source.provider, x.connectionId ?? '', x.name])

  return (
    <Card
      extra={
        <Button
          key="1"
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => {
            const m = modal.confirm({
              width: 'min(80%, 1440px)',
              title: t(`SALESCOREオブジェクトの新規作成`),
              content: (
                <CustomizedModelForm
                  onAfterFinish={() => {
                    void refetch()
                    m.destroy()
                  }}
                  onAfterCancel={() => {
                    m.destroy()
                  }}
                />
              ),
              footer: <></>,
            })
          }}
        >
          {t(`カスタムオブジェクトの新規作成`)}
        </Button>
      }
    >
      <Table
        loading={loading}
        dataSource={sortedEltModels}
        pagination={{
          pageSize: 100,
        }}
        scroll={{ y: 'min(85vh, calc(100vh - 250px))' }}
        columns={[
          {
            title: t(`オブジェクト名`),
            key: `label`,
            sorter: (a, b) => compareFunction(a.model.label, b.model.label),
            ...getColumnSearchProps<EltModelFieldsFragment>((eltModel) => eltModel.model.label),
            render: (_, eltModel) => <ModelLabel model={eltModel.model} />,
          },
          {
            title: t(`内部名`),
            key: `name`,
            sorter: (a, b) => compareFunction(a.name, b.name),
            ...getColumnSearchProps<EltModelFieldsFragment>((eltModel) => eltModel.name),
            render: (_, eltModel) => <Tag>{eltModel.name}</Tag>,
          },
          {
            title: t(`同期時刻`),
            // カスタムオブジェクトはsyncedAtがnullなのでtransformedAtを表示する
            key: `transformedAt`,
            sorter: (a: EltModelFieldsFragment, b: EltModelFieldsFragment) =>
              compareFunction(a.transformedAt, b.transformedAt),
            render: (_: unknown, eltModel: EltModelFieldsFragment) => {
              switch (eltModel.transform?.type) {
                case 'view': {
                  return <span className="text-sm text-gray-400">同期の必要はありません</span>
                }
                case 'materialized_view': {
                  return <span>{datetimeFormatJaYYYYMMDDHHmm(eltModel.transformedAt)}</span>
                }
                default: {
                  // viewとmaterialized_view以外は来ないはずだが念の為
                  return <span />
                }
              }
            },
          },
          {
            title: ``,
            key: `properties`,
            width: 100,
            render: (_, eltModel) => <PropertyButton eltModel={eltModel} />,
          },
          {
            title: ``,
            key: `edit`,
            width: 100,
            render: (_: unknown, eltModel: EltModelFieldsFragment) => (
              <Button
                type="text"
                className="text-blue-500"
                icon={<EditOutlined />}
                onClick={() => {
                  const m = modal.info({
                    width: 'min(80%, 1440px)',
                    content: (
                      <CustomizedModelForm
                        editingEltModel={eltModel}
                        onAfterFinish={() => {
                          m.destroy()
                        }}
                        onAfterCancel={() => {
                          m.destroy()
                        }}
                      />
                    ),
                    footer: <></>,
                  })
                }}
              >
                {t(`編集`)}
              </Button>
            ),
          },
          {
            title: ``,
            key: `delete`,
            width: 100,
            render: (_: unknown, eltModel: EltModelFieldsFragment) => (
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  void modal.confirm({
                    content: (
                      <div className="text-red-500">
                        {t(`削除を行うと今までに保存したデータが全て失われます。`)}
                        <br />
                        {t(`この操作は取り消せません。`)}
                        <br />
                        {t(`本当に削除しますか？`)}
                      </div>
                    ),
                    onOk: () => {
                      void deleteEltModel({
                        variables: {
                          organizationId: getOrganizationIdFromPath(),
                          id: eltModel.id,
                        },
                        onCompleted: () => {
                          void message.success(t(`削除しました`))
                          void refetch()
                        },
                        onError: () => {
                          void message.error(t(`エラーが発生しました`))
                        },
                      })
                    },
                  })
                }}
              >
                {t(`削除`)}
              </Button>
            ),
          },
        ]}
      />
    </Card>
  )
}
