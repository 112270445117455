import { PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { isNull, isPresent } from '@salescore/buff-common'
import { Button, Checkbox, Input, Space } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { FolderPathAndCreator } from './FolderPathAndCreator'

export function PickableSearchableList<T extends { id: string; name: string; path?: string[]; createdBy?: string }>({
  records,
  initialValues,
  onFinish,
  onAdd,
}: {
  records: T[]
  initialValues: string[]
  onFinish: (picked: T[]) => void
  onAdd?: (addValue: (value: string) => void) => void
}) {
  const [searchKey, setSearchKey] = useState(``)
  const [picked, setPicked] = useState<string[]>(initialValues)
  const filtered = isPresent(searchKey)
    ? records.filter((x) => searchKey.split(/\s/).every((q) => x.name.includes(q) || x.createdBy?.includes(q)))
    : records

  return (
    <div className="divide-x-0 divide-y divide-solid divide-gray-200">
      <div className="px-2 py-1">
        <Input.Search
          onSearch={(key) => {
            setSearchKey(key)
          }}
          allowClear
        />
      </div>
      <div style={{ maxHeight: 400 }} className="divide-x-0  divide-y divide-solid divide-gray-200 overflow-y-scroll">
        {filtered.map((record) => {
          const isPicked = picked.includes(record.id)
          return (
            <div
              className={`flex w-full cursor-pointer py-2 transition-all hover:opacity-60 ${
                isPicked ? `bg-blue-50` : ``
              }`}
              onClick={() => {
                setPicked((xs) => {
                  if (xs.includes(record.id)) {
                    return xs.filter((x) => x !== record.id)
                  }
                  return [...xs, record.id]
                })
              }}
            >
              <Checkbox checked={isPicked} className="ml-3 mr-1" />
              <div className="grow">
                <div className="whitespace-pre-wrap break-all pb-1">{record.name}</div>
                <FolderPathAndCreator path={record.path} createdBy={record.createdBy} />
              </div>
            </div>
          )
        })}
      </div>
      <Space className="mt-4 flex flex-row-reverse pt-4">
        <Button
          type="primary"
          icon={<SaveOutlined />}
          onClick={() => {
            const pickedRecords = picked.map((id) => records.find((x) => x.id === id)).compact()
            onFinish(pickedRecords)
          }}
        >
          {t(`決定`)}
        </Button>
        <Button
          type="default"
          icon={<PlusOutlined />}
          disabled={isNull(onAdd)}
          onClick={() => {
            if (isNull(onAdd)) {
              return
            }
            onAdd((kpiId) => {
              setPicked((xs) => [...xs, kpiId])
            })
          }}
        >
          {t(`新規作成`)}
        </Button>
      </Space>
    </div>
  )
}
