import { message } from 'antd'
import { useEffect } from 'react'
import { useRecoilState } from 'recoil'

import { feedbackMessagesAtom } from './records/atoms'

export const useFeedbackMessagesEffect = () => {
  const [feedbackMessages, setFeedbackMessages] = useRecoilState(feedbackMessagesAtom)

  useEffect(() => {
    if (feedbackMessages.isPresent()) {
      setFeedbackMessages([])
      for (const feedbackMessage of feedbackMessages) {
        void message.warning(feedbackMessage.message)
      }
    }
  }, [feedbackMessages])
}
