import { PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'

interface ViewUIKpiTimeSeriesEmptyProperties {
  onClickCreateNew: () => void
}

export function ViewUIKpiTimeSeriesEmpty(properties: ViewUIKpiTimeSeriesEmptyProperties) {
  return (
    <div className="h-full space-y-8 bg-white px-9 py-12">
      <div className="space-y-8">
        <div className="space-y-2">
          <div className="text-2xl font-bold">{t(`着地予実見込み`)}</div>
          <div className="flex gap-3">
            {Array.from({ length: 5 }).map((_, index) => (
              <div key={index} className="flex gap-1">
                <div className="size-4 rounded bg-gray-100" />
                <div className="h-4 w-16 rounded bg-gray-100" />
              </div>
            ))}
          </div>
        </div>

        <div className="relative max-w-max">
          <div className="flex items-end gap-3">
            {Array.from({ length: 7 }).map((_, index) => (
              <EmptyBar key={index} h={72} />
            ))}

            <EmptyBar h={64} />
            <EmptyBar h={60} />
            <EmptyBar h={52} />
            <EmptyBar h={60} />
            <EmptyBar h={52} />
            <EmptyBar h={56} />
            <EmptyBar h={60} />
          </div>

          <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-6">
            <div className="flex flex-col items-center gap-2">
              <div className="text-2xl font-bold">{t(`案件の見込みを可視化しよう`)}</div>
              <div>{t(`案件を管理するための時系列グラフを作成しましょう`)}</div>
            </div>
            <Button icon={<PlusOutlined />} type="primary" onClick={properties.onClickCreateNew}>
              {t(`新規作成`)}
            </Button>
          </div>
        </div>
      </div>

      <div className="space-y-3">
        <div className="text-xl font-bold">{t(`達成プラン`)}</div>
        <div className="flex gap-3">
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="h-24 w-48 rounded-lg bg-gray-100" />
          ))}
        </div>
      </div>
    </div>
  )
}

function EmptyBar({ h }: { h: 72 | 64 | 60 | 56 | 52 }) {
  // tailwindcss の scale
  const height = {
    72: '288px',
    64: '256px',
    60: '240px',
    56: '224px',
    52: '208px',
  }[h]
  return (
    <div className="flex w-16 flex-col items-center gap-2">
      <div style={{ height }} className="w-full rounded bg-gray-100" />
      <div className="text-gray-200">x/x</div>
    </div>
  )
}
