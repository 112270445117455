import { isSome } from '@salescore/buff-common'
import type { CoreDslFormLeafPartial, CoreDslFormOperator, ModelProperty } from '@salescore/core'
import { propertyTypeSchema } from '@salescore/core'
import { contextualizeDictionary } from '@salescore/frontend-common'
import { Form, Select } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'

const fieldTypeToFilterTypes: Record<ModelProperty['type'], CoreDslFormOperator[]> = {
  string: [
    '=',
    '!=',
    '<',
    '>',
    'present',
    'blank',
    'in',
    'not_in',
    'include',
    'not_include',
    'starts_with',
    'not_starts_with',
  ], // 比較系は選択肢のときのみ
  integer: ['=', '!=', '<', '>', '>=', '<=', 'not_null', 'null'],
  numeric: ['=', '!=', '<', '>', '>=', '<=', 'not_null', 'null'],
  date: ['=', '!=', '<', '>', '>=', '<=', 'not_null', 'null'],
  datetime: ['=', '!=', '<', '>', '>=', '<=', 'not_null', 'null'],
  time: ['=', '!=', '<', '>', '>=', '<=', 'not_null', 'null'],
  boolean: ['=', '!=', 'not_null', 'null'],
  array: ['present', 'blank', 'overlap', 'not_overlap', 'include', 'not_include', 'in', 'not_in'],
  object: [],
}

const filterTypeJa: Record<CoreDslFormOperator, string> = {
  in: `次のどれか`,
  not_in: `次のどれでもない`,
  '=': `等しい`,
  '!=': `異なる`,
  null: `入力なし`,
  not_null: `入力あり`,
  blank: `入力なし`,
  present: `入力あり`,
  include: `次の要素を全て含む`,
  not_include: `次の要素を全て含まない`,
  starts_with: `次の文字列から始まる`,
  not_starts_with: `次の文字列から始まらない`,
  overlap: `次の要素を含む`,
  not_overlap: `次の要素を含まない`,
  '<': `より小さい`,
  '<=': `以下`,
  '>': `より大きい`,
  '>=': `以上`,
  // ['']: '自分のユーザーID',
}

// filterTypeJa の翻訳を、 コンテキスト (fieldType) 別に生成している
// 例えば日本語で翻訳する場合、
// {
//   date: {
//     ['<']: 'より前',
//     ['<=']: '以前',
//     ...
//   },
//   integer: {
//     ['<']: 'より小さい',
//     ['<=']: '以下',
//     ...
//   },
//   ...
// }
const filterTypeJaByType = propertyTypeSchema.options.reduce(
  (previous, fieldType) => Object.assign(previous, { [fieldType]: contextualizeDictionary(filterTypeJa, fieldType) }),
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions,@typescript-eslint/prefer-reduce-type-parameter
  {} as Record<ModelProperty['type'], Record<CoreDslFormOperator, string>>,
)

const filterTypeNoTypeJa = contextualizeDictionary(filterTypeJa) as Record<CoreDslFormOperator, string>

export const CoreDslFormOperatorFormItem = ({
  leaf,
  onChange,
}: {
  onChange: (x: CoreDslFormLeafPartial) => void
  leaf: CoreDslFormLeafPartial
}) => {
  // if (leaf.type === 'field') {
  //   return <></>
  // }
  const property = leaf.left === undefined || leaf.left.type === 'literal' ? undefined : leaf.left.property
  const options = useMemo(() => getOptions(property), [property])

  useEffect(() => {
    // 選択中のfilterTypeが、選択可能なオプションの中に含まれなかったらクリア
    if (isSome(leaf.operator) && !options.some((option) => option.value === leaf.operator)) {
      onChange({
        ...leaf,
        operator: undefined,
        right: undefined,
      })
    }
  }, [options])

  return (
    <Form.Item label={t(`条件`)} rules={[{ required: true, message: t(`入力してください`) }]}>
      <Select
        disabled={options.length === 0}
        value={leaf.operator}
        onChange={(value) => {
          onChange({
            ...leaf,
            operator: value,
          })
        }}
        options={options}
      />
    </Form.Item>
  )
}

function getOptions(property: ModelProperty | undefined) {
  if (property === undefined) {
    return []
  }

  const selectOptions = property.selectOptions ?? []

  const filterTypes = fieldTypeToFilterTypes[property.type]

  const fixedFilterTypes: CoreDslFormOperator[] = filterTypes.filter((operator) => {
    // 一部のフィルタ種別については、条件を満たしたときのみ表示する
    switch (operator) {
      // 選択肢があるときだけin,not_inを表示する
      case 'in': {
        return selectOptions.length > 0
      }
      case 'not_in': {
        return selectOptions.length > 0
      }
      case '>': {
        // 文字列で指定されている比較演算子は、選択肢があるときのみ
        return property.type !== 'string' || selectOptions.isPresent()
      }
      case '<': {
        // 文字列で指定されている比較演算子は、選択肢があるときのみ
        return property.type !== 'string' || selectOptions.isPresent()
      }
    }
    return true
  })
  const options = fixedFilterTypes.map((operator, index) => ({
    label: getLabel(property.type, operator),
    key: `${operator}-${index}`, // filterTypeに重複がでてしまいkeyが重複すると表示がバグるので、iをいれておく
    value: operator,
  }))

  return options
}

function getLabel(type: ModelProperty['type'] | undefined, operator: CoreDslFormOperator) {
  return type === undefined ? filterTypeNoTypeJa[operator] : filterTypeJaByType[type][operator]
}
