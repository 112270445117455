import type { ViewUIComponent } from '@salescore/core'
import { Empty } from 'antd'
import { t } from 'i18next'
import * as React from 'react'

import type { ViewComponentArgument } from '../ViewComponent'
import { ViewUICheckboxC } from './ViewUICheckbox'
import { ViewUIChildRecordsFormC } from './ViewUIChildRecordsForm'
import { ViewUIColC } from './ViewUICol'
import { ViewUIDatePickerC } from './ViewUIDatePicker'
import { ViewUIFormC } from './ViewUIForm'
import { ViewUIFormItemC } from './ViewUIFormItem'
import { ViewUIFormListC } from './ViewUIFormList'
import { ViewUIInputC } from './ViewUIInput'
import { ViewUIInputNumberC } from './ViewUIInputNumber'
import { ViewUIKpi } from './ViewUIKpi'
import { ViewUIKpiPivot } from './ViewUIKpiPivot'
import { ViewUIRi } from './ViewUIRi/ViewUIRi'
import { ViewUIRowC } from './ViewUIRow'
import { ViewUISearchSelectC } from './ViewUISearchSelect'
import { ViewUISelectC } from './ViewUISelect'
import { ViewUISheetWithNavigator } from './ViewUISheetWithNavigator'
import { ViewUITitleFormItemC } from './ViewUITitleFormItem'

export const ViewUIComponentC = ({ component }: { component: ViewUIComponent }): JSX.Element => {
  const type = component.type
  switch (type) {
    case 'Sheet': {
      return <ViewUISheetWithNavigator component={component} />
    }
    case 'KpiPivotSheet': {
      return <ViewUIKpiPivot component={component} />
    }
    case 'KpiSheet': {
      return <ViewUIKpi component={component} />
    }
    case 'KpiTimeSeriesGraph': {
      return <ViewUIRi />
    }
    // Grid
    case 'Row': {
      return <ViewUIRowC component={component} />
    }
    case 'Col': {
      return <ViewUIColC component={component} />
    }
    // Form
    case 'Form': {
      return <ViewUIFormC component={component} />
    }
    case 'FormList': {
      return <ViewUIFormListC component={component} />
    }
    case 'ChildRecordsForm': {
      return <ViewUIChildRecordsFormC component={component} />
    }
    case 'FormItem': {
      return <ViewUIFormItemC component={component} />
    }
    case 'TitleFormItem': {
      return <ViewUITitleFormItemC component={component} />
    }

    // Data Entry
    case 'Input': {
      return <ViewUIInputC component={component} />
    }
    case 'Select': {
      return <ViewUISelectC component={component} />
    }
    case 'SearchSelect': {
      return <ViewUISearchSelectC component={component} />
    }
    case 'InputNumber': {
      return <ViewUIInputNumberC component={component} />
    }
    case 'DatePicker': {
      return <ViewUIDatePickerC component={component} />
    }
    case 'Checkbox': {
      return <ViewUICheckboxC component={component} />
    }
    default: {
      const x: never = type
      return <Empty description={t(`このUIコンポーネントには対応していません`)} />
    }
  }
}

//
// [deprecated] recoilに移行予定
//
export const ViewUIContext = React.createContext<{
  isEditMode: boolean
  onFinish: ViewComponentArgument['onFinish']
}>({
  isEditMode: false,
  onFinish: undefined,
})
