import Head from 'next/head'

const prefix = process.env.APP_ENV === 'prod' ? undefined : `[${process.env.APP_ENV}]`

export const DefaultHead = ({ organizationName }: { organizationName?: string }) => (
  <Head>
    <title>{[prefix, organizationName, 'SALESCORE'].compact().join(' | ')}</title>
    <link rel="icon" href="/favicon.png" />
    <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
    <link
      href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap"
      rel="stylesheet"
    />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
  </Head>
)
