import Mustache from 'mustache'

import { CORE_CONSTANT } from '../../constant'
import type {
  ViewQueryAggregation,
  ViewQueryRecordNode,
  ViewQueryResult,
  ViewQueryResultCursor,
} from '../../schemas/query'
import { generateAggregationSql } from './executeViewQuery/generateSql'

export type SqlResultRecord = Record<string, unknown>

// TODO
const tryAndCatch = async <T, K>(f: () => T | Promise<T>, handler: (error: Error) => K): Promise<T | K> => {
  try {
    return await f()
  } catch (error) {
    if (error instanceof Error) {
      return handler(error)
    }
    throw error
  }
}

export type ViewResultCursorOrInitialCursor = Omit<ViewQueryResultCursor, 'nextId'> & {
  nextId?: string | null
}

export class ExecuteViewPostgresError extends Error {
  public originalError: Error

  public constructor(e: Error, message: string) {
    super(message)
    this.name = 'ExecuteViewPostgresError'
    this.originalError = e
  }
}

export class TooManyChildRecordsError extends Error {
  public constructor(message: string) {
    super(message)
    this.name = 'TooManyChildRecordsError'
  }
}

type PromiseOrNonPromise<T> = Promise<T> | T
interface SqlClientLogBody {
  viewId: string
  viewName: string
}
export interface SqlClient {
  query: (
    sql: string,
    option?: { logBody?: SqlClientLogBody },
  ) => PromiseOrNonPromise<{
    rows: Array<Record<string, unknown>>
  }>
}

export const executeViewQueryForAggregationQuery = async ({
  query,
  sqlClient,
  mustacheParameter,
}: {
  query: ViewQueryAggregation
  sqlClient: SqlClient
  mustacheParameter: Record<string, unknown>
}): Promise<ViewQueryResult> => {
  try {
    const { rows, sql, error } = await executeAggregationQuery({ query, sqlClient, mustacheParameter })
    if (error !== undefined) {
      throw error
    }
    const nodeRecords = groupByCurrentNode(rows, query)

    return {
      viewQuery: query,
      result: nodeRecords,
      sqls: [sql],
      sqlDetails: [
        {
          sql,
          name: `集計SQL`, // TODO
        },
      ],
      nextCursor: undefined,
    }
  } catch (error) {
    if (error instanceof TooManyChildRecordsError) {
      return {
        viewQuery: query,
        result: [],
        nextCursor: undefined,
        error: `子レコードが多すぎます。フィールド設定よりツリーを見直してください。`,
      }
    }
    if (error instanceof Error) {
      return {
        viewQuery: query,
        result: [],
        nextCursor: undefined,
        error: error.message,
      }
    }
    throw error
  }
}

export async function executeAggregationQuery({
  query,
  sqlClient,
  mustacheParameter,
  option,
}: {
  query: ViewQueryAggregation
  sqlClient: SqlClient
  mustacheParameter: Record<string, unknown>
  option?: {
    logBody?: SqlClientLogBody
  }
}) {
  const rawSql = generateAggregationSql(query, mustacheParameter)
  const sql = Mustache.render(rawSql, mustacheParameter)
  const queryResult = await tryAndCatch(
    async () => {
      const result = await sqlClient.query(sql, option)
      let hasMoreRows = false
      if (result.rows.length > CORE_CONSTANT.AGGREGATION_QUERY_LIMIT) {
        result.rows.splice(
          CORE_CONSTANT.AGGREGATION_QUERY_LIMIT,
          result.rows.length - CORE_CONSTANT.AGGREGATION_QUERY_LIMIT,
        )
        hasMoreRows = true
      }

      return {
        success: true as const,
        result,
        hasMoreRows,
      }
    },
    (e) => ({
      success: false as const,
      error: new ExecuteViewPostgresError(e, `${e.name}: ${e.message}`),
    }),
  )
  if (!queryResult.success) {
    return { error: queryResult.error, sql, rows: [] }
  }

  const { rows, ...queryResultMeta } = queryResult.result
  return { rows, hasMoreRows: queryResult.hasMoreRows, sql }
}

function groupByCurrentNode(records: SqlResultRecord[], query: ViewQueryAggregation): ViewQueryRecordNode[] {
  if (records.length <= 1) {
    const record = records.first()
    if (record === undefined) {
      return []
    }

    return [
      {
        id: CORE_CONSTANT.AGGREGATION_NO_GROUPING_ROOT_ID,
        attributes: record,
        meta: {
          height: 1,
          innerRowIndexStart: 0,
          innerRowIndexEnd: 1,
        },
        children: [],
      },
    ]
  }

  return records.map((record, index) => ({
    id: `group-${index + 1}`,
    attributes: record,
    meta: {
      height: 1,
      innerRowIndexStart: index,
      innerRowIndexEnd: index + 1,
    },
    children: [],
  }))
}
