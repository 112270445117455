import type { ViewUISheet } from '@salescore/core'

import { useViewValue } from '../../recoil/view/hooks'
import { ViewUISheetC } from './ViewUISheet'
import { SheetNavigation } from './ViewUISheetWithNavigation/SheetNavigation'

const SIDER_COL = 6
const NAVIGATION_HEIGHT = 51
export const ViewUISheetWithNavigator = ({ component }: { component: ViewUISheet }) => {
  const view = useViewValue()

  return (
    <div
      className="view-ui-sheet-with-navigator flex flex-col overflow-y-auto bg-white"
      style={{
        height: '100%',
        WebkitBorderBottomLeftRadius: 10,
        WebkitBorderBottomRightRadius: 10,
        WebkitBorderTopRightRadius: 10,
      }}
    >
      <SheetNavigation component={component} />

      <div
        className="min-h-0 flex-1 border"
        // ここでkey指定するとviewの切り替え時に全レンダリングが走って良くないが、実装ミスでレンダリングが微妙になるのを防ぐのを優先する
        key={view.id}
      >
        <ViewUISheetC component={component} />
      </div>
    </div>
  )
}
