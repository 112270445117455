import { dateUtil, isTruthy } from '@salescore/buff-common'
import dayjs from 'dayjs'

import { CORE_CONSTANT } from '../../../../../../constant'
import { type DateTimeFilterExpressionType, timeframeSchema } from '../../../../../../schemas/misc/timeframe'
import type { AlwaysFilter, FilterType } from '../../../../../../schemas/query/filterTypes'
import { NotImplementedError, ValidationError } from '../../../../../errors'
import type { CompileContext } from '../../../../common'

type TimezoneInterval = string

// FYI https://docs.looker.com/reference/filter-expressions

const validateExpression = (expression: string | number | undefined): string | number => {
  if (expression === undefined) {
    throw new ValidationError(`expression must be specified`)
  }
  return expression
}

function generateLiteralExpression(exp: unknown): string {
  if (typeof exp === 'string') {
    if (exp === CORE_CONSTANT.KPI_PIVOT_NULL_STRING) {
      return `NULL`
    }
    return `'${exp}'`
  }

  if (exp === null) {
    return `NULL`
  }

  if (Array.isArray(exp)) {
    return `(${exp.map((x) => generateLiteralExpression(x)).join(', ')})`
  }
  if (typeof exp === 'number') {
    return `${exp}`
  }
  if (typeof exp === 'boolean') {
    return exp ? `TRUE` : `FALSE`
  }

  return `''` // TODO
}

// date
// const createIntervalExpression = (interval: TimezoneInterval) => {
//   const [operator, value] = [interval[0], interval.slice(1, -1)]
//   return `${operator} interval '${value}'`
// }

// const convertTimezone = (
//   field: string,
//   fieldType: FieldType,
//   interval: TimezoneInterval,
// ) => {
//   if (fieldType === 'date') {
//     return `${field}`
//   }
//   if (fieldType === 'datetime') {
//     return isSome(interval)
//       ? `(${field} ${createIntervalExpression(interval)})`
//       : `${field}`
//   }

//   throw new NotImplementedError(
//     `convertTimezone for ${fieldType} is not implemented`,
//   )
// }

//
// filters
//
export const nullFilter = (filter: AlwaysFilter, not: boolean): string =>
  `${filter.field_name} IS ${not ? 'NOT ' : ''}NULL`

export const blankFilter = (filter: AlwaysFilter, not: boolean): string =>
  `${not ? 'NOT ' : ''}(${filter.field_name} IS NULL OR ${filter.field_name} = '')`

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const parseJsonIfValid = (x: string | number): unknown | undefined => {
  if (typeof x === 'number') {
    return x
  }

  try {
    return JSON.parse(x)
  } catch {
    return undefined
  }
}

export const stringInFilter = (filter: AlwaysFilter, not: boolean): string => {
  const expression = validateExpression(filter.filter_expression)

  const values = Array.isArray(expression) ? expression : parseJsonIfValid(expression)
  if (values === undefined || !Array.isArray(values)) {
    throw new ValidationError(`values for 'in' filter must be json array, but got ${JSON.stringify(values)}`)
  }
  const normalized = values.map((x) => generateLiteralExpression(x))

  //
  // XXX: NULLが含まれるときの処理
  //      このレイヤーでやるべきかどうかなど、諸々悩ましいが、現状はこれで間に合うのでこれで。
  //      現状だとこれを生成するのは、軸フィルタのときのみのはず
  //
  if (normalized.includes(`NULL`)) {
    // nullが含まれる場合、IN (NULL)としても適用されないため、フィルタを分ける
    const notNull = normalized.filter((x) => x !== `NULL`)
    const nullSql = `${filter.field_name} ${not ? 'IS NOT' : 'IS'} NULL`
    if (notNull.isBlank()) {
      return nullSql
    }
    return `(${nullSql} OR ${filter.field_name} ${not ? 'NOT IN' : 'IN'} (${notNull.join(', ')}))`
  }

  // 配列が空のとき、`IN ()` というSQLが生成されてエラーになるため、空の場合は無視する
  if (normalized.isBlank()) {
    return `TRUE` // undefinedを返すべきか悩やましいが、スキップされたことを少しでも示すために一旦TRUEとする
  }
  const includeNULL = not ? `OR ${filter.field_name} IS NULL` : ''
  return `(${filter.field_name} ${not ? 'NOT IN' : 'IN'} (${normalized.join(', ')}) ${includeNULL})`
}

export const numericInFilter = (filter: AlwaysFilter, not: boolean): string => {
  const expression = validateExpression(filter.filter_expression)
  const values = Array.isArray(expression) ? expression : parseJsonIfValid(expression)
  if (!Array.isArray(values)) {
    throw new ValidationError(`values for 'in' filter must be json array, but got ${JSON.stringify(values)}`)
  }

  return `${filter.field_name} ${not ? 'NOT IN' : 'IN'} (${values.map((x) => Number.parseInt(x as string)).join(', ')})`
}

export const stringEqualFilter = (filter: AlwaysFilter, not: boolean, shouldConsiderCase?: boolean): string => {
  const expression = generateLiteralExpression(filter.filter_expression)
  if (expression === `NULL`) {
    // ありえないはずだが一応
    return `${filter.field_name} ${not ? 'IS NOT' : 'IS'} NULL`
  }

  const caseIgnoreOperator = not ? 'NOT ILIKE' : 'ILIKE'
  const caseSensitiveOperator = not ? '!=' : '='
  const operator = isTruthy(shouldConsiderCase) ? caseSensitiveOperator : caseIgnoreOperator
  const includeNULL = not ? `OR ${filter.field_name} IS NULL` : ''
  return `(${filter.field_name} ${operator} ${expression} ${includeNULL})`
}

export const booleanEqualFilter = (filter: AlwaysFilter, not: boolean): string => {
  const value = ['true', 'false', 'TRUE', 'FALSE'].find((x) => x === filter.filter_expression)

  return `${filter.field_name} ${not ? '!=' : '='} ${validateExpression(value)}`
}

// fieldの文字列がexpression文字列を含むかどうか
// expressionに,を使った場合、複数条件とみなす。こういった暗黙的な対応を極力避けたかったが、利便性に負けた
export const stringIncludeFilter = (filter: AlwaysFilter, not: boolean, shouldConsiderCase?: boolean): string => {
  const expression = validateExpression(filter.filter_expression)
  const xs = `${expression}`.split(',')
  // NOTE:NOT LIKE(次の文字列を含まない)した際にNULLは除外されるが空文字は除外されないため、
  //      統一感を持たせるためにNULLが除外されないようにする
  const includeNULL = not ? `OR ${filter.field_name} IS NULL` : ''
  if (xs.length <= 1) {
    const caseIgnoreOperator = not ? 'NOT ILIKE' : 'ILIKE'
    const caseSensitiveOperator = not ? 'NOT LIKE' : 'LIKE'
    const operator = isTruthy(shouldConsiderCase) ? caseSensitiveOperator : caseIgnoreOperator

    return `(${filter.field_name} ${operator} '%${expression}%' ${includeNULL})`
  }

  const caseIgnoreRegExpOperator = not ? '!~*' : '~*'
  const caseSensitiveRegExpOperator = not ? '!~' : '~'
  const regExpOperator = isTruthy(shouldConsiderCase) ? caseSensitiveRegExpOperator : caseIgnoreRegExpOperator
  const regExp = xs.map((x) => `(.*${x}.*)`).join('|')

  return `(${filter.field_name} ${regExpOperator} '${regExp}' ${includeNULL})`
}

export const stringStartsWithFilter = (filter: AlwaysFilter, not: boolean, shouldConsiderCase?: boolean): string => {
  const expression = validateExpression(filter.filter_expression)
  const xs = `${expression}`.split(',')
  // NOTE:NOT LIKE(次の文字列を含まない)した際にNULLは除外されるが空文字は除外されないため、
  //      統一感を持たせるためにNULLが除外されないようにする
  const includeNULL = not ? ` OR ${filter.field_name} IS NULL` : ''
  if (xs.length <= 1) {
    const caseIgnoreOperator = not ? 'NOT ILIKE' : 'ILIKE'
    const caseSensitiveOperator = not ? 'NOT LIKE' : 'LIKE'
    const operator = isTruthy(shouldConsiderCase) ? caseSensitiveOperator : caseIgnoreOperator

    return `(${filter.field_name} ${operator} '${expression}%' ${includeNULL})`
  }

  const caseIgnoreRegExpOperator = not ? '!~*' : '~*'
  const caseSensitiveRegExpOperator = not ? '!~' : '~'
  const regExpOperator = isTruthy(shouldConsiderCase) ? caseSensitiveRegExpOperator : caseIgnoreRegExpOperator
  const regExp = xs.map((x) => `(^${x}.*)`).join('|')

  return `(${filter.field_name} ${regExpOperator} '${regExp}' ${includeNULL})`
}

// fieldの配列がexpression配列を含むかどうか
export const arrayIncludeFilter = (filter: AlwaysFilter, not: boolean): string => {
  const expression = validateExpression(filter.filter_expression)
  const values = Array.isArray(expression) ? expression : parseJsonIfValid(expression)
  if (!Array.isArray(values)) {
    throw new ValidationError(`values for arrayIncludeFilter must be array`)
  }

  const includeNULL = not ? `OR ${filter.field_name} IS NULL` : ''
  return `(${not ? 'NOT' : ''}(${filter.field_name} @> ARRAY[${values
    .map((x) => (typeof x === 'string' ? `'${x}'` : (x as string)))
    .join(',')}]) ${includeNULL})`
}

// fieldの配列がexpression配列に含まれるかどうか
export const arrayInFilter = (filter: AlwaysFilter, not: boolean): string => {
  const expression = validateExpression(filter.filter_expression)
  const values = Array.isArray(expression) ? expression : parseJsonIfValid(expression)
  if (!Array.isArray(values)) {
    throw new ValidationError(`values for arrayIncludeFilter must be array`)
  }
  const includeNULL = not ? `OR ${filter.field_name} IS NULL` : ''
  return `(${not ? 'NOT' : ''}(${filter.field_name} <@ ARRAY[${values
    .map((x) => (typeof x === 'string' ? `'${x}'` : (x as string)))
    .join(',')}]) ${includeNULL})`
}

export const arrayOverlapFilter = (filter: AlwaysFilter, not: boolean): string => {
  const expression = validateExpression(filter.filter_expression)
  const values = Array.isArray(expression) ? expression : parseJsonIfValid(expression)
  if (!Array.isArray(values)) {
    throw new ValidationError(`values for arrayIncludeFilter must be array`)
  }

  const includeNULL = not ? `OR ${filter.field_name} IS NULL` : ''
  return `(${not ? 'NOT' : ''}(${filter.field_name} && ARRAY[${values
    .map((x) => (typeof x === 'string' ? `'${x}'` : (x as string)))
    .join(',')}]) ${includeNULL})`
}

const compareOperator = (type: FilterType) => {
  switch (type) {
    case 'equal': {
      return '='
    }
    case 'equal_me': {
      return '='
    }
    case 'not_equal': {
      return '!='
    }
    case 'greater_than': {
      return '>'
    }
    case 'greater_than_or_equal': {
      return '>='
    }
    case 'less_than': {
      return '<'
    }
    case 'less_than_or_equal': {
      return '<='
    }
    // case ('between'):
    //   return 'BETWEEN'
    default: {
      const x:
        | 'null'
        | 'not_null'
        | 'in'
        | 'not_in'
        | 'include'
        | 'not_include'
        | 'starts_with'
        | 'not_starts_with'
        | 'overlap'
        | 'not_overlap'
        | 'blank'
        | 'present' = type
      throw new NotImplementedError()
    }
  }
}

export const timeCompareFilter = (filter: AlwaysFilter, interval: TimezoneInterval | undefined): string => {
  const filterExpression = validateExpression(filter.filter_expression)
  if (typeof filterExpression === 'number') {
    throw new ValidationError()
  }
  // time型は13:00:00の形式のみを許す
  if (!/^\d\d:\d\d:\d\d$/.test(filterExpression)) {
    throw new ValidationError()
  }

  const operator = compareOperator(filter.filter_type)

  return `${filter.field_name} ${operator} '${filterExpression}'` // TODO: time型の場合、インターバルを考慮すべきなのか未調査。おそらくしなくて良いはず。
}

export const dateCompareFilter = (
  filter: AlwaysFilter,
  interval: TimezoneInterval | undefined,
  dateTimeType: DateTimeFilterExpressionType,
  context: CompileContext,
): string => {
  const filterValue = validateExpression(filter.filter_expression)
  if (typeof filterValue === 'number') {
    throw new ValidationError()
  }

  const operator = compareOperator(filter.filter_type)
  const fieldWithTimezone = filter.field_name // タイムゾーンを実行時に指定するようになったため、不要
  const result = getDateTruncTypeAndRightValue(dateTimeType, filterValue, fieldWithTimezone, context)
  if (result === undefined) {
    return `` // TODO
  }

  if (result.type === 'raw' || result.type === 'trunc') {
    return `${result.left} ${operator} ${result.right}`
  }

  const { field, startAt, endAt } = result
  const dateFormat = filter.field_type === 'datetime' ? `YYYY-MM-DD HH:mm:ss` : `YYYY-MM-DD`
  switch (operator) {
    case '=': {
      return `(${field} >= '${startAt.format(dateFormat)}' AND ${field} <= '${endAt.format(dateFormat)}')`
    }
    case '!=': {
      return `(${field} < '${startAt.format(dateFormat)}' OR ${field} > '${endAt.format(dateFormat)}')`
    }
    case '>': {
      return `${field} > '${endAt.format(dateFormat)}'`
    }
    case '>=': {
      return `${field} >= '${startAt.format(dateFormat)}'`
    }
    case '<': {
      return `${field} < '${startAt.format(dateFormat)}'`
    }
    case '<=': {
      return `${field} <= '${endAt.format(dateFormat)}'`
    }
    default: {
      throw new Error(operator satisfies never)
    }
  }
}

// TODO: このレイヤに渡ってくる時に、クォートがある時とない時が混在しているのが良くない
function quote(x: string) {
  if (x.startsWith(`'`) && x.endsWith(`'`)) {
    return x
  }
  return `'${x}'`
}

// dateTimeTypeをもとに、WHERE句の表現として適切になるようにvalue,expressionを変換する関数
function getDateTruncTypeAndRightValue(
  dateTimeType: DateTimeFilterExpressionType,
  valueString: string,
  fieldExpression: string,
  context: CompileContext,
) {
  const accountClosingMonth = context.resources.organizationSetting.accountClosingMonth

  switch (dateTimeType) {
    case 'datetime': {
      // datetimeのときはそのまま表示するだけ(型が揃わないこともあるが気にしない。そもそもrightはdateしかないはず)
      return {
        type: 'raw' as const,
        left: fieldExpression,
        right: quote(valueString),
      }
    }
    case 'date': {
      // dateのときは、型を揃えるだけ
      return {
        type: 'raw' as const,
        left: `${fieldExpression}::date`,
        right: `'${valueString}'::date`,
      }
    }
    case 'day': {
      // dayはdateと等価(dateがdeprecated)
      return {
        type: 'raw' as const,
        left: `${fieldExpression}::date`,
        right: `'${valueString}'::date`,
      }
    }

    // NOTE:
    // 「作成日が2023年1月である」のような絞り込みをSQLで行う場合、以下の2パターンがありうる。
    // 1. 2023-01-01 00:00:00 <= created_at AND created_at 2023-02-01 00:00:00
    // 2. DATE_TRUC('month', created_at) = '2023-01-01'::date
    // ※DATE_TRUNCは、引数で指定した日付の初日を返す関数
    // ~SQLのパフォーマンス的には、1の方が良いが、2の方が見た目がシンプルであり元の設定が分かりやすいため、SALESCOREでは2を採用している~
    // ~また1だと実行するたびにSQLが変わってしまうため、設定変更前後のSQLの差分が取りづらい。~
    // ~現状だと差分が必要なケースはないが、過去にこれが必要となるケースがあったはずで、それもありDATE_TRUNCを使っている。~
    // 2023/09 上記判断をしていたが、パフォーマンス面で問題となり、1に変更した
    //
    case 'week': {
      // TODO: 仕様にないので未実装のはずだが、移行時にそのままコードを残してしまったためか実装がある
      // この実装だとダメなはずだが、もし本番側にこの分岐があり、エラーになるおt怖いので一旦残しておく
      const startAt = dayjs(valueString, 'YYYY-MM-DD').startOf('week')
      const endAt = startAt.endOf('week')
      return {
        type: 'range' as const,
        field: fieldExpression,
        startAt,
        endAt,
      }
    }
    case 'month': {
      const startAt = dayjs(valueString, 'YYYY-MM').startOf('month')
      const endAt = startAt.endOf('month')
      return {
        type: 'range' as const,
        field: fieldExpression,
        startAt,
        endAt,
      }
    }
    case 'year': {
      const startAt = dayjs(valueString, 'YYYY').startOf('year')
      const endAt = startAt.endOf('year')
      return {
        type: 'range' as const,
        field: fieldExpression,
        startAt,
        endAt,
      }
    }
    case 'relative': {
      // 相対表現はパースして頑張る
      const diff = Number.parseInt(valueString.split(' ')[0] ?? '0') // "-9 day"の-9の部分
      const dateTruncType = timeframeSchema.parse(valueString.split(' ')[1] ?? 'day') // "-9 day"のdayの部分

      if (dateTruncType === 'fiscal_year') {
        const interval = diff === 0 ? '' : ` + INTERVAL '${diff} year'`
        // 3月締めのとき、2023年3月は2022年扱いにしたいので-3する
        const fiscalInterval = accountClosingMonth === 12 ? `` : `- INTERVAL '${accountClosingMonth} month'`
        return {
          type: 'trunc' as const,
          left: `DATE_TRUNC('year', ${fieldExpression}${fiscalInterval})`,
          right: `DATE_TRUNC('year', CURRENT_DATE${interval}${fiscalInterval})`,
        }
      }

      if (dateTruncType === 'fiscal_half') {
        const interval = diff === 0 ? 0 : diff * 6

        // 決算月が8月の場合、期の開始月は9月
        const fiscalFirstYearStartMonth = (accountClosingMonth + 1) % 12
        // 現在月から期の範囲を求める
        // e.g. 現在2024年3月、期の開始が2023年9月の場合、現在から6ヶ月引く(開始月だけでは年が考慮されないため)
        //      startAt : 2023-09-01 00:00:00
        //      endAt   : 2024-02-29 23:59:59
        const currentMonth = dayjs().month() + 1
        const startAt = dayjs()
          .subtract((currentMonth - fiscalFirstYearStartMonth + 12) % 12, 'month')
          .add(interval, 'month')
          .startOf('month')
        const endAt = startAt.add(5, 'month').endOf('month')

        return {
          type: 'range' as const,
          field: fieldExpression,
          startAt,
          endAt,
        }
      }

      if (dateTruncType === 'fiscal_quarter') {
        const interval = diff === 0 ? '' : ` + INTERVAL '${diff * 3} month'`
        // 3月締めのとき、2023年3月は2022年扱いにしたいので-3する
        const fiscalInterval = accountClosingMonth === 12 ? `` : `- INTERVAL '${accountClosingMonth} month'`
        // NOTE: fiscal quarterの場合、DATE_TRUNCで頑張っても逆に分かりづらいかも…。。
        return {
          type: 'trunc' as const,
          left: `DATE_TRUNC('quarter', ${fieldExpression}${fiscalInterval})`,
          right: `DATE_TRUNC('quarter', CURRENT_DATE${interval}${fiscalInterval})`,
        }
      }

      if (dateTruncType === 'week') {
        // 週の開始曜日は月曜日で統一する
        const baseDate = dayjs().day() === 0 ? dayjs().subtract(1, 'day') : dayjs()
        const startAt = baseDate.add(diff, 'week').startOf('week').add(1, 'day')
        const endAt = startAt.add(1, 'week').subtract(1, 'day').endOf('day')
        return {
          type: 'range' as const,
          field: fieldExpression,
          startAt,
          endAt,
        }
      }

      if (dateTruncType === 'weekday') {
        return // TODO
      }

      const startAt = dayjs().add(diff, dateTruncType).startOf(dateTruncType)
      const endAt = startAt.endOf(dateTruncType)
      return {
        type: 'range' as const,
        field: fieldExpression,
        startAt,
        endAt,
      }
    }
    // TODO
    case 'hour': {
      throw new Error(`対応していない時間の絞り込み種別です。type: hour`)
    }
    case 'weekday': {
      throw new Error(`対応していない時間の絞り込み種別です。type: weekday`)
    }
    case 'fiscal_year': {
      const parsed = dateUtil.parse(valueString, { accountClosingMonth, timeframe: `fiscal_year` })
      if (parsed === undefined) {
        throw new Error(`対応していない時間の絞り込み値です。value: ${valueString}`)
      }
      return {
        type: 'range' as const,
        field: fieldExpression,
        startAt: parsed.startAt,
        endAt: parsed.endAt,
      }
    }
    case 'fiscal_half': {
      throw new Error(`対応していない時間の絞り込み種別です。type: fiscal_half`)
    }
    case 'fiscal_quarter': {
      const parsed = dateUtil.parse(valueString, { accountClosingMonth })
      if (parsed === undefined) {
        throw new Error(`対応していない時間の絞り込み値です。value: ${valueString}`)
      }
      return {
        type: 'range' as const,
        field: fieldExpression,
        startAt: parsed.startAt,
        endAt: parsed.endAt,
      }
    }
    default: {
      throw new Error(dateTimeType satisfies never)
    }
  }
}

export const numericCompareFilter = (filter: AlwaysFilter): string => {
  const expression = validateExpression(filter.filter_expression)
  const operator = compareOperator(filter.filter_type)
  const value = typeof expression === 'string' ? Number.parseInt(expression) : expression
  return `${filter.field_name} ${operator} ${value}`
}
