export function cartesianProduct<T1, T2, T3, T4, T5, T6, T7, T8>([c1, c2, c3, c4, c5, c6, c7, c8]: [
  T1[],
  T2[],
  T3[],
  T4[],
  T5[],
  T6[],
  T7[],
  T8[],
]): Array<[T1, T2, T3, T4, T5, T6, T7, T8]>
export function cartesianProduct<T1, T2, T3, T4, T5, T6, T7>([c1, c2, c3, c4, c5, c6, c7]: [
  T1[],
  T2[],
  T3[],
  T4[],
  T5[],
  T6[],
  T7[],
]): Array<[T1, T2, T3, T4, T5, T6, T7]>
export function cartesianProduct<T1, T2, T3, T4, T5, T6>([c1, c2, c3, c4, c5, c6]: [
  T1[],
  T2[],
  T3[],
  T4[],
  T5[],
  T6[],
]): Array<[T1, T2, T3, T4, T5, T6]>
export function cartesianProduct<T1, T2, T3, T4, T5>([c1, c2, c3, c4, c5]: [T1[], T2[], T3[], T4[], T5]): Array<
  [T1, T2, T3, T4, T5]
>
export function cartesianProduct<T1, T2, T3, T4>([c1, c2, c3, c4]: [T1[], T2[], T3[], T4[]]): Array<[T1, T2, T3, T4]>
export function cartesianProduct<T1, T2, T3>([c1, c2, c3]: [T1[], T2[], T3[]]): Array<[T1, T2, T3]>
export function cartesianProduct<T1, T2>([c1, c2]: [T1[], T2[]]): Array<[T1, T2]>
export function cartesianProduct<T1>([c1]: [T1[]]): Array<[T1]>
export function cartesianProduct(c: never[]): Array<[]>
export function cartesianProduct<T>(sets: T[][]): T[][] {
  return sets.reduce<T[][]>(
    (productedSets, set) =>
      // eslint-disable-next-line unicorn/prefer-array-flat
      productedSets
        .map((productedSet) => set.map((x) => [...productedSet, x]))
        // eslint-disable-next-line unicorn/prefer-spread
        .reduce((c, d) => c.concat(d), []),
    [[]],
  )
}

// export function cartesianProduct<T>(arr: T[][]): T[][] {
//   return arr.reduce(
//     (a, b) => {
//       return a
//         .map((x) => {
//           return b.map((y) => {
//             return x.concat(y)
//           })
//         })
//         .reduce((c, d) => c.concat(d), [])
//     },
//     [[]] as T[][],
//   )
// }
