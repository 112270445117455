import { ConsoleSqlOutlined, SettingOutlined } from '@ant-design/icons'
import { isNull } from '@salescore/buff-common'
import { Button, Dropdown, type MenuProps } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { useViewQueryResult } from '../../../../recoil/records/hooks'

export const filterSavedMessage = t('フィルター設定を保存しました')

export function ViewUiRiPreviewWrapper({ header, body }: { header: JSX.Element; body: JSX.Element }): ReactNode {
  const viewQueryResult = useViewQueryResult()
  const { metadataModal } = useNavigationModal()

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div
          className="flex w-[300px] items-center justify-between"
          onClick={(e) => {
            e.preventDefault()
          }}
        >
          <div className="flex items-center gap-2">
            <ConsoleSqlOutlined />
            <div>{t(`SQL表示`)}</div>
          </div>

          <Button
            type="link"
            disabled={isNull(viewQueryResult)}
            onClick={() => {
              metadataModal.showModal()
            }}
          >
            {t(`表示`)}
          </Button>
        </div>
      ),
    },
  ]

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-end gap-2 px-6">
        {header}
        <Dropdown menu={{ items }} overlayClassName="view-ui-kpi-time-series-form-preview-dropdown" trigger={['click']}>
          <Button className="min-w-[32px]" type="text" icon={<SettingOutlined />} />
        </Dropdown>
      </div>

      <div className="relative flex-1">
        <div className="absolute inset-0">{body}</div>
      </div>
    </div>
  )
}
