import * as React from 'react'

import { useColumnsValue, useContextValue } from '../../recoil/models/propModels'
import { useColumnBlocksRelatedSelector } from '../../recoil/selectors/columnBlockRelatedSelector'
import { useColumnsRelatedSelector } from '../../recoil/selectors/columnRelatedSelector'
import { RSheetsStyle } from '../../util/RSheetsStyle'
import { CELL_WIDTH } from '../body/cell/RsheetsCell'
import { HEADER_RIGHT_WIDTH_MARGIN } from './const'
import { RSheetsHeaderCell } from './RSheetsHeaderCell'
import { RSheetsNodeBlockHeader } from './RSheetsNodeBlockHeader'

export const HEADER_PLUS_ICON_WIDTH = 50

// columnsが書きかわった場合、recoil側で発火して再レンダリングが走る
// そのため、React.memoとしてのメモ化は親コンポーネントが書きかわった際にいつ再レンダリングするかを定義するだけでよく、
// この場合は単にReact.memo()するだけでよい。
export const RSheetsHeaderRowMemo = React.memo(RSheetsHeaderRow)

export function RSheetsHeaderRow({ leftFixed }: { leftFixed: boolean }) {
  const columns = useColumnsValue()
  const columnRelated = useColumnsRelatedSelector()
  const blockRelated = useColumnBlocksRelatedSelector()
  const options = useContextValue()
  const { leftFixedColumns, notFixedColumns } = columnRelated
  const targetColumns = leftFixed ? leftFixedColumns : notFixedColumns
  if (targetColumns.length === 0 && leftFixed) {
    // flexを使っている関係で、!leftFixedなときは描画しないとレイアウトが崩れる
    return <></>
  }

  const widths = targetColumns.map((c) => c.width ?? CELL_WIDTH)

  return (
    <>
      <div
        style={{
          position: 'sticky',
          top: 0,
          ...(leftFixed ? { left: 0 } : {}),
          zIndex: leftFixed ? RSheetsStyle.zIndex.headerRowWhenLeftFixed : RSheetsStyle.zIndex.headerRow,
          display: 'flex',
          height: blockRelated.headerHeight,
          width: widths.sum(),
          borderRight: leftFixed ? RSheetsStyle.border.rightEdgeColumn : undefined,
          marginRight: leftFixed ? 0 : HEADER_RIGHT_WIDTH_MARGIN,
          backgroundColor: '#F3F4F6',
        }}
        className="rsheets-header-row"
        data-e2e={`rsheets-header-row-${leftFixed ? 'left-fixed' : 'not-fixed'}`}
      >
        <div style={{ height: 'inherit', overflow: 'hidden', width: 'inherit' }} className="absolute">
          <RSheetsNodeBlockHeader leftFixed={leftFixed} />
        </div>
        <div
          style={{
            width: widths.sum(),
            top: blockRelated.treeHeaderHeight - blockRelated.headerTopMargin,
          }}
          className="absolute flex"
        >
          {targetColumns.map((column, columnIndex) => {
            const columnIndexStart = leftFixed ? 0 : (options.fixedLeftColumnIndex ?? 0)
            return (
              <RSheetsHeaderCell
                column={column}
                allColumns={columns} // fixedを考慮しない全てのcolumns
                allColumnIndex={columnIndex + columnIndexStart} // 上記の上でのindex
                key={columnIndex}
              />
            )
          })}
        </div>
        {/* {!leftFixed && (
          <div
            style={{
              height: ROW_HEIGHT,
              width: HEADER_PLUS_ICON_WIDTH,
            }}
            className="flex align-middle items-center opacity-50"
          >
            <Button icon={<PlusOutlined />} type="text" onClick={onAddColumn} />
          </div>
        )} */}
      </div>
    </>
  )
}
