import { ApolloLink } from '@apollo/client'

export const cleanTypeNameLink = new ApolloLink((operation, forward) => {
  try {
    if (operation.variables !== null) {
      const newVariables = JSON.parse(JSON.stringify(operation.variables), (key, value: unknown) =>
        key === '__typename' ? undefined : value,
      ) as Record<string, unknown>
      operation.variables = newVariables
    }
  } catch {
    // nothing
  }

  return forward(operation).map((data) => data)
})
