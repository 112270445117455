import { PermissionActionEnum, type ViewFieldsFragment } from '@salescore/client-api'
import { CORE_CONSTANT, type ViewConfigKpi } from '@salescore/core'
import { ToggleText } from '@salescore/frontend-common'
import { Empty } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useMeValue, useViewsContextValue, useViewValue } from '../../../../recoil/view/hooks'
import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import { DurationTimerStart } from '../../../misc/DurationTimer'
import { ViewComponent } from '../../../ViewComponent'
import { useSaveKpiForm } from './KpiFormSaveButton'

const dynamicViewFields: ViewFieldsFragment = {
  id: '',
  viewGroupId: ``,
  type: `sheet`,
  name: ``,
  queries: [],
  ui: [],
  config: { type: `sheet` },
  rank: 0,
  defaultPermission: PermissionActionEnum.None,
  importedSourceId: ``,
  emoji: ``,
  roleRecordPermissions: [],
  private: false,
  archived: false,
  archivedAt: null,
}

export function KpiFormPreview() {
  const view = useViewValue()
  const { organization } = useMeValue()
  const { validatedFormValue, formModified, setFormModified, currentSheet, setSheetConfig, setForm } =
    useKpiFormSelectors()
  const saveKpiForm = useSaveKpiForm()
  const [toSaveKpiForm, setToSaveKpiForm] = useState(false)
  const viewsContext = useViewsContextValue()

  const { kpiConfig } = validatedFormValue

  useEffect(() => {
    async function callSaveKpiForm() {
      if (toSaveKpiForm) {
        await saveKpiForm()
        setToSaveKpiForm(false)
      }
    }

    void callSaveKpiForm()
  }, [toSaveKpiForm, validatedFormValue])

  if (!kpiConfig.success) {
    return (
      <Empty
        description={
          <div>
            {t(`KPI設定が終わっていません。`)}
            <br />
            <ToggleText text={t(`詳細`)}>
              <>{kpiConfig.error.message}</>
            </ToggleText>
          </div>
        }
      />
    )
  }

  const config = kpiConfig.data
  const previewViewId = `${CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX}${view.id}`
  // configの変更があった場合にKPIプレビューを再レンダリングさせるために、configを元にキーを生成している
  const kpiPreviewKey = generateKpiPreviewKey({ config })

  return (
    <>
      <ViewComponent
        key={kpiPreviewKey}
        view={{
          ...dynamicViewFields,
          id: previewViewId,
          config,
          queries: [],
          ui: [],
        }}
        parameter={{}}
        // onFinish={onFinish}
        viewsContext={{
          ...viewsContext,
          parentViewProtectingUnload: formModified,
          // eslint-disable-next-line @typescript-eslint/require-await
          updateView: async (argument): Promise<undefined> => {
            if (argument.config?.type === 'sheet') {
              // プレビューモーダルの中のシートから `updateView` が呼ばれた場合の処理
              const sheetViewConfig = argument.config
              const newSheet = {
                ...currentSheet,
                tree: sheetViewConfig.tree,
                fields: sheetViewConfig.fields,
                filterTree: sheetViewConfig.filterTree,
                sorters: sheetViewConfig.sorters,
                meta: {
                  fixedLeftColumnIndex: sheetViewConfig.meta?.fixedLeftColumnIndex,
                  visibleRowNum: sheetViewConfig.meta?.visibleRowNum,
                },
              }
              setSheetConfig(newSheet)
              // フィルター条件を変更した時に、ViewFilterTreeFormのキャッシュを更新するために、キャッシュキーを更新する
              // キャッシュキーが書き換わればなんでも良い（ので、ここでは現在時刻をキャッシュキーにしている）
              setForm((oldValue) => ({ ...oldValue, filterFormItemCacheKey: new Date().toISOString() }))
              setFormModified(true)
              setToSaveKpiForm(true)
            }
            // TODO: arg としてシートの config 以外が渡された場合（特に KPI の config が渡された場合）の対応が必要になるかもしれない
            // if (arg.config?.type === 'kpi') {
            //   const kpiViewConfig = arg.config
            //   setKpi(kpiViewConfig)
            //   setFormModified(true)
            //   setToSaveKpiForm(true)
            //   return undefined
            // }
            // eslint-disable-next-line no-useless-return
            return
          },
        }}
      />
      <DurationTimerStart
        // kpiPreviewKeyが切り替わる度にdurationの計測をトリガーする
        key={`${kpiPreviewKey}_duration`}
        trigger="kpiPreviewUpdated"
        viewId={previewViewId}
        organizationId={organization.id}
      />
    </>
  )
}

function generateKpiPreviewKey({ config }: { config: ViewConfigKpi }) {
  const ui = {
    ...config.ui,
    kpi: {
      ...config.ui?.kpi,
      // アイコンと説明文を変更してもKPIプレビューの再レンダリングが発生しないようにする
      icon: undefined,
      description: undefined,
    },
  }

  return JSON.stringify({
    ...config,

    ui,
  })
}
