import { functionNodeSchemaWithDeprecated } from '../../dsl/syntax/expression_ast'
import type { ModelProperty } from '../../schemas/model/modelProperty'

export function isRequiredProperty(property: ModelProperty): boolean {
  // やや手抜き
  if (property.type === 'boolean') {
    // Salesforce の真偽値は、入力しなくてもレコードの作成ができてしまうので非必須扱いとする: https://github.com/salescore-inc/issues/issues/881
    // この条件だと他のデータソースでも真偽値なら非必須扱いになってしまう。必要なら Salesforce だけに適用する。
    return false
  }
  return (property.validations ?? []).some((v) => {
    const parsed = functionNodeSchemaWithDeprecated.safeParse(v.function)
    if (!parsed.success) {
      return false
    }
    const type = parsed.data.type
    switch (type) {
      case 'func': {
        return parsed.data.name === 'present'
      }
      case 'function': {
        return parsed.data.functionType === 'present'
      }
      default: {
        return false
      }
    }
  })
}
