import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import {
  archivedViewGroupTreeAtom,
  privateViewGroupTreeAtom,
  viewGroupsAtom,
  viewGroupTreeAtom,
  viewsAtom,
} from '../atoms'
import { convertFlatViewGroupsToAntdTree } from '../functions/convertFlatNodesToAntdTree'

export const useRefreshViewGroupTreeMutation = () => {
  const views = useRecoilValue(viewsAtom)
  const viewGroups = useRecoilValue(viewGroupsAtom)
  const setTree = useSetRecoilState(viewGroupTreeAtom)
  const setPrivateTree = useSetRecoilState(privateViewGroupTreeAtom)
  const setArchivedTree = useSetRecoilState(archivedViewGroupTreeAtom)

  useEffect(() => {
    const [privateViews, publicViews] = views.partition((x) => x.private)
    const [privateViewGroups, publicViewGroups] = viewGroups.partition((x) => x.private)
    const [archivedViews, publicActiveViews] = publicViews.partition((x) => x.archived)
    const [archivedViewGroups, publicActiveViewGroups] = publicViewGroups.partition((x) => x.archived)
    const publicActiveTrees = convertFlatViewGroupsToAntdTree(publicActiveViewGroups, publicActiveViews)
    const privateTrees = convertFlatViewGroupsToAntdTree(privateViewGroups, privateViews)
    const archivedTrees = convertFlatViewGroupsToAntdTree(archivedViewGroups, archivedViews)
    setTree(publicActiveTrees)
    setPrivateTree(privateTrees)
    setArchivedTree(archivedTrees.sortBy((x) => x.item.archivedAt).reverse())
  }, [views, viewGroups])
}
