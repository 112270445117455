import dayjs from 'dayjs'

import type { GoalConfig } from './types'

export function validateDateByGoalConfig(x: Date, timeSpanType: GoalConfig['timeSpanType']): Date | undefined {
  const type = timeSpanType
  switch (type) {
    case 'day': {
      return x
    }
    case 'none': {
      return x
    }
    case 'month': {
      return dayjs(x).startOf('month').format(`YYYY-MM-DD`) === dayjs(x).format(`YYYY-MM-DD`) ? x : undefined
    }
    case 'week': {
      // startOfが日曜始まりなので、1を追加して(月)始まりにする
      return dayjs(x).startOf('week').add(1, 'day').format(`YYYY-MM-DD`) === dayjs(x).format(`YYYY-MM-DD`)
        ? x
        : undefined
    }
    default: {
      throw new Error(type satisfies never)
    }
  }
}
