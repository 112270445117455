import { r } from '@salescore/buff-common'
import type { ViewQueryRecordNode } from '@salescore/core'
import type { SetRecoilState } from 'recoil'

import { generateNewRecordId } from '../../../../state/nodeUtil'
import type { ViewRecordFieldChange } from '../../../../state/useViewRecordsState/useChangesState'
import { recordAtomFamily } from '../../atoms'
import type { NewRecordChange } from '../addNewRecords'

export function addNewRootRecord(
  rootRecordNodes: ViewQueryRecordNode[],
  newRecordIds: string[],
  newRecordChange: NewRecordChange,
  set: SetRecoilState,
) {
  const { rowIndex, node } = newRecordChange

  // 新規レコードを生成
  const recordNode = generateNewRootRecord()

  // 新規レコードを挿入
  set(recordAtomFamily({ id: recordNode.id! }), r(recordNode).clone().data)

  // 次の関数に変更差分を伝えるため、引数で渡されたレコードに破壊的変更を行う
  rootRecordNodes.splice(rowIndex, 0, recordNode)
  newRecordIds.splice(rowIndex, 0, recordNode.id!)

  // 子レコードがないので、メタ情報の更新は不要

  // 変更差分を生成
  const change: ViewRecordFieldChange | undefined =
    node.write === undefined
      ? undefined
      : {
          rootNodeId: recordNode.id,
          id: recordNode.id,
          streamName: node.write.streamName,
          fieldChanges: [],
          before: {},
          after: {},
        }

  return {
    recordNode,
    change,
  }
}

export function generateNewRootRecord(): ViewQueryRecordNode {
  return {
    id: generateNewRecordId(),
    attributes: {},
    children: [],
    meta: {
      height: 1,
      innerRowIndexStart: 0,
      innerRowIndexEnd: 1,
    },
  }
}
