import { PlusOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import { PermissionActionEnum, type ViewFieldsFragment } from '@salescore/client-api'
import { convertSheetConfigToFormConfig, CORE_CONSTANT, type ViewConfigSheet } from '@salescore/core'
import { useMessage } from '@salescore/frontend-common'
import { Button, Empty, Modal } from 'antd'
import { t } from 'i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import type {
  DrillDownParameter,
  DrillDownParameterForAny,
  DrillDownParameterForKpiPivot,
  DrillDownParameterForSheet,
} from '../recoil/navigation/atoms'
import { useKpiPivotParameter, useKpiPivotPickedPresetName, useNavigationModal } from '../recoil/navigation/hooks'
import { useRefetchKpiPivotQueryResult } from '../recoil/records/mutations/useRefetchKpiPivotQueryResult'
import { convertedSheetCustomModelsSelector, sharedUrlAtom } from '../recoil/view/atoms'
import { useMeValue, useViewConfig, useViewsContextValue, useViewValue } from '../recoil/view/hooks'
import { useKpiFormSelectors } from '../recoil/view/selectors/kpiFormSelector'
import { useViewsRelated } from '../recoil/view/selectors/viewsRelatedSelector'
import { DurationTimerStart } from './misc/DurationTimer'
import { ViewComponent } from './ViewComponent'
import { ViewComponentWrapper } from './ViewComponentWrapper'
import { ViewDrillDownComponentForRi } from './ViewDrillDownComponentForRi'
import { getDimensionsWithValue, ViewDrillDownModalContentForKpi } from './ViewDrillDownModalContentForKpi'

export const ViewDrillDownModal = ({ onFinish }: { onFinish?: () => Promise<void> | void }) => {
  const { drillDownModal } = useNavigationModal()
  const refetchKpiPivotQueryResult = useRefetchKpiPivotQueryResult()
  const {
    isDrillDown,
    isChangedDrillDownModal,
    setIsChangedDrillDownModal,
    isSaveConfigButtonActive,
    setIsSaveConfigButtonActive,
  } = useViewsContextValue()
  const { formModified, setFormModified } = useKpiFormSelectors()
  const previousView = useViewValue()
  const message = useMessage()
  const setSharedUrl = useSetRecoilState(sharedUrlAtom)

  return (
    <Modal
      className="ant-modal-without-padding"
      open={drillDownModal.isModalVisible}
      onCancel={() => {
        if (isChangedDrillDownModal) {
          refetchKpiPivotQueryResult.refetch(false)
        }
        if (
          formModified || // KPI設定パネルで変更があるとき
          (!previousView.id.startsWith(CORE_CONSTANT.KPI_PREVIEW_VIEW_ID_PREFIX) &&
            drillDownModal.innerState.hasChanges) || // KPIシートのレコードに変更があるとき（ただし、KPIプレビューからモーダルを開いた時を除く）
          isSaveConfigButtonActive // KPIシートの設定に変更があるとき
        ) {
          const messageKey = `close-modal-warning`
          message.warning({
            key: messageKey,
            content: (
              <span>
                {t(`変更差分がある場合は閉じられません。`)}
                <br />
                {t(`差分を削除してモーダルを閉じますか？`)}
                <Button
                  className="ml-2"
                  onClick={() => {
                    setFormModified(false)
                    setIsSaveConfigButtonActive(false)
                    drillDownModal.hideModal()
                    setSharedUrl(undefined)
                    message.destroy(messageKey)

                    if (!isDrillDown && isSome(setIsChangedDrillDownModal)) {
                      setIsChangedDrillDownModal(false)
                    }
                  }}
                >
                  {t(`はい`)}
                </Button>
              </span>
            ),
            duration: 3,
          })
        } else {
          drillDownModal.hideModal()
          setSharedUrl(undefined)

          if (!isDrillDown && isSome(setIsChangedDrillDownModal)) {
            setIsChangedDrillDownModal(false)
          }
        }
      }}
      width={'98%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{ top: '3%' }}
      footer={<></>}
      bodyStyle={{
        padding: 0,
      }}
      destroyOnClose
    >
      {drillDownModal.content !== undefined && (
        <ViewDrillDownModalBody
          parameter={drillDownModal.content}
          onFinish={async () => {
            if (onFinish !== undefined) {
              await onFinish()
            }
            drillDownModal.hideModal()
          }}
        />
      )}
    </Modal>
  )
}

function ViewDrillDownModalBody({
  parameter,
  onFinish,
}: {
  parameter: DrillDownParameter
  onFinish?: () => Promise<void>
}) {
  const previousView = useViewValue()
  const previousConfig = useViewConfig()

  const type = parameter.type
  switch (type) {
    case 'form': {
      if (previousConfig.type === 'sheet') {
        return (
          <div data-e2e={`view-drill-down-component-for-${previousConfig.type}`}>
            <ViewDrillDownComponentForSheet previousConfig={previousConfig} parameter={parameter} onFinish={onFinish} />
          </div>
        )
      }
      break
    }
    case 'kpi': {
      if (previousConfig.type === 'kpiPivot') {
        return (
          <div data-e2e={`view-drill-down-component-for-${previousConfig.type}`}>
            <ViewDrillDownComponentForKpiPivot parameter={parameter} onFinish={onFinish} />
          </div>
        )
      }
      if (previousConfig.type === 'kpi') {
        return (
          <div data-e2e={`view-drill-down-component-for-${previousConfig.type}`}>
            <ViewDrillDownModalContentForKpi
              parameter={parameter}
              onFinish={onFinish}
              kpiView={{ ...previousView, config: previousConfig }}
            />
          </div>
        )
      }
      if (previousConfig.type === 'kpiTimeSeries') {
        return (
          <div data-e2e={`view-drill-down-component-for-${previousConfig.type}`}>
            <ViewDrillDownComponentForRi parameter={parameter} onFinish={onFinish} />
          </div>
        )
      }
      break
    }
    case 'any': {
      return (
        <div data-e2e={`view-drill-down-component-for-${previousConfig.type}`}>
          <ViewDrillDownComponentForAny parameter={parameter} onFinish={onFinish} />
        </div>
      )
    }
    default: {
      throw new Error(type satisfies never)
    }
  }

  // TODO
  return <Empty />
}

// TODO
export const dynamicViewFields: ViewFieldsFragment = {
  id: '',
  viewGroupId: ``,
  type: `sheet`,
  name: ``,
  queries: [],
  ui: [],
  config: { type: `sheet` },
  rank: 0,
  defaultPermission: PermissionActionEnum.None,
  importedSourceId: ``,
  emoji: ``,
  roleRecordPermissions: [],
  private: false,
  archived: false,
  archivedAt: null,
}

export function ViewDrillDownComponentForSheet({
  parameter,
  previousConfig,
  onFinish,
}: {
  parameter: DrillDownParameterForSheet
  previousConfig: ViewConfigSheet
  onFinish?: () => Promise<void>
}) {
  const view = useViewValue()
  const { organization } = useMeValue()
  const formConfig = convertSheetConfigToFormConfig(previousConfig)
  const viewsContext = useViewsContextValue()
  const convertedSheetCustomModels = useRecoilValue(convertedSheetCustomModelsSelector)
  const drillDownViewId = CORE_CONSTANT.SHEET_FORM_DYNAMIC_VIEW_ID_PREFIX + view.id

  if (formConfig === undefined) {
    return (
      <Empty
        description={
          <div>
            {t(`ビューが見つかりません。`)}
            <br />
            <Button icon={<PlusOutlined />} type="primary">
              {t(`新規作成`)}
            </Button>
          </div>
        }
      />
    )
  }

  return (
    <div className="pt-6">
      <ViewComponent
        view={{
          ...dynamicViewFields,
          id: drillDownViewId,
          name: `${view.name}のフォーム`,
          config: formConfig,
          queries: [],
          ui: [],
        }}
        parameter={{ recordId: parameter.recordId }}
        onFinish={onFinish}
        viewsContext={{
          ...viewsContext,
          isDrillDown: true,
          models: [
            ...viewsContext.models,
            // 本来であればsheetCustomModelsの解決はViewComponent側で行われるので、ここで渡す必要はないのだが
            // sheetCustomModelsはviewに紐づくモデルであり、上記で動的にフォーム用のviewを生成している影響で、
            // フォーム用のビューには元のシートのsheetCustomModelが紐づかず、解決されない
            // よって、ややこしい対応になってしまうが、ここで元のsheetCustomModelsを渡す
            ...convertedSheetCustomModels,
          ],
        }}
      />
      <DurationTimerStart viewId={drillDownViewId} organizationId={organization.id} trigger="drillDownModalOpened" />
    </div>
  )
  // })
}

//
// parameterからkpiIdを抽出し、kpiViewに変換する
//
function ViewDrillDownComponentForKpiPivot({
  parameter,
  onFinish,
}: {
  parameter: DrillDownParameterForKpiPivot
  onFinish?: () => Promise<void>
}) {
  const [kpiPivotParameter] = useKpiPivotParameter()
  const { searchView } = useViewsRelated()
  const dimensionsWithValue = getDimensionsWithValue(parameter, kpiPivotParameter.pivot)
  const kpiDimension = dimensionsWithValue.find((x) => x.key === CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key)
  const kpiViewId = (kpiDimension?.value?.value as string) ?? parameter.viewId
  const kpiView = searchView(kpiViewId)
  const kpiViewConfig = kpiView?.config
  const [kpiPivotPresetName] = useKpiPivotPickedPresetName()
  if (kpiView === undefined || kpiViewConfig?.type !== 'kpi') {
    return <Empty description={<div>{t(`KPIビューが見つかりません。`)}</div>} />
  }

  // 前半のロジックが冗長だが、一旦許容する
  return (
    <ViewDrillDownModalContentForKpi
      kpiView={{ ...kpiView, config: kpiViewConfig }}
      parameter={{ ...parameter, kpiPivotPresetName }}
      onFinish={onFinish}
    />
  )
}

export function ViewDrillDownComponentForAny({
  parameter,
  onFinish,
}: {
  parameter: DrillDownParameterForAny
  onFinish?: () => Promise<void>
}) {
  const viewsContext = useViewsContextValue()

  return (
    <div
      style={{
        height: 'calc(100vh - 150px)', // 画面一杯に表示するために、100vhからモーダル・タイトルをざっくり引いた値を指定
      }}
    >
      <ViewComponentWrapper
        viewId={parameter.viewId}
        parameter={{}}
        onFinish={onFinish}
        viewsContext={{ ...viewsContext, isDrillDown: true }}
        withHeader
      />
    </div>
  )
  // })
}
