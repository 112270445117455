import { castString, range, roundValue } from '@salescore/buff-common'
import { POSTHOG_EVENTS } from '@salescore/client-base'
import { convertRecordNodeToSheetRow, CORE_CONSTANT, type SheetRow } from '@salescore/core'
import { CsvDownloadWithTextButton, generateCsvString } from '@salescore/frontend-common'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'

import { recordsAtom } from '../../../recoil/records/atoms'
import { pivotColumnsSelector } from '../../../recoil/selectors/pivotColumnsSelector'
import { usePosthogTrackClick } from '../../../recoil/usePosthogTrack'
import { useViewAbilityValue, useViewValue } from '../../../recoil/view/hooks'
import { extractActualAndGoal } from '../../../rsheet/components/body/cell/kpi/extractKpiInfo'

export function DashboardCsvDownloadButton() {
  const rows = useRecoilValue(recordsAtom)
  const { columns } = useRecoilValue(pivotColumnsSelector)
  const view = useViewValue()
  const posthogTrackClick = usePosthogTrackClick()
  const ability = useViewAbilityValue()

  return (
    <CsvDownloadWithTextButton
      type="text"
      disabled={!ability.canDownloadCsv}
      filename={view.name}
      onClick={() => {
        posthogTrackClick(POSTHOG_EVENTS.click_dashboard_csv_download)
      }}
      fetch={
        // eslint-disable-next-line @typescript-eslint/require-await
        async () => {
          // シートのCSVダウンロードは、ダウンロードしたい行が大量になることが想定されるため、サーバー側でcsvを生成してダウンロードする
          // ダッシュボードについては、ダウンロードしたい行が少ないのと、ダッシュボード関連のロジックが現状だとクライアントサイドで実装されてしまっているので、
          // クライアント側でcsvを生成してダウンロードする
          // TODO: ロジックが諸々と分散しているので、core側で実装した上で、csvは常にサーバーで生成するようにすると見通しがよさそう
          const columnsWithoutSystem = columns.slice(1)

          //
          // ヘッダー行の生成
          //
          const titleHeaders = columnsWithoutSystem.flatMap((c) => {
            if (c.field?.name.startsWith(`dimension`) ?? false) {
              return [(c.title ?? '').replace(CORE_CONSTANT.KPI_PIVOT_NULL_STRING, '')]
            }
            // KPIの列は、実績と目標の2列に分割して表示する
            return [`${c.title ?? ''} (${t(`実績`)})`, `${c.title ?? ''} (${t(`目標`)})`]
          })
          // ブロックの方のタイトルもヘッダーに含める
          const blockMaxDepth = columnsWithoutSystem.map((x) => x.nodeBlock?.labels.length ?? 0).max() ?? 0
          const blockHeaders = range(0, blockMaxDepth - 1).map((depth) =>
            columnsWithoutSystem.flatMap((c) => {
              if (c.field?.name.startsWith(`dimension`) ?? false) {
                return ['']
              }
              const label = c.nodeBlock?.labels[depth] ?? ``
              return [label, label]
            }),
          )
          const headers = [...blockHeaders, titleHeaders]

          //
          // bodyの表示
          //
          const sheetRows = rows.map((row) =>
            convertRecordNodeToSheetRow(
              row,
              columnsWithoutSystem.map((column) =>
                column.node === undefined
                  ? null
                  : {
                      fieldName: column.field?.name,
                      nodePath: column.node.path,
                    },
              ),
            ),
          )
          const body = sheetRows.flatMap((sheetRow) => generateCsvBody(sheetRow))

          return generateCsvString({ headers: headers[0]!, rows: [...headers.slice(1), ...body] })
        }
      }
    />
  )
}

function generateCsvBody(sheetRow: SheetRow): string[][] {
  const values = sheetRow.columns.map((column) => {
    const reversed = [...column.innerCells].reverse() // このあとfindLast()をしたいだけだが、なぜかtsでエラーになるのでreverse()してからfind()している(tsconfigが悪い？)
    return range(0, sheetRow.height - 1).map((index): string[] => {
      const cell = reversed.find((cell) => cell.innerRowIndexStart <= index)
      if (cell?.recordNode === undefined) {
        return ['']
      }
      if (column.uiColumn?.fieldName?.startsWith(`dimension`) ?? false) {
        return [
          castString(cell.recordNode.attributes[`${column.uiColumn?.fieldName ?? ''}_label`]) ??
            castString(cell.value) ??
            '',
        ]
      }

      const x = extractActualAndGoal(column.uiColumn?.fieldName ?? '', cell.recordNode)
      return [`${roundValue(x.actual) ?? ''}`, `${roundValue(x.goal) ?? ''}`]
    })
  })

  // このvaluesは、行がcolumns、列がrowIndexとなっている
  // これを、行がrowIndex、列がcolumnsとなるように変換する
  return transpose(values).map((row) => row.flat())
}

function transpose<T>(values: T[][]): T[][] {
  return values[0]?.map((_, colIndex) => values.map((row) => row[colIndex] as T)) ?? []
}
