import type { ViewConfigTreeNode } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { viewSelector } from '../../selectors/viewSelector'
import { setConfigMutation } from '../setConfigMutation'

export const setNodeMutation = mutation<{
  node: ViewConfigTreeNode
}>({
  key: `view/setNodeMutation`,
  set({ get, set }, { node }) {
    const { config, flattenNodes } = get(viewSelector)
    const tree = config.tree
    if (tree === undefined) {
      // 初期化はsetRootNodeにより終わっているはず
      return
    }

    set(setConfigMutation, {
      ...config,
      tree: replaceTreeRec(tree, node),
    })
  },
})

function replaceTreeRec(tree: ViewConfigTreeNode, node: ViewConfigTreeNode): ViewConfigTreeNode {
  if (tree.name === node.name) {
    return node
  }

  return {
    ...tree,
    children: tree.children?.map((child) => replaceTreeRec(child, node)),
  }
}
