import { DeleteOutlined, HolderOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useMutation } from '@apollo/client'
import type { EltModelFieldsFragment, ViewFieldsFragment } from '@salescore/client-api'
import { CreateViewDocument, UpdateViewDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { HUB_PROPERTY_TYPE_ICONS, propertyTypeOptions } from '@salescore/client-common'
import {
  type CoreModel,
  isRequiredProperty,
  type ModelProperty,
  type ViewConfig,
  type ViewConfigForm,
  type ViewConfigUiComponent,
} from '@salescore/core'
import { getColumnSearchProps, SortableFlexElement, useBooleanState } from '@salescore/frontend-common'
import { Alert, Button, Checkbox, Col, message, Row, Space, Table } from 'antd'
import { t } from 'i18next'
import { useMemo, useState } from 'react'

//
// モデルに紐づくフォームビューを設定するフォーム
// 名前がややこしいが、いい名前が思いつかなかった… :bow:
//
export function ModelFormViewForm({
  formView,
  model,
  onAfterFinish,
}: {
  formView: ViewFieldsFragment | undefined
  model: EltModelFieldsFragment
  onAfterFinish: () => void
}): JSX.Element {
  const [pickedPropertyNames, setPickedPropertyNames] = useState<string[]>(() => {
    if (formView === undefined) {
      return model.model.properties.filter((x) => isRequiredProperty(x)).map((x) => x.name)
    }
    return convertConfigToPropertyNames(formView.config)
  })
  const [createView] = useMutation(CreateViewDocument)
  const [updateView] = useMutation(UpdateViewDocument)
  const loading = useBooleanState()

  const onFinish = async (): Promise<void> => {
    if (pickedPropertyNames.isBlank()) {
      void message.error(t(`1つ以上の項目を選択してください`))
      return
    }

    loading.setTrue()
    try {
      if (formView === undefined) {
        await createView({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            view: {
              name: `${model.model.label}のフォーム`, // TODO: i18nどうする？結局使われないから良いだろうか？
              eltModelId: model.id,
              config: convertPropertyNamesToFormConfig(pickedPropertyNames, model.model),
            },
          },
        })
        void message.success(t(`保存しました`))
        onAfterFinish()
        return
      }

      await updateView({
        variables: {
          organizationId: getOrganizationIdFromPath(),
          view: {
            id: formView.id,
            config: convertPropertyNamesToFormConfig(pickedPropertyNames, model.model),
          },
        },
      })
      void message.success(t(`保存しました`))
      onAfterFinish()
    } catch (error) {
      if (error instanceof Error) {
        void message.error(error.message)
      } else {
        void message.error(t(`エラーが発生しました`))
      }
    } finally {
      loading.setFalse()
    }
  }

  return (
    <PageHeader
      title={
        <span>
          {t(`入力項目設定`)} - {model.model.label}
        </span>
      }
      extra={[
        <Button
          key="save"
          type="primary"
          icon={<SaveOutlined />}
          loading={loading.isTrue}
          onClick={() => {
            void onFinish()
          }}
          disabled={pickedPropertyNames.isBlank()}
        >
          {t('保存')}
        </Button>,
      ]}
    >
      {pickedPropertyNames.isBlank() && (
        <Alert
          type="warning"
          showIcon
          description={<div>{t(`1つ以上の項目を選択してください`)}</div>}
          className="mb-3"
        />
      )}
      <Row justify="space-between">
        <Col
          span={12}
          className="m-1 rounded-lg bg-gray-50 p-4"
          style={{
            backgroundColor: '#fafafa', // なぜかbg-gray-50が効かない？
          }}
        >
          <SelectorTable
            model={model.model}
            pickedPropertyNames={pickedPropertyNames}
            toggle={(propertyName) => {
              if (pickedPropertyNames.includes(propertyName)) {
                setPickedPropertyNames((xs) => xs.filter((x) => x !== propertyName))
              } else {
                setPickedPropertyNames((xs) => [...xs, propertyName])
              }
            }}
            add={(propertyName) => {
              setPickedPropertyNames((xs) => [...xs, propertyName].unique())
            }}
            remove={(propertyName) => {
              setPickedPropertyNames((xs) => xs.filter((x) => x !== propertyName))
            }}
          />
        </Col>
        <Col
          span={11}
          className="m-1 rounded-lg bg-gray-50 p-4"
          style={{
            backgroundColor: '#fafafa', // なぜかbg-gray-50が効かない？
          }}
        >
          <Sorter
            model={model.model}
            pickedPropertyNames={pickedPropertyNames}
            setPickedPropertyNames={setPickedPropertyNames}
          />
        </Col>
      </Row>
    </PageHeader>
  )
}

function SelectorTable({
  model,
  pickedPropertyNames,
  toggle,
  add,
  remove,
}: {
  model: CoreModel
  pickedPropertyNames: string[]
  toggle: (propertyName: string) => void
  add: (propertyName: string) => void
  remove: (proeprtyName: string) => void
}): JSX.Element {
  const { requiredProperties, uncheckedRequiredProperties } = useMemo(() => {
    const requiredProperties = model.properties.filter((x) => isRequiredProperty(x))
    // まだチェックされていないプロパティ
    const uncheckedRequiredProperties = requiredProperties.filter(
      (requiredProperty) => !pickedPropertyNames.includes(requiredProperty.name),
    )

    return {
      requiredProperties,
      uncheckedRequiredProperties,
    }
  }, [model, pickedPropertyNames])

  return (
    <div>
      <Table
        scroll={{ y: 550 }}
        dataSource={model.properties.map((x) => ({ ...x, key: x.name }))}
        pagination={false}
        size="small"
        rowClassName={() =>
          // const isPicked = record.isPicked
          `cursor-pointer bg-white hover:opacity-70`
        }
        onRow={(record) => ({
          onClick() {
            toggle(record.name)
          },
        })}
        rowSelection={{
          selectedRowKeys: pickedPropertyNames,
          onSelect: (record) => {
            toggle(record.name)
          },
        }}
        columns={[
          {
            title: t(`型`),
            dataIndex: 'propertyType',
            width: 50,
            filters: propertyTypeOptions.map((x) => ({
              value: x.value,
              text: x.label,
            })),
            onFilter: (value, field) => field.type === value,
            render(_, property) {
              return <span>{HUB_PROPERTY_TYPE_ICONS[property.type]}</span>
            },
          },
          {
            dataIndex: 'propertyLabel',
            title: t(`項目名`),
            ...getColumnSearchProps((x: ModelProperty) => x.label),
            render(_, property) {
              return <span>{property.label}</span>
            },
          },
          {
            dataIndex: 'addButton',
            width: 80,
            render(_) {
              return (
                <span>
                  <Button type="text" size="small" icon={<PlusOutlined />} className="text-blue-600">
                    {t(`選択`)}
                  </Button>
                </span>
              )
            },
          },
        ]}
      />
      <div className="mt-4">
        <Checkbox
          // 必須項目がないとき、disabledにする
          disabled={requiredProperties.isBlank()}
          // 必須項目があって、uncheckedなものがない=全てチェックしている時は、チェック済みにする
          checked={requiredProperties.isPresent() && uncheckedRequiredProperties.isBlank()}
          onClick={() => {
            if (uncheckedRequiredProperties.isPresent()) {
              // 未チェックのものがあるときは、全てチェックする
              for (const property of uncheckedRequiredProperties) {
                add(property.name)
              }
            } else {
              // 未チェックのものがなく、必須項目がある時、すでにチェックしたものを全て削除する
              for (const property of requiredProperties) {
                remove(property.name)
              }
            }
          }}
        >
          {t(`必須項目を全て追加`)}
        </Checkbox>
      </div>
    </div>
  )
}

function Sorter({
  pickedPropertyNames,
  model,
  setPickedPropertyNames,
}: {
  pickedPropertyNames: string[]
  model: CoreModel
  setPickedPropertyNames: (xs: string[]) => void
}): JSX.Element {
  const propertyMapper = useMemo(() => model.properties.groupByUniqueKey((x) => x.name), [model])
  return (
    <div>
      <div className="mb-3">
        <div className="font-bold">{t(`選択した項目`)}</div>
      </div>

      <SortableFlexElement
        axis="y"
        onChange={(items) => {
          setPickedPropertyNames(items.map((x) => x.key))
        }}
        items={pickedPropertyNames.map((propertyName) => ({
          key: propertyName,
          meta: propertyName, // TODO
          render() {
            const property = propertyMapper[propertyName]!
            return (
              <Row className="mb-1 rounded bg-white px-2 py-1" justify="space-between">
                <Space>
                  {<HolderOutlined />}
                  {HUB_PROPERTY_TYPE_ICONS[property.type]}
                  <span>{property.label}</span>
                </Space>
                <Space>
                  <Button
                    icon={<DeleteOutlined />}
                    type="text"
                    onClick={() => {
                      setPickedPropertyNames(pickedPropertyNames.filter((x) => x !== propertyName))
                    }}
                  />
                </Space>
              </Row>
            )
          },
        }))}
      />
    </div>
  )
}

function convertConfigToPropertyNames(config: ViewConfig): string[] {
  if (config.type !== 'form') {
    return []
  }
  //
  // すでにフォームビューを設定していたら、それをこの設定UIの初期値とする
  // この設定UIでは、単純にプロパティの一覧を表示して、それを選択するだけの設定しかできないので、
  // まずはビューに含まれるプロパティを取り出す
  // 現状は、必ず Row > Col > ... という形式になっているので、この形式になっている前提で取り出す
  // （このUI以外で、type=formなビューを作成する機能は存在せず、ここで作るとこの形式になる）
  //
  const rowComponent = config.components?.first()
  if (rowComponent?.componentType !== 'Row') {
    return []
  }
  const colComponents = rowComponent.children
  const properties = colComponents
    ?.map((x) => {
      if (x.componentType !== 'Col') {
        return
      }
      return x.children?.first()?.property
    })
    .compact()
  return properties?.map((x) => x.propertyName) ?? []
}

function convertPropertyNamesToFormConfig(propertyNames: string[], model: CoreModel): ViewConfigForm {
  const components = propertyNames
    .map((propertyName): ViewConfigUiComponent | undefined => ({
      componentType: `Col`,
      children: [
        {
          property: {
            nodeName: model.name,
            modelName: model.name,
            propertyName, // 存在確認は行わない
          },
        },
      ],
    }))
    .compact()

  return {
    type: 'form',
    tree: {
      type: 'model',
      name: model.name,
      modelName: model.name,
    },
    filterTree: {
      logicalOperator: 'and',
      children: [],
      leafs: [
        {
          type: 'property',
          filterType: 'equal',
          property: {
            nodeName: model.name,
            modelName: model.name,
            propertyName: 'id',
          },
          filterValueParameterName: 'recordId',
        },
      ],
    },
    components: [
      {
        componentType: `Row`,
        children: components,
      },
    ],
  }
}
