import type { ViewQueryList, ViewQueryNode } from '@salescore/core'
import { selector } from 'recoil'

import { listQueryAtom } from '../../view/atoms'
import { searchQuerySelector } from './searchQuerySelector'

export const listQuerySelector = selector({
  key: `records/listQuerySelector`,
  get({ get }) {
    const query = get(listQueryAtom)
    const { searchQueryFilterTree } = get(searchQuerySelector)
    const queryWithAdditionalOption: ViewQueryList = {
      ...query,
      filterTree: {
        leafs: [],
        logicalOperator: 'and',
        children: [query.filterTree, searchQueryFilterTree].compact(),
      },
    }
    return {
      query,
      queryWithAdditionalOption,
    }
  },
})

export function getDefaultAttributes(node: ViewQueryNode, query: ViewQueryList) {
  const fields = query.fields.filter((x) => x.nodePath.isEqual(node.path))
  const defaultValues = fields
    .map((field) => {
      if (field.meta.defaultValue === undefined || field.write?.propertySourceName === undefined) {
        return
      }

      return {
        field,
        name: field.name,
        propertySourceName: field.write.propertySourceName,
        value: field.meta.defaultValue,
      }
    })
    .compact()
  const recordAttributes = defaultValues.toObject((x) => [x.name, x.value])
  const changeAttributes = defaultValues.toObject((x) => [x.propertySourceName, x.value])
  return {
    defaultValues,
    recordAttributes,
    changeAttributes,
  }
}
