import type { ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import type { SetRecoilState } from 'recoil'

import type { ViewRecordFieldChange } from '../../../state/useViewRecordsState/useChangesState'
import { addNewChildRecord } from './addNewRecord/addNewChildRecord'
import { addNewRootRecord } from './addNewRecord/addNewRootRecord'

export interface NewRecordChange {
  rowIndex: number
  innerRowIndex: number
  node: ViewQueryNode
}

export type OnAfterInsert = (recordNode: ViewQueryRecordNode) => void
export type OnAfterChange = (changes: ViewRecordFieldChange[]) => void

export function addNewRecords(
  rootRecordNodes: ViewQueryRecordNode[],
  newRecordIds: string[],
  newRecordChanges: NewRecordChange[],
  set: SetRecoilState,
  onAfterInsert?: OnAfterInsert,
) {
  return newRecordChanges
    .map((newRecordChange) => {
      const { node } = newRecordChange
      return node.path.length === 1
        ? addNewRootRecord(rootRecordNodes, newRecordIds, newRecordChange, set)
        : addNewChildRecord(rootRecordNodes, newRecordChange, set, onAfterInsert)
    })
    .compact()
}
