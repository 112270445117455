import { useMutation } from '@apollo/client'
import { range } from '@salescore/buff-common'
import {
  type OrganizationFieldsFragment,
  type OrganizationSettingInput,
  UpdateOrganizationDocument,
} from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { getOrganizationIdFromPath, OrganizationAvatar, UploadImageButton } from '@salescore/client-common'
import { Button, Form, message, Row, Select, Space, Typography } from 'antd'
import { t } from 'i18next'
import Link from 'next/link'
import { useState } from 'react'

interface FormValue {
  accountClosingMonth: Exclude<OrganizationSettingInput['accountClosingMonth'], undefined>
  // TODO: 旧Visualize削除と同時に削除予定
  // monthBeginningDay: Exclude<OrganizationSettingInput['monthBeginningDay'], undefined>
}

export function OrganizationBasicSettingForm({ organization }: { organization: OrganizationFieldsFragment }) {
  const [updateOrganizationMutation] = useMutation(UpdateOrganizationDocument)
  const [loading, setLoading] = useState(false)
  const onImageUploaded = async (imageUrl: string) => {
    await updateOrganizationMutation({
      variables: {
        organizationId: getOrganizationIdFromPath(),
        organization: {
          imageUrl,
        },
      },
    })
    void message.success(t(`画像をアップロードしました`))
  }

  return (
    <>
      <Typography.Title level={4}>{t(`基本設定`)}</Typography.Title>
      <Form
        onFinish={async (values: FormValue) => {
          try {
            await updateOrganizationMutation({
              refetchQueries: ['fetchOrganization'],
              variables: {
                organizationId: getOrganizationIdFromPath(),
                organization: {
                  setting: {
                    // TODO: 旧Visualize削除と同時に削除予定
                    // monthBeginningDay: values.monthBeginningDay,
                    accountClosingMonth: values.accountClosingMonth,
                  },
                },
              },
            })
            void message.success(t(`設定を更新しました`))
          } finally {
            setLoading(false)
          }
        }}
        initialValues={organization.setting}
        layout="vertical"
      >
        <Form.Item name="imageUrl" label={t(`組織プロフィール画像`)}>
          <Space>
            <OrganizationAvatar organization={organization} />
            <UploadImageButton onFinish={onImageUploaded} />
          </Space>
        </Form.Item>
        <Form.Item
          name="accountClosingMonth"
          label={t(`決算月`)}
          rules={[{ required: true, message: t(`決算月を入力してください`) }]}
          className="w-40"
        >
          <Select>
            {range(1, 12).map((accountClosingMonth) => (
              <Select.Option key={accountClosingMonth} value={accountClosingMonth}>
                {t(`${accountClosingMonth}月`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {/* // TODO: 旧Visualize削除と同時に削除予定
      <Form.Item
        // TODO: 開始日じゃない
        name="monthBeginningDay"
        label="月の開始日"
        rules={[{ required: true, message: '月の開始日を入力してください' }]}
        className="w-32"
      >
        <Select>
          {range(1, 31).map((day) => (
            <Select.Option key={day} value={day}>
              {day}日
            </Select.Option>
          ))}
        </Select>
      </Form.Item> */}
        <Form.Item>
          <Row justify="start">
            <Button type="primary" htmlType="submit" loading={loading}>
              {t(`保存`)}
            </Button>
          </Row>
        </Form.Item>
      </Form>

      <Row>
        <Link href={routes.businessDaysSettingsPath()} legacyBehavior>
          <Button>{t(`営業日の設定`)}</Button>
        </Link>
      </Row>
    </>
  )
}
