import { type ModalAtomType, useModalAtom } from '@salescore/frontend-common'
import { atom } from 'recoil'

const prefix = `settings/reverse_elt`
const reverseEltJobModalAtom = atom<ModalAtomType<{ jobId: string }>>({
  key: `${prefix}/jobModal`,
  default: {
    visible: false,
    content: undefined,
  },
})

export const useReverseEltRelated = () => {
  const reverseEltJobModal = useModalAtom(reverseEltJobModalAtom)
  return {
    reverseEltJobModal,
  }
}
