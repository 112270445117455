import { useSalescoreAuth } from '@salescore/client-base'
import { useOpenAuthorizationUrl } from '@salescore/client-common'
import { getQueryParameter } from '@salescore/frontend-common'
import { Button } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'

export const SignInBySalesforceOauth = () => {
  const { openAuthorizationUrl } = useOpenAuthorizationUrl()
  const { clearToken } = useSalescoreAuth()
  const from = getQueryParameter('from')

  const signIn = async () => {
    clearToken() // salescore authのtokenが残っていると、Auth0でログインしたのにsalescore token側のログイン情報で表示されてしまうので、ここで一応消しておく（基本的にはありえない）
    await openAuthorizationUrl({
      type: 'signIn',
      provider: 'salesforce',
    })
  }

  useEffect(() => {
    if (from === 'salesforceApp') {
      // salesforceからのアクセスの場合は、自動でログインさせる
      void signIn()
    }
  }, [from])

  return (
    <>
      <Button
        block
        size="large"
        type="primary"
        onClick={async () => {
          await signIn()
        }}
      >
        {t(`Salesforceでログイン`)}
      </Button>

      <div className="mt-8 text-center text-xs text-gray-500">
        <Trans>
          ログインできない場合などは、
          <br />
          お気軽にお問い合わせください。
        </Trans>
      </div>
    </>
  )
}
