import { useApolloClient } from '@apollo/client'
import { FetchFavoritesDocument } from '@salescore/client-api'
import { useSetRecoilState } from 'recoil'

import { useOrganizationId } from '../../global/atoms'
import { favoritesAtom } from '../atoms'

export function useRefetchFavorites() {
  const organizationId = useOrganizationId()
  const client = useApolloClient()
  const setFavorites = useSetRecoilState(favoritesAtom)
  const refetchFavorites = async () => {
    // ロジック
    const result = await client.query({
      query: FetchFavoritesDocument,

      variables: {
        organizationId,
      },
    })
    setFavorites(result.data.favorites ?? [])
  }

  return refetchFavorites
}
