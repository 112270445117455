import { RetryLink } from '@apollo/client/link/retry'
import { logger, LoggerEvents } from '@salescore/frontend-common'

// https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
export const retryLink = new RetryLink({
  attempts: {
    max: process.env.NODE_ENV === 'development' ? 3 : 3, // 開発環境でもホットリロードが走った際に通信エラー表示がでるのを避けるため、リトライする
    retryIf: (_error, operation) =>
      // mutationはリトライしない。queryのみretryする。
      // queryは必ずfetchにする命名規則になっているはず
      operation.operationName.startsWith(`fetch`),
  },
  delay: (count, operation, error) => {
    if (error instanceof Error) {
      const message = `${error.name}: ${error.message}, retry: ${count}, operation: ${operation.operationName}`
      logger.error(LoggerEvents.APOLLO_CLIENT, message)

      logger.debug(message)
    }
    return 2000 * 1.5 ** count
  },
})
