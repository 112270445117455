import { multiCompareFunction } from './array/compareFunction'
import { compareFunction } from './util'

export const sliceWhen =
  <T>(function_: (a: T, b: T) => boolean) =>
  (xs: T[]): T[][] => {
    if (xs.length === 0) {
      return []
    }
    if (xs.length === 1) {
      return [[xs[0]!]]
    }

    const result: T[][] = [[xs[0] as T]] // TODO
    for (let index = 1; index < xs.length; index++) {
      const x = xs[index]!
      const previousGroup = result.at(-1)!
      const previousValue = previousGroup.at(-1)!
      if (function_(previousValue, x)) {
        // 新しいグループとしてpush
        result.push([x])
      } else {
        previousGroup.push(x)
      }
    }
    return result
  }

export const mapConsIncremental = <A>(as: A[]): A[][] => {
  const xs = as.reduce<A[][]>((accumulator, x) => {
    const lastXs = accumulator.at(-1) ?? []
    const newXs = [...lastXs, x]
    return [...accumulator, newXs]
  }, [])
  return xs
}

function sortBy<T>(xs: T[], f: (x: T) => number) {
  return [...xs].sort((a, b) => {
    const aKey = f(a)
    const bKey = f(b)
    return aKey - bKey < 0 ? -1 : aKey === bKey ? 0 : 1
  })
}

// mapperの返り値の順番で要素をソートする。
// mapperがundefinedを返す場合は、その要素の位置は固定される
export function sortSpecificElementOnly<T>(xs: T[], mapper: (x: T) => number | undefined): T[] {
  const fixed = xs.filter((x) => mapper(x) === undefined)
  const toBeSorted = xs.filter((x) => mapper(x) !== undefined)
  const sorted = sortBy(toBeSorted, (x) => mapper(x) ?? 0)

  return xs.map((_, index) => {
    const isFixed = mapper(xs[index]!) === undefined
    if (isFixed) {
      return fixed.shift()!
    }
    return sorted.shift()!
  })
}

// 2023/06- こちらを使っていきたい
export const arrayUtil = {
  compareFunction,
  multiCompareFunction,
  sortSpecificElementOnly,
  mapConsIncremental,
  sliceWhen,
}
