import type { ViewQueryRecordNode } from '@salescore/core'
import * as React from 'react'
import { useContext } from 'react'

import type { RecordNodePath } from '../../recoil/records/mutations/upsertViewRecordByPath'
import type { useUpsertViewRecordsByPathMutation } from '../../recoil/records/mutations/upsertViewRecordsByPathMutation'

interface ViewUIRecordNodeContextProperties {
  recordNode?: ViewQueryRecordNode
  recordNodePath: RecordNodePath
  upsertViewRecordsByPath: ReturnType<typeof useUpsertViewRecordsByPathMutation>
}

export class ViewUIRecordNodeContextClass {
  public state: ViewUIRecordNodeContextProperties

  public constructor(state: ViewUIRecordNodeContextProperties) {
    this.state = state
  }

  public selectors() {
    return {
      getValue: (fieldName: string) => this.state.recordNode?.attributes[fieldName],
    }
  }

  public mutations() {
    return {
      onChange: ({ fieldName, value }: { fieldName: string; value: unknown }) => {
        this.state.upsertViewRecordsByPath({
          recordChanges: [
            {
              id: this.state.recordNode!.id!,
              recordNodePath: this.state.recordNodePath,
              fieldName,
              value,
            },
          ],
        })
      },
    }
  }
}

export const ViewUIRecordNodeContext = React.createContext<ViewUIRecordNodeContextClass>(undefined as never)

// どういう形で露出させるか検討中。。
export const useViewUIRecordNodeContext = () => {
  const c = useContext(ViewUIRecordNodeContext)

  return {
    ...c.state,
    ...c.selectors(),
    ...c.mutations(),
  }
}
