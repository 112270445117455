import { type Policy, policySchema } from './schema'

interface PolicyRecord {
  id: string
  organizationId: string | null
  // principal: PermissionPrincipal
  principal: unknown
  viewId: string | null
  viewGroupId: string | null
  action: string
  permission: boolean
  createdAt: Date
  updatedAt: Date
}

export function convertPolicyRecord(x: PolicyRecord) {
  const subject = getSubject(x)
  const policy: Policy = {
    // いったんanyでアサインしてからzodでチェックする
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
    principal: x.principal as any,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
    action: x.action as any,
    subject,
    permission: x.permission,
  }

  return policySchema.safeParse(policy)
}

function getSubject(x: PolicyRecord): Policy['subject'] | undefined {
  const { viewId, viewGroupId } = x
  if (viewId === null && viewGroupId === null) {
    return undefined
  }
  if (viewId !== null && viewGroupId !== null) {
    return undefined // TODO: ありえないはずだが、どうしようか？
  }
  if (viewId !== null) {
    return { id: viewId, entityType: 'view' }
  }
  if (viewGroupId !== null) {
    return { id: viewGroupId, entityType: 'viewGroup' }
  }
  return undefined
}
