import type { RSheetColumn, RSheetRecordNode } from '../../../../types'
import { extractActualAndGoal, type KpiInfo } from './extractKpiInfo'

export interface TransitionInfo {
  transitionRatio?: number
  transitionRatioGoal?: number
  diffTransitionRatio?: number
}

export function extractTransitionInfo(
  kpiInfo: KpiInfo | undefined,
  column: RSheetColumn<RSheetRecordNode>,
  columns: Array<RSheetColumn<RSheetRecordNode>>,
  recordNode: RSheetRecordNode,
) {
  if (kpiInfo === undefined || kpiInfo.kpi?.showTransition !== true) {
    return
  }
  // 前のcolumnsを抽出
  const previousColumn = columns[column.index - 1]
  if (previousColumn === undefined || previousColumn.type !== 'kpi') {
    return
  }
  // TODO: 前のカラムがKPI相当じゃなかったらエラー

  const nextKpiInfo = extractActualAndGoal(previousColumn.field?.name ?? '', recordNode)
  if (nextKpiInfo === undefined) {
    return
  }

  const transitionRatio =
    nextKpiInfo.actual !== undefined && kpiInfo.actual !== undefined
      ? roundNumber(((nextKpiInfo.actual * 1) / kpiInfo.actual) * 100)
      : undefined
  const transitionRatioGoal =
    nextKpiInfo.goal !== undefined && kpiInfo.goal !== undefined
      ? roundNumber(((nextKpiInfo.goal * 1) / kpiInfo.goal) * 100)
      : undefined

  const diffTransitionRatio =
    transitionRatio !== undefined &&
    Number.isFinite(transitionRatio) &&
    transitionRatioGoal !== undefined &&
    Number.isFinite(transitionRatioGoal)
      ? roundNumber(transitionRatio - transitionRatioGoal)
      : undefined

  return {
    transitionRatio,
    transitionRatioGoal,
    diffTransitionRatio,
  }
}

// 有効桁数2桁にしているが、numberで扱っている限り4.0が4になってしまうため、表示時に改めてこの処理を行なっている。
function roundNumber(x: number | undefined): number | undefined {
  if (x === undefined) {
    return undefined
  }
  if (Number.isInteger(x)) {
    return x
  }
  if (Number.isNaN(x)) {
    // ありえないが一応
    return undefined
  }

  //
  // 表示にちょうど良い程度に小数点を丸めてから返す
  //
  // 10以上の時は、小数点を表示しない
  if (Math.abs(x) >= 10) {
    return Math.floor(x)
  }
  if (Math.abs(x) < 0.01) {
    // 0.01より小さい場合、表示が長くなるので0として返す
    return 0
  }
  // 10未満かつ0.01以上のとき、有効数字2桁(=小数点第一位)で返す
  return Number(x.toPrecision(2))
}
