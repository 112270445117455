import { openIntercom } from '@salescore/client-base'
import dayjs, { type Dayjs } from 'dayjs'

// const startAt: Dayjs | undefined = dayjs(`2023-06-14 00:00:00`)
// const endAt: Dayjs | undefined = dayjs(`2023-06-14 01:00:00`)
const startAt = undefined as Dayjs | undefined
const endAt = undefined as Dayjs | undefined

export function isMaintainancePeriod() {
  // QA用のバックドア
  // 悪意のあるユーザーがこのバックドアを使っても現状は特に問題がないため、このシンプルな仕組みで十分
  const skipFlag = localStorage.getItem(`skipMaintainance`)
  if (skipFlag === `true`) {
    return false
  }

  if (startAt === undefined || endAt === undefined) {
    return false
  }

  const current = dayjs()
  return current.isAfter(startAt) && current.isBefore(endAt)
}

export const MaintainanceLayout = () => {
  openIntercom({})
  return (
    <div className="flex size-full flex-col items-center justify-center text-center align-middle text-gray-400">
      <div>
        現在メンテナンス中です。
        <br />
        メンテナンスの期間は{startAt?.format(`YYYY年MM月DD日HH時`)}〜{endAt?.format(`YYYY年MM月DD日HH時`) ?? ''}
        を予定しております。
        <br />
        ご迷惑をお掛け致しますが、何卒どうぞよろしくお願いします。
      </div>
    </div>
  )
}
