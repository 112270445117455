import { DeleteOutlined, FilterOutlined, PlusCircleFilled } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { isSome } from '@salescore/buff-common'
import { CONSTANT } from '@salescore/client-base'
import type { NodePropertyName, ViewConfigSorter } from '@salescore/core'
import { useMessage } from '@salescore/frontend-common'
import { Button, Col, Form, Modal, Row, Select, Space } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { useChangesValue } from '../../../../recoil/records/hooks'
import { useSetSorterMutations } from '../../../../recoil/view/mutations/setSorterMutations'
import { useConfigSheetSelector } from '../../../../recoil/view/selectors/configSheetSelector'

export const ViewQuerySortersModal = () => {
  const { sortersModal } = useNavigationModal()
  const changes = useChangesValue()
  const message = useMessage()

  useEffect(() => {
    if (sortersModal.isModalVisible && changes.length > 0) {
      message.warning(t('設定を変更すると変更情報が失われます。'))
    }
  }, [sortersModal.isModalVisible])

  return (
    <Modal
      open={sortersModal.isModalVisible}
      onCancel={() => {
        sortersModal.hideModal()
      }}
      width={'90%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
      }}
      destroyOnClose
      footer={<></>}
    >
      <ViewQuerySorters
        onAfterFinish={() => {
          sortersModal.hideModal()
        }}
      />
    </Modal>
  )
}

interface Option {
  value: string
  label: string
  nodeProperty: NodePropertyName
}

type ViewConfigSorterForForm = Partial<ViewConfigSorter>

function nodePropertyToKey(nodeProperty: NodePropertyName) {
  return [nodeProperty.nodeName, nodeProperty.modelName, nodeProperty.propertyName].join(',')
}

function ViewQuerySorters({ onAfterFinish }: { onAfterFinish: () => void }) {
  const { config, getNodePropertyLabel } = useConfigSheetSelector()
  const mutation = useSetSorterMutations()
  const fields = config.fields ?? []
  const options: Option[] = fields.map((field) => ({
    value: nodePropertyToKey(field.property),
    label: getNodePropertyLabel(field.property),
    nodeProperty: field.property,
  }))

  const [sorters, setSorters] = useState<ViewConfigSorterForForm[]>(() => config.sorters ?? [])

  const onFinish = () => {
    const newSorters = sorters
      .map((x): ViewConfigSorter | undefined => {
        if (x.property === undefined) {
          return undefined
        }
        return {
          property: x.property,
          order: x.order ?? 'asc',
          type: 'property',
        }
      })
      .compact()
    mutation.set({ sorters: newSorters })
    onAfterFinish()
  }

  return (
    <PageHeader
      title={
        <Space>
          <FilterOutlined />
          <span>{t(`並び替え`)}</span>
        </Space>
      }
      extra={[]}
    >
      <Form layout="vertical">
        {sorters.map((sorter, index) => (
          <>
            <ViewQuerySorterFormItem
              // key={`filter-${index}-${filter.nodePaths.join(',') ?? ''}`}
              sorter={sorter}
              options={options}
              onChange={(newSorter) => {
                setSorters((xs) => [...xs.slice(0, index), newSorter, ...xs.slice(index + 1)])
              }}
              onRemove={() => {
                setSorters((xs) => [...xs.slice(0, index), ...xs.slice(index + 1)])
              }}
            />
            <hr className="opacity-30" />
          </>
        ))}
        <Space className="">
          <Button
            // type="dashed"
            onClick={() => {
              setSorters((xs) => [...xs, {}])
            }}
            block
            icon={<PlusCircleFilled />}
            type="text"
            style={{
              color: CONSTANT.colors.primaryColor,
              paddingLeft: 0,
            }}
          >
            {t(`並び替えを追加`)}
          </Button>
        </Space>
      </Form>
      <Row justify="end">
        <Button
          onClick={() => {
            onFinish()
          }}
        >
          {t(`適用`)}
        </Button>
      </Row>
    </PageHeader>
  )
}

function ViewQuerySorterFormItem({
  sorter,
  options,
  onChange,
  onRemove,
}: {
  sorter: ViewConfigSorterForForm
  options: Option[]
  onChange: (x: ViewConfigSorterForForm) => void
  onRemove: () => void
}) {
  // const { optionGroups } = useConnectionsSelector()
  const spans = [10, 6, 8]

  return (
    <Row justify="space-between" align="middle">
      <Col span={23}>
        <Row justify="space-between" align="middle" gutter={8}>
          <Col span={spans[0]}>
            <Form.Item label={t(`並び替え項目`)} rules={[{ required: true, message: t(`入力してください`) }]} required>
              <Select
                value={isSome(sorter.property) ? nodePropertyToKey(sorter.property) : ''}
                options={options}
                onChange={(value) => {
                  const option = options.find((x) => x.value === value)
                  if (isSome(option)) {
                    onChange({
                      ...sorter,
                      property: option.nodeProperty,
                    })
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={spans[1]}>
            <Form.Item label={t(`並び順`)} required>
              <Select
                style={{ width: 100 }}
                value={sorter.order}
                onChange={(order) => {
                  onChange({
                    ...sorter,
                    order,
                  })
                }}
                options={[
                  {
                    label: t(`昇順`),
                    value: 'asc',
                  },
                  {
                    label: t(`降順`),
                    value: 'desc',
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={spans[2]}>{/*  */}</Col>
        </Row>
      </Col>
      <Col span={1}>
        <Button
          icon={<DeleteOutlined />}
          type="text"
          onClick={() => {
            onRemove()
          }}
        />
      </Col>
    </Row>
  )
}
