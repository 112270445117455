import type { EltChangeResultsChunk, ViewQueryRecordNode } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'

import type { ChangeHistory } from '../../../state/useViewRecordsState/useChangesState'
import { useViewValue } from '../../view/hooks'
import { useCachedViewsSelector } from '../../view/selectors/cachedViewSelector'
import { backedChangesAtom, changesAtom, errorsAtom, recordsAtom, sheetInSaveErrorsAtom } from '../atoms'

export const backHistoryMutation = mutation({
  key: `view/records/backHistoryMutation`,
  set({ get, set }, backCount: number) {
    if (backCount < 1) {
      return
    }
    const changes = get(changesAtom)
    const records = get(recordsAtom)

    const backingChanges = changes.slice(changes.length - backCount)
    if (backingChanges.isEmpty()) {
      return
    }

    // 本当はchangesは操作せず、指し示すchangesだけを変更したいが、実装がやや面倒なので後回し
    set(recordsAtom, JSON.parse(changes[changes.length - backCount]!.snapshot) as ViewQueryRecordNode[])
    set(changesAtom, (oldValue) => oldValue.slice(0, oldValue.length - backCount))
    set(backedChangesAtom, (oldChanges) => [
      ...oldChanges,
      ...backingChanges
        .map(
          (x, index): ChangeHistory => ({
            ...x,
            snapshotAfterChange: backingChanges[index + 1]?.snapshot ?? JSON.stringify(records),
          }),
        )
        .reverse(),
    ])
  },
})

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const forwardHistoryMutation = mutation<void>({
  key: `view/records/forwardHistoryMutation`,
  set({ get, set }) {
    const backedChanges = get(backedChangesAtom)
    const currentChange = backedChanges.at(-1)
    if (currentChange?.snapshotAfterChange === undefined) {
      return
    }

    // 本当はchangesは操作せず、指し示すchangesだけを変更したいが、実装がやや面倒なので後回し
    set(recordsAtom, JSON.parse(currentChange.snapshotAfterChange) as ViewQueryRecordNode[])
    set(backedChangesAtom, (oldValue) => oldValue.slice(0, -1))
    set(changesAtom, (oldChanges) => [...oldChanges, currentChange])
  },
})

export type ChangesWithTransactionUpsertStatus =
  | { status: 'notUpserted' }
  | { status: 'upsertError'; error: unknown }
  | { status: 'upserted'; results: EltChangeResultsChunk[] }

function replaceRecordId(
  record: ViewQueryRecordNode,
  successfullCreateRecordIdMap: Record<string, string>,
  alreadyReplacedRecordIds: Set<string>,
  depth: number,
): ViewQueryRecordNode {
  if (Object.keys(successfullCreateRecordIdMap).length === alreadyReplacedRecordIds.size || depth > 10) {
    return record
  }
  const updatedRecordChildren = record.children.map((tableChild) => {
    const updatedTableChildren = tableChild.children.map((recordChild) =>
      replaceRecordId(recordChild, successfullCreateRecordIdMap, alreadyReplacedRecordIds, depth + 1),
    )
    return { ...tableChild, children: updatedTableChildren }
  })
  if (record.id === undefined || successfullCreateRecordIdMap[record.id] === undefined) {
    return { ...record, children: updatedRecordChildren }
  }

  const updatedRecordId = successfullCreateRecordIdMap[record.id]
  alreadyReplacedRecordIds.add(record.id)
  return { ...record, id: updatedRecordId, children: updatedRecordChildren }
}

export const useChangesMutation = () => {
  const view = useViewValue()
  const [changes, setChanges] = useRecoilState(changesAtom)
  const setBackedChanges = useSetRecoilState(backedChangesAtom)
  const setRecords = useSetRecoilState(recordsAtom)
  const forwardHistory = useSetRecoilState(forwardHistoryMutation)
  const backHistory = useSetRecoilState(backHistoryMutation)
  const setErrors = useSetRecoilState(errorsAtom)
  const setSheetInSaveErrors = useSetRecoilState(sheetInSaveErrorsAtom)
  const { updateCachedView } = useCachedViewsSelector()

  return {
    clearHistoryAndMoveToInitialState() {
      const change = changes.first()
      if (change === undefined) {
        return
      }
      setRecords(JSON.parse(change.snapshot) as ViewQueryRecordNode[])
      setChanges([])
    },
    clearHistory() {
      setChanges([])
    },
    async resetChangesWithTransaction(callback: () => Promise<ChangesWithTransactionUpsertStatus>) {
      const changesToSave = [...changes]
      const result = await callback()
      if (result.status !== 'upserted') {
        // upserted でないときは、 changesAtom の中身はそのままにしておく。
        // つまり、保存処理に渡した changes と保存処理中に新たに作られた changes を合わせたものが changesAtom の中身。
        if (result.status === 'notUpserted') {
          return
        }
        // バックエンド側でtry-catchしているので、ここでエラーになることはネットワークエラー系のみのはず
        const error = result.error
        setErrors([JSON.stringify(error)])
        const error_ = error instanceof Error ? error : new Error(`エラーが発生しました`)
        throw error_
      }
      // upsertedなとき、エラーになったレコードが存在すれば、それだけを復元する
      const results = result.results.flatMap((x) => x.results)
      const errors = results.map((x) => (x.status === 'success' ? undefined : x)).compact()
      const successes = results.map((x) => (x.status === 'success' ? x : undefined)).compact()
      const successfullCreateRecordIdMap: Record<string, string> = {}
      for (const success of successes.filter((x) => x.change.type === 'create')) {
        successfullCreateRecordIdMap[success.change.id] = success.result.id
      }
      if (successes.isPresent()) {
        updateCachedView?.({ viewId: view.id, shouldIgnoreRefetchCache: true })
      }

      if (errors.isBlank()) {
        void message.success(t(`{{length}}件のレコードを保存しました`, { length: results.length }))
        setSheetInSaveErrors(undefined)
        updateCachedView?.({ viewId: view.id, sheetInSaveErrors: undefined })
        return results
      }
      const errorIds = new Set(errors.map((x) => x.change.id))
      const changesWithError = changesToSave
        .map((change): ChangeHistory | undefined => {
          const newChanges = change.changes.filter((c) => errorIds.has(c.id!))
          if (newChanges.isBlank()) {
            return undefined
          }
          return {
            ...change,
            changes: newChanges,
            // TODO: snapshotとかどうしようね…。
          }
        })
        .compact()
      setChanges(changesWithError)
      updateCachedView?.({ viewId: view.id, changes: changesWithError })

      if (successes.isPresent()) {
        void message.success(t(`{{length}}件のレコードが正常に保存されました。`, { length: successes.length }))
      }
      const errorSummaries = [t(`{{length}}件のレコードでエラーが発生しました。`, { length: errors.length })]
      const unknownCreateErrors = errors.filter((x) => x.status === 'unknown').filter((x) => x.change.type === 'create')
      if (unknownCreateErrors.length > 1) {
        errorSummaries.push(
          t(`複数の新規レコード作成中にエラーが発生しました。このまま保存すると重複して保存される可能性があります。`),
        )
      }

      // 正常に保存されたレコードとエラーが混在している場合、 refetch が走らないので正常に保存されたレコードの ID が古いままになってしまうので正常に保存されたレコードの ID を更新する
      // TODO: 正常保存レコードが見つからなかった時にエラーを出すかどうか決める
      if (Object.keys(successfullCreateRecordIdMap).length > 0 && errors.length > 0) {
        setRecords((oldRecords) => {
          const newRecords = []
          const alreadyReplacedRecordIds = new Set<string>()
          for (const oldRecord of oldRecords) {
            if (alreadyReplacedRecordIds.size === Object.keys(successfullCreateRecordIdMap).length) {
              break
            }
            newRecords.push(replaceRecordId(oldRecord, successfullCreateRecordIdMap, alreadyReplacedRecordIds, 0))
          }
          return [...newRecords, ...oldRecords.slice(newRecords.length)]
        })
      }

      setSheetInSaveErrors([...errors])
      updateCachedView?.({ viewId: view.id, sheetInSaveErrors: [...errors] })
      throw new Error([...errorSummaries, ...errors.map((e) => e.error)].join(`\n`))
    },
    addChange(changeHistory: ChangeHistory) {
      if (changeHistory.changes.length === 0) {
        return
      }

      setChanges((oldChanges) => {
        const newChanges = [...oldChanges, changeHistory]
        return newChanges
      })
      setBackedChanges([])
    },
    backHistory,
    forwardHistory,
    removeChanges(removeIds: string[]) {
      setChanges((changes) =>
        changes.map((change) => ({
          ...change,
          changes: change.changes.filter((x) => !removeIds.includes(x.id!)),
        })),
      )
    },
  }
}
