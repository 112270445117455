import { isNull, isSome } from '@salescore/buff-common'
import { addPeriodNameToCache, getPeriodsForDashboard } from '@salescore/client-common'
import { type Period, periodUtil } from '@salescore/features'
import { useMessage } from '@salescore/frontend-common'
import { DatePicker, Select } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect } from 'react'
import type { SetterOrUpdater } from 'recoil'

import { usePeriodSelector } from '../../../recoil/navigation/selector/PeriodSelector'
import { useChangesValue } from '../../../recoil/records/hooks'
import { useMeValue } from '../../../recoil/view/hooks'
import type { ChangeHistory } from '../../../state/useViewRecordsState/useChangesState'

export function PeriodPicker({ disabled }: { disabled?: boolean }) {
  const [period, setPeriod] = usePeriodSelector()
  const changes = useChangesValue()
  return <PeriodSelect disabled={disabled} period={period} setPeriod={setPeriod} changes={changes} />
}

export function PeriodSelect({
  disabled,
  stretch,
  period,
  setPeriod,
  changes,
}: {
  disabled?: boolean
  stretch?: boolean
  period: Period | undefined
  setPeriod: SetterOrUpdater<Period | undefined>
  changes: ChangeHistory[]
}) {
  const { organization } = useMeValue()
  const periods = getPeriodsForDashboard(organization.setting)
  const customPickerType = getCustomPickerType(period)
  const message = useMessage()

  const onChange = (selectedValue: string) => {
    const newPeriod = periods.find((x) => x.name === selectedValue)
    if (!newPeriod) {
      return
    }
    const newCustomPickerType = getCustomPickerType(newPeriod)
    switch (newCustomPickerType) {
      // カスタム、カスタム月を選んだ時は、単純にnewPeriodをセットするのではなく
      // startAt, endAtにもセットする必要がある。
      // ここでは、前のperiodの値をデフォルト値としてセットする
      case 'date': {
        setPeriod({
          ...newPeriod,
          startAt: dayjs(period?.startAt).startOf('day'),
          endAt: dayjs(period?.endAt).endOf('day'),
        })
        break
      }
      case 'month': {
        setPeriod({
          ...newPeriod,
          startAt: dayjs(period?.startAt).startOf('month'),
          endAt: dayjs(period?.endAt).endOf('month'),
        })
        break
      }
      case 'quarter': {
        break
      } // 未実装
      // カスタム系でなければ、セットするだけ
      default: {
        setPeriod(newPeriod)
      }
    }

    addPeriodNameToCache(newPeriod.name)
  }

  const groupNames = periods
    .map((x) => ({ groupName: x.groupName, groupNameLabel: x.groupNameLabel }))
    .uniqueBy((x) => x.groupName) // 非破壊操作になっているので、この順番で表示する

  // 無効化されているときは常に全期間にする
  useEffect(() => {
    if (disabled === true) {
      onChange(periodUtil.getAllPeriod().name)
    }
  }, [disabled])

  useEffect(() => {
    // 表示直後はperiodがセットされていない場合があるので、periodsの一番先頭の値で補完する
    if (isNull(period) && periods[0] !== undefined) {
      onChange(periods[0].name)
    }
  }, [period?.name, periods])

  // 初期の設計だとdisabledして表示しようとしていたが、やはり邪魔そうなので表示しない。
  // 表示されない時に、どこかで期間項目を設定していないから表示されない旨を説明する必要がある。
  if (disabled === true) {
    return <></>
  }

  return (
    <>
      <Select
        // suffixIcon={<ClockCircleOutlined />}
        // menuItemSelectedIcon={<ClockCircleOutlined />}
        disabled={disabled}
        defaultValue={period?.name}
        value={period?.name}
        style={{ width: 170, flex: stretch === true ? '1' : undefined }}
        onClick={() => {
          if (changes.length > 0) {
            message.warning(t('設定を変更すると変更情報が失われます。'))
          }
        }}
        onChange={onChange}
        // disabled={disabled}
      >
        {groupNames.map((groupName) => (
          <Select.OptGroup label={groupName.groupNameLabel} key={groupName.groupName}>
            {periods
              .filter((period) => period.groupName === groupName.groupName)
              .map((period) => (
                <Select.Option value={period.name} key={`${groupName.groupName ?? ''}-${period.name}`}>
                  <span data-e2e={`period-picker-${groupName.groupName ?? ''}-${period.name}`}>{period.nameLabel}</span>
                </Select.Option>
              ))}
          </Select.OptGroup>
        ))}
      </Select>
      {isSome(customPickerType) && (
        <DatePicker.RangePicker
          value={[dayjs(period?.startAt?.toISOString()), dayjs(period?.endAt?.toISOString())]}
          className="ml-2"
          picker={customPickerType}
          onClick={() => {
            if (changes.length > 0) {
              message.warning(t('設定を変更すると変更情報が失われます。'))
            }
          }}
          onChange={(rangeValue) => {
            if (!rangeValue) {
              return
            }
            let startAt = rangeValue[0]
            let endAt = rangeValue[1]
            if (!startAt || !endAt) {
              return
            }
            if (customPickerType === 'date') {
              startAt = startAt.startOf('day')
              endAt = endAt.endOf('day')
            } else {
              startAt = startAt.startOf('month')
              endAt = endAt.endOf('month')
            }
            setPeriod((oldValue) => ({
              ...oldValue,
              startAt,
              endAt,
            }))
          }}
        />
      )}
    </>
  )
}

function getCustomPickerType(period: Period | undefined) {
  switch (period?.name) {
    case 'カスタム': {
      return `date` as const
    }
    case 'カスタム(日)': {
      return `date` as const
    }
    case 'カスタム(月)': {
      return `month` as const
    }
    case 'カスタム(Q)': {
      return `quarter` as const
    }
    default:
  }
}
