import { getApolloContext } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import type { FetchMeQuery } from '@salescore/client-api'
import { notifyBugsnag, routes } from '@salescore/client-base'
import { useRedirect } from '@salescore/frontend-common'
import { Button, Result } from 'antd'
import Link from 'next/link'
import { createContext, useContext, useEffect } from 'react'
import JSONPretty from 'react-json-pretty'

import { CardContainer } from './CardContainer'

// eslint-disable-next-line @typescript-eslint/no-require-imports,@typescript-eslint/no-unsafe-assignment,unicorn/prefer-module
const Monokai = require('react-json-pretty/dist/monikai') // どうしても型エラーが修正できなかったのでrequireで解決
type MeUser = FetchMeQuery['myUser']

export const ErrorContext = createContext<{
  throwErrors: (_e: Error | Error[]) => void
  clearErrors: () => void
  myIdentity?: FetchMeQuery['myUser']['identity']
}>({
  throwErrors(_e: Error | Error[]) {
    // empty
  },
  clearErrors() {
    // empty
  },
  myIdentity: undefined,
})

// i18n: 認証前に実装される可能性があるため対応しない
const UnauthorizedError = ({ error, myUser }: { error: Error; myUser?: MeUser }) => {
  const redirect = useRedirect()
  const { clearErrors } = useContext(ErrorContext)
  const { client } = useContext(getApolloContext())

  useEffect(() => {
    // 古いキャッシュを読み込み続けてエラーになるケースがあるため、キャッシュクリアする
    void client?.resetStore()

    if (error.message.includes(`この組織へのアクセス権限がありません`)) {
      // ネットワークのエラーはこのレイヤで通知せずErrorLinkで通知（そもそも設計の変更でここにはたどり着かないはず）
      // notifyBugsnag({ error, user: myUser, severity: 'info' })
      redirect('/')
      clearErrors()
    }

    if (
      error.message === 'トークンの有効期限が切れています' ||
      error.message.includes(`認証に失敗しました。再度ログインをお試しください。`)
    ) {
      redirect('/sign_out')
      // void message.error(`認証の有効期限が切れました。再ログインしてください`) // 何度も表示されるようになり、解決できなかったのでコメントアウト
      clearErrors()
    }
    if (error.message.includes(`存在しないアカウントです`)) {
      // ネットワークのエラーはこのレイヤで通知せずErrorLinkで通知（そもそも設計の変更でここにはたどり着かないはず）
      // notifyBugsnag({ error, user: myUser, severity: 'info' })
      redirect('/sign_up')
      clearErrors()
    }
  }, [])

  return (
    <Result
      status="403"
      title="認証エラー"
      subTitle={error.message}
      extra={
        <div className="text-center">
          <Link href="/sign_out" legacyBehavior>
            <Button type="default" onClick={clearErrors}>
              ログイン画面へ
            </Button>
          </Link>
        </div>
      }
    />
  )
}

const NotFoundError = ({ error: _error, myUser }: { error: Error; myUser?: MeUser }) => {
  const { clearErrors } = useContext(ErrorContext)
  useEffect(() => {
    // ネットワークのエラーはこのレイヤで通知せずErrorLinkで通知（そもそも設計の変更でここにはたどり着かないはず）
    // notifyBugsnag({ error, user: myUser, severity: 'info' })
  }, [])

  return (
    <Result
      status="404"
      title="404"
      subTitle="このページは存在しません。"
      extra={
        <div className="text-center">
          <Link href="/" legacyBehavior>
            <Button type="primary" onClick={clearErrors}>
              ホーム画面へ
            </Button>
          </Link>
          <br />
          <br />
          <Link href="/sign_out" legacyBehavior>
            <Button type="default" onClick={clearErrors}>
              ログイン画面へ
            </Button>
          </Link>
        </div>
      }
    />
  )
}

const StandardError = ({ errors, myUser }: { errors: Error[]; myUser?: MeUser }) => {
  // TODO: clientを渡せておらず、使えない…
  // const [createActionLogMutation] = useCreateActionLogMutation()
  const { clearErrors } = useContext(ErrorContext)

  useEffect(() => {
    for (const e of errors) {
      notifyBugsnag({ error: e })
    }

    // try {
    //   createActionLogMutation({
    //     variables: {
    //       organizationId: getOrganizationIdFromPath(),
    //       actionLog: {
    //         action: "error",
    //         label: window ? window.location.pathname.replace(`/${getOrganizationIdFromPath()}`, "") : "",
    //         value: JSON.stringify(errors[0])
    //       }
    //     }
    //   })
    // } catch (_e) {}
  }, [])

  return (
    <Result
      title="500"
      subTitle={
        <p>
          申し訳ありません、エラーが発生しました。
          <br />
          画面を再読み込みしてください。
          <br />
          エラーが続く場合は、右下のチャット画面よりお問い合わせください。
        </p>
      }
      className="text-gray-300"
      extra={
        <div className="text-center">
          <Link href="/" legacyBehavior>
            <Button
              type="primary"
              onClick={() => {
                location.reload()
              }}
            >
              画面を再読み込み
            </Button>
          </Link>
          <br />
          <br />
          <Link href="/sign_out" legacyBehavior>
            <Button type="default" onClick={clearErrors}>
              ログイン画面へ
            </Button>
          </Link>
        </div>
      }
    />
  )
}

const WaitingVerification = () => (
  <div className="flex size-full flex-col items-center justify-center text-center align-middle text-gray-400">
    <div>
      メールアドレスの認証待ちです。
      <br />
      登録したメールアドレスに認証メールをお送りしましたので、ご確認ください。
    </div>
    <div className="mt-4">
      <Link href={routes.signOutPath()} legacyBehavior>
        <Button type="primary">違うアカウントでログインする</Button>
      </Link>
    </div>
  </div>
)

const DevelopmentError = ({ errors }: { errors: Error[] }) => (
  <div className="h-auto px-8 py-4" style={{ backgroundColor: '#272823' }}>
    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
    <JSONPretty id="json-pretty" data={errors} theme={Monokai} style={{ whiteSpace: 'pre-wrap' }} />
  </div>
)

export const Error = ({ errors, myUser }: { errors: Error[]; myUser?: MeUser }) => {
  const authorizationError = errors.find((x) => x.message.includes('Unauthorized') || x.message.includes('Forbidden'))
  const notFoundError = errors.find((x) => x.message.includes('Not Found') || x.message.includes('NotFound'))
  const waitingVerificationError = errors.find((x) => x.message.includes('メールアドレス認証が完了していません'))

  if (process.env.NODE_ENV === 'development' && isNull(authorizationError)) {
    return <DevelopmentError errors={errors} />
  }

  return (
    <CardContainer withLogo={false}>
      {waitingVerificationError ? (
        <WaitingVerification />
      ) : authorizationError ? (
        <UnauthorizedError error={authorizationError} myUser={myUser} />
      ) : notFoundError ? (
        <NotFoundError error={notFoundError} myUser={myUser} />
      ) : (
        <StandardError errors={errors} myUser={myUser} />
      )}
    </CardContainer>
  )
}

// 各所で適切なハンドリングをしたつもりだが、無駄に何度もレンダリングが走り、見た目がちらついたりする
// 下記も不要なはずだが、一応いれておく
// export const Error = React.memo(ErrorRaw, (prev, next) => {
//   return JSON.stringify(prev) === JSON.stringify(next)
// })
