import type { ViewQueryRecordNodeWithParent } from '../functions/util/recordNodeUtil'
import { compileExpression, type CoreDslParameter } from './compile'
import { flatAstNode } from './flat'
import { PegjsTracer } from './PegjsTracer'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import expressionParser from './syntax/expression'
import type { ExpressionNodeWithDeprecated } from './syntax/expression_ast'
import { toString } from './toString'

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CoreDsl {
  public static parse(x: string) {
    const tracer = new PegjsTracer(x) // tracerを使うには、--traceオプションをつけてpegjsを実行してパーサーを生成する必要がある
    try {
      const ast = expressionParser.parse(x, { tracer }) as ExpressionNodeWithDeprecated

      return {
        success: true as const,
        data: ast,
      }
    } catch (error) {
      return {
        success: false as const,
        error,
      }
    }
  }

  // deprecated. compileWithRecordNodeを使ってください。
  public static compile(exp: unknown, parameter?: CoreDslParameter) {
    try {
      const data = compileExpression(exp as ExpressionNodeWithDeprecated, parameter ?? {})
      return {
        success: true as const,
        data,
      }
    } catch (error) {
      return {
        success: false as const,
        error,
      }
    }
  }

  // expにはExpressionNodeWithDeprecatedを期待するが、
  // zodが循環参照を表現できないためexpをzodで書けず、unknownになっているのでunknownで受け取る。
  public static compileWithRecordNode(
    exp: unknown,
    record: ViewQueryRecordNodeWithParent,
    parameter?: CoreDslParameter,
  ) {
    return this.compile(exp as ExpressionNodeWithDeprecated, {
      ...record.attributes,
      ...record,
      ...parameter,
      // deprecated
      recordNode: record,
      parentRecordNode: record.parent,
    })
  }

  public static execute(exp: string, parameter?: CoreDslParameter) {
    const ast = this.parse(exp)
    if (!ast.success) {
      return ast
    }
    return {
      success: true as const,
      data: this.compile(ast.data, parameter),
    }
  }

  public static executeWithRecordNode(exp: string, record: ViewQueryRecordNodeWithParent) {
    const parameter = getRecordParameter(record)
    return this.execute(exp, parameter)
  }

  public static flat(ast: ExpressionNodeWithDeprecated) {
    return flatAstNode(ast)
  }

  public static stringify(ast: ExpressionNodeWithDeprecated | undefined): string {
    return toString(ast)
  }
}

function getRecordParameter(record: ViewQueryRecordNodeWithParent) {
  return {
    ...record.attributes,
    ...record,
    // deprecated
    recordNode: record,
    parentRecordNode: record.parent,
  }
}
