import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { VERSION } from '@salescore/buff-common'
import { logger } from '@salescore/frontend-common'

import { getAuth0UserFromLocalStorage } from './getAuth0UserFromLocalStorage'

const auth0User = getAuth0UserFromLocalStorage()
if (process.env.BUGSNAG_API_KEY !== undefined) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: VERSION,
    releaseStage: process.env.APP_ENV,
    plugins: [new BugsnagPluginReact()],
    user: {
      id: auth0User?.sub,
      name: auth0User?.name,
      email: auth0User?.email,
    },
  })
}

export const notifyBugsnag = ({
  error,
  severity,
  metadata,
}: {
  error: Error
  severity?: 'error' | 'warning' | 'info'
  metadata?: {
    key: string
    value: Record<string, string | number | undefined | Record<string, unknown>>
  }
}) => {
  const auth0User = getAuth0UserFromLocalStorage()
  if (process.env.BUGSNAG_API_KEY === undefined) {
    logger.debug(`[bugsnag] not notified.`, { error, severity, metadata, auth0User })
    return
  }

  Bugsnag.notify(error, function (event) {
    event.severity = severity ?? 'error'
    if (auth0User) {
      event.setUser(auth0User.sub, auth0User.email, auth0User.name)
      // event.addMetadata(`auth0`, auth0User) // 現状一緒なので不要
    }
    if (metadata !== undefined) {
      event.addMetadata(metadata.key, metadata.value)
    }
  })
}
