import { PeriodPicker } from '@salescore/client-common'
import type { Period } from '@salescore/features'
import { type ReactNode, useEffect } from 'react'
import type { SetterOrUpdater } from 'recoil'

import { usePeriodSelector } from '../../../../recoil/navigation/selector/PeriodSelector'

export interface PeriodFormInputProperties {
  period: Period | undefined
  setPeriod: SetterOrUpdater<Period | undefined>
}

export function PeriodFormInput(properties: PeriodFormInputProperties): ReactNode {
  const [, setPeriod] = usePeriodSelector()

  useEffect(() => {
    setPeriod(properties.period)
  }, [properties.period])

  return (
    <div className="flex">
      <PeriodPicker
        pickerType="kpiTimeSeriesKpiDateX"
        selectedPeriod={properties.period}
        setPeriod={properties.setPeriod}
        withInitialization={true}
        initialPeriodName="今年度"
        stretch
      />
    </div>
  )
}
