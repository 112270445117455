import type { PolicyAction } from '../action'
import { playingManagerRolePolicyDefinition } from './playingManagerRolePolicyDefinition'

// NEC様向け現場マネージャー：「レコードの新規作成・削除ができない現場マネージャー」のロール。
// XXX: 各種ロールは定義の簡素化のため上位ロールを継承している。
//      権限を変更したら下位ロールにも影響する可能性があるため、継承関係を意識して変更すること
export const necPlayingManagerRolePolicyDefinition: Record<PolicyAction, boolean> = {
  ...playingManagerRolePolicyDefinition,
  [`sheet-create-new-record`]: false,
  [`kpi-create-new-record`]: false,
  [`sheet-delete-record`]: false,
  [`kpi-delete-record`]: false,
  [`convert-lead`]: false,
  // 機能系
  [`manage-dimension-group`]: false,
  [`manage-users`]: false,
  [`manage-user-groups`]: false,
  [`manage-invitation`]: false,
  [`read-user-activity`]: false,
}
