import { simpleLogger } from '../misc/simpleLogger'

const createTableSql = /* sql */ `
CREATE TABLE "public"."salescore_user_groups" (
    "id" varchar(255) NOT NULL COLLATE "ja-x-icu",
    "name" text COLLATE "ja-x-icu",
    "rank" int NOT NULL DEFAULT(0),
    "depth" int NOT NULL DEFAULT(0),
    "image_url" text COLLATE "ja-x-icu",
    "user_group_id" varchar(255) COLLATE "ja-x-icu",
    "created_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    "updated_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    "deleted_at" timestamptz,
    PRIMARY KEY ("id")
);`

const ciDUserGroupIdSql = /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_user_groups__depth_user_group_id ON salescore_user_groups (depth,user_group_id);`
const ciRUserGroupIdSql = /* sql */ `CREATE INDEX IF NOT EXISTS r__salescore_user_groups__user_group_id ON salescore_user_groups (user_group_id);`

const afgUserGroupIdSetNullSql = /* sql */ `ALTER TABLE "salescore_users" ADD FOREIGN KEY ("user_group_id") REFERENCES "salescore_user_groups"("id") ON DELETE SET NULL ON UPDATE SET NULL;`
const afgUserGroupIdCascadeSql = /* sql */ `ALTER TABLE "salescore_user_groups" ADD FOREIGN KEY ("user_group_id") REFERENCES "salescore_user_groups"("id") ON DELETE CASCADE ON UPDATE CASCADE;`

export async function createUserGroupsTable(query: (sql: string) => Promise<void>) {
  await query(createTableSql)
  // 冪等にしたいが、インデックスが存在するとエラーになるので無視（丁寧にケアすれば可能だがやらない）
  const queryAndIgnoreError = async (sql: string) => {
    try {
      await query(sql)
    } catch (error) {
      simpleLogger.warn(error)
    }
  }
  await queryAndIgnoreError(ciDUserGroupIdSql)
  await queryAndIgnoreError(ciRUserGroupIdSql)

  await queryAndIgnoreError(afgUserGroupIdSetNullSql)
  await queryAndIgnoreError(afgUserGroupIdCascadeSql)
}
