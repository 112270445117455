import { range } from '@salescore/buff-common'
import type { ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'
import { t } from 'i18next'
import type { GetRecoilValue, SetRecoilState } from 'recoil'

import { feedbackMessagesAtom, recordIdsAtom, recordsAtom } from '../../../../recoil/records/atoms'
import { generateNewRootRecord } from '../../../../recoil/records/mutations/addNewRecord/addNewRootRecord'
import type { RSheetColumn, RSheetRecordNode } from '../../../types'
import { columnsAtom } from '../../atoms'
import { cursorSelector, type ExpandCursorArea } from '../../selectors/cursorSelector'

export interface PasteRelatedData {
  area: ExpandCursorArea
  columns: Array<RSheetColumn<RSheetRecordNode>>
  rows: ViewQueryRecordNode[]
  pseudoRootNodes: ViewQueryNode[]
}

export type PasteRelatedDataDest = PasteRelatedData & {
  editingRows: ViewQueryRecordNode[]
}

export function getRowsAndColumns(
  get: GetRecoilValue,
  set: SetRecoilState,
):
  | {
      source: PasteRelatedData
      dest: PasteRelatedDataDest
      recordRelated: {
        newRecordIds: string[]
        records: ViewQueryRecordNode[]
        recordIds: string[]
      }
    }
  | undefined {
  const x = getColumns(get, set)
  if (x === undefined) {
    return
  }
  const { sourceArea, destArea, sourceColumns, destColumns } = x

  const y = getRows({
    get,
    set,
    sourceArea,
    destArea,
    sourceColumns,
    destColumns,
  })
  if (y === undefined) {
    return
  }
  const {
    newRecordIds,
    records,
    recordIds,
    sourceRows,
    destRows,
    sourcePseudoRootNodes,
    destPseudoRootNodes,
    editingDestRows,
  } = y

  return {
    source: {
      area: sourceArea,
      columns: sourceColumns,
      rows: sourceRows,
      pseudoRootNodes: sourcePseudoRootNodes,
    },
    dest: {
      area: destArea,
      columns: destColumns,
      rows: destRows,
      editingRows: editingDestRows,
      pseudoRootNodes: destPseudoRootNodes,
    },
    recordRelated: {
      newRecordIds,
      records,
      recordIds,
    },
  }
}

function getRows({
  get,
  set,
  sourceArea,
  destArea,
  sourceColumns,
  destColumns,
}: {
  get: GetRecoilValue
  set: SetRecoilState
  sourceArea: ExpandCursorArea
  destArea: ExpandCursorArea
  sourceColumns: Array<RSheetColumn<ViewQueryRecordNode>>
  destColumns: Array<RSheetColumn<ViewQueryRecordNode>>
}) {
  //
  // 対象となる行の取得
  //
  const records = get(recordsAtom)
  const sourceRows = records.slice(sourceArea.upRowIndex, sourceArea.downRowIndex + 1)
  // ペーストの対象行は、destAreaそのものではなく、destAreaの左上を起点としてコピーした範囲に相当するエリア
  // ただし、コピー行が1行だったときのみ、単一行コピー複数行ペーストを行うため、元の行数とする
  // TODO: sourceとdestでnodeが異なるとき、この辺のロジックが微妙
  const destinationRows = structuredClone(
    records.slice(
      destArea.upRowIndex,
      sourceRows.length === 1 ? destArea.downRowIndex + 1 : destArea.upRowIndex + sourceRows.length,
    ),
  )
  // 不足している分の行数を追加する
  const additionalRows = range(0, sourceRows.length - destinationRows.length - 1).map((_) => generateNewRootRecord())
  destinationRows.push(...additionalRows)

  // 子ノードのペーストについては、行だと足りない場合があるため（TODO: ここのロジックが微妙）
  const editingDestinationRows = structuredClone(records.slice(destArea.upRowIndex))
  editingDestinationRows.push(...additionalRows) // TODO: ここで新規追加すべきではない。

  const recordIds = get(recordIdsAtom)
  const newRecordIds = [...recordIds, ...additionalRows.map((x) => x.id!)]

  // 擬似ルートノード(選択している範囲内のカラムのノードで一番浅いノード)を求める
  // eslint-disable-next-line unicorn/consistent-function-scoping
  const getPseudoRootNodes = (nodes: ViewQueryNode[]) => {
    const rootDepth = nodes.map((x) => x.path.length).min()
    return nodes.filter((node) => node.path.length === rootDepth).uniqueBy((node) => node.name)
  }
  const sourcePseudoRootNodes = getPseudoRootNodes(sourceColumns.map((column) => column.node))
  const destinationPseudoRootNodes = getPseudoRootNodes(destColumns.map((column) => column.node))

  // 擬似ルートが複数あるケースは、一旦禁止とする
  if (sourcePseudoRootNodes.length > 1 || destinationPseudoRootNodes.length > 1) {
    set(feedbackMessagesAtom, [
      {
        type: 'warn' as const,
        message: t(`複数の子ブロックにまたがるようなペーストはできません`),
      },
    ])
    return
  }

  return {
    newRecordIds,
    records,
    recordIds,
    sourceRows,
    destRows: destinationRows,
    sourcePseudoRootNodes,
    destPseudoRootNodes: destinationPseudoRootNodes,
    editingDestRows: editingDestinationRows,
  }
}

function getColumns(get: GetRecoilValue, set: SetRecoilState) {
  const { cursor, copiedArea, selectedArea } = get(cursorSelector)
  const columns = get(columnsAtom)
  if (copiedArea === undefined || selectedArea === undefined) {
    // TODO: コピーせずにペーストを実行した際の挙動はどうするか？
    return
  }

  // コピー対象となった列を求める
  // ついでに、書き込み禁止列のチェック
  const sourceColumns = columns.slice(copiedArea.area.leftColumnIndex, copiedArea.area.rightColumnIndex + 1)
  // ペーストの対象列は、destAreaそのものではなく、destAreaの左上を起点としてコピーした範囲に相当するエリア
  const destinationColumns = columns.slice(
    selectedArea.area.leftColumnIndex,
    selectedArea.area.leftColumnIndex + sourceColumns.length,
  )
  if (destinationColumns.some((x) => x.readonly === true && x.field?.write === undefined)) {
    set(feedbackMessagesAtom, [
      {
        type: 'warn' as const,
        message: t(`書き込み禁止列にはペーストできません`),
      },
    ])
  }

  return {
    sourceArea: copiedArea.area,
    destArea: selectedArea.area,
    sourceColumns,
    destColumns: destinationColumns,
  }
}
