import { CloseCircleOutlined, WarningOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { FetchSyncConnectionResultsDocument, type SourceProviderEnum } from '@salescore/client-api'
import { SourceProviderIcon } from '@salescore/client-common'
import { Button, Drawer, Flex, Table, type TableProps } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useState } from 'react'

import { EltModelSettingsLink, ReportSyncModel, type ReportSyncModelParameters } from './ReportSyncModel'

interface DataType {
  key: string
  checkSuccess: boolean
  checkError: string | undefined
  syncSuccess: boolean
  timestamp: Date
  jobQueueId: string
}

export interface ReportSyncConnectionParameters {
  connectionId: string
  organizationId: string
  name: string
  provider: SourceProviderEnum
}

const DrawerContent = ({ params: { connectionId, organizationId } }: { params: ReportSyncConnectionParameters }) => {
  const [reportSyncModelParameter, setReportSyncModelParameter] = useState<ReportSyncModelParameters | undefined>()
  const fetchResult = useQuery(FetchSyncConnectionResultsDocument, {
    variables: {
      organizationId,
      connectionId,
    },
  })
  const dataSource: DataType[] = (fetchResult.data?.syncConnectionResults ?? []).compact().map(
    (e, index): DataType => ({
      key: index.toString(),
      checkSuccess: e.checkSuccess,
      checkError: e.checkError ?? undefined,
      syncSuccess: e.syncSuccess,
      timestamp: dayjs(e.createdAt).toDate(),
      jobQueueId: e.jobQueueId,
    }),
  )
  const columns: TableProps<DataType>['columns'] = [
    {
      title: t('同期時刻'),
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (x: Date) => t(`{{datetime, datetimestr}}`, { datetime: x }),
    },
    {
      title: t('結果'),
      key: 'success',
      render: (_, record) => {
        if (!record.checkSuccess) {
          return (
            <Button
              type={'text'}
              icon={<CloseCircleOutlined />}
              danger={true}
              onClick={() => {
                setReportSyncModelParameter({
                  connectionId,
                  organizationId,
                  jobQueueId: record.jobQueueId,
                  checkSuccess: record.checkSuccess,
                  checkError: record.checkError,
                  timestamp: record.timestamp,
                })
              }}
            >
              {t('同期前エラー')}
            </Button>
          )
        }

        return record.syncSuccess ? (
          t('成功')
        ) : (
          <Button
            type={'text'}
            icon={<WarningOutlined />}
            danger={true}
            onClick={() => {
              setReportSyncModelParameter({
                connectionId,
                organizationId,
                jobQueueId: record.jobQueueId,
                checkSuccess: record.checkSuccess,
                checkError: record.checkError,
                timestamp: record.timestamp,
              })
            }}
          >
            {t('一部失敗')}
          </Button>
        )
      },
    },
  ]

  return (
    <div>
      <ReportSyncModel
        onClose={() => {
          setReportSyncModelParameter(undefined)
        }}
        params={reportSyncModelParameter}
      />

      <Table dataSource={dataSource} columns={columns} />
    </div>
  )
}

export const ReportSyncConnection = ({
  onClose,
  params,
}: {
  onClose: () => void
  params?: ReportSyncConnectionParameters
}) => (
  <Drawer
    title={
      <span>
        {params !== undefined && (
          <Flex justify={'space-between'}>
            <div>
              {t('同期履歴')}: <SourceProviderIcon provider={params.provider} />
              {params.name}
            </div>
            <EltModelSettingsLink connectionId={params.connectionId} />
          </Flex>
        )}
      </span>
    }
    width={600}
    onClose={onClose}
    closable={false}
    open={params !== undefined}
  >
    {params !== undefined && <DrawerContent params={params} />}
  </Drawer>
)
