import { EyeOutlined } from '@ant-design/icons'
import { useSuspenseQuery } from '@apollo/client'
import { isNull, isSome } from '@salescore/buff-common'
import { FetchJobQueuesDocument, FetchReverseEltsDocument, JobQueueStatusEnum } from '@salescore/client-api'
import { ExpandablePre, getOrganizationIdFromPath, JobQueueStatusTag } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { SqlSyntaxHighlighter } from '@salescore/frontend-common'
import { Button, Descriptions, Modal, Popover, Table } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { z } from 'zod'

import { JobQueueDetail } from './JobQueueDetail'

export function ReverseEltJobsTable() {
  // TODO: pollIntervalへの対応
  const { data: fetchJobQueuesData } = useSuspenseQuery(FetchJobQueuesDocument, {
    variables: { organizationId: getOrganizationIdFromPath(), jobName: `ReverseEltJob` },
  })
  const queues = fetchJobQueuesData.jobQueues
  const { data: fetchReverseEltsData } = useSuspenseQuery(FetchReverseEltsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })
  const reverseElts = fetchReverseEltsData.reverseElts
  const [current, setCurrent] = useState(dayjs())
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrent(dayjs())
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])
  const { reverseEltJobModal } = recoil.setting.useReverseEltRelated()

  return (
    <>
      <Table
        rowKey="id"
        dataSource={queues.map((q) => ({ ...q, key: q.id }))}
        size="small"
        columns={[
          {
            width: 80,
            dataIndex: 'id',
            title: 'ID',
            render(_, record) {
              return <Popover content={record.id}>{record.id.slice(0, 4)}</Popover>
            },
          },
          // {
          //   width: 80,
          //   dataIndex: 'workerId',
          //   title: 'ワーカー',
          //   render(_, record) {
          //     return <Popover content={record.jobWorkerId}>{record.jobWorkerId?.slice(0, 4)}</Popover>
          //   },
          // },
          // {
          //   width: 200,
          //   dataIndex: 'jobName',
          //   title: 'ジョブ名',
          //   filters: [
          //     {
          //       text: '同期',
          //       value: 'SyncSourceRecordsJob',
          //     },
          //   ],
          //   // フィルタ結果の処理
          //   onFilter: (value, record) => record.jobName === value,
          // },
          {
            dataIndex: 'jobArg',
            title: t(`引数`),
            render(_, record) {
              return <span>{JSON.stringify(record.jobArg)}</span>
            },
          },
          // {
          //   dataIndex: "processId",
          //   title: "プロセスID",
          // },
          {
            width: 100,
            dataIndex: 'status',
            title: t(`ステータス`),
            render(_, record) {
              return <JobQueueStatusTag status={record.status} />
            },
          },
          {
            width: 140,
            dataIndex: 'scheduledAt',
            title: t(`実行予定時刻`),
            render(_, queue) {
              const d = dayjs(queue.scheduledAt)
              return <span>{d.format(`MM/DD HH:mm:ss`)}</span>
            },
          },
          {
            dataIndex: 'message',
            title: t(`メッセージ`),
            render(_, record) {
              return (
                <ExpandablePre
                  text={record.message ?? ''}
                  ellipsisChars={50}
                  className={record.status === JobQueueStatusEnum.Error ? 'text-red-400' : ''}
                />
              )
            },
          },
          // {
          //   dataIndex: "updatedAt",
          //   title: "ID",
          // },
          {
            width: 100,
            dataIndex: 'duration',
            title: t(`実行時間`),
            render(_, queue) {
              if (isNull(queue.startedAt)) {
                return <></>
              }
              const started = dayjs(queue.startedAt)
              const target = isSome(queue.stoppedAt) ? dayjs(queue.stoppedAt) : current
              return <span>{durationJaBySec(target.diff(started, 'seconds'))}</span>
            },
          },
          {
            key: 'detail',
            width: 40,
            render(_, queue) {
              return (
                <Button
                  icon={<EyeOutlined />}
                  onClick={() => {
                    reverseEltJobModal.showModal({ jobId: queue.id })
                  }}
                />
              )
            },
          },
          // {
          //   key: 'kill',
          //   width: 40,
          //   render(_, queue) {
          //     return <KillQueueButton id={queue.id} status={queue.status} />
          //   },
          // },
        ]}
      />
      <Modal
        open={reverseEltJobModal.isModalVisible}
        onCancel={reverseEltJobModal.hideModal}
        width={'90%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        style={{ top: '3%' }}
        destroyOnClose
      >
        {reverseEltJobModal.isModalVisible && reverseEltJobModal.content !== undefined && (
          <JobQueueDetail
            queueId={reverseEltJobModal.content.jobId}
            detail={(queue) => {
              const data = z.object({ reverseEltId: z.string() }).safeParse(queue.jobArg)
              if (!data.success) {
                return <></>
              }
              const reverseElt = reverseElts.find((x) => x.id === data.data.reverseEltId)
              if (reverseElt === undefined) {
                return <></>
              }
              return (
                <Descriptions title={t(`設定詳細`)} column={3}>
                  <Descriptions.Item label={t(`設定名`)}>{reverseElt.name}</Descriptions.Item>
                  <Descriptions.Item span={1} label={t(`データベース`)}>
                    {reverseElt.destination.provider}
                  </Descriptions.Item>
                  <Descriptions.Item span={1} label={t(`更新先`)}>
                    {reverseElt.eltModel.model.label}({reverseElt.eltModel.name})
                  </Descriptions.Item>
                  <Descriptions.Item span={4} label="SQL">
                    <SqlSyntaxHighlighter sql={reverseElt.sql} language="sql" />
                  </Descriptions.Item>
                </Descriptions>
              )
            }}
          />
        )}
      </Modal>
    </>
  )
}

function durationJaBySec(sec: number) {
  if (sec < 0) {
    return ``
  }
  if (sec < 60) {
    return t(`{{sec}}秒`, { sec })
  }
  const min = Math.floor(sec / 60)
  if (min < 60) {
    return t(`{{min}}分`, { min })
  }
  const hour = Math.floor(min / 60)
  return t(`{{hour}}時間{{min}}分`, { hour, min: min % 60 })
}
