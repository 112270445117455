import { WarningOutlined } from '@ant-design/icons'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { Badge, Button } from 'antd'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { aggregationErrorsAtom } from '../../../recoil/records/atoms'
import { useErrorsState } from '../../../recoil/records/hooks'
import { compiledConfigSelector } from '../../../recoil/view/atoms'
import { useMeValue } from '../../../recoil/view/hooks'

export function CompileErrorsButton({ onClick }: { onClick: () => void }) {
  const [queryErrors] = useErrorsState()
  const { logs } = useRecoilValue(compiledConfigSelector)
  const aggregationErrors = useRecoilValue(aggregationErrorsAtom)
  const errors = [...(logs?.showErrors() ?? []), ...queryErrors, ...aggregationErrors] // ここを変更したらCompileLogsModal側も変更すること
  const warns = logs?.showWarns() ?? []
  const me = useMeValue()

  useEffect(() => {
    errors.map((error) => {
      Posthog.track(POSTHOG_EVENTS.view_compile_header_error, { message: error })
    })
    warns.map((warn) => {
      Posthog.track(POSTHOG_EVENTS.view_compile_header_warning, { message: warn })
    })
  }, [])

  if (errors.isPresent()) {
    return (
      <Badge count={errors.length} overflowCount={999} offset={[-3, 3]}>
        <Button type="default" onClick={onClick} danger icon={<WarningOutlined />} />
      </Badge>
    )
  }

  if (warns.isPresent() && me.isAdmin) {
    // ユーザー向けのわかりやすい警告表示にできていないので、一旦表示しないという話になった
    return (
      <Badge count={warns.length} overflowCount={999} offset={[-3, 3]} color="yellow">
        <Button
          type="default"
          onClick={onClick}
          icon={<WarningOutlined />}
          className="border-yellow-400 text-yellow-500"
        />
      </Badge>
    )
  }

  return <></>
}
