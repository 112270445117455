import { TimePicker } from 'antd'
import dayjs, { type Dayjs } from 'dayjs'
import { useRef, useState } from 'react'
import { z } from 'zod'

import { useUpsertSheetRowMutation } from '../../../../recoil/mutations/upsertSheetRowMutation'
import type { RSheetsCellInputRenderTypeArgument } from '../rSheetsCellInputRenderTypeArgument'
import { RSheetsInputCellWrapper } from './InputCellWrapper'

export function RSheetsCellTimeInput({
  recordNode,
  column,
  rowIndex,
  innerRowIndex,
  defaultWidth,
  finishEditMode,
  boxStyle,
}: RSheetsCellInputRenderTypeArgument) {
  const columnOnChange = useUpsertSheetRowMutation()
  const [value, setValue] = useState<Dayjs | null>(() => {
    const initialValue = recordNode === undefined ? undefined : column.value(recordNode)
    const parsed = z.string().safeParse(initialValue)
    const text = parsed.success ? parsed.data : '00:00:00'
    // TODO: データベース上はtime with timezoneでタイムゾーンを扱っているが、salesforceのレスポンスにタイムゾーンが含まれない。
    //       この辺を適切に対策したいが後回しにしているため、フロントで整合性をとる。（他の連携先で、タイムゾーンが含まれるような値が含まれると厳しくなる）
    const m = dayjs(`2000-01-01T${text.replace('+00', '')}`)
    if (m.isValid()) {
      return m
    }
    return dayjs().add(1, 'hour').set('minute', 0)
  })
  const reference = useRef<HTMLInputElement>(null)

  setTimeout(() => {
    if (reference.current) {
      reference.current.focus()
    }
  }, 200)

  return RSheetsInputCellWrapper(
    {
      async onChangeFixed() {
        await columnOnChange({ value: value?.format('HH:mm'), rowIndex, innerRowIndex, column })
      },
      finishEditMode,
    },
    ({ handleKeyDown }) => {
      const format = 'HH:mm'

      return (
        <div
          style={{
            ...boxStyle,
            padding: '3px 1px',
            marginTop: 1, // これがないと1pxずれるが、Cell側と比べてなぜ1px分の差があるのか不明
            backgroundColor: 'white',
          }}
        >
          <TimePicker
            minuteStep={5}
            format={format}
            bordered={false}
            autoFocus={true}
            value={value}
            open={true}
            onChange={async (value) => {
              await columnOnChange({ value: value?.format('HH:mm'), rowIndex, innerRowIndex, column })
              finishEditMode()
            }}
          />
        </div>
      )
    },
  )
}
