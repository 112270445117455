import { DownOutlined } from '@ant-design/icons'
import { Button, Form, Space } from 'antd'

export function CoreDslFormDropdownButton({
  value,
  label,
  prefix,
}: {
  value?: string
  label: string
  prefix?: JSX.Element
}) {
  return (
    <Form.Item label={label}>
      <Button className="w-full">
        <div className="flex justify-between">
          <Space>
            {prefix}
            {value}
          </Space>
          <DownOutlined
            style={{
              marginLeft: 8,
              color: '#CFCFCF',
            }}
          />
        </div>
      </Button>
    </Form.Item>
  )
}
