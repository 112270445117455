import { useMutation, useQuery } from '@apollo/client'
import {
  FetchGoalConfigsDocument,
  InitializeGoalsDocument,
  type OrganizationSettingFieldsFragment,
  type UserGroupFieldsFragment,
  type ViewFieldsFragment,
  type ViewUpdateInputSingle,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { recoil } from '@salescore/client-recoil'
import { CORE_CONSTANT, type ViewConfigDimension } from '@salescore/core'
import { generateDateStringsForInitialize, generatePivotedGoalModel, type Period } from '@salescore/features'
import { message, Table } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import { type ReactNode, useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import { viewAtom } from '../../../../recoil/view/atoms'
import { useViewsContextValue } from '../../../../recoil/view/hooks'
import { setConfigWithoutChangeMutation } from '../../../../recoil/view/mutations/setConfigMutation'
import { ViewComponent } from '../../../ViewComponent'
import { useKpiTimeSeriesConfig } from '../hooks'
import type { TimeSpan } from './TimeSpanFormInput'

export function ViewUiRiGoalSetting(properties: {
  period: Period | undefined
  timeSpan: TimeSpan | undefined
  breakdownProperty: ViewConfigDimension | undefined
  loading: boolean
  hasChanged: boolean
  updateKpiTimeSeriesView: (shouldUseExistingGoalConfigFragment?: boolean) => Promise<void>
}): ReactNode {
  const config = useKpiTimeSeriesConfig()

  const [initializeGoalsMutation, { loading: isGoalsUpdating }] = useMutation(InitializeGoalsDocument)

  const [isInitialized, setIsInitialized] = useState(false)
  const me = recoil.global.useMe()

  useEffect(() => {
    async function initGoals(): Promise<void> {
      if (isInitialized || properties.loading || isGoalsUpdating) {
        return
      }

      // NOTE: 差分があれば更新する. initializeGoalsMutation で最新の kpiFragment, goalConfigFragment を参照したいため
      if (properties.hasChanged) {
        await properties.updateKpiTimeSeriesView(false)
      }

      if (config.goalConfigFragment === undefined) {
        // NOTE: 新規作成の場合にあり得る
        return
      }

      if (
        properties.period?.startAt === undefined ||
        properties.period.endAt === undefined ||
        properties.timeSpan === undefined
      ) {
        // NOTE: あり得ないはず
        throw new Error(
          `props.period.startAt,endAt or props.timeSpan is undefined. ${JSON.stringify({
            period: properties.period,
            timeSpan: properties.timeSpan,
          })}`,
        )
      }

      await initializeGoalsMutation({
        variables: {
          goalConfigId: config.goalConfigFragment.id,
          organizationId: getOrganizationIdFromPath(),
          dates: generateDateStringsForInitialize(
            properties.period.startAt,
            properties.period.endAt,
            properties.timeSpan,
          ),
        },
      })

      setIsInitialized(true)
    }

    void initGoals()
  }, [config.goalConfigFragment, properties.period, properties.timeSpan])

  const { data } = useQuery(FetchGoalConfigsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })

  return (
    <div className="flex h-full flex-col py-4">
      <div className="text-xl font-bold">{t(`目標設定`)}</div>

      {isInitialized && data !== undefined ? (
        <div className="relative flex-1">
          <div className="absolute inset-0">
            <div className="size-full">
              <GoalsSheet userGroups={data.userGroups} organizationSetting={me.organization.setting} />
            </div>
          </div>
        </div>
      ) : (
        <Table loading />
      )}
    </div>
  )
}

interface GoalsSheetProperties {
  userGroups: UserGroupFieldsFragment[]
  organizationSetting: OrganizationSettingFieldsFragment
}

function GoalsSheet(properties: GoalsSheetProperties): ReactNode {
  const config = useKpiTimeSeriesConfig()
  const [view, setView] = useRecoilState(viewAtom)
  const setConfigWithoutChange = useSetRecoilState(setConfigWithoutChangeMutation)
  const { updateView } = useViewsContextValue()

  if (
    config.goalConfigFragment === undefined ||
    config.period?.startAt === undefined ||
    config.period.endAt === undefined
  ) {
    return null
  }

  const { pivotedGoalModel, goalViewConfig } = generatePivotedGoalModel({
    goalConfig: {
      ...config.goalConfigFragment,
      userIds: config.goalConfigFragment.userIdsConfig,
      userGroupIds: config.goalConfigFragment.userGroupIdsConfig,
    },
    userGroups: properties.userGroups,
    users: properties.userGroups.flatMap((x) => x.users),
    userGroupCategoryNames: properties.organizationSetting.userGroupCategoryNames,
    startAt: dayjs(config.period.startAt),
    endAt: dayjs(config.period.endAt),
  })

  const goalView: ViewFieldsFragment = {
    id: ``,
    type: `sheet`,
    config: goalViewConfig,
    queries: [],
    ui: [],
    viewGroupId: ``,
    name: ``,
    rank: 1,
    defaultPermission: `none`,
    roleRecordPermissions: [],
    private: false,
    archived: false,
    archivedAt: null,
  }

  async function onUpdateView(v: ViewUpdateInputSingle): Promise<undefined> {
    try {
      if (config.goalConfigFragment === undefined) {
        return
      }
      const updatedView = await updateView({
        id: view.id,
        config: {
          ...config,
          goalConfigFragment: {
            ...config.goalConfigFragment,
            config: v.config,
          },
        },
      })
      if (updatedView !== undefined) {
        setView(updatedView)
        setConfigWithoutChange(updatedView.config)
      }
    } catch (error) {
      void message.error(error instanceof Error ? error.message : t(`エラーが発生しました。`))
    }
  }

  return (
    <ViewComponent
      view={goalView}
      parameter={{}}
      viewsContext={{
        models: [pivotedGoalModel, CORE_CONSTANT.SALESCORE_USER_MODEL],
        views: [goalView],
        viewGroups: [],
        viewsForSider: [],
        createView: async (): Promise<undefined> => {
          // empty
        },
        updateView: onUpdateView,
        deleteView: async (): Promise<undefined> => {
          // empty
        },
        pickView: async () => {
          // empty
        },
        refetchViews: async () => {
          // empty
        },
        onSaveView: async () => {
          // empty
        },
        onAddResource: async () => {
          // empty
        },
        onChangesChange: () => {
          // empty
        },
        hasChanges: false,
        setIsSaveConfigButtonActive: () => {
          // empty
        },
        isSaveConfigButtonActive: false,
        isSheetMoreActionsCopyVisible: false,
      }}
    />
  )
}
