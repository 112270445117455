import { r } from '@salescore/buff-common'
import type { ModelSearcher, ViewQueryList, ViewQueryRecordNode } from '@salescore/core'
import { logger } from '@salescore/frontend-common'
import { t } from 'i18next'
import type { SetRecoilState } from 'recoil'

import type { ViewRecordFieldChange } from '../../../../state/useViewRecordsState/useChangesState'
import { getLabeledFieldName } from '../../../../state/util'
import { feedbackMessagesAtom, recordAtomFamily, recordsAtom } from '../../atoms'
import { isNewRecord, type RecordChange } from '../upsertViewRecords'
import { generateDeleteChanges } from './generateDeleteChanges'

export function upsertRootViewRecord(
  set: SetRecoilState,
  { rowIndex, innerRowIndex, field, value, label, isDelete, isDeleteAllChildren }: RecordChange,
  query: ViewQueryList,
  newRootRecordNode: ViewQueryRecordNode,
  records: ViewQueryRecordNode[],
  modelSearcher: ModelSearcher,
): ViewRecordFieldChange[] {
  // 削除系
  if (isDelete === true) {
    const deletingRecordIndex = records.findIndex((record) => record.id === newRootRecordNode.id)
    records.splice(deletingRecordIndex, 1)
    set(recordsAtom, records)
    return generateDeleteChanges(query, query.tree, newRootRecordNode, modelSearcher, isDeleteAllChildren)
  }

  if (field.write === undefined) {
    logger.debug('field.write not found')
    return []
  }

  const oldValue = newRootRecordNode.attributes[field.name]
  if (oldValue === value) {
    return []
  }
  if (field.meta.creatable === false && isNewRecord(newRootRecordNode)) {
    set(feedbackMessagesAtom, [
      {
        message: t(`{{label}}は作成禁止です`, { label: field.meta.label }),
        type: 'warn' as const,
      },
    ])
    return []
  }
  if (field.meta.updatable === false && !isNewRecord(newRootRecordNode)) {
    set(feedbackMessagesAtom, [
      {
        message: t(`{{label}}は更新禁止です`, { label: field.meta.label }),
        type: 'warn' as const,
      },
    ])
    return []
  }

  // レコードを更新
  newRootRecordNode.attributes[field.name] = value as string
  if (label !== undefined) {
    newRootRecordNode.attributes[getLabeledFieldName(field.name)] = label
  }
  set(recordAtomFamily({ id: newRootRecordNode.id! }), r(newRootRecordNode).clone().data) // 複製して渡さないとfreezeされてしまい、後続の関数で編集できなくなる

  if (newRootRecordNode.id === undefined) {
    return []
  }
  const nameField = query.fields
    .filter((x) => x.nodePath.isEqual(query.tree.path))
    .find((field) => field.meta.fieldMetaType === 'name')
  return [
    {
      id: newRootRecordNode.id,
      streamName: field.write.streamName,
      fieldChanges: [
        {
          id: newRootRecordNode.id,
          fieldName: field.name,
          propertySourceName: field.write.propertySourceName,
          value,
          valueLabel: label,
          rowIndex,
          innerRowIndex,
        },
      ],
      recordNameLabel: nameField === undefined ? undefined : (newRootRecordNode.attributes[nameField.name] as string),
      before: {
        [field.write.propertySourceName]: oldValue,
      },
      after: {
        [field.write.propertySourceName]: value,
      },
    },
  ]
}
