import type { CoreView } from '../../../schemas/View'
import type { SheetConfigOrReference, ViewConfig } from '../../../schemas/view_config'

type Mapper = Record<string, CoreView>

export class ViewSearcher {
  // private readonly views: CoreView[]
  private readonly mapper: Mapper
  public readonly views: CoreView[]

  public constructor(views: CoreView[]) {
    this.views = views
    this.mapper = views
      .groupBy((x) => x.id)
      .transformValues(
        (vs) => vs.first()!, // 必ず1つのみ存在する
      ).data
  }

  public searchView(viewId: string): CoreView | undefined {
    return this.mapper[viewId]
  }

  public searchConfigIfReference(maybeReference: SheetConfigOrReference): ViewConfig | undefined {
    switch (maybeReference.type) {
      case 'reference': {
        return this.searchView(maybeReference.viewId)?.config
      }
      case 'sheet': {
        return maybeReference
      }
    }
  }
}
