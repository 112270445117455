import type { ViewConfigTypeEnum } from '@salescore/client-api'
import { mutation, organizationHasFeature } from '@salescore/frontend-common'

import { meAtom } from '../../../recoil/view/atoms'
import { openSheetThreadsAtom } from '../atoms'

export const openSheetThreadMutation = mutation({
  key: `rsheet/openSheetThreadMutation`,
  set(
    { get, set },
    {
      threadId,
      draftComment,
      view,
    }: {
      threadId: string
      draftComment?: string
      view: { id?: string; createdById?: string | null; type: `${ViewConfigTypeEnum}` }
    },
  ) {
    const me = get(meAtom)
    const openSheetThreads = get(openSheetThreadsAtom)
    const commentFeatureEnabled = organizationHasFeature(me.organization, 'enable_slack_comment')
    if (commentFeatureEnabled && view.type === 'sheet') {
      const openSheetThreadsWithoutThisThread = openSheetThreads.filter((t) => t.id !== threadId)
      set(openSheetThreadsAtom, [
        ...openSheetThreadsWithoutThisThread,
        { id: threadId, draftComment: draftComment ?? '' },
      ])
    }
  },
})

export const closeSheetThreadMutation = mutation({
  key: `rsheet/closeSheetThreadMutation`,
  set({ get, set }, threadId: string) {
    const openSheetThreads = get(openSheetThreadsAtom)
    const openSheetThreadsWithoutThisThread = openSheetThreads.filter((t) => t.id !== threadId)
    set(openSheetThreadsAtom, openSheetThreadsWithoutThisThread)
  },
})
