import { PlusOutlined } from '@ant-design/icons'
import { DelayWrapper } from '@salescore/client-common'
import type { ViewUISheet } from '@salescore/core'
import { useModal } from '@salescore/frontend-common'
import { Button, Empty, Modal } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { CLIENT_VIEW_CONSTANT } from '../../constants/constants'
import { useSheetThreads } from '../../recoil/records/mutations/useSheetThreads'
import { useSheetColumns, useSheetVisibleColumns } from '../../recoil/selectors/useSheetColumns'
import { useSheetSystemColumns } from '../../recoil/selectors/useSheetSystemColumns'
import { loadingProgressAtom, sharedUrlAtom } from '../../recoil/view/atoms'
import { useViewValue } from '../../recoil/view/hooks'
import { useConfigSheetSelector } from '../../recoil/view/selectors/configSheetSelector'
import { RSheet } from '../../rsheet/components/RSheet'
import { openSheetThreadsAtom, openSheetThreadsFormAtom } from '../../rsheet/recoil/atoms'
import { ViewLoading } from '../ViewLoading'
import { detectSharedUrlSettings } from './common'
import { PropertySelectorTableForContextMenu } from './ViewUISheet/PropertySelectorTableForContextMenu'

// TODO: dataでメモ化が必要
export const ViewUISheetC = ({ component }: { component: ViewUISheet }) => {
  const view = useViewValue()
  const { config } = useConfigSheetSelector()
  const sharedUrl = useRecoilValue(sharedUrlAtom)
  const setLoadingProgress = useSetRecoilState(loadingProgressAtom)

  const systemColumns = useSheetSystemColumns()
  const fieldColumns = useSheetColumns()
  const visibleFieldColumns = useSheetVisibleColumns()
  const columns = useMemo(
    () =>
      [...systemColumns, ...visibleFieldColumns].map((x, index) => ({
        ...x,
        index,
      })),
    [visibleFieldColumns, systemColumns],
  )
  const isEmptySheet = component.columns.isBlank() || fieldColumns.isBlank() || visibleFieldColumns.isBlank()
  const { sheetThreads } = useSheetThreads()
  const setOpenSheetThreads = useSetRecoilState(openSheetThreadsAtom)
  const setOpenSheetThreadsForm = useSetRecoilState(openSheetThreadsFormAtom)

  const initializeModal = useModal()

  const isSharedUrlSettings = detectSharedUrlSettings(config, sharedUrl)
  useEffect(() => {
    if (isSharedUrlSettings && sharedUrl !== undefined) {
      const sheetThread = sheetThreads.find(
        (sheetThread) =>
          sheetThread.sharedUrl?.id === sharedUrl.id ||
          sheetThread.comments.some((comment) => comment?.sharedUrl?.id === sharedUrl.id),
      )
      if (sheetThread === undefined) {
        return
      }
      setOpenSheetThreads((openSheetThreads) => [
        { id: sheetThread.id, draftComment: '' },
        ...openSheetThreads.filter((t) => t.id !== sheetThread.id),
      ])
      setOpenSheetThreadsForm((openSheetThreadsForm) => [
        { recordId: sheetThread.recordId },
        ...openSheetThreadsForm.filter((t) => t.recordId !== sheetThread.recordId),
      ])
    }
  }, [isSharedUrlSettings, sheetThreads])

  const context = useMemo(
    () => ({
      fixedLeftColumnIndex: component.fixedLeftColumnIndex,
      isGoalView: component.isGoalView,
    }),
    [component.fixedLeftColumnIndex, component.isGoalView],
  )

  useEffect(() => {
    if (isEmptySheet) {
      setLoadingProgress((progress) => ({
        ...progress,
        completed: true,
      }))
    }
  }, [isEmptySheet])

  return (
    <div className="view-ui-sheet h-full">
      {isEmptySheet ? (
        <DelayWrapper
          delay={CLIENT_VIEW_CONSTANT.RENDER_DELAY_AFTER_LOAD}
          fallback={<ViewLoading context={{ view }} />}
        >
          {/* ローディング画面で読み込みが完了したことを通知してからViewを表示するために、描画タイミングを読み込み完了からごく短時間遅らせている */}
          <EmptySheet component={component} showModal={initializeModal.showModal} />
        </DelayWrapper>
      ) : (
        <RSheet
          height="100%" // TODO
          columns={columns}
          context={context}
        />
      )}
      <Modal
        open={initializeModal.isModalVisible}
        onCancel={initializeModal.hideModal}
        width={'60%'}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
        destroyOnClose
      >
        <PropertySelectorTableForContextMenu
          initialNodePath={[]}
          configIndex={-1} // configIndex の先頭は -1 で表現することになっている
        />
      </Modal>
    </div>
  )
}

function EmptySheet({ component, showModal }: { component: ViewUISheet; showModal: (content?: unknown) => void }) {
  const fieldColumns = useSheetColumns()
  const visibleFieldColumns = useSheetVisibleColumns()

  if (component.columns.isBlank()) {
    return (
      <div className="pt-8">
        <Empty
          description={
            <div>
              <p>{t(`カラムが設定されていません。`)}</p>
              <br />
              <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
                {t(`カラムを追加`)}
              </Button>
            </div>
          }
        />
      </div>
    )
  }

  if (fieldColumns.isBlank()) {
    return (
      <div className="pt-8">
        <Empty
          description={
            <div>
              <p>{t(`カラムが設定されていない、もしくは全てのフィールドが連携先から削除されました。`)}</p>
              <br />
              <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
                {t(`カラムを追加`)}
              </Button>
            </div>
          }
        />
      </div>
    )
  }

  if (visibleFieldColumns.isBlank()) {
    return (
      <div className="pt-8">
        <Empty
          description={
            <div>
              <p>{t(`全てのカラムが非表示になっています。`)}</p>
            </div>
          }
        />
      </div>
    )
  }

  return <></>
}
