import { EyeOutlined, UserOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { isNull, isSome } from '@salescore/buff-common'
import type { CreateViewInput, ViewForSiderFieldsFragment, ViewGroupFieldsFragment } from '@salescore/client-api'
import { POSTHOG_EVENTS } from '@salescore/client-base'
import { AdminCard, EntityPolicyForm, ErrorContext } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import type { ViewConfig } from '@salescore/core'
import { SyntaxHighlightedJsonModal, useModal } from '@salescore/frontend-common'
import { Alert, Button, Col, Form, Input, Row, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useContext, useMemo, useState } from 'react'

import { usePosthogTrackView } from '../../recoil/usePosthogTrack'
import { EmojiPickerFormItem } from '../EmojiPicker'
import { ViewGroupPicker } from './ViewGroupPicker'

interface FormValue {
  name: string
  viewGroupId: string | null
  userGroupIds?: string[]
  kpiIds?: string[]
}

interface ViewGroupWithParents {
  viewGroup: ViewGroupFieldsFragment
  parents: ViewGroupFieldsFragment[]
}

// viewからもmainからも呼ばれるため、recoilを使わないようにすること
// useCanのみ例外的に使用している
export function ViewForm(argument: {
  id?: string
  viewGroupId?: string
  views: Array<Omit<ViewForSiderFieldsFragment, '__typename'>>
  viewGroupsWithParents: ViewGroupWithParents[]
  type: ViewConfig['type'] | undefined
  isPrivate: boolean
  onAfterFinish: (view?: ViewForSiderFieldsFragment) => void
  createView: (x: CreateViewInput) => Promise<Omit<ViewForSiderFieldsFragment, '__typename'> | undefined>
  updateView: (
    x: {
      id: string
      name: string
      emoji?: string | null
      viewGroupId: string | null | undefined
    },
    type?: ViewForSiderFieldsFragment['type'],
  ) => Promise<void>
}) {
  const { id, viewGroupId, type, views, viewGroupsWithParents, onAfterFinish, createView, updateView } = argument
  const { editingView, viewGroup } = useMemo(() => {
    const editingView = views.find((x) => x.id === id)
    return {
      editingView,
      viewGroup: viewGroupsWithParents.find(
        (x) => x.viewGroup.id === (isSome(editingView) ? editingView.viewGroupId : viewGroupId),
      ),
    }
  }, [id, viewGroupId])
  const isPrivate = editingView?.private ?? argument.isPrivate
  const updatable = isNull(editingView) ? true : recoil.global.useCanForView('update', editingView) // XXX: 分岐によりhooksの呼び出し回数が異なる
  const [emoji, setEmoji] = useState<string | null>(editingView?.emoji ?? null)
  const modal = useModal()
  const [loading, setLoading] = useState(false)
  const { throwErrors } = useContext(ErrorContext)
  const [form] = useForm<FormValue>()
  const editingType = editingView?.type ?? type // TODO: CreateViewForm, UpdateViewFormに分割した方が見通しが良い。。
  const posthogTrackView = usePosthogTrackView()

  const onFinish = async () => {
    if (loading) {
      return
    }

    const values: FormValue = form.getFieldsValue()
    try {
      setLoading(true)
      if (editingView === undefined) {
        const config = generateEmptyConfig(editingType ?? 'sheet')
        const createdView = await createView({
          name: values.name,
          viewGroupId: values.viewGroupId,
          config,
          emoji,
          private: viewGroup?.viewGroup.private ?? isPrivate,
        })
        onAfterFinish(createdView ?? undefined)
        if (createdView === undefined) {
          return
        }
        switch (config.type) {
          case 'sheet': {
            posthogTrackView(POSTHOG_EVENTS.create_view_sheet, {
              viewId: createdView.id,
            })
            break
          }
          case 'kpi': {
            posthogTrackView(POSTHOG_EVENTS.create_view_kpi, {
              viewId: createdView.id,
            })
            break
          }
          case 'kpiPivot': {
            posthogTrackView(POSTHOG_EVENTS.create_view_dashboard, {
              viewId: createdView.id,
            })
          }
        }
      } else {
        await updateView(
          {
            id: editingView.id,
            name: values.name,
            emoji,
            viewGroupId: editingView.viewGroupId === values.viewGroupId ? undefined : (values.viewGroupId ?? null),
          },
          editingView.type,
        )
        onAfterFinish()
      }
    } catch (error) {
      throwErrors(error as Error)
    } finally {
      setLoading(false)
    }
  }

  const initialValue: FormValue = {
    name: editingView?.name ?? '',
    viewGroupId: viewGroup?.viewGroup.id ?? null,
  }

  const typeJa =
    editingType === 'kpiPivot'
      ? t(`ダッシュボード`)
      : editingType === 'kpi'
        ? 'KPI'
        : editingType === 'kpiTimeSeries'
          ? t(`案件管理`)
          : t(`シート`)
  const isEditing = isSome(editingView)
  const dataE2e = `view-form-${isEditing ? 'edit' : 'create'}-${editingType}`

  return (
    <PageHeader title={isEditing ? t(`{{type}}を編集`, { type: typeJa }) : t(`{{type}}を新規作成`, { type: typeJa })}>
      {!updatable && <Alert className="mb-4" showIcon type="warning" message={t(`権限がないため編集できません`)} />}
      <Form form={form} initialValues={initialValue} layout="vertical" onFinish={onFinish} data-e2e={dataE2e}>
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Form.Item
              name="name"
              label={t(`{{type}}名`, { type: typeJa })}
              required
              rules={[
                {
                  required: true,
                  message: t(`{{type}}名を入力してください`, { type: typeJa }),
                },
              ]}
              className={isSome(editingView) ? 'mb-1' : 'mb-4'}
            >
              <Input disabled={!updatable} />
            </Form.Item>
            {isSome(editingView) && (
              <div className="mb-4 pl-3">
                <span className="text-gray-400">
                  <div className="flex flex-row gap-x-1">
                    <div>
                      <UserOutlined />
                    </div>
                    {editingView.createdBy?.name ?? ''}
                  </div>
                </span>
              </div>
            )}
          </Col>

          <Col span={16}>
            <Form.Item name="viewGroupId" label={t(`フォルダ`)}>
              <ViewGroupPicker
                type={editingType}
                isPrivate={viewGroup?.viewGroup.private ?? isPrivate}
                archived={false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <EmojiPickerFormItem
              emoji={emoji}
              onSelected={(emoji) => {
                setEmoji(emoji)
              }}
            />
          </Col>
        </Row>

        <Form.Item>
          <Row justify="end">
            <Space>
              <Button
                disabled={!updatable}
                type="primary"
                htmlType="submit"
                loading={loading}
                data-e2e="view-form-submit-button"
              >
                {isNull(editingView) ? t(`新規作成`) : t(`更新`)}
              </Button>
              {/* {isNull(editingView) && (
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                onClick={() => {
                  void onFinish(true)
                }}
              >
                現在のシートをコピーして新規作成
              </Button>
            )} */}
            </Space>
          </Row>
        </Form.Item>
      </Form>
      {isSome(editingView) && (
        <>
          <EntityPolicyForm
            subject={{ id: editingView.id, entityType: 'view', createdById: editingView.createdById }}
            actionGroup={editingView.type}
          />
          <AdminCard>
            <Button onClick={modal.showModal} icon={<EyeOutlined />}>
              {t(`スキーマの確認`)}
            </Button>
          </AdminCard>
          <SyntaxHighlightedJsonModal jsonStrings={[JSON.stringify(editingView)]} modal={modal} />
        </>
      )}
    </PageHeader>
  )
}

function generateEmptyConfig(type: ViewConfig['type']): ViewConfig {
  switch (type) {
    case 'sheet': {
      return {
        type: 'sheet',
      }
    }
    case 'kpi': {
      return {
        type: 'kpi',
      }
    }
    case 'kpiPivot': {
      return {
        type: 'kpiPivot',
        kpis: [],
      }
    }
    case 'kpiTimeSeries': {
      return {
        type: 'kpiTimeSeries',
      }
    }
  }
  // i18n: エラー系は一旦保留
  throw new Error(`この種別の設定は新規作成できません。${type}`)
}
