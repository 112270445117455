import { simpleLogger } from '../../misc/simpleLogger'

const createTableSql = /* sql */ `
CREATE TABLE IF NOT EXISTS "public"."salescore_extracted_email_metadata" (
    "id" varchar(255) NOT NULL,
    "email_id" varchar(255) NOT NULL,
    "email_table_name" varchar(255) NOT NULL,
    "message" TEXT NOT NULL,
    "error" TEXT,
    "full_name" varchar(255),
    "last_name" varchar(255),
    "first_name" varchar(255),
    "company" varchar(255),
    "division" varchar(255),
    "role" varchar(255),
    "phone_number" varchar(255),
    "company_url" TEXT,
    "company_address" TEXT,
    "created_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    PRIMARY KEY ("id")
);`

const index1 = /* sql */ `CREATE UNIQUE INDEX IF NOT EXISTS s__salescore_extracted_email_metadata__email_id ON salescore_extracted_email_metadata (email_id, email_table_name);`
const index2 = /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_extracted_email_metadata__created_at ON salescore_extracted_email_metadata (created_at);`

export async function createExtractedEmailMetadataTable(query: (sql: string) => Promise<void>) {
  // 冪等にしたいが、インデックスが存在するとエラーになるので無視（丁寧にケアすれば可能だがやらない）
  const queryAndIgnoreError = async (sql: string): Promise<void> => {
    try {
      await query(sql)
    } catch (error) {
      simpleLogger.warn(`SQLの実行中にエラーが発生`, sql, error)
    }
  }
  await queryAndIgnoreError(createTableSql)
  await Promise.all([queryAndIgnoreError(index1), queryAndIgnoreError(index2)])
}
