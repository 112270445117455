import { ConsoleSqlOutlined, DeleteOutlined, FilterOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { CONSTANT } from '@salescore/client-base'
import { Button, List, Modal, Space } from 'antd'
import { t } from 'i18next'

import {
  useKpiPivotNavigationModal,
  useKpiPivotParameter,
  useNavigationModal,
} from '../../../../recoil/navigation/hooks'
import { useMeValue } from '../../../../recoil/view/hooks'

export const DimensionFiltersModal = () => {
  const { dimensionFiltersModal } = useKpiPivotNavigationModal()
  return (
    <Modal
      open={dimensionFiltersModal.isModalVisible}
      onCancel={() => {
        dimensionFiltersModal.hideModal()
      }}
      width={'90%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
      }}
      destroyOnClose
      footer={<></>}
    >
      <Body
        onAfterFinish={() => {
          dimensionFiltersModal.hideModal()
        }}
      />
    </Modal>
  )
}

function Body({ onAfterFinish }: { onAfterFinish: () => void }) {
  const me = useMeValue()
  const { debugModal } = useNavigationModal()
  const [parameter, setParameter] = useKpiPivotParameter()
  const filters = parameter.dimensionFilterLeafs ?? []

  return (
    <PageHeader
      title={
        <Space>
          <FilterOutlined />
          <span>
            {t(`絞り込み`)}({t(`ダッシュボード`)})
          </span>
        </Space>
      }
      extra={[
        me.isAdmin && (
          <Button
            type="text"
            className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}
            style={{ width: '100%', textAlign: 'left' }}
            icon={<ConsoleSqlOutlined />}
            onClick={() => {
              debugModal.showModal({
                content: filters,
              })
            }}
          >
            {t(`スキーマ表示`)}
          </Button>
        ),
      ]}
    >
      <List
        bordered
        itemLayout="horizontal"
        dataSource={filters}
        renderItem={(filter, i) => {
          if (filter.type !== 'dimension') {
            return <></>
          }

          return (
            <List.Item
              actions={[
                <Space
                  className="cursor-pointer text-blue-500"
                  onClick={() => {
                    setParameter((x) => ({
                      ...x,
                      dimensionFilterLeafs: [
                        ...(x.dimensionFilterLeafs?.slice(0, i) ?? []),
                        ...(x.dimensionFilterLeafs?.slice(i + 1) ?? []),
                      ],
                    }))
                    //
                  }}
                  // setHasChangeToViewSchema(true) // configの中で現在の軸設定は保存していないので、ここでhasChangeを呼ぶ必要はない。
                >
                  <DeleteOutlined /> {t(`削除`)}
                </Space>,
                // <Button type="text" icon={<DeleteOutlined />} />,
              ]}
            >
              {t(`{{label}} が {{valueLabel}} に等しい`, {
                label: filter.dimension.label,
                valueLabel: filter.filterValueLabel,
              })}
            </List.Item>
          )
        }}
      />
    </PageHeader>
  )
}
