import { selector } from 'recoil'

import { uiAtom } from '../atoms'

export const sheetUiComponentSelector = selector({
  key: `view/sheetUiComponentSelector`,
  get({ get }) {
    const component = get(uiAtom)[0]
    if (component === undefined || component.type !== 'Sheet') {
      // TODO: 雑な作りにしている
      // eslint-disable-next-line unicorn/no-useless-undefined
      return undefined
    }
    return component
  },
})
