import { CalendarOutlined } from '@ant-design/icons'
import type { CoreDslFormLiteral, ModelProperty } from '@salescore/core'
import dayjs from 'dayjs'

import {
  getNInputValue,
  getNOptionValue,
  isNSelected,
  relativeOptionGroups,
  searchFilterOption,
} from './CoreDslFormDateLiteralFormItem'
import { CoreDslFormDropdownButton } from './CoreDslFormDropdownButton'

interface Properties {
  term: CoreDslFormLiteral
  property: ModelProperty
  label: string
}

const NotSupportedType = () => <div>not supported</div>

export function CoreDslFormLiteralItem(argument: Properties) {
  const { term, property, label } = argument
  const type = term.literalType
  switch (type) {
    case 'string' as const: {
      // 選択肢だったときはlabelを表示する
      const options = property.selectOptions ?? []
      const value = options.isPresent()
        ? (options.find((x) => x.value === term.value)?.label ?? term.value)
        : term.value
      return <CoreDslFormDropdownButton value={value} label={label} />
    }
    case 'number' as const: {
      return <CoreDslFormDropdownButton value={`${term.value}`} label={label} />
    }
    case 'date' as const: {
      switch (term.dateTimeType) {
        case 'relative': {
          const option =
            searchFilterOption({ filterValue: term.value, relativeOptionGroups }) ??
            searchFilterOption({ filterValue: getNOptionValue(term.value), relativeOptionGroups })

          // N値を選択している場合、ラベル（ex:「N日後」）を置換するので、{N}ではなくN単体を置換する
          const value = isNSelected(option?.value)
            ? option?.label.replace('N', getNInputValue(term.value).toString())
            : option?.label

          return <CoreDslFormDropdownButton value={value} label={label} prefix={<CalendarOutlined />} />
        }
        default: {
          const date = dayjs(term.value)
          const format =
            term.dateTimeType === 'year' ? 'YYYY年' : term.dateTimeType === 'month' ? 'YYYY年MM月' : 'YYYY年MM月DD日'
          return <CoreDslFormDropdownButton value={date.format(format)} label={label} prefix={<CalendarOutlined />} />
        }
      }
    }
    // case 'datetime' as const:
    //   return <DateLiteralItem {...arg} /> // 全てDateで扱う
    // case 'time' as const:
    //   return <NotSupportedType />
    case 'boolean' as const: {
      return <CoreDslFormDropdownButton value={term.value ? 'TRUE' : 'FALSE'} label={label} />
    }
    case 'array' as const: {
      const options = property.selectOptions ?? []
      const value = term.value.map((v) => options.find((x) => x.value === v)?.label ?? v).join(', ')
      return <CoreDslFormDropdownButton value={value} label={label} />
    }
    // case 'object' as const:
    // TODO
    // return <NotSupportedType />
    // case 'array' as const:
    // TODO: どう表示させるか？一旦JSONにして表示している
    // return <NotSupportedType />
    default: {
      const x: never = type
      return <NotSupportedType />
    }
  }
}
