import { simpleLogger } from '../misc/simpleLogger'

const createTableSql = /* sql */ `
CREATE TABLE IF NOT EXISTS "public"."salescore_extracted_calling_metadata" (
    "id" varchar(255) NOT NULL,
    "call_id" varchar(255) NOT NULL,
    "type" varchar(255) NOT NULL,
    "version" varchar(255) NOT NULL,
    "status" varchar(255) NOT NULL DEFAULT 'initialized',
    "transcript_raw" JSON,
    "transcript" TEXT,
    "transcript_refined" TEXT,
    "extracted_metadata_raw" TEXT,
    "extracted_metadata" JSON,
    "s3_recording_path" TEXT,
    "prompt_for_speech_to_text" TEXT,
    "prompt_to_refine_text" JSON,
    "prompt_to_extract_metadata" JSON,
    "token_usage_to_refine_text_input" integer,
    "token_usage_to_refine_text_output" integer,
    "token_usage_to_extract_metadata_input" integer,
    "token_usage_to_extract_metadata_output" integer,
    "refine_text_input_text_length" integer,
    "refine_text_prompt_text_length" integer,
    "refine_text_output_text_length" integer,
    "refine_text_input_splited_count" integer,
    "extract_metadata_input_text_length" integer,
    "extract_metadata_prompt_text_length" integer,
    "extract_metadata_output_text_length" integer,
    "extract_metadata_input_splited_count" integer,
    "error" TEXT,
    "created_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    "updated_at" timestamptz NOT NULL DEFAULT CURRENT_TIMESTAMP,
    PRIMARY KEY ("id")
);`

const index1 = /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_extracted_calling_metadata__call_id ON salescore_extracted_calling_metadata (call_id);`
const index2 = /* sql */ `CREATE INDEX IF NOT EXISTS s__salescore_extracted_calling_metadata__version_call_id ON salescore_extracted_calling_metadata (version, call_id);`

export async function createExtractedCallingMetadataTable(query: (sql: string) => Promise<void>) {
  // 冪等にしたいが、インデックスが存在するとエラーになるので無視（丁寧にケアすれば可能だがやらない）
  const queryAndIgnoreError = async (sql: string): Promise<void> => {
    try {
      await query(sql)
    } catch (error) {
      simpleLogger.warn(`SQLの実行中にエラーが発生`, sql, error)
    }
  }
  await queryAndIgnoreError(createTableSql)
  await Promise.all([queryAndIgnoreError(index1), queryAndIgnoreError(index2)])
}
