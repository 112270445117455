import { createRef } from 'react'
import { atom, atomFamily } from 'recoil'

import type { RSheetColumn, RSheetContext, RSheetRecordNode } from '../types'
import type { RSheetsCursor } from '../types/CursorTypes'

const key = `rsheet`

export const cursorAtom = atom<RSheetsCursor | undefined>({
  key: `${key}/cursor`,
  default: undefined,
})

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type RowConfig = {
  expanded: boolean
}
export const rowConfigAtomFamily = atomFamily<RowConfig | undefined, { id: string }>({
  key: `${key}/rowConfig`,
  default: undefined,
})

interface OpenColumnContextMenu {
  column: RSheetColumn<RSheetRecordNode>
  addColumnDropdownVisible: boolean
}

export const openColumnContextMenuAtom = atom<OpenColumnContextMenu | undefined>({
  key: `${key}/openColumnContextMenu`,
  default: undefined,
})

//
// 以下はglobalにアクセスするためにatomとしているが、初期化時以外に値が変わることはない。（いったんpropsと呼び、アクセサはpropModelsで定義）
//
export const columnsAtom = atom<Array<RSheetColumn<RSheetRecordNode>>>({
  key: `${key}/columns`,
  default: [],
})

export const contextAtom = atom<RSheetContext>({
  key: `${key}/context`,
  default: {},
})

export const rootRefAtom = atom<React.RefObject<HTMLDivElement>>({
  key: `${key}/rootRef`,
  default: createRef<HTMLDivElement>(),
  dangerouslyAllowMutability: true,
})

export const openSheetThreadsFormAtom = atom<
  Array<{
    recordId: string
  }>
>({
  key: `${key}/openSheetThreadsForm`,
  default: [],
})

export const openSheetThreadsAtom = atom<
  Array<{
    id: string
    draftComment: string
  }>
>({
  key: `${key}/openSheetThreads`,
  default: [],
})
