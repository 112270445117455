import { z } from 'zod'

import { goalConfigSchema } from '../misc/goal_config'
import { kpiAppearanceSchema, pivotAppearanceSchema } from '../ui/ui'
import { nodePropertyNameSchema } from './common'
import { viewConfigDimensionSchema } from './dimension'
import { viewConfigFilterNodeSchema, viewConfigFilterSchema } from './filter'
import { viewConfigKpiMeasureSchema } from './measure'
import { ViewConfigSheetSchema } from './sheet'

const sheetConfigOrReferenceSchema = z.union([
  z.object({
    type: z.literal(`reference`),
    viewId: z.string(),
  }),
  ViewConfigSheetSchema,
])
export type SheetConfigOrReference = z.infer<typeof sheetConfigOrReferenceSchema>

const viewConfigPivotSchema = z.object({
  rows: viewConfigDimensionSchema.array(), // rows, columnsのどちらかに、必ずMEASURE_NAMEが含まれる?
  columns: viewConfigDimensionSchema.array(),
  dimensionsWithoutRollup: viewConfigDimensionSchema.array().optional(), // 目標でのみ使用予定
})
export type ViewConfigPivot = z.infer<typeof viewConfigPivotSchema>

const viewConfigPivotSorterSchema = z.object({
  columnKeys: z
    .object({
      key: z.string(),
      order: z.enum(['asc', 'desc']),
    })
    .array()
    .optional(),
})
export type ViewConfigPivotSorter = z.infer<typeof viewConfigPivotSorterSchema>

export const viewConfigKpiParameterSchema = z.object({
  pivot: viewConfigPivotSchema,
  sorter: viewConfigPivotSorterSchema.optional(), // 集計値をもとにレコードのソートを行う(dimensionのソートではないので注意)
  queryParameter: z.record(z.string(), z.union([z.string(), z.undefined()])).optional(), // XXX: この値はcoreのcompileでは使われず、ui側でparameterとしてマージされて使われる。挙動が分かりづらい。。
  invisibleKpiIds: z.string().array().optional(),
  dimensionFilterLeafs: viewConfigFilterSchema.array().optional(),
  goalConfig: goalConfigSchema.optional(),
})
export type ViewConfigKpiParameter = z.infer<typeof viewConfigKpiParameterSchema>
export const kpiPresetSchema = z.object({
  name: z.string(), // unique
  parameter: viewConfigKpiParameterSchema,
})
export type ViewConfigKpiPreset = z.infer<typeof kpiPresetSchema>

//
// KPIの設定
//
export const viewConfigKpiSchema = z.object({
  type: z.literal(`kpi`),
  sheet: sheetConfigOrReferenceSchema.optional(), // 基本的な設定は、シートとして設定させる
  measure: viewConfigKpiMeasureSchema.optional(),
  date: z
    .object({
      property: nodePropertyNameSchema,
    })
    .optional(),
  user: z
    .object({
      property: nodePropertyNameSchema,
      joinAs: z.enum([`id`, `email`, `name`]),
    })
    .optional(),
  dimensionAliases: z
    .object({
      property: nodePropertyNameSchema,
      alias: z.string(),
    })
    .array()
    .optional(),
  ui: z
    .object({
      kpi: kpiAppearanceSchema.omit({ id: true, label: true, withDateField: true }),
      appearance: pivotAppearanceSchema.optional(),
    })
    .optional(),
  presets: kpiPresetSchema.array().optional(),
  sheetExtraSetting: z
    .object({
      // KPIをドリルダウンして、シートとして表示するときだけに適用される設定。現状はfilterしかないので、実装側もfilterに特化した実装にしている
      // 2023/08/17 必要なのはフィルタではなくブロックフィルタだった :bow: migrationを後で行うが、以下はブロックフィルタ想定
      filterTree: viewConfigFilterNodeSchema.optional(),
    })
    .optional(),
  options: z
    .object({
      dimensionFieldsVisibility: z.boolean(),
    })
    .optional(),
})
export type ViewConfigKpi = z.infer<typeof viewConfigKpiSchema>
