import { useApolloClient } from '@apollo/client'
import type { ViewFieldsFragment } from '@salescore/client-api'
import { FetchMixedUsersDocument } from '@salescore/client-api'
import { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { organizationIdAtom } from './records/atoms'
import { usersAtom } from './view/atoms'

export const useInitializeResources = ({ view }: { view: ViewFieldsFragment }) => {
  const organizationId = useRecoilValue(organizationIdAtom)
  const client = useApolloClient()
  const setUsers = useSetRecoilState(usersAtom)

  const setUsersAsync = async () => {
    const { data } = await client.query({
      query: FetchMixedUsersDocument,
      variables: {
        organizationId,
      },
      fetchPolicy: 'cache-first',
    })
    setUsers(data.mixedUsers ?? [])
  }

  useEffect(() => {
    void setUsersAsync()
  }, [])
}
