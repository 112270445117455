import type { NodePropertyName } from '@salescore/core'
import { Select } from 'antd'
import { useEffect, useMemo } from 'react'

import type { KpiFormCurrentNodeProperty } from '../../../../recoil/view/selectors/kpiFormSelector'

export const PropertySelect = ({
  properties,
  value,
  placeholder,
  disabled,
  allowClear,
  onChange,
}: {
  properties: KpiFormCurrentNodeProperty[]
  value: NodePropertyName | undefined
  placeholder?: string
  allowClear?: boolean
  disabled?: boolean
  onChange: (value?: { nodePropertyName: NodePropertyName; property: KpiFormCurrentNodeProperty }) => void
}) => {
  const { options, picked } = useMemo(() => {
    const options = properties
      .map((x) => {
        const { property, model, node } = x
        return {
          value: propertyWithModelToSelectOptionValue(x),
          // TODO: labelどうするか問題
          key: `${node.nodePathAsLabel.join(': ')}: ${property.label} - ${node.node.name}.${property.name}`,
          label: (
            <>
              {node.nodePathAsLabel.join(': ')}: {property.label}
              <span className="ml-2 text-xs text-gray-500">
                - {node.node.name}.{property.name}
              </span>
            </>
          ),
          property: x,
        }
      })
      .uniqueBy((x) => x.value)
    const valueAsString = value === undefined ? '' : nodePropertyNameToSelectOptionValue(value)
    const picked = options.find((x) => x.value === valueAsString)
    return { options, picked }
  }, [properties, value])

  useEffect(() => {
    // options,valueが切り替わって選択している値がなくなったら、値をリセットする
    if (picked === undefined) {
      onChange()
    }
  }, [picked?.value])

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const filterOption = (input: string, option?: { key: string }) =>
    (option?.key ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <Select
      allowClear={allowClear}
      filterOption={filterOption}
      style={{ width: `100%` }}
      placeholder={placeholder}
      disabled={disabled}
      options={options}
      value={picked?.value ?? ''}
      onChange={(value) => {
        const picked = options.find((x) => x.value === value)
        if (picked === undefined) {
          onChange()
          return
        }
        const property = picked.property
        onChange({
          nodePropertyName: {
            nodeName: property.node.node.name,
            modelName: property.model.name,
            propertyName: property.property.name,
          },
          property,
        })
      }}
      showSearch
      optionRender={(option) => <span className="whitespace-normal break-words ">{option.label}</span>}
    />
  )
}

function propertyWithModelToSelectOptionValue(x: KpiFormCurrentNodeProperty) {
  return JSON.stringify([x.node.node.name, x.model.name, x.property.name])
}

function nodePropertyNameToSelectOptionValue(x: NodePropertyName) {
  return JSON.stringify([x.nodeName, x.modelName, x.propertyName])
}
