import { isNull } from '@salescore/buff-common'
import { Alert, Modal } from 'antd'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { aggregationErrorsAtom } from '../../../../recoil/records/atoms'
import { useErrorsState } from '../../../../recoil/records/hooks'
import { compiledConfigSelector } from '../../../../recoil/view/atoms'

export const CompileLogsModal = () => {
  const { compileLogsModal } = useNavigationModal()

  return (
    <Modal
      open={compileLogsModal.isModalVisible}
      onCancel={compileLogsModal.hideModal}
      width={'60%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      title={<div className="font-bold">{t(`設定エラー一覧`)}</div>}
      style={{ top: '3%' }}
    >
      <Body />
    </Modal>
  )
}

function Body() {
  const [queryErrors] = useErrorsState()
  const { logs } = useRecoilValue(compiledConfigSelector)
  const aggregationErrors = useRecoilValue(aggregationErrorsAtom)
  const errors = [...(logs?.showErrors() ?? []), ...queryErrors, ...aggregationErrors]
  if (isNull(logs) && errors.isBlank()) {
    return <></>
  }

  return (
    <>
      <div className="mb-8">
        {/* <Typography.Title level={4}>エラー一覧</Typography.Title> */}
        {errors.map((error) => (
          <Alert className="mb-2" showIcon type="error" message={error} />
        ))}
        {logs?.showWarns().map((warn) => <Alert className="mb-2" showIcon type="warning" message={warn} />)}
      </div>
    </>
  )
}
