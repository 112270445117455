import { Form, Select } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'
import { useRecoilState } from 'recoil'

import { chartDesignSettingsAtom } from '../../../../recoil/navigation/atoms'
import { labelDecimalPlaceOptions, labelDisplayOptions, labelUnitOptions } from '../../ViewUIKpiPivot/KpiParameterSider'

export function ViewUiRiDesignSetting(): ReactNode {
  const [chartDesignSettings, setChartDesignSettings] = useRecoilState(chartDesignSettingsAtom)

  return (
    <div className="py-4">
      <div className="text-xl font-bold">{t(`デザイン設定`)}</div>

      <div className="mt-2">
        <Form.Item label={t(`X軸のラベル表示`)}>
          <Select
            options={labelDisplayOptions}
            value={chartDesignSettings.chartAxisLabelDisplayMode}
            onChange={(v) => {
              setChartDesignSettings((previous) => ({
                ...previous,
                chartAxisLabelDisplayMode: v,
              }))
            }}
          />
        </Form.Item>

        <Form.Item label={t(`値ラベルの表示`)}>
          <Select
            options={labelDisplayOptions}
            value={chartDesignSettings.chartValueLabelDisplayMode}
            onChange={(v) => {
              setChartDesignSettings((previous) => ({
                ...previous,
                chartValueLabelDisplayMode: v,
              }))
            }}
          />
        </Form.Item>

        <Form.Item label={t(`値ラベルの単位`)}>
          <Select
            options={labelUnitOptions}
            value={chartDesignSettings.chartValueLabelUnitType}
            onChange={(v) => {
              setChartDesignSettings((previous) => ({
                ...previous,
                chartValueLabelUnitType: v,
              }))
            }}
          />
        </Form.Item>

        <Form.Item label={t(`値ラベルの桁`)}>
          <Select
            options={labelDecimalPlaceOptions}
            value={chartDesignSettings.chartValueLabelDecimalPlaceType}
            onChange={(v) => {
              setChartDesignSettings((previous) => ({
                ...previous,
                chartValueLabelDecimalPlaceType: v,
              }))
            }}
          />
        </Form.Item>
      </div>
    </div>
  )
}
