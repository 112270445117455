import type { ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'

import { getRecordNodesWithInnerRowIndex } from '../../../util/innerRowRelated'

export interface ViewQueryRecordNodeWithIndex {
  record: ViewQueryRecordNode
  rowIndex: number
  innerRowIndex: number
}

// ペースト先の擬似行をIteratorパターンで生成する
export class PseudoRowIterator {
  private readonly pseudoRootNode: ViewQueryNode
  private readonly rows: ViewQueryRecordNode[]
  private readonly currentPseudoRows: ViewQueryRecordNodeWithIndex[]
  private readonly originalRowIndexStart: number
  public rowIndex: number

  public lastEditedRowIndex: number | undefined // 編集したrowIndex。やや冗長だが、この実装の方が確実なのでこうする。

  public constructor(
    pseudoRootNode: ViewQueryNode,
    rows: ViewQueryRecordNode[],
    initialPosition: { innerRowIndex: number; rowIndex: number },
  ) {
    this.pseudoRootNode = pseudoRootNode
    this.rows = rows
    this.originalRowIndexStart = initialPosition.rowIndex
    this.rowIndex = 0
    this.lastEditedRowIndex = -1
    const row = rows[this.rowIndex]
    this.currentPseudoRows =
      row === undefined
        ? []
        : getRecordNodesWithInnerRowIndex(row, pseudoRootNode)
            .filter((x) => x.innerRowIndexStart >= initialPosition.innerRowIndex)
            .map((x): ViewQueryRecordNodeWithIndex | undefined => {
              if (x.recordNode === undefined) {
                return undefined
              }
              return {
                record: x.recordNode,
                rowIndex: this.rowIndex + this.originalRowIndexStart,
                innerRowIndex: x.innerRowIndexStart,
              }
            })
            .compact()
  }

  public next(): ViewQueryRecordNodeWithIndex | undefined {
    if (this.currentPseudoRows.isPresent()) {
      this.lastEditedRowIndex = this.rowIndex
      return this.currentPseudoRows.shift()
    }
    // 次の行へ
    if (this.rowIndex >= this.rows.length) {
      return undefined
    }
    this.rowIndex += 1
    const row = this.rows[this.rowIndex]
    if (row === undefined) {
      return undefined
    }
    const newPseudoRows: ViewQueryRecordNodeWithIndex[] = getRecordNodesWithInnerRowIndex(row, this.pseudoRootNode)
      .map((x): ViewQueryRecordNodeWithIndex | undefined => {
        if (x.recordNode === undefined) {
          return undefined
        }
        return {
          record: x.recordNode,
          rowIndex: this.rowIndex + this.originalRowIndexStart,
          innerRowIndex: x.innerRowIndexStart,
        }
      })
      .compact()
    this.currentPseudoRows.push(...newPseudoRows)
    return this.next()
  }
}
