import type { ViewConfigTypeEnum } from '@salescore/client-api'
import { policyUtil, type ViewPolicyAction } from '@salescore/features'
import { selectorFamily, useRecoilValue } from 'recoil'

import { abilitySelectorFamily } from './useCan'

export const abilityForViewSelectorFamily = selectorFamily<
  boolean,
  {
    action: ViewPolicyAction
    view: { id?: string; createdById?: string | null; type: `${ViewConfigTypeEnum}` }
  }
>({
  key: `common/abilityForViewSelectorFamily`,
  get:
    ({ action, view }) =>
    ({ get }) => {
      const subject = view.id === undefined ? undefined : policyUtil.toSubject({ ...view, id: view.id }, 'view')
      switch (view.type) {
        case 'sheet': {
          switch (action) {
            case 'create': {
              return get(abilitySelectorFamily({ action: 'sheet-create', subject }))
            }
            case 'update': {
              return get(abilitySelectorFamily({ action: 'sheet-update', subject }))
            }
            case 'delete': {
              return get(abilitySelectorFamily({ action: 'sheet-delete', subject }))
            }
            case 'create-new-record': {
              return get(abilitySelectorFamily({ action: 'sheet-create-new-record', subject }))
            }
            case 'save-record': {
              return get(abilitySelectorFamily({ action: 'sheet-save-record', subject }))
            }
            case 'delete-record': {
              return get(abilitySelectorFamily({ action: 'sheet-delete-record', subject }))
            }
            case 'open-relation-input-form': {
              return get(abilitySelectorFamily({ action: 'sheet-open-relation-input-form', subject }))
            }
            case 'download-csv': {
              return get(abilitySelectorFamily({ action: 'sheet-download-csv', subject }))
            }
            default: {
              const _: never = action
              return true
            }
          }
        }
        case 'kpi': {
          switch (action) {
            case 'create': {
              return get(abilitySelectorFamily({ action: 'kpi-create', subject }))
            }
            case 'update': {
              return get(abilitySelectorFamily({ action: 'kpi-update', subject }))
            }
            case 'delete': {
              return get(abilitySelectorFamily({ action: 'kpi-delete', subject }))
            }
            case 'create-new-record': {
              return get(abilitySelectorFamily({ action: 'kpi-create-new-record', subject }))
            }
            case 'save-record': {
              return get(abilitySelectorFamily({ action: 'kpi-save-record', subject }))
            }
            case 'delete-record': {
              return get(abilitySelectorFamily({ action: 'kpi-delete-record', subject }))
            }
            case 'open-relation-input-form': {
              return get(abilitySelectorFamily({ action: 'kpi-open-relation-input-form', subject }))
            }
            case 'download-csv': {
              return get(abilitySelectorFamily({ action: 'kpi-download-csv', subject }))
            }
            default: {
              const _: never = action
              return true
            }
          }
        }
        case 'kpiPivot': {
          switch (action) {
            case 'create': {
              return get(abilitySelectorFamily({ action: 'kpi-pivot-create', subject }))
            }
            case 'update': {
              return get(abilitySelectorFamily({ action: 'kpi-pivot-update', subject }))
            }
            case 'delete': {
              return get(abilitySelectorFamily({ action: 'kpi-pivot-delete', subject }))
            }
            case 'create-new-record': {
              return true
            }
            case 'save-record': {
              return true
            }
            case 'delete-record': {
              return true
            }
            case 'open-relation-input-form': {
              return true
            }
            case 'download-csv': {
              return get(abilitySelectorFamily({ action: 'kpi-pivot-download-csv', subject }))
            }
            default: {
              const _: never = action
              return true
            }
          }
        }
        case 'form': {
          return true
        }
        case 'kpiTimeSeries': {
          // TODO: RI
          return true
        }
        // No default
      }
      const _: never = view.type
      return true
    },
})

export const useCanForView = (
  action: ViewPolicyAction,
  view: { id?: string; createdById?: string | null; type: `${ViewConfigTypeEnum}` },
) => useRecoilValue(abilityForViewSelectorFamily({ action, view }))
