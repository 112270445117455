import type { ViewQueryRecordNode } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'
import { message } from 'antd'
import { t } from 'i18next'
import { useSetRecoilState } from 'recoil'

import type { RSheetColumn, RSheetRecordNode } from '../../types'
import type { RSheetCursorContextMenuPosition, RSheetsCursor, RSheetsCursorPosition } from '../../types/CursorTypes'
import { cursorModel } from '../models/cursorModel'
import { columnsModel, rowsModel, useColumnsValue } from '../models/propModels'
import { moveCursorMutation } from './cursor/moveCursor'

// TODO: 実装をシンプルにするため、rowIndexカラム分をスキップする設定をここでやってしまっている
export const CURSOR_LEFT_COLUMN_INDEX = 1

interface CursorOverlapInfo {
  isMainSelected: boolean
  isExpandSelected: boolean
  isRangeSelected: boolean
}

const getUpAndDownPosition = (positionA: RSheetsCursorPosition, positionB: RSheetsCursorPosition) => {
  if (positionA.rowIndex !== positionB.rowIndex) {
    return {
      upPosition: positionA.rowIndex < positionB.rowIndex ? positionA : positionB,
      downPosition: positionA.rowIndex > positionB.rowIndex ? positionA : positionB,
    }
  }

  return {
    upPosition: positionA.innerRowIndex < positionB.innerRowIndex ? positionA : positionB,
    downPosition: positionA.innerRowIndex > positionB.innerRowIndex ? positionA : positionB,
  }
}

export const getArea = (positionA: RSheetsCursorPosition, positionB: RSheetsCursorPosition) => {
  const { upPosition, downPosition } = getUpAndDownPosition(positionA, positionB)

  return {
    left: Math.min(positionA.columnIndex, positionB.columnIndex),
    right: Math.max(positionA.columnIndex, positionB.columnIndex),
    up: upPosition.rowIndex,
    down: downPosition.rowIndex,
    upInnerRowIndex: upPosition.innerRowIndex,
    downInnerRowIndex: downPosition.innerRowIndex,
  }
}

export const isSamePosition = (positionA: RSheetsCursorPosition, positionB: RSheetsCursorPosition) =>
  positionA.rowIndex === positionB.rowIndex &&
  positionA.columnIndex === positionB.columnIndex &&
  positionA.innerRowIndex === positionB.innerRowIndex

export const isCursorOverlap = (
  cursor: RSheetsCursor | undefined,
  position: RSheetsCursorPosition,
): CursorOverlapInfo => {
  const defaultResult = {
    isMainSelected: false,
    isExpandSelected: false,
    isRangeSelected: false,
  }
  const { main, expand } = cursor ?? {}
  if (main === undefined) {
    return defaultResult
  }

  const isMainSelected = isSamePosition(position, main)
  if (expand === undefined) {
    return {
      ...defaultResult,
      isMainSelected,
    }
  }

  const isExpandSelected = position.rowIndex === expand.rowIndex && position.columnIndex === expand.columnIndex
  const { left, right, up, down, upInnerRowIndex, downInnerRowIndex } = getArea(main, expand)
  const isParentCellRangeSelected =
    left <= position.columnIndex &&
    position.columnIndex <= right &&
    up <= position.rowIndex &&
    position.rowIndex <= down
  const isRangeSelected =
    isParentCellRangeSelected &&
    // 行境界の最上段でも最下段でもない
    ((up < position.rowIndex && position.rowIndex < down) ||
      // 行境界の最上段で、ネストしたセルの行より下
      (position.rowIndex === up && position.rowIndex !== down && upInnerRowIndex <= position.innerRowIndex) ||
      // 行境界の最下段で、ネストしたセルの行より上
      (position.rowIndex !== up && position.rowIndex === down && position.innerRowIndex <= downInnerRowIndex) ||
      // 1行のみ選択されているとき
      (position.rowIndex === up &&
        position.rowIndex === down &&
        upInnerRowIndex <= position.innerRowIndex &&
        position.innerRowIndex <= downInnerRowIndex))

  return {
    isMainSelected,
    isExpandSelected,
    isRangeSelected,
  }
}

const PREFIX = `cursorMutation`
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const clearCursorMutation = mutation<void>({
  key: `${PREFIX}/clearCursor`,
  set({ get, set }) {
    set(cursorModel, undefined)
  },
})

export const moveToEdgeMutation = mutation<{ type: 'left' | 'right' | 'up' | 'down'; expand: boolean }>({
  key: `${PREFIX}/moveToEdge`,
  set({ get, set }, argument) {
    const { type, expand } = argument

    const d: RSheetsCursorPosition = {
      rowIndex: 0,
      columnIndex: 0,
      innerRowIndex: 0,
    }
    const cursor = get(cursorModel)
    const columns = get(columnsModel)
    const rows = get(rowsModel)
    const setCursor = (value: RSheetsCursor) => {
      set(cursorModel, value)
    }
    const target: RSheetsCursorPosition = (expand ? cursor?.expand : cursor?.main) ?? cursor?.main ?? d
    // edgeが空のセルだったとき、表示されないことがあるが許容する
    const moved: RSheetsCursorPosition =
      type === 'left'
        ? {
            ...target,
            columnIndex: CURSOR_LEFT_COLUMN_INDEX,
          }
        : type === 'right'
          ? {
              ...target,
              columnIndex: columns.length - 1,
            }
          : type === 'up'
            ? {
                ...target,
                innerRowIndex: 0,
                rowIndex: 0,
              }
            : {
                ...target,
                innerRowIndex: maxInnerRowIndex(rows.last()),
                rowIndex: rows.length - 1,
              }
    if (expand) {
      if (cursor === undefined) {
        setCursor({
          main: d,
          expand: moved,
        })
        return
      }
      setCursor({
        ...cursor,
        expand: moved,
      })
      return
    }

    if (cursor === undefined) {
      setCursor({
        main: moved,
        expand: undefined,
      })
      return
    }
    setCursor({
      ...cursor,
      main: moved,
      expand: undefined,
    })
  },
})

function maxInnerRowIndex(record: ViewQueryRecordNode | undefined): number {
  if (record === undefined) {
    return 0
  }
  const childInnerStart = record.children.map((childNode) => maxInnerRowIndex(childNode.children.last())).compact()
  return [record.meta.innerRowIndexStart, ...childInnerStart].compact().max() ?? 0
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const hideCopyingAreaMutation = mutation<void>({
  key: `${PREFIX}/hideCopyingAreaMutation`,
  set({ get, set }) {
    set(cursorModel, (oldValue) => {
      if (oldValue === undefined) {
        return
      }
      if (oldValue.copying === undefined) {
        return oldValue
      }

      return {
        ...oldValue,
        copying: {
          ...oldValue.copying,
          visible: false,
        },
      }
    })
  },
})

export type RSheetCursorState = ReturnType<typeof useCursorMutation>
export const useCursorMutation = () => {
  // ここでcursorを呼ぶと、useCursorMutationを呼んでいる箇所でcursorの変更のたびにレンダリングが走るので注意
  const setCursor = useSetRecoilState(cursorModel)
  const columns = useColumnsValue()

  const clearCursor = useSetRecoilState(clearCursorMutation)
  const moveToEdge = useSetRecoilState(moveToEdgeMutation)
  const moveCursor = useSetRecoilState(moveCursorMutation)
  const hideCopyingArea = useSetRecoilState(hideCopyingAreaMutation)

  return {
    setCursor,
    clearCursor,
    moveCursor,
    moveToEdge,
    hideCopyingArea,
    finishEditMode() {
      setCursor((oldValue) => {
        if (oldValue === undefined) {
          return
        }

        return {
          ...oldValue,
          editing: undefined,
        }
      })
    },
    moveToAboveInnerRow() {
      setCursor((x) => {
        if (x === undefined) {
          return x
        }
        return {
          ...x,
          main: {
            ...x.main,
            innerRowIndex: Math.max(0, x.main.innerRowIndex - 1),
          },
          contextMenuPosition: undefined,
        }
      })
    },
    setMainCursor(position: RSheetsCursorPosition, isEditing?: boolean) {
      const column = columns[position.columnIndex]
      if (column === undefined) {
        return
      }
      setCursor((oldValue) => ({
        main: position,
        expand: undefined,
        editing: column.readonly === true ? undefined : { isEditing },
        contextMenuPosition: undefined,
        copying: oldValue?.copying,
      }))
    },
    setMainCursorOrToggle(position: RSheetsCursorPosition) {
      const column = columns[position.columnIndex]
      if (column === undefined) {
        return
      }
      setCursor((oldValue) => {
        if (
          [
            oldValue?.main.rowIndex === position.rowIndex,
            oldValue?.main.columnIndex === position.columnIndex,
            oldValue?.main.innerRowIndex === position.innerRowIndex,
          ].every(Boolean)
        ) {
          // TODO: setterの中でmessageを読んでいて治安が悪い
          if (column.readonly === true) {
            void message.warning(t(`この列は書き込み禁止です`))
            return oldValue
          }
          return {
            main: position,
            isEditing: true,
          }
        }

        return {
          main: position,
          expand: undefined,
          isEditing: false,
          contextMenuPosition: undefined,
        }
      })
    },
    setContextMenuPosition(position: RSheetsCursorPosition, contextMenuPosition: RSheetCursorContextMenuPosition) {
      setCursor({
        main: position,
        expand: undefined,
        editing: undefined,
        contextMenuPosition,
      })
    },
    hideContextMenu() {
      setCursor((x) => {
        if (x === undefined) {
          return
        }
        return {
          ...x,
          contextMenuPosition: undefined,
        }
      })
    },
    moveToRowIndexAndEditMode(rowIndex: number, column?: RSheetColumn<RSheetRecordNode>) {
      // 変な形で編集モードになるケースが多いため、いったん編集モードはやめる
      setCursor((oldCursor) => {
        if (oldCursor === undefined) {
          const firstWritableColumnIndex = Math.max(
            columns.findIndex((x) => x.readonly !== false),
            0,
          ) // TODO

          return {
            main: {
              rowIndex,
              columnIndex: firstWritableColumnIndex,
              innerRowIndex: 0,
            },
            // isEditing: column?.readonly !== true,
            isEditing: false,
          }
        }

        return {
          main: {
            ...oldCursor.main,
            rowIndex,
          },
          // isEditing: column?.readonly !== true,
          isEditing: false,
        }
      })
    },
  }
}
