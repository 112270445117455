import type { PolicyAction } from '../action'
import { playingManagerRolePolicyDefinition } from './playingManagerRolePolicyDefinition'

// 一般メンバー：設定系ができないロール。シートの変更などは可能
// XXX: 各種ロールは定義の簡素化のため上位ロールを継承している。
//      権限を変更したら下位ロールにも影響する可能性があるため、継承関係を意識して変更すること
export const memberRolePolicyDefinition: Record<PolicyAction, boolean> = {
  ...playingManagerRolePolicyDefinition,
  [`kpi-create`]: false,
  [`kpi-update`]: false,
  [`kpi-delete`]: false,
  [`kpi-pivot-create`]: false,
  [`kpi-pivot-update`]: false,
  [`kpi-pivot-delete`]: false,
  [`manage-organization-setting`]: false,
  [`manage-connection`]: false,
  [`manage-goal`]: false,
  [`manage-dimension-group`]: false,
  [`manage-users`]: false,
  [`manage-user-groups`]: false,
  [`manage-invitation`]: false,
  [`read-user-activity`]: false,
}
