import { EyeOutlined } from '@ant-design/icons'
import { isSome } from '@salescore/buff-common'
import { Button, Checkbox, Dropdown, Input, InputNumber } from 'antd'
import type { MenuProps } from 'antd/es'
import { t } from 'i18next'
import { useState } from 'react'
import { useRecoilState } from 'recoil'

import { kpiUserAppearanceAtom } from '../../../recoil/navigation/atoms'

// 永続化しない細かな設定
export const KpiUserAppearancePicker = () => {
  const [kpiUserAppearance, setKpiUserAppearance] = useRecoilState(kpiUserAppearanceAtom)
  const toggleViewType = (key: 'diffVisible' | 'skipTotal' | 'goalVisible') => {
    const value = kpiUserAppearance[key] ?? false
    setKpiUserAppearance({ ...kpiUserAppearance, [key]: !value })
  }

  const menus: MenuProps['items'] = [
    {
      key: 'fontSize',
      label: (
        <div
          className="flex items-center justify-between gap-2"
          onClick={(e) => {
            // フォントサイズの入力欄をクリックしてもドロップダウンが閉じないようにする
            e.stopPropagation()
          }}
        >
          <span className="whitespace-nowrap text-xs font-bold">{t('セル内の文字サイズ')}</span>
          <FontSizeInput
            value={kpiUserAppearance.fontSize}
            onChange={(value) => {
              if (value === null) {
                setKpiUserAppearance((old) => ({ ...old, fontSize: undefined }))
                return
              }
              setKpiUserAppearance((old) => ({ ...old, fontSize: value }))
            }}
          />
        </div>
      ),
    },
    {
      key: 'diffVisible',
      label: (
        <Checkbox
          checked={kpiUserAppearance.diffVisible}
          onClick={() => {
            toggleViewType('diffVisible')
          }}
        >
          {t(`標準進捗との差分`)}
        </Checkbox>
      ),
    },
    {
      key: 'skipTotal',
      label: (
        <Checkbox
          checked={!(kpiUserAppearance.skipTotal ?? false)}
          onClick={() => {
            toggleViewType('skipTotal')
          }}
        >
          {t(`合計・小計を表示`)}
        </Checkbox>
      ),
    },
    {
      key: 'goalVisible',
      label: (
        <Checkbox
          checked={kpiUserAppearance.goalVisible}
          onClick={() => {
            toggleViewType('goalVisible')
          }}
        >
          {t(`目標を表示`)}
        </Checkbox>
      ),
    },
    {
      key: 'colorSchema',
      label: (
        <Checkbox
          checked={kpiUserAppearance.colorSchema === 'success-green'}
          onChange={(e) => {
            if (e.target.checked) {
              setKpiUserAppearance({ ...kpiUserAppearance, colorSchema: 'success-green' })
            } else {
              setKpiUserAppearance({ ...kpiUserAppearance, colorSchema: 'normal' })
            }
          }}
        >
          {t(`色を変更`)}
        </Checkbox>
      ),
    },
    {
      key: 'totalRowAndColumnOrder',
      label: (
        <Checkbox
          checked={kpiUserAppearance.totalRowOrder === 'top'}
          onChange={(e) => {
            if (e.target.checked) {
              setKpiUserAppearance({ ...kpiUserAppearance, totalRowOrder: 'top', totalColumnOrder: 'left' })
            } else {
              setKpiUserAppearance({ ...kpiUserAppearance, totalRowOrder: 'bottom', totalColumnOrder: 'right' })
            }
          }}
        >
          {t(`小計を先頭に表示`)}
        </Checkbox>
      ),
    },
    {
      key: 'perElapsedBusinessDays',
      label: (
        <Checkbox
          checked={kpiUserAppearance.perElapsedBusinessDays === true}
          onChange={(e) => {
            if (e.target.checked) {
              setKpiUserAppearance({ ...kpiUserAppearance, perElapsedBusinessDays: true })
            } else {
              setKpiUserAppearance({ ...kpiUserAppearance, perElapsedBusinessDays: false })
            }
          }}
        >
          {t(`1営業日あたりで表示`)}
        </Checkbox>
      ),
    },
  ]

  return (
    <Dropdown menu={{ items: menus }}>
      <Button icon={<EyeOutlined />} type="text" />
    </Dropdown>
  )
}

function FontSizeInput({ value, onChange }: { value?: number; onChange: (value: number | null) => void }) {
  const [isEditing, setIsEditing] = useState(false)

  return isEditing || isSome(value) ? (
    <InputNumber
      className="w-16"
      value={value}
      min={0}
      autoFocus
      onChange={onChange}
      onBlur={() => {
        setIsEditing(false)
      }}
    />
  ) : (
    <Input
      className="w-16"
      value={t('auto')}
      onFocus={() => {
        setIsEditing(true)
      }}
    />
  )
}
