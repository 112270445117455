import Mustache from 'mustache'
import { z } from 'zod'

import type { ViewQueryRecordNode } from '../../../schemas/query'
import type { DynamicSelectOptionConfig } from '../../../schemas/ui/ui'

export async function getDynamicSelectOptions({
  config,
  recordNode,
  fetch,
}: {
  config: DynamicSelectOptionConfig
  recordNode: ViewQueryRecordNode | undefined
  fetch: (sql: string) => Promise<unknown[]>
}) {
  // NOTE: configは今後unionにして、場合分けする予定
  const compiledSql = Mustache.render(config.sql, {
    record: recordNode,
  })
  const rows = await fetch(compiledSql)
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-explicit-any
  const row = rows.first() as any
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
  const values = row?.[config.valuesColumn]
  // stringのみを許可でいいだろうか？
  const stringValues = z.string().array().safeParse(values)
  if (stringValues.success) {
    return stringValues.data.map((value) => ({
      value,
      label: value,
    }))
  }
  // TODO
  return []
}
