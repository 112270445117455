import { Modal } from 'antd'
import { t } from 'i18next'

import { useNavigationModal } from '../../../../recoil/navigation/hooks'
import { useTreeMutations } from '../../../../recoil/view/mutations/treeMutations'
import { SearchSqlForm } from './SearchSqlForm'

export const NodeSearchSqlFormModal = () => {
  const { nodeSearchSqlModal } = useNavigationModal()
  const { editNode } = useTreeMutations()

  return (
    <Modal
      open={nodeSearchSqlModal.isModalVisible}
      onCancel={() => {
        nodeSearchSqlModal.hideModal()
      }}
      width={'90%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
      }}
      destroyOnClose
      footer={<></>}
    >
      {nodeSearchSqlModal.content !== undefined && (
        <SearchSqlForm
          title={t(`参照ブロックの検索設定`)}
          onAfterFinish={(sql: string) => {
            editNode({
              node: nodeSearchSqlModal.content!,
              f: (oldNode) => {
                oldNode.override = {
                  ...oldNode.override,
                  searchSql: sql,
                }
              },
            })
            nodeSearchSqlModal.hideModal()
          }}
          // TODO: デフォルトのSQLを表示
          sql={nodeSearchSqlModal.content.override?.searchSql ?? ''}
        />
      )}
    </Modal>
  )
}
