import type { DimensionGroup } from '../../schemas/misc/dimension_group'
import type { CoreModel } from '../../schemas/model/model'
import type { CoreView } from '../../schemas/View'
import type { ViewConfigAdditionalConfig } from '../../schemas/view_config'
import type { ModelSearcher } from './compileViewConfig/ModelSearcher'
import type { ViewSearcher } from './compileViewConfig/ViewSearcher'

export class CompileLog {
  private readonly errors: string[]
  private readonly warns: string[]
  private readonly logs: string[]

  public constructor() {
    this.errors = []
    this.warns = []
    this.logs = []
  }

  public warn(x: string) {
    this.warns.push(x)
  }

  public log(x: string) {
    this.logs.push(x)
  }

  public showWarns() {
    return this.warns
  }

  public error(x: string) {
    this.errors.push(x)
  }

  public showErrors() {
    return this.errors
  }
}

export interface OrganizationSetting {
  userGroupCategoryNames: string[]
  dimensionGroups: DimensionGroup[]
  accountClosingMonth: number
}

export interface CompileResources {
  view: Pick<CoreView, 'id' | 'name'> // 本来はconfigだけで完結させ、viewの情報そのものは不要な想定だったが、kpi周りで必要になった
  models: CoreModel[]
  views: CoreView[]
  organizationSetting: OrganizationSetting
}

export interface CompileContext {
  modelSearcher: ModelSearcher
  viewSearcher: ViewSearcher
  additionalConfig: ViewConfigAdditionalConfig | undefined
  logs: CompileLog
  resources: CompileResources
}
