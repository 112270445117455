import type { PolicyAction } from '../action'
import { managerRolePolicyDefinition } from './managerRolePolicyDefinition'

// 現場マネージャー：「組織設定、連携設定ができない組織管理者」のロール
// XXX: 各種ロールは定義の簡素化のため上位ロールを継承している。
//      権限を変更したら下位ロールにも影響する可能性があるため、継承関係を意識して変更すること
export const playingManagerRolePolicyDefinition: Record<PolicyAction, boolean> = {
  ...managerRolePolicyDefinition,
  [`manage-policy`]: false,
  [`manage-organization-setting`]: false,
  [`manage-connection`]: false,
}
