import { isPresent } from '@salescore/buff-common'
import { POSTHOG_EVENTS } from '@salescore/client-base'
import { generateLabelSqlFieldName, type ViewUiKpi } from '@salescore/core'
import { EChartPie } from '@salescore/frontend-common'
import { Empty } from 'antd'
import { t } from 'i18next'
import { useRecoilValue } from 'recoil'

import { chartDesignSettingsAtom, kpiPieChartCategoryFiltersAtom } from '../../../recoil/navigation/atoms'
import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { usePosthogTrackClick } from '../../../recoil/usePosthogTrack'
import { useViewValue } from '../../../recoil/view/hooks'
import { ChartCardWrapper, useRecords } from './KpiStackedChart'

export function KpiPieChart({ component }: { component: ViewUiKpi }) {
  const { records, key1, key2, csv } = useRecords({ component })
  const { drillDownModal } = useNavigationModal()
  const view = useViewValue()
  const posthogTrackClick = usePosthogTrackClick()
  const kpiPieChartCategoryFilters = useRecoilValue(kpiPieChartCategoryFiltersAtom)
  const {
    chartValueLabelDisplayMode,
    chartValueLabelUnitType,
    chartValueLabelDecimalPlaceType,
    chartLegendsDisplayMode,
  } = useRecoilValue(chartDesignSettingsAtom)

  if (component.pivot.rows.isEmpty()) {
    return <Empty description={t(`軸を指定してください`)} />
  }

  if (component.pivot.rows.length > 2) {
    return <Empty description={t(`グラフを描画するには、軸を1つ以上2つ以下にしてください`)} />
  }

  return (
    <ChartCardWrapper>
      <EChartPie
        title={view.name}
        onSeriesClick={(value) => {
          drillDownModal.showModal({
            type: 'kpi',
            dimensionValues: {
              rows: [
                {
                  value: value.key,
                  label: value.label,
                },
                isPresent(value.groupKey)
                  ? {
                      value: value.groupKey,
                      label: value.groupLabel,
                    }
                  : undefined,
              ].compact(),
              columns: [],
            },
          })
        }}
        records={records}
        ratioKey={key1}
        groupKey={key2}
        ratioLabelKey={generateLabelSqlFieldName(key1)}
        groupLabelKey={generateLabelSqlFieldName(key2)}
        groupFilters={kpiPieChartCategoryFilters.map((x) => x.key)}
        valueLabelDisplayMode={chartValueLabelDisplayMode}
        valueLabelUnitType={chartValueLabelUnitType}
        valueLabelDecimalPlaceType={chartValueLabelDecimalPlaceType}
        legendDisplayMode={chartLegendsDisplayMode}
        valueKey={JSON.stringify(['actual'])}
        csv={csv}
        onCsvDownloadClick={() => {
          posthogTrackClick(POSTHOG_EVENTS.click_kpi_pie_chart_csv_download)
        }}
        onImageDownloadClick={() => {
          posthogTrackClick(POSTHOG_EVENTS.click_kpi_pie_chart_image_download)
        }}
      />
    </ChartCardWrapper>
  )
}
