import type { ApolloClient } from '@apollo/client'
import type { FetchMeQuery } from '@salescore/client-api'
import { buildReadonlyHook } from '@salescore/frontend-common'
import { atom } from 'recoil'

export interface Me {
  myUser: FetchMeQuery['myUser']
  myIdentity: FetchMeQuery['myUser']['identity']
  organization: FetchMeQuery['myUser']['organization']
  isAdmin: boolean
  isAdminOrManager: boolean
  isPlayingManager: boolean
}

const prefix = `common`

export const meAtom = atom<Me | undefined>({
  key: `me`, // global側のmeと名前を統一している
  default: undefined,
})

export const organizationIdAtom = atom<string>({
  key: `${prefix}/organizationId`,
  default: '',
})

export const useOrganizationId = buildReadonlyHook(organizationIdAtom)

export const apolloClientAtom = atom<ApolloClient<unknown>>({
  key: 'apolloClient',
  default: undefined as never,
  dangerouslyAllowMutability: true,
})
