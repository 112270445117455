import type { NodePropertyName } from '../../../../schemas/view_config'

export function isSameNodeProperty(
  a: NodePropertyName | undefined,
  b: NodePropertyName | undefined,
  // デフォルトではmodelNameの一致は見ない
  shouldIgnoreModelNameMatch = true,
): boolean {
  if (a === undefined || b === undefined) {
    return false
  }

  const isMatchNodeNameAndPropertyName = b.nodeName === a.nodeName && b.propertyName === a.propertyName

  return shouldIgnoreModelNameMatch
    ? isMatchNodeNameAndPropertyName
    : isMatchNodeNameAndPropertyName && b.modelName === a.modelName
}
