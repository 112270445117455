import type { ViewConfigField } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { configSheetAtom } from '../../atoms'
import { meRelatedSelector } from '../../selectors/meRelatedSelector'
import { setConfigMutation } from '../setConfigMutation'
import { isSameNodeProperty } from './util'

//
// 別のシートのfieldsを、現在のviewのnodeにインポートする
// 引数のfieldsは、streamがnodeと等しいことを前提としている（TODO: このvalidationをUIレイヤでしかやっていないので、このレイヤでも行いたい）
//
export const addFieldsByFieldsMutation = mutation<{
  fields: ViewConfigField[]
  targetField: ViewConfigField
}>({
  key: `view/addFieldsByFieldsMutation`,
  set({ get, set }, { fields, targetField }) {
    const { permission, me } = get(meRelatedSelector)
    if (!permission.view.canEditField) {
      return // 本来辿り着かないはずなので、特にメッセージなどは出さない（出したいが、recoilの中でメッセージを表示させる機構が現状ない）
    }
    const config = get(configSheetAtom)
    const currentFields = config.fields ?? []
    const targetIndex = currentFields.findIndex((field) => isSameNodeProperty(field.property, targetField.property))

    const newFields = fields
      .map(
        (field): ViewConfigField => ({
          type: 'property',
          property: {
            nodeName: targetField.property.nodeName,
            modelName: field.property.modelName,
            propertyName: field.property.propertyName,
          },
          override: field.override,
        }),
      )
      .compact()
      .filter((field) => currentFields.every((x) => !isSameNodeProperty(x.property, field.property)))
    set(setConfigMutation, {
      ...config,
      fields: [...currentFields.slice(0, targetIndex + 1), ...newFields, ...currentFields.slice(targetIndex + 1)],
    })
  },
})
