import { useSuspenseQuery } from '@apollo/client'
import { isPresent } from '@salescore/buff-common'
import { FetchMeDocument, UserRoleEnumV2 } from '@salescore/client-api'
import type { Me } from '@salescore/client-recoil'
import { useRedirect } from '@salescore/frontend-common'
import type { ReactNode } from 'react'

import { Loading } from '../components/Loading'
import { getOrganizationIdFromPath } from './common/auth'
import { HandleQuery } from './common/handleQuery'

type Callback = ({ myUser, myIdentity, organization, isAdmin }: Me) => ReactNode

const Wrapper = ({ callback, organizationId }: { callback: Callback; organizationId: string }): ReactNode => {
  const {
    data: { myUser },
  } = useSuspenseQuery(FetchMeDocument, {
    variables: { organizationId },
    fetchPolicy: 'cache-first',
  })

  const { identity: myIdentity, organization } = myUser

  return callback({
    myUser,
    myIdentity,
    organization,
    isAdmin: myUser.role === UserRoleEnumV2.Admin,
    isAdminOrManager: myUser.role === UserRoleEnumV2.Manager || myUser.role === UserRoleEnumV2.Admin,
    isPlayingManager: myUser.role === UserRoleEnumV2.PlayingManager,
  })
}

export const handleMe = (callback: Callback): ReactNode => {
  const redirect = useRedirect()

  const organizationId = getOrganizationIdFromPath()
  if (!isPresent(organizationId)) {
    redirect('/')
    return <Loading />
  }

  return (
    <HandleQuery>
      <Wrapper callback={callback} organizationId={organizationId} />
    </HandleQuery>
  )
}
