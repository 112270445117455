import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { isPresent, isSome } from '@salescore/buff-common'
import type { ConvertingSalesforceLead } from '@salescore/client-api'
import { ConvertSalesforceLeadsDocument, FetchConvertingSalesforceLeadsDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { SanitizedHtml, SuspenseWithLoading } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import { useBooleanState, useModalAtom } from '@salescore/frontend-common'
import { Alert, Button, Card, Checkbox, Empty, Form, Input, message, Modal, Radio, Select, Spin } from 'antd'
import { t } from 'i18next'
import { type CSSProperties, useEffect, useRef, useState } from 'react'

import type { SearchResultRow } from '../../../domain/service/generateSearchSql'
import { useSearchQueue } from '../../../recoil/hooks/searchRelation'
import { convertLeadFormModalAtom } from '../../../recoil/navigation/atoms'
import { useRefetchMutation } from '../../../recoil/records/mutations/useRefetchMutation'

//
// リードの昇格（取引を開始）のフォーム
// 今後のことを考えて、複数のリードを一括で昇格できるようにしているが、実際のユースケースはまだ1件のみの想定で、複数の場合のUIは真面目に作っていない
//
export const ConvertLeadsFormModal = () => {
  const modal = useModalAtom(convertLeadFormModalAtom)
  return (
    <Modal
      open={modal.isModalVisible}
      onCancel={() => {
        modal.hideModal()
      }}
      width={'80%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      style={{
        // top: '3%',
        maxWidth: 1000,
        padding: 0,
      }}
      bodyStyle={
        {
          // backgroundColor: "#eee"
        }
      }
      // title={'集計方法の編集'}
      destroyOnClose
      footer={<></>}
    >
      {modal.content !== undefined && (
        <PageHeader title={<span>{t(`取引の開始`)}</span>}>
          <SuspenseWithLoading>
            <ConvertLeadForm
              leadIds={modal.content.leadIds}
              onFinish={() => {
                modal.content?.onFinish?.()
                modal.hideModal()
              }}
            />
          </SuspenseWithLoading>
        </PageHeader>
      )}
    </Modal>
  )
}

// create/udpateだけで必要な値、contactだけで必要な値などが混在しており、型としてあまり書き方がよくないが、実用的にこれがやりやすいのでこれで
interface CreateOrUpdate {
  type: 'create' | 'update'
  name?: string
  firstName?: string // contactの時だけ
  id?: string // updateの時に必須
  idLabel?: string // updateの表示で使う
  idMetaLabel?: string // updateの表示で使う
  doNotCreate?: boolean // 商談でのみ利用
}

interface Owner {
  id: string
  label: string
  imageUrl: string | undefined
}

interface ConvertingLeadFormState {
  account: CreateOrUpdate
  contact: CreateOrUpdate
  opportunity: CreateOrUpdate
  status: string
  current: ConvertingSalesforceLead
  owner?: {
    label: string
    value: string
  }
}

function ConvertLeadForm({ leadIds, onFinish }: { leadIds: string[]; onFinish: () => void }) {
  const { data } = useSuspenseQuery(FetchConvertingSalesforceLeadsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
      leadIds,
    },
  })
  const { leadStatuses, leads } = data.convertingSalesforceLeads
  const [convertSalesforceLeads] = useMutation(ConvertSalesforceLeadsDocument)
  const loading = useBooleanState()
  const [error, setError] = useState('')
  const recordsMutation = useRefetchMutation()
  const canConvertLead = recoil.global.useCan('convert-lead')
  const me = recoil.global.useMe()

  const [state, setState] = useState<ConvertingLeadFormState[]>(() =>
    leads
      .filter((lead) => !lead.isConverted)
      .map((lead) => ({
        current: lead,
        account: {
          type: 'create',
          name: lead.accountName ?? undefined,
          id: lead.candidateAccounts.first()?.id,
          idLabel: lead.candidateAccounts.first()?.name,
        },
        contact: {
          type: 'create',
          name: lead.lastName ?? undefined,
          firstName: lead.firstName ?? undefined,
          id: lead.candidateContacts.first()?.id,
          idLabel: [lead.candidateContacts.first()?.lastName, lead.candidateContacts.first()?.firstName]
            .compact()
            .join(' '),
        },
        opportunity: {
          type: 'create',
        },
        status: leadStatuses[0]!.value,
      })),
  )

  if (!canConvertLead) {
    return <Alert type="warning" message={<div>{t(`取引の開始をする権限がありません`)}</div>} />
  }

  if (leadStatuses.isBlank()) {
    return <Alert type="warning" message={<div>{t(`開始済みを示すリードのステータスが取得できませんでした`)}</div>} />
  }
  if (state.isBlank()) {
    if (leads.some((x) => x.isConverted)) {
      return (
        <Alert type="warning" message={<div>{t(`既に取引開始済みのリードであるため、取引の開始はできません`)}</div>} />
      )
    }
    // ありえないはず
    return <Alert type="warning" message={<div>{t(`リードが見つかりません`)}</div>} />
  }

  return (
    <>
      {leads.some((x) => x.isConverted) && (
        <Alert
          className="mb-6"
          type="warning"
          message={<div>{t(`既に取引開始済みのリードが含まれています。このリードは除外されます`)}</div>}
        />
      )}
      <Form
        onFinish={async () => {
          // validation
          // フォームが複雑なので、自前でvalidationする
          const errors = state.flatMap((lead) => {
            const xs = [] as string[]
            const doNotCreateOpportunity =
              lead.opportunity.doNotCreate ??
              !me.organization.setting.shouldCreateSalesforceOpportunityWhenConvertingLead

            if (lead.account.type === 'create' && !isPresent(lead.account.name)) {
              xs.push(t(`取引先名を入力してください`))
            }
            if (lead.contact.type === 'create' && !isPresent(lead.contact.name)) {
              xs.push(t(`取引先責任者の姓を入力してください`))
            }
            if (!doNotCreateOpportunity && lead.opportunity.type === 'create' && !isPresent(lead.opportunity.name)) {
              xs.push(t(`商談名を入力してください`))
            }

            if (lead.account.type === 'update' && !isPresent(lead.account.id)) {
              xs.push(t(`既存の取引先を入力してください`))
            }
            if (lead.contact.type === 'update' && !isPresent(lead.contact.id)) {
              xs.push(t(`既存の取引先責任者を入力してください`))
            }
            if (!doNotCreateOpportunity && lead.opportunity.type === 'update' && !isPresent(lead.opportunity.id)) {
              xs.push(t(`既存の商談を入力してください`))
            }
            return xs
          })
          if (errors.isPresent()) {
            setError(errors.join('。'))
            // antdのフォームをそのまま使っていないので、エラー文が残り続けるが、許容する
            return
          }
          setError('') // 一旦エラーをクリア

          try {
            loading.setTrue()
            const { data: result } = await convertSalesforceLeads({
              variables: {
                organizationId: getOrganizationIdFromPath(),
                inputs: state.map((lead) => ({
                  doNotCreateOpportunity:
                    lead.opportunity.doNotCreate ??
                    !me.organization.setting.shouldCreateSalesforceOpportunityWhenConvertingLead,
                  convertedStatus: lead.status,
                  leadId: lead.current.id,
                  accountId: lead.account.type === 'create' ? undefined : lead.account.id,
                  contactId: lead.contact.type === 'create' ? undefined : lead.contact.id,
                  ownerId: lead.owner?.value,
                  // nameは編集していなければundefinedを送る。（こうするとサーバーサイド側で不要な更新が発生せず、少し早い）
                  accountName: lead.current.accountName === lead.account.name ? undefined : lead.account.name,
                  contactLastName: lead.current.lastName === lead.contact.name ? undefined : lead.contact.name,
                  contactFirstName:
                    lead.current.firstName === lead.contact.firstName ? undefined : lead.contact.firstName,
                  opportunityName: lead.opportunity.name ?? undefined,
                })),
              },
            })
            // 成功したものが1つでもあれば、いったんrefetch
            const success = result?.convertSalesforceLeads.filter((x) => x.success) ?? []
            if (success.length > 0) {
              await recordsMutation.refetch({ kind: 'save' })
            }
            const errors = result?.convertSalesforceLeads.map((x) => (x.success ? undefined : x.errors)).compact() ?? []
            if (errors.isPresent()) {
              void message.error(t(`エラーが発生しました。`))
              setError(errors.unique().join(', '))
              return
            }
            void message.success(t(`取引を開始しました`))
            onFinish()
          } catch (error_) {
            if (error_ instanceof Error) {
              void message.error(error_.message)
            }
          } finally {
            loading.setFalse()
          }
        }}
      >
        {state.map((lead, index) => (
          <LeadFormItem
            lead={lead}
            leadStatuses={leadStatuses}
            shouldCreateSalesforceOpportunityWhenConvertingLead={
              me.organization.setting.shouldCreateSalesforceOpportunityWhenConvertingLead
            }
            onChange={(callback) => {
              setState((xs) => {
                const current = xs[index]!
                const newLead = callback(current)
                return [...xs.slice(0, index), newLead, ...xs.slice(index + 1)]
              })
            }}
          />
        ))}
        {/* htmlのエラーが返ってくることがあるので、SanitizedHtmlを使う */}
        {isPresent(error) && <Alert className="mb-6" type="error" message={<SanitizedHtml rawHtmlText={error} />} />}
        <div className="flex flex-row-reverse">
          <Form.Item>
            <Button htmlType="submit" type="primary" loading={loading.isTrue}>
              {t(`取引の開始`)}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </>
  )
}

function LeadFormItem({
  lead,
  leadStatuses,
  shouldCreateSalesforceOpportunityWhenConvertingLead,
  onChange,
}: {
  lead: ConvertingLeadFormState
  leadStatuses: Array<{ value: string; label: string }>
  shouldCreateSalesforceOpportunityWhenConvertingLead: boolean
  onChange: (callback: (current: ConvertingLeadFormState) => ConvertingLeadFormState) => void
}) {
  return (
    <div className="mb-6 rounded-lg border border-solid border-gray-200 p-4">
      <CreateOrUpdateFormItem
        key="account"
        label={t(`取引先`)}
        nameLabel={t(`取引先名`)}
        state={lead.account}
        searchSql={`SELECT id as value, name as label FROM salesforce_account WHERE {{# queries }} "name" LIKE '%{{ . }}%' AND {{/ queries }} TRUE`}
        candidates={lead.current.candidateAccounts.map((x) => ({
          value: x.id,
          label: x.name,
          render: () => (
            <div className="text-xs">
              <div className="mb-1">
                <span className="mr-1 text-gray-500">{t(`電話`)}</span>
                {x.phone ?? ''}
              </div>
              <div className="mb-1">
                <span className="mr-1 text-gray-500">{t(`住所`)}</span>
                {x.billingAddress ?? ''}
              </div>
              <div className="mb-1">
                <span className="mr-1 text-gray-500">{t(`Webサイト`)}</span>
                {x.website ?? ''}
              </div>
              <div className="mb-1">
                <span className="mr-1 text-gray-500">{t(`所有者`)}</span>
                {x.ownerName ?? ''}
              </div>
            </div>
          ),
        }))}
        onChange={(x) => {
          onChange((current) => ({
            ...current,
            account: x,
          }))
        }}
      />
      <CreateOrUpdateFormItem
        key="contact"
        label={t(`取引先責任者`)}
        nameLabel={t(`姓`)}
        state={lead.contact}
        searchSql={`SELECT salesforce_contact.id as value, salesforce_contact.name as label, salesforce_account.name as meta_label FROM salesforce_contact LEFT JOIN salesforce_account ON salesforce_account.id = salesforce_contact.account_id WHERE {{# queries }} (salesforce_contact.name LIKE '%{{ . }}%' OR salesforce_account.name LIKE '%{{ . }}%') AND {{/ queries }} TRUE`}
        searchDisabledMessage={
          lead.account.type === 'create' ? t(`取引先責任者を検索するには、既存の取引先を選択してください`) : undefined
        }
        candidates={lead.current.candidateContacts.map((x) => ({
          value: x.id,
          label: [x.lastName, x.firstName].compact().join(' '),
          render: () => (
            <div className="text-xs">
              <div className="mb-1">
                <span className="mr-1 text-gray-500">{t(`役職`)}</span>
                {x.title ?? ''}
              </div>
              <div className="mb-1">
                <span className="mr-1 text-gray-500">{t(`取引先名`)}</span>
                {x.accountName ?? ''}
              </div>
              <div className="mb-1">
                <span className="mr-1 text-gray-500">{t(`電話番号`)}</span>
                {x.phone ?? ''}
              </div>
              <div className="mb-1">
                <span className="mr-1 text-gray-500">{t(`メールアドレス`)}</span>
                {x.email ?? ''}
              </div>
              <div className="mb-1">
                <span className="mr-1 text-gray-500">{t(`所有者名`)}</span>
                {x.ownerName ?? ''}
              </div>
            </div>
          ),
        }))}
        onChange={(x) => {
          onChange((current) => ({
            ...current,
            contact: x,
          }))
        }}
      />
      {shouldCreateSalesforceOpportunityWhenConvertingLead ? (
        <CreateOrUpdateFormItem
          key="opportunity"
          label={t(`商談`)}
          nameLabel={t(`商談名`)}
          state={lead.opportunity}
          searchSql={`SELECT id as value, name as label FROM salesforce_opportunity WHERE {{# queries }} "name" LIKE '%{{ . }}%' AND {{/ queries }} account_id = '${
            lead.account.id ?? ''
          }'`}
          searchDisabledMessage={
            lead.account.type === 'update' && isSome(lead.account.id)
              ? undefined
              : t(`商談を検索するには、既存の取引先を選択してください`)
          }
          candidates={[]}
          onChange={(x) => {
            onChange((current) => ({
              ...current,
              opportunity: x,
            }))
          }}
        />
      ) : (
        <div className="my-2 text-gray-500">{t(`商談の作成はオフになっています`)}</div>
      )}
      <div className="mt-4 grid grid-cols-2">
        <Form.Item className="pr-4" label={t(`取引開始後の状況`)} required>
          <Select
            options={leadStatuses}
            value={lead.status}
            onChange={(x) => {
              onChange((current) => ({
                ...current,
                status: x,
              }))
            }}
          />
        </Form.Item>
        <Form.Item className="" label={t(`所有者`)} required style={{ width: '100%' }}>
          <RelationSelect
            style={{ width: '100%' }}
            disabled={false}
            current={
              lead.owner ?? {
                value: ``,
                label: t(`自分`),
              }
            }
            searchSql={`SELECT id as value, name as label FROM salesforce_user WHERE {{# queries }} "name" LIKE '%{{ . }}%' AND {{/ queries }} is_active = TRUE`}
            onChange={(x) => {
              onChange((current) => ({
                ...current,
                owner: x,
              }))
            }}
          />
        </Form.Item>
      </div>
    </div>
  )
}

function CreateOrUpdateFormItem({
  state,
  label,
  nameLabel,
  searchSql,
  candidates,
  searchDisabledMessage,
  onChange,
}: {
  state: CreateOrUpdate
  label: string
  nameLabel: string
  searchSql: string
  candidates: Array<{ value: string; label: string; render: () => JSX.Element }>
  searchDisabledMessage?: string
  onChange: (x: CreateOrUpdate) => void
}) {
  const updateDisabled = isPresent(searchDisabledMessage)

  useEffect(() => {
    if (updateDisabled && state.type !== 'create') {
      onChange({
        ...state,
        type: 'create',
      })
    }
  }, [updateDisabled])

  return (
    <div className="border-b-1 mb-6 border-x-0 border-t-0 border-solid border-gray-100 pb-6">
      <div className="text-md mb-2 font-bold">{label}</div>
      <div className="my-2 grid grid-cols-2 divide-x divide-y-0 divide-solid divide-gray-200">
        <div
          className={`px-3 ${state.type === 'create' ? '' : 'cursor-pointer'}`}
          onClick={() => {
            if (state.type !== 'create') {
              onChange({
                ...state,
                type: 'create',
              })
            }
          }}
        >
          <Radio
            className="mb-3"
            onClick={() => {
              onChange({
                ...state,
                type: 'create',
                doNotCreate: undefined,
              })
            }}
            checked={state.type === 'create' && state.doNotCreate !== true}
          >
            {t(`新規作成`)}
          </Radio>
          <Form.Item label={nameLabel} required>
            <Input
              disabled={state.type !== 'create' || state.doNotCreate === true}
              value={state.name}
              onChange={(e) => {
                onChange({
                  ...state,
                  name: e.target.value,
                })
              }}
            />
          </Form.Item>
          {label === t(`取引先責任者`) && (
            <Form.Item label={t(`名`)}>
              <Input
                disabled={state.type !== 'create'}
                value={state.firstName}
                onChange={(e) => {
                  onChange({
                    ...state,
                    firstName: e.target.value,
                  })
                }}
              />
            </Form.Item>
          )}
          {label === t(`商談`) && (
            <div>
              <Checkbox
                className=""
                checked={state.doNotCreate}
                onChange={(e) => {
                  onChange({
                    ...state,
                    doNotCreate: e.target.checked,
                  })
                }}
              >
                {t(`取引開始時、商談は作成しない`)}
              </Checkbox>
            </div>
          )}
        </div>
        <div
          className={`px-3 ${state.type !== 'update' && !updateDisabled ? 'cursor-pointer' : ''}`}
          onClick={() => {
            if (updateDisabled) {
              return
            }
            if (state.type !== 'update') {
              onChange({
                ...state,
                type: 'update',
              })
            }
          }}
        >
          {updateDisabled && <div className="mb-3 text-yellow-500">{searchDisabledMessage}</div>}

          <Radio
            className="mb-3"
            disabled={updateDisabled}
            onClick={() => {
              onChange({
                ...state,
                type: 'update',
              })
            }}
            checked={state.type === 'update'}
          >
            {t(`既存を選択`)}
          </Radio>
          <div className="w-full" style={{ width: '100%' }}>
            <RelationSelect
              style={{ width: '100%' }}
              defaultOptions={
                state.id === undefined
                  ? []
                  : [
                      {
                        key: ``,
                        value: state.id,
                        label: state.idLabel ?? state.id,
                      },
                    ]
              }
              disabled={state.type !== 'update' || updateDisabled}
              current={
                state.id === undefined
                  ? undefined
                  : {
                      value: state.id,
                      label: state.idLabel!, // TODO
                    }
              }
              searchSql={searchSql}
              onChange={(option) => {
                onChange({
                  ...state,
                  id: option.value,
                  idLabel: option.label,
                })
              }}
            />
            <div className="mt-4 border border-gray-200 p-2" style={{ opacity: state.type === 'update' ? 1 : 0.5 }}>
              <div className="mb-3 border-gray-500">
                {t(`{{length}}件の{{label}}の一致`, { length: candidates.length, label })}
              </div>

              {candidates.map((candidate) => (
                <Card
                  size="small"
                  title={
                    <Radio className="" checked={state.type === 'update' && state.id === candidate.value}>
                      {candidate.label}
                    </Radio>
                  }
                  onClick={() => {
                    if (updateDisabled) {
                      return
                    }
                    onChange({
                      ...state,
                      type: 'update',
                      id: candidate.value,
                      idLabel: candidate.label,
                    })
                  }}
                >
                  {candidate.render()}
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type SearchResultRowWithKey = SearchResultRow & {
  key: string
}

function RelationSelect({
  style,
  disabled,
  current,
  searchSql,
  defaultOptions,
  onChange,
}: {
  style?: CSSProperties
  disabled: boolean
  searchSql: string
  current: { value: string; label: string } | undefined
  defaultOptions?: SearchResultRowWithKey[]
  onChange: (option: { value: string; label: string }) => void
}) {
  const reference = useRef<HTMLInputElement>(null)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<SearchResultRowWithKey[]>(defaultOptions ?? [])
  const { queueSearch, search } = useSearchQueue({
    setLoading,
    setOptions: (options) => {
      setOptions(options.map((x) => ({ ...x, key: x.value })))
    },
  })

  useEffect(() => {
    if (options.length === 0) {
      void search(searchSql, '') // recordNodeが指定できるようになっているが、今回の検索SQLでは不要
    }

    setTimeout(() => {
      if (reference.current) {
        reference.current.focus()
      }
    }, 200)
  }, [])

  return (
    <Select
      disabled={disabled}
      loading={loading}
      allowClear
      placeholder={`${t(`検索`)}...`}
      // options={options}
      showSearch
      filterOption={false}
      onSearch={(searchKey) => {
        queueSearch(searchSql, searchKey)
      }}
      notFoundContent={loading ? <Spin size="small" /> : <Empty />}
      style={{
        minWidth: 120,
        ...style,
      }}
      value={current?.label}
      onChange={(value) => {
        const selectedOption = options.find((x) => x.value === value)
        if (selectedOption === undefined) {
          return
        }
        onChange(selectedOption)
      }}
    >
      {options.map((option) => (
        <Select.Option key={option.key} value={option.value}>
          {option.label}
          <span className="ml-2 text-gray-400">
            {isSome(option.meta_label) ? '- ' : ''}
            {option.meta_label}
          </span>
        </Select.Option>
      ))}
    </Select>
  )
}
