import type { ViewUICheckbox } from '@salescore/core'
import { Checkbox, message } from 'antd'
import { t } from 'i18next'
import { useContext, useState } from 'react'

import { useViewUIRecordNodeContext } from './contexts'
import { ViewUIContext } from './ViewUIComponent'

export function ViewUICheckboxC({ component }: { component: ViewUICheckbox }) {
  const { isEditMode } = useContext(ViewUIContext)
  const { getValue, onChange } = useViewUIRecordNodeContext()
  const value = getValue(component.fieldName)
  const [checked, setChecked] = useState(typeof value === 'boolean' ? value : false)

  // checkedを明示的に指定しないとチェックが表示されない
  return (
    <Checkbox
      checked={checked}
      value={checked}
      onChange={(v) => {
        setChecked((x) => !x)
        onChange({ fieldName: component.fieldName, value: !checked })
      }}
      onClick={() => {
        if (component.readonly === true) {
          void message.warning(t(`この項目は書き込みが禁止されています。`))
        }
      }}
      disabled={isEditMode || component.readonly}
    />
  )
}
