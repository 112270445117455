import type { FetchMeQuery } from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import { ellipsisFilter } from '@salescore/frontend-common'
import Link from 'next/link'

import { UserAvatar } from './avatar/UserAvatar'

export const SiderUserV2 = ({ myUser }: { myUser: FetchMeQuery['myUser'] }) => (
  <Link href={routes.mePathV2()} legacyBehavior>
    <div className="justify-left flex w-full cursor-pointer items-center px-4 py-3 align-middle hover:bg-slate-50 hover:opacity-90">
      <UserAvatar user={myUser} />
      <div style={{ width: 140 }} className="flex items-center justify-between align-middle">
        <div className="ml-2 text-sm" style={{ wordBreak: 'break-all' }}>
          {ellipsisFilter(myUser.name, 13)}
          <br />
          <span className="text-xs text-gray-500">{ellipsisFilter(myUser.identity.email, 20)}</span>
        </div>
      </div>
    </div>
  </Link>
)
