import { isNull } from '@salescore/buff-common'
import type { ViewQueryNode, ViewQueryRecordNode } from '@salescore/core'

import { convertRelationToNodeType } from '../../recoil/selectors/useSheetColumns'
import { getRecordNodeFromRootNode } from '../../state/nodeUtil'
import type { RSheetColumn, RSheetRecordNode } from '../types'

export interface RecordNodeWithInnerRowIndex {
  innerRowIndexStart: number
  innerRowIndexEnd: number
  recordNode: undefined | RSheetRecordNode
  parentRecordNode: undefined | RSheetRecordNode // rootのときにundefined
  height: number
}

export function getRecordNodesWithInnerRowIndex(
  row: RSheetRecordNode,
  node: ViewQueryNode,
): RecordNodeWithInnerRowIndex[] {
  const nodeType = convertRelationToNodeType(node)
  // TODO: なぜかrowがないことがありうるようなので、検査する
  if (isNull(row)) {
    return []
  }

  // rootのとき
  if (nodeType === 'root') {
    return [
      {
        innerRowIndexStart: 0,
        innerRowIndexEnd: row.meta.height,
        recordNode: row,
        parentRecordNode: undefined,
        height: row.meta.height,
      },
    ]
  }

  const parentRecordNodes = getParentRecordNodes(row, node.path)
  if (parentRecordNodes === undefined) {
    // 親が一切いない子など
    return []
  }

  const recordNodesWithIndex = parentRecordNodes.flatMap((parentRecordNode): RecordNodeWithInnerRowIndex[] => {
    // 子がいない場合、空セルを親の高さで作る
    if (parentRecordNode.children.isEmpty()) {
      return [
        {
          innerRowIndexStart: parentRecordNode.parent.meta.innerRowIndexStart,
          innerRowIndexEnd: parentRecordNode.parent.meta.innerRowIndexEnd,
          recordNode: undefined,
          parentRecordNode: parentRecordNode.parent,
          height: parentRecordNode.parent.meta.height,
        },
      ]
    }

    // parentな子が1つだけある場合、親の高さで作る
    if (nodeType === 'parent' && parentRecordNode.children.length === 1) {
      return [
        {
          innerRowIndexStart: parentRecordNode.parent.meta.innerRowIndexStart,
          innerRowIndexEnd: parentRecordNode.parent.meta.innerRowIndexEnd,
          recordNode: parentRecordNode.children.first()!,
          parentRecordNode: parentRecordNode.parent,
          height: parentRecordNode.parent.meta.height,
        },
      ]
    }

    return parentRecordNode.children.map((recordNode) => ({
      innerRowIndexStart: recordNode.meta.innerRowIndexStart,
      innerRowIndexEnd: recordNode.meta.innerRowIndexEnd,
      recordNode,
      parentRecordNode: parentRecordNode.parent,
      height: recordNode.meta.height,
    }))
  })

  // 親がいないセルに対して、blankCellを描画するために穴埋めする
  return addBlankCell(recordNodesWithIndex, row.meta.innerRowIndexEnd)
}

function addBlankCell(
  nodesWithIndex: RecordNodeWithInnerRowIndex[],
  rowInnerRowIndexEnd: number,
): RecordNodeWithInnerRowIndex[] {
  const result: RecordNodeWithInnerRowIndex[] = []
  for (const nodeWithIndex of nodesWithIndex) {
    const lastIndex = result.last()?.innerRowIndexEnd ?? 0
    if (nodeWithIndex.innerRowIndexStart === lastIndex) {
      result.push(nodeWithIndex)
    } else {
      const emptyCell: RecordNodeWithInnerRowIndex = {
        innerRowIndexStart: lastIndex,
        innerRowIndexEnd: nodeWithIndex.innerRowIndexStart,
        recordNode: undefined,
        parentRecordNode: undefined,
        height: nodeWithIndex.innerRowIndexStart - lastIndex,
      }
      result.push(emptyCell, nodeWithIndex)
    }
  }
  const lastIndex = result.last()?.innerRowIndexEnd
  if (lastIndex !== undefined && lastIndex !== rowInnerRowIndexEnd) {
    result.push({
      innerRowIndexStart: lastIndex,
      innerRowIndexEnd: rowInnerRowIndexEnd,
      recordNode: undefined,
      parentRecordNode: undefined,
      height: rowInnerRowIndexEnd - lastIndex,
    })
  }

  return result
}

function getParentRecordNodes(rootViewNodeRecord: ViewQueryRecordNode, nodePath: string[]) {
  const parentRecordNodes = getRecordNodeFromRootNode(rootViewNodeRecord, nodePath.slice(0, -1))
  if (parentRecordNodes === undefined) {
    // ルートのとき
    return
  }
  const xs = Array.isArray(parentRecordNodes) ? parentRecordNodes : [parentRecordNodes]
  return xs.map((x) => ({
    parent: x,
    children: x.children.find((tableNode) => tableNode.nodeName === nodePath.last()!)?.children ?? [],
  }))
}

export function getRecordNodeWithInnerRowIndex(
  row: RSheetRecordNode,
  node: ViewQueryNode,
  innerRowIndex: number,
): RecordNodeWithInnerRowIndex | undefined {
  const xs = getRecordNodesWithInnerRowIndex(row, node)
  return xs.find((x) => x.innerRowIndexStart <= innerRowIndex && innerRowIndex < x.innerRowIndexEnd)
}

export function getRecordNodeAttributeValue(
  data: RSheetRecordNode[],
  column?: RSheetColumn<RSheetRecordNode>,
  cursor?: {
    rowIndex: number
    columnIndex: number
    innerRowIndex: number
  },
): unknown {
  if (cursor === undefined || column === undefined) {
    return undefined
  }
  const row = data[cursor.rowIndex]
  if (row === undefined) {
    return undefined
  }
  const recordNode = getRecordNodeWithInnerRowIndex(row, column.node, cursor.innerRowIndex)?.recordNode
  if (recordNode === undefined) {
    return undefined
  }

  return column.value(recordNode)
}
