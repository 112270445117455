export function WallPaper() {
  return (
    <>
      <div
        className="absolute top-0"
        style={{
          zIndex: 0,
          height: '100vh',
          width: '100vw',
          // backgroundImage: `url("/images/sheets/mountain-sunrise-1.jpg")`,
          // backgroundImage: `url("https://ucarecdn.com/c457d6e1-fcfd-46d6-815a-f359ea1339e1/-/preview/2000x2000/")`,
          // backgroundImage: `url("https://ucarecdn.com/b93155fa-359a-499e-a11d-845b1eb98a65/-/preview/2000x2000/")`,
          // backgroundImage: `url("https://ucarecdn.com/b9b6ab9b-aa43-4faf-9938-598051328767/-/preview/2000x2000/")`,
          // backgroundImage: `url("https://ucarecdn.com/109a7f97-8390-42f8-8d2d-ef5d6b68ad24/-/preview/2000x2000/")`,
          // backgroundImage: `url("https://ucarecdn.com/33a93810-39b5-4402-a4a7-3e2755aac289/-/preview/2000x2000/")`,
          backgroundImage: `url("https://ucarecdn.com/5718752d-b809-45b4-a358-bfcbf4ca3d5c/-/preview/2000x2000/")`,
          backgroundSize: 'cover',
        }}
      />
      <div
        className="absolute top-0"
        style={{
          // backgroundColor: "red",
          background: 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0))',
          // background: 'radial-gradient(circle at 0% 0%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0) 200px)',
          height: 200,
          width: '100vw',
          zIndex: 0,
        }}
      />
    </>
  )
}
