import type { NodePropertyName, ViewConfigField, ViewQueryField } from '@salescore/core'

export function queryFieldToConfigField(queryField: ViewQueryField, configFields: ViewConfigField[]) {
  return configFields.find((configField) => isSameField(configField, queryField))
}

export function isSameField(a: ViewConfigField, b: ViewQueryField) {
  return isSameNodeProperty(a.property, {
    // TODO
    propertyName: b.meta.dependedPropertyNamesWithStreamName.first()?.propertyName ?? '',
    modelName: b.meta.dependedPropertyNamesWithStreamName.first()?.streamName ?? '',
    nodeName: b.nodePath.last()!,
  })
}

export function isSameNodeProperty(a: NodePropertyName, b: NodePropertyName) {
  return b.nodeName === a.nodeName && b.modelName === a.modelName && b.propertyName === a.propertyName
}
