import { isPresent } from '@salescore/buff-common'
import type { V2Organization } from '@salescore/client-api'
import { Avatar } from 'antd'

const COLOR_CLASSNAMES = ['bg-blue-500', 'bg-yellow-400', 'bg-red-400', 'bg-green-400', 'bg-purple-400']

export const OrganizationAvatar = ({
  organization,
}: {
  organization: Pick<V2Organization, 'imageUrl' | 'name' | '__typename'>
}) => {
  if (isPresent(organization.imageUrl)) {
    return <Avatar shape="square" src={organization.imageUrl} className="bg-white" />
  }
  const fixedName = organization.name.replace('株式会社', '')
  // eslint-disable-next-line unicorn/prefer-code-point,unicorn/prefer-spread
  const colorSeed = organization.name.split('').reduce((accumulator, x) => accumulator + x.charCodeAt(0), 0)
  const color = COLOR_CLASSNAMES[colorSeed % COLOR_CLASSNAMES.length]
  return (
    <Avatar shape="square" className={color}>
      {fixedName[0]}
    </Avatar>
  )
}
