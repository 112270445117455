import { getApolloContext } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { useSalescoreAuth } from '@salescore/client-base'
import { useRedirect } from '@salescore/frontend-common'
import { Button } from 'antd'
import { t } from 'i18next'
import { useContext } from 'react'
import { Trans } from 'react-i18next'

export const SignIn = () => {
  const { loginWithPopup } = useAuth0()
  const { clearToken } = useSalescoreAuth()
  const { client } = useContext(getApolloContext())
  const redirect = useRedirect()
  return (
    <>
      <Button
        block
        size="large"
        type="primary"
        onClick={async () => {
          clearToken() // salescore authのtokenが残っていると、Auth0でログインしたのにsalescore token側のログイン情報で表示されてしまうので、ここで一応消しておく（基本的にはありえない）
          await client?.resetStore() // 異なるidentityでログインするケースのためにリセットしておく
          await loginWithPopup()
          redirect(`/`)
        }}
      >
        {t(`ログイン`)}
      </Button>

      <div className="mt-8 text-center text-xs text-gray-500">
        <Trans>
          ログインできない場合などは、
          <br />
          お気軽にお問い合わせください。
        </Trans>
      </div>
    </>
  )
}
