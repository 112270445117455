import dayjs, { type Dayjs } from 'dayjs'

import type { GoalConfig } from './types'
import { validateDateByGoalConfig } from './util'

const generateDates = (startAt: Dayjs, endAt: Dayjs) => {
  const days = []
  let current = startAt.startOf('day')
  while (current.isBefore(endAt.endOf('day'))) {
    // 閉区間を期待し、endAtを含む。
    days.push(current)
    current = current.add(1, 'day').startOf('day')
  }
  return days
}

export function generateDateStringsForInitialize(
  startAt: Dayjs,
  endAt: Dayjs,
  timeSpanType: GoalConfig['timeSpanType'],
): string[] {
  const dates = generateDates(startAt, endAt)
  return (
    dates
      .map((x) => validateDateByGoalConfig(x.toDate(), timeSpanType))
      .compact()
      // format(YYYY-MM-DD)せずにtoISOString()すると、UTCになるので注意
      .map((x) => dayjs(x).format(`YYYY-MM-DD`))
  )
}
