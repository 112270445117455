import { useMutation, useQuery } from '@apollo/client'
import { isPresent } from '@salescore/buff-common'
import { CreateSourceDocument, FetchSourcesTableRecordsDocument, SourceProviderEnum } from '@salescore/client-api'
import { routes } from '@salescore/client-base'
import {
  CLIENT_COMMON_CONSTANT,
  getOrganizationIdFromPath,
  type OAuthExtraConfig,
  ProviderLogo,
  useOpenAuthorizationUrl,
} from '@salescore/client-common'
import { ButtonWithTooltip, useRedirect } from '@salescore/frontend-common'
import { App, Form, Table } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

import { GoogleSheetsSourceForm } from './source_forms/GoogleSheetsSourceForm'
import { type KintoneConfig, type KintonePasswordForm, KintoneSourceForm } from './source_forms/KintoneSourceForm'
import { MicrosoftDynamicsSourceForm } from './source_forms/MicrosoftDynamicsSourceForm'
import { SalesforceSourceForm } from './source_forms/SalesforceSourceForm'

// 仕組みの違う連携設定を一箇所でやっていて見通しが悪くなっていきているのでそろそろ見直しが必要
export const CreateSourceForm = ({ onFinish }: { onFinish: () => void }): JSX.Element => {
  const { message, modal } = App.useApp()
  const { openAuthorizationUrl, loadingProvider } = useOpenAuthorizationUrl()
  const [extraConfig, setExtraConfig] = useState<OAuthExtraConfig>({})
  const [createSourceMutation, { loading }] = useMutation(CreateSourceDocument)
  const redirect = useRedirect()
  const [kintoneForm] = Form.useForm<KintonePasswordForm>()
  const { data } = useQuery(FetchSourcesTableRecordsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
    fetchPolicy: 'cache-and-network',
  })

  const onClickForOauthSource = (provider: SourceProviderEnum): void => {
    if (
      (isPresent(extraConfig.clientId) || isPresent(extraConfig.clientSecret)) &&
      (!isPresent(extraConfig.clientId) || !isPresent(extraConfig.clientSecret))
    ) {
      void message.error(t(`クライアントID, クライアントシークレットを両方入力してください`))
      return
    }

    void openAuthorizationUrl({
      type: 'organizationSource',
      provider,
      extraConfig,
    })
  }

  const onClickForNotOauthSource = async (provider: SourceProviderEnum): Promise<void> => {
    const { name, ...config } = extraConfig
    if (provider === SourceProviderEnum.GoogleSheets && !isPresent(config.spreadsheetId)) {
      void message.warning(t(`URLを入力してください。`))
      return
    }
    await createSourceMutation({
      variables: {
        sourceInput: {
          organizationId: getOrganizationIdFromPath(),
          provider,
          name,
          config,
        },
      },
      onCompleted: (data) => {
        void message.success(t(`連携しました。続いて、同期項目を設定してください。`))
        if (data.createSource?.id !== undefined) {
          redirect(routes.connectionSourceSettingsPath(data.createSource.id))
        }
        onFinish()
      },
      onError: (error) => {
        if (
          provider === SourceProviderEnum.GoogleSheets &&
          error.message.startsWith(t(`シートにアクセスする権限がありません。`))
        ) {
          modal.error({
            content: <>{error.message}</>,
            width: 'min(80%, 1200px)',
          })
        } else {
          void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
        }
      },
    })
  }

  // 組織毎に連携できる同一SFAは基本的に1つのみとする
  const isConnectable = (provider: SourceProviderEnum): boolean => {
    if (provider === SourceProviderEnum.GoogleSheets) {
      return true
    }
    return data?.sourcesTableRecords.some((s) => s.connection.source.provider === provider) === false
  }

  return (
    <>
      <Table
        scroll={{ y: '70vh' }}
        dataSource={getIntegrationCandidates()}
        rowKey="provider"
        loading={loading}
        pagination={false}
        columns={[
          {
            title: t(`連携先`),
            render(_text, record) {
              return <ProviderLogo provider={record.provider} />
            },
          },
          {
            title: '',
            render(_text, record) {
              return (
                <ButtonWithTooltip
                  loading={record.provider === loadingProvider}
                  type="primary"
                  disabled={!isConnectable(record.provider)}
                  showTooltip={!isConnectable(record.provider)}
                  tooltipTitle={t(`同一SFAを複数設定することはできません。既存の連携設定を削除してください。`)}
                  onClick={() => {
                    switch (record.provider) {
                      case SourceProviderEnum.GoogleSheets: {
                        void onClickForNotOauthSource(record.provider)
                        return
                      }
                      case SourceProviderEnum.Kintone: {
                        kintoneForm.submit()
                        return
                      }
                      default: {
                        onClickForOauthSource(record.provider)
                      }
                    }
                  }}
                >
                  {t(`{{source}}と連携`, { source: CLIENT_COMMON_CONSTANT.i18n.ja.provider[record.provider] })}
                </ButtonWithTooltip>
              )
            },
          },
          {
            title: '',
            render(_text, record) {
              switch (record.provider) {
                case SourceProviderEnum.Salesforce: {
                  return <SalesforceSourceForm setExtraConfig={setExtraConfig} />
                }
                case SourceProviderEnum.GoogleSheets: {
                  return <GoogleSheetsSourceForm setExtraConfig={setExtraConfig} />
                }
                case SourceProviderEnum.MicrosoftDynamics: {
                  return <MicrosoftDynamicsSourceForm setExtraConfig={setExtraConfig} />
                }
                case SourceProviderEnum.Kintone: {
                  return (
                    <KintoneSourceForm
                      form={kintoneForm}
                      onFinish={(form: KintonePasswordForm): void => {
                        const config: KintoneConfig = {
                          host: form.host,
                          credentials: {
                            type: 'password',
                            id: form.id,
                            password: form.password,
                          },
                        }
                        void createSourceMutation({
                          variables: {
                            sourceInput: {
                              organizationId: getOrganizationIdFromPath(),
                              provider: SourceProviderEnum.Kintone,
                              config,
                            },
                          },
                          onCompleted: (data) => {
                            void message.success(t(`連携しました。続いて、同期項目を設定してください。`))
                            if (data.createSource?.id !== undefined) {
                              redirect(routes.connectionSourceSettingsPath(data.createSource.id))
                            }
                            onFinish()
                          },
                          onError: (error) => {
                            void message.error(`${t(`エラーが発生しました。`)}${error.message}`)
                          },
                        })
                      }}
                    />
                  )
                }
              }
              return <></>
            },
          },
        ]}
      />
    </>
  )
}

// TODO: enumにする？
function getIntegrationCandidates(): Array<{ provider: SourceProviderEnum }> {
  return [
    {
      provider: SourceProviderEnum.Salesforce,
    },
    {
      provider: SourceProviderEnum.Hubspot,
    },
    {
      provider: SourceProviderEnum.Zoho,
    },
    {
      provider: SourceProviderEnum.GoogleSheets,
    },
    {
      provider: SourceProviderEnum.MicrosoftDynamics,
    },
    [`4`, `29`, `20`, `b6256f6e-cafc-4718-9b2e-a67527b05910`, `1bba75be-a04c-42be-8d9a-a5d5f02ffaf8`].includes(
      getOrganizationIdFromPath(),
    )
      ? { provider: SourceProviderEnum.Zoom }
      : undefined,
    {
      provider: SourceProviderEnum.Kintone,
    },
  ].compact()
}
