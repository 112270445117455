import { isNull } from '@salescore/buff-common'
import type { UserFieldsFragment, UserPlanEnum } from '@salescore/client-api'
import { Space, Tag } from 'antd'
import { t } from 'i18next'

export const UserPlanTag = ({
  userOrInvitation,
}: {
  userOrInvitation: Pick<UserFieldsFragment, 'planForSync' | 'planForVis'> | undefined
}) => {
  if (isNull(userOrInvitation)) {
    return <></>
  }
  return (
    <Space>
      <PlanTag color={`blue`} plan={userOrInvitation.planForSync}>
        SYNC
      </PlanTag>
      <PlanTag color={`green`} plan={userOrInvitation.planForVis}>
        VISUALIZE
      </PlanTag>
    </Space>
  )
}

function PlanTag({ plan, children, color }: { plan: UserPlanEnum; children: string; color: string }) {
  if (plan === 'none') {
    return <></>
  }

  return (
    <Tag style={{ opacity: plan === 'standard' ? 1 : 0.7 }} color={color}>
      {children}
      {plan === 'free' ? t(`(無料)`) : ''}
    </Tag>
  )
}
