import type { CoreModel } from '../../../../../schemas/model/model'
import type { ViewQueryNode } from '../../../../../schemas/query'
import type { CompileContext } from '../../../common'

export const SEARCH_RESULT_VALUE_COLUMN_NAME = 'value'
export const SEARCH_RESULT_LABEL_COLUMN_NAME = 'label'
export const SEARCH_RESULT_META_LABEL_COLUMN_NAME = 'meta_label' // camelCaseを使うと、quoteし忘れた際に小文字になってしまい不具合となるため、snake_caseとする

export interface GenerateSearchSqlForStreamArgument {
  model: CoreModel
  parentModel: CoreModel
  parentNode: ViewQueryNode
  context: CompileContext
}

export function generateSearchSqlMustache(fieldSql: string) {
  // v0
  // const OLD_SEARCH_SQL_KEY_PLACEHOLDER = `{{ searchKey }}` // v0
  // return `${fieldSql} LIKE '%${OLD_SEARCH_SQL_KEY_PLACEHOLDER}%'`
  // v1
  // return `{{ '${fieldSql}' | search }}`
  return `{{#queries}}
${fieldSql} ILIKE '%{{.}}%' AND
{{/queries}}
TRUE`
}

export function orderByLength(sql: string, labelField: string) {
  return `${sql}
  ORDER BY LENGTH(${labelField})`
}
