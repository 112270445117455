import { type ApolloClient, useApolloClient, useMutation } from '@apollo/client'
import { isNull, isTruthy } from '@salescore/buff-common'
import {
  FetchAverageRenderDurationDocument,
  type FetchAverageRenderDurationQuery,
  RecordRenderDurationDocument,
} from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { NextTickRender } from '@salescore/client-common'
import { CORE_CONSTANT } from '@salescore/core'
import platform from 'platform'
import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { type DurationMetrics, oldestSyncedAtAtom } from '../../recoil/view/atoms'
import { useMeValue, useViewValue } from '../../recoil/view/hooks'
import { useCachedViewsSelector } from '../../recoil/view/selectors/cachedViewSelector'
import { useDurationMetricsSelector } from '../../recoil/view/selectors/durationMetricsSelector'

// DurationMetricsを計測するためのコンポーネント・hooks群

interface DurationTimerStartArguments {
  trigger: DurationMetrics['trigger']
  viewId: string
  organizationId: string
  // 基本的にはDurationMetricsの更新はviewsContextのupdateDurationMetricsListを使うが、
  // 一部、ViewLayoutなどViewsContextの初期化前にDurationTimerStartを設置する必要があったため、
  // 専用のupdaterを渡すことができるようにしている
  durationUpdater?: (function_: (oldMetricsList: DurationMetrics[]) => DurationMetrics[]) => void
}

export function DurationTimerStart(arguments_: DurationTimerStartArguments) {
  return (
    <NextTickRender>
      <TimerStartBody {...arguments_} />
    </NextTickRender>
  )
}

export function DurationTimerEnd() {
  return (
    <NextTickRender>
      <TimerEndBody />
    </NextTickRender>
  )
}

function TimerStartBody({ trigger, viewId, organizationId, durationUpdater }: DurationTimerStartArguments) {
  const client = useApolloClient()
  const { updateDurationMetricsList } = useDurationMetricsSelector()
  const updateDurations = durationUpdater ?? updateDurationMetricsList
  const oldestSyncedAt = useRecoilValue(oldestSyncedAtAtom)
  const { getCachedView, getShouldUseRefetchCache } = useCachedViewsSelector()
  const currentCachedView = getCachedView(viewId)
  const shouldUseRefetchCache = getShouldUseRefetchCache({ currentCachedView, oldestSyncedAt })

  // 計測開始時にdurationMetricsに新規のmetricsを追加し
  // 平均duration取得のクエリを発行する
  useEffect(() => {
    // drillDownのシートではviewIdにprefixがついた状態でdurationが記録されているので、
    // 新規に計測を開始する時にもMetricsにあらかじめprefixをつけておく
    const metricsViewId =
      trigger === 'drillDownModalOpened' ? CORE_CONSTANT.KPI_SHEET_DYNAMIC_VIEW_ID_PREFIX + viewId : viewId
    const newMetrics: DurationMetrics = {
      viewId: metricsViewId,
      startAt: performance.now(),
      trigger,
      isRefetchCacheUsed: shouldUseRefetchCache,
    }
    updateDurations?.((oldMetricsList) => [...oldMetricsList.filter((x) => x.viewId !== metricsViewId), newMetrics])

    void fetchAndSetAverageDuration({ viewId: metricsViewId, organizationId, client, updateDurations })
  }, [viewId, trigger])

  return <></>
}

function TimerEndBody() {
  const { myUser } = useMeValue()
  const view = useViewValue()
  const { organization } = useMeValue()
  const { findLastDurationMetrics, deleteDurationMetrics } = useDurationMetricsSelector()
  const [recordRenderDurationMutation] = useMutation(RecordRenderDurationDocument)

  const logBaseInfo = {
    email: myUser.identity.email || 'unknown@buffup.jp',
    organizationId: myUser.organization.id || 'Unknown',
    organizationName: myUser.organization.name || 'Unknown',
    userId: myUser.id || 'Unknown',
    userName: myUser.name || 'Unknown',
    viewId: view.id,
    viewName: view.name,
    viewType: view.config.type,
    platform: platform.toString(),
  }

  // 計測が終了したら、計測結果をmutationで送信して
  // 該当のmetricsを削除する
  useEffect(() => {
    const targetMetrics = findLastDurationMetrics(view.id)
    if (isNull(targetMetrics)) {
      return
    }

    const { startAt, isRefetchCacheUsed, viewId, trigger } = targetMetrics
    const endAt = performance.now()
    const viewType = view.config.type

    const duration = Math.floor(endAt - startAt)
    void recordRenderDurationMutation({
      variables: {
        organizationId: organization.id,
        renderDuration: {
          viewId,
          viewType,
          trigger,
          duration,
          isRecordsCached: isRefetchCacheUsed,
        },
      },
    })

    Posthog.track(POSTHOG_EVENTS.render_duration, {
      ...logBaseInfo,
      duration,
      trigger,
      isRecordsCached: isRefetchCacheUsed,
    })

    deleteDurationMetrics(viewId)
  }, [])

  return <></>
}

async function fetchAndSetAverageDuration({
  viewId,
  organizationId,
  client,
  updateDurations,
}: {
  viewId: string
  organizationId: string
  client: ApolloClient<object>
  updateDurations?: (function_: (oldMetricsList: DurationMetrics[]) => DurationMetrics[]) => void
}) {
  const { data } = await client.query<FetchAverageRenderDurationQuery>({
    query: FetchAverageRenderDurationDocument,
    variables: {
      organizationId,
      viewId,
    },
  })

  const { averageRenderDuration } = data

  const cachedDuration = averageRenderDuration.find((x) => isTruthy(x.isRecordsCached))
  const noCachedDuration = averageRenderDuration.find((x) => !isTruthy(x.isRecordsCached))

  updateDurations?.((oldMetricsList) =>
    oldMetricsList.map((x) =>
      x.viewId === viewId
        ? {
            ...x,
            viewId,
            cachedPredictedDuration: cachedDuration?.averageDuration ?? undefined,
            noCachedPredictedDuration: noCachedDuration?.averageDuration ?? undefined,
          }
        : x,
    ),
  )
}
