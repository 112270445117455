import { EyeOutlined } from '@ant-design/icons'
import type { EltModelFieldsFragment } from '@salescore/client-api'
import { App, Button } from 'antd'
import { t } from 'i18next'

import { ModelPropertiesModalContent } from './ModelPropertiesModal'

export const PropertyButton = ({ eltModel }: { eltModel: EltModelFieldsFragment }): JSX.Element => {
  const { modal } = App.useApp()

  return (
    <Button
      type="text"
      className="text-blue-500"
      icon={<EyeOutlined />}
      onClick={() => {
        void modal.info({
          maskClosable: true,
          width: 'min(80%, 1440px)',
          content: <ModelPropertiesModalContent eltModel={eltModel} />,
        })
      }}
    >
      {t(`詳細`)}
    </Button>
  )
}
