import { EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { isPresent } from '@salescore/buff-common'
import { HUB_PROPERTY_TYPE_ICONS, propertyTypeOptions } from '@salescore/client-common'
import type { ModelProperty, NodePropertyName } from '@salescore/core'
import { getColumnSearchProps } from '@salescore/frontend-common'
import { Button, Input, Row, Table } from 'antd'
import { t } from 'i18next'
import { useMemo, useState } from 'react'

import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'

// レンダリングのパフォーマンスが悪いため、一部に制限する
// TODO: virtualizeして良い感じにやりたい
const RENDER_ROW_LIMIT = 100

export const KpiFormSheetFieldsFormItemSelectorTable = () => {
  const { currentFields, currentNodeModels, currentNodeProperties, sheet, setSheetConfig, setFormModified } =
    useKpiFormSelectors()

  const [searchKey, setSearchKey] = useState('')
  const [withRenderLimit, setWithRenderLimit] = useState(true)

  //
  // やや動作が重いのでメモ化した。しかし動作が重いのはレンダリングのせいであり、あまり関係ないかも
  //
  const { filteredFields, selectedRowKeys, isLimited } = useMemo(() => {
    const filteredFields = isPresent(searchKey)
      ? currentNodeProperties.filter(
          (field) => field.property.label.includes(searchKey), // TODO
        )
      : currentNodeProperties
    const shouldLimitFields = withRenderLimit && filteredFields.length >= RENDER_ROW_LIMIT
    const limitedFields = shouldLimitFields ? filteredFields.slice(0, RENDER_ROW_LIMIT) : filteredFields
    const mapper = currentFields.map((x) => x.field.property).groupBy((x) => JSON.stringify(x)).data
    const checkFieldsExists = (x: NodePropertyName) => mapper[JSON.stringify(x)] !== undefined
    const limitedFieldsWithPicked = limitedFields.map((x) => ({
      ...x,
      isPicked: checkFieldsExists(x.nodeProeprtyName),
      key: JSON.stringify(x.nodeProeprtyName),
    }))
    const selectedRowKeys = limitedFieldsWithPicked.filter((x) => x.isPicked).map((x) => x.key)
    return {
      isLimited: shouldLimitFields,
      filteredFields: limitedFieldsWithPicked,
      selectedRowKeys,
      checkFieldsExists,
    }
  }, [currentNodeProperties, searchKey, withRenderLimit])

  return (
    <div>
      <Row className="mb-3" align="middle">
        <div className="mb-2 font-bold">{t(`選択可能な項目`)}</div>
        <Input.Search
          allowClear
          enterButton={false}
          className=""
          style={{ width: '100%' }}
          onChange={(e) => {
            setSearchKey(e.target.value)
          }}
        />
      </Row>
      <Table
        scroll={{ y: 550 }}
        dataSource={filteredFields}
        pagination={false}
        size="small"
        rowClassName={(record) =>
          // const isPicked = record.isPicked
          `cursor-pointer bg-white hover:opacity-70`
        }
        onRow={(record, rowIndex) => ({
          onClick() {
            const result = sheet.togglePropertyField(record.nodeProeprtyName)
            setSheetConfig(result.config)
            setFormModified(true)
          },
        })}
        rowSelection={{
          selectedRowKeys,
          onSelect: (record) => {
            const result = sheet.togglePropertyField(record.nodeProeprtyName)
            setSheetConfig(result.config)
            setFormModified(true)
          },
        }}
        columns={[
          {
            title: <span className="text-xs">{t(`関連付け`)}</span>,
            dataIndex: 'nodeLabel',
            width: 110,
            filters: currentNodeModels
              .map((node) => ({
                text: node.nodePathAsLabel.join(': '),
                value: node.node.name,
              }))
              .uniqueBy((x) => x.value),
            onFilter: (value, field) => field.node.node.name === value,
            render(_, field) {
              return <span className="text-xs">{field.node.nodePathAsLabel.join(': ')}</span>
            },
          },
          {
            title: <span className="text-xs">{t(`テーブル名`)}</span>,
            dataIndex: 'nodeLabel',
            width: 110,
            filters: currentNodeModels
              .map((node) => ({
                text: node.model.label,
                value: node.model.name,
              }))
              .uniqueBy((x) => x.value),
            onFilter: (value, field) => field.node.model.name === value,
            render(_, field) {
              return <span className="text-xs">{field.node.model.label}</span>
            },
          },
          {
            title: t(`型`),
            dataIndex: 'propertyType',
            width: 50,
            filters: propertyTypeOptions.map((x) => ({
              value: x.value,
              text: x.label,
            })),
            onFilter: (value, field) => field.property.type === value,
            render(_, field) {
              return <span>{HUB_PROPERTY_TYPE_ICONS[field.property.type]}</span>
            },
          },
          {
            dataIndex: 'propertyLabel',
            title: t(`項目名`),
            ...getColumnSearchProps((x: { property: ModelProperty }) => x.property.label),
            render(_, field) {
              return <span>{field.property.label}</span>
            },
          },
          {
            dataIndex: 'addButton',
            width: 80,
            render(_, field) {
              return (
                <span>
                  <Button type="text" size="small" icon={<PlusOutlined />} className="text-blue-600">
                    {t(`選択`)}
                  </Button>
                </span>
              )
            },
          },
        ]}
      />
      {isLimited && (
        <div className="font-gray-500 mt-3 text-xs">
          {t(`※項目数が多すぎるため、一部の項目のみを表示しています。`)}{' '}
          <Button
            icon={<EyeOutlined />}
            style={{ fontSize: 10 }}
            size="small"
            type="text"
            onClick={() => {
              setWithRenderLimit(false)
            }}
          >
            {t(`全てを表示`)}
          </Button>
        </div>
      )}
    </div>
  )
}
