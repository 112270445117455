import type { ViewConfigField, ViewConfigMeasure } from '@salescore/core'
import { mutation } from '@salescore/frontend-common'

import { configSheetAtom } from '../../atoms'
import { setFieldMutation } from './setFieldMutation'

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type Argument = {
  field: ViewConfigField
  measure: ViewConfigMeasure
}

export const addMeasureMutation = mutation<Argument>({
  key: `view/addMeasureMutation`,
  set({ get, set }, { field, measure }) {
    const config = get(configSheetAtom)
    const { fields } = config
    // const configField = fields?.find((x) => isSameField(x, field))
    // if (configField === undefined) {
    //   return
    // }

    const newField: ViewConfigField = {
      ...field,
      measures: (field.measures ?? []).upsertBy(measure, (x) => x.name),
    }
    set(setFieldMutation, { field: newField })
  },
})

export const removeMeasureMutation = mutation<{ field: ViewConfigField; measureName: string }>({
  key: `view/removeMeasureMutation`,
  set({ get, set }, { field, measureName }) {
    // TODO: measureの削除時に、kpi(とkpiPivot)からも削除をするオプションとUI
    const config = get(configSheetAtom)
    const { fields } = config
    // const configField = fields?.find((x) => isSameField(x, field))
    // if (configField === undefined) {
    //   return
    // }

    const newField: ViewConfigField = {
      ...field,
      measures: (field.measures ?? []).filter((x) => x.name !== measureName),
    }
    set(setFieldMutation, { field: newField })
  },
})
