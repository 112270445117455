import { Button, message, Select, Table } from 'antd'
import type * as React from 'react'
import { Suspense, useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { Loading } from './Loading'

type FallbackType = 'default' | 'table' | 'select' | 'button' | 'none' | 'custom'

const ErrorBoundaryForSuspense: React.FC<{ children: JSX.Element; type?: FallbackType }> = ({ children, type }) => (
  <ErrorBoundary
    FallbackComponent={({ error, resetErrorBoundary }) => {
      useEffect(() => {
        // 認証前に呼ばれることがあるためi18n対応しない
        void message.error(`エラーが発生しました。${(error as Error).message}`)
      }, [])
      return (
        <div className="text-red-500">
          <p>エラーが発生しました。{(error as Error).message}</p>
          {/* <button onClick={resetErrorBoundary}>Try again</button> */}
        </div>
      )
    }}
    onReset={() => {
      // reset the state of your app so the error doesn't happen again
    }}
  >
    {children}
  </ErrorBoundary>
)

//
// ApolloProviderやRecoilRootよりも上の要素で使っているので、これらの要素に依存させないこと
// （依存させたい場合、DefaultProividerのロジックを変更すること）
//
export const SuspenseWithLoading: React.FC<{
  children: JSX.Element
  type?: FallbackType
  loadingText?: string
  loadingElement?: JSX.Element
}> = ({ children, type, loadingText, loadingElement }) => {
  switch (type) {
    case 'table': {
      return (
        <ErrorBoundaryForSuspense type={type}>
          <Suspense fallback={<Table loading />}>{children}</Suspense>
        </ErrorBoundaryForSuspense>
      )
    }
    case 'select': {
      return (
        <ErrorBoundaryForSuspense type={type}>
          <Suspense fallback={<Select loading />}>{children}</Suspense>
        </ErrorBoundaryForSuspense>
      )
    }
    case 'button': {
      return (
        <ErrorBoundaryForSuspense type={type}>
          <Suspense fallback={<Button loading>{loadingText ?? ''}</Button>}>{children}</Suspense>
        </ErrorBoundaryForSuspense>
      )
    }
    case 'none': {
      // ErrorBoundaryForSuspenseを使うだけ
      // このtypeを選択するなら、わざわざこのコンポーネントを使う意味があまりないが、共通化のため一応定義しておく
      return (
        <ErrorBoundaryForSuspense type={type}>
          <Suspense>{children}</Suspense>
        </ErrorBoundaryForSuspense>
      )
    }
    case 'custom': {
      return (
        <ErrorBoundaryForSuspense type={type}>
          <Suspense fallback={loadingElement}>{children}</Suspense>
        </ErrorBoundaryForSuspense>
      )
    }
  }

  return (
    <ErrorBoundaryForSuspense type={type}>
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </ErrorBoundaryForSuspense>
  )
}
