import type { WaterfallDimension } from '@salescore/core'
import { t } from 'i18next'

export type WaterfallDimensionGroupType = 'newOrLost' | 'timelineShift' | 'amountChange' | 'probabilityChange'

export interface WaterfallDimensionGroupOption {
  label: string
  value: WaterfallDimensionGroupType
  items: Array<{
    name: WaterfallDimension['name']
    label: string
  }>
}

export const waterfallDimensionGroupOptions: WaterfallDimensionGroupOption[] = [
  {
    label: t('新規・失注案件'),
    value: 'newOrLost',
    items: [
      { name: 'newOpportunity', label: t('新規案件') },
      { name: 'lostOpportunity', label: t('失注案件') },
    ],
  },
  {
    label: t('時期前後'),
    value: 'timelineShift',
    items: [
      { name: 'timelineAdvanced', label: t('時期前倒し') },
      { name: 'timelineDelayed', label: t('時期後ろ倒し') },
    ],
  },
  {
    label: t('金額増減'),
    value: 'amountChange',
    items: [
      { name: 'amountIncreased', label: t('金額増加') },
      { name: 'amountDecreased', label: t('金額減少') },
    ],
  },
  {
    label: t('確度増減'),
    value: 'probabilityChange',
    items: [
      { name: 'probabilityIncreased', label: t('確度増加') },
      { name: 'probabilityDecreased', label: t('確度減少') },
    ],
  },
]
