import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { compact, isPresent } from '@salescore/buff-common'
import {
  type FetchInvitationsQuery,
  IdentityProviderEnum,
  type SubscriptionStatusFieldsFragment,
  UpsertInvitationsDocument,
  UserRoleEnumV2,
} from '@salescore/client-api'
import { FetchPolicyUserRolesDocument } from '@salescore/client-api'
import { ErrorContext, getOrganizationIdFromPath } from '@salescore/client-common'
import { Alert, Button, Checkbox, Form, Input, message, Row, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useContext, useState } from 'react'

interface FormValue {
  emails: string
  provider: IdentityProviderEnum
  role: UserRoleEnumV2
  // planForSync: boolean
  // planForVis: boolean
}

export const USER_ROLE_JA: Record<UserRoleEnumV2, string> = {
  [UserRoleEnumV2.Member]: t(`一般ユーザー`),
  [UserRoleEnumV2.PlayingManager]: t(`現場マネージャー`),
  [UserRoleEnumV2.Manager]: t(`組織管理者`),
  [UserRoleEnumV2.Guest]: t(`ゲスト`),
  [UserRoleEnumV2.NecMember]: t(`一般ユーザー`), // NEC様向けのロール
  [UserRoleEnumV2.NxMember]: t(`一般ユーザー`), // NX様向けのロール
  [UserRoleEnumV2.NecPlayingManager]: t(`現場マネージャー`), // NEC様向けのロール
  [UserRoleEnumV2.ReadonlyMember]: t(`閲覧ユーザー`),
  [UserRoleEnumV2.Admin]: '', // SALESCOREのCS
}

export const createUserRoleOptions = () => {
  const oid = getOrganizationIdFromPath()
  const roles = [`01b96215-d257-453e-9a64-cd1eb777d258`, `636883ec-aa5c-463d-916d-b0cf1152b965`, `29`].includes(oid)
    ? [
        UserRoleEnumV2.NecMember,
        UserRoleEnumV2.NecPlayingManager,
        UserRoleEnumV2.Manager,
        UserRoleEnumV2.ReadonlyMember,
      ]
    : [
          `6d0f2828-8218-4c29-ae1e-2892c0bc5e77`,
          `410dad2d-7705-4abc-a986-f82a5bccc562`,
          `4636aa05-cf7c-472a-8160-06c396c567ba`,
          `e67bc5cf-650e-43c2-b34f-de10ec18588c`,
          `dc1915af-d91a-41d6-bd59-1f64ec6e6a8b`,
        ].includes(oid)
      ? [UserRoleEnumV2.NxMember, UserRoleEnumV2.PlayingManager, UserRoleEnumV2.Manager]
      : [
          UserRoleEnumV2.Member,
          UserRoleEnumV2.PlayingManager,
          UserRoleEnumV2.Manager,
          // UserRoleEnumV2.Guest, // 2023/04 deprecated
        ]

  // カスタム権限を取得
  const { data: policyUserRoles } = useSuspenseQuery(FetchPolicyUserRolesDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const customRoles = policyUserRoles.policyUserRoles.map((x) => ({
    value: x.name,
    label: x.label,
  }))

  return [
    ...roles.map((x: UserRoleEnumV2) => ({
      value: x,
      label: USER_ROLE_JA[x],
    })),
    ...customRoles,
  ]
}

export const USER_PROVIDER_JA: Record<IdentityProviderEnum, string> = {
  googleOauth2: t(`Gmailアカウント`),
  auth0: t(`パスワード`),
  // 'auth0_or_google-oauth2': 'gmailもしくはパスワード',
  hubspot: 'Hubspot',
  salesforce: 'Salesforce',
  zoho: 'ZOHO',
  saml: 'SAML',
}

const validate = (
  emails: string[],
  provider: IdentityProviderEnum,
  users: FetchInvitationsQuery['users'],
  invitations: FetchInvitationsQuery['invitations'],
) => {
  const signedInEmails = new Set(
    users
      .map((x) => x.identity)
      .filter((x) => x.provider === provider)
      .map((x) => x.email),
  )
  const invitedEmails = new Set(invitations.filter((x) => x.provider === provider).map((x) => x.email))
  const errors = emails.map((email) => {
    // TODO: メールアドレスのバリデーション
    if (signedInEmails.has(email)) {
      return `${t(`{{email}}は既にアカウントが存在します(ログイン方法`, { email })} : ${USER_PROVIDER_JA[provider]})`
    }
    if (invitedEmails.has(email)) {
      return `${t(`{{email}}は既に招待済みです(ログイン方法`, { email })} : ${USER_PROVIDER_JA[provider]})`
    }
    // if (!/.*@.*\..*/.test(email)) {
    //   return `${email}は有効なメールアドレスではありません`
    // }
  })
  return compact(errors)
}

export const CreateInvitationForm = ({
  onAfterFinish,
  users,
  invitations,
  subscriptionStatus,
}: {
  onAfterFinish: () => Promise<void>
  users: FetchInvitationsQuery['users']
  invitations: FetchInvitationsQuery['invitations']
  subscriptionStatus: SubscriptionStatusFieldsFragment[]
}) => {
  const selectableProviders: IdentityProviderEnum[] = [
    IdentityProviderEnum.GoogleOauth2,
    IdentityProviderEnum.Auth0,
    IdentityProviderEnum.Salesforce,
    IdentityProviderEnum.Hubspot,
    IdentityProviderEnum.Zoho,
    IdentityProviderEnum.Saml,
  ]
  const options = createUserRoleOptions()
  const [form] = useForm()
  const [upsertInvitationsMutation] = useMutation(UpsertInvitationsDocument)
  const [loading, setLoading] = useState(false)
  const [emails, setEmails] = useState<string[]>([])
  const syncStatus = subscriptionStatus.find((x) => x.subscription.plan === 'sync') ?? {
    numberOfRestSeats: 0,
    numberOfSeats: 0,
    numberOfUsers: 0,
    numberOfInvitations: 0,
    isInvitationFreeOrganization: false,
  } // TODO
  const visStatus = subscriptionStatus.find((x) => x.subscription.plan === 'visualize') ?? {
    numberOfRestSeats: 0,
    numberOfSeats: 0,
    numberOfUsers: 0,
    numberOfInvitations: 0,
    isInvitationFreeOrganization: false,
  } // TODO
  const [plan, setPlan] = useState({
    planForSync: syncStatus.numberOfRestSeats > 0,
    planForVis: visStatus.numberOfRestSeats > 0,
  }) // TODO: デフォルト値
  const initialValues = {
    role: options[0]!.value,
    provider: selectableProviders[0]!,
    planForSync: syncStatus.numberOfRestSeats > 0,
    planForVis: visStatus.numberOfRestSeats > 0,
  }

  const [provider, setProvider] = useState<IdentityProviderEnum>(initialValues.provider)
  const errorContext = useContext(ErrorContext)
  const errors = validate(emails, provider, users, invitations)
  const isExceededNumberOfRestSeatsForSync =
    plan.planForSync && emails.length > syncStatus.numberOfRestSeats && !syncStatus.isInvitationFreeOrganization
  const isExceededNumberOfRestSeatsForVis =
    plan.planForVis && emails.length > visStatus.numberOfRestSeats && !visStatus.isInvitationFreeOrganization

  const onFinish = async (values: FormValue) => {
    if (errors.length > 0) {
      void message.error(t(`メールアドレスのエラーを解消してください`))
      return
    }
    if (isExceededNumberOfRestSeatsForSync || isExceededNumberOfRestSeatsForVis) {
      void message.error(t(`契約ライセンス数を超えて招待をすることはできません`))
      return
    }

    if (!plan.planForSync && !plan.planForVis) {
      void message.error(t(`SYNC,VISUALIZEのどちらかのライセンスをチェックしてください`))
      return
    }

    try {
      setLoading(true)
      await upsertInvitationsMutation({
        variables: {
          organizationId: getOrganizationIdFromPath(),
          invitations: emails.map((email) => ({
            email,
            provider: values.provider,
            role: values.role,
            planForSync: plan.planForSync,
            planForVis: plan.planForVis,
          })),
        },
      })
      void message.info(t(`ユーザーを招待しました`))
      setEmails([])
      form.resetFields()
      await onAfterFinish()
    } catch (error) {
      void message.error(t(`招待中にエラーが発生しました`))
      errorContext.throwErrors(error as Error)
      await onAfterFinish()
    } finally {
      setLoading(false)
    }
  }

  return (
    <PageHeader title={t(`ユーザーを招待`)}>
      {errors.map((error) => (
        <Alert message={error} type="error" className="mb-2" />
      ))}
      <Alert
        message={<div>{t(`招待時、招待したユーザーにメールは送信されません。ご注意ください。`)}</div>}
        type="warning"
        className="mb-4"
        showIcon
      />
      {(isExceededNumberOfRestSeatsForSync || isExceededNumberOfRestSeatsForVis) && (
        <Alert
          message={
            <div>
              {t(`契約ライセンス数を超えています。`)}
              <br />
              {t(`ライセンス数の追加をご希望の場合は、お手数ですが営業担当者までお声がけください。`)}
              <br />
              {t(`現在の契約中ライセンス数`)}(sync): {syncStatus.numberOfSeats} <br />
              {t(`現在の契約中ライセンス数`)}(visualize): {visStatus.numberOfSeats} <br />
              {t(`現在のユーザー数(+招待数)`)}: {syncStatus.numberOfUsers}(+{syncStatus.numberOfInvitations}) <br />
            </div>
          }
          type="error"
          className="mb-2"
        />
      )}

      <Form form={form} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
        <Form.Item
          name="emails"
          label={t(`メールアドレス(複数の場合は改行して入力)`)}
          rules={[{ required: true, message: t(`メールアドレスを入力してください`) }]}
        >
          <Input.TextArea
            rows={4}
            onChange={(e) => {
              const text = e.target.value
              const emails = text
                .split('\n')
                .filter((email) => isPresent(email))
                .map((email) => email.trim())
                .unique() // 重複しているとサーバー側でエラーになる
              setEmails(emails)
            }}
          />
        </Form.Item>
        <Form.Item name="role" label={t(`権限`)} rules={[{ required: true, message: t(`権限を入力してください`) }]}>
          <Select
            options={options}
            optionRender={(option) => <span className="whitespace-normal break-words ">{option.label}</span>}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 4 }}
          name="planForSync"
          label={t(`SYNCライセンス`)}
          valuePropName="checked"
          rules={[{ required: true, message: t(`ライセンスを入力してください`) }]}
        >
          <Checkbox
            disabled={syncStatus.numberOfRestSeats === 0 || visStatus.numberOfRestSeats === 0}
            value={plan.planForSync}
            onChange={(e) => {
              setPlan((x) => ({
                ...x,
                planForSync: e.target.checked,
              }))
            }}
          />
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 4 }}
          name="planForVis"
          label={t(`VISUALIZEライセンス`)}
          valuePropName="checked"
          rules={[{ required: true, message: t(`ライセンスを入力してください`) }]}
        >
          <Checkbox
            disabled={syncStatus.numberOfRestSeats === 0 || visStatus.numberOfRestSeats === 0}
            value={plan.planForVis}
            onChange={(e) => {
              setPlan((x) => ({
                ...x,
                planForVis: e.target.checked,
              }))
            }}
          />
        </Form.Item>
        <Form.Item
          name="provider"
          label={t(`ログイン方法`)}
          rules={[{ required: true, message: t(`ログイン方法を入力してください`) }]}
        >
          {/* TODO: salesforceなど、integrationsをみて判断 */}
          <Select
            options={selectableProviders.map((provider) => ({
              value: provider,
              label: USER_PROVIDER_JA[provider],
            }))}
            onChange={(e) => {
              setProvider(e as IdentityProviderEnum)
            }}
          />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button type="primary" htmlType="submit" loading={loading} disabled={errors.length > 0}>
              {t(`招待`)}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </PageHeader>
  )
}
