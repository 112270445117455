import { selector, useRecoilValue } from 'recoil'

import { meAtom } from '../atoms'

export const meRelatedSelector = selector({
  key: `view/meRelatedSelector`,
  get({ get }) {
    const me = get(meAtom)

    return {
      me,
      permission: {
        view: {
          canEditField: me.isAdminOrManager || me.organization.id !== 'ac5fb7f8-4974-4ac4-9bcb-173027f1b8c7',
          // 他の条件も追加予定
        },
      },
    }
  },
})

export const useMeRelatedSelector = () => useRecoilValue(meRelatedSelector)
