import { atom, useRecoilState } from 'recoil'

import { useCanForFeatures } from '../policy/useCanForFeature'
import { prefix } from './launcherAtom'
import type { LauncherCommandType } from './launcherCommandV2'

const launcherVisibilityAtom = atom<boolean>({
  key: `${prefix}/launcherVisibilityAtom`,
  default: false,
})
const launcherCommandFilterTypeAtom = atom<LauncherCommandType>({
  key: `${prefix}/launcherCommandFilterTypeAtom`,
  default: 'all',
})

export const useLauncher = () => {
  const [visibility, setVisibility] = useRecoilState(launcherVisibilityAtom)
  const [filterType, setFilterType] = useRecoilState(launcherCommandFilterTypeAtom)
  const ability = useCanForFeatures()

  return {
    visibility,
    filterType,
    setFilterType,
    moveNextFilter: (option?: { reverse?: boolean }) => {
      const filtersRaw: Array<LauncherCommandType | undefined> = [
        'all',
        'sheet',
        ability.canOpenKpi ? `kpi` : undefined,
        'kpiPivot',
        'page',
        'archived',
      ]
      const filters = filtersRaw.compact()
      const currentFilterIndex = filters.indexOf(filterType)
      const d = option?.reverse === true ? filters.length - 1 : 1
      const nextFilter = filters[(currentFilterIndex + d) % filters.length] ?? `all`
      setFilterType(nextFilter)
    },
    open: (option?: { filterType?: LauncherCommandType }) => {
      setVisibility(true)
      if (option?.filterType !== undefined) {
        setFilterType(option.filterType)
      }
    },
    hide: () => {
      setVisibility(false)
    },
  }
}
