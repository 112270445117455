import { PageHeader } from '@ant-design/pro-layout'
import { checkVisualizeEnabled } from '@salescore/client-recoil'
import { CORE_CONSTANT, type ViewUiKpiPivot } from '@salescore/core'
import { message } from 'antd'
import { t } from 'i18next'
import { useEffect } from 'react'

import { useKpiPivotParameter, useKpiPivotParameterDrawerVisibility } from '../../recoil/navigation/hooks'
import { useMeValue, useViewValue } from '../../recoil/view/hooks'
import { ExpandableSider } from './ExpandableSider'
import { VisualizeUserPlanNotFound } from './ViewUIKpi/VisualizeUserPlanNotFound'
import { KpiParameterSider } from './ViewUIKpiPivot/KpiParameterSider'
import { KpiPivotKpisForm, KpiPivotKpisFormModal } from './ViewUIKpiPivot/KpiPivotKpisFormModal'
import { KpiPivotNavigation } from './ViewUIKpiPivot/KpiPivotNavigation'
import { ViewUIKpiPivotBody } from './ViewUIKpiPivot/ViewUIKpiPivotBody'

export const ViewUIKpiPivot = ({ component }: { component: ViewUiKpiPivot }) => {
  const view = useViewValue()
  const [visible, setVisibility] = useKpiPivotParameterDrawerVisibility()
  const [kpiPivotParameter, setKpiPivotParameter] = useKpiPivotParameter()
  const me = useMeValue()

  // pivotにKPIが含まれていない時、強制的にKPIを含める
  useEffect(() => {
    const ds = [...kpiPivotParameter.pivot.rows, ...kpiPivotParameter.pivot.columns]
    if (ds.every((x) => x.key !== CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION.key)) {
      setKpiPivotParameter((oldValue) => ({
        ...oldValue,
        pivot: {
          ...oldValue.pivot,
          columns: [...oldValue.pivot.columns, CORE_CONSTANT.KPI_PIVOT_KPI_DIMENSION],
        },
      }))
    }
  }, [kpiPivotParameter])

  if (!checkVisualizeEnabled(me.myUser)) {
    return (
      <div className="view-ui-kpi-sheet size-full bg-white">
        <VisualizeUserPlanNotFound />
      </div>
    )
  }

  if (component.kpis.isBlank()) {
    return (
      <PageHeader title={t(`ダッシュボード設定`)} className="view-ui-kpi-pivot-sheet h-full bg-white">
        <div style={{ maxWidth: 700 }}>
          <KpiPivotKpisForm
            onFinish={() => {
              void message.success(t(`KPIを設定しました`))
            }}
          />
        </div>
      </PageHeader>
    )
  }

  return (
    <div className="view-ui-kpi-pivot-sheet h-full">
      <ExpandableSider
        visibility={visible}
        width={430}
        sider={<KpiParameterSider component={component} />}
        body={
          <div className="flex h-full flex-col overflow-y-auto bg-white">
            <KpiPivotNavigation component={component} />

            <div
              className="min-h-0 flex-1 border"
              // ここでkey指定するとviewの切り替え時に全レンダリングが走って良くないが、実装ミスでレンダリングが微妙になるのを防ぐのを優先する
              key={view.id}
            >
              <ViewUIKpiPivotBody component={component} />
            </div>
          </div>
        }
      />
      <>
        <KpiPivotKpisFormModal component={component} />
      </>
    </div>
  )
}
