import { CheckCircleOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons'
import { type JobQueueLogFieldsFragment, JobQueueLogLevelEnum } from '@salescore/client-api'
import { getColumnSearchProps } from '@salescore/frontend-common'
import { Popover, Table, Tag } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'

import { ExpandableSpan } from './ExpandableSpan'
import { JobQueueLogLevelTag } from './JobQueueLogLevelTag'

export const JobQueueLogTable = ({ jobQueueLogs }: { jobQueueLogs: JobQueueLogFieldsFragment[] }) => {
  // 同期ジョブについて、ログから雑に実行中・完了したmodelを抽出する
  const startedModels = jobQueueLogs
    .filter((x) => x.message.startsWith(`syncStream() start`))
    .map((x) => x.tags.first())
    .compact()
    .unique()
  const finishedModels = jobQueueLogs
    .filter((x) => x.message.startsWith(`syncStream() finished`) && x.tags.every((tag) => tag !== 'setup'))
    .map((x) => x.tags.first())
    .compact()
    .unique()
  const errorModels = jobQueueLogs
    .filter((x) => x.level === 'error')
    .map((x) => x.tags.first())
    .compact()
    .unique()
  const runningModels = startedModels.difference(finishedModels).difference(errorModels)

  return (
    <>
      <div>
        {runningModels.isPresent() && (
          <div className="mb-2">
            <Tag icon={<LoadingOutlined />} color={`blue`}>
              {t(`実行中 `)}
            </Tag>
            {runningModels.slice(0, 20).map((x) => (
              <Tag>{x}</Tag>
            ))}
            {runningModels.length > 20 ? t(`+他。全{{length}}件`, { length: runningModels.length.toString() }) : ''}
          </div>
        )}
        {finishedModels.isPresent() && (
          <div className="mb-2">
            <Tag icon={<CheckCircleOutlined />} color={`green`}>
              {t(`完了 `)}
            </Tag>
            {finishedModels.slice(0, 20).map((x) => (
              <Tag>{x}</Tag>
            ))}
            {finishedModels.length > 20 ? t(`+他。全{{length}}件`, { length: finishedModels.length.toString() }) : ''}
          </div>
        )}
        {errorModels.isPresent() && (
          <div className="mb-2">
            <Tag icon={<WarningOutlined />} color={`red`}>
              {t(`エラー `)}
            </Tag>
            {errorModels.slice(0, 20).map((x) => (
              <Tag>{x}</Tag>
            ))}
            {errorModels.length > 20 ? t(`+他。全{{length}}件`, errorModels.length.toString()) : ''}
          </div>
        )}
      </div>
      <Table
        dataSource={jobQueueLogs.map((q) => ({ ...q, key: q.id }))}
        size="small"
        pagination={{
          pageSize: 100,
        }}
        title={() => <span>{t(`ログ`)}</span>}
        columns={[
          // {
          //   dataIndex: 'id',
          //   title: 'ID',
          // },
          {
            dataIndex: 'level',
            width: 100,
            title: '',
            filters: [
              {
                text: 'info',
                value: 'info',
              },
              {
                text: 'warn',
                value: 'warn',
              },
              {
                text: 'error',
                value: 'error',
              },
            ],
            // フィルタ結果の処理
            onFilter: (value, record) => record.level === value,
            render(_, record) {
              return <JobQueueLogLevelTag status={record.level} />
            },
          },
          {
            dataIndex: 'jobWorkerId',
            width: 100,
            title: t(`ワーカーID`),
            render(_, record) {
              return <Popover title={record.jobWorkerId}>{record.jobWorkerId.slice(0, 4)}</Popover>
            },
          },
          // {
          //   dataIndex: 'jobQueueId',
          //   width: 100,
          //   title: 'キューID',
          //   render(_, record) {
          //     return <Popover title={record.jobQueueId}>{record.jobQueueId.slice(0, 4)}</Popover>
          //   }
          // },
          {
            dataIndex: 'createdAt',
            title: t(`時刻`),
            width: 130,
            render(_, log) {
              const d = dayjs(log.createdAt)
              return <span>{d.format(`MM/DD HH:mm:ss`)}</span>
            },
          },
          {
            dataIndex: 'tags',
            title: t(`タグ`),
            ...getColumnSearchProps<JobQueueLogFieldsFragment>((record) => record.tags.join(',')),
            // eslint-disable-next-line unicorn/prefer-includes
            onFilter: (value, record) => record.tags.some((tag) => tag === value),
            render(_, record) {
              return (
                <span>
                  {record.tags.map((tag) => (
                    <Tag>{tag}</Tag>
                  ))}
                </span>
              )
            },
          },
          {
            dataIndex: 'message',
            title: t(`メッセージ`),
            ...getColumnSearchProps<JobQueueLogFieldsFragment>((record) => record.message),
            render(_, record) {
              // return record.message
              return (
                <ExpandableSpan
                  text={record.message ?? ''}
                  ellipsisChars={100}
                  className={record.level === JobQueueLogLevelEnum.Error ? 'text-red-400' : ''}
                />
              )
            },
          },
        ]}
      />
    </>
  )
}
