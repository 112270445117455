import { useApolloClient } from '@apollo/client'
import { sleep } from '@salescore/buff-common'
import type { JobQueueFieldsFragment } from '@salescore/client-api'
import { FetchJobStatusesDocument, JobQueueStatusEnum } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { useState } from 'react'

const POLLING_INTERVAL_SEC = 2

export const useFetchJobStatus = () => {
  const [jobStatuses, setJobStatuses] = useState<JobQueueFieldsFragment[]>([])
  const [polling, setPolling] = useState(false)
  const client = useApolloClient()
  return {
    jobStatuses,
    polling,
    async fetchStart(argument: { id: string | undefined; jobName: string }) {
      setPolling(true)

      while (true) {
        const { data: jobStatuses } = await client.query({
          query: FetchJobStatusesDocument,
          variables: {
            organizationId: getOrganizationIdFromPath(),
            jobName: argument.jobName,
            id: argument.id,
          },
        })
        setJobStatuses(jobStatuses.jobStatuses)
        // 全てのジョブが完了したら終了
        if (
          jobStatuses.jobStatuses.every(
            (jobStatus) =>
              jobStatus.status !== JobQueueStatusEnum.Running && jobStatus.status !== JobQueueStatusEnum.Waiting,
          )
        ) {
          setPolling(false)
          return
        }
        await sleep(POLLING_INTERVAL_SEC)
      }
    },
  }
}
