import { FilterOutlined } from '@ant-design/icons'
import type { ViewConfigFilterNode } from '@salescore/core'
import { stableStringify } from '@salescore/features'
import { addModelIdProperty, useBooleanState } from '@salescore/frontend-common'
import { Col, Collapse, Row, Space, Switch } from 'antd'
import { t } from 'i18next'

import { convertToFilterNodeForForm } from '../../../../recoil/navigation/hooks'
import { useKpiFormSelectors } from '../../../../recoil/view/selectors/kpiFormSelector'
import {
  applyAdvancedSettingsFlag,
  ViewFilterTreeForm,
} from '../../ViewUISheetWithNavigation/SheetNavigation/filter/ViewFilterTreeForm'

//
// extraSheetSetting.filterTreeのフォーム
// KPIをシートとして表示するときにだけ適用されるフィルタ
//
export function KpiFormExtraFilterTreeFormItem() {
  const { currentSheet, currentNodeProperties, form, setForm, setFormModified, modelSearcher } = useKpiFormSelectors()
  const isAdvancedModeState = useBooleanState(
    (form.sheetExtraSetting?.filterTree?.children ?? []).isPresent() ||
      form.sheetExtraSetting?.filterTree?.logicalOperator === 'or',
  )
  const onChange = (filterTree: ViewConfigFilterNode | undefined, option?: { asInitialization?: boolean }) => {
    setForm((oldValue) => ({
      ...oldValue,
      sheetExtraSetting: {
        filterTree,
      },
    }))
    // 変更を行ったら、変更フラグを立てる
    // フォームの初期レンダリング時に、一部ロジックでonChangeを呼ぶことがあるが、この際は変更フラグを立てない
    if (option?.asInitialization !== true) {
      setFormModified(true)
    }
  }

  const propertiesWithNode = currentNodeProperties.map((x) => ({
    node: x.node.node,
    model: x.model,
    property: x.property,
    nodePathAsLabel: x.node.nodePathAsLabel,
  }))
  const rootModel = modelSearcher.searchModel(currentSheet.tree?.modelName)
  const properitesAndModelIdWithNode = addModelIdProperty(
    { node: currentSheet.tree, model: rootModel },
    propertiesWithNode,
  )

  return (
    <Collapse defaultActiveKey={[]} className="mb-8">
      <Collapse.Panel header={t(`高度な設定`)} key="detail">
        <div>
          <Row justify="space-between" className="mb-4">
            <Col>
              <Space className="text-md font-normal">
                <FilterOutlined />
                <span>{t(`一覧表示に適用されるフィルタ条件`)}</span>
              </Space>
            </Col>
            <Col>
              <Space>
                {t(`高度なフィルタ条件`)}
                <Switch
                  checked={isAdvancedModeState.isTrue}
                  onChange={(value) => {
                    if (value) {
                      isAdvancedModeState.setTrue()
                    } else {
                      isAdvancedModeState.setFalse()
                    }
                  }}
                />
              </Space>
            </Col>
          </Row>
          <ViewFilterTreeForm
            // treeの状態が変わったらfilterをリセットする（内部でuseStateしているので、こうしないとクリアされない）
            // KpiFormFilterTreeFormItemでは、filterFormItemCacheKeyもキャッシュキーに含めているが、
            // ここで変更するのはsheetExtraSettingであり、これは外部から変更されないので、ここで変更する必要はない
            cacheKey={stableStringify([currentSheet.tree ?? ''])}
            filterTree={convertToFilterNodeForForm(
              form.sheetExtraSetting?.filterTree ?? { logicalOperator: `and`, leafs: [], children: [] },
            )}
            propertiesWithNode={properitesAndModelIdWithNode}
            isAdvancedMode={isAdvancedModeState.isTrue}
            inNestedForm
            onChange={(filterTree, option) => {
              const newFilterTree = applyAdvancedSettingsFlag(isAdvancedModeState.isTrue, filterTree)
              onChange(newFilterTree, option)
            }}
          />
        </div>
      </Collapse.Panel>
    </Collapse>
  )
}
