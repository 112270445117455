import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { DeleteHubSourceDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { Button, Form, Input, Row, Space, Typography } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'

const { Text } = Typography

export const SourceDeleteContent = ({
  sourceId,
  onCompleted,
  onError,
}: {
  sourceId: string
  onCompleted: () => void
  onError: () => void
}) => {
  const [deletable, setDeletable] = useState(false)
  const [deleteHubSourceMutation, { loading }] = useMutation(DeleteHubSourceDocument)

  const handleFinish = async () => {
    await deleteHubSourceMutation({
      variables: {
        id: sourceId,
        organizationId: getOrganizationIdFromPath(),
      },
      onCompleted,
      onError,
    })
  }

  return (
    <Space direction="vertical" size={'large'}>
      <Text strong type="danger">
        {t(
          `あなたは、個人ではなく組織全体のデータ連携を削除しようとしています。この操作は実行すると取り消すことができません。組織のデータ連携を本当に削除してもよいか、慎重にご検討ください。`,
        )}
      </Text>
      <Text strong>
        <ol>
          <li>{t(`すべての同期データが完全に失われます。`)}</li>
          <li>{t(`データの削除後、連携していたデータを用いたダッシュボードやシートは閲覧不可能になります。`)}</li>
          <li>{t(`削除プロセスには時間がかかります。したがって、削除操作が直ちに反映されるわけではありません。`)}</li>
        </ol>
      </Text>
      <Form onFinish={handleFinish} layout="vertical">
        <Form.Item
          className="text-gray-100"
          label={t(`削除を確定にするには、「削除」と入力してください。`)}
          name="delete"
        >
          <Input
            placeholder={t(`削除`)}
            onChange={(e) => {
              setDeletable(e.target.value === t(`削除`))
            }}
          />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button
              disabled={!deletable}
              type="primary"
              danger
              icon={<DeleteOutlined />}
              loading={loading}
              htmlType="submit"
            >
              {t(`削除`)}
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Space>
  )
}
