import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import type { ViewUICol, ViewUIRow } from '@salescore/core'
import { Row } from 'antd'
import type * as React from 'react'
import { useContext } from 'react'

import { ViewUIComponentC, ViewUIContext } from './ViewUIComponent'

const SortableContextWrapper: React.FC<{ isEditMode: boolean; cols: ViewUICol[]; children: JSX.Element }> = ({
  isEditMode,
  cols,
  children,
}) => {
  if (!isEditMode) {
    return <>{children}</>
  }

  return (
    <SortableContext items={cols} strategy={rectSortingStrategy}>
      {children}
    </SortableContext>
  )
}

export function ViewUIRowC({ component }: { component: ViewUIRow }) {
  const { isEditMode } = useContext(ViewUIContext)

  return (
    <Row gutter={component.gutter ?? 16} className="mb-5">
      <SortableContextWrapper isEditMode={isEditMode} cols={component.children}>
        <>
          {component.children.map((child) => (
            <ViewUIComponentC component={child} />
          ))}
        </>
      </SortableContextWrapper>
    </Row>
  )
}
