import { CameraOutlined, DeleteOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { compareFunction } from '@salescore/buff-common'
import {
  DeleteSnapshotModelDocument,
  type EltModelFieldsFragment,
  FetchEltModelsDocument,
  TakeSnapshotMutationDocument,
} from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-base'
import { getColumnSearchProps } from '@salescore/frontend-common'
import { App, Button, Card, DatePicker, Form, message, Table, Tag } from 'antd'
import type dayjs from 'dayjs'
import { t } from 'i18next'
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs'

import { ModelLabel } from './ModelLabel'
import { PropertyButton } from './PropertyButton'
import { SnapshotModelForm } from './SnapshotModelForm'

export const SnapshotModelTable = (): JSX.Element => {
  const { modal } = App.useApp()
  const { data, loading, refetch } = useQuery(FetchEltModelsDocument, {
    variables: {
      organizationId: getOrganizationIdFromPath(),
    },
  })
  const [deleteSnapshotModel] = useMutation(DeleteSnapshotModelDocument)
  const [takeSnapshot] = useMutation(TakeSnapshotMutationDocument)
  const [form] = Form.useForm<{ snapshotCreatedAt?: dayjs.Dayjs }>()

  const sortedEltModels = (data?.eltModels ?? [])
    .filter((x) => x.type === 'userCustomizedSnapshot')
    .sortBy((x) => x.name)

  return (
    <Card
      bordered={false}
      extra={
        <Button
          type={'primary'}
          key="snapshot"
          icon={<PlusOutlined />}
          onClick={() => {
            const m = modal.confirm({
              title: t(`スナップショットオブジェクトの新規作成`),
              width: 'min(80%, 1440px)',
              content: (
                <SnapshotModelForm
                  onAfterFinish={() => {
                    m.destroy()
                    void refetch()
                  }}
                  onAfterCancel={(): void => {
                    m.destroy()
                  }}
                />
              ),
              footer: <></>,
            })
          }}
        >
          {t(`スナップショットオブジェクトの新規作成`)}
        </Button>
      }
    >
      <Table
        loading={loading}
        dataSource={sortedEltModels}
        pagination={{
          pageSize: 100,
        }}
        scroll={{ y: 'min(85vh, calc(100vh - 250px))' }}
        columns={[
          {
            title: t(`オブジェクト名`),
            key: `label`,
            sorter: (a, b) => compareFunction(a.model.label, b.model.label),
            ...getColumnSearchProps<EltModelFieldsFragment>((eltModel) => eltModel.model.label),
            render: (_, eltModel) => <ModelLabel model={eltModel.model} />,
          },
          {
            title: t(`内部名`),
            key: `name`,
            sorter: (a, b) => compareFunction(a.name, b.name),
            ...getColumnSearchProps<EltModelFieldsFragment>((eltModel) => eltModel.name),
            render: (_, eltModel) => <Tag>{eltModel.name}</Tag>,
          },
          {
            title: ``,
            key: `properties`,
            width: 100,
            render: (_, eltModel) => <PropertyButton eltModel={eltModel} />,
          },
          {
            title: ``,
            key: `sql`,
            width: 100,
            render: (_: unknown, eltModel: EltModelFieldsFragment) => (
              <Button
                type="text"
                className="text-blue-500"
                icon={<EyeOutlined />}
                onClick={() => {
                  void modal.info({
                    title: t('定義'),
                    width: 'min(80%, 1200px)',
                    maskClosable: true,
                    content: (
                      <div className="max-h-[80vh] overflow-y-scroll">
                        <h2>SQL</h2>
                        <SyntaxHighlighter
                          language="pgsql"
                          style={monokaiSublime}
                          customStyle={{ padding: 10 }}
                          wrapLongLines={true}
                        >
                          {eltModel.transform?.sql ?? ''}
                        </SyntaxHighlighter>
                        <h2>{t('モデル定義')}</h2>
                        <SyntaxHighlighter
                          language="json"
                          style={monokaiSublime}
                          customStyle={{ padding: 10 }}
                          wrapLongLines={true}
                        >
                          {JSON.stringify(eltModel.model, null, 2)}
                        </SyntaxHighlighter>
                      </div>
                    ),
                  })
                }}
              >
                {t('定義')}
              </Button>
            ),
          },
          {
            title: ``,
            key: `takeSnapshot`,
            width: 120,
            render: (_: unknown, eltModel: EltModelFieldsFragment) => (
              <Button
                type="text"
                className="text-blue-500"
                icon={<CameraOutlined />}
                onClick={() => {
                  void modal.confirm({
                    title: t('スナップショットの取得'),
                    maskClosable: true,
                    content: (
                      <Form form={form}>
                        {t('日時設定は動作確認用なので実際に指定日時のスナップショットデータを取得はしません')}
                        <Form.Item name="snapshotCreatedAt">
                          <DatePicker />
                        </Form.Item>
                      </Form>
                    ),
                    okText: t('取得する'),
                    onOk: () => {
                      void takeSnapshot({
                        variables: {
                          organizationId: getOrganizationIdFromPath(),
                          snapshotModelId: eltModel.id,
                          timestamp: form.getFieldsValue().snapshotCreatedAt?.toISOString(),
                        },
                        onCompleted: (data) => {
                          void message.success(`${t(`スナップショットを取得しました`)}: ${data.takeSnapshot.createdAt}`)
                        },
                        onError: () => {
                          void message.error(t(`エラーが発生しました`))
                        },
                      })
                    },
                  })
                }}
              >
                {t('取得する')}
              </Button>
            ),
          },
          {
            title: ``,
            key: `delete`,
            width: 100,
            render: (_: unknown, eltModel: EltModelFieldsFragment) => (
              <Button
                type="text"
                danger
                icon={<DeleteOutlined />}
                onClick={() => {
                  void modal.confirm({
                    title: `${t('削除')}: ${eltModel.model.label}`,
                    content: (
                      <div className="text-red-500">
                        {t(`削除を行うと今までに保存したデータが全て失われます。`)}
                        {t(`この操作は取り消せません。`)}
                        <br />
                        {t(`本当に削除しますか？`)}
                        <br />
                      </div>
                    ),
                    okText: t('削除する'),
                    onOk: () => {
                      void deleteSnapshotModel({
                        variables: {
                          organizationId: getOrganizationIdFromPath(),
                          id: eltModel.id,
                        },
                        onCompleted: (data) => {
                          void message.success(t(`削除しました`) + `: ${data.deleteSnapshotModel.model.label}`)
                          void refetch()
                        },
                        onError: () => {
                          void message.error(t(`エラーが発生しました`))
                        },
                      })
                    },
                  })
                }}
              >
                {t(`削除`)}
              </Button>
            ),
          },
        ]}
      />
    </Card>
  )
}
