import type { ViewForSiderFieldsFragment, ViewGroupFieldsFragment } from '@salescore/client-api'
import { mutation } from '@salescore/frontend-common'
import { useSetRecoilState } from 'recoil'

import { viewGroupsAtom, viewsAtom } from '../atoms'

const initializeViewGroupsMutation = mutation<{
  viewGroups: ViewGroupFieldsFragment[]
  views: ViewForSiderFieldsFragment[]
}>({
  key: `recoil-pages/initializeViewGroupsMutation`,
  set({ get, set }, { viewGroups, views }) {
    set(viewGroupsAtom, viewGroups)
    set(viewsAtom, views)
  },
})

export const useInitializeViewGroupsMutation = () => useSetRecoilState(initializeViewGroupsMutation)
