import { Alert, Modal } from 'antd'
import { t } from 'i18next'

import { useNavigationModal } from '../../../recoil/navigation/hooks'
import { useMeValue } from '../../../recoil/view/hooks'

export const SyncUserPlanNotFoundModal = () => {
  const { syncUserPlanNotFoundModal } = useNavigationModal()

  return (
    <Modal
      open={syncUserPlanNotFoundModal.isModalVisible}
      onCancel={syncUserPlanNotFoundModal.hideModal}
      width={'60%'}
      cancelText={t(`閉じる`)}
      okButtonProps={{ style: { display: 'none' } }}
      title={<div className="font-bold">{t(`SALESCORE SYNCのご案内`)}</div>}
      style={{ top: '3%' }}
      destroyOnClose
    >
      <Body
        onAfterFinish={() => {
          syncUserPlanNotFoundModal.hideModal()
        }}
      />
    </Modal>
  )
}

export function Body({ onAfterFinish }: { onAfterFinish: () => void }) {
  const me = useMeValue()

  return (
    <div>
      <Alert
        showIcon
        type="warning"
        description={
          <div>
            {t(`この機能はSYNCプランをご契約の方のみご利用可能です。`)}
            <br />
            {t(`組織の管理者に、SYNCプランの契約とライセンス割り当てを依頼してください。`)}
          </div>
        }
      />
    </div>
  )
}
