import {
  ConsoleSqlOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FilterOutlined,
  FlagOutlined,
  SaveOutlined,
  SettingOutlined,
  ShareAltOutlined,
} from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { faTablePivot } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isNull, isPresent, isSome, isTruthy } from '@salescore/buff-common'
import { CreateSharedUrlDocument } from '@salescore/client-api'
import { CONSTANT, getOrganizationIdFromPath, Posthog, POSTHOG_EVENTS, routes } from '@salescore/client-base'
import { type Period, SuspenseWithLoading } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import type { ViewConfigKpiParameter, ViewUiKpi, ViewUiKpiPivot } from '@salescore/core'
import { ButtonWithTooltip, isSharedLinkPresetName } from '@salescore/frontend-common'
import { Button, Dropdown, type MenuProps, message, Row, Space, Tooltip } from 'antd'
import { diff as deepObjectDiff } from 'deep-object-diff'
import { t } from 'i18next'
import { type SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { z } from 'zod'

import {
  useKpiPivotNavigationModal,
  useKpiPivotParameter,
  useKpiPivotParameterDrawerVisibility,
  useKpiPivotPickedPresetName,
  useNavigationModal,
} from '../../../recoil/navigation/hooks'
import type { ProgressType } from '../../../recoil/navigation/selector/calculateAverageProgressService'
import { usePeriodSelector } from '../../../recoil/navigation/selector/PeriodSelector'
import { useProgressSelector } from '../../../recoil/navigation/selector/ProgressSelector'
import { additionalConfigAtom, errorsDrawerOpenAtom } from '../../../recoil/records/atoms'
import { useRecordsValue, useViewQueryResult } from '../../../recoil/records/hooks'
import { useRefetchKpiPivotQueryResult } from '../../../recoil/records/mutations/useRefetchKpiPivotQueryResult'
import { useMustacheParameterSelector } from '../../../recoil/records/selectors/mustacheParameterSelector'
import { configAtom, hasChangeToKpiPivotParameterAtom } from '../../../recoil/view/atoms'
import {
  useQueriesValue,
  useUiValue,
  useViewAbilityValue,
  useViewConfig,
  useViewsContextValue,
  useViewValue,
} from '../../../recoil/view/hooks'
import { useMeRelatedSelector } from '../../../recoil/view/selectors/meRelatedSelector'
import { useViewsRelated } from '../../../recoil/view/selectors/viewsRelatedSelector'
import { ViewUIErrorDrawer } from '../ViewUIErrorDrawer'
import { DashboardCsvDownloadButton } from '../ViewUISheetWithNavigation/DashboardCsvDownloadButton'
import { ImportSourceRecordsButton } from '../ViewUISheetWithNavigation/SheetNavigation/ImportButton'
import { CompileErrorsButton } from './CompileErrorsButton'
import { GoalConfigsPicker } from './GoalConfigsPicker'
import { KpiPivotPresetsPicker } from './KpiPivotPresetsPicker'
import { KpiUserAppearancePicker } from './KpiUserAppearancePicker'
import { PeriodPicker } from './PeriodPicker'
import { recursiveRemoveEmptyValues } from './recursive'

const viewTypeSchema = z.object({
  diffVisible: z.boolean(),
  totalVisible: z.boolean(),
  goalVisible: z.boolean(),
  perElapsedBusinessDays: z.boolean().optional(),
  colorSchema: z.enum(['normal', 'success-blue']).optional(),
  subtotalOrder: z.enum([`top`, `bottom`]).optional(),
})

type ViewType = z.infer<typeof viewTypeSchema>

export const KpiPivotNavigation = ({
  component,
  siderVisibility,
  siderOpen,
}: {
  component: ViewUiKpiPivot | ViewUiKpi
  siderVisibility?: boolean
  siderOpen?: () => void
}) => {
  const { clearTargetViewQueryCaches } = recoil.sider.useClearViewQueryCacheMutation()
  const viewsContext = useViewsContextValue()
  const [_visibility, setDrawerVisibility] = useKpiPivotParameterDrawerVisibility()
  const progress = useProgressSelector()
  const [period] = usePeriodSelector()
  const refetchKpiPivotQueryResult = useRefetchKpiPivotQueryResult()
  const view = useViewValue()
  const config = useViewConfig()
  const isKpiView = config.type === 'kpi'
  const [errorsDrawerOpen, setErrorsDrawerOpen] = useRecoilState(errorsDrawerOpenAtom)

  return (
    <Row justify="space-between" className="py-2 pl-2 pr-5" style={{ borderBottom: '1px solid #E1E6E8' }}>
      <Row>
        {!isKpiView && (
          <>
            <KpiPivotPresetsPicker
              withNoDateFieldKpis={
                component.type === 'KpiSheet'
                  ? !component.kpi.withDateField
                  : component.kpis.every((x) => !x.withDateField)
              }
            />
            <SuspenseWithLoading>
              <GoalConfigsPicker />
            </SuspenseWithLoading>
          </>
        )}
        <PeriodPicker
          disabled={
            component.type === 'KpiSheet' ? !component.kpi.withDateField : component.kpis.every((x) => !x.withDateField)
          }
        />
        {!isKpiView && (
          <>
            <AverageProgress period={period} progress={progress} />
            <Button
              onClick={() => {
                setDrawerVisibility((x) => !x)
              }}
              type="text"
              icon={<FontAwesomeIcon icon={faTablePivot} />}
            >
              {t(`集計設定`)}
            </Button>
          </>
        )}
        {siderOpen !== undefined && siderVisibility !== true && (
          <Button
            onClick={() => {
              siderOpen()
            }}
            type="text"
            icon={<FontAwesomeIcon icon={faTablePivot} />}
          >
            {t(`集計設定`)}
          </Button>
        )}
        <DimensionFiltersButton />
        <KpiUserAppearancePicker />
        <SettingButton />
        <DashboardCsvDownloadButton />
        {config.type === 'kpiPivot' && <ShareViewButton />}
        <InvisibleKpisButton />
        <CompileErrorsButton
          onClick={() => {
            setErrorsDrawerOpen(true)
          }}
        />
        <ViewUIErrorDrawer
          open={errorsDrawerOpen}
          onClose={() => {
            setErrorsDrawerOpen(false)
          }}
        />
      </Row>
      <Space>
        <ImportSourceRecordsButton
          buttonType="link"
          onSuccess={() => {
            // 同期後に古いキャッシュを読まないようにするためにApolloのキャッシュをクリアする
            const { isDrillDown, prevView, setIsChangedDrillDownModal } = viewsContext
            const cacheClearViewId = isTruthy(isDrillDown) ? prevView?.id : view.id
            clearTargetViewQueryCaches({ viewIds: [cacheClearViewId].compact() })

            if (isTruthy(isDrillDown) && isSome(setIsChangedDrillDownModal)) {
              setIsChangedDrillDownModal(true)
            }

            refetchKpiPivotQueryResult.refetch()
          }}
        />
        <SaveViewButtons />
      </Space>
    </Row>
  )
}

function DimensionFiltersButton() {
  const [parameter] = useKpiPivotParameter()
  const { dimensionFiltersModal } = useKpiPivotNavigationModal()
  const filters = parameter.dimensionFilterLeafs ?? []

  // 一旦、絞り込みの追加系はヘッダー列のみから行うことし、このボタンからの変更は考えない
  if (filters.isBlank()) {
    return <></>
  }

  return (
    <Button
      type="text"
      icon={<FilterOutlined />}
      onClick={() => {
        dimensionFiltersModal.showModal()
      }}
    >
      {t(`絞り込み`)}({filters.length})
    </Button>
  )
}

function MenuItemLabel({
  visible,
  id,
  name,
  setParameter,
}: {
  visible: boolean
  id: string
  name: string
  setParameter: SetterOrUpdater<ViewConfigKpiParameter>
}) {
  const textColor = visible ? '' : 'text-slate-400'
  return (
    <div
      className="flex max-w-xs flex-nowrap justify-between gap-2"
      onClick={() => {
        if (visible) {
          setParameter((oldValue) => ({
            ...oldValue,
            invisibleKpiIds: oldValue.invisibleKpiIds === undefined ? [id] : [...oldValue.invisibleKpiIds, id],
          }))
        } else {
          setParameter((oldValue) => ({
            ...oldValue,
            invisibleKpiIds: oldValue.invisibleKpiIds?.filter((x) => x !== id),
          }))
        }
      }}
    >
      <span style={{ display: 'contents' }}>{name}</span>
      {visible ? <EyeOutlined className={textColor} /> : <EyeInvisibleOutlined className={textColor} />}
    </div>
  )
}

function InvisibleKpisButton() {
  const [parameter, setParameter] = useKpiPivotParameter()
  const { searchView } = useViewsRelated()
  const config = useViewConfig()
  if (config.type !== 'kpiPivot') {
    return <></>
  }
  const invisibleKpiIds = parameter.invisibleKpiIds ?? []
  if (invisibleKpiIds.isBlank()) {
    return <></>
  }

  const invisibleItems: MenuProps['items'] = invisibleKpiIds
    .map((x) => {
      const view = searchView(x)
      if (view === undefined) {
        return
      }
      return {
        key: x,
        label: <MenuItemLabel visible={false} id={x} name={view.name} setParameter={setParameter} />,
      }
    })
    .compact()
  const visibleItems: MenuProps['items'] = config.kpis
    .filter((kpi) => !invisibleKpiIds.includes(kpi.viewId))
    .map((kpi) => {
      const view = searchView(kpi.viewId)
      if (view === undefined) {
        return
      }
      return {
        key: kpi.viewId,
        label: <MenuItemLabel visible={true} id={kpi.viewId} name={view.name} setParameter={setParameter} />,
      }
    })
    .compact()
  const items = [...invisibleItems, ...visibleItems]

  return (
    <Dropdown menu={{ items }}>
      <Button
        onClick={() => {
          // compileLogsModal.showModal()
        }}
        icon={<EyeInvisibleOutlined />}
        className="border-yellow-400 text-yellow-500"
      >
        {t(`{{length}}件の非表示KPI`, { length: invisibleKpiIds.length })}
      </Button>
    </Dropdown>
  )
}

// 現在の値で、選択中のプリセットを更新
function SaveViewButtons() {
  const ability = useViewAbilityValue()
  const { kpiPresetFormModal } = useKpiPivotNavigationModal()
  const [config, setConfig] = useRecoilState(configAtom) // TODO: setConfigした後の永続化
  const [parameter, setParameter] = useKpiPivotParameter()
  const [picked] = useKpiPivotPickedPresetName()
  const setHasChangeToKpiPivotParameter = useSetRecoilState(hasChangeToKpiPivotParameterAtom)
  if (config.type !== 'kpi' && config.type !== 'kpiPivot') {
    // ありえないはず
    return <></>
  }
  const presets =
    // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
    config.presets !== undefined && config.presets.isPresent() ? config.presets : []
  const pickedPreset = presets.find((x) => x.name === picked)

  const { goalConfig: savedGoalConfig, ...savedOtherParameter } =
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    pickedPreset?.parameter ?? ({ goalConfig: undefined } as ViewConfigKpiParameter)
  const { goalConfig: currentGoalConfig, ...currentOtherParameter } = parameter

  // 差分からundefined/null/空arrayを除外する
  const diff = {
    ...recursiveRemoveEmptyValues(deepObjectDiff(savedOtherParameter, currentOtherParameter)),
    ...recursiveRemoveEmptyValues(deepObjectDiff(currentOtherParameter, savedOtherParameter)),
  }

  // GoalConfigのみ__typenameや含まれるパラメーターに差異があるためidの比較をしている
  const hasChanges = isPresent(Object.keys(diff)) || savedGoalConfig?.id !== currentGoalConfig?.id
  if (isPresent(pickedPreset)) {
    setHasChangeToKpiPivotParameter(hasChanges)
  }
  const hasChangesToPreset = hasChanges && isPresent(pickedPreset)

  if (isSharedLinkPresetName(pickedPreset?.name)) {
    return <></>
  }
  return (
    <>
      <Space>
        {hasChangesToPreset && (
          <ButtonWithTooltip
            tooltipTitle={t(`権限がないため集計設定の変更を破棄できません`)}
            showTooltip={!ability.canUpdate}
            disabled={!hasChangesToPreset || !ability.canUpdate}
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              setParameter(pickedPreset.parameter)
              setHasChangeToKpiPivotParameter(false)
            }}
          >
            {t(`集計設定変更を破棄`)}
          </ButtonWithTooltip>
        )}
        <ButtonWithTooltip
          tooltipTitle={t(`権限がないため保存できません`)}
          showTooltip={!ability.canUpdate}
          icon={<SaveOutlined />}
          disabled={!hasChangesToPreset || !ability.canUpdate}
          type="primary"
          onClick={() => {
            if (pickedPreset !== undefined) {
              kpiPresetFormModal.showModal({ preset: pickedPreset })
            }
          }}
        >
          {t(`集計設定を保存`)}
        </ButtonWithTooltip>
      </Space>
    </>
  )
}

function SettingButton() {
  const config = useViewConfig()
  const view = useViewValue()
  const [kpiPivotParameter] = useKpiPivotParameter()
  const queries = useQueriesValue()
  const viewQueryResult = useViewQueryResult()
  const mustacheParameter = useMustacheParameterSelector()
  const ui = useUiValue()
  const records = useRecordsValue()
  const { metadataModal, debugModal } = useNavigationModal()
  const { me } = useMeRelatedSelector()
  const additionalConfig = useRecoilValue(additionalConfigAtom)

  return (
    <Dropdown
      overlay={
        <div className="bg-white p-4 shadow" style={{ width: 300 }}>
          <Row justify="space-between" className="mb-2">
            <Space>
              <ConsoleSqlOutlined />
              {t(`SQL表示`)}
            </Space>
            <Button
              type="text"
              style={{
                color: CONSTANT.colors.primaryColor,
              }}
              disabled={isNull(viewQueryResult)}
              onClick={() => {
                metadataModal.showModal()
              }}
            >
              {t(`表示`)}
            </Button>
          </Row>
          {me.isAdmin && (
            <Row justify="space-between" className="mb-2">
              <Space className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}>
                <ConsoleSqlOutlined />
                {t(`設定編集`)}
              </Space>
              <Button
                type="text"
                style={{
                  color: CONSTANT.colors.primaryColor,
                }}
                onClick={() => {
                  debugModal.showModal({
                    content: config,
                    // schema: viewConfigFieldSchema, // TODO: 指定はしているものの、現状ワークしていない
                    // TODO: 直接編集を許すか？
                    // onChange(newField) {
                    //   fieldMutations.setField({ field: newField as ViewConfigField })
                    // },
                  })
                }}
              >
                {t(`表示`)}
              </Button>
            </Row>
          )}
          {me.isAdmin && (
            <Row justify="space-between" className="mb-2">
              <Space className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}>
                <ConsoleSqlOutlined />
                {t(`スキーマ表示`)}
              </Space>
              <Button
                type="text"
                style={{
                  color: CONSTANT.colors.primaryColor,
                }}
                onClick={() => {
                  debugModal.showModal({
                    content: {
                      config,
                      additionalConfig,
                      mustacheParameter,
                      ui,
                      queries,
                    },
                    // schema: viewConfigFieldSchema, // TODO: 指定はしているものの、現状ワークしていない
                    // TODO: 直接編集を許すか？
                    // onChange(newField) {
                    //   fieldMutations.setField({ field: newField as ViewConfigField })
                    // },
                  })
                }}
              >
                {t(`表示`)}
              </Button>
            </Row>
          )}
          {me.isAdmin && (
            <Row justify="space-between" className="mb-2">
              <Space className={CONSTANT.colorClasses.ADMIN_TEXT_COLOR}>
                <ConsoleSqlOutlined />
                {t(`レコード表示`)}
              </Space>
              <Button
                type="text"
                style={{
                  color: CONSTANT.colors.primaryColor,
                }}
                onClick={() => {
                  debugModal.showModal({
                    content: {
                      viewQueryResult: {
                        ui: viewQueryResult?.ui,
                      },
                      records,
                    },
                    // schema: viewConfigFieldSchema, // TODO: 指定はしているものの、現状ワークしていない
                    // TODO: 直接編集を許すか？
                    // onChange(newField) {
                    //   fieldMutations.setField({ field: newField as ViewConfigField })
                    // },
                  })
                }}
              >
                {t(`表示`)}
              </Button>
            </Row>
          )}
        </div>
      }
      placement="bottomLeft"
      trigger={['hover']}
    >
      <Button type="text" icon={<SettingOutlined />} />
    </Dropdown>
  )
}

function ShareViewButton() {
  const [parameter] = useKpiPivotParameter()
  const [picked] = useKpiPivotPickedPresetName()
  const view = useViewValue()
  const [createSharedUrlMutation] = useMutation(CreateSharedUrlDocument)

  return (
    <>
      <Button
        type="text"
        onClick={async () => {
          Posthog.track(POSTHOG_EVENTS.click_shareurl_from_dashboard, {
            organizationId: getOrganizationIdFromPath(),
            viewId: view.id,
          })

          const result = await createSharedUrlMutation({
            variables: {
              organizationId: getOrganizationIdFromPath(),
              sharedUrl: {
                viewId: view.id,
                parameter: {
                  ...parameter,
                  kpiPivotPresetName: picked,
                },
                type: view.type,
              },
            },
          })

          if (isNull(result.data?.createSharedUrl?.id)) {
            void message.warning(t(`共有用URLを生成できませんでした`))
            return
          }

          const shareURL =
            location.origin +
            routes.topPathV2(
              `viewId=${view.id}&shareId=${result.data.createSharedUrl.id}&pickedPresetName=${encodeURIComponent(picked ?? '')}`,
            )
          await navigator.clipboard.writeText(shareURL)
          void message.info(t(`共有用URLをクリップボードにコピーしました`))
        }}
        icon={<ShareAltOutlined />}
      ></Button>
    </>
  )
}

function AverageProgress({
  progress,
  period,
  hasTimeRelatedDimension,
  viewType,
}: {
  progress: ProgressType
  period: Period | undefined
  hasTimeRelatedDimension?: boolean
  viewType?: ViewType
}) {
  if (period === undefined) {
    return <></>
  }

  const translatedPeriodName = t(period.name)
  // 全期間を設定したときなどにbusinessDaysが0。このとき、文言がおかしくなるので出しわけている。
  const title =
    progress.businessDays === 0
      ? t(`目標達成のために必要な進捗率です`)
      : `${t(
          `{{periodName}}の目標達成のために、今日の時点で必要な進捗率です。{{periodName}}は営業日が{{startAt, datestr}}から{{endAt, datestr}}の間に{{businessDays}}日間あり、今日時点で{{elapsedDays}}日間が経過しているため、必要な進捗率は{{elapsedDays}}/{{businessDays}}={{progressPercent}}%です。`,
          {
            periodName: translatedPeriodName,
            startAt: period.startAt,
            endAt: period.endAt,
            businessDays: progress.businessDays,
            elapsedDays: progress.elapsedDays,
            progressPercent: progress.progressPercent,
            // formatParams: {
            //   startAt: { year: 'numeric', month: 'numeric', day: 'numeric' },
            //   endAt:  { year: 'numeric', month: 'numeric', day: 'numeric' },
            // },
          },
        )} ${
          hasTimeRelatedDimension === true
            ? t(`※時間関係の項目を集計項目に選択している場合、必要な進捗率はセルごとに異なります。`)
            : ``
        }`
  return (
    <Tooltip title={title}>
      <div
        className="ml-4 flex"
        // style={{ width: 120 }}
      >
        <span className="text-lg font-bold">
          <FlagOutlined className="mr-2" />
          {progress.progressPercent}%
        </span>
        {/* <Progress
          className='mt-3 mx-1'
          steps={10}
          // type="circle"
          size="small"
          width={20}
          percent={progress.progressPercent}
          showInfo={false}
          // trailColor="white"
          strokeColor={
            viewType?.colorSchema === 'success-blue' ? CONSTANT.colors.SUCCESS_COLOR_BLUE : CONSTANT.colors.SUCCESS_COLOR
          }
        /> */}
      </div>
    </Tooltip>
  )
}
