import { notifyBugsnag } from '@salescore/client-base'
import type { ViewQueryFilterNode, ViewQueryList, ViewQueryNode } from '@salescore/core'
import { message } from 'antd'
import { t } from 'i18next'

import { useSetQueryState } from '../../recoil/view/hooks'
import { pruneQuery } from '../../recoil/view/mutations/pruneQuery'
import { addNode, flatNodes, removeNode } from '../nodeUtil'

export class InvalidViewNodesError extends Error {
  public constructor() {
    super()
    this.name = 'InvalidViewNodesError'
  }
}

function removeRemovedNodeFromFilterTree(nodes: ViewQueryNode[], filterTree: ViewQueryFilterNode): ViewQueryFilterNode {
  const leafs = filterTree.leafs.filter((leaf) =>
    leaf.nodePaths.every((nodePath) => nodes.some((node) => nodePath.join(',') === node.path.join(','))),
  )
  const children = filterTree.children.map((childNode) => removeRemovedNodeFromFilterTree(nodes, childNode))

  return {
    ...filterTree,
    leafs,
    children,
  }
}

export const treeActions = () => {
  const setCurrentViewQuery = useSetQueryState()

  return {
    removeNodeFromCurrentViewTree(nodeName: string) {
      setCurrentViewQuery((oldValue): ViewQueryList => {
        if (oldValue === undefined) {
          return oldValue
        }
        const oldTree = oldValue.tree

        const newTree = removeNode({
          tree: structuredClone(oldTree),
          targetNodeName: nodeName,
        })

        // 指定されたparentNodeNameが見つからなかった場合
        if (newTree === undefined) {
          return oldValue
        }

        const nodes = flatNodes(newTree)
        const newFields = oldValue.fields.filter((field) => {
          const isNodeExists = nodes.some((node) => node.path.join(',') === field.nodePath.join(','))
          const labelNodePath = field.read.labelNodePath
          const isLabelNodeExists =
            labelNodePath === undefined ? true : nodes.some((node) => node.path.join(',') === labelNodePath.join(','))
          return isNodeExists && isLabelNodeExists
        })
        const newFilterTree = removeRemovedNodeFromFilterTree(nodes, oldValue.filterTree)

        if (!validateTree(newTree)) {
          notifyBugsnag({ error: new InvalidViewNodesError() })
          void message.warning(t(`同じ名前のノードが含まれています`))
          return oldValue
        }

        return {
          ...oldValue,
          tree: newTree,
          fields: newFields,
          sorters: oldValue.sorters.filter((x) =>
            x.nodePaths.every((nodePath) => nodes.some((node) => nodePath.join(',') === node.path.join(','))),
          ),
          filterTree: newFilterTree,
        }
      })
    },
    addNodeToCurrentViewTree(parentNodeName: string, newNode: ViewQueryNode) {
      setCurrentViewQuery((oldValue) => {
        if (oldValue === undefined) {
          return oldValue
        }
        const oldTree = oldValue.tree

        // addNodeは破壊的操作かつ、oldValueはreadonlyになっているので、一旦jsonにして回避している // TODO: 他の解決策はあるか？
        const newTree = addNode({
          tree: structuredClone(oldTree),
          targetNodeName: parentNodeName,
          newNode,
        })

        // 指定されたparentNodeNameが見つからなかった場合
        if (newTree === undefined) {
          return oldValue
        }
        const newValue = {
          ...oldValue,
          tree: newTree,
        }
        const pruned = pruneQuery(newValue)

        if (!validateTree(pruned.tree)) {
          notifyBugsnag({ error: new InvalidViewNodesError() })
          void message.warning(t(`同じ名前のノードが含まれています`))
          return oldValue
        }

        return pruned
      })
    },
  }
}

export function validateTree(tree: ViewQueryNode) {
  const flattenedNodes = flatNodes(tree)
  return flattenedNodes.length === flattenedNodes.uniqueBy((x) => x.name).length
}
