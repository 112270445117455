import { type ApolloClient, useApolloClient } from '@apollo/client'
import { isNull, r } from '@salescore/buff-common'
import { FetchViewSearchSqlResultDocument } from '@salescore/client-api'
import { getOrganizationIdFromPath } from '@salescore/client-common'
import {
  CoreDsl,
  type DynamicSelectOptionConfig,
  getDynamicSelectOptions,
  type SelectOption,
  type ViewQueryRecordNode,
  type ViewQueryRecordNodeWithParent,
} from '@salescore/core'
import { calculateCharLength } from '@salescore/frontend-common'
import { Select } from 'antd'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'

import { useQueryValue } from '../../../../../recoil/view/hooks'
import { useUpsertSheetRowMutation } from '../../../../recoil/mutations/upsertSheetRowMutation'
import type { RSheetRecordNode, RSheetsSelectOption } from '../../../../types'
import type { RSheetsCellInputRenderTypeArgument } from '../rSheetsCellInputRenderTypeArgument'
import { RSheetsInputCellWrapper } from './InputCellWrapper'

export function RSheetsCellSelectOptionInput({
  recordNode,
  rootRecordNode,
  parentRecordNode,
  column,
  rowIndex,
  innerRowIndex,
  defaultWidth,
  finishEditMode,
  boxStyle,
}: RSheetsCellInputRenderTypeArgument) {
  const query = useQueryValue()
  const columnOnChange = useUpsertSheetRowMutation()
  const initialValue = recordNode === undefined ? undefined : column.value(recordNode)
  const [value, setValue] = useState(typeof initialValue === 'string' ? initialValue : null)
  const reference = useRef<HTMLInputElement>(null)
  const [options, setOptions] = useState<SelectOption[]>([])
  const client = useApolloClient()
  const setOptionsAsync = async () => {
    const xs =
      column.dynamicSelectOptionConfig === undefined
        ? getOptions({
            nodeName: column.node.name,
            options: column.selectOptions ?? [],
            recordNode,
            parent: parentRecordNode,
          })
        : await getOptionsBySql({
            recordNode,
            client,
            organizationId: getOrganizationIdFromPath(),
            config: column.dynamicSelectOptionConfig,
          })
    setOptions(xs)
  }
  useEffect(() => {
    void setOptionsAsync()
  }, [])

  setTimeout(() => {
    if (reference.current) {
      reference.current.focus()
    }
  }, 200)

  const onChangeFixed = async () => {
    await columnOnChange({ value, rowIndex, innerRowIndex, column })
  }

  return RSheetsInputCellWrapper(
    {
      onChangeFixed,
      finishEditMode,
    },
    ({ handleKeyDown }) => {
      const width = Math.max(defaultWidth, ...options.map((x) => calculateCharLength(x.label) * 10))

      return (
        <div
          style={{
            ...boxStyle,
            // Spreadsheetと似たロジックで、中身の文字列が増えたら幅を広げて全部表示させる。
            // 他のセルの位置に影響を与えずこれを実現するため、position: absoluteにして実現している
            width: Math.max(defaultWidth, calculateCharLength(value!) * 10),
            backgroundColor: 'white',
            // padding: '6px 10px', // padingYはボーダーにかぶらなければなんでも良い
            paddingTop: 3,
            marginTop: 1, // これがないと1pxずれるが、Cell側と比べてなぜ1px分の差があるのか不明
            marginLeft: 1,
            // boxShadow: '1px 4px 7px 2px rgb(60 64 67 / 15%)',
          }}
          onClick={() => {
            finishEditMode()
          }}
        >
          <Select
            onKeyDown={async (e) => {
              if (e.key === 'Enter') {
                // ここのEnterでfinishEditModeすると、日本語変換ができなくなるのでやらない。
                // onChangeの方でfinishEditModeするだけで十分。（カーソル移動してEnterすればonChangeが発火する）
                // finishEditMode({
                // moveRowDiff: 1, // 2021/11 この場合のエンターは移動させたくないという意見があったため
                // })
                e.stopPropagation()
              } else if (e.key === 'Escape') {
                await onChangeFixed()
                finishEditMode()
                e.stopPropagation()
              } else {
                handleKeyDown(e)
              }
            }}
            // 選択状態にするためvalueを設定したいが、renderをカスタムすることができず、表示が微妙になるためvalueの設定を行わない
            // value={value}
            // tagRender={() => {
            //   return <></>
            // }}
            // placeholder="aaa"
            allowClear
            autoFocus={true}
            defaultOpen={true}
            bordered={false}
            showSearch
            value={value}
            options={[
              {
                value: null as unknown as string, // TODO
                label: t(`(選択なし)`),
              },
              ...options,
            ]}
            style={{
              width: '100%',
              minWidth: width,
            }}
            onChange={async (value) => {
              // 選択時にescなどで離脱するとvalueがundefinedになるケースがあるためnull変換する
              // ※undefinedで通すと後続(upsertViewRecordsMutation)のJSON.stringify()でスキーマから当該プロパティが除外されてしまうため
              // eslint-disable-next-line unicorn/prefer-default-parameters
              const newValue = value ?? null
              await columnOnChange({ value: newValue, rowIndex, innerRowIndex, column })
              // enterで選択した時はカーソルを下に移動するが、onClickで選択した場合は下に移動させない
              finishEditMode()
            }}
          />
        </div>
      )
    },
  )
}

export async function getOptionsBySql({
  client,
  config,
  organizationId,
  recordNode,
}: {
  client: ApolloClient<unknown>
  organizationId: string
  config: DynamicSelectOptionConfig
  recordNode: ViewQueryRecordNode | undefined
}) {
  return await getDynamicSelectOptions({
    config,
    recordNode,
    fetch: async (sql) => {
      const result = await client.query({
        query: FetchViewSearchSqlResultDocument,
        variables: {
          organizationId,
          sql,
        },
        fetchPolicy: `no-cache`,
      })
      const rows = result.data.viewSearchSqlResult.rows as unknown[]
      return rows
    },
  })
}

export function getOptions({
  options,
  nodeName,
  recordNode,
  parent,
}: {
  options: RSheetsSelectOption[]
  nodeName: string
  recordNode: ViewQueryRecordNodeWithParent | undefined
  parent: RSheetRecordNode | undefined
}) {
  if (recordNode === undefined) {
    return options
  }
  // XXX: optionのruleに記載されている条件式の変数名はプロパティ名であり、フィールド名(≒ノード名+プロパティ名)ではない
  //      ここで無理やりノード名を取り除いているが、同じプロパティ名のものが複数あるとエラーになる可能性がある
  //      ノードが違う場合はrecordNodeが異なるはずなので、同じものが複数ありうることは、設計上はおそらくないはず
  const attributesAsPropertyNames = r(recordNode.attributes ?? {}).transformKeys((key) =>
    key.replace(`${nodeName}_`, ''),
  ).data
  const filteredOptions = options.filter((option) => {
    if (isNull(option.rule)) {
      return true
    }
    const result = CoreDsl.compileWithRecordNode(option.rule, recordNode)
    if (result.success && result.data === true) {
      return true
    }

    // TODO: deprecated
    const resultOld = CoreDsl.compile(option.rule, attributesAsPropertyNames)
    return resultOld.success && resultOld.data === true
  })

  return filteredOptions.isPresent() ? filteredOptions : options
}

// TODO: 他の箇所でも使いそうなので、共通化
type ParentRSheetRecordNode = Omit<RSheetRecordNode, 'children'> & {
  parent: ParentRSheetRecordNode | undefined
}
