import { isNull } from '@salescore/buff-common'
import type { NodePropertyName } from '@salescore/core'
import { useModal } from '@salescore/frontend-common'
import { Input, Modal } from 'antd'
import { t } from 'i18next'

import { useConnectionsSelector } from '../../../../../recoil/view/selectors/connectionsSelector'
import { NodePropertyPickerModalContent } from './NodePropertyPickerModalContent'
import type { NodeWithModel } from './nodeWithModel'

export const NodePropertyPicker = ({
  propertiesWithNode,
  picked,
  onFinish,
}: {
  propertiesWithNode: NodeWithModel[]
  picked: NodePropertyName | undefined
  onFinish: (property: NodePropertyName) => void
}) => {
  const modal = useModal()
  const { getModel } = useConnectionsSelector()

  const getPickedLabel = () => {
    if (isNull(picked)) {
      return ''
    }
    const pickedModel = getModel(picked.modelName)
    const pickedPropertyWithNode = propertiesWithNode.find(
      (x) => x.model.name === picked.modelName && x.property.name === picked.propertyName,
    )
    if (isNull(pickedModel) || isNull(pickedPropertyWithNode)) {
      return ''
    }
    return `${pickedModel.label}: ${pickedPropertyWithNode.property.label}`
  }

  return (
    <>
      <Input
        onClick={() => {
          modal.showModal()
        }}
        // TODO: node name
        value={getPickedLabel()}
        readOnly
      />
      <Modal
        open={modal.isModalVisible}
        onCancel={modal.hideModal}
        width={900}
        cancelText={t(`閉じる`)}
        okButtonProps={{ style: { display: 'none' } }}
        title={<div></div>}
        style={{ top: '3%' }}
        destroyOnClose
      >
        <NodePropertyPickerModalContent
          propertiesWithNode={propertiesWithNode}
          onFinish={(nodePropertyName) => {
            onFinish(nodePropertyName)
            modal.hideModal()
          }}
        />
      </Modal>
    </>
  )
}
