import { isSome } from '@salescore/buff-common'
import type { OAuthExtraConfig } from '@salescore/client-common'
import { Form, Input } from 'antd'
import FormItem from 'antd/es/form/FormItem'
import { t } from 'i18next'
import type { Dispatch, SetStateAction } from 'react'

const SHEET_URL_PREFIX = 'https://docs.google.com/spreadsheets/d/'

export function GoogleSheetsSourceForm({
  setExtraConfig,
}: {
  setExtraConfig: Dispatch<SetStateAction<OAuthExtraConfig>>
}) {
  return (
    <Form layout="vertical">
      <FormItem
        label={t(`スプレッドシートのURL`)}
        name="url"
        required
        rules={[{ pattern: new RegExp(SHEET_URL_PREFIX), message: t(`スプレッドシートのURLを入力してください。`) }]}
      >
        <Input
          onChange={(e) => {
            const url = e.target.value
            const spreadsheetId = extractSheetId(url)
            if (isSome(spreadsheetId)) {
              setExtraConfig({
                spreadsheetId,
              })
            } else {
              setExtraConfig({
                spreadsheetId: undefined,
              })
            }
          }}
        />
      </FormItem>
      <FormItem label={t(`シート名`)}>
        <Input
          onChange={(e) => {
            const name = e.target.value
            setExtraConfig((x) => ({
              ...x,
              name,
            }))
          }}
        />
      </FormItem>
    </Form>
  )
}

function extractSheetId(url: string) {
  if (!url.startsWith(SHEET_URL_PREFIX)) {
    return
  }
  return url.replace(SHEET_URL_PREFIX, '').split('/')[0]
}
