import { MenuOutlined } from '@ant-design/icons'
import { isNull, isSome } from '@salescore/buff-common'
import { isSameNodeProperty, type WaterfallDimension } from '@salescore/core'
import { SortableContainer } from '@salescore/frontend-common'
import { Button, Card, Dropdown, Flex } from 'antd'
import { t } from 'i18next'
import type { ReactNode } from 'react'
import type { SetterOrUpdater } from 'recoil'

import {
  type KpiFormCurrentNodeProperty,
  useKpiFormSelectors,
} from '../../../../../recoil/view/selectors/kpiFormSelector'
import { PropertySelect } from '../../../ViewUIKpiPivot/KpiForm/PropertySelect'
import type { WaterfallDimensionGroupOption, WaterfallDimensionGroupType } from '../common'

export function WaterfallDimensionPropertySelect({
  option,
  dimension,
  setWaterfallDimensions,
}: {
  option?: WaterfallDimensionGroupOption
  dimension?: WaterfallDimension
  setWaterfallDimensions: SetterOrUpdater<WaterfallDimension[] | undefined>
}): ReactNode {
  const selectableProperties = useSelectableProperties({ dimensionGroupType: option?.value })

  return (
    <Card style={{ width: 245 }} size="small">
      <Flex className="mb-2 w-full" justify="space-between" align="center">
        <span className="font-bold">{t('項目の追加')}</span>
        {/* 一旦、項目が固定のうちは「確度増減」だけ項目順序並び替えを許可する */}
        {option?.value === 'probabilityChange' && (
          <Dropdown
            placement="bottomLeft"
            trigger={['click']}
            dropdownRender={() => (
              <>
                <SortableProperties dimension={dimension} setWaterfallDimensions={setWaterfallDimensions} />
              </>
            )}
          >
            <Button disabled={isSome(dimension?.property)} className="text-xs opacity-25" type="text">
              {t('並び替え')}
            </Button>
          </Dropdown>
        )}
      </Flex>
      <PropertySelect
        properties={selectableProperties}
        placeholder={t('項目を選択')}
        value={dimension?.property}
        onChange={(value) => {
          if (isNull(option) || isNull(value)) {
            return
          }

          setWaterfallDimensions((oldDimensions) =>
            oldDimensions?.map((dimension) => {
              if (option.items.some((item) => dimension.name === item.name)) {
                return {
                  ...dimension,
                  property: value.nodePropertyName,
                }
              }
              return dimension
            }),
          )
        }}
      />
    </Card>
  )
}

function useSelectableProperties({
  dimensionGroupType,
}: {
  dimensionGroupType?: WaterfallDimensionGroupType
}): KpiFormCurrentNodeProperty[] {
  const {
    currentNodeIdProperties,
    currentNodeNumberProperties,
    currentNodeDateProperties,
    currentNodeSelectProperties,
  } = useKpiFormSelectors()

  if (isNull(dimensionGroupType)) {
    return []
  }

  switch (dimensionGroupType) {
    case 'newOrLost': {
      return currentNodeIdProperties
    }
    case 'amountChange': {
      return currentNodeNumberProperties
    }
    case 'timelineShift': {
      return currentNodeDateProperties
    }
    case 'probabilityChange': {
      return [...currentNodeSelectProperties, ...currentNodeNumberProperties]
    }
    default: {
      const x: never = dimensionGroupType
      throw new Error(`Unknown dimensionType`)
    }
  }
}

function SortableProperties({
  dimension,
  setWaterfallDimensions,
}: {
  dimension?: WaterfallDimension
  setWaterfallDimensions: SetterOrUpdater<WaterfallDimension[] | undefined>
}): ReactNode {
  const { currentNodeProperties } = useKpiFormSelectors()
  const currentProperty = currentNodeProperties.find((x) => isSameNodeProperty(x.nodeProeprtyName, dimension?.property))

  if (isNull(currentProperty?.property.selectOptions)) {
    return <></>
  }

  const sortedOptions = [...currentProperty.property.selectOptions].sort((a, b) => {
    const aIndex = dimension?.extra?.optionsOrder?.findIndex((x) => x.value === a.value) ?? -1
    const bIndex = dimension?.extra?.optionsOrder?.findIndex((x) => x.value === b.value) ?? -1
    return aIndex - bIndex
  })

  return (
    <Card style={{}} size="small">
      <Flex className="mb-2 w-full" justify="space-between" align="center">
        <span className="font-bold">{t('{{label}}の並び替え', { label: currentProperty.property.label })}</span>
      </Flex>
      <span className="text-[10px] font-bold">{t('優位')}</span>
      <SortableContainer
        items={sortedOptions.map((x, index) => ({
          id: `${x.value}`,
          render: () => (
            <div className="flex cursor-grab items-center justify-between hover:opacity-75">
              <div className="flex w-full items-center gap-3">
                <MenuOutlined />
                {x.value}
              </div>
            </div>
          ),
        }))}
        onSortEnd={(sortedIds) => {
          const optionsOrder = sortedIds
            .map((id) => currentProperty.property.selectOptions?.find((option) => option.value === id))
            .compact()

          setWaterfallDimensions((oldDimensions) =>
            oldDimensions?.map((dimension) => {
              if (isSameNodeProperty(dimension.property, currentProperty.nodeProeprtyName)) {
                return {
                  ...dimension,
                  extra: {
                    ...dimension.extra,
                    optionsOrder,
                  },
                }
              }
              return dimension
            }),
          )
        }}
      />
      <span className="text-[10px] font-bold">{t('劣位')}</span>
    </Card>
  )
}
