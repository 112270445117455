import { DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { useMutation, useSuspenseQuery } from '@apollo/client'
import { isNull } from '@salescore/buff-common'
import {
  CreateDimensionGroupDocument,
  type DimensionGroupFieldsFragment,
  UpdateDimensionGroupDocument,
} from '@salescore/client-api'
import { FetchDimensionGroupsDocument } from '@salescore/client-api'
import { Posthog, POSTHOG_EVENTS } from '@salescore/client-base'
import { ErrorContext, getOrganizationIdFromPath, isDataExtensionModel } from '@salescore/client-common'
import { recoil } from '@salescore/client-recoil'
import type { DimensionGroup } from '@salescore/core'
import { Alert, Button, Col, Form, Input, List, message, Row, Select, Space } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import { useContext, useState } from 'react'

import { PropertiesList } from './PropertiesList'
import { PropertyCascader } from './PropertyCascader'

type FormValue = Omit<DimensionGroup, 'id'>

export function UpsertDimensionGroupForm({
  dimensionGroup,
  withoutGoalFormItem,
  onAfterFinish,
}: {
  dimensionGroup?: DimensionGroupFieldsFragment
  withoutGoalFormItem?: boolean
  onAfterFinish: (x: DimensionGroupFieldsFragment) => void
}): JSX.Element {
  const me = recoil.global.useMe()
  const haveDataExtensionLicense =
    me.isAdmin || me.organization.organizationPlans.some((x) => x.license === 'dataExtension')
  const { canManageDimensionGroup } = recoil.global.policy.useCanForFeatures()
  const { data } = useSuspenseQuery(FetchDimensionGroupsDocument, {
    variables: { organizationId: getOrganizationIdFromPath() },
  })
  const { models, goalDimensions } = data
  const [form] = useForm<FormValue>()
  const [updateDimensionGroupMutation] = useMutation(UpdateDimensionGroupDocument)
  const [createDimensionGroupMutation] = useMutation(CreateDimensionGroupDocument)
  const [loading, setLoading] = useState(false)
  const errorContext = useContext(ErrorContext)
  const [properties, setProperties] = useState<Array<FormValue['properties'][0]>>(dimensionGroup?.properties ?? [])
  const removeModelProperty = (index: number): void => {
    setProperties((xs) => {
      const modelProperties = xs.filter((x) => x.type === 'modelProperty')
      const goalDimensions = xs.filter((x) => x.type === 'goalDimension')
      return [...modelProperties.slice(0, index), ...modelProperties.slice(index + 1), ...goalDimensions]
    })
  }
  const filteredModels = models.filter((x) => haveDataExtensionLicense || !isDataExtensionModel(x))

  const onFinish = async (values: FormValue): Promise<void> => {
    try {
      setLoading(true)
      if (dimensionGroup === undefined) {
        const result = await createDimensionGroupMutation({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            dimensionGroup: {
              label: values.label,
              properties,
            },
          },
        })
        const saved = result.data?.createDimensionGroup
        if (isNull(saved)) {
          void message.error(t(`エラーが発生しました`))
        } else {
          onAfterFinish(saved)
          void message.info(t(`軸グループ設定を更新しました！`))
          Posthog.track(POSTHOG_EVENTS.save_dimention_group, {
            organizationId: getOrganizationIdFromPath(),
            config: result.data?.createDimensionGroup,
          })
        }
      } else {
        const result = await updateDimensionGroupMutation({
          variables: {
            organizationId: getOrganizationIdFromPath(),
            dimensionGroup: {
              id: dimensionGroup.id,
              label: values.label,
              properties,
            },
          },
        })
        const saved = result.data?.updateDimensionGroup
        if (isNull(saved)) {
          void message.error(t(`エラーが発生しました`))
        } else {
          onAfterFinish(saved)
          void message.info(t(`軸グループ設定を更新しました！`))
          Posthog.track(POSTHOG_EVENTS.save_dimention_group, {
            organizationId: getOrganizationIdFromPath(),
            config: result.data?.updateDimensionGroup,
          })
        }
      }
    } catch (error) {
      errorContext.throwErrors(error as Error)
    } finally {
      setLoading(false)
    }
  }

  if (!canManageDimensionGroup) {
    return (
      <PageHeader title={t(`軸グループ設定を作成`)}>
        <Alert type="warning" message={t(`権限がありません`)} />
      </PageHeader>
    )
  }

  return (
    <PageHeader title={t(`軸グループ設定を作成`)}>
      <Form<FormValue>
        form={form}
        onFinish={(value) => {
          void onFinish(value)
        }}
        layout="vertical"
        initialValues={dimensionGroup}
      >
        <Form.Item
          name="label"
          label={t(`軸グループ設定の名前`)}
          // tooltip={'「事業計画」「チーム目標」など'}
          rules={[{ required: true, message: t(`軸グループ設定の名前を入力してください`) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="propertiesForModel" label={t(`グループ化したいプロパティを選択(複数選択)`)} required>
          <PropertyCascader
            models={filteredModels}
            className="mb-4"
            onChange={(x) => {
              // 既に選択済みのpropertyが選択されたら削除する
              if (
                properties.some(
                  (y) => y.type === 'modelProperty' && y.propertyName === x.propertyName && y.modelName === x.modelName,
                )
              ) {
                setProperties((xs) =>
                  xs.filter(
                    (y) =>
                      !(y.type === 'modelProperty' && y.propertyName === x.propertyName && y.modelName === x.modelName),
                  ),
                )
              } else {
                setProperties((xs) => [
                  ...xs,
                  {
                    type: 'modelProperty',
                    modelName: x.modelName,
                    propertyName: x.propertyName,
                  },
                ])
              }
            }}
          />
          <PropertiesList
            models={filteredModels}
            propertyNames={properties.map((x) => (x.type === 'modelProperty' ? x : undefined)).compact()}
            onDelete={removeModelProperty}
          />
        </Form.Item>
        {withoutGoalFormItem !== true && (
          <Form.Item name="propertiesForGoal" label={t(`グループ化したい目標軸を選択(複数選択)`)}>
            <Select
              options={goalDimensions.map((x) => ({
                label: <span className="whitespace-normal break-words">{x.label}</span>,
                value: x.id,
              }))}
              value={undefined}
              className="mb-4"
              onChange={(goalDimensionId: string) => {
                setProperties((xs) => [
                  ...xs,
                  {
                    type: 'goalDimension',
                    goalDimensionId,
                  },
                ])
              }}
            />
            <List
              dataSource={properties.map((x) => (x.type === 'goalDimension' ? x : undefined)).compact()}
              bordered
              renderItem={(item) => {
                const goalDimension = goalDimensions.find((x) => x.id === item.goalDimensionId)
                return (
                  <List.Item
                    actions={[
                      <Button
                        key={1}
                        onClick={() => {
                          setProperties((xs) =>
                            xs.filter(
                              (x) => !(x.type === 'goalDimension' && x.goalDimensionId === item.goalDimensionId),
                            ),
                          )
                        }}
                        type="text"
                        icon={<DeleteOutlined />}
                      />,
                    ]}
                  >
                    {goalDimension?.label ?? item.goalDimensionId}
                  </List.Item>
                )
              }}
            />
          </Form.Item>
        )}
        <Form.Item>
          <Space className="mt-16 w-full flex-row-reverse">
            <Button icon={<SaveOutlined />} type="primary" loading={loading} htmlType="submit">
              {t(`保存`)}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </PageHeader>
  )
}

function PropertyFormItem({ property }: { property: Partial<FormValue['properties'][0]> }): JSX.Element {
  return (
    <Row>
      <Col span={8}>
        <Form.Item>
          <Select
            value={property.type}
            options={[
              {
                value: 'modelProperty',
                label: t(`プロパティ`),
              },
              {
                value: 'goalDimension',
                label: t(`目標軸`),
              },
            ]}
          />
        </Form.Item>
      </Col>
      {property.type === 'modelProperty' && (
        <>
          <Col span={8}>
            <Form.Item>
              {/* PropertyPicker */}
              <Select />
            </Form.Item>
          </Col>
        </>
      )}
      {property.type === 'goalDimension' && (
        <>
          <Col span={8}>
            <Form.Item>{/* goalDimensionのpicker */}</Form.Item>
          </Col>
        </>
      )}
    </Row>
  )
}
