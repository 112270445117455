import { parseJsonIfValid } from '@salescore/buff-common'
import { z } from 'zod'

const CONTEXT = `# 指示内容
これからメールの情報を入力します。以下の2つの情報を抽出してください。

1. メールの差出人名、または署名情報から差出人の氏名を抽出し、また姓名で分割してください。
* 氏名
* 姓
* 名

2. 本文中の署名から以下の情報を抽出してください。
* 会社名
* 部署名
* 役職
* 電話番号
* 会社URL
* 会社住所

# 入力フォーマット
以下の形式でメールの情報を入力します。

差出人メールアドレス：
差出人名：
本文：

# 出力フォーマット
以下のJSON形式で抽出した情報を出力してください。抽出ができない場合、nullを出力してください。
{
  "氏名": "",
  "姓": "",
  "名": "",
  "会社名": "",
  "部署名": "",
  "役職": "",
  "電話番号": "",
  "会社URL": "",
  "会社住所": ""
}`

const resultSchema = z.object({
  氏名: z.string().nullable(),
  姓: z.string().nullable(),
  名: z.string().nullable(),
  会社名: z.string().nullable(),
  部署名: z.string().nullable(),
  役職: z.string().nullable(),
  電話番号: z.string().nullable(),
  会社URL: z.string().nullable(),
  会社住所: z.string().nullable(),
})

type AskWithContext = (x: { question: string; context: string }) => Promise<string>
export async function extractFromEmailMessage({
  emailAddress,
  emailUserName,
  emailBody,
  askWithContext,
}: {
  emailAddress: string
  emailUserName: string
  emailBody: string
  askWithContext: AskWithContext
}) {
  if (emailBody.length < 5) {
    // 短すぎる場合は抽出しない。姓名の分離も行わない
    return {
      success: false as const,
      error: `本文が短すぎるため抽出を実行しませんでした`,
      message: emailBody,
    }
  }
  const shortenedMessage = shortenEmailMessage(emailBody)
  const question = `差出人メールアドレス：${emailAddress}
差出人名：${emailUserName}
本文：${shortenedMessage}`

  const res = await askWithContext({ question, context: CONTEXT })
  const result = resultSchema.safeParse(parseJsonIfValid(res))
  if (!result.success) {
    return {
      success: false as const,
      error: [result.error.message, res].join(': '),
      message: shortenedMessage,
    }
  }
  return {
    success: true as const,
    data: result.data,
    message: shortenedMessage,
  }
}

const EMAIL_MESSAGE_PREFIX_MAX_LENGTH = 200 // 比較的余裕をもった値
const EMAIL_MESSAGE_SUFFIX_MAX_LENGTH = 300
const EMAIL_MESSAGE_MAX_LENGTH = EMAIL_MESSAGE_PREFIX_MAX_LENGTH + EMAIL_MESSAGE_SUFFIX_MAX_LENGTH

function shortenEmailMessage(message: string) {
  if (message.length < EMAIL_MESSAGE_MAX_LENGTH) {
    return message
  }

  return message.slice(0, EMAIL_MESSAGE_PREFIX_MAX_LENGTH) + '\n\n\n' + message.slice(-EMAIL_MESSAGE_SUFFIX_MAX_LENGTH)
}
