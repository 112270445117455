import { compact } from '@salescore/buff-common'
import type { ViewConfigFilterNodeForJoinOn, ViewConfigPropertyFilter } from '@salescore/core'
import { Button, Form, Row } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'

import type { NodeWithModel } from '../common/nodeWithModel'
import type { FilterFormItemState, ViewConfigFilterNodeForForm } from './common'
import { type FilterFormNodeState, initialState, ViewFilterNodeFormItem } from './ViewFilterNodeFormItem'

export const ViewFilterTreeForm = ({
  filterTree,
  propertiesWithNode,
  isAdvancedMode,
  cacheKey,
  inNestedForm,
  onAfterFinish,
  onChange,
  disabled,
}: {
  filterTree: ViewConfigFilterNodeForForm | undefined
  propertiesWithNode: NodeWithModel[]
  isAdvancedMode: boolean
  cacheKey?: string
  inNestedForm?: boolean // XXX: このコンポーネント内でFormを使っているが、親でもFormを使っていると表示が崩れる。これを防ぐため、フォーム内で使う時はこの引数で指定する
  onAfterFinish?: (filterNode: ViewConfigFilterNodeForJoinOn | undefined) => void
  onChange?: (filterNode: ViewConfigFilterNodeForJoinOn | undefined, option?: { asInitialization?: boolean }) => void
  disabled?: boolean
}) => {
  const [filterNodeState, setFilterNodeStateRaw] = useState<FilterFormNodeState>(filterTree ?? initialState)
  const setFilterNodeState = (
    f: (x: FilterFormNodeState) => FilterFormNodeState,
    option?: { asInitialization?: boolean },
  ) => {
    setFilterNodeStateRaw((x) => {
      const newValue = f(x)
      if (onChange !== undefined) {
        const filterTree = convertFilterNodeStateToFilterNode(newValue)
        onChange(filterTree, option)
      }
      return newValue
    })
  }

  const onFinish = () => {
    const filterTree = convertFilterNodeStateToFilterNode(filterNodeState)
    // TODO: validationの表示をちゃんとやりたいが、いったん後回し
    onAfterFinish?.(filterTree)
  }

  useEffect(() => {
    setFilterNodeStateRaw(filterTree ?? initialState)
  }, [cacheKey])

  return (
    <Form layout="vertical">
      <ViewFilterNodeFormItem
        inNestedForm={inNestedForm}
        isAdvancedMode={isAdvancedMode}
        filterNodeState={filterNodeState}
        propertiesWithNode={propertiesWithNode}
        setFilterState={(
          index: number,
          newFilter: FilterFormItemState | undefined,
          option?: { asInitialization?: boolean },
        ) => {
          setFilterNodeState((x) => {
            const newLeafs = compact([...x.leafs.slice(0, index), newFilter, ...x.leafs.slice(index + 1)])
            return {
              ...x,
              leafs: newLeafs,
            }
          }, option)
        }}
        setFilterNodeState={(index: number, newFilterNode: ViewConfigFilterNodeForForm | undefined) => {
          setFilterNodeState((x) => {
            const newChildren: ViewConfigFilterNodeForForm[] = [
              ...x.children.slice(0, index),
              newFilterNode,
              ...x.children.slice(index + 1),
            ].compact()
            return {
              ...x,
              children: newChildren,
            }
          })
        }}
        onLogicalOperatorChange={(logicalOperator: 'and' | 'or') => {
          setFilterNodeState((x) => ({ ...x, logicalOperator }))
        }}
        // onDestroy={() => {
        //   setFilterNodeState(initialState)
        // }}
        disabled={disabled}
      />
      {onAfterFinish !== undefined && (
        <Row justify="end">
          <Button
            onClick={() => {
              onFinish()
            }}
          >
            {t(`適用`)}
          </Button>
        </Row>
      )}
    </Form>
  )
}

// 必須項目が埋められていなかったら消してから返す
function convertFilterNodeStateToFilterNode(state: FilterFormNodeState): ViewConfigFilterNodeForJoinOn | undefined {
  const leafs = state.leafs.map((leaf) => validateFilterState(leaf)).compact()
  const converted = {
    logicalOperator: state.logicalOperator,
    leafs,
    children: compact(state.children.map((child) => convertFilterNodeStateToFilterNode(child))),
  }

  if (converted.leafs.length === 0 && converted.children.length === 0) {
    return undefined
  }

  return converted
}

function validateFilterState(x: FilterFormItemState): ViewConfigPropertyFilter | undefined {
  if (x.filterType === undefined || x.property === undefined) {
    return undefined
  }

  return {
    type: 'property',
    property: x.property,
    filterType: x.filterType,
    filterValue: x.filterValue,
    filterDateTimeType: x.filterDateTimeType,
    option: x.option,
  }
}

export function applyAdvancedSettingsFlag<T>(isAdvanced: boolean, filterTree?: T) {
  if (filterTree === undefined || isAdvanced) {
    return filterTree
  }
  return { ...filterTree, logicalOperator: 'and', children: [] }
}
