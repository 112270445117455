import { isSome } from '@salescore/buff-common'

import type { ViewQueryNode, ViewQueryRecordNode, ViewQueryTableNode } from '../../../schemas/query'
import type { SqlClient } from '../executeViewQueryForAggregationQuery'

const recordChangeStream = {
  destination: {
    name: 'salescore_record_changes',
  },
}

interface SalescoreRecordChange {
  stream_name: string
  record_id: string
  changed_salescore_user_id: string
  properties_before?: Record<string, unknown>
  properties_after?: Record<string, unknown>
  properties_after_keys?: string[]
  is_initialized: boolean
  status: string // TODO: enum
  error: unknown
}

export async function addMetadata(
  sqlClient: SqlClient,
  records: ViewQueryRecordNode[],
  tree: ViewQueryNode,
  dateForDiffHighlight: Date | undefined,
): Promise<ViewQueryRecordNode[]> {
  const withInnerIndex = records.map((record) => addInnerRowIndexMetadata(record))
  const withHighlight = isSome(dateForDiffHighlight)
    ? await addHighlightMetadata(sqlClient, withInnerIndex, tree, dateForDiffHighlight)
    : withInnerIndex
  return withHighlight
}

// TODO: 子レコードへの対応
async function addHighlightMetadata(
  sqlClient: SqlClient,
  records: ViewQueryRecordNode[],
  tree: ViewQueryNode,
  dateForDiffHighlight: Date,
): Promise<ViewQueryRecordNode[]> {
  const ids = records.map((x) => x.id).compact()
  if (ids.isEmpty()) {
    return records
  }

  const streamName = tree.meta.dependedStreamNames[0]! // TODO:
  const sql = `SELECT * FROM ${recordChangeStream.destination.name} WHERE created_at >= '${dateForDiffHighlight
    .toISOString()
    .split('T')[0]!}' AND stream_name = '${streamName}' AND record_id in (${ids
    .map((id) => `'${id}'`)
    .join(', ')}) LIMIT 1000`
  const result = await sqlClient.query(sql)
  const changes = (result.rows ?? []) as unknown as SalescoreRecordChange[]

  return records.map((record): ViewQueryRecordNode => {
    const changesForRecord = changes.filter((c) => c.record_id === record.id)
    if (changesForRecord.length === 0) {
      return record
    }

    return {
      ...record,
      meta: {
        ...record.meta,
        highlightAttributeNames: changesForRecord
          .flatMap((c) => c.properties_after_keys)
          .compact()
          .unique(),
      },
    }
  })
}

export function addInnerRowIndexMetadata(record: ViewQueryRecordNode): ViewQueryRecordNode {
  const innerRowIndexStart = 0
  const children = record.children.map((child) => addInnerRowIndexMetadataToTableNode(child, innerRowIndexStart))
  const heights = children.map((child) => {
    const innerRowIndexStart = child.children.first()?.meta.innerRowIndexStart ?? 0
    const innerRowIndexEnd = child.children.last()?.meta.innerRowIndexEnd ?? 1
    return innerRowIndexEnd - innerRowIndexStart
  })
  return {
    ...record,
    children,
    meta: {
      ...record.meta,
      height: heights.max() ?? 1,
      innerRowIndexStart,
      innerRowIndexEnd: innerRowIndexStart + record.meta.height,
    },
  }
}

function addInnerRowIndexMetadataToTableNode(tableNode: ViewQueryTableNode, parentStart: number): ViewQueryTableNode {
  const startIndice = tableNode.children.reduce(
    (accumulator, recordNode) => [...accumulator, accumulator.last()! + recordNode.meta.height],
    [parentStart],
  )

  return {
    ...tableNode,
    children: tableNode.children.map((recordNode, index) => {
      const innerRowIndexStart = startIndice[index]!
      const children = recordNode.children.map((child) =>
        addInnerRowIndexMetadataToTableNode(child, innerRowIndexStart),
      )
      const heights = children.map((child) => {
        const innerRowIndexStart = child.children.first()?.meta.innerRowIndexStart ?? 0
        const innerRowIndexEnd = child.children.last()?.meta.innerRowIndexEnd ?? 1
        return innerRowIndexEnd - innerRowIndexStart
      })
      return {
        ...recordNode,
        children,
        meta: {
          ...recordNode.meta,
          height: heights.max() ?? 1,
          innerRowIndexStart,
          innerRowIndexEnd: startIndice[index]! + recordNode.meta.height,
        },
      }
    }),
  }
}
