import type { OrganizationSettingFieldsFragment } from '@salescore/client-api'
import { type Period, periodUtil } from '@salescore/features'
import { parseJsonIfValid } from '@salescore/frontend-common'
import { t } from 'i18next'

export type { Period } from '@salescore/features'

const PERIODS_CACHE_KEY = 'PERIOD_CACHE_KEY'
const getCachedPeriodNames = () => {
  const cacheRaw = localStorage.getItem(PERIODS_CACHE_KEY)
  const cache = cacheRaw === null ? undefined : parseJsonIfValid(cacheRaw)
  const cachedPeriods = Array.isArray(cache) ? cache : []
  return cachedPeriods as string[] // TODO: validation
}

export const addPeriodNameToCache = (name: string) => {
  const cachedPeriods = getCachedPeriodNames()
  const newCache = [name, ...cachedPeriods].unique().slice(0, 10)
  localStorage.setItem(PERIODS_CACHE_KEY, JSON.stringify(newCache))
}

const getSelectedPeriodsHistory = (setting: OrganizationSettingFieldsFragment, periods: Period[]): Period[] => {
  const defaultRecommended = periodUtil.getDefaultRecommended(setting).map((x) => x.name)

  const recommended = [...getCachedPeriodNames(), ...defaultRecommended]
    .unique()
    .slice(0, 10)
    .map((name) => periods.find((period) => period.name === name))
    .compact()

  return recommended.map((x) => ({
    ...x,
    groupName: '履歴', // 本当はカウントなどしたいが、一旦これで十分か。
    groupNameLabel: t(`履歴`),
  }))
}

export const getPeriodsForDashboard = (setting: OrganizationSettingFieldsFragment): Period[] => {
  const periods = periodUtil.getPeriodsForDashboard(setting)

  return [...getSelectedPeriodsHistory(setting, periods), ...periods]
}

export const getPeriodsForGoalView = (setting: OrganizationSettingFieldsFragment): Period[] => {
  const periods = periodUtil.getPeriodsForGoalView(setting)

  // 基本的にダッシュボードと同じだが、以下を変更
  // ・「全期間」をださない（やるなら「目標が設定されている全ての期間」など。分かりづらいのでなしでよさそう）
  // ・履歴を表示しない（やるならダッシュボード側とkeyを変える必要）
  return periods
}

export const getPeriodsForKpiTimeSeriesKpiDateX = (setting: OrganizationSettingFieldsFragment): Period[] => {
  const periods = periodUtil.getPeriodsForKpiTimeSeriesKpiDateX(setting)

  // ・全期間を非表示
  // ・履歴を非表示
  // ・日を非表示
  return periods
}

export const getPeriodsForKpiTimeSeriesKpiDateY = (setting: OrganizationSettingFieldsFragment): Period[] => {
  const periods = periodUtil.getPeriodsForKpiTimeSeriesKpiDateY(setting)

  // ・履歴を非表示
  // ・日を非表示
  return periods
}
