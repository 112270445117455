interface Location {
  offset: number
  line: number
  column: number
}

interface PegjsEventBase {
  rule: string
  result: unknown
  location: Location
}

type PegjsEventMatch = PegjsEventBase & {
  type: 'rule.match'
  result: unknown
}

type PegjsEventEnter = PegjsEventBase & {
  type: 'rule.enter'
}

type PegjsEventFail = PegjsEventBase & {
  type: 'rule.fail'
}

type PegjsEvent = PegjsEventEnter | PegjsEventFail | PegjsEventMatch
interface PegjsEventWithDepth {
  event: PegjsEvent
  depth: number
}

export class PegjsTracer {
  private depth: number
  private readonly events: PegjsEventWithDepth[]
  private readonly text: string

  public constructor(text: string) {
    this.depth = 0
    this.events = []
    this.text = text
  }

  public trace(event: PegjsEvent) {
    if (event.type !== 'rule.enter') {
      this.depth -= 1
    }
    const eventWithDepth = {
      event,
      depth: this.depth,
    }
    this.events.push(eventWithDepth)
    if (this.depth < 13) {
      // 10以上はtermのはずで、冗長なので表示しない。
    }
    if (event.type === 'rule.enter') {
      this.depth += 1
    }
  }
}
