import type { From, Join, TableReference } from '../parser/grammer_schema'
import { expression } from './expression'
import type { BaseTableRecord, Table, TemporaryTable } from './types'

export function fromAndJoin(from: From, joins: Join[], tables: Table[]): TemporaryTable {
  const fromTable = referTable(from.table, tables)

  const selectTableByFrom: TemporaryTable = {
    tables: [
      {
        name: from.as ?? fromTable.name,
        columns: fromTable.columns,
      },
    ],
    records: fromTable.records.map((record) => ({
      [from.as ?? fromTable.name]: record,
    })),
  }
  const selectTableByJoin = joins.reduce((selectTable, join): TemporaryTable => {
    const joinTable = referTable(join.table, tables)
    const selectTableTables: TemporaryTable['tables'] = [
      ...selectTable.tables,
      {
        name: join.as ?? joinTable.name,
        columns: joinTable.columns,
      },
    ]
    switch (join.joinType) {
      case 'LEFT': {
        return {
          tables: selectTableTables,
          records: selectTable.records.flatMap((leftRecord): BaseTableRecord[] => {
            const joinedRecords = joinTable.records.map(
              (joinRecord): BaseTableRecord => ({
                ...leftRecord,
                [join.as ?? joinTable.name]: joinRecord,
              }),
            )
            const filteredRecords = joinedRecords.filter((x) => expression(join.on, x, { fields: [] }) === true)
            if (filteredRecords.isBlank()) {
              return [leftRecord]
            }
            return filteredRecords
          }),
        }
      }
      default: {
        throw new Error(join.joinType satisfies never)
      }
    }
  }, selectTableByFrom)
  return selectTableByJoin
}

function referTable(reference: TableReference, tables: Table[]): Table {
  const table = tables.find((table) => table.name === reference.tableName)
  if (table === undefined) {
    throw new Error(`from table not found`) // TODO
  }
  return table
}
