import { useApolloClient } from '@apollo/client'
import type { ViewQueryNode } from '@salescore/core'
import { message } from 'antd'
import { t } from 'i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import { searchRelation } from '../../../rsheet/recoil/mutations/searchRelation'
import { sheetCustomModelsAtom } from '../../view/atoms'
import { useQueryValue, useViewAbilityValue } from '../../view/hooks'
import { getDefaultAttributes } from '../selectors/querySelector'
import type { NewRecordChange, OnAfterInsert } from './addNewRecords'
import type { RelationChange } from './updateRecordNodeRelations'
import type { RecordChange } from './upsertViewRecords'
import { upsertViewRecordsMutation } from './upsertViewRecordsMutation'

//
// 指定したrowIndex,innerRowIndexの行の下に新規行を追加するhook
// 0行目に挿入したい場合は-1を用いる
//
export const useAddEmptyRecordMutation = () => {
  const upsertViewRecords = useSetRecoilState(upsertViewRecordsMutation)
  const client = useApolloClient()
  const query = useQueryValue()
  const ability = useViewAbilityValue()
  const sheetCustomModels = useRecoilValue(sheetCustomModelsAtom)

  const addEmptyRecordsMutation = async ({
    rowIndex,
    innerRowIndex,
    node,
    num,
    onAfterInsert,
  }: {
    rowIndex: number
    innerRowIndex: number
    node: ViewQueryNode
    num: number
    onAfterInsert?: OnAfterInsert
  }) => {
    const allNewRecordChanges: NewRecordChange[] = []
    const allRecordChanges: RecordChange[] = []
    const allRelationChanges: RelationChange[] = []

    for (let index = 0; index < num; index++) {
      const newRecordChange: NewRecordChange = {
        rowIndex,
        innerRowIndex,
        node,
      }
      const { defaultValues } = getDefaultAttributes(node, query)
      const recordChanges = defaultValues.map(
        ({ field, value }): RecordChange => ({
          rowIndex,
          innerRowIndex, // NOTE: upsertViewRecordsMutation側で新規生成されたレコードのinnerRowIndexに書き変わる(子ブロックの際は基本的に+1される、子レコードがない状態から新規作成した場合はindexは変わらない)
          field,
          nodePath: field.nodePath,
          isNestedColumn: false,
          value,
          label: undefined,
        }),
      )
      const relationChanges = await Promise.all(
        recordChanges.map(async (recordChange) => await searchRelation({ recordChange, client, query })),
      )
      allNewRecordChanges.push(newRecordChange)
      allRecordChanges.push(...recordChanges)
      allRelationChanges.push(...relationChanges.compact())
    }
    upsertViewRecords({
      newRecordChanges: allNewRecordChanges,
      recordChanges: allRecordChanges,
      relationChanges: allRelationChanges,
      onAfterInsert,
    })
  }

  async function addRootRecords({ rowIndex, num }: { rowIndex: number; num: number }) {
    if (!ability.canCreateNewRecord || !ability.canSaveRecord) {
      void message.warning(t(`権限がないため、新規レコード作成はできません`))
      return
    }
    const newRowIndex = rowIndex + 1
    await addEmptyRecordsMutation({ rowIndex: newRowIndex, innerRowIndex: 0, node: query.tree, num })
    return newRowIndex
  }

  async function addChildRecords({
    rowIndex,
    innerRowIndex,
    node,
    num,
    onAfterInsert,
  }: {
    rowIndex: number
    innerRowIndex: number
    node: ViewQueryNode
    num: number
    onAfterInsert?: OnAfterInsert
  }) {
    // 新規レコード作成権限があるかをチェック
    // SheetCustomModel＝カスタム列に対する新規作成は許す（ユーザーからの見た目は新規作成ではなく単なる編集なので）
    if (
      (!ability.canCreateNewRecord || !ability.canSaveRecord) &&
      !sheetCustomModels.map((x) => x.name).includes(node.name)
    ) {
      void message.warning(t(`権限がないため、新規レコード作成はできません`))
      return
    }
    await addEmptyRecordsMutation({ rowIndex, innerRowIndex, node, num, onAfterInsert })
  }

  return {
    addRootRecord: async ({ rowIndex }: { rowIndex: number }) => await addRootRecords({ rowIndex, num: 1 }),
    addRootRecords,
    addChildRecord: async ({
      rowIndex,
      innerRowIndex,
      node,
      onAfterInsert,
    }: {
      rowIndex: number
      innerRowIndex: number
      node: ViewQueryNode
      onAfterInsert?: OnAfterInsert
    }) => {
      await addChildRecords({ rowIndex, innerRowIndex, node, num: 1, onAfterInsert })
    },
    addChildRecords,
  }
}
